/* Copyright 2019 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * @ngdoc service
 * @name com.vmware.platform.ui:arraysUtil
 * @module com.vmware.platform.ui
 *
 * @description
 *    The `ChunkExecutor` service provides utility method to execute some task
 *    for each element of the data array, on chunks with given size and timeout between chunks .
 */
module platform {
   export class ChunkExecutor {
      constructor(private $q : any, private $timeout : any) {}

      /**
       * Execute some task for each element of the data array, on chunks
       * with given size and timeout between chunks. Each chunk is executed asynchronously.
       * @param {Function} callback represents task to be executed for each element.
       * @param {any[]} data input containing elements.
       * @param {number} chunckSize size of chunks to separate data and execute asynchronously.
       * @param {number} timeoutBetweenChunks minimum amount of time to wait
       * before starting execute next chunk, after the time previous has started.
       *
       * @returns {Promise<boolean>} promise resolved with true when all chunks
       * executions has finished successfully.
       */
      public executeOnChunks(callback: Function, data: any[], chunckSize: number, timeoutBetweenChunks: number): Promise<boolean> {
         const deferred = this.$q.defer();
         const chunkExecutions: Promise<any>[] = [];
         // Replace with _.chunk as soon as underscorejs is bumped to 1.9.1
         _.chunk(data, chunckSize).forEach((chunk, index) => {
            const nextTimeout = index * timeoutBetweenChunks;
            chunkExecutions[index] = this.scheduleChunkExecution(callback, chunk, nextTimeout);
         });

         this.$q.all(chunkExecutions).then(
               function () {
                  deferred.resolve(true);
               },
               function(error) {
                  deferred.reject(error);
               }
         );
         return deferred.promise;
      }

      private scheduleChunkExecution (callback, chunk, nextTimeout): Promise<any> {
         return this.$timeout(() => {
            this.executeOnChunk(callback, chunk);
         }, nextTimeout);
      }

      private executeOnChunk (callback, chunk) {
         chunk.forEach(function(item) {
            callback(item);
         });
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("chunkExecutor", ChunkExecutor);
}

