/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Click directive, supports different types of click. Eg: long click
 */
angular.module('com.vmware.platform.ui').directive('vxClick', ['$timeout', '$parse',
function($timeout, $parse) {
   return {
      restrict: 'A',
      link: function($scope, iElement, iAttrs, controller){

         var mouseDownTimeout;
         var type;

         function call(locals){
            // execute the expression
            $parse(iAttrs.vxClick)($scope, locals);
            type = locals.$type;
         }

         function mouseDown(evt){
            // TODO mousedown triggerd form Jquery  doesnt have the
            // evet.which populated. This affects testing or programatic triggers
            if (evt.which !== 1) {
               // Only interested in left button clicks.
               return;
            }
            type = null;
            $scope.$apply(function(){
               call({
                  $type: 'mousedown',
                  $event: evt
               });
            });

            mouseDownTimeout = $timeout(function() {
               call({
                  $type: 'slowClick'
               });
            },500);
         }

         function mouseUp(evt){
            if (evt.which !== 1) {
            // Only interested in left button clicks.
               return;
            }
            $timeout.cancel(mouseDownTimeout);
            if (type === 'slowClick') {
               call({
                  $type: 'mouseup'
               });
            } else {
               call({
                  $type: 'click',
                  $event: evt
               });
            }
         }

         function rightClick(evt){
            $scope.$apply(function(){
               call({
                  $type: 'rightclick',
                  $event: evt
               });
            });
         }

         function keyUp(evt){
            if (evt.keyCode === 32) { // space
               call({
                  $type: 'click',
                  $event: evt
               });
            }
         }

         $(iElement).on("mousedown", mouseDown);
         $(iElement).on("mouseup", mouseUp);
         $(iElement).on("contextmenu", rightClick);
         $(iElement).on("keyup", keyUp);
         $(iElement).on("click", function(evt) {
            evt.which = 1;
            mouseDown(evt);
         });


         $scope.$on("$destroy", function(){
            $(iElement).off("mousedown", mouseDown);
            $(iElement).off("mouseup", mouseUp);
            $(iElement).off("contextmenu", rightClick);
            $(iElement).off("keyup", keyUp);
         });
      },
      controller:['$scope', '$element', '$attrs', function($scope, $element, $attrs) {

      }]
   };
}]);