/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service responsible for showing menus and invoking actions related to alarm issues.
 */
angular.module('com.vmware.platform.ui').factory('alarmIssueActionService',
    ['$http', '$window', 'logService', 'i18nService', 'vuiActionsMenuService',
        'mutationService', 'authorizationService', 'defaultUriSchemeUtil', '$rootScope',
        'vuiConstants', '$q', 'jsUtils', '$timeout', '$analytics',
        function($http, $window, logService, i18nService, vuiActionsMenuService,
                 mutationService, authorizationService, defaultUriSchemeUtil, $rootScope,
                 vuiConstants, $q, jsUtils, $timeout, $analytics) {

    'use strict';
    var log = logService('alarmIssueActionService');

    var ACKNOWLEDGE_ACTION_UID = 'vsphere.opsmgmt.alarms.acknowledge';
    var RESET_TO_GREEN_ACTION_UID = 'vsphere.opsmgmt.alarms.reset';
    var ACTIONS_UIDS = [
        ACKNOWLEDGE_ACTION_UID,
        RESET_TO_GREEN_ACTION_UID
    ];

    var ACTION_UID_TO_DESCRIPTOR = {};
    ACTION_UID_TO_DESCRIPTOR[ACKNOWLEDGE_ACTION_UID] = {
        actionUid: ACKNOWLEDGE_ACTION_UID,
        iconClass: 'vx-icon-acknowledged',
        label: i18nService.getString('CommonModuleUi', 'alarmAction.acknowledge.label'),
        requiredPrivileges: [ 'Alarm.Acknowledge' ],
        availabilityPreCheck: function(alarmInfo, alarmState) {
            return alarmState.acknowledged === false;
        }
    };
    ACTION_UID_TO_DESCRIPTOR[RESET_TO_GREEN_ACTION_UID] = {
        actionUid: RESET_TO_GREEN_ACTION_UID,
        iconClass: 'vx-icon-reset_green',
        label: i18nService.getString('CommonModuleUi', 'alarmAction.reset.label'),
        requiredPrivileges: [ 'Alarm.SetStatus' ],
        availabilityPreCheck: function(alarmInfo, alarmState) {
            return alarmInfo.expression !== null;
        }
    };

    /**
     * Shows an actions menu for an AlarmIssue similar to the one in actionsService.
     *
     * @param event The click event that was raised to trigger the menu. Used
     * for getting the target element (event.target) on which the click was done
     * for anchoring the menu.
     *
     * @param scope The scope from which the function is invoked.
     *
     * @param alarmInfo A com.vmware.vim.binding.vim.alarm.AlarmInfo object describing an alarm.
     *
     * @param alarmState A com.vmware.vim.binding.vim.alarm.AlarmState object describing an alarm state.
     *
     * @param x The left co-ordinate from where the menu should render. (Optional)
     *
     * @param y The top co-ordinate from where the menu should render. (Optional)
     *
     * @param targetElement Specifies the element on which the actions menu should open.
     * If this is not specified event.target is used. If event is null or undefined,
     * the default element is document body. (Optional)
     */
    function showActionsMenu(event, scope, alarmInfo, alarmState, x, y, targetElement) {
        // New scope object for the vuiActionsMenu two way binding
        var localScope = scope.$new();
        localScope.menuDescriptor = buildMenuDescriptor(alarmInfo, alarmState);
        var target = targetElement;
        if (!target && event) {
            target = event.target;
        }

        var menuOptions = {
            scope: localScope,
            configObjectName: 'menuDescriptor',
            target: target,
            menuContainerId: 'applicationMenuContainer'
        };
        if (!jsUtils.isUndefinedOrNull(x) && !jsUtils.isUndefinedOrNull(y)) {
            menuOptions.coordinates = {
                x: x,
                y: y
            };
        }

        vuiActionsMenuService.showMenu(menuOptions);
        setMenuTooltips();
        $analytics.eventTrack('h5.viewActions', { category: 'h5.actions.view', label: alarmState.key });
    }

    /**
     * Builds an AlarmIssue actions menu descriptor.
     *
     * @param alarmInfo A com.vmware.vim.binding.vim.alarm.AlarmInfo object describing an alarm
     *
     * @param alarmState A com.vmware.vim.binding.vim.alarm.AlarmState object describing an alarm state .
     *
     * @return {object} representing an AlarmIssue actions menu descriptor.
     */
    function buildMenuDescriptor(alarmInfo, alarmState) {
        var menuItemsDescriptors = buildAlarmActions(alarmInfo, alarmState);

        var actionsText = i18nService.getString('Common', 'contextMenu.title.actions');
        var menuHeaderIcon = getAlarmOverallStatusIconClass(alarmState);
        var menuHeaderText = actionsText + ' - ' + alarmInfo.name;

        var menuDescriptor = {
            label: menuHeaderText,
            iconClass: menuHeaderIcon,
            items: menuItemsDescriptors
        };

        return menuDescriptor;
    }

    /**
     * Builds a list of alarm actions (acknowledge, reset to green...) including their label and availability
     *
     * @param alarmInfo A com.vmware.vim.binding.vim.alarm.AlarmInfo object describing an alarm
     *
     * @param alarmState A com.vmware.vim.binding.vim.alarm.AlarmState object describing an alarm state .
     *
     * @return {object} representing an AlarmIssue actions menu descriptor.
     */
    function buildAlarmActions(alarmInfo, alarmState){
        var actionsMenuContext = {
            actionUidToMenuItemDescriptor: { },
            actionUidToEvaluatedAvailability: { },
            pendingAction: null
        };

        var menuItemsDescriptors = [];
        _.forEach(ACTIONS_UIDS, function(actionUid) {
            var actionDescriptor = ACTION_UID_TO_DESCRIPTOR[actionUid];

            var menuItemDescriptor = buildMenuItemDescriptor(
               actionDescriptor, alarmInfo, alarmState, actionsMenuContext
            );
            menuItemsDescriptors.push(menuItemDescriptor);
            actionsMenuContext.actionUidToMenuItemDescriptor[actionUid] = menuItemDescriptor;
        });

        evaluateActionsAvailability(alarmInfo, alarmState, actionsMenuContext);

        return menuItemsDescriptors;
    }

    function buildMenuItemDescriptor(actionDescriptor, alarmInfo, alarmState, actionsMenuContext) {
        return {
            enabled: true,
            iconClass: actionDescriptor.iconClass,
            id: actionDescriptor.actionUid,
            label: actionDescriptor.label,
            onClick: function(eventInfo) {
                return invokeAction(alarmState, actionsMenuContext, actionDescriptor.actionUid);
            }
        };
    }

    // vuiActionMenu does not support tooltips like the docs say
    function setMenuTooltips() {
        $('.k-menu.k-menu-vertical.k-context-menu span.k-link').each(function (index, menuItem) {
            // Set title attribute only if the contents of the menu item will overflow.
            if (menuItem.offsetWidth < menuItem.scrollWidth) {
                var $menuItem = $(menuItem);
                $menuItem.attr('title', $menuItem.text());
            }
        });
    }

    /**
     * Given a com.vmware.vim.binding.vim.alarm.AlarmState object describing the state of an alarm
     * returns the CSS icon class to use for that alarm.
     *
     * @param alarmState object describing the state of the alarm
     * @returns {string} the name of icon class to use
     */
    function getAlarmOverallStatusIconClass(alarmState) {
        switch (alarmState.overallStatus) {
            case 'green':
                return 'vsphere-icon-status-ok';
            case 'yellow':
                return 'vsphere-icon-status-warning';
            case 'red':
                return 'vsphere-icon-status-error';
            case 'gray':
                return 'vui-icon-datagrid-status-unknown';
            default :
                return 'vui-icon-datagrid-status-unknown';
        }
    }

    function evaluateActionsAvailability(alarmInfo, alarmState, actionsMenuContext) {
        var privilegesToCheck = [];

        _.forEach(ACTIONS_UIDS, function(actionUid) {
            var actionDescriptor = ACTION_UID_TO_DESCRIPTOR[actionUid];

            var isActionAvailable = true;
            if (typeof actionDescriptor.availabilityPreCheck === 'function') {
                isActionAvailable = actionDescriptor.availabilityPreCheck(alarmInfo, alarmState);
            }

            if (isActionAvailable) {
                if (_.isEmpty(actionDescriptor.requiredPrivileges)) {
                    actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] = true;
                    actionsMenuContext.actionUidToMenuItemDescriptor[actionUid].enabled = true;
                } else {
                    Array.prototype.push.apply(privilegesToCheck, actionDescriptor.requiredPrivileges);
                }
            } else {
                actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] = false;
                actionsMenuContext.actionUidToMenuItemDescriptor[actionUid].enabled = false;
            }
        });

        privilegesToCheck = _.unique(privilegesToCheck, false);
        if (_.isEmpty(privilegesToCheck)) {
            return;
        }

        authorizationService.checkPrivileges(
            defaultUriSchemeUtil.getVsphereObjectId(alarmState.entity), privilegesToCheck
        ).then(function(privilegesStatuses) {
            var privilegeToStatus = _.object(privilegesToCheck, privilegesStatuses);
            var actionDescriptor;

            for (var actionUid in ACTION_UID_TO_DESCRIPTOR) {
                if (!ACTION_UID_TO_DESCRIPTOR.hasOwnProperty(actionUid)) {
                    continue;
                }

                actionDescriptor = ACTION_UID_TO_DESCRIPTOR[actionUid];
                if (typeof actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] === 'boolean') {
                    continue;
                }

                /*jshint loopfunc: true */
                var actionAvailable = _.every(actionDescriptor.requiredPrivileges, function(requiredPrivilege) {
                    return privilegeToStatus[requiredPrivilege];
                });
                /*jshint loopfunc: false */

                actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] = actionAvailable;
                actionsMenuContext.actionUidToMenuItemDescriptor[actionUid].enabled = actionAvailable;
            }

            if (actionsMenuContext.pendingAction !== null) {
                actionDescriptor = ACTION_UID_TO_DESCRIPTOR[actionsMenuContext.pendingAction.uid];
                var deferred = actionsMenuContext.pendingAction.deferred;
                invokeAction(alarmState, actionsMenuContext, actionDescriptor.actionUid).then(function () {
                    deferred.resolve();
                });
            }
        });
    }

    function invokeAction(alarmState, actionsMenuContext, actionUid) {
        actionsMenuContext.pendingAction = null;

        if (typeof actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] === 'undefined') {
            var deferred = $q.defer();
            actionsMenuContext.pendingAction = {uid: actionUid, deferred: deferred};
            return deferred.promise;
        }

        if (actionsMenuContext.actionUidToEvaluatedAvailability[actionUid] === false) {
            showActionNotAvailableDialog(actionUid);
            return $q.reject();
        }

        return h5.actions[actionUid](null, null, alarmAndEntityContext(alarmState));
    }

    function alarmAndEntityContext(alarmState){
        return {
            alarm: defaultUriSchemeUtil.extractManagedObjectReference(alarmState.alarm),
            entity: alarmState.entity,
            entityId: defaultUriSchemeUtil.getVsphereObjectId(alarmState.entity)
        };
    }

    function showActionNotAvailableDialog(actionUid) {
        //TODO amarinov: implement action not available dialog as in actionsService
        //alert('Action \'' + ACTION_UID_TO_DESCRIPTOR[actionUid].label + '\' is not available!');
    }

    return {
        showActionsMenu: showActionsMenu,
        buildAlarmActions: buildAlarmActions
    };
}]);
