/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The categories list reference of an object navigator controller.
 * renders the home, hosts and clusters, VMs and templates, storage, etc.. list
 */
angular.module('com.vmware.platform.ui')
      .controller('CategoryNodeController', CategoryNodeController);

CategoryNodeController.$inject = ['$scope', 'objectNavigatorService', '$location',
      'objectNavigatorAccessibilityServiceFactory', '$element'];

function CategoryNodeController(
   $scope, objectNavigatorService, $location, objectNavigatorAccessibilityServiceFactory, $element) {
   var objNavi = $scope.objectnavigator;
   objectNavigatorAccessibilityServiceFactory.create($element.get(0), $scope);

   // init case
   getObjectNavigatorView($scope._view.viewDetails.context);
   $scope.$on('dataRefreshInvocationEvent', function () {
      getObjectNavigatorView($scope._view.viewDetails.context);
   });

   $scope.$on('vxRouteChangeSuccess', function (evt, tree, route, previousRoute) {
      if (route.extensionId === previousRoute.extensionId
            && route.objectId === previousRoute.objectId) {
         return;
      }

      objectNavigatorService
            .getNaviData(route.extensionId, route.objectId, route.navigator, previousRoute.navigator)
            .then(function (data) {
               if (data && data.viewState && data.viewState.selectedNodeId) {
                  $scope.selectedUid = data.viewState.selectedNodeId;
               }
            });
   });

   function getObjectNavigatorView(node) {
      var nodeId = node ? node.uid : '';
      if (node.viewState && node.viewState.selectedNodeId) {
         $scope.selectedUid = node.viewState.selectedNodeId;
      }

      objectNavigatorService.getView(nodeId).then(function (node) {
         angular.extend($scope, {
            topNode: node,
            node: node
         });
      });
   }

   function traverse(node, matchedEntries, level) {
      node.level = level;
      if (node.nodeObjectType) {
         matchedEntries.push(node);
      }
      _.each(node.children, function (child) {
         traverse(child, matchedEntries, level + 1);
      });
   }

   $scope.isItemSelected = function (node) {
      return node.uid === $scope.selectedUid || ( !$scope.selectedUid && $scope.topNode.children[0].children[0] === node);
   };

   $scope.selectItem = function (node) {
      if (!node.navigationTargetUid) {
         return;
      }

      if (!node.viewOpenedUponFocus) {
         $scope.selectedUid = node.uid;
         var context = $scope._view.viewDetails.context;
         if(context && context.viewState && context.viewState.selectedNodeId){
            context.viewState.selectedNodeId = node.uid;
         }
         $scope._navigate(node.navigationTargetUid, null);
      } else {
         objNavi.switchView({
            url: node.viewOpenedUponFocus.url,
            context: node.viewOpenedUponFocus,
            historyDescription: {
               icon: node.icon,
               title: node.title
            },
            navigate: angular.extend({
               extensionId: node.navigationTargetUid
            })
         });
      }
   };
}
