/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   /***
    * The class gets and sets vx grid setting
    */
   export class VxGridSettingsService {
      private selectedSettings = [];

      constructor() {
      };

      public getSelectedSettings() {
         return this.selectedSettings;
      }

      public setSelectedSettings(setting) {
         this.selectedSettings = setting;
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("vxGridSettingsService", VxGridSettingsService);
}
