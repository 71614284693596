/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Renders a summary of issues view. Usage:
 *  <div vx-issues vx-object-id="" vx-issues-extension-id=""/>
 */

/**
 * @desc
 * @example <div vx-issues vx-object-id="urn:..."
 *             vx-issues-extension-id="extIdtoNaviga">
 *          </div>
 */
(function () {
   angular
      .module('com.vmware.platform.ui')
      .directive('vxIssuesList', issuesList);

   function issuesList() {
      return {
         restrict: 'EA',
         templateUrl: 'resources/ui/views/summary/vxIssuesListTemplate.html',
         scope: {
            objectId: '@vxObjectId', // Object id needed to fetch issues and for navigation
            issuesExtensionId: '@vxIssuesExtensionId' // the extension to navigate to on click of ShowAll
         },
         controller: 'IssuesListController',
         controllerAs: 'issuesListCtrl',
         bindToController: true
      };
   }

}());
