/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.platform.ui')
         .service('simpleValidationCommit', simpleValidationCommit);

   simpleValidationCommit.$inject = [
      '$q',
      'vuiConstants'
   ];

   /**
    * Provides a basic onCommit implementation for wizard pages that has
    * the sole responsibility of showing validation messages in case of
    * invalid user input. It takes care of:
    *    - wizardConfig.loading
    *    - marking the page completed or incomplete
    *    - determining whether the user is allowed to proceed to the next page
    * in case the validation complete with errors, validation complete without
    * errors or the validation promise is rejected
    *
    *
    * For more see simpleValidationCommitSpec.
    *
    * @param validationFunction a function that returns an array of the
    *    error messages to be shown
    * @param wizardConfig the wizard config to be manipulated based on the
    *    validationFunction output.
    */
   function simpleValidationCommit($q, vuiConstants) {

      var commitCallback = function (validationFunction, wizardConfig) {
         return function () {
            return $q.when(validationFunction()).then(function (result) {
               if (!_.isArray(result) && !_.isBoolean(result)) {
                  throw Error('validationFunction must return an array or boolean' +
                        ' the promise resolved with array or boolean');
               }
               if (_.isArray(result)) {
                  var errors = result;
                  if (errors.length > 0) {
                     wizardConfig.currentPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
                     wizardConfig.validationBanner.messages = _.map(errors, function (message) {
                        return {
                           text: message,
                           type: vuiConstants.validationBanner.type.ERROR
                        };
                     });
                     return false;
                  } else {
                     wizardConfig.currentPage.state = vuiConstants.wizard.pageState.COMPLETED;
                     return true;
                  }
               }
               else if (_.isBoolean(result)) {
                  if (result) {
                     wizardConfig.currentPage.state = vuiConstants.wizard.pageState.COMPLETED;
                  } else {
                     wizardConfig.currentPage.state = vuiConstants.wizard.pageState.INCOMPLETE;
                  }
                  return result;
               }
            }, function () {
               wizardConfig.currentPage.state = vuiConstants.wizard.pageState.COMPLETED;
               return true;
            }).finally(function () {
               wizardConfig.loading = false;
            });
         };
      };
      return commitCallback;
   }
})();
