/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   export class ListViewDropTargetHandler {
      private _dragAndDropManager: any;

      public constructor(dragAndDropManager: any) {
         this._dragAndDropManager = dragAndDropManager;
      }

      public drop($event: any): void {
         let sourceDataItems: any[] = $event.draggable.currentTarget.itemsData;

         let targetRowElement = $($event.target).closest("tr");
         let targetKendoGrid: any = $($event.dropTarget).data("kendoGrid");
         let targetDataItem: any = targetKendoGrid.dataItem(targetRowElement);

         this._dragAndDropManager.invokeAction(sourceDataItems, targetDataItem);
         $event.preventDefault();
      }
   }

   angular.module("com.vmware.platform.ui")
         .factory("listViewDropTargetHandler", ["dragAndDropManager",
            function (dragAndDropManager) {
               return new ListViewDropTargetHandler(dragAndDropManager);
            }]);
}
