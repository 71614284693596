/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Url routing.
 *
 * Supports nested <vx-view> directive backed by a tree data structure.
 *
 * Originally inspired by ui-router.
 *
 */
module platform {

    import {ILocationService} from "angular";
    import IPromise = angular.IPromise;
    import TelemetryTimeTrackerFactory = platform.TelemetryTimeTrackerFactory;

    "use strict";

    declare var h5: any;

    interface Route {
        extensionId: string | null;
        objectId: string | null;
        relatedItem?: string | undefined;
        navigator?: string;
        query?: string;
        searchType?: string;
        forceNavigate?: boolean;
        _frontPage?: boolean;
        _navigationParam?: string | null;
        getAdditionalParams(nav: any): any;
    }

    interface Metadata {
        showRelationsFor: string;
        fetchRelatedAsChildren: boolean;
    }

    interface ContentSpec {
        url: string;
        showVCenterSelector: boolean;
        legacyScriptPlugin: boolean;
        sandbox: boolean;
        viewRetentionPolicy: string;
        metadata: Metadata;
        uid: string;
    }

    interface ExtensionObject {
        name: string;
        icon: string;
        hostsMultipleViewsSimultaneously: boolean;
        contentSpec: ContentSpec;
        categoryUid: string;
        applyDefaultChrome: boolean;
        uid: string;
    }

    interface Tree {
        $childIndexToActivate: number;
        $id: string;
        $selectedChildIndex: number;
        $templateUrl: string;
        $children: Tree[];
        extensionObject: ExtensionObject;
        parent: Tree;
        contentSpec: ContentSpec;
        uid: string;
        getSelectedLeafNode(): Tree;
        getById(extensionId: string | null): Tree;
    }

    export class Navigation {
        static $inject = [
                '$rootScope',
                '$location',
                '$http',
                '$templateCache',
                '$q',
                '$log',
                'navigationTreeService',
                'navigationPreferenceService',
                'navigatorRelationsService',
                'browserHistoryService',
                'urlService',
                'telemetryTimeTrackerFactory',
                'navigationConstants'
            ];
        private extensionId: string | null;
        private objectId: string | null;
        private relatedItem: string | undefined;

        // this is a huge HACK to detect self navigation vs back/forward button
        // proper fix is at https://github.com/angular/angular.js/issues/4059
        private _navigationParam: string | null;
        private tree: Tree;
        private route: Route = <Route>{};
        private previousRoute: Route = <Route>{};
        private telemetryTimeTracker: TimeTracker | null ;

        // Number of times to retry while navigating to an invalid extension.
        // Used to avoid endless loops when there's an actual server error and not
        // an invalid extension id.
        private retries: number;

        private offLocationChangeSuccess: any = null;

        constructor(private $rootScope: ng.IRootScopeService,
                    private $location: ng.ILocationService,
                    private $http: ng.IHttpService,
                    private $templateCache: ng.ITemplateCacheService,
                    private $q: ng.IQService,
                    private $log: any,
                    private navigationTreeService: any,
                    private navigationPreferenceService: any,
                    private navigatorRelationsService: NavigatorRelationsService,
                    private browserHistoryService: any,
                    private urlService: any,
                    private telemetryTimeTrackerFactory: TelemetryTimeTrackerFactory,
                    private navigationConstants: any) {
           this.retries = 0;
        }

        getTree(): Tree {
            return this.tree;
        }

        getRoute(): Route {
            return this.route;
        }

        getPreviousRoute(): Route {
            return this.previousRoute;
        }

        populateScope(scope: ng.IScope): void {
            const self = this;
            angular.extend(scope, {
                _route: self.route,
                _navigate: self.navigate.bind(self),
                _navigateToObject: self.navigateToObject.bind(self),
                _navigateToViewAndObject: self.navigateToViewAndObject.bind(self),
                _navigateToView: self.navigateToView.bind(self)
            });
        }

        fetch(searchParam: Route, options?: { cancellable?: boolean }): IPromise<Tree> {
            // If this call is coming because of navigate(), we might have already
            // got the tree for this extensionId and objectId if extensionId is 'serverObjectView'.
            if (this.tree
                && this.extensionId && this.extensionId === searchParam.extensionId
                && this.objectId && this.objectId === searchParam.objectId
                && this.relatedItem === searchParam.relatedItem) {
                // Cancel previous request to avoid late http resolve with the wrong data.
                this.navigationTreeService.cancelPrevious();
                // Reuse once is enough since we want to deal with only back-to-back fetch() calls only.
                this.extensionId = null;
                this.objectId = null;
                this.relatedItem = undefined;
                return this.$q.resolve(this.tree);
            }

            // Optimization added that when the user is on the search view and the search view is requested again,
            // send the same navigation tree. The navigationTree for search view is constant and does not depend on the
            // query or any dynamic user input.
            if (this.tree && this.extensionId && this.extensionId === searchParam.extensionId
                && this.extensionId === this.navigationConstants.SEARCH_VIEW_EXTENSION_ID) {
                return this.$q.resolve(this.tree);
            }

            const cancellable: boolean = !(options && options.cancellable === false);

            return this.navigationTreeService
                .fetchNavigationTree(searchParam, cancellable).then((tree: Tree) => {
                    this.tree = tree;
                    this.extensionId = searchParam.extensionId;
                    this.objectId = searchParam.objectId;
                    this.relatedItem = searchParam.relatedItem || undefined;
                    return tree;
                }, (err) => {
                    return this.$q.reject(err);
                });
        }

        getRouteFromUrl(): Route {
            const search: Route = this.$location.search();
            if (search.objectId === 'null') {
                search.objectId = null;
            }

            const route: Route = angular.copy(search);

            if (!search.extensionId && !search.objectId && !search.navigator) {
                // front page
                // Create an empty route
                route.extensionId = null;
                route.objectId = null;
                route._frontPage = true;
            } else if (!search.extensionId && !search.navigator && search.objectId) {
                // uses a default extensionId if only objectId is specified
                // I'm not sure if we actually need this TBO
                route.extensionId = h5.ext;
            }

            // helper function to get additional query params from the route or from a passed argument
            // checks through a list of params and returns the rest
            route.getAdditionalParams = function (nav) {
                const r = (nav === undefined) ? this : nav;
                const queryParams = {};
                const PARAMS = ['extensionId', 'objectId', '_navigationParam'];
                for (let key in r) {
                    if (PARAMS.indexOf(key) === -1 && !angular.isFunction(r[key])) {
                        queryParams[key] = r[key];
                    }
                }
                return queryParams;
            };

            return route;
        }

        navigateToServerObject(searchParam: Route, navParam: string): void {
            this.applyUserRoutePreferences(searchParam)
                .then((route: Route) => {
                    return this.fetch(route);
                })
                .then((tree) => {
                    if (searchParam.extensionId !== this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID) {
                        // Navigating to non default extension. Check extension availability.
                        const node = tree.getById(searchParam.extensionId);
                        if (!node || !node.parent || node.parent.$childIndexToActivate < 0) {
                            // The object either has no node with this extension or other node should be activated.
                            // Navigate to nodeToActivate extension
                            const nodeToActivate = this.tree.getSelectedLeafNode();
                            searchParam.extensionId = nodeToActivate.$id;
                        }
                    }

                    this.retries = 0;
                    this._navigationParam = navParam;

                    const location: Route = this.$location.search();
                    const search: ILocationService = this.$location.search(searchParam);
                    // Caution, the following should be regarded as a hack/patch. It
                    // handles a couple of very specific cases that occur rarely, but
                    // lead to some weird behavior, e.g. the browser history buttons
                    // not working properly. A lot of issues related to browser
                    // navigation are caused by the fact that 'navigation' in the H5
                    // client does not necessarily correspond to a single URL change,
                    // in fact there are cases where more than two calls to
                    // $location.search() are made. Note that a call to search() without
                    // replace() causes a new history record to be written. If you ever
                    // come across issues with browser navigation, watch out for multiple
                    // calls to $location.search().
                    //
                    // Without further ado, the special cases are the following:
                    // 1) The previous call to $location.search() was made as a result
                    // of handling an inventory tree event (in that case the extensionId
                    // is a vi tree id).
                    // 2) The only modified URL parameter is 'navigator' with the
                    // additional restriction that the previous value was the bogus
                    // 'tree' and the new value is one of the tree IDs. For example this
                    // can happen when navigating using hyperlinks and the navigator is
                    // changed from object selector to inventory tree.
                    if (location
                            && ((location.extensionId
                                    && _.contains(this.navigationConstants.NAVIGATOR_IDS,
                                            location.extensionId))
                                || (location.extensionId === searchParam.extensionId
                                    && location.objectId === searchParam.objectId
                                    && location.navigator === "tree"
                                    && _.contains(this.navigationConstants.NAVIGATOR_IDS,
                                            searchParam.navigator)))) {
                        search.replace();
                    }
                },
                (err) => {
                    if (this.retries >= this.navigationConstants.RETRIES_THRESHOLD) {
                        this.retries = 0;
                        return this.$q.reject(err);
                    }
                    if (err.status !== 500) {
                        return this.$q.reject(err);
                    }
                    this.retries++;
                    // We failed to retrieve the extension id. Clear the invalid extension id in
                    // the local storage and navigate the user to the first object tab.
                    this.navigationPreferenceService.invalidate(searchParam.objectId);
                    this.navigate(this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID, searchParam.objectId);
                    return this.$q.reject(err);
                });
        }

        navigateToRelatedObject(objectId: string, relation: string, relatedItemUri: string): void {
            const searchParam: Route = angular.extend({}, {
                extensionId: this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID,
                objectId: objectId
            });

            this.navigatorRelationsService.setRelationContext(relation, relatedItemUri);

            this.$location.search(searchParam);
        }

        navigate(extensionId: string, objectId: string | null, arg?: any, _navigationParam?: any): void {
            //all navigation does is set the url in the correct format so onLocationChange can read it back

            _navigationParam = _navigationParam || this._navigationParam || true;

            arg = arg || {};
            // special case for related item
            if (arg.relatedItemParentId) {
                arg.relatedItem = extensionId;
                extensionId = arg.relatedItemParentId;
                delete arg.relatedItemParentId;
            }

            const searchParam: Route = angular.extend({
                extensionId: extensionId,
                objectId: objectId
            }, arg);

            const currView: Route = this.$location.search();

            if (angular.equals(currView, searchParam)
                || (searchParam.extensionId === this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID
                    && searchParam.objectId === currView.objectId
                    && searchParam.navigator === currView.navigator)) {
                return;
            }

            // start time counter for the current navigation request
            this.telemetryTimeTracker.startTimeTracking();

            if (searchParam.objectId) {
                this.navigateToServerObject(searchParam, _navigationParam);
            } else {
                this._navigationParam = _navigationParam; //see note at variable declaration
                this.$location.search(searchParam);
                this.telemetryTimeTracker.stopTimeTracking();
            }
        }

        init(): void {
           this.route = angular.copy(this.getRouteFromUrl());
           this.populateScope(this.$rootScope);
           this.telemetryTimeTracker = this.telemetryTimeTrackerFactory.createTelemetryTracker(
              this.navigationConstants.NAVIGATION_REQUEST_DURATION_MEDIAN,
              this.navigationConstants.NAVIGATION_REQUEST_BUFFER_TIME_PROP);
           this.route = angular.copy(this.getRouteFromUrl());
           this.populateScope(this.$rootScope);
        }

        doInitialRouting(): IPromise<void> {
            const applyUserPreferencesIfNeeded = this.route._frontPage ?
                  this.applyUserRoutePreferences(this.route) :
                  this.$q.when(this.route);
            return applyUserPreferencesIfNeeded.then((route: Route) => {
                if (route._frontPage) {
                    this.$location.search({
                        extensionId: route.extensionId || this.navigationConstants.DEFAULT_CLIENT_LOCATION
                    }).replace();
                }

                return this.onLocationChange({ cancellable: false, forceNavigate: true }).then(() => {
                    // Subscribe for $locationChangeSuccess here so that
                    // onLocationChange() does not get fired twice when initially loading
                    // the client where we change the browser location through
                    // this.$location.search() call.
                    this.subscribeForLocationChange();
                }, (err) => {
                    this.$log.error(err);
                    this.subscribeForLocationChange();
                });
            });
        }

        navigateToObject(objectId: string, context: any): void {
            const finalContext = angular.extend({navigator: "tree"}, context);
            this.navigate(this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID, objectId, finalContext, {triggerONChange: true});
        }

        navigateToViewAndObject(extensionId: string, objectId: string, context: any): void {
            const finalContext = angular.extend({navigator: "tree"}, context);
            this.navigate(extensionId, objectId, finalContext, {triggerONChange: true});
        }

        navigateToView(extensionId: string, context?: any): void {
            this.navigate(extensionId, null, context, {triggerONChange: true});
        }

        shouldReload(node, previousNode, route: Route, previousRoute: Route) {
            return (route.objectId !== previousRoute.objectId);
        }

        private onLocationChange(options?: { cancellable?: boolean, forceNavigate?: boolean }): IPromise<void> {
            this.telemetryTimeTracker.stopTimeTracking();
            const route = this.getRouteFromUrl();
            const cancellable: boolean = !(options && options.cancellable === false);
            if (options && options.forceNavigate === true) {
                route.forceNavigate = true;
            }

            //TODO: corner case:
            // Eg: navigate to host view (which shows the host summary tab), then navigate to host summary tab
            // two possible approaches:
            // 1. if we navigate to a middle of the tree, we might want to replace the url with the leaf extId
            // 2. OR, fetch on navigate, if returned tree is same, then do nothing.

            const promise = this.fetch(route, { cancellable: cancellable }).then((tree) => {
                return this.handleRelatedItemLeafNode(tree, route);
            }, (err) => {
                if (this.retries >= this.navigationConstants.RETRIES_THRESHOLD) {
                    this.retries = 0;
                    return this.$q.reject(err);
                }
                if (err.status !== 500) {
                    return this.$q.reject(err);
                }
                this.retries++;
                const newRoute = this.$location.search();
                // We failed to retrieve the extension id. Clear the invalid extension id in
                // the local storage and navigate the user to the first object tab.
                if (newRoute.objectId) {
                    newRoute.extensionId = this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID;
                    this.navigationPreferenceService.invalidate(route.objectId);
                } else {
                    newRoute.extensionId = this.navigationConstants.HOME_EXTENSION_ID;
                }
                // Conditionally subscribe for location change in case this method was
                // called directly from the init() method. This is the case where user
                // opens the browser and navigates to an invalid URL or to an invalid
                // bookmark or there is invalid URL in the local storage.
                this.subscribeForLocationChange();
                this.$location.search(newRoute).replace();
                return this.$q.reject(err);
            });

            route._navigationParam = this._navigationParam;
            this._navigationParam = null; // see note above
            return promise.then((tree: Tree): IPromise<any> => {
                this.browserHistoryService.setBrowserTitle(tree, route.objectId, route.query);
                if (tree.constructor !== NavigationTree) {
                    throw 'tree needs to be of type NavigationTreeModel';
                }
                this.tree = tree;
                angular.copy(this.route, this.previousRoute);
                angular.copy(route, this.route);
                return this.precacheTemplates(this.tree).then(
                   () => {
                       this.preCacheTemplateCallback(route);
                    }, (err) => {
                       this.$log.error("Failed to retrieve one or more html templates." + err);
                       this.preCacheTemplateCallback(route);
                    });
            });
        }

        // load all html templates to reduce flickering
        // this fetches the navi path htmls + sibling htmls.
        // We could stop fetching siblings if this is too aggressive.
        private precacheTemplates(tree: any): IPromise<any> {
            const promises = <any>[];
            tree.everyNode((node) => {
                // url used in iframe src is not a template so no request should be sent
                const iframeRelated: boolean =
                        node.contentSpec && node.contentSpec.sandbox;
                if (node.$templateUrl && !iframeRelated) {
                    promises.push(this.$http.get(node.$templateUrl, {cache: this.$templateCache}));
                }
            });

            return this.$q.all(promises);
        }

       private preCacheTemplateCallback(route: Route) {
          if (route.extensionId
             && route.extensionId !== this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID) {
             this.navigationPreferenceService.persistLastExtension(
                route.objectId,
                route.extensionId,
                route.relatedItem,
                route.navigator);
          }
          // this is to trigger all existing <vx-view> to tell them to update themselves
          this.$rootScope.$broadcast('vxRouteChangeSuccess', this.tree, this.route,
             this.previousRoute);
       }

        private subscribeForLocationChange(): void {
            if (!this.offLocationChangeSuccess) {
                this.offLocationChangeSuccess =
                    this.$rootScope.$on('$locationChangeSuccess',
                        (event: any, newUrl: string, oldUrl: string) => {
                            // Don't handle URL changes if only the list view selection
                            // parameter was changed (we're not really changing location
                            // in this case).
                            newUrl = this.urlService.removeParameter(
                                newUrl, h5.listViewSelectedItemIdProperty);
                            oldUrl = this.urlService.removeParameter(
                                oldUrl, h5.listViewSelectedItemIdProperty);
                            if (newUrl !== oldUrl) {
                                this.onLocationChange();
                            }
                        });
            }
        }

        // code to handle relatedItems as they come from a different endpoint
        // if the leaf node has related items as children, we fetch from a different endpoint
        // and add the results as its children into the tree
        private handleRelatedItemLeafNode(tree: Tree, route: Route): IPromise<Tree> {
            const leaf = tree.getSelectedLeafNode();
            if (!(leaf.extensionObject &&
                leaf.extensionObject.contentSpec &&
                leaf.extensionObject.contentSpec.metadata &&
                (leaf.extensionObject.contentSpec.metadata.fetchRelatedAsChildren ||
                leaf.extensionObject.contentSpec.metadata.showRelationsFor))) {
                return this.$q.resolve(tree);
            }
            if (leaf.$children.length !== 0) {
                throw "Assumption violated: any extension with fetchRelatedAsChildren or " +
                "showRelationsFor set should not have any children as they come " +
                "from a different data structure";
            }
            let url;
            const params = <any>{
                relationsViewId: leaf.uid
            };
            if (leaf.extensionObject.contentSpec.metadata.fetchRelatedAsChildren) {
                url = 'relateditems/list/' + route.objectId;
            } else { //showRelationsFor is specified
                url = 'relateditems/listspec/' + leaf.extensionObject.contentSpec.metadata.showRelationsFor;
                params.onlyFavorites = false;
            }
            return this.$http({
                method: 'get',
                url: url,
                params: params
            }).then(function (resp) {
                const data: Tree[] = <Tree[]>resp.data;
                leaf.$children = data;
                leaf.$selectedChildIndex = data.length ? 0 : -1;

                for (let i = 0; i < data.length; i++) {
                    const relatedNode = <any>data[i];
                    const url = (relatedNode.listSpec && relatedNode.listSpec.contentSpec) ?
                        relatedNode.listSpec.contentSpec.url :
                        null;

                    angular.extend(relatedNode, {
                        $id: relatedNode.listSpec.uid,
                        $children: [],
                        $parent: leaf,
                        $selectedChildIndex: -1,
                        $templateUrl: url,
                        //$viewRetentionPolicy: node.extensionObject.contentSpec.viewRetentionPolicy //TODO: copy over this field
                        liveRefreshEnabled: true
                    });

                    // if url specified a related item to go to, set the index correctly
                    if (route.relatedItem === relatedNode.$id) {
                        leaf.$selectedChildIndex = i;
                    }
                }
                return tree;
            });
        }

        /**
         * Applies the user preferences for the route.
         *
         * @param searchParam the location
         * @returns {Function} future with applied modifications to the search param
         */
        private applyUserRoutePreferences(searchParam: Route): IPromise<Route> {
            const self = this;
            if (!searchParam.extensionId || this.navigationConstants.SERVER_OBJECT_VIEW_EXTENSION_ID === searchParam.extensionId) {
                // No extension specified for object - swap with the last extension the user navigated to
                return this.navigationPreferenceService.getLastExtension(searchParam.objectId)
                    .then(function (userSetting) {
                        if (userSetting && userSetting.extensionId) {
                            searchParam.extensionId = userSetting.extensionId;
                        }
                        if (userSetting && userSetting.relatedItem) {
                            searchParam.relatedItem = userSetting.relatedItem;
                        }
                        return searchParam;
                    }, (err) => {
                       self.$log.error("User preferences for the route not applied correctly." + err);
                    });
            }
            return this.$q.resolve(searchParam);
        }
    }


    angular.module("com.vmware.platform.ui").service("navigation", Navigation);
}
