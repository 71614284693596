namespace platform {
   class SearchCenterPaneController implements ng.IComponentController {
      static $inject: string[] = [
         "$rootScope",
         "vxViewUtil",
         "i18nService",
         "extensionFrameworkConstants"
      ];

      searchResponse: any;
      searchOperationResponse: any;
      viewNode: any;
      rootScopeDeregisterFns: Function[];

      constructor(private $rootScope: any,
                  private vxViewUtil: any,
                  public i18nService: any,
                  private extensionFrameworkConstants: any) {

         this.rootScopeDeregisterFns = [
            $rootScope.$on("refreshSearchResults", (evt: any, payload: any) => {
               this.searchResponse = null;
               setTimeout(() => this.handleSearchResponseChange(payload), 0);
            }),
            $rootScope.$on("searchOperationChanged", (evt: any, payload: any) => {
               this.searchOperationResponse = null;
               this.searchResponse.savedSearch = null;
               setTimeout(() => this.handleSearchOperationResponseChange(payload), 0);
            })
         ];
      }

      $onDestroy(): void {
         let deregister: Function;

         for (deregister of this.rootScopeDeregisterFns) {
            deregister();
         }
      }

      private isAllResultsType(): boolean {
         return this.searchResponse.selection.type === "ALL_RESULTS";
      }

      private hasNoResults(): boolean {
         return this.searchResponse.selection.count === 0;
      }

      private getViewNode(): any {
         return this.vxViewUtil.getNode({
            $templateUrl: this.searchResponse.selection.listUrl,
            $viewRetentionPolicy: this.extensionFrameworkConstants.ViewRetentionPolicy.NONE,
            title: this.searchResponse.selection.labelPlural,
            icon: this.searchResponse.selection.icon,
            filterId: "simpleSearchListFilterId",
            filterParams: this.searchResponse.selection.objectUids,
            dataModels: [ this.searchResponse.selection.type ],
            $id: this.searchResponse.selection.listUid,
            listSpec:  {
               contentSpec: {
                  metadata: this.searchResponse.selection.listMetadata
               }
            }
         });
      }

      private handleSearchResponseChange(searchResponse: any): void {
         this.searchResponse = searchResponse;
         this.searchOperationResponse = null;
         if (this.isAllResultsType() || this.hasNoResults()) {
            this.viewNode = null;
         } else {
            this.viewNode = this.getViewNode();
         }
      }

      private handleSearchOperationResponseChange(searchOperationResponse: any): void {
         if (!searchOperationResponse) {
            return;
         }

         this.searchOperationResponse = searchOperationResponse;
         this.searchResponse.savedSearch = searchOperationResponse.savedSearch;
      }
   }

   export class SearchCenterPaneComponent {
      controller: any;
      templateUrl: string;

      constructor() {
         this.controller = SearchCenterPaneController;
         this.templateUrl = "resources/ui/views/search/search-center-pane.html";
      }
   }

   angular.module("com.vmware.platform.ui")
         .component("searchCenterPane", new SearchCenterPaneComponent());
}
