/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */

/**
 * Directive which represents the Object Selector. The object selector got the same
 * behaviour as the Flex ObjectSelector v3. The selector contains 3 different tabs
 * (filter, browse, selected objects).
 *
 * 1) Filter tab is always shown, which means that the listTabConfig is always required.
 * 2) Browse tab shows the inventory tree and is displayed when there's an available
 * treeTabConfig and multipleSelect == false. If in treeTabConfig object a caption property
 * is passed, it will appear above the tree as caption label.
 * 3) Selected objects tab is shown when multipleSelect == true.
 *
 * Usage:
 *
 * var config = {
 *    contextObject: "some-context-object-id",
 *    multipleSelect: false,
 *    listTabConfig: {
 *       selectedTabIndex: 0, // tab index to be preselected after load
 *       label: "Filter",
 *       listConfig: [ {
 *          label: "Label",
 *          listViewId: "vsphere.core.host.list",
 *          dataModels: ["HostSystem"],
 *          filterId: "some-filter-id",
 *          filterParams: [ "obj-id-1", "obj-id-2", ... ],
 *          propertyParams: [ {      // array of ParamSpec. See com.vmware.vise.mvc.model.data.ParamSpec.java
 *             propertyName: "propertyName",
 *             parameterType: "java.lang.String",
 *             parameter: "foo"
 *          ] }
 *       } ]
 *    },
 *    treeTabConfig: {
 *      caption: "CaptionLabel",
 *      treeConfig: {
 *         treeId: "vsphere.core.physicalInventorySpec",
 *         rootObjRef: rootObjRef
 *      }
 *    },
 *    onSelectionChanged: function (newSelectedItems, oldSelectedItems) {
 *        ...
 *    },
 *    selectedObjects: [
 *       "some-id-1",
 *       "some-id-2",
 *       ...
 *    ]
 * };
 *
 * filterParams: the list of object ids will be provided as a 2nd argument to the
 * Java filter implementation. It's up to the user what to do with the provided object ids.
 * It's possible to use them as a way to exclude some of the objects from the filter.
 *
 * newSelectedItems & oldSelectedItems will be arrays of objects, i.e:
 * [{
 *       "id": "urn:vmomi:Folder:group-d1:bbb78699-6264-4232-87ac-01d55fc20e42",
 *       "icon": "vsphere-icon-vcenter",
 *       "name": "sof2-rila-5-13.eng.vmware.com",
 *       "rawData": {
 *         "text": "sof2-rila-5-13.eng.vmware.com",
 *         "spriteCssClass": "vsphere-icon-vcenter",
 *         "objRef": "urn:vmomi:Folder:group-d1:bbb78699-6264-4232-87ac-01d55fc20e42",
 *         "nodeTypeId": "VcRoot",
 *         "items": [],
 *         "index": 0,
 *         "selected": true
 *       }
 * },
 * { ... },
 * { ... }
 * ]
 *
 * <div vx-object-selector config="config"></div>
 *
 * If `filterId` is not provided, the object selector will fetch the relation id for
 * the ListView based on the provided `listViewId` and `dataModels[0]`.
 *
 * Samples:
 *
 * 1) '''Filter tab only'''
 *  1.1 '''One object type'''
 *    var config = {
 *          contextObject: "some-context-object-id",
 *          multipleSelect: false,
 *          listTabConfig: {
 *             label: "Filter",
 *             listConfig: [ {
 *                label: "Label",
 *                listViewId: "vsphere.core.host.list",
 *                dataModels: ["HostSystem"],
 *                filterId: "some-filter-id"
 *             } ]
 *          }
 *    };
 *
 *  1.2 '''Many object types'''
 *    var config = {
 *          contextObject: "some-context-object-id",
 *          multipleSelect: false,
 *          listTabConfig: {
 *             label: "Filter",
 *             listConfig: [
 *                {
 *                   label: "Label_1",
 *                   listViewId: "vsphere.core.host.list",
 *                   dataModels: ["HostSystem"],
 *                   filterId: "some-host-filter-id"
 *                },
 *                {
 *                   label: "Label_2",
 *                   listViewId: "vsphere.core.datastore.list",
 *                   dataModels: ["Datastore"],
 *                   filterId: "some-datastore-filter-id"
 *                }
 *             ]
 *          }
 *    };
 *
 * 2) '''Browse tab only'''
 *    var config = {
 *          contextObject: "some-context-object-id",
 *          multipleSelect: false,
 *          treeTabConfig: {
 *             caption: "CaptionLabel",
 *             treeConfig: {
 *                treeId: "vsphere.core.physicalInventorySpec",
 *                rootObjRef: rootObjRef
 *             }
 *          }
 *    };
 *
 * 3) '''Filter + Browse tab'''
 *    var config = {
 *          contextObject: "some-context-object-id",
 *          multipleSelect: false,
 *          listTabConfig: {
 *             label: "Filter",
 *             listConfig: [ {
 *                label: "Host",
 *                listViewId: "vsphere.core.host.list",
 *                dataModels: ["HostSystem"],
 *                filterId: "some-filter-id"
 *             } ]
 *          },
 *          treeTabConfig: {
 *             caption: "CaptionLabel",
 *             treeConfig: {
 *                treeId: "vsphere.core.physicalInventorySpec",
 *                rootObjRef: rootObjRef
 *             }
 *          }
 *    };
 *
 * 4) '''Filter + "Selected Objects" tabs (multiple selection)'''
 *    var config = {
 *          contextObject: "some-context-object-id",
 *          multipleSelect: true,
 *          listTabConfig: {
 *             label: "Filter",
 *             listConfig: [ {
 *                label: "Label",
 *                listViewId: "vsphere.core.host.list",
 *                dataModels: ["HostSystem"],
 *                filterId: "some-filter-id"
 *             } ]
 *          }
 *    };
 */
angular.module('com.vmware.platform.ui').directive('vxObjectSelector', ['i18nService', 'logService',
   'vuiConstants', '$timeout', '$http', '$q', 'defaultUriSchemeUtil',
   function(i18nService, logService, vuiConstants, $timeout, $http, $q, defaultUriSchemeUtil) {
      var log = logService('vxObjectSelector');
      var _itemsBeforeRemove = null;
      var _removeItemsInProgress = false;

      return {
         templateUrl: 'resources/ui/components/objectselector/vxObjectSelector.html',
         restrict: 'A',
         scope: {
            config: '='
         },
         link: linkFn
      };

      function linkFn($scope, element) {
         // mapping from listViewId -> array of selected objects
         var selectedItems = {};
         var configCallback = $scope.config.onSelectionChanged;
         // used only when multipleSelect is false
         $scope.currentSelection = [];

         $scope.primaryTabOptions = {
            hideWhenSingleTab: !$scope.multipleSelect,
            tabs: createPrimaryTabs($scope, element),
            tabType: vuiConstants.tabs.type.PRIMARY
         };

         $scope.config.multipleSelect = angular.isDefined($scope.config.multipleSelect) ?
               !!$scope.config.multipleSelect : false;

         $scope.config.selectedObjects = extractSelectedObjects($scope.config);

         $scope.$watch(function() {
            if ($scope.filterTabOptions && $scope.filterTabOptions.selectedTabIndex) {
               return $scope.filterTabOptions.selectedTabIndex;
            }
         }, function(newItem, oldItem) {
            if (newItem === oldItem) {
               return;
            }
            $timeout(function() {
               var grid = getGrid(element, $scope.filterTabOptions.tabs
                     [$scope.filterTabOptions.selectedTabIndex].listViewId);
               if (!grid) {
                  return;
               }
               var kendoGrid = grid.data('kendoGrid');
               if (!kendoGrid) {
                  return;
               }
               kendoGrid.resize(true);
            }, 0);
         });

         if ($scope.config.treeTabConfig) {
            if ($scope.config.multipleSelect) {
               throw new Error('Configuration error: tree is not supported in multipleSelect mode');
            }

            $scope.treeConfig = $scope.config.treeTabConfig.treeConfig || {};

            angular.extend($scope.treeConfig, {
               preselectRoot: false
            });

            $scope.onTreeSelectionChanged = function(item) {
               var old = $scope.currentSelection;
               $scope.currentSelection = [formatItem(item)];

               if (old.length &&
                     _.isEqual(_.values(selectedItems)[0], old)) {

                  var deselectListViewId = _.keys(selectedItems)[0];
                  deselectAllFromList(element, deselectListViewId);
                  delete selectedItems[deselectListViewId];
               }

               triggerConfigCallback(configCallback, $scope.currentSelection, old);
            };

            if ($scope.config.selectedObjects.length) {
               $scope.treePreselectedNode = $scope.config.selectedObjects[0];
               var activeTabIndex = _.findIndex($scope.primaryTabOptions.tabs, {
                  label: i18nService.getString('CommonUi',
                        'objectSelector.navigationTabTitle')
               });
               $scope.primaryTabOptions.selectedTabIndex = activeTabIndex !== -1 ?
                     activeTabIndex : 0;
            }
         }

         if ($scope.config.listTabConfig) {
            var configTabIndex = $scope.config.listTabConfig.selectedTabIndex;
            var selectedTabIndex = angular.isDefined(configTabIndex) ? configTabIndex : 0;
            var listConfig = $scope.config.listTabConfig.listConfig;
            if (!isValidListConfig(listConfig)) {
               return;
            }

            if ($scope.config.selectedObjects.length && !$scope.config.treeTabConfig) {
               // Clone the array since the onSelectionChange callback is invoked
               // in-between the loading of the two tabs and overrides the original array.
               var selectedObjects = _.clone($scope.config.selectedObjects);

               var preselectComparator = function(item) {
                  if (!item) {
                     return false;
                  }
                  return selectedObjects.indexOf(item.id) > -1;
               };
               _.each(listConfig, function(conf) {
                  conf.preselectComparator = preselectComparator;
               });
            }

            createFilterTabs(listConfig, $scope).then(function(tabs) {
               var contextObject = $scope.config.contextObject;
               var selectionMode = $scope.config.multipleSelect ?
                     vuiConstants.grid.selectionMode.MULTI :
                     vuiConstants.grid.selectionMode.SINGLE;
               var numConfigs = listConfig.length;
               // Initialize watchers for reloadable tabs.
               var watchExpressionFunctions = _.flatten(_.map(listConfig, function(config) {
                  return [
                     function() {
                        return config.propertyParams;
                     }, function() {
                        return config.filterId;
                     }
                  ];
               }));
               $scope.$watchGroup(watchExpressionFunctions, function(newValues, oldValues) {
                  _.each(_.range(numConfigs), function(tabIndex) {
                     var tabPropertyParamsValueIndex = tabIndex * 2;
                     var tabFilterIdValueIndex = tabPropertyParamsValueIndex + 1;
                     if (newValues[tabPropertyParamsValueIndex] !== oldValues[tabPropertyParamsValueIndex] ||
                           newValues[tabFilterIdValueIndex] !== oldValues[tabFilterIdValueIndex]) {
                        reloadTab(tabIndex);
                     }
                  });
               });
               initializeFilterTabs(tabs);

               function reloadTab(idx) {
                  // Deselect and reload inside a timeout to avoid nested $digest.
                  $timeout(function reload() {
                     var deselectListViewId = listConfig[idx].listViewId;
                     delete selectedItems[deselectListViewId];

                     // Update the Selected Objects tab to ensure that no weird
                     // selection behaviour occurs after recreating the filter tab.
                     if ($scope.config.multipleSelect) {
                        var selectedObjTab = $scope.primaryTabOptions.tabs[1];
                        updateSelectedObjectsTab(element, selectedItems, selectedObjTab);
                     }

                     // Deselect the items in the tab before recreating it to
                     // remove them from the Selected Objects tab.
                     deselectAllFromList(element, deselectListViewId);

                     tabs[idx] = createTab(contextObject, selectionMode, listConfig[idx]);
                     initializeFilterTabs([tabs[idx]]);
                  }, 0);
               }

               function initializeFilterTabs(tabsToInit) {
                  initializeTabs(tabsToInit, selectedTabIndex, selectedItems, $scope, element);
                  $scope.filterTabOptions = {
                     selectedTabIndex: selectedTabIndex,
                     tabs: tabs,
                     tabType: vuiConstants.tabs.type.SECONDARY,
                     tabStyle: $scope.config.tabStyle ?
                           $scope.config.tabStyle :
                           vuiConstants.tabs.style.TABS,
                     hideWhenSingleTab: true
                  };
               }
            });
         }
      }

      function initializeTabs(tabs, selectedTabIndex, selectedItems, $scope, element) {
         // Attach onSelectionChange callbacks to each listView in the tabs.
         // We do it here, after creating the tabs, because we don't want
         // createFilterTabs() to track the selectedItems for each listview
         _.each(tabs, function(tab, idx) {
            var old, current;

            // For the currently selected tab - override deferred instantiation
            // flag - so that it is instantiated immediately.
            if (idx === selectedTabIndex) { tab.deferInstantiation = false; }

            tab.onSelectionChanged = function(newItems, oldItems) {
               if (isInitializing(newItems, oldItems)) {
                  return;
               }

               var items = _.map(newItems, formatItem);
               if ($scope.config.multipleSelect) {
                  old = _.chain(selectedItems).values().flatten().value();
                  selectedItems[tab.listViewId] = items;
                  current = _.chain(selectedItems).values().flatten().value();
                  var selectedObjTab = $scope.primaryTabOptions.tabs[1];
                  updateSelectedObjectsTab(element, selectedItems, selectedObjTab);
                  $scope.config.selectedObjects = _.pluck(current, 'id');
               } else {
                  if (!newItems || !newItems.length) {
                     return;
                  }
                  old = _.flatten(_.values(selectedItems));

                  // Get the id of the first (and only) list view that currently
                  // has a selection. If it is not the current list view, trigger
                  // a deselection, otherwise let Kendo handle it.
                  var deselectListviewId = _.keys(selectedItems)[0];
                  if (deselectListviewId !== tab.listViewId && old.length) {
                     deselectAllFromList(element, deselectListviewId);
                     delete selectedItems[deselectListviewId];
                  }

                  // If there is a currently selected item but listViewSelections
                  // is empty, this means that the selection is in the tree
                  // and we should deselect everything from in it.
                  if ($scope.currentSelection.length && !old.length) {
                     old = old.concat($scope.currentSelection);
                     deselectAllFromTree(element);
                  }

                  selectedItems[tab.listViewId] = items;
                  $scope.currentSelection = items;
                  current = items;
               }

               if (_removeItemsInProgress && !_itemsBeforeRemove) {
                  _itemsBeforeRemove = _.clone(old);
               }

               if (!_removeItemsInProgress) {
                  if (!_itemsBeforeRemove) {
                     triggerConfigCallback($scope.config.onSelectionChanged,
                           current, old);
                  } else {
                     triggerConfigCallback($scope.config.onSelectionChanged,
                           current, _itemsBeforeRemove);
                     _itemsBeforeRemove = null;
                  }
               }

               $scope.config.selectedObjects = _.pluck(current, 'id');
            };
            tab.onClick = function (event, tab) {
               // NOTE: Clicking on a tab clears deferred instantiation flag -
               // so that it is instantiated as soon as possible. If the flag was already
               // cleared - or was set to false initially (the default in most cases) -
               // then the bellow assignment has no effect whatsoever.
               tab.deferInstantiation = false;
            };
         });
      }

      function getGrid(element, listviewId) {
         var grid = element.find('[list-view-id="' + listviewId + '"] [kendo-grid]');

         return grid;
      }

      function deselectAllFromList(element, deselectListviewId) {
         var grid = getGrid(element, deselectListviewId);
         var kendoGrid = grid.data('kendoGrid');

         if (kendoGrid !== undefined) {
            grid.data('kendoGrid').clearSelection();
         }
      }

      function getTree(element) {
         var tree = element.find('[vx-tree-view]');

         return tree;
      }

      function deselectAllFromTree(element) {
         var tree = getTree(element);
         tree.data("kendoTreeView").select($());
      }

      function getDuplicateIds(listConfig, propertyName) {
         var ids = {};
         var dups = [];
         _.each(listConfig, function(cfg) {
            var val = cfg[propertyName];
            if (ids.hasOwnProperty(val)) {
               dups.push(val);
            }
            ids[val] = 1;
         });
         return dups;
      }

      /**
       * Creates the config for the primary tabs (Filter, Browse, Selected objects) in the
       * object selector.
       *
       * @param $scope The current angular scope
       * @returns {Array} An array containing the definitions required by <vui-tabs>
       */
      function createPrimaryTabs($scope, element) {
         var config = $scope.config;
         var tabs = [];

         if (config.listTabConfig) {
            var filterTabTitle = i18nService.getString('CommonUi',
                  'objectSelector.filterTabTitle');
            var filterLabel = config.listTabConfig.label || filterTabTitle;
            var filterTab = {
               label: filterLabel,
               contentUrl: 'resources/ui/components/objectselector/filterTab.html',
            };
            tabs.push(filterTab);
         }

         if (config.treeTabConfig && !config.multipleSelect) {
            var browseTab = {
               label: i18nService.getString('CommonUi',
                     'objectSelector.navigationTabTitle'),
               contentUrl: 'resources/ui/components/objectselector/browseTab.html'
            };
            tabs.push(browseTab);
         }

         if (config.multipleSelect) {
            var selectObjectsTab = {
               label: i18nService.getString('CommonUi',
                     'objectSelector.selectedObjectsTabTitle', 0),
               contentUrl: 'resources/ui/components/objectselector/selectObjectsTab.html',
               gridOpts: createSelectedItemsGridOpts($scope, element)
            };
            tabs.push(selectObjectsTab);
         }
         return tabs;
      }

      function createFilterTabs(listConfig, $scope) {
         var contextObject = $scope.config.contextObject;
         var selectionMode = $scope.config.multipleSelect ? vuiConstants.grid.selectionMode.MULTI :
               vuiConstants.grid.selectionMode.SINGLE;
         var relItemsTabs = _.filter(listConfig, function(conf) {
            return !angular.isDefined(conf.filterId);
         });
         var tabs;

         if (relItemsTabs.length === 0) {
            tabs = _.map(listConfig, function(conf) {
               return createTab(contextObject, selectionMode, conf);
            });
            return $q.when(tabs);
         }

         var promises = _.map(relItemsTabs, function(tab) {
            return fetchRelatedItem(contextObject, tab.listViewId, tab.dataModels[0]);
         });

         return $q.all(promises).then(function(relationSpecs) {
            tabs = _.map(listConfig, function(conf) {
               return createTab(contextObject, selectionMode, conf, relationSpecs);
            });
            return tabs;
         });
      }

      function createTab(contextObject, selectionMode, conf, relationSpecs) {
         var tab = {
            label: conf.label,
            contentUrl: 'resources/ui/components/objectselector/tabContent.html',
            listViewId: conf.listViewId,
            contextObject: contextObject,
            selectionMode: selectionMode,
            filterId: conf.filterId,
            dataModels: conf.dataModels,
            preselectComparator: conf.preselectComparator,
            filterParams: conf.filterParams,
            propertyParams: conf.propertyParams,
            showLoadingIndicatorWhileFetchingColumns: conf.showLoadingIndicatorWhileFetchingColumns,
            loadingIndicatorSize: conf.loadingIndicatorSize,
            deferInstantiation: conf.useLazyInstantiation ? true : false,
         };
         if (!conf.filterId) {
            tab.relationId = relationSpecs.shift().relationId;
         }
         return tab;
      }

      function fetchRelatedItem(contextObject, listViewId, targetType) {
         return $http({
            method: 'get',
            url: 'relateditems/relateditem/' + contextObject + '?listViewId=' +
            listViewId + '&targetType=' + targetType
         }).then(function(res) {
            return res.data;
         });
      }

      function createSelectedItemsGridOpts($scope, element) {
         var nameTemplate = '<span ng-non-bindable>' +
               '<span class="#:iconClass#"></span>#:name#</span>';

         var removeItemsButton = {
            actionId: 'removeItems',
            tooltipText: i18nService.getString('CommonUi', 'selector.removeItems'),
            label: i18nService.getString('CommonUi', 'selector.removeItems'),
            enabled: true,
            iconClass: 'vx-icon-removeIcon',
            onClick: removeItemsCallback
         };

         var opts = {
            columnDefs: [
               {
                  displayName: i18nService.getString('CommonUi',
                        'objectSelector.selectedObjectsTabName'),
                  template: kendo.template(nameTemplate),
                  field: 'name',
                  width: '50%'
               },
               {
                  displayName: i18nService.getString('CommonUi',
                        'objectSelector.selectedObjectTabType'),
                  field: 'type'
               }
            ],
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.MULTI,
            data: [],
            selectedItems: [],
            resizable: true,
            searchable: false,
            actionBarOptions: {
               actions: [removeItemsButton]
            }
         };

         $scope.$watch(function() {
            return opts.selectedItems;
         }, function(newItems) {
            removeItemsButton.enabled = !!newItems.length;
         });

         /**
          * Function executed when the 'Remove Items' button in the
          * 'Selected items' tab is clicked.
          *
          * When called deselects selected items in other Object Selector tabs.
          */
         function removeItemsCallback() {
            var selectedItemsGrid = element.find('#selected-objects > div')
                  .data('kendoGrid');

            var selectedItemsData = selectedItemsGrid.dataSource.data();
            var itemsToDeselect = selectedItemsGrid.select();

            // Map the kendo ids to the vsphere ids,
            // so that we can find the item across list views.
            var vsIds = [];
            for (var i = 0, j = 0; i < selectedItemsData.length && j < itemsToDeselect.length; i++) {
               if (selectedItemsData[i].uid === itemsToDeselect[j].getAttribute('data-uid')) {
                  vsIds.push(selectedItemsData[i].id);
                  j++;
               }
            }

            // Deselect the item inside a timeout, so that we do not trigger a digest
            // cycle inside the currently running digest cycle.
            $timeout(function() {
               // Properly update the check-all checkbox.
               itemsToDeselect.find('input[type="checkbox"]').click();
            }, 0);

            // Collect all list view datasource items.
            var dataSources = _.map(
                  element.find('.vui-secondary-tabs [vui-datagrid] > div'),
                  function(item) {
                     var grid = $(item).data('kendoGrid');

                     return grid.dataSource.data();
                  });

            var uidsToDelete = [];
            _.each(dataSources, function(dataSource) {
               for (var i = 0; i < dataSource.length; i++) {
                  var index = vsIds.indexOf(dataSource[i].id);
                  if (index !== -1) {
                     uidsToDelete.push(dataSource[i].uid);
                     delete vsIds[index];
                  }
               }
            });

            // Deselect the item inside a timeout, so that we do not trigger a digest
            // cycle inside the currently running digest cycle.
            $timeout(function() {
               _removeItemsInProgress = true;
               _.each(uidsToDelete, function(uid) {
                  if (uid === uidsToDelete[uidsToDelete.length - 1]) {
                     // Turn off the flag before the last deselect digest cycle
                     // so the onSelectionChange callback can be properly called.
                     _removeItemsInProgress = false;
                  }
                  element.find('[data-uid="' + uid + '"] input[type="checkbox"]').click();
               });
            }, 0);
         }

         return opts;
      }

      /**
       * Updates the grid in the "Selected object (XX)" tab.
       * Clears the grid and adds the new items from the selectedItems param.
       *
       * NOTE: This will not update the vui-datagrid internal selection.
       *
       * @param element The DOM element of the grid
       * @param selectedItems Currently selected items from all listview grids
       * @param selectedObjTab The vui tab object, contained in the primaryTabOptions.
       */
      function updateSelectedObjectsTab(element, selectedItems, selectedObjTab) {
         if (!element) {
            return;
         }
         var grid = element.find('#selected-objects > div').data("kendoGrid");
         if (!grid) {
            return;
         }
         var gridData = _.chain(selectedItems)
               .map(function(values, listViewId) {
                  if (!values || values.length === 0) {
                     return [];
                  }
                  return _.map(values, function(val) {
                     return {
                        iconClass: val.icon,
                        name: val.name,
                        type: getObjectType(val.id),
                        id: val.id
                     };
                  });
               })
               .flatten()
               .value();

         grid.dataSource.data(gridData);
         selectedObjTab.label = i18nService.getString('CommonUi',
               'objectSelector.selectedObjectsTabTitle', gridData.length);
      }

      /**
       * Returns a localized object type for an objectId, i.e 'Host' for
       * 'HostSystem:host-1:server-guid'.
       * @param objectId Object references
       * @returns {String} Localized type or an empty string, if the ref is invalid.
       */
      function getObjectType(objectId) {
         if (!objectId) {
            return '';
         }
         var type = defaultUriSchemeUtil.getEntityType(objectId);
         if (!type) {
            return '';
         }
         return i18nService.getString('Common', 'fieldType.' + type);
      }

      /**
       * Invokes the provided user callback.
       *
       * @callback configCallback The function provided by the user.
       * @param current {String[]} Current selected items.
       * @param old {String[]} Old selected items.
       */
      function triggerConfigCallback(configCallback, current, old) {
         if (configCallback && current) {
            configCallback(current, old);
         }
      }

      /**
       * Transforms a tree or listview item into a common format for the end user.
       * The original object is also provided as the 'rawData' property.
       */
      function formatItem(item) {
         return {
            id: item.id || item.objRef,
            icon: item.primaryIconId || item.spriteCssClass,
            name: item.name || item.text,
            rawData: item
         };
      }

      /**
       * Checks if the properties, which hold the selected items are still being
       * initialized.
       */
      function isInitializing(newItems, oldItems) {
         return !newItems ||
               newItems && newItems.length === 0 && (!oldItems || oldItems.length === 0);
      }

      function extractSelectedObjects(config) {
         var preselected = config.selectedObjects ?
               config.selectedObjects : [];

         if (!config.multipleSelect && preselected.length > 1) {
            log.error('Configuration error: Cannot preselect more than' +
                  'one item when multiple selection is set to false');
            return [preselected[0]];
         }

         return preselected;
      }

      /**
       * @param {Array} listConfig
       * @return {boolean}
       */
      function isValidListConfig(listConfig) {
         var listViewDups = getDuplicateIds(listConfig, 'listViewId');
         if (listViewDups.length !== 0) {
            log.error('Duplicate list view ids: ' + listViewDups.join());
            return false;
         }

         for (var i = 0; i < listConfig.length; i++) {
            if (!listConfig[i].dataModels || !listConfig[i].dataModels.length) {
               log.error('Missing or empty data models for list view: ' +
                     listConfig[i].listViewId);
               return false;
            }
         }

         var dataModelsDups = getDuplicateIds(listConfig, 'dataModels');
         if (dataModelsDups.length !== 0) {
            log.error('Duplicate data models: ' + dataModelsDups.join());
            return false;
         }

         return true;
      }

   }]);
