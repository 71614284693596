angular.module('com.vmware.platform.ui').directive('listView', [function () {
   return {
      restrict: 'E',
      scope: {
         /**
          * Id of the list view, e.g.
          * vsphere.core.host.list
          * vsphere.core.cluster.list
          * etc
          */
         listViewId: '@',

         /**
          * Type of the object in the list view. This is used by the refresh mechanism
          * to detect changes for that type.
          */
         listViewType: '=',

         /**
          * Id of the context object.
          */
         objectId: '=',

         /**
          * Id of a spec describing items related to the given object.
          * TODO hmihalski: this is a temporary solution and will be removed
          * when we start using DS 2.0 filters.
          */
         relationId: '=',

         /**
          * Property name that is going to be used to find if an object applies to this
          * relation.
          */
         inverseRelation: '=',

         /**
          * UID of an object for which to show related items. Used in case object id is
          * unset, e.g. list views that do not have context.
          */
         showRelationsFor: '=',

         /**
          * Enable/Disable the option to right click on the grid.
          */
         rightClickable: '@',

         /**
          * Enable/Disable object navigation.
          */
         navigatable: '@',

         /**
          * A function that will be called with every object in the grid. If
          * the comparator function returns true, that row will be selected when
          * the grid is initialized. If the comparator is not provided, the grid
          * will select the first row by default.
          *
          * Example:
          *    <list-view preselectComparator="preselectComparator(item)">
          *    </list-view>
          */
         preselectComparator: '&',

          /**
           * The type of selection in the grid. One of vuiConstants.grid.selectionMode.NONE,
           * vuiConstants.grid.selectionMode.SINGLE or vuiConstants.grid.selectionMode.MULTI.
           * Case is ignored.
           */
         selectionMode: '@',

         /**
          * A string value - "true" or "false"
          * Shows whether or not the current grid should be draggable
          */
         isDraggable: '@',

         /**
          * Should checkboxes be shown when multi selection is enabled
          */
         showCheckboxesOnMultiSelection: '&',

         /**
          * Show a toolbar on the top of the list view with a filter input field.
          */
         showFilter: '@',

         /**
          * Shows an export link to export items in list view's footer bar.
          */
         showExport: '@',

         /**
          * Callback that will be triggered when the selection is changed. Note that
          * onSelectionChanged is controlled by a watch, so you could receive undefined
          * or empty values as arguments.
          *
          * Example:
          *    <list-view onSelectionChanged="onSelectionChanged(newItems, oldItems)">
          *    </list-view>
          */
         onSelectionChanged: '&',

         /**
          * FilterId as in DataService 2.0 filters. Filters replace the DataService 1.0
          * constraints. More info on how to create new filter ids:
          * https://wiki.eng.vmware.com/User:Hmihalski/DS2DynamicFilterRetrieval
          */
         filterId: '@',

         /**
          * A list of object ids that will be provided to the Java Filter implementation
          * as a 2nd argument.
          */
         filterParams: '=',

         /**
          * A list of ParamSpec objects to optionally provide parameters when retrieving
          * properties to populate the list.
          *
          * Example:
          *    If the list specified with listViewId needs to retrieve a property hasPrivilege and that property
          *    requires a parameter, here is how to pass the parameter value:
          *    propertyParams = [{
          *       propertyName: "hostStorageDeviceMultipathingInfo",
          *       parameterType: "java.lang.String"
          *       parameter: deviceId
          *    }]
          */
         propertyParams: '=',

         /**
          * DataModels in DataService 2.0 replace the DataService 1.0 object type.
          * Expected type is String[].
          *
          * Example:
          *    <list-view data-models="dataModels"></list-view>
          */
         getModels: '&models',

         /**
          * Indicates whether refresh for live updates is enabled for this list.
          */
         liveRefreshEnabled: '=',

         /**
          * Indicates whether selection context is to be added to the browser URL.
          * Works for single selection only and is true by default.
          */
         usePersistentSelectionContext: '@',

         /**
          * Indicates whether list options should be requested.
          */
         overrideMetadataFromServer: '@',

         /**
          * Whether to show a loading indicator while fetching the column definitions
          * and before fetching the rows data.
          */
         showLoadingIndicatorWhileFetchingColumns: '@',

         /**
          * Optional: The size of the loading indicator - "medium" or "large".
          */
         loadingIndicatorSize: '@',
      },
      templateUrl: 'resources/ui/views/listview/ListViewTemplate.html',
      controller: 'ListViewController'
   };
}]);
