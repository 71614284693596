/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').service('datagridActionBarService',
         ['commonActionBarService', function(commonActionBarService) {

            function updateActionBar(datagridOptions, targetIds, actionSpecs, datagridActionBarServiceCacheObject) {
               var actionBarOptions;
               if(datagridOptions.actionsOptions) {
                  actionBarOptions = datagridOptions.actionsOptions;
                  datagridOptions.toolbar = [{
                     template: "<action-bar options=\"datagridOptions.actionsOptions\"></action-bar>"
                  }];
               } else {
                  datagridOptions.actionBarOptions = datagridOptions.actionBarOptions || {};
                  actionBarOptions = datagridOptions.actionBarOptions;
               }

               return commonActionBarService.updateActionBar(
                     actionBarOptions,
                     'actions',
                     targetIds,
                     actionSpecs,
                     datagridActionBarServiceCacheObject
               );
            }

            return {
               updateActionBar: updateActionBar
            };
         }]);
})();
