/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {
    'use strict';
    import IPromise = angular.IPromise;
    import IRequestConfig = angular.IRequestConfig;

    interface RequestConfigOptions {
        skipLoadingNotification?: boolean;
    }

    export interface NavigationNode {
        title: string;
        icon: string;
        uid: string;
        navigationTargetUid: string | null;
        children: NavigationNode[] | null;
    }

    export class ObjectNavigatorService {
        static $inject = [
            '$http',
            '$q',
            'logService'
        ];

        private log: any;

        constructor(private $http: ng.IHttpService,
                    private $q: ng.IQService,
                    logService: any) {
            this.log = logService('objectNavigatorService');
        }

        getNaviData(targetViewExtensionId: string,
                    contextObjectId: string,
                    navigator: any,
                    prevNavigator: any): IPromise<any> {
            return this.$http({
                method: 'GET',
                url: 'navigator/navigate/' + targetViewExtensionId,
                params: {
                    context: contextObjectId,
                    navigator: navigator,
                    prevNavigator: prevNavigator
                }
            }).then((resp) => {
                if (!resp) {
                    const reason: string = 'undefined response to navigator/navigate/'
                        + targetViewExtensionId;
                    this.log.error(reason);
                    return this.$q.reject(reason);
                }
                return resp.data;
            });
        }

        /**
         * Get object navigator view by id.
         *
         * @param nodeId
         *    A view node id
         *
         * @param [options]
         *    Http config options
         */
        getView(nodeId: string, options?: RequestConfigOptions): IPromise<NavigationNode> {
            const id: string = nodeId ? nodeId : '';

            const config: IRequestConfig = {
                method: 'GET',
                url: 'navigator/node?nodeId=' + id
            };

            if (options) {
                angular.extend(config, options);
            }

            return this.$http(config).then((resp) => {
                return resp.data;
            });
        }
    }

    angular.module('com.vmware.platform.ui').service('objectNavigatorService', ObjectNavigatorService);
}
