angular.module('com.vmware.platform.ui').directive('storageProfile', [
   function () {
      return {
         scope: {
            selectedStorageProfile: '=',
            storageProfiles: '=',
            readOnly: '=',
            warning: '=',
            errorConfig: '=',
            onChangeCallback: '&',
            selectAriaLabel: '<?'
         },
         templateUrl: 'resources/ui/components/storageProfile/storageProfile.html'
      };
   }]);
