/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */
/*
 * Resizes the contained kendo widgets when the expression value changes
 */
angular.module('com.vmware.platform.ui').directive('vxKendoResize', ['vxZoneService',
function (vxZoneService) {
   'use strict';
   return {
      restrict: 'A',
      scope: false,
      link: function($scope, $element, $attr) {
         function resize() {
            kendo.resize($element, true);
         }

         var resizeTimeoutId = null;

         // $watchCollection to support observing multiple objects
         $scope.$watchCollection($attr.vxKendoResize, function () {
            // use timeout to ensure that any ng-ifs ng-shows have added/removed nodes
            // from the DOM
            if (resizeTimeoutId !== null) {
               return;
            }

            vxZoneService.runOutsideAngular(function () {
               resizeTimeoutId = setTimeout(function () {
                  resizeTimeoutId = null;
                  resize();
               }, 1);
            });
         });

         $scope.$on('$destroy', function () {
            if (resizeTimeoutId !== null) {
               vxZoneService.runOutsideAngular(function () {
                  clearTimeout(resizeTimeoutId);
                  resizeTimeoutId = null;
               });
            }
         });
      }
   };
}]);
