/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

declare var h5: any;
declare var _paq: any;


/* interface cloned from ng-next-app */
interface ActionsServiceEntityBase {
   id: string;
   type: "ACTION" | "SEPARATOR";
   label: string;
   icon: string;
   feature: string;
   children: Array<ActionsServiceEntityBase>;
}

// realized implementation
interface ActionsServiceMenu extends ActionsServiceEntityBase {
   children: Array<ActionsServiceMenuItem>;
}

// realized implementation
interface ActionsServiceMenuItem extends ActionsServiceEntityBase {
   // object linkage
   references: {
      evaluation?: ActionsServiceEvaluation;
   };
}

// ++++++++++ schema for evaluations from actions service +++++++++++++++
interface ActionsServiceEvaluation {
   timestamp: number;
   available: boolean;
   invoker: string;
   additionalData: any;
   context: {targetObjects: any};
   action: {
      label: string;
      icon: string;
      uid: string;
      description: string;
      confirmationTitle: string;
      confirmationText: string;
      confirmationTextNone: string;
      confirmationTextSome: string;
      confirmationTextAll: string;
      applicableTargetTypes: Array<string>;
   };
   evaluationStatus: Array<any>;
}

var resources = {
   theme: {
      light: "header-7",
      dark: "header-7"
   },
   product: {
      defaultName: "vSphere Client",
   },
   menu: {
      homeMenuNavigatorNodeId: "vsphere.core.navigator.launcher",
   }
};


/*
 * Controller of the controlBar of the application.
 */
angular.module('com.vmware.platform.ui').controller('ControlBarViewController',
   ['$scope', '$rootScope', 'userSessionService',
      'logService', 'actionsService', '$analytics', 'timeFormatterService',
      'configurationService', 'vFeedDialogService', 'vscFeedbackInvoker', 'themeService', 'vcChangeThemesService',
      function ($scope, $rootScope, userSessionService,
                logService, actionsService, $analytics, timeFormatterService,
                configurationService, vFeedDialogService, vscFeedbackInvoker, themeService, vcChangeThemesService) {
         'use strict';
         var self = this;

         var logger = logService('ControlBarViewController');
         var i18n = $rootScope.i18n;
         var productName = h5.name || resources.product.defaultName;

         $scope.isFeedbackToolEnabled = true;
         $scope.h5uiFeedbackTool = false;
         $scope.themeClass = "";
         $scope.h5uiVcTheme = false;

         configurationService.getProperty('feedbackTool.enabled').then(function(propVal) {
            $scope.isFeedbackToolEnabled = (propVal === 'true');
         });

         configurationService.isFeatureEnabled('h5uiFeedbackTool').then(function(isFeatureEnabled) {
            $scope.h5uiFeedbackTool = isFeatureEnabled;
         });

         $scope.homeMenuNavigatorNodeId = resources.menu.homeMenuNavigatorNodeId;
         $scope.userMenuType = 'vise.menus.user';
         $scope.helpMenuType = 'vise.menus.help';
         $scope.userMenuLabel = i18n('Common', 'ariaLabel.userMenu.link');
         $scope.helpMenuLabel = i18n('Common', 'ariaLabel.helpMenu.link');
         $scope.productNavigationMenuLabel = i18n('Common', 'ariaLabel.productNavigation.link');
         $scope.showSpinner = true;
         $scope.isCodeCaptureVisible = false;
         $scope.connectionAlive = true;

         self.$onInit = function () {
            prepareTheme();
            enableVcThemeFeature();
         };

         self.$onDestroy = function () {

         };

         userSessionService.getUserSession().then(function (userSession) {
            if (userSession.userName) {
               $scope.userName = userSession.userName;
               // TODO: smarathe - Check if the analytics is enabled
               // Username is lowercased for the purposes of telemetry correlation
               // with the Flex client.
               $analytics.setUsername(userSession.userName.toLowerCase());
            } else {
               logger.warn('Error getting username from userSessionService');
            }

            if (userSession.hashedClientId) {
               // If telemetry is enabled...
               if (typeof _paq !== 'undefined' && _paq !== null) {
                  // Collect the user session for correlation with vFeedback tool data.
                  _paq.push(['setCustomDimension', 'app-session', userSession.hashedClientId]);
               }
            } else {
               logger.warn('Error getting session ID from userSessionService');
            }

            $scope.showSpinner = false;
         });

         // Initialize the scope with defaults or from configuration.
         angular.extend($scope, {
            isUpdating: false,
            lastRefreshedTimeStamp: '',
            productName: productName,
            showPerf: h5.debug
         });

         $rootScope.$on("backendConnectionLostEvent", (): void => {
            if ($scope.connectionAlive) {
               $scope.$apply(function () {
                  // Apply is called so that the DOM gets updated even if the browser
                  // window is inactive.
                  $scope.connectionAlive = false;
               });
            }
         });

         $rootScope.$on("backendConnectionRestoredEvent", (): void => {
            if (!$scope.connectionAlive) {
               $scope.$apply(function () {
                  // Apply is called so that the DOM gets updated even if the browser
                  // window is inactive.
                  $scope.connectionAlive = true;
                  $rootScope.$broadcast('dataRefreshInvocationEvent', null);
               });
            }
         });

         /**
          * ShowContextMenu function that calls ActionService to render menus of
          * type (userMenu, helpMenu).
          */
         $scope.showMenu = function ($event, alignmentDirection, menuType, elementId) {
            var position = getContextMenuPosition(elementId);
            var rightAlignedOffset = document.body.getBoundingClientRect().width -
               $event.target.getBoundingClientRect().right - parseInt($($event.target).css("margin-right"));

            // call Action service to fetch and display the menu
            actionsService.showActionsMenu($event, menuType, $scope, null, position.left, position.top)
               .then(function (vuiMenuMetadata) {

                  var applicationMenuContainerElement = actionsService.getApplicationMenuContainerElement();

                  switch (alignmentDirection) {

                     case "right":
                        // todo: this is a workaround to get right-alignment on dropdown
                        // post menu layout modifications for right alignment
                        applicationMenuContainerElement
                           .css({left: "", width: "", right: rightAlignedOffset + "px"})
                           .children("UL").css({position: "static"});
                        break;
                  }
               });
         };

         // reacts to menu clicks via Angular4 VcActionMenuItem entries
         // action triggered by passing evaluation to invoke
         $scope.onDropdownMenuItemClickEvent = function (menuItem: ActionsServiceMenuItem) {
            actionsService.invokeAction(menuItem.references.evaluation, null);
         };

         /**
          * Returns true if the product home page is present and non-empty.
          * Returns false otherwise.
          */
         $scope.productHomePresent = function () {
            if ($scope.rootExtId && $scope.rootExtId.trim().length > 0) {
               return true;
            }
            return false;
         };

         /**
          * Determines which feedback modal to show
          */
         $scope.showEnhancedFeedbackModal = function() {
            var isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;
            if ($scope.h5uiFeedbackTool && !isFirefox) {
               vscFeedbackInvoker.openFeedbackModal();
            } else {
               vFeedDialogService.showFeedbackDialog();
            }
         };

         /**
          * determine if feedback modal is open or not
          */
         $scope.isFeedbackModalOpen = function() {
            return document.getElementsByClassName('feedback-modal').length === 1;
         };

         /**
          * Get the context menu position.
          */
         function getContextMenuPosition(elementId) {
            var $uiElement = $('#' + elementId);
            var offset = $uiElement.offset();
            offset.top = $uiElement.parent().height();

            return offset;
         }

         /**
          * Refresh function that sends the 'dataRefreshInvocationEvent'
          * event on both the eventBus and also on the angularJS
          * rootscope.
          */
         $scope.onRefresh = function () {
            if ($scope.connectionAlive) {
               $rootScope.$broadcast('dataRefreshInvocationEvent', null);
            }
         };

         function refreshUpdatingState(update, timestamp) {
            $scope.isUpdating = update;
            $scope.lastRefreshedTimeStamp = timestamp;
         }

         /**
          * Listen on the 'dataRefreshStateChanged' event on the scope and
          * change the view state - updating, or updated at timestamp.
          */
         $scope.$on('dataRefreshStateChanged', function (event, dataRefreshState) {
            if (dataRefreshState.numOfActiveJobs > 0) {
               // Show the Updating label.
               refreshUpdatingState(true, '');
            } else {
               timeFormatterService.formatDate(dataRefreshState.time).then(function (formattedTime) {
                  var timestamp = '';

                  if (dataRefreshState.time !== null) {
                     timestamp = i18n('Common', 'updatedTimeStamp', formattedTime);
                  }
                  refreshUpdatingState(false, timestamp);
               });
            }
         });

         /**
          * Listen on the 'showRefreshSpinner' event on the scope
          */
         $scope.$on('showRefreshSpinner', function () {
            $scope.showSpinner = true;
         });

         /**
          * Listen on the 'hideRefreshSpinner' event on the scope
          */
         $scope.$on('hideRefreshSpinner', function () {
            $scope.showSpinner = false;
         });

         /**
          * Use it to show/hide the divider located after
          * the code capture button.
          */
         $scope.onCodeCaptureVisibilityChanged = function(event: boolean) {
            $scope.isCodeCaptureVisible = event;
         };

         /**
          * deals with changing of themes for the header. Currently the header looks like a dark theme ( dark background ) in both light and dark
          * modes. Supports future theme-related styling needs on the header
          */
         function prepareTheme() {
            assignThemeClass(resources.theme[themeService.getCurrentTheme()]);
         }

         /**
          * Function that subscribes to the ChangeThemesService to change the header/banner color
          * whenever inside a vCenter context.
          */
         function prepareVCThemeChange() {
            vcChangeThemesService.onVCThemeChanged$.subscribe( function(vcTheme: string) {
               if (vcTheme) {
                  assignThemeClass(vcTheme);
               }
            });
         }

         function assignThemeClass(vcTheme: string) {
            $scope.themeClass = vcTheme;
         }

         /**
          * Function that enables the vCenter Theme change feature in header.
          */
         function enableVcThemeFeature() {
            configurationService.isFeatureEnabled('h5uiVcTheme').then(function (isFeatureEnabled) {
               if ($scope.h5uiVcTheme = isFeatureEnabled) {
                  prepareVCThemeChange();
               }
            });
         }

      }]);
