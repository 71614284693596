/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * StackViewEditorController
 */
angular.module('com.vmware.platform.ui').controller('RIStackEditorController', [ '$scope','$http',
function($scope, $http) {

   var self = this;

   var id = $scope._route.objectId;

   angular.extend($scope._container, {
      title: $scope._view.title,
      onClose: function(arg){
         if (arg.cancel) {
            return;
         }

         return save();
      }
   });

   angular.extend($scope, {
      data : $scope._view.data,
   });

   function save() {
      return $http({
         method: 'POST',
         url: 'mutation/' + id,
         params: {
           propertyName: $scope._view.propertyName
         },
         data: $scope.data.values
      });
   }

} ]);
