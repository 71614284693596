/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Service for validating MAC address.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').service('macAddressValidatorService', MacAddressValidatorService);

   MacAddressValidatorService.$inject = ['i18nService'];

   function MacAddressValidatorService(i18nService) {
      var SEPARATED_MAC_FORMAT = /^([0-9a-fA-F]{2}[:\-\s.]){5}[0-9a-fA-F]{2}$/;
      var MAC_ADDRESS_MAX_LENGTH = 17;
      var FORMAT_ERROR = i18nService.getString('VmUi', 'NetworkConfig.MACFormatError');

      this.validate = function(value) {
         if(!validateLength(value) || !validateFormat(value)){
            return buildResult(FORMAT_ERROR, false);
         } else {
            return buildResult(null, true);
         }
      };

      function validateLength (value) {
         return (value &&
            value.length >0 &&
            value.length <= MAC_ADDRESS_MAX_LENGTH)
            ? true : false;
      }

      function validateFormat (value) {
         return SEPARATED_MAC_FORMAT.test(value);
      }

      function buildResult (message, result) {
         return {
            error: message,
            result: result
         };
      }

      return this;
   }
})();
