/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   angular.module('com.vmware.platform.ui').service('commonActionBarService', CommonActionBarService);

   CommonActionBarService.$inject = ['actionsService', 'vuiConstants', '$q'];

   function CommonActionBarService(actionsService, vuiConstants, $q) {
      var pendingActionRequestsData = [];
      return {
         createActionBar: createActionBar,
         updateActionBar: updateActionBar
      };

      function updateActionBar(actionsWrapperObject,
                               actionsPropertyName,
                               targetIds,
                               actionSpecs,
                               commonActionBarServiceCacheObject) {
         if(actionsWrapperObject[actionsPropertyName] &&
             actionsWrapperObject[actionsPropertyName].length) {
            return refreshActionBar(actionsWrapperObject,
                actionsPropertyName,
                targetIds,
                actionSpecs,
                commonActionBarServiceCacheObject);
         } else {
            return createActionBar(actionsWrapperObject, actionsPropertyName,
                targetIds, actionSpecs, commonActionBarServiceCacheObject);
         }
      }

      function createActionBar(actionsWrapperObject, actionsPropertyName, targetIds,
            actionSpecs, commonActionBarServiceCacheObject) {
         // this is needed to prevent 'no actions passed' error when loading the action
         // bar for the very first time
         if (!actionsWrapperObject[actionsPropertyName]) {
            actionsWrapperObject[actionsPropertyName] = [];
         }

         var actionEvalsPromise = commonActionBarServiceCacheObject ?
               commonActionBarServiceCacheObject :
               requestActions(targetIds, actionSpecs);

         return $q.when(actionEvalsPromise).then(function(actionEvalsById) {
            var actionButtons = [];
            _.forEach(actionSpecs, function(actionSpec) {
               var button;
               if (actionSpec === vuiConstants.actions.SEPARATOR) {
                  button = createSeparator();
                  if (button) {
                     actionButtons.push(button);
                  }
                  return;
               }

               if (actionSpec && actionSpec.actionId) {
                  button = createActionButton(
                        actionEvalsById[actionSpec.actionId],
                        getOnActionInvocationFunction(actionSpec, actionEvalsById),
                        actionSpec);
                  if (button) {
                     actionButtons.push(button);
                  }
               }
            });

            // the action bar is updated at once when all the buttons are created to
            // prevent the UI from flickering - if you initially reset (remove all the
            // actions ) the actions bar and then update the actions one by one UI is
            // flickering
            actionsWrapperObject[actionsPropertyName] = actionButtons;
            return actionEvalsById;
         });
      }

      function refreshActionBar(actionsWrapperObject,
                                actionsPropertyName,
                                targetIds,
                                actionSpecs,
                                commonActionBarServiceCacheObject) {
         var actionEvalsPromise = null;
         if(commonActionBarServiceCacheObject) {
            actionEvalsPromise = commonActionBarServiceCacheObject;
         } else {
            actionEvalsPromise = requestActions(targetIds, actionSpecs);
         }

         return $q.when(actionEvalsPromise).then(function(actionEvalsById) {
            var actionSpecsById = {};
            _.forEach(actionSpecs, function(spec) {
               actionSpecsById[spec.actionId] = spec;
            });
            _.forEach(actionsWrapperObject[actionsPropertyName], function(actionButton) {
               if(actionButton === vuiConstants.actions.SEPARATOR) {
                  return;
               }
               var actionId = actionButton.actionId;
               var actionEval = actionEvalsById[actionId];
               actionButton.onClick = getOnActionInvocationFunction(actionSpecsById[actionId], actionEvalsById);
               updateActionButtonAvailability(actionButton, actionEval);
               updateActionButtonVisibility(actionButton, actionEval);
            });
            return actionEvalsById;
         });
      }

      function createSeparator() {
         return vuiConstants.actions.SEPARATOR;
      }

      function createActionButton(actionEval, onClickCallback, actionSpec) {
         if(!actionEval) {
            return;
         }
         var label = actionEval.action.label;
         var tooltipText = actionEval.action.description;

         if(actionSpec.noLabel) {
            label = null;
            tooltipText = actionEval.action.label;
         }

         if(actionSpec.customLabel) {
            label = actionSpec.customLabel;
         }

         var actionButton = {
            actionId: actionEval.action.uid,
            tooltipText: tooltipText,
            label: label,
            iconClass: actionSpec.hideIcon ? "" : actionEval.action.icon,
            onClick: onClickCallback,
            isAvailableCustomCallback: actionSpec.isActionAvailable,
            isVisibleCustomCallback: actionSpec.isActionVisible
         };

         updateActionButtonAvailability(actionButton, actionEval);
         updateActionButtonVisibility(actionButton, actionEval);

         return actionButton;
      }

      function updateActionButtonAvailability(actionButton, actionEval) {
         if(!actionButton) {
            return;
         }

         if(!actionEval) {
            actionButton.enabled = false;
            return;
         }

         if(actionButton.isAvailableCustomCallback) {
            actionButton.enabled = !!actionButton.isAvailableCustomCallback(actionEval);
         } else {
            actionButton.enabled = !!actionEval.available;
         }
      }

      function updateActionButtonVisibility(actionButton, actionEval) {
         if (!actionButton) {
            return;
         }
         if (!actionEval) {
            actionButton.visible = true;
         }

         if(actionButton.isVisibleCustomCallback) {
            actionButton.visible = !!actionButton.isVisibleCustomCallback(actionEval);
         } else {
            actionButton.visible = true;
         }
      }

      /**
       * Requests action definitions for all action IDs in actionSpec array
       * @return map with action's uid as keys and actionDefintions as values.
       */
      function requestActions(targetIds, actionSpecs) {
         if(!actionSpecs) {
            return $q.when({});
         }

         var actionIds = actionSpecs.filter(function(actionSpec) {
            return actionSpec && actionSpec.actionId;
         }).map(function(actionSpec) {
            return actionSpec.actionId;
         });

         if(!actionIds || !actionIds.length) {
            return $q.when({});
         }

         var pendingActionRequestId = actionIds.join('\n');
         var pendingActionRequestData = pendingActionRequestsData[pendingActionRequestId];
         if (!pendingActionRequestData) {
            pendingActionRequestData = {
               targetIds: targetIds
            };
            pendingActionRequestsData[pendingActionRequestId] = pendingActionRequestData;
            pendingActionRequestData.request =
                requestActionsFromActionsService(targetIds, actionIds, pendingActionRequestId);
         } else {
            pendingActionRequestData.targetIds = targetIds;
         }

         return pendingActionRequestData.request
             .then(function(actionEvals) {
                return angular.copy(actionEvals);
             });
      }

      function requestActionsFromActionsService(targetIds, actionIds, pendingActionRequestId) {
         return actionsService.getActions(targetIds, actionIds).then(function(actionEvals) {

            var pendingRequest = pendingActionRequestsData[pendingActionRequestId];
            if (!angular.equals(targetIds, pendingRequest.targetIds)) {
               return requestActionsFromActionsService(pendingRequest.targetIds, actionIds, pendingActionRequestId);
            }

            var actionEvalsById = {};
            _.forEach(actionEvals, function(actionEval) {
               actionEvalsById[actionEval.action.uid] = actionEval;
            });

            delete pendingActionRequestsData[pendingActionRequestId];

            return actionEvalsById;
         });
      }

      function getOnActionInvocationFunction(actionSpec, actionEvalsById) {
         return function($event) {
            var actionEval = actionEvalsById[actionSpec.actionId];
            if(actionSpec.onInvokeAction) {
               actionSpec.onInvokeAction(actionEval);
            }
            var actionInvocationContext = actionSpec.getActionInvocationContext ?
                actionSpec.getActionInvocationContext() : null;
            if ($event && $event.currentTarget) {
               actionInvocationContext = actionInvocationContext ? angular.copy(actionInvocationContext) : {};
               actionInvocationContext.focusTarget = $event.currentTarget;
            }
            actionsService.invokeAction(actionEval, actionInvocationContext);
         };
      }
   }
})();

