module platform {
   export interface WebSocket {
      onmessage(message: any): void;
      send(message: string): void;
      onerror(): void;
      onclose(event: CloseEvent): void;
      onopen(): void;
      close(): void;
      readyState: number;
   }

   export class WebSocketService {
      private log: any;

      constructor(logService: any) {
         this.log = logService('webSocketService');
      }

      createWebSocket(url:string): WebSocket | null {
         const WebSocket: any = window['WebSocket'] || window['MozWebSocket'];
         if (WebSocket) {
            return new WebSocket(url);
         } else {
            this.log.error('Error: WebSocket is not supported by this browser.');
            return null;
         }
      }
   }
   angular.module('com.vmware.platform.ui').service('webSocketService', WebSocketService);
}