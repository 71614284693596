/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

declare var h5: any;

/*
 * A simple context menu style menu.
 *
 * <p>
 * This directive has isolated scope. To use, pass the vx-menu attribute an object containing
 * the following properties:
 * <ul>
 * <li>showMenu: Boolean controlling when to show or hide the menu.</li>
 * <li>menuItems: Array of objects representing the items in the list. Each object should
 * contain:
 *    <ul>
 *    <li>id: Unique string identifier for the item.</li>
 *    <li>label: Localized display text for the item.</li>
 *    <li>icon: CSS class name of an icon for the item.</li>
 *    <li>enabled: Boolean controlling whether the item is enabled (clickable) or not.</li>
 *    </ul>
 *    Use 'separator' string to insert separator between items in the list.
 * </li>
 * <li>itemClick: Function accepting a click event and called when an enabled menu item
 * is clicked. The event is raised on the menu itself, so the function is responsible for
 * finding the parent li that was clicked. The id of the li is set to the id of the item data.</li>
 * <li>left: The absolute coordinate for the left side of the menu.</li>
 * <li>top: The absolute coordinate for the top side of the menu.</li>
 * <li>header: An object containing the following properties:
 *    <ul>
 *       <li>show: Boolean controlling whether a header is shown for this menu.</li>
 *       <li>icon: CSS class name of an icon for the header.</li>
 *       <li>label: Localized display text for the header.</li>
 *    </ul>
 * </li>
 * </p>
 */
(function() {
angular.module('com.vmware.platform.ui').directive('vxMenu', vxMenu);

function vxMenu() {
   return {
      restrict: 'A',
      templateUrl: 'resources/ui/components/menu/vxMenuTemplate.html',
      scope: {
         vxMenu: '='
      },

      link: function(scope, element, attrs, controller) {
         if (!h5.debug) {
            element.on('contextmenu', function() {
               return false;
            });
         }

         scope.clickHandler = function(event) {
            // Get clicked on item.
            var targetElement = $(event.target);
            var liElement = targetElement.closest("li");
            if (liElement.length === 0) {
               // Clicked on some empty space that wasn't an item.
               return false;
            }
            var clickedItem = $(liElement[0]);
            if (clickedItem.hasClass("disabled")) {
            // Do nothing if item is disabled.
               return false;
            }
            // Hide menu then call the click handler the client supplied.
            scope.vxMenu.showMenu = false;
            // inform trigger point of closing action
            scope.vxMenu.isShowing = false;

            scope.vxMenu.itemClick(event, clickedItem[0]);
         };

         //Returns true if item is string divider
         scope.isDivider = function(item) {
            return item === 'SEPARATOR';
         };

         // Styles is bound in the template using ng-style. We use it only to control
         // the coordinates, according to what the client is putting on the scope.
         scope.styles = {left: '0px', top: '0px'};
         scope.$watch("vxMenu.left", function(val){
            scope.styles.left = val + 'px';
         });
         scope.$watch("vxMenu.top", function(val){
            scope.styles.top = val + 'px';
         });
      }
   };
}
}());
