/* Copyright 2015-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
         .controller('SummaryViewController', SummaryViewController);

   SummaryViewController.$inject = ['$scope', '$timeout', 'extensionService',
         'i18nService', 'persistenceService', 'summaryService', 'vcH5ConstantsService', 'configurationService'];

   function SummaryViewController($scope, $timeout, extensionService,
                                  i18nService, persistenceService, summaryService,
                                  vcH5ConstantsService, configurationService) {
      var self = this;
      var objectId;
      var delayHandler = null;
      var SUMMARY_VIEW_STATUS_UID = 'com.vmware.usersetting.summaryview.status';
      var SUMMARY_VIEW_CATEGORY = 'com.vmware.usersetting.summaryview';
      self.loading = false;
      self.newSummaryEnabled = false;
      self.showSummary = false;
      self.isVmView = summaryService.isVmView($scope._view.contentSpec.metadata.resourceBundle);

      init();

      function init() {
         getSummaryViewStatus();
         activate();
      }

      configurationService.isFeatureEnabled('h5uiVmSummary').then(function(isFeatureEnabled) {
         if ($scope._view && $scope._view.contentSpec && $scope._view.contentSpec.metadata) {
            self.newSummaryEnabled = isFeatureEnabled && self.isVmView;
         }
      });

      self.newSummarySignpost = {
         title: i18nService.getString('Common', 'summary.newSummary.title')
      };

      self.showSignPostMessage = function() {
         return self.showSummary ? i18nService.getString('Common', 'summary.newSummary.content') : i18nService.getString('Common', 'summary.newSummary.contentOff');
      };

      self.toggleNewSummary = function() {
         persistenceService.setData(
            SUMMARY_VIEW_STATUS_UID,
            angular.toJson({
               showSummaryStatus: !self.showSummary
            }),
            {},
            SUMMARY_VIEW_CATEGORY);
         self.showSummary = !self.showSummary && self.isVmView;
      };

      function getSummaryViewStatus() {
         var conditionObj = {
            key: 'uid',
            op: '=',
            value: SUMMARY_VIEW_STATUS_UID
         };
         persistenceService.getData(conditionObj).then(function(showSummaryStatus) {
            if (showSummaryStatus && showSummaryStatus.length === 1 && showSummaryStatus[0].value) {
               var showSummary = angular.fromJson(showSummaryStatus[0].value);
               if (showSummary.showSummaryStatus) {
                  self.showSummary = showSummary.showSummaryStatus && self.isVmView;
               }
            }
         });
      }

      function activate() {
         // This is used in case of plugin portlets which need the objectId in the iframe URL
         objectId = $scope._route ? $scope._route.objectId : null;

         $scope.portletOptions = {
            portlets: []
         };
         $scope.$on(vcH5ConstantsService.MODEL_CHANGED_EVENT, function (event, objectChangeInfo) {
            if (objectId === objectChangeInfo.objectId) {
               evaluatePortlets();
            }
         });
         $scope.$on('$destroy', function() {
            if (delayHandler) {
               $timeout.cancel(delayHandler);
            }
         });
         $scope.$on(vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT, evaluatePortlets);
         evaluatePortlets();
      }

      function evaluatePortlets() {
         $scope._view = $scope._view ? $scope._view : {};
         $scope._route = $scope._route ? $scope._route : {};

         if($scope.portletOptions.portlets.length === 0) {
               delayHandler = $timeout(function() {
                  self.loading = true;
               }, 1000);
         }
         extensionService.getHostedExtensions($scope._view.$id, $scope._route.objectId)
               .then(function (portletSpecs) {
                  recreatePortlets(portletSpecs);
                  if (delayHandler) {
                     $timeout.cancel(delayHandler);
                  }
                  delayHandler = null;
                  self.loading = false;
               });
      }

      function recreatePortlets(portletSpecs) {
         var newPortlets = summaryService.createPortlets(portletSpecs, objectId);
         var oldPortlets = $scope.portletOptions.portlets;
         var updatedPortlets = summaryService.updatePortlets(newPortlets, oldPortlets);
         // reference equality is fine here
         if (updatedPortlets === oldPortlets) {
            // nothing changed, do not rebind
            return;
         }
         $scope.portletOptions.portlets = updatedPortlets;
      }
   }
})();
