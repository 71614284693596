/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
*@ngdoc directive
*@name com.vmware.platform.ui:vxAlert
*@description
*  Directive to create a confirmation or alert dialog
*@example

   <html>
      <div vx-alert='alertOptions'></div>
   </html>

   <script>
   $scope.alertOptions = {
      title: 'new title',
      text: 'Some scary text',
      icon: 'icon-applib-cool-class' // 32x32 px
      visible:true
      confirmOptions: {
         label: 'Yay'
         visible: true,
         disabled: false,
         onClick: function(){
            console.log('yes clicked');
         }
      },
      rejectOptions: {
         label: 'Nay'
         visible: true,
         focused: true,
         disabled: false,
         onClick: function(){
            alert('Reject button clicked or escape pressed');
         }
      }
   };
   </script>
*/
(function() {
var vxAlertTemplateUrl = 'resources/ui/components/alert/vxAlertTemplate.html';

angular
   .module('com.vmware.platform.ui')
   .directive('vxAlert', vxAlert);

vxAlert.$inject = [
   '$templateCache',
   '$document',
   '$timeout'
];

function vxAlert($templateCache, $document, $timeout) {
   return {
      restrict: 'A',
      template: $templateCache.get(vxAlertTemplateUrl),
      scope: {
         vxAlert: '='
      },
      link: function (scope, element, attrs) {
         var ESC_KEYCODE = 27;

         if (!_.isObject(scope.vxAlert)) {
            throw new TypeError('Alert configuration is not an object');
         }

         function onReject() {
            if (_.isObject(scope.vxAlert.rejectOptions) &&
                _.isFunction(scope.vxAlert.rejectOptions.onClick)) {
               scope.vxAlert.rejectOptions.onClick.call(undefined);
            }

            // hide alert
            scope.vxAlert.visible = false;
         }

         var dismissAlertHandler = function (event) {
            // Avoid propagation of any keydown event since some other dialog might be
            // listening for it and trigger undesired functionality as closing the other
            // dialog when the current one on is on focus.
            event.stopImmediatePropagation();

            if (event.which === ESC_KEYCODE || event.keyCode === ESC_KEYCODE) {
               // hide alert
               //  need $apply beacuse digest does not trigger ?
               scope.$apply(function () {
                  onReject();
               });
            }
         };

         scope.vxAlert.htmlSafe = scope.vxAlert.text;

         $document.on('keydown', dismissAlertHandler);

         // clean up bindings
         scope.$on('$destroy', function(){
            $document.off('keydown', dismissAlertHandler);
         });

         // watch the text and replace with safeHtml
         scope.$watch('vxAlert.text', function(oldVal,newVal){
            if(scope.vxAlert.htmlSafe && angular.equals(oldVal, newVal)){
               return;
            }
            scope.vxAlert.htmlSafe = scope.vxAlert.text;
         });

         scope.isConfirmVisible = function (){
            // if not defined default to visible/true
            if ( _.isUndefined(scope.vxAlert.confirmOptions.visible)) {
               scope.vxAlert.confirmOptions.visible = true;
            }
            return scope.vxAlert.confirmOptions.visible ;
         };

         scope.isRejectVisible = function (){
            // if not defined default to visible/true
            if ( _.isUndefined(scope.vxAlert.rejectOptions.visible)) {
               scope.vxAlert.rejectOptions.visible = true ;
            }
            return scope.vxAlert.rejectOptions.visible;
         };

         scope.isConfirmDisabled = function (){
            // if not defined default to not-disabled/false
            if ( _.isUndefined(scope.vxAlert.confirmOptions.disabled)) {
               scope.vxAlert.confirmOptions.disabled = false ;
            }
            return scope.vxAlert.confirmOptions.disabled ;
         };

         scope.isRejectDisabled = function (){
            // if not defined default to not-disabled/false
            if ( _.isUndefined(scope.vxAlert.rejectOptions.disabled)) {
               scope.vxAlert.rejectOptions.disabled = false;
            }
            return scope.vxAlert.rejectOptions.disabled;
         };

         scope.onConfirmClick = function (event){
            if (angular.isDefined(scope.vxAlert.confirmOptions.onClick) &&
                  angular.isFunction(scope.vxAlert.confirmOptions.onClick)) {
               scope.vxAlert.confirmOptions.onClick.call(undefined,event);
            }
            // hide alert right now the alert closes immediately after hitting yes or no
            // TODO: jaked move this to a service and give control to user to close the alert.
            scope.vxAlert.visible = false;
         };

         scope.onRejectClick = onReject;

         scope.getConfirmText = function (){
            return scope.vxAlert.confirmOptions.label  ;
         };

         scope.getRejectText = function (){
            return scope.vxAlert.rejectOptions.label;
         };

         if (scope.vxAlert.rejectOptions.focused) {
            $timeout(function() {
               element.find('.reject-button').focus();
            }, 0);
         }
      }
   };
}
}());
