/// <reference path="../../../types/clarity/index.d.ts" />

module platform {

// --------------- shim for tabs ----------------------
   export class TabsDirective implements INg2DowngradedDirective {

      restrict = "E"; //realized like a component

      bindToController = {};
      hostToController = {};
      terminal = false; // no guarantee

      // via clarity source
      require: any;
      controllerAs = "tabsCtrl"; // ng 1.5 needs identifier to bind to controller, remove in later versions?

      controller = class TabsDirectiveController {
         clarityTabs: any;

         // clarity concepts
         id: string;
         active: boolean = false;

         // strict ng4 Zone territory :: @ContentChildren refs
         tabLinkComponentsList: Array<any>; // Array<TabLink>
         tabContentComponentsList: Array<any>; // Array<TabComponent>

         TabLinkClass: any; // class
         TabContentClass: any; // class
         timer:any; //timer for canceling timeout created in postLink

         constructor(private $scope: angular.IScope, private $element: angular.IAugmentedJQuery, private $attrs: any, private $timeout: angular.ITimeoutService, private clarityExtender: ClarityExtenderService) {
            // host bindings @Input
         }

         $onInit() {
         }

         $postLink() {
            this.clarityExtender.preparingHostComponent(this.$element).then((cmp) => {
               this.clarityTabs = cmp; // bindable component as it's prepared

               // tick...
               // Add second argument, temporary solution in order the tests to work
               this.timer = this.$timeout(() => {
                  this.afterViewInit();
               }, 0);

            });

         }

         $onDestroy() {
            if (this.timer) {
               this.$timeout.cancel(this.timer);
            }
         }

         // Respond after Angular initializes the component's views and child views.
         // @ContentChildren initialized at this sequence
         afterViewInit() {
            this.applyComponentPatch();
            // higher dimension alignment of children TabLink components
            this.clarityTabs.tabLinkChildren.reset(this.queryTabLinkContentChildren());
            // higher dimension alignment of children TabContent components
            this.clarityTabs.tabContentChildren.reset(this.queryTabContentContentChildren());

            // sync
            this.clarityTabs.setUpLinksAndContents();

            // assign safely class references
            if (Array.isArray(this.tabContentComponentsList)) {
               this.TabContentClass = this.tabContentComponentsList[0]!.constructor;
            }
            if (Array.isArray(this.tabLinkComponentsList)) {
               this.TabLinkClass = this!.tabLinkComponentsList[0]!.constructor;
            }
         }

         // scan within component QueryList
         queryTabLinkContentChildren() {
            return this.tabLinkComponentsList = this.clarityExtender.getChildrenComponentsList("> UL > clr-tab-link", this.$element);
         }

         queryTabContentContentChildren() {
            return this.tabContentComponentsList = this.clarityExtender.getChildrenComponentsList("> clr-tab-content", this.$element);
         }


         // accommodate shortcoming in AoT builds
         // WRONG  mapped content projection
         private applyComponentPatch() {

            // organization of "lost" TabContent blocks. bug in downgraded AoT?
            this.clarityExtender.getChildrenNodesList("> UL > clr-tab-content", this.$element)
               .forEach((sheep) => this.$element[0].appendChild(sheep));
         };

         static $inject: Array<string> = ["$scope", "$element", "$attrs", "$timeout", 'ClarityExtenderService'];
      };

      link: angular.IDirectiveLinkFn = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, requiredCtrlMap: any) => {

      };

      constructor(private clarityExtender: ClarityExtenderService) {
         // shared state only
         this.controller["HostToController"] = this.hostToController; // static inject
      }

      static Metastructure: AngularMetastructure<angular.IDirectiveFactory> = {
         name: "clrTabs", // referenced name
         factory: ["ClarityExtenderService", (ClarityExtenderService: ClarityExtenderService) => new TabsDirective(ClarityExtenderService)]
      };

   }

   angular.module("clarity-bridge").directive(TabsDirective.Metastructure.name, TabsDirective.Metastructure.factory);
}
