/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Utility method
 */
angular.module('com.vmware.platform.ui').factory('extensionCategoryUtil', [
function() {
   'use strict';
   return {
      /*
       * Given the categories(parentExtensions) and a list of childextensions, map the
       * children to a parent by matching the categoryUid of the child with the uid of the parent
       *
       */
      mergeCategories: function(categories, extensions) {
         if (!(extensions && extensions[0])) {
            return [];
         }
         //Add two empty categories to handle uncategorised extensions

         var UNCATEGORIZED_VSPHERE_EXT = "_Uncategorized_vSphere_Extensions_";
         var UNCATEGORIZED_PLUGIN_EXT = "_Uncategorized_Plugin_Extensions_";
         categories.push({uid: UNCATEGORIZED_VSPHERE_EXT});
         categories.push({uid: UNCATEGORIZED_PLUGIN_EXT});
         // [childSpecs] to {categoryId,[extensions]}
         var group = _(extensions).groupBy(function(extension) {
            if (!extension.categoryUid) {
               if (extension.contentSpec && extension.contentSpec.sandbox) {
                  extension.categoryUid = UNCATEGORIZED_PLUGIN_EXT;
               } else {
                  extension.categoryUid = UNCATEGORIZED_VSPHERE_EXT;
               }
            }
            return extension.categoryUid;
         });

         var combinedCategories = _.chain(categories)
               .map(function(category) {
                  if (!group[category.uid]) {
                      return null;
                  }
                  var extensions = group[category.uid];
                  var isExternalPlugin = extensions && extensions[0] &&
                        extensions[0].contentSpec && extensions[0].contentSpec.sandbox;
                  return {
                     title: category.label,
                     exts: group[category.uid],
                     categoryId: category.uid,
                     isExternalPlugin: isExternalPlugin
                  };
               })
               .compact()
               .partition(function (category) {
                  return category.isExternalPlugin;
               })
               .sortBy(function (partition) {
                  var isExternalPlugin = partition.length && partition[0].isExternalPlugin;
                  return isExternalPlugin ? 1 : 0;
               })
               .compact()
               .flatten(true).value();
         return combinedCategories;
      }
   };
}]);
