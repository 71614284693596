/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Defines task-related constants
 */
angular.module('com.vmware.platform.ui').constant('taskConstants', {
   status: {
      ALL: 'all',
      RUNNING: 'RUNNING',
      ERROR: 'ERROR',
      CANCELED: 'CANCELED',
      SUCCESS: 'SUCCESS',
      PAUSED: 'PAUSED'
   },
   events: {
      RECENT_TASKS: 'recent-tasks',
      UPDATED: 'TASKS_UPDATED',
      ADDED: 'TASKS_ADDED',
      DELETED: 'TASKS_DELETED',
      COMPLETED: 'TASKS_COMPLETED',
      FAILED: 'taskFailed'
   }
});