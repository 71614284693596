angular.module('com.vmware.platform.ui').controller('vxStackviewController', ['$scope',
    function($scope) {
        var thisController = this;
        $scope.stackviewItems = [];

        $scope.commitProperties = function() {
            if ($scope._stackViewItemsChanged) {
                thisController.analyzeStackviewItems();
                delete $scope._stackViewItemsChanged;
            }
        };

        this.registerStackviewItem = function(stackviewItemScope) {
            if ($scope.stackviewItems.indexOf(stackviewItemScope) < 0) {
                $scope.stackviewItems.push(stackviewItemScope);
            }
            //leave this outside of the if block. This method can be used for level changes etc and not just for registering new stackViewItems.
            $scope._stackViewItemsChanged = true;
        };

        this.unregisterStackviewItem = function(stackviewItemScope) {
            var idx = $scope.stackviewItems.indexOf(stackviewItemScope);
            if (idx > -1) {
                $scope.stackviewItems.splice(idx, 1);
                $scope._stackViewItemsChanged = true;
            }
        };

        this.analyzeStackviewItems = function() {
            var i, j;
            var chkParentScope;
            var len = $scope.stackviewItems.length;
            var level;
            var parentLevel;
            var parentScope;
            var stackviewItems = $scope.stackviewItems;
            var stackviewItemScope;

            for (i=0; i<len; i++) {
                stackviewItemScope = stackviewItems[i];
                parentScope = null;
                stackviewItemScope.children = null;
                level = parseInt(stackviewItemScope.level, 10);
                level = !level ? 0 : level;

                //find the parent
                for (j=i-1; j>=0; j--) {
                    chkParentScope = stackviewItems[j];
                    parentLevel = parseInt(chkParentScope.level, 10);
                    parentLevel = !parentLevel ? 0 : parentLevel;
                    if (parentLevel < level) {
                        parentScope = chkParentScope;
                        break;
                    }
                }

                if (parentScope) {
                    if (!parentScope.children) {
                        parentScope.children = [];
                    }
                    parentScope.children.push(stackviewItemScope);
                    stackviewItemScope.indent = parentScope.indent+1;
                    stackviewItemScope.isVisible = !!parentScope.isExpanded;
                } else {
                    stackviewItemScope.indent = 0;
                    stackviewItemScope.isVisible = true;
                }
            }

            $scope.$watch($scope.commitProperties);
        };
    }
]);


/**
 * @ngdoc directive
 * @name com.vmware.platform.ui.directive:vxStackview
 *
 * @description
 * A Stackview widget with expand/ collapse functionality for read/ edit child views.<BR><BR>
 *
 * The StackView displays dynamic data (using ng-repeat) or flat data (JSON structure). This widget provides the following functionality ... <BR>
 * 1. Expand/ Collapse button to the left if a StackviewItem is the parent of other StackviewItems.<BR>
 * 2. Multi levels: There is no restriction to the number of levels the Stackview can expand up to. <BR><BR>
 *
 * Sample usage with dynamic data (ng-repeat):<BR>
 * <pre><code>
 *      <table vx-stackview>
 *           <tr level="{{obj.lvlx}}" vx-stackview-item ng-repeat="obj in myData">
 *               <td vx-stackview-item-label>{{obj.firstName}}</td>
 *               <td vx-stackview-item-value>{{obj.lvlx}}</td>
 *           </tr>
 *       </table>
 *
 *       OR
 *
 *       <vx-stackview>
 *           <vx-stackview-item level="{{obj.lvlx}}"  ng-repeat="obj in myData">
 *               <vx-stackview-item-label>{{obj.firstName}}</vx-stackview-item-label>
 *               <vx-stackview-item-value>{{obj.lvlx}}</vx-stackview-item-value>
 *           </vx-stackview-item>
 *       </vx-stackview> *
 * </code></pre>
 *
 * level: It is the responsibility of the developer (using this widget) to provide the level (refer above example and below too). Levels are
 * what determine the parent/ child relationships. They are relative numbers (relative between the parent/child).<BR><BR>
 *
 * Sample usage with flat data (JSON structure):<BR>
 * <pre><code>
            <vx-stackview>
                <vx-stackview-item level="100">
                    <vx-stackview-item-label>Name: </vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.fullName}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="101">
                    <vx-stackview-item-label>First Name:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.firstName}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="101">
                    <vx-stackview-item-label>Middle Name:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.middleName}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="101">
                    <vx-stackview-item-label>Last Name:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.lastName}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="50">
                    <vx-stackview-item-label>Address: </vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.fullAddress}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="51">
                    <vx-stackview-item-label>Street:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.street1}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="51">
                    <vx-stackview-item-label>City:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.city}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="51">
                    <vx-stackview-item-label>State:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.state}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="51">
                    <vx-stackview-item-label>zip:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.zip}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="51">
                    <vx-stackview-item-label>Country:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.fullCountry}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="52">
                    <vx-stackview-item-label>Code:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.country.code}}</vx-stackview-item-value>
                </vx-stackview-item>
                <vx-stackview-item level="52">
                    <vx-stackview-item-label>Name:</vx-stackview-item-label>
                    <vx-stackview-item-value>{{employee.address.country.name}}</vx-stackview-item-value>
                </vx-stackview-item>
            </vx-stackview>
 * </code></pre>
 *
 * Some things to note in the above example ...<BR>
 * 1. The 1st StackviewItem has a level=100. The 2nd StackviewItem has level=101. Hence the 2nd StackviewItem is the child of the 1st StackviewItem and the 1st
 * StackviewItem will show the expand/ collapse button (because it has at least 1 child).<BR>
 * 2. The StackviewItemLabel's inner HTML will become the label for that StackviewItem.<BR>
 * 3. The StackviewItemValue's inner HTML will become the value for that StackviewItem.<BR>
 * 4. The inner HTML of the label and value can be complex html structure (as long it is vaid).<BR>
 * 5. The above example could have been written with table, tr, td tags. Except that those tags would need to contain the relevant
 * Stackview attributes (refer to the ng-repeat) example. <BR><BR>
 *
 * @element table
 * @scope isolated
 * @priority default
 * @transclude true
 *
 *
 * @see com.vmware.platform.ui.directive:vxStackviewItem
 * @see com.vmware.platform.ui.directive:vxStackviewItemLabel
 * @see com.vmware.platform.ui.directive:vxStackviewItemValue
 */

angular.module('com.vmware.platform.ui').directive('vxStackview', [
    function() {
        return {
            restrict: 'EA',
            scope: {},
            template: '<table ng-transclude ng-class="{stackview: true}"></table>',
            replace: true,
            transclude: true,
            controller: 'vxStackviewController',
            link: function(scope, iElement, iAttrs, controller) {
                controller.analyzeStackviewItems();
            }
        };
    }
]);


/**
 * @ngdoc directive
 * @name com.vmware.platform.ui.directive:vxStackviewItem
 *
 * @description
 * A StackviewItem is a child of the Stackview.<BR><BR>
 *
 * @element tr
 * @scope isolated
 * @priority default
 * @transclue true
 *
 *
 * @see com.vmware.platform.ui.directive:vxStackview
 * @see com.vmware.platform.ui.directive:vxStackviewItemLabel
 * @see com.vmware.platform.ui.directive:vxStackviewItemValue
 */


angular.module('com.vmware.platform.ui').directive('vxStackviewItem', [
    function() {
        return {
            require: '^vxStackview',
            restrict: 'EA',
            scope: {level: '@'},
            template: '<tr ng-transclude ng-show="isVisible"></tr>',
            replace: true,
            transclude: true,
            controller: ["$scope", function($scope) {
                this.registerLabelValue = function(stackviewItemLabelValueScope) {
                    stackviewItemLabelValueScope.stackviewItem = $scope;
                };
            }],
            link: function(scope, iElement, iAttrs, stackviewController) {
                var tr = iElement;

                scope.level = scope.level || 0;

                scope.getItemCSS = function(value) {
                    var newClass = 'sv-indent' + scope.indent;
                    if (!scope.hasChildItems()) {
                        newClass += ' sv-expandCollapseNone';
                    } else {
                        if (scope.isExpanded) {
                            newClass += ' sv-expanded';
                        } else {
                            newClass += ' sv-collapsed';
                        }
                    }

                    return newClass;
                };

                scope.hasChildItems = function() {
                   return scope.children && scope.children.length;
                };

                scope.expandCollapse = function(value) {
                    scope.showHideChildren();
                };


                scope.showHideChildren = function() {
                    var i, childScope,
                        children = scope.children,
                        len = children ? children.length : 0;

                    for (i=0; i<len; i++) {
                        childScope = children[i];
                        childScope.isVisible = scope.isExpanded && scope.isVisible;
                    }
                };

                scope.onLevelChange = function(value) {
                    stackviewController.registerStackviewItem(scope);
                };

                scope.$watch('isExpanded', scope.expandCollapse);
                scope.$watch('isVisible', scope.expandCollapse);
                scope.$watch('level', scope.onLevelChange);

                stackviewController.registerStackviewItem(scope);
            },
            compile: function(tElement, tAttrs, transclude) {
                return this.link;
            }
        };
    }
]);


/**
 * @ngdoc directive
 * @name com.vmware.platform.ui.directive:vxStackviewItemLabel
 *
 * @description
 * A StackviewItemLabel is a child of the StackviewItem.<BR><BR>
 *
 * @element td
 * @scope isolated
 * @priority default
 * @transclue true
 *
 *
 * @see com.vmware.platform.ui.directive:vxStackview
 * @see com.vmware.platform.ui.directive:vxStackviewItem
 * @see com.vmware.platform.ui.directive:vxStackviewItemValue
 */


angular.module('com.vmware.platform.ui').directive('vxStackviewItemLabel', [
    function() {
        return {
            require: ['^vxStackviewItem'],
            restrict: 'EA',
            template: '<td class="sv-label" ng-click="toggle()"><div class="sv-hbox"><div ng-class="stackviewItem.getItemCSS()"></div><div ng-transclude></div></div></td>',
            replace: true,
            transclude: true,
            controller: ["$scope", function($scope) {
                $scope.toggle = function() {
                   if($scope.stackviewItem.hasChildItems()) {
                      $scope.stackviewItem.isExpanded = !$scope.stackviewItem.isExpanded;
                   }
                };
            }],
            link: function(scope, iElement, iAttrs, controllers) {
                var stackviewItemController = controllers[0];

                stackviewItemController.registerLabelValue(scope);
            }
        };
    }
]);




/**
 * @ngdoc directive
 * @name com.vmware.platform.ui.directive:vxStackviewItemValue
 *
 * @description
 * A StackviewItemValue is a child of the StackviewItem.<BR><BR>
 *
 * @element td
 * @scope isolated
 * @priority default
 * @transclue true
 *
 *
 * @see com.vmware.platform.ui.directive:vxStackview
 * @see com.vmware.platform.ui.directive:vxStackviewItem
 * @see com.vmware.platform.ui.directive:vxStackviewItemLabel
 */
angular.module('com.vmware.platform.ui').directive('vxStackviewItemValue', [
    function() {
        return {
            require: '^vxStackviewItem',
            restrict: 'EA',
            template: '<td class="sv-value"><div ng-transclude ng-show="!stackviewItem.isExpanded"></div></td>',
            replace: true,
            transclude: true,
            link: function(scope, iElement, iAttrs, stackviewItemController) {
                stackviewItemController.registerLabelValue(scope);
            }
        };
    }
]);
