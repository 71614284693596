/// <reference path="../../../types/clarity/index.d.ts" />
/**
 * Angular 2 downgraded components require special care because content projection with children sub-components breaks
 * These utilities are crafted to let special directives for Clarity tabs, wizard to workaround known Angular defect
 * Goal is to offer tooling for injector detection and expose underlying component
 */

module platform {


   export class ClarityExtenderService {

      constructor(private $q: ng.IQService, private $parse: ng.IParseService) {
         this.scopePseudoNotationSubstrate();
      }


      // reassemble :scope support for deadhead MS browsers
      private scopePseudoNotationSubstrate() {
         (function (doc, elemProto) {
            try {
               doc.querySelector(":scope BODY");
            } catch (err) {
               for (let method of ['querySelector', 'querySelectorAll']) {
                  const nativ = elemProto[method];
                  elemProto[method] = function (selectors) {
                     if (/(^|,)\s*:scope/.test(selectors)) { // only if selectors contains :scope
                        const id = this.id; // remember
                        this.id = 'scopedPseudo_' + Date.now(); // new unique id
                        selectors = selectors.replace(/((^|,)\s*):scope/g, '$1#' + this.id); // replace :scope with #ID
                        const result = doc[method](selectors); // trigger
                        this.id = id; // restore previous id
                        return result;
                     } else {
                        return nativ.call(this, selectors); // norml routine
                     }
                  };
               }
            }
         })(window.document, Element.prototype);
      }


      // utility : host bindings @Input emulated
      public observeHostToController(hostMap: {[identifier: string]: string}, componentInstance: any, componentScope: ng.IScope, componentAttributes: ng.IAttributes) {
         const rules: Array<[string, Function]> = [], list = Object.keys(hostMap);
         for (let internalProperty of list) {

            let externalProperty = hostMap[internalProperty];
            // track rules for assigner
            rules.push([
               externalProperty,
               // binder back to live component onchange
               (val: any) => {
                  componentInstance[internalProperty] = val;
               }]);
         }

         // next, observe
         this.observeHostInputsList(rules, componentScope, componentAttributes);
      }

      // routine :: internally host bindings @Input emulated
      private observeHostInputsList(rules: Array<[string, Function]>, componentScope: ng.IScope, componentAttributes: ng.IAttributes) {

         for (let [bindingProperty, valueAssigner] of rules) {

            let observedProperty = bindingProperty;
            // unless it doesn't have brackets ( then rewrite )
            if (observedProperty.indexOf("[") !== 0 && observedProperty.substr(observedProperty.length - 1) !== "]") {
               observedProperty = "[" + componentAttributes.$normalize(observedProperty) + "]"; // prefer ng4 wrapping nomenclature
            }

            componentAttributes.$observe(observedProperty, (val: any) => {
               valueAssigner(this.$parse(val)(componentScope)); // deref expression and call assignment
            });
         }
      }


      /**
       * Notes: https://github.com/angular/angular/commit/1367cd9 - believe wiring process to be different
       *
       * In order to enable more control over the wiring of downgraded components and
       * their content (which eventually allows better control over features like
       * injector setup and content projection), it was necessary to change the
       * implementation of the directives generated for downgraded components.
       * The directives are now terminal and manually take care of projecting and
       * compiling their contents in the post-linking function. This is similar to how
       * the dynamic version of `upgrade` does it.
       * This is not expected to affect apps, since the relative order of individual
       * operations is preserved. Still, it is difficult to predict how every possible
       * usecase may be affected.
       * @param elem
       * @returns {any}
       */
      // two ways of preparation : encapsulated
      public preparingHostComponent(elem: ng.IAugmentedJQuery): ng.IPromise<any> {
         // note: this reference may not have been fully resolved, due to deep component composition
         //const injectableRef = elem.inheritedData()[this.getInjectorControllerName()],
         const $q = this.$q.defer();

         this.fetchingComponentInjectorReference(elem).then((injectableRef) => {
            const m = {[ this.injectorName ]: injectableRef};

            $q.resolve(injectableRef.then
               ? this.fetchingHostComponent(m) // injectable unavailable ( still ... processing )
               : this.getHostComponent(injectableRef) // grab it by the pointer!
            );

         });

         return $q.promise;
      }

      // ng4 wires downgraded components using a different technique
      // may yield injector OR a promise that resolves to an injector
      public fetchingComponentInjectorReference(elem: ng.IAugmentedJQuery): ng.IPromise<any> {
         const $q = this.$q.defer(),
            icn = this.getInjectorControllerName(),
            injectableRef = _getReference();

         if (injectableRef) {
            $q.resolve(injectableRef);
         }

         // children composition components can take additional cycles
         else {
            // awaits ng4 component fabrication and linking process. must complete no exception
            const _ticker = setInterval(() => {
               const r = _getReference();
               if (r) {
                  clearInterval(_ticker);
                  $q.resolve(_getReference());
               }
            }, 0);
         }
         return $q.promise;

         function _getReference() {
            return elem.data(icn);
         }
      }


      // resolves NG4 component from an element: critical piece in normalizing interpretation of nested ng2 components
      public fetchingHostComponent(controllerMap: any): ng.IPromise<any> {
         const $q = this.$q.defer();

         let injectorPromise = controllerMap[this.injectorName];
         if (injectorPromise && injectorPromise.then) { // promise-like
            injectorPromise.then((elemInjector: any) => $q.resolve(this.getHostComponent(elemInjector)));
         }
         else {
            $q.reject(); // malformed
         }

         return $q.promise;
      }


      // for external access in a template to an NG1 directive, resolve its class controller instance ( bound to elem )
      // normalized selector in the form of "clrTabs"
      getElementControllerByIdSelector(markerId: string, normalizedSelector: string, containerNodeElement: ng.IAugmentedJQuery|HTMLElement): any {

         if (normalizedSelector.indexOf("-") > 0) {
            return;
         }

         const expandedControllerNotation = "$" + normalizedSelector + "Controller"; //ng1 factory notation
         const $targetElement = angular.element(this.determineContainerDOM(containerNodeElement).querySelector("[" + '\\' + "#" + markerId + "]")); // using marker

         let potentials = $targetElement.data();
         // extract
         let probableClassMatch = Object.keys(potentials)
         // likely match given notation
            .filter((k) => [expandedControllerNotation, normalizedSelector].indexOf(k) >= 0)
            .map((k) => potentials[k]);

         if (probableClassMatch.length) {
            return probableClassMatch[0];
         }
      }


      // standard identity technique
      // for external access in a template to an NG4 component decorated with hash notation
      getComponentById(markerId: string, containerNodeElement: ng.IAugmentedJQuery|HTMLElement): any {

         const $targetElement = angular.element(this.determineContainerDOM(containerNodeElement).querySelector("[" + '\\' + "#" + markerId + "]")); // using marker
         if ($targetElement) {
            const injectableRef = $targetElement.data(this.getInjectorControllerName());
            // injectable has resolved and isn't awaiting
            if (injectableRef && !(typeof injectableRef.then === "function")) {
               return this.getHostComponent(injectableRef);
            }
         }

      }

      private determineContainerDOM(containerNodeElement: ng.IAugmentedJQuery|HTMLElement): HTMLElement {
         // decide which particle?
         const originalChildNode = containerNodeElement[0];
         // IE misinterprets
         const containerDOM = ( originalChildNode && (originalChildNode instanceof HTMLElement || containerNodeElement[0] instanceof HTMLUnknownElement ))
            ? containerNodeElement[0]
            : containerNodeElement;

         return containerDOM;
      }


      //internal only for safety:: having an injector, extract the component
      private getHostComponent(ng4Injector: any): any {
         return ng4Injector.view.nodes[0].componentView.component;

         /*
          20170227 - special notes for previous build before view engine refactor from Angular
          let nodeIndex        = 0, // assumption is the component is first in the template where it is defined
          primarySpecCmpId = `compView_${nodeIndex}`; // proven via view_compiler as such - keep a watchful eye
          return ng4Injector._view.ref.internalView[primarySpecCmpId].context; // targeted via component factory
          */
      }

      // find a list of angular DOM fragments in the sub-hierarchy
      public getChildrenNodesList(selector: string, elem: ng.IAugmentedJQuery): Array<any> {
         const scopeDescendants = ":scope "; // baseElement bracing
         //const children: Array<any> = Array.prototype.slice.call(elem.find(selector))
         return Array.prototype.slice.call(elem[0].querySelectorAll(scopeDescendants + selector));
      }


      // find a list of angular components in the sub-hierarchy
      public getChildrenComponentsList(selector: string, elem: ng.IAugmentedJQuery): Array<any> {

         return this.getChildrenNodesList(selector, elem)
         // xform and extract underlying component
            .map((elem: ng.IAugmentedJQuery) => {
               return this.getHostComponent(angular.element(elem).data(this.getInjectorControllerName()));
            });
      }


      // supply ng4 injected promise by its specific compilation phase
      public augmentRequire(requireMap: any, searchAncestors: boolean = true): any {
         // generally scan ancestors, unless overrriden
         requireMap[this.injectorName] = searchAncestors ? "^^" : "";// ng4 downgraded enclosing
         return requireMap;
      }

      // canonical definition from the scriptures
      get injectorName(): string {
         //return "ng2.Injector"; // ng2
         return "$$angularInjector"; // ng 4 2017
      }

      // buried in inherited data
      public getInjectorControllerName(): string {
         return "$" + this.injectorName + "Controller";
      }

      static Metastructure: AngularMetastructure<ng.IDirectiveFactory> = {
         name: "ClarityExtenderService", // referenced name
         factory: ["$q", "$parse", ($q: ng.IQService, $parse: ng.IParseService) => new ClarityExtenderService($q, $parse)]
      };

   }

   angular.module("clarity-bridge").service(ClarityExtenderService.Metastructure.name,
      ClarityExtenderService.Metastructure.factory);
}

