/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service to persist the navigation tree
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('navigationTreeService', navigationTreeService);

   navigationTreeService.$inject = ['$http', '$q', 'httpCancellationService', 'logService'];

   function navigationTreeService($http, $q, httpCancellationService, logService) {
      var notImplementedUrl = "common-module-ui/resources/common-module/views/NotImplemented.html";
      var notSupportedUrl = "common-module-ui/resources/common-module/views/NotSupported.html";
      var errorUrls = {
         OBJECT_NOT_FOUND: "resources/ui/views/objectnavigator/error/ObjectNotFound.html"
      };
      var cancellableRequest = httpCancellationService();

      var log = logService('navigationTreeService');

      // Public API
      return {
         /**
          * Fetch the NavigationTree for a given navigation route.
          *
          * @param {Object} searchParam
          * @param {boolean} cancellable
          * @returns {NavigationTree}
          */
         fetchNavigationTree: fetchNavigationTree,

         /**
          * Cancels previous navigation tree fetch.
          */
         cancelPrevious: cancellableRequest.cancel
      };

      function fetchNavigationTree(searchParam, cancellable) {
         var config = {
            method: 'get',
            url: 'navigation/data/' + searchParam.extensionId,
            params: {
               objectId: searchParam.objectId
            },
            // errorResponseInterceptor.js will intercept all 40x, 500, etc errors
            // and it will display a popup with an error message. We want to prevent
            // that code from executing and handle the error ourselves. The reason
            // is that it's possible to send a request for an invalid extension id,
            // because extension ids for tabs are cached in the local storage. Those extension ids
            // could be invalidated by renaming the extension, uninstalling the plugin providing
            // the extension, etc. In that case, we'll clear the local storage entry
            // and navigate the user to the 1st tab of the object.
            skipErrorInterceptor: true
         };

         var promise = cancellable ? cancellableRequest.fetch(config) : $http(config);

         return promise.then(function (resp) {
            if (!resp) {
               var reason = 'undefined response to navigation/data/' + searchParam.extensionId;
               log.error(reason);
               return  $q.reject(reason);
            }
            var tree = resp.data;
            NavigationTree.everyNode(tree, function (node, parent) {
               // Having a contentSpec but no contentSpec.url means it's a non supported Flex view
               var url = errorUrls[node.error] ||
                  (node.extensionObject.contentSpec && (node.extensionObject.contentSpec.url || notSupportedUrl)) ||
                  notImplementedUrl;

               angular.extend(node, {
                  $id: node.error || node.extensionObject.uid,
                  $children: node.children,
                  $parent: parent || null,
                  $selectedChildIndex: node.childIndexToActivate,
                  $templateUrl: url,
                  $viewRetentionPolicy: (node.extensionObject && node.extensionObject.contentSpec && node.extensionObject.contentSpec.viewRetentionPolicy) ?
                        node.extensionObject.contentSpec.viewRetentionPolicy : null
               }, node.extensionObject);
            }, 'children');

            return new NavigationTree(tree);
         }, function (err) {
            return $q.reject(err);
         });
      }
   }
})();
