/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   listViewDropTargetDirective.$inject = [
      "listViewDropTargetHandler"
   ];

   function listViewDropTargetDirective(
         listViewDropTargetHandler): angular.IDirective {
      return {
         restrict: "A",
         controller: function ($scope, $element, $attrs) {
            let isDropTarget: boolean =  $scope.$eval($attrs.listViewDropTarget);
            if (isDropTarget) {
               let listViewDropTargetCtrl = new ListViewDropTargetController(
                  $element, listViewDropTargetHandler);

               $scope.$on("$destroy", () => {
                  listViewDropTargetCtrl.destroyDropTarget();
               });
            }
         }
      };
   }

   export class ListViewDropTargetController {
      private _listViewDropTargetHandler: ListViewDropTargetHandler;
      private static DROP_TARGET_GROUP_NAME = "gridGroup";

      public constructor(
            $element: any,
            listViewDropTargetHandler: ListViewDropTargetHandler) {
         this._listViewDropTargetHandler = listViewDropTargetHandler;
         this.initListViewDropTarget($element);
      }

      private initListViewDropTarget($element: any) {
         let kendoGrid: any = $element.children("div[kendo-grid]");
         kendoGrid.kendoDropTarget({
            // accept drops coming only from 'gridGroup'
            group: ListViewDropTargetController.DROP_TARGET_GROUP_NAME,
            filter: ".k-grid-content tr", // drop only on the row
            drop: ($event: Event) =>  {
               this.onDropFromGridGroup($event);
            }
         });
      }

      private onDropFromGridGroup($event: any): void {
         this._listViewDropTargetHandler.drop($event);
      }

      destroyDropTarget() {
         //destroy components and detach events, otherwise leaks
         // significant memory
         kendo.ui.DropTarget
               .destroyGroup(ListViewDropTargetController.DROP_TARGET_GROUP_NAME);
      }
   }

   angular.module("com.vmware.platform.ui").directive(
         "listViewDropTarget", listViewDropTargetDirective);
}
