/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Adds the cancelOnDestroy: $scope, option to $http() requests. When specified, the http
 * request will be automatically aborted if the scope $destroy event fires.
 */
angular.module('com.vmware.platform.ui').config(["$httpProvider", function($httpProvider) {

   $httpProvider.interceptors.push(["$q", function($q) {
      return {
         'request' : function(config) {
            if (config.cancelOnDestroy) {
               var deferred = $q.defer();

               config.timeout = deferred.promise;
               config.cancelOnDestroy.$on("$destroy", function(){
                  deferred.resolve();
               });
            }

            return config;
         }
      };
   }]);

}]);
