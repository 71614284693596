/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The typeahead directives provide functionality for the typeahead in input boxes.
 * The directive requires a config object to be passed as an attribute
 * The config object must contain a key dataSource which should be a function which
 * returns the data to be fetched on change of the input box
 * Results from the data source are set on the same config object along with a searching
 * flag
 * config structure
 *
 * dataSource
 * onSelectCallback
 * isInputRefocused - after triggering a search by icon, refocus the input box
 *
 *
 * Possible enhancements
 *   Pass a variable waitTime and minimum number of letters to be typed before getting the
 *   results
 *   Pass a formatting function which could format the inputBox text based on the results
 * Usage :
 *       <input ng-model="searchTerm"  vx-typeahead="typeAheadConfig"/>
 */
angular
   .module('com.vmware.platform.ui')
   .directive('vxTypeahead', ['$parse', '$timeout', '$document',
function ($parse, $timeout, $document) {
   return {
      require:['ngModel'],
      restrict:'A',
      link : function postLink(scope, element, attrs, modelCtrl) {

         // get the config for the typeahead
         var typeaheadConfig =  scope.$eval(attrs.vxTypeahead);

         // the function which returns the results
         var dataSource = typeaheadConfig.dataSource;
         // wait 150ms before starting the search
         var waitTime = 150;
         // the text of the input text box
         var $getModelValue = $parse(attrs.ngModel);

         // Declare the timeout promise var outside the function scope
         // so that stacked calls can be cancelled later
         var timeoutPromise;

         // function to get results from outside the directive
         typeaheadConfig.doSearch = function(){
            getSearchResults(modelCtrl[0].$modelValue);
            if ( typeaheadConfig.isInputRefocused ) {
               element.focus(); // draws attentions and user access to edit results
            }
         };

         // watch input text and get results
         scope.$watch(function(){
            return modelCtrl[0].$modelValue;
         }, function(inputValue) {
            getSearchResults(inputValue);
         });

         var getSearchResults = function(inputValue) {
            resetMatches();
            // if we have non empty input string
            if (inputValue && inputValue.length >= 0) {
               $timeout.cancel(timeoutPromise);    // cancel previous timeout
               timeoutPromise = $timeout(function() {
                  getResultsAsync(inputValue); // get the results
               }, waitTime);
            }
            else {
               resetMatches();
            }
         };

         // reset matches
         var resetMatches = function () {
            typeaheadConfig.results = undefined;
            typeaheadConfig.searching = false ;
            typeaheadConfig.searchTerm = undefined;
         };

         // get the results from given dataSource
         var getResultsAsync = function(inputValue) {
            var currentSearchTerm = modelCtrl[0].$viewValue;
            if (!currentSearchTerm || currentSearchTerm.length === 0) {
               // no need to spawn new search request if current search term is empty
               resetMatches();
               return;
            }
            typeaheadConfig.searching = true; // start searching
            //get the data
            dataSource(inputValue).then(function(data) {
               // there could be several async queries if the user was typing fast
               // set the results only if the current string is the same as the input
               if (inputValue && inputValue === modelCtrl[0].$viewValue){
                  if (data){
                     typeaheadConfig.searchTerm = inputValue;
                     typeaheadConfig.searching = false;
                     typeaheadConfig.results = data;
                  }
               }
            });
         };

         //bind keyboard events: arrows up(38) / down(40), enter(13) and tab(9), esc(27)
         element.bind('keydown', function (evt) {
            if(typeaheadConfig.onKeyPress){
               typeaheadConfig.onKeyPress(evt);
            }
         });

         // Keep reference to click handler to unbind it.
         var dismissClickHandler = function (evt) {
            if (element[0] !== evt.target) {
               resetMatches();
            }
         };

         $document.bind('click', dismissClickHandler);

         scope.$on('$destroy', function(){
            $document.unbind('click', dismissClickHandler);
         });
      }
   };
}]);
