/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * @ngdoc directive
 * @name com.vmware.platform.ui:vxError
 *
 * @description
 *
 * Directive to show an error tooltip on error in an input.
 * Tooltip is shown when mouseenter event is triggered on the element the directive is attached to.
 * The tooltip will be visible only if the message is not empty and isVisible is set to true. The tooltip is hidden on mouseleave or if the error message becomes empty.
 *
 * |---------|  /-----------------------|
 * | Input   | <  This is the tooltip   |
 * |---------|  \-----------------------|
 *
 *
 * ******
 * Usage:
 * ******
 *
 *  <input type="text" vx-error="booleanConfig"></input>
 *  <input type="text" vx-error="functionConfig"></input>
 *
 *  <script>
 *     $scope.booleanConfig = {
 *        message: 'Some error message',
 *        isVisible: true/false
 *     };
 *
 *     $scope.functionConfig = {
 *        message: 'Some error message',
 *        isVisible: function() {
 *          if(condition){ return true; }
 *          return false;
 *        }
 *     };
 *  </script>
 *
 *<BR>
 *
 *
 *
 * @element input
 * @priority default
 *
 */
(function () {
   'use strict';
angular.module('com.vmware.platform.ui')
   .directive('vxError', [
      '$compile', '$window', '$rootScope', 'positionService',
      function($compile, $window, $rootScope, positionService) {

         return {
            restrict: 'EA',
            require: 'ngModel',
            scope: {
               'vxError': '=' // two way binding
            },
            link: function(scope, element, attrs, ctrl) {
               element.bind('mouseenter', show);
               element.bind('mouseleave', hide);

               var bodyElement = angular.element($window.document.body);
               var templateElement;

               scope.getMessage = function() {
                  if (!scope.vxError) {
                     return '';
                  }
                  if (typeof(scope.vxError.message) === 'function') {
                     return scope.vxError.message();
                  }

                  return scope.vxError.message;
               };

               var template = '<div class="vx-error-tooltip tooltip right in">' +
                  '<div class="tooltip-arrow"></div>' +
                  '<div class="tooltip-inner">{{getMessage()}}</div>' +
                  '</div>';

               templateElement = $compile(template)(scope);

               function positionTooltip() {
                  var ttPosition = positionService.positionElements(element, templateElement, 'right', true);
                  ttPosition.top += 'px';
                  ttPosition.left += 'px';

                  templateElement.css(ttPosition);
               }

               function show() {
                  var isErrorMessageVisible;
                  if (typeof(scope.vxError.isVisible) === 'function') {
                     isErrorMessageVisible = scope.vxError.isVisible.call(scope.vxError, ctrl);
                  } else if (typeof(scope.vxError.isVisible) === 'boolean') {
                     isErrorMessageVisible = scope.vxError.isVisible;
                  }
                  if (isErrorMessageVisible) {
                     bodyElement.append(templateElement);
                     positionTooltip();
                  }
               }

               function hide() {
                  if (templateElement) {
                     templateElement.remove();
                  }
               }

               scope.$on('$destroy', function() {
                  if (templateElement) {
                     templateElement.remove();
                  }
                  cancelOnMessageChange();
               });

               var cancelOnMessageChange = scope.$watch('vxError.message', function(value) {
                  if (_.isEmpty(value)) {
                     hide();
                  }
               });
            }
         };
      }
   ]);
}());
