(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').controller('AlarmPopupController', [
      '$scope',
      'i18nService',
      'navigation',
      'defaultUriSchemeUtil',
      'objectTypesService',
      'alarmIssueActionService',
      function($scope, i18nService, navigation, defaultUriSchemeUtil, objectTypesService, alarmIssueActionService) {
         $scope.alarm = $scope.vuiSignPost.innerContentScope.alarm;

         $scope.i18n = i18nService.getString;
         navigation.populateScope($scope);

         $scope.triggeringObjectClicked = function () {
            $scope._navigateToObject($scope.alarm.triggeringObjectId);
         };

         $scope.definedInObjectClicked = function () {
            objectTypesService.getAllObjectTypeSpecs().then(function (data) {
               var definedInObjectType = defaultUriSchemeUtil.getPartsFromVsphereObjectId($scope.alarm.definedInObjectId).type;
               var definedInObjectNamespace = data[definedInObjectType].namespace;

               $scope._navigateToViewAndObject(
                  definedInObjectNamespace + '.alarmDefinitionsView',
                  $scope.alarm.definedInObjectId,
                  {alarmId: defaultUriSchemeUtil.getVsphereObjectId($scope.alarm.vimAlarmInfo.alarm)}
               );
            });
         };

         $scope.actions = alarmIssueActionService.buildAlarmActions($scope.alarm.vimAlarmInfo, $scope.alarm.vimAlarmState);

         $scope.alarmActionClicked = function(alarmAction) {
            alarmAction.onClick().then(function() {
               $scope.closeSignPost();
            });
         };
      }]);
})();

