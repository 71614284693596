/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   export class ExportListController implements angular.IComponentController {
      public static $inject = [
         "i18nService"
      ];

      i18n: Function;
      selectedExportType: number;
      readonly exportType = platform.ExportColumnType;
      readonly selectedItemsCount: number;
      readonly columnsById: { [uid: string]: ExportableColumn };
      readonly columns: Array<ExportableColumn>;
      readonly selectedRowsOnlyEnabled: boolean;
      isSelectionInvalid = true;
      selectAll: boolean;


      constructor(i18nService: any) {
         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         // If there is more than one selected item - pre-select Selcted rows
         // only option, otherwise - pre-select All
         // If there are no selected items - disable Selected rows only
         this.selectedExportType = this.selectedItemsCount > 1
               ? this.exportType.SELECTED_ONLY
               : this.exportType.ALL;
         this.selectedRowsOnlyEnabled = this.selectedItemsCount > 0;
         this.columns = _.values(this.columnsById);
         this.onSelectionChange();
      }

      onSelectAllChange(): void {
         if (_.isEmpty(this.columns)) {
            this.isSelectionInvalid = true;
            return;
         }

         _.each(this.columns, (column: ExportableColumn) => {
            column.selected = this.selectAll;

         });

         this.isSelectionInvalid = !this.selectAll;
      }

      onSelectionChange(): void {
         this.isSelectionInvalid =
               !_.some(this.columns, (column: ExportableColumn) => column.selected);
      }
   }

   export class ExportListComponent implements angular.IComponentOptions {
      public controller: any;
      public templateUrl: string;
      public bindings: any;

      constructor() {
         this.controller = ExportListController;
         this.templateUrl = "resources/ui/views/listview/export/export-list.component.html";
         this.bindings = {
            selectedExportType: "=",
            selectedItemsCount: "<",
            columnsById: "=",
            isSelectionInvalid: "=",
            generatingFile: "<"
         };
      }
   }

   angular.module("com.vmware.platform.ui")
         .component("exportList",
               new ExportListComponent());
}
