/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Application Pinger.
 */
angular.module('com.vmware.platform.ui').factory('appPingerService',
   ['configurationService', 'userSessionService', 'vxZoneService', '$rootScope', '$log',
function(configurationService, userSessionService, vxZoneService, $rootScope, $log) {
   'use strict';

   var HTTP_BAD_REQUEST = 400;
   var HTTP_UNAUTHORIZED = 401;
   var HTTP_NO_RESPONSE = 0;

   var SESSION_KEEP_ALIVE_PING_INTERVAL = 10 * 60 * 1000; // 10 minutes

   var sessionKeepAliveTimerId = null;
   var connectionMonitorTimerId = null;

   var connectionAlive = true;

   function onSessionKeepAliveTimerEvent() {
       userSessionService.ping();
   }

   function onConnectionMonitorTimerEvent(requestTimeout) {
      userSessionService.sessionPing(requestTimeout).then(function (status) {
         if (status === HTTP_UNAUTHORIZED) {
            // The user session might have expired.
            pinger.stopConnectionMonitor();
            return;
         }
         if (status === HTTP_NO_RESPONSE || status >= HTTP_BAD_REQUEST) {
            // Usually this status code should not be received here, but nevertheless we
            // check for it for the sake of completeness. In this case the websocket
            // ping service will cause the application to reload, so there is nothing
            // left for us to do here. Same goes for an ordinary client/server error
            // status code.
            return;
         }
         if (_.isUndefined(status)) {
            // Undefined means the ping request has timed out, e.g. the connection to
            // the server is lost at least temporarily.
            if (connectionAlive) {
               connectionAlive = false;
               $rootScope.$emit("backendConnectionLostEvent");
               $log.debug("Server connection lost.");
            }
            return;
         }
         if (status >= 200 && status < HTTP_BAD_REQUEST && !connectionAlive) {
            connectionAlive = true;
            $rootScope.$emit("backendConnectionRestoredEvent");
            $log.debug("Server connection restored.");
         }
      });
   }

   var pinger = {
      /**
       * Starts the application pinger.
       */
      start: function() {
         pinger.startConnectionMonitor();
         pinger.startSessionKeepAlive();
      },

      /**
       * Stops the application pinger.
       */
      stop: function() {
         pinger.stopConnectionMonitor();
         pinger.stopSessionKeepAlive();
      },

      startConnectionMonitor: function() {
         configurationService.getProperty("connecton.state.check.interval")
            .then(function (value) {
               var interval = parseInt(value, 10);
               if (_.isNaN(interval) || interval <= 0) {
                  $log.debug("Connection state monitoring is disabled.");
                  return;
               }
               interval = interval * 1000; // convert to milliseconds
               pinger.stopConnectionMonitor();
               vxZoneService.runOutsideAngular(function () {
                  connectionMonitorTimerId =
                     setInterval(onConnectionMonitorTimerEvent,
                           interval, Math.round(interval / 2));
               });
               $log.debug("Started connection state monitor;" +
                          " check interval =", interval + 'ms');
            });
      },

      stopConnectionMonitor: function () {
         vxZoneService.runOutsideAngular(function () {
            if (connectionMonitorTimerId !== null) {
               clearInterval(connectionMonitorTimerId);
               connectionMonitorTimerId = null;
            }
         });
      },

      startSessionKeepAlive: function() {
         pinger.stopSessionKeepAlive();
         vxZoneService.runOutsideAngular(function () {
            sessionKeepAliveTimerId =
               setInterval(onSessionKeepAliveTimerEvent,
                  SESSION_KEEP_ALIVE_PING_INTERVAL);
         });
      },

      stopSessionKeepAlive: function() {
         vxZoneService.runOutsideAngular(function () {
            if (sessionKeepAliveTimerId !== null) {
               clearInterval(sessionKeepAliveTimerId);
               sessionKeepAliveTimerId = null;
            }
         });
      }
   };

   $rootScope.$on('$destroy', function () {
      pinger.stop();
   });

   return pinger;
}]);
