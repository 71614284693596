/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Utilities for the dataService
 */
angular.module('com.vmware.platform.ui').factory('dataServiceUtil',
      ['$injector',
function($injector) {
   var _dataService = $injector.get('dataService');
   var _vcDataServiceUtil = $injector.get('vcDataServiceUtil');


   return {
      /**
       * Helper method to make it easier to use the modelChanged event.
       * Retrieves attribute on changed objects, and then updates existing object with new attributes.
       *
       * @param $scope
       *    Scope to listens for changes on.
       * @param attrsToFetch
       *    Array of attributes to fetch for changed objects
       * @param getMatching
       *    A function that returns an array of matching objects for a given id
       * @param mappingFunction
       *    A function that receives the new attributes and called on each matching objects
       */
      onModelChanged: function($scope, attrsToFetch, getMatching, mappingFunction) {
         $scope.$on('modelChanged', function(event, objectChangeInfo) {
            if (!objectChangeInfo || !objectChangeInfo.objectId) {
               return;
            }
            var matchedEntries = getMatching(objectChangeInfo);

            if (!matchedEntries.length) {
               return;
            }

            _dataService.getProperties(objectChangeInfo.objectId, attrsToFetch).then(function(resp){
               matchedEntries.forEach(function(entry){
                  mappingFunction(entry, resp);
               });
            });
         });
      },

      /**
       * The function is a wrapper around 'dataService.getPropertiesForObjects',
       * but  supports heterogeneous object set. It works around the original
       * 'dataService' limitation by grouping the supplied objects by type and then
       * issuing separate (but parallel) requests for each group. Once all requests
       * are back - the result is merged and returned to the caller.
       *
       * @see dataService.getPropertiesForObjects
       */
      getPropertiesForObjects: function (objectIds, properties, options) {
         return _vcDataServiceUtil.getPropertiesForObjects(
               objectIds, properties, options);
      },

      /**
       * This method is inspired by the 'dataService.getPropertiesByRelation'
       * function, but works on multiple input objects.
       *
       * @param objectIds
       *    The 'ids' of the objects that are the source of the relation.
       * @param relation
       *    The relation, which allow the code to jump from the source objects to
       *    a new set of objects.
       * @param properties
       *    The properties to obrain on the final set of objects retrived via the
       *    relation hop.
       * @param options
       *    options supplied when requesting the property set
       */
      getPropertiesForObjectsByRelation: function (objectIds, relation, properties, options) {
         return _vcDataServiceUtil.getPropertiesForObjectsByRelation(
               objectIds, relation, properties, options);
      },

      /**
       * A helper method that builds a map from the property results you
       * get back from the 'dataService' when requesting properties for multiple
       * objects. What you get back from the data service is a map
       * {
       *    objectId1: {
       *        prop1: value1,
       *        prop2: value2,
       *        ...
       *    }
       *    objectId2: {
       *       prop1: value21,
       *       prop2: value22,
       *       ...
       *    }
       * }
       *
       * calling this method with 'propertyName' say 'prop1' will return the following
       * map:
       * {
       *    objectId1: value1,
       *    objectId2: value2,
       *    ...
       * }
       *
       * this structure is often more convenient for processing by the property
       * data requester.
       *
       * @param propertyValuesByObjectId
       *    The result returned by the 'getPropertiesForObjects' method
       *
       * @param propertyName
       *    The name of the property which value should be put in the resulting map
       */
      createPropertyByObjectMap: function (propertyValuesByObjectId, propertyName) {
         return _vcDataServiceUtil.createPropertyByObjectMap(
               propertyValuesByObjectId, propertyName);
      }
   };
}]);
