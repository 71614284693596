/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {

   export interface RecentTasksColumnData {
      sort: Array<any>;
      columnDefs: Array<RecentTasksColumnDef>;
   }

   export class RecentTasksColumnDef {
      title: string;
      field: string;
      filterable: any;
      template?: Function | string;
      width?: number;
      hidden?: boolean;
   }

   class PersistedColumnDef {
      title: string;
      hidden?: boolean;
      width?: number;
   }

   export class RecentTasksColumnsService {
      static $inject = [
         "i18nService",
         "vuiLocale",
         "columnRenderersRegistry",
         "timeFormatterService",
         "persistenceService",
         "$timeout",
         "telemetryService"
      ];

      private readonly i18n = this.i18nService.getString;
      private readonly RECENT_TASKS_COLUMNS_STATE = "com.vmware.usersetting.recenttasks.columns";
      private readonly RECENT_TASKS_COLUMNS_STATE_CATEGORY = "com.vmware.usersetting.recenttasks";
      private readonly PERSISTENCE_OBJ_KEY = {
         key: "uid",
         op: "=",
         value: this.RECENT_TASKS_COLUMNS_STATE
      };

      private readonly CUSTOM_TEXT_FILTER = {
         extra: false,
         messages: {
            info: this.vuiLocale.datagrid.colFilterInfo,
            filter: this.vuiLocale.datagrid.filter,
            clear: this.vuiLocale.datagrid.clear
         },
         operators: {
            string: {
               contains: this.vuiLocale.datagrid.contains
            }
         },
         ui: (element) => {
            const parent = element.parent();
            while (parent.children().length > 1) {
               $(parent.children()[0]).remove();
            }
            parent.prepend("<input data-bind=\"value:filters[0].value\" class=\"k-textbox\" type=\"text\">");
         }
      };
      private readonly DEFAULT_COLUMN_DEFS: Array<RecentTasksColumnDef>;
      private readonly DEFAULT_SORT: Array<any>;
      private DEFAULT_COLUMN_DEFS_BY_TITLE: Object = {};

      private dateFormat: string;

      constructor(private i18nService: any,
            private vuiLocale: any,
            private columnRenderersRegistry: any,
            private timeFormatterService: any,
            private persistenceService: any,
            private $timeout: any,
            private telemetryService: any) {
         this.DEFAULT_COLUMN_DEFS = this.getDefaultColumnDefs();
         this.DEFAULT_COLUMN_DEFS.forEach((columnDef) => {
            this.DEFAULT_COLUMN_DEFS_BY_TITLE[columnDef.title] = columnDef;
         });
         this.DEFAULT_SORT = this.getDefaultSort();
      }

      fetchColumnsData(): angular.IPromise<RecentTasksColumnData> {
         let columnsData = <RecentTasksColumnData> {};

         const promise = this.persistenceService.getData(this.PERSISTENCE_OBJ_KEY);
         return promise.then((data: Array<{ value: string }>) => {
            if (!data || !data.length || data.length === 0 || !data[0] || !data[0].value) {
               columnsData.columnDefs = this.DEFAULT_COLUMN_DEFS;
               columnsData.sort = this.DEFAULT_SORT;
               return columnsData;
            }

            let columnDefs: Array<RecentTasksColumnDef>;
            let sort: Array<any> = [];
            const columnDefsSet = {};
            try {
               const persistedColumnsData = angular.fromJson(data[0].value);
               sort = persistedColumnsData.sort;
               columnDefs =
                     _.filter(persistedColumnsData.columns, (persistedColumn: PersistedColumnDef) => {
                        return this.DEFAULT_COLUMN_DEFS_BY_TITLE[persistedColumn.title];
                     }).map((persistedColumn: PersistedColumnDef) => {
                        const columnDef: RecentTasksColumnDef =
                              this.DEFAULT_COLUMN_DEFS_BY_TITLE[persistedColumn.title];

                        const column = new RecentTasksColumnDef();
                        column.title = columnDef.title;
                        column.field = columnDef.field;
                        column.filterable = columnDef.filterable;
                        column.template = columnDef.template;
                        if (persistedColumn.width) {
                           column.width = persistedColumn.width;
                        }
                        column.hidden = persistedColumn.hidden;

                        columnDefsSet[column.title] = true;

                        return column;
                     });
            } catch (err) {
               columnDefs = this.DEFAULT_COLUMN_DEFS;
               // Do nothing on parse error
            }

            if (columnDefs.length !== this.DEFAULT_COLUMN_DEFS.length) {
               for (let i = 0; i < this.DEFAULT_COLUMN_DEFS.length; i++) {
                  if (columnDefsSet[this.DEFAULT_COLUMN_DEFS[i].title]) {
                     continue;
                  }

                  columnDefs.push(this.DEFAULT_COLUMN_DEFS[i]);
               }
            }

            columnsData.columnDefs = columnDefs;
            columnsData.sort = sort;
            return columnsData;
         });
      }

      persistColumnsState(scope: any): (event: any) => void {
         let timeoutPromise;

         // Cancel the promise when the view's scope is destroyed
         scope.$on('destroy', () => {
            if (timeoutPromise) {
               this.$timeout.cancel(timeoutPromise);
               timeoutPromise = undefined;
            }
         });

         return (event: any): void => {
            // Use a timeout in order to allow the grid to apply the column
            // changes and de-bounce frequent requests
            if (timeoutPromise) {
               this.$timeout.cancel(timeoutPromise);
            }

            timeoutPromise = this.$timeout(
                  () => {
                     // event.sender.columns array will now hold the updated column definitions
                     const columnDefs = event.sender.columns;
                     const columnsData: any = {};

                     columnsData.columns =
                           _.filter(columnDefs, (kendoColumn: any) => {
                              return kendoColumn;
                           }).map((kendoColumn: any) => {
                              const width = parseInt(kendoColumn.width);

                              const persistColumn = new PersistedColumnDef();
                              persistColumn.title = kendoColumn.title;
                              persistColumn.hidden = kendoColumn.hidden;
                              persistColumn.width = width ? width : undefined;

                              return persistColumn;
                           });
                     const dataSource = event.sender.dataSource;
                     if (dataSource && dataSource.sort()) {
                        columnsData.sort = dataSource.sort();
                     }

                     this.persistenceService.setData(
                           this.RECENT_TASKS_COLUMNS_STATE,
                           angular.toJson(columnsData),
                           {},
                           this.RECENT_TASKS_COLUMNS_STATE_CATEGORY);
                     this.telemetryService.trackEvent("recentTasks", "columns", JSON.stringify(columnsData.columns));
                  }, 100);
         };
      }

      renewUserTimeFormat(): angular.IPromise<void> {
         return this.timeFormatterService.getUserTimeFormatPreference()
               .then((savedFormatId) => {
                  this.dateFormat = savedFormatId;
               });
      }

      private getDefaultColumnDefs(): Array<RecentTasksColumnDef> {
         const nameCol = new RecentTasksColumnDef();
         nameCol.title = this.i18n("Common", "taskNameHeader");
         nameCol.field = "description";
         nameCol.filterable = this.CUSTOM_TEXT_FILTER;

         const targetCol = new RecentTasksColumnDef();
         targetCol.title = this.i18n("Common", "taskTargetHeader");
         targetCol.field = "entityName";
         targetCol.filterable = this.CUSTOM_TEXT_FILTER;
         targetCol.template = this.targetRenderer.bind(this);

         const statusCol = new RecentTasksColumnDef();
         statusCol.title = this.i18n("Common", "taskStatusHeader");
         statusCol.field = "state";
         statusCol.filterable = this.CUSTOM_TEXT_FILTER;
         statusCol.template = this.columnRenderersRegistry.getColumnRenderer("recent-task-state");

         const detailsCol = new RecentTasksColumnDef();
         detailsCol.title = this.i18n("Common", "taskDetailsHeader");
         detailsCol.field = "details";
         detailsCol.filterable = this.CUSTOM_TEXT_FILTER;

         const initiatorCol = new RecentTasksColumnDef();
         initiatorCol.title = this.i18n("Common", "taskInitiatorHeader");
         initiatorCol.field = "initiator";
         initiatorCol.filterable = this.CUSTOM_TEXT_FILTER;
         initiatorCol.template = "<div title=\"#:initiator#\">#:initiator#</div>";

         const qTimeCol = new RecentTasksColumnDef();
         qTimeCol.title = this.i18n("Common", "taskQueueTimeHeader");
         qTimeCol.field = "queuedForMilliseconds";
         qTimeCol.filterable = this.CUSTOM_TEXT_FILTER;
         qTimeCol.template = (item) => item.timeInQueueString ? item.timeInQueueString : "";

         const startTimeCol = new RecentTasksColumnDef();
         startTimeCol.title = this.i18n("Common", "taskStartTimeHeader");
         startTimeCol.field = "startTimeString";
         startTimeCol.filterable = this.CUSTOM_TEXT_FILTER;
         startTimeCol.template = (item) =>
               this.timeFormatterService.timestampToText(item.startTime, this.dateFormat);

         const completeTimeCol = new RecentTasksColumnDef();
         completeTimeCol.title = this.i18n("Common", "taskCompleteTimeHeader");
         completeTimeCol.field = "completionTimeString";
         completeTimeCol.filterable = this.CUSTOM_TEXT_FILTER;
         completeTimeCol.template = (item) =>
               this.timeFormatterService.timestampToText(item.completionTime, this.dateFormat);

         const serverCol = new RecentTasksColumnDef();
         serverCol.title = this.i18n("Common", "taskvServerHeader");
         serverCol.field = "service";
         serverCol.filterable = this.CUSTOM_TEXT_FILTER;
         serverCol.template = this.getServerRenderer.bind(this);

         return [nameCol, targetCol, statusCol, detailsCol, initiatorCol,
            qTimeCol, startTimeCol, completeTimeCol, serverCol];
      }

      private getDefaultSort(): Array<any> {
         return [{
            dir: "desc",
            field: "startTimeString"
         }];
      }

      private targetRenderer(clientTaskInfo: any): any {
         const objectLinkRenderer =
               this.columnRenderersRegistry.getColumnRenderer("object-link");
         const props = ["entityUid", "entityName", clientTaskInfo.entityIcon,
            "", clientTaskInfo.targetExists];

         return objectLinkRenderer(props, clientTaskInfo, { isKendoGrid: true });
      }

      private getServerRenderer(clientTaskInfo: any): any {
         const objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
         const props = ["rootFolderUid", "service"];

         return objectLinkRenderer(props, clientTaskInfo, { isKendoGrid: true });
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("recentTasksColumnsService", RecentTasksColumnsService);
}
