angular.module('com.vmware.platform.ui').factory('treeViewLocalStorageService', ['$q', 'localStorageService', 'simpleObjectStorageService',
   function ($q, localStorageService, simpleObjectStorageService) {
      function TreeViewLocalStorage(treeId) {
         var treeViewLocalStorage = this,

         //the treeId used for this directive
            _treeId = treeId,

         // Storage where the data will be stored
            _storage = simpleObjectStorageService.getObjectStorage();

         // Future pointing to the last save persistence call
            var savePersistenceFuture = $q.when();

         /**
          * Set whether persistence is enabled
          * @param enablePersistence true when data should be persisted
          */
         treeViewLocalStorage.enablePersistence = function (enablePersistence) {
            if (enablePersistence === true) {
               _storage = localStorageService;
            } else if (_storage === localStorageService) {
               // Initialize with instance storage only if it isn't initialized yet
               _storage = simpleObjectStorageService.getObjectStorage();
            }
         };

         /**
          * Node selection change listener.
          * Currently used to persist the last selected node as persisted is enabled.
          * @param item The data item of the selected node
          */
         treeViewLocalStorage.selectionChanged = function (item) {
            extendPersistedData({lastSelectedNode: item.objRef, lastSelectedNodeExpanded: item.expanded});
         };

         /**
          * Node expand listener
          * @param item The data item of the node that is being expanded
          */
         treeViewLocalStorage.nodeExpanded = function (item) {
            getPersistedData().then(function (persistedData) {
               //persist the expanded status only for the currently selected node
               if (persistedData.lastSelectedNode === item.objRef) {
                  extendPersistedData({lastSelectedNodeExpanded: true});
               }

               setExpansionPersistanceTree(persistedData, item, function (parent) {
                  var treeNode = parent.children[item.objRef];
                  if (treeNode) {
                     treeNode.expanded = true;
                  } else {
                     parent.children[item.objRef] = {expanded: true, children: {}};
                  }
               });
            });
         };

         /**
          * Node collapse listener
          * @param item The data item of the node that is being collapsed
          */
         treeViewLocalStorage.nodeCollapsed = function (item) {
            getPersistedData().then(function (persistedData) {
               //persist the expanded status only for the currently selected node
               if (persistedData.lastSelectedNode === item.objRef) {
                  extendPersistedData({lastSelectedNodeExpanded: false});
               }

               setExpansionPersistanceTree(persistedData, item, function (parent) {
                  var treeNode = parent.children[item.objRef];
                  if (!treeNode) {
                     return;
                  }

                  if (Object.keys(treeNode.children).length > 0) {
                     // If the there are expanded children, we just need to update the expand state
                     treeNode.expanded = false;
                  } else {
                     // If the node doesn't have children, we don't need it anymore
                     delete parent.children[item.objRef];
                  }
               });
            });
         };

         /**
          * Retrieve data for the last selected node as persisted in the local storage
          * The functionality is dependent on whether or not the persistence is enabled
          * @returns object containing data for the last selected node
          */
         treeViewLocalStorage.getLastSelectedNodeData = function () {
            return getPersistedData().then(function (persistedData) {
                  return {
                     node: persistedData.lastSelectedNode,
                     expanded: persistedData.lastSelectedNodeExpanded
                  };
               }
            );
         };

         /**
          * Retrieve data for the last expanded nodes as persisted in the local storage
          * The functionality is dependent on whether or not the persistence is enabled
          * @returns object containing a root node of the expanded elements in the tree
          */
         treeViewLocalStorage.getExpandedNodesData = function () {
            return getPersistedData().then(buildPersistedTree);
         };

         /**
          * Clean and return the expanded nodes data
          */
         treeViewLocalStorage.cleanExpandedNodesData = function () {
            return getPersistedData().then(function (persistedData) {
               extendPersistedData({expandedNodes: {children: {}}});
               return persistedData.expandedNodes;
            });
         };

         /**
          * Retrieves the data from the persistence storage.
          * Depends on whether or not the persistence is enabled.
          * The data is lazily loaded and stored into the local variable.
          * @returns JS object representing the data persisted in the local storage
          *          or empty object if persistence is not enabled
          */
         function getPersistedData() {
            return _storage.getUserData(_treeId)
               .then(function (localStorageData) {
                  return localStorageData || {};
               });
         }

         /**
          * Preserve data in the persistence storage if persistence is enabled.
          * Note that the data may not be the complete object, but just a subset of it.
          * @param data the data that is going to be persisted
          * @param newData the data that is going to be persisted, this will be replaced in the persistedData
          */
         function setPersistedData(persistedData, newData) {
            var data = angular.copy(persistedData);
            if (newData) {
               angular.extend(data, newData);
            }
            _storage.setUserData(_treeId, data);
         }

         /**
          * Preserve data in the persistence storage if persistence is enabled.
          * Note that the data may not be the complete object, but just a subset of it.
          * @param data the data that is going to be persisted, this may be just a subset of the
          *             whole local storage data
          */
         function extendPersistedData(data) {
            // Making sure that each extend of the persisted data will happen after the previous one
            // This will ensure that we will extend always the latest data
            savePersistenceFuture = savePersistenceFuture.then(getPersistedData).then(function (persistedData) {
               setPersistedData(persistedData, data);
            });
         }

         function setExpansionPersistanceTree(persistedData, item, modifyDirectParentFunction) {
            var expandedTree = buildPersistedTree(persistedData);
            var itemPath = buildItemPath(item);

            // Search for the direct parent of currently expanded/collapsed in the persisted tree
            var currentNode = expandedTree;
            for (var i = itemPath.length - 1; i > 0 && currentNode; --i) {
               currentNode = currentNode.children[itemPath[i]];
            }

            if (currentNode) {
               // If we found the direct parent of the item, just update the tree
               modifyDirectParentFunction(currentNode);
            }

            extendPersistedData({expandedNodes: expandedTree});
         }

         function buildPersistedTree(data) {
            if (!data.expandedNodes) {
               return {children: {}};
            }

            return data.expandedNodes;
         }

         function buildItemPath(item) {
            var path = [];
            var currentItem = item;
            while (currentItem) {
               path.push(currentItem.objRef);
               currentItem = currentItem.parentNode();
            }
            return path;
         }

         return this;
      }

      function getLocalStorageByTreeId(treeId) {
         return new TreeViewLocalStorage(treeId);
      }

      return {
         getLocalStorageByTreeId: getLocalStorageByTreeId
      };
   }]);