/* Copyright 2014 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {
   /**
    * Http request interceptor which injects custom header for each request made from angular.
    */
   export class RequestInterceptor {
      private vac_url:string = "https://vcsa.vmware.com";

      constructor(private userSessionService: UserSessionService,
                  private $window: any,
                  private $q: any) {
      }

      request = (config: any): any => {
         // If logout is in progress, any requests that occur in the meantime have to be
         // blocked from executing as at this point it is possible the client session
         // to be already invalidated. By blocking the requests and not rejecting them
         // altogether, it is being prevented executing callers' error handlers
         // and showing unnecessary errors before the logout actually completes.
         if (this.$window.h5.isLogoutInProgress) {
            return this.$q.defer().promise;
         }

         // Don't add extra header for VAC requests
         // TODO change this logic to only add headers for local requests to Virgo
         let addSessionIdHeader = this.userSessionService.webClientSessionId &&
               config.url && !config.url.startsWith(this.vac_url);
         if (addSessionIdHeader) {
            //Add web client session id to the request header, needed to
            //an application other than h5ngc (DispatcherServlets of the plugins).
            config.headers[this.userSessionService.WEB_CLIENT_SESSION_ID] =
               this.userSessionService.webClientSessionId;
         }
         return config;
      }
   }

   angular.module('com.vmware.platform.ui').factory('requestInterceptor', [
      'userSessionService', '$window', '$q', function (userSessionService, $window, $q) {
         return new RequestInterceptor(userSessionService, $window, $q);
      }]);

   angular.module('com.vmware.platform.ui').config(['$httpProvider', function ($httpProvider) {
      $httpProvider.interceptors.push('requestInterceptor');
      // change all GET headers to contain Pragma: no-cache header
      // this is required for IE 10/11 to prevent it from caching GET requests and
      // forcing all GET requests to hit the server.
      // https://github.com/saintmac/angular-cache-buster may be a better solution
      // initialize get if not there

      if (!$httpProvider.defaults.headers.get) {
         $httpProvider.defaults.headers.get = {};
      }
      //disable IE ajax request caching
      $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
   }]);
}
