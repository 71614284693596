/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

angular.module('com.vmware.platform.ui').directive('vxBindHtmlAndCompile', ['$compile',
   function($compile) {
      return {
         restrict: 'A',
         link: function (scope, element, attrs) {
            scope.$watch( function () {
               return scope.$eval(attrs.vxBindHtmlAndCompile);
            }, function (value) {
               element.html(value && value.toString());
               $compile(element.contents())(scope);
            });
         }
      };
   }]);
