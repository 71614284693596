/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Service providing an API for working with IP addresses.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').service('ipParserService', ipParserService);

   function ipParserService() {
      var SUBNET_MASK_MAX_QUAD_VALUE = 255;
      var SUBNET_MASK_VALID_QUAD_VALUES = [0, 128, 192, 224, 240, 248, 252, 254];
      var MAX_PORT = Math.pow(2, 16);
      var IPV6_BRACKETED_NOTATION_REGEX = new RegExp('^\\[(.*)\\](:(\\d+))?$'); // capture groups are: [fullText, address, portWithColon, port]
      var DIGITS_REGEX = new RegExp('^\\d+$');
      var ZONE_INDEX_REGEXP = /^\w+$/;

      return {
         parse: parse,
         isSubnetMaskValid: isSubnetMaskValid,
         isSubnetPrefixValid: isSubnetPrefixValid,
         isIpv4AddressValid: isIpv4AddressValid,
         isIpv6AddressValid: isIpv6AddressValid,
         isAddressValid: isAddressValid,
         isSameIpv4Subnet: isSameIpv4Subnet,
         expandIpv6Address: expandIpv6Address,
         equalIpv6Addresses: equalIpv6Addresses
      };

      function isIpv4AddressValid(ipAddress) {
         return ipaddr.IPv4.isValid(ipAddress) && validIpV4Address(ipAddress);
      }

      function isIpv6AddressValid(ipAddress, acceptZoneIndex) {
         if (!ipAddress) {
            return false;
         }

         var addr = ipAddress.trim();

         if (acceptZoneIndex) {
            var delimiterIndex = addr.indexOf('%');

            if (delimiterIndex > -1) {
               var zoneIndex = addr.substring(delimiterIndex + 1);

               if (!ZONE_INDEX_REGEXP.test(zoneIndex)) {
                  return false;
               }

               addr = addr.substring(0, delimiterIndex);
            }
         }

         return ipaddr.IPv6.isValid(addr);
      }

      /**
       * @param ipAddressFirst - first ipv4Address for comparison
       * @param ipAddressSecond - second ipv4Address for comparison
       * @param subnetMask - subnetMask associated with the given IPs
       * @returns {boolean} true if all parameters are valid and ipAddressFirst
       *          subnet matches the ipAddressSecond subnet, otherwise returns false
       */
      function isSameIpv4Subnet(ipAddressFirst, ipAddressSecond, subnetMask) {
         if (!isIpv4AddressValid(ipAddressFirst)
               || !isIpv4AddressValid(ipAddressSecond)
               || !isSubnetMaskValid(subnetMask)) {
            return false;
         }

         var splitIpFirst = ipAddressFirst.split('.');
         var splitIpSecond = ipAddressSecond.split('.');
         var splitSubnet = subnetMask.split('.');

         // enable bitwise operations
         /*jshint bitwise:false */
         for (var i = 0; i < 4; i++) {
            if ((splitIpFirst[i] & splitSubnet[i]) !== (splitIpSecond[i] & splitSubnet[i])) {
               return false;
            }
         }

         return true;
      }

      /**
       * Returns true if provided ipAddress is valid IPv4 or IPv6 address
       * @param ipAddress
       */
      function isAddressValid(ipAddress) {
         return isIpv6AddressValid(ipAddress) || isIpv4AddressValid(ipAddress);
      }

      function expandIpv6Address(ipAddress) {
         if (!ipAddress ||
                  (!isIpv4AddressValid(ipAddress) && !isIpv6AddressValid(ipAddress))) {
            return ipAddress;
         }

         var parsedAddress = ipaddr.parse(ipAddress);
         return (typeof parsedAddress.toNormalizedString !== 'undefined')
               ? parsedAddress.toNormalizedString() : ipAddress;
      }

      function validIpV4Address(ipAddress) {
         var splittedIpAddress = ipAddress.split('.');
         if(splittedIpAddress.length !== 4){
            return false;
         }

         return _.every(splittedIpAddress, function(item) {
            return item >= 0 && item <= 255;
         });
      }

      function isSubnetPrefixValid(prefix) {
         return prefix >= 1 && prefix <= 128;
      }

      function isSubnetMaskValid(subnetMask) {
         if (!subnetMask) {
            return false;
         }

         // If the mask ends with '.', or has no number between two '.', then the .split()
         // would return "" in this case, which transformed into a number results in `0`.
         // This should be avoided by filtering the non-empty strings.
         var maskBytes = [];
         _.each(subnetMask.split('.'), function (octet) {
            if (!!octet) {
               maskBytes.push(Number(octet));
            }
         });

         // The first octet cannot be 0 for a valid IP
         if (maskBytes.length !== 4 || maskBytes[0] === 0) {
            return false;
         }

         // Find the first quad that is not equal to 255
         var firstNonMaxQuadIndex = 0;

         for (var i = 0; i < maskBytes.length; i++) {
            if (maskBytes[i] !== SUBNET_MASK_MAX_QUAD_VALUE) {
               break;
            } else {
               firstNonMaxQuadIndex++;
            }
         }

         // All quads are equal to 255 -> valid subnet mask
         if (firstNonMaxQuadIndex === maskBytes.length) {
            return true;
         }

         // Quad should be contiguous
         if (!_.contains(SUBNET_MASK_VALID_QUAD_VALUES, maskBytes[firstNonMaxQuadIndex])) {
            return false;
         }

         // Following quads should be zero
         for (var j = firstNonMaxQuadIndex + 1; j < maskBytes.length; j++) {
            if (maskBytes[j] !== 0) {
               return false;
            }
         }

         return true;
      }

      /**
       * Compares its two ipv6 addresses. Returns true if it's the same ip address even
       * if the format is different.
       *
       * @param addr1 The first valid ipv6 address to be compared
       * @param addr2 The second valid ipv6 address to be compared
       * @returns {boolean}
       */
      function equalIpv6Addresses(addr1, addr2){
         return getNormalizedIpv6Address(addr1) === getNormalizedIpv6Address(addr2);
      }

      /**
       * Returns normalized form of IPv6 and IPv4 address
       * @param ipAddress
       *
       */
      function getNormalizedIpv6Address(ipAddress){
         var addr = ipaddr.parse(ipAddress);

         if(addr.kind() === "ipv4") {
            addr = addr.toIPv4MappedAddress();
         }

         return addr.toNormalizedString();
      }

      function parse(addressString) {
         var addressAndPort = detectParts(addressString);
         var portResult = parsePort(addressAndPort.port);
         var addressResult = parseAddress(addressAndPort.address);

         if (portResult.valid && addressResult.valid) {
            return buildIpAddress(addressResult.address, portResult.port);
         } else {
            return null;
         }
      }

      function detectParts(addressString) {
         var parts = addressString.split(':');

         if (parts.length > 2) {
            return detectIPv6Parts(addressString);
         } else {
            return {
               address: parts[0],
               port: parts[1]
            };
         }
      }

      function detectIPv6Parts(address) {
         var match = IPV6_BRACKETED_NOTATION_REGEX.exec(address);
         if (match) {
            return {
               address: match[1],
               port: match[3]
            };
         } else {
            return {
               address: address
            };
         }
      }

      function parseAddress(address) {
         if (!ipaddr.isValid(address)) {
            return {valid: false};
         }

         return {
            valid: true,
            address: ipaddr.parse(address).toString()
         };
      }

      function parsePort(port) {
         if (port === undefined) {
            return {valid: true, port: undefined};
         }

         if (!DIGITS_REGEX.test(port)) {
            return {valid: false};
         }

         var parsedPort = parseInt(port, 10);
         if (parsedPort <= 0) {
            return {valid: false};
         } else if (parsedPort >= MAX_PORT) {
            return {valid: false};
         } else {
            return {valid: true, port: parsedPort};
         }
      }

      function buildIpAddress(address, port) {
         return {
            address: address,
            port: port
         };
      }
   }
})();
