/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   /**
    * Provides modal dialogs based on Clarity Design.
    */
   angular
      .module('com.vmware.platform.ui')
      .service('clarityModalService', clarityModalService);

   clarityModalService.$inject = [
      '$document',
      '$rootScope',
      '$compile',
      '$templateRequest',
      'clarityConstants',
      'clarityModalServiceHelper',
      'dataService',
      '$q',
      'focusTraceService',
      'i18nService',
      'vxModalKeyService',
      '$timeout'];


   function clarityModalService($document,
                                $rootScope,
                                $compile,
                                $templateRequest,
                                clarityConstants,
                                clarityModalHelper,
                                dataService,
                                $q,
                                focusTraceService,
                                i18nService,
                                vxModalKeyService,
                                $timeout) {

      return {
         openModal: openModal,
         openConfirmationModal: openConfirmationModal,
         openOkCancelModal: openOkCancelModal,
         openPopUp: openPopUp
      };

      function openModal(actionEval, availableTargets, dialogData, templateUrl) {
         var scope = clarityModalHelper.prepareModalScope(actionEval,
                                                          availableTargets,
                                                          dialogData,
                                                          templateUrl);

         scope.submitModal = function() {
            if (dialogData.submit) {
               if (dialogData.submit()) {
                  scope.destroyModal();
               }
            }
         };
         _openModal(scope, templateUrl);
         // This is done only to support the SDK use case, see HtmlBridgeService closeDialog.
         return scope;
      }

      /**
       * Creates modal dialog with OK and Cancel buttons.
       *
       * @param modalOptions
       *       title - title of the modal dialog.
       *       message - title of the modal dialog.
       *       contentTemplate - the path to template that is the warning content.
       *       submit - primary button callback
       *       onCancel - cancel button callback
       *
       *       OPTIONAL:
       *       subTitle: {
       *          text: subtitle of the modal dialog
       *          objectId: if text is not specified, you can fetch name for objectId
       *       }
       *       icon - icon class
       *       clarityIcon: configuration object for the clarityIcon according to their iconography
       *       saveButtonLabel - Text for primary button, OK is default text
       *       cancelButtonLabel - Text for the secondary button, Cancel is the default text
       *       preserveNewlines - default FALSE
       *       defaultButton - Specifies which button ('submit' or 'close') is highlighted. 'submit' by default.
       *       hideCancelButton - hide the cancel button from the footer, leaving the modal with only one button.
       *       modalClass - Specify wrapper class for the clarity modal
       *       contentTemplateContext - object to be injected into the scope of the contentTemplate.
       */
      function openConfirmationModal (modalOptions) {
         var templateUrl = 'resources/ui/views/dialogs/confirmationModal.html';
         var scope = $rootScope.$new();
         scope.modalOptions = modalOptions;
         scope.modalOptions.modalOpen = true;
         _.defaults(scope.modalOptions, {
            defaultButton: 'submit',
            showXIcon: true
         });

         var confirmationDefered = $q.defer();
         if (scope.modalOptions.submit === undefined) {
            scope.modalOptions.submit = function() {
               confirmationDefered.resolve(true);
            };
         }
         if (scope.modalOptions.onCancel === undefined) {
            scope.modalOptions.onCancel = function() {
               confirmationDefered.resolve(false);
            };
         }

         setSubtitle(modalOptions);

         scope.submitModal = submitModal;
         scope.onCancelModal = scope.modalOptions.onCancel;

         function submitModal() {

            var closePredicate = scope.modalOptions.submit();
            if (closePredicate || closePredicate === undefined) {
               scope.destroyModal();
            }
         }

         decorateScope(scope, modalOptions);
         _openModal(scope, templateUrl);
         return confirmationDefered.promise;
      }

      /**
       * Creates and opens a modal dialog with OK and Cancel buttons.
       *
       * @param modalOptions - Object containing the parameters used by the dialog.
       *
       *  Example:
       *
       * <pre><code>
       *
       *       var modalOptions = {
       *         title: "Modal Dialog Title",
       *         subTitle: {
       *           objectId: 'vmObjectId',
       *         },
       *         contentTemplate: '/featureFolder/someTemplate.html',
       *         onSubmit: function(){
       *           mutationService.apply(...)
       *         },
       *         dialogData: {
       *           vmName: "My VM",
       *           hostDetails: {
       *             hostName: ''
       *           }
       *         }
       *          ....
       *       }
       *
       * </pre></code>
       *
       *    The following properties are available:
       *
       *      Required:
       *
       *       - title - title of the modal dialog.
       *       - contentTemplate - the path to template that is a content of the modal.
       *          The template must be inside &lt;div class="modal-body"&gt;&lt;/div&gt; tag.
       *       - onSubmit - OK button callback
       *       - onCancel - Cancel button callback
       *
       *      Optional:
       *
       *       - dialogData - all additional data which will be used in the content template
       *       - subTitle.text - text string to display as subtitle
       *       - subTitle.objectId - if subTitle.text is not set, objectId can be provided instead. The objectId's name will be fetched and displayed as the subtitle
       *       - size - modal dialog's size. Values are 'sm', <none>, 'lg'.
       *       - alerts - list of {text, type} notifications to be displayed in the dialog.
       *       - defaultButton - Specifies which button ('submit' or 'close') is highlighted.
       *       - modalClass - Specify wrapper class for the clarity modal
       *       - hideSubmitButton - hides the Submit button from the footer, leaving the modal with only one button.
       *       - closeButtonTitle - title of the close button
       *       - submitButtonTitle - title of the submit button
       *       - submitDisabled : flag to enable/disable the Ok/submi button
       */
      function openOkCancelModal(modalOptions) {
         var templateUrl = 'resources/ui/views/dialogs/okCancelModal.html';

         validateModalOptions(modalOptions);
         setSubtitle(modalOptions);

         var scope = $rootScope.$new();
         scope.modalOptions = _.extend(
               modalOptions ? modalOptions : {}, {
               modalOpen: true,
               submitDisabled: _.has(modalOptions, "submitDisabled") ? modalOptions.submitDisabled : false
            });

         scope.onSubmitModal = onSubmitModal;
         scope.onCancelModal = scope.modalOptions.onCancel;

         scope.$watch(function() {
            return scope.modalOptions.alerts;
         }, function(alerts) {
            _.forEach(alerts, function(alert) {
               switch (alert.type) {
                   case clarityConstants.notifications.type.ERROR:
                       alert.className = 'danger';
                       alert.iconShape = 'exclamation-circle';
                       break;

                   case clarityConstants.notifications.type.WARNING:
                       alert.className = 'warning';
                       alert.iconShape = 'exclamation-triangle';
                       break;

                   case clarityConstants.notifications.type.INFO:
                       alert.className = 'info';
                       alert.iconShape = 'info-circle';
                       break;

                   case clarityConstants.notifications.type.SUCCESS:
                       alert.className = 'success';
                       alert.iconShape = 'check-circle';
                       break;

                   default:
                       alert.className = '';
                       alert.iconShape = null;
               }
            });
         });

         decorateScope(scope, modalOptions);
         _openModal(scope, templateUrl);

         function validateModalOptions(modalOptions) {
            if (!hasNonEmptyStringProperty(modalOptions, 'title')) {
               throw Error('modalOptions.title is not defined or is empty.');
            }

            if (!hasNonEmptyStringProperty(modalOptions, 'contentTemplate')) {
               throw Error('modalOptions.contentTemplate is not defined or is empty.');
            }
         }



         function onSubmitModal() {
            //prevent modal resubmission
            if(!scope.isSubmitInProgress) {
               if (typeof scope.modalOptions.onSubmit === 'function') {
                  var onSubmitResult = scope.modalOptions.onSubmit();
                  if (onSubmitResult === true) {
                     scope.destroyModal();
                  } else if (onSubmitResult !== false && onSubmitResult !== undefined) {
                     scope.isSubmitInProgress = true;
                     onSubmitResult.then(function(promiseResult) {
                        if (promiseResult === true) {
                           scope.destroyModal();
                        }
                        scope.isSubmitInProgress = false;
                     }, function() {
                        scope.isSubmitInProgress = false;
                     });
                  }
               }
            }
         }
      }

      /**
       * Creates pop-up dialog with a header.
       *
       * @param scope
       *    The pop-up's scope. It contains modalOptions property as it is described
       *    below.
       *
       *    scope.modalOptions
       *       title - title of the pop-up dialog.
       *       size - pop-up's size. Values are 'sm', <none>, 'lg'.
       *       contentUrl - the path to template that is a content of the pop-up.
       *          The template must be inside &lt;div class="modal-body"&gt;&lt;/div&gt; tag.
       */
      function openPopUp(scope) {
         //  Why does template need alerts, if none of the callers use it?
         var templateUrl = 'resources/ui/views/dialogs/popUpDialog.html';

         scope.modalOptions = angular.extend(
            scope.modalOptions || {}, {
               modalOpen: true
            });

         _openModal(scope, templateUrl);
      }

      function _openModal(scope, templateUrl) {
         var modal;

         scope.closeModal = closeModal;
         scope.destroyModal = destroyModal;
         if (scope.modalOptions.focusTarget === undefined) {
            scope.modalOptions.focusTarget = focusTraceService.popFocusableElement();
         }

         var bodyElement = angular.element($document[0].body);

         $templateRequest(templateUrl).then(function(template) {
            // Attach our handlers before clarity's
            setKeyPressHandler('on');
            $(window).on('resize', resizeModalKendoContent);

            modal = $compile(template)(scope);

            bodyElement.append(modal);

            scope.$watch('modalOptions.modalOpen', function(newValue, oldValue) {
               if (oldValue && !newValue) {
                  scope.$evalAsync("closeModal()");
               }
            });

            scope.$on('$destroy', function() {
               setKeyPressHandler('off');
               $(window).off('resize', resizeModalKendoContent);
            });
         });

         var onKeydownHandler = function ($event) {
            vxModalKeyService.onKeydownHandler($event, closeModal.bind(this));
         };

         function resizeModalKendoContent() {
            kendo.resize(modal, true);
         }

         function destroyModal() {
            setKeyPressHandler('off');
            $(window).off('resize', resizeModalKendoContent);

            // move the focus back to the trigger element if there is any
            if (scope.modalOptions.focusTarget) {
               var focusElement = scope.modalOptions.focusTarget;
               // Without timeout the element is some time focused sometime
               // not
               $timeout(function () {
                  if (focusElement) {
                    focusElement.focus();
                    focusElement = null;
                  }

               },0);
            }
            delete scope['closeModal'];

            scope.$destroy();

            if(modal) {
               modal.remove();
               modal = null;
            }
         }

         function closeModal(result) {
            if (_.isFunction(scope.onCancelModal)) {
               scope.onCancelModal(result);
            }
            destroyModal();
         }

         // Turn the keypress handler on/off
         function setKeyPressHandler(state) {
            if (state === 'on') {
               $document.on('keydown', onKeydownHandler);
               // Shadow Clarity's keyup handler on the body.
               $document.find('body').on('keyup', onKeyUp);
            } else {
               $document.off('keydown', onKeydownHandler);
               $document.find('body').off('keyup', onKeyUp);
            }
         }

         function onKeyUp(event) {
            var keyCode = event.which || event.keyCode;

            if (keyCode === clarityConstants.keys.ENTER
                  || keyCode === clarityConstants.keys.ESC) {
               event.stopImmediatePropagation();
               event.preventDefault();
               return false;
            }

            return true;
         }
      }

      function setSubtitle(modalOptions) {
         if (_.has(modalOptions, 'subTitle') && _.isObject(modalOptions.subTitle)) {
            if (hasNonEmptyStringProperty(modalOptions.subTitle, 'text')) {
               modalOptions.subTitle = modalOptions.subTitle.text;
            }
            else if (hasNonEmptyStringProperty(modalOptions.subTitle, 'objectId')) {
               var objectId = modalOptions.subTitle.objectId;
               modalOptions.subTitle = '';
               dataService
                     .getProperties(objectId, ['name'])
                     .then(function(data) {
                        if (data && data.name) {
                           modalOptions.subTitle = data.name;
                        }
                     });
            } else {
               modalOptions.subTitle = '';
            }
         }
      }

      function hasNonEmptyStringProperty(object, property) {
         return _.has(object, property) && isNonEmptyString(object[property]);
      }

      function isNonEmptyString(string) {
         return !_.isUndefined(string) && !_.isNull(string) && _.isString(string) && !_.isEmpty(
                     string);
      }

      function decorateScope(scope, modalOptions) {
         scope.getOkBtnLabel = function () {
            return modalOptions.saveButtonLabel !== undefined ?
               modalOptions.saveButtonLabel :
               i18nService.getString("Common", "saveButtonLabel");
         };

         scope.getCancelBtnLabel = function () {
            return modalOptions.cancelButtonLabel !== undefined ?
               modalOptions.cancelButtonLabel :
               i18nService.getString("Common", "cancelButtonLabel");
         };
      }
   }
})();
