/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Handles extension related logic.
 */
angular.module('com.vmware.platform.ui').factory('extensionService', ['$http',
function($http) {
   return {
      /**
       * Get a extensions array keyed by a pointId, the array content is filtered using the objectId.
       */
      getExtensions: function(pointId, objectId) {
         return $http({
            method: 'get',
            url: 'extensions/' + pointId + '/' + objectId
         }).then(function(resp){
            return resp.data;
         });
      },
      /**
       * Given a extension, determine the child pointId, and return the associated extensions array filtered by the objectId.
       *
       * Note: in plugin.xml, the child pointId is specified as the "hostedPoint" of a extension.
       */
      getHostedExtensions: function(extensionId, objectId) {
         var params;
         if (angular.isDefined(objectId) && objectId !== null) {
            params = {
               objectId: objectId
            };
         }
         return $http({
            method: 'get',
            url: 'hostedextensions/' + extensionId,
            params: params
         }).then(function(resp){
            return resp.data;
         });
      },

      /**
       * Check if new plugins have been registered after login
       */
      checkForNewPlugins: function() {
         return $http({
            method: 'get',
            url: 'checkForNewPlugins',
            skipLoadingNotification: true
         }).then(function(resp){
            return resp.data;
         });
      },
   };
}]);
