/* Copyright 2017 Vmware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   export class DatastoreFileBrowserController {
      public static $inject = ["datastoreBrowserConstants", "defaultUriSchemeUtil",
         "i18nService", "vuiConstants"];

      private FOLDER_TYPE: string = "folder";
      private DATASTORE_TYPE: string = "datastore";
      private FILE_TYPE: string = "file";
      private areContentItemsLoading: boolean = false;
      public i18n: any;
      public initialFileQueryType: number;
      public filterFileQueryTypes: {label: string, value: string}[];
      public fileQueryType: number;
      public showVmImagesFolder: boolean;
      public selectedTreeItem: any;
      public contentItems: any[];
      public selectedFile: any;
      public selectedFileChanged: any;
      public dsBrowserTreeAccessor: any = {};
      public selectedFileQueryType: any;
      public disablePreselect: boolean;
      public splitterOptions: any = {
         orientation: this.vuiConstants.splitter.orientation.HORIZONTAL,
         panes: [
            {
               min: "250px",
               size: "35%"
            }, {
               min: "250px",
               size: "35%"
            }, {
               min: "200px",
               size: "30%"
            }
         ]
      };

      constructor(private datastoreBrowserConstants: any,
            private defaultUriSchemeUtil: any,
            i18nService: any, private vuiConstants: any) {

         this.i18n = i18nService.getString;
      }

      $onInit(): void {
         this.fileQueryType = this.initialFileQueryType;
         this.selectedFileQueryType = this.filterFileQueryTypes && this.filterFileQueryTypes.length > 0 ?
               this.filterFileQueryTypes[0] : undefined;
      }

      clearContentItems(): void {
         this.contentItems = [];
         this.selectedFile = null;
         this.notifySelectedFileChanged();
      }

      selectedTreeItemChanged(selectedTreeItem: any): void {
         this.clearContentItems();
         this.selectedTreeItem = selectedTreeItem;
         this.areContentItemsLoading = true;
      }

      selectedTreeItemFilesLoaded(selectedTreeItemContentFiles): void {
         this.areContentItemsLoading = false;
         this.contentItems = selectedTreeItemContentFiles;

         if (this.contentItems && this.contentItems.length === 1) {
            let item: any = this.contentItems[0];
            if (item.type === this.FILE_TYPE && !this.disablePreselect) {
               // if only one file is available in the contents panel
               // preselect it
               this.selectedContentItemChanged(item);
            }
         }
      }

      selectedContentItemChanged(selectedItem: any): void {
         let newItem: any = null;
         if (selectedItem.type === this.FOLDER_TYPE) {
            this.dsBrowserTreeAccessor.selectChildItem(selectedItem.path);
         } else {
            newItem = selectedItem;
         }

         this.selectedFile = newItem;
         this.notifySelectedFileChanged();
      }

      private notifySelectedFileChanged(): void {
         if (this.selectedFileChanged) {
            this.selectedFileChanged({selectedFile: this.selectedFile});
         }
      }

      fileQueryTypeChange(selectedFileType: any): void {
         this.clearContentItems();
         this.fileQueryType = selectedFileType.value;

         if (this.dsBrowserTreeAccessor.reloadContentFiles) {
            this.areContentItemsLoading = true;
            this.dsBrowserTreeAccessor.reloadContentFiles(this.fileQueryType);
         }
      };

      isDatastoreItemSelected(): boolean {
         if (!this.selectedTreeItem) {
            return false;
         }
         return this.selectedTreeItem.type === this.DATASTORE_TYPE;
      }

      isFolderItemSelected(): boolean {
         if (!this.selectedTreeItem) {
            return false;
         }
         return this.selectedTreeItem.type === this.FOLDER_TYPE;
      }

      isFileItemSelected(): boolean {
         if (!this.selectedFile) {
            return false;
         }
         return this.selectedFile.type === this.FILE_TYPE;
      };
   }

   export class DatastoreFileBrowserComponent {
      public bindings: any;
      public controller: any;
      public templateUrl: string;

      constructor() {
         this.bindings = {
            objectId: "<",
            datastoreIds: "<",
            initialFileQueryType: "<",
            filterFileQueryTypes: "<?",
            showVmImagesFolder: "<",
            selectedFile: "=?",
            selectedFileChanged: "&",
            selectedTreeItem: "=?",
            disablePreselect: "<?"
         };
         this.controller = DatastoreFileBrowserController;
         this.templateUrl = "resources/ui/components/datastore-file-browser/datastore-file-browser.html";
      }
   }

   angular.module("com.vmware.platform.ui")
         .component("datastoreFileBrowser",
               new DatastoreFileBrowserComponent());
}
