module platform {
   import IDirective = angular.IDirective;

   listViewDraggableDirective.$inject = ["listViewDragAndDropHandler"];
   /**
    * Function that returns a directive used to enabled the drag and drop functionality
    * into a kendo grid. Should be used along side the vui-datagrid directive.
    * Should be placed as a property of the grid element
    * Ex:
    * <div ng-if="datagridOptions"
    *     vui-datagrid="datagridOptions"
    * list-view-draggable="true">
    * </div>
    * @param listViewDragAndDropHandler
    * @returns {{restrict: string, link: (function(any, any, any): undefined)}}
    */
   function listViewDraggableDirective(listViewDragAndDropHandler):IDirective {
      return {
         restrict: 'A',
         controller: function ($scope, $element, $attrs) {
            let isDraggable:string = $scope.$eval($attrs.listViewDraggable);
            let listViewDraggable = new ListViewDraggable(listViewDragAndDropHandler);
            listViewDraggable.setListViewDraggable($element, isDraggable);
         }
      };
   }

   export class ListViewDraggable {
      private DRAGSTART:string = "dragstart";
      private DRAG:string = "drag";
      private DRAGEND:string = "dragend";
      private HINT:string = "hint";
      private _element:any;

      public constructor(private listViewDragAndDropHandler:any) {
      }

      /**
       * Method called from the listViewDraggableDirective only once it is used for modifing the dom
       * In this case if the attribute listViewDraggable is set to true then to the kendo grid
       * it is attached a listener for the kendoDraggable event
       * @param scope
       * @param elem
       * @param isDraggable
       */
      public setListViewDraggable(elem:any, isDraggable:string) {

         if (isDraggable === 'true') {
            this._element = elem;
            let kendoGrid:any = this.getGrid();
            kendoGrid.kendoDraggable({
               group: "gridGroup",
               filter: ".k-grid-content tr",
               hint: (target) => {
                  return this.onHint(target);
               },
               dragstart: (event) => {
                  this.onDragStart(event);
               },
               cursorOffset: {top: 10, left: 10},
               drag: (event) =>  {
                  this.onDrag(event);
               },
               dragend: (event) =>  {
                  this.onDragEnd(event);
               }
            });
         }
      }

      /**
       * Get the kendo-grid element
       * @returns {any}
       */
      private getGrid():JQuery {
         return this._element.children('div[kendo-grid]');
      }

      /**
       * Invoked when a drag event is started on an row of the grid
       * @param evt
       */
      private onDragStart(evt:Event):void {
         this.invokeHandler(this.acquireHandler(this.DRAGSTART), evt, evt.currentTarget);
      }

      /**
       * Invoked multiple times during a drag, each time the mouse is moved
       * @param evt
       */
      private onDrag(evt:Event):void {
         this.invokeHandler(this.acquireHandler(this.DRAG), evt, null);
      }

      /**
       * Invoked when a drag event is ended
       * @param evt
       */
      private onDragEnd(evt:Event):void {
         this.invokeHandler(this.acquireHandler(this.DRAGEND), evt, evt.currentTarget);
      }

      /**
       * Invoked when drag is started.
       * It returned an jquery element that will be appended to the dom
       * And should indicate that a drag event is started
       * @param target
       * @returns {undefined|any}
       */
      private onHint(target:Event):JQuery {
         return this.invokeHandler(this.acquireHandler(this.HINT), null, target);
      }

      /**
       * Augment the supplied 'evt' object with additional data and
       * invoke the event handler hooked at the $scope object.
       */
      private invokeHandler(fn:any, evt:Event|null, target:any) {
         if (!fn) {
            return;
         }
         let dataItems:any[]|undefined;
         if (target) {
            // Prepopulate the event object with some additional data.
            let grid:any = this.getGrid();
            let gridData:any = grid.data('kendoGrid');

            let selectedItems:any[] = [target];
            if(gridData.options.selectable) {
               let selection:any[] = gridData.select();
               if(selection.length > 1) {
                  selectedItems = selection;
               }
            }
            dataItems = _.map(selectedItems, function(item) {
               let itemData:any;
               itemData = gridData.dataItem(item);
               itemData.objRef = itemData.id;
               return itemData;
            });
         }
         return fn.call(this.listViewDragAndDropHandler, evt, dataItems);
      }

      private acquireHandler(name:string):any {
         let fn:any = this.listViewDragAndDropHandler[name];
         return fn;
      }
   }

   angular.module('com.vmware.platform.ui')
         .directive("listViewDraggable", listViewDraggableDirective);

}
