(function () {
   'use strict';
   angular
         .module('com.vmware.platform.ui')
         .factory('contextObjectService', contextObjectService);
   contextObjectService.$inject = ['$rootScope'];

   /**
    * @ngdoc service
    * @name contextObjectService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Service to get the selected context objects
    * @param $rootScope
    * @returns {{
         getSelection: getSelection
      }}
    */
   function contextObjectService($rootScope) {
      var service = {
         getSelection: getSelection
      };

      return service;

      /**
       * Returns the selected context objects
       * @returns [selectedElements]
       */
      function getSelection() {
         //used to check if a modal has been opened
         //this is the class of the modal background
         //if a modal exists with different class this check will not work.
         //The class should be added to the check as well
         var modalOpened = $('div[class*="modal-backdrop"]');

         var selection = [];
         if(modalOpened.length === 0) {
            var contentList = $('div[vx-extension-view-id="vsphere.core.inventory.serverObjectViewsExtension"] list-view div[kendo-grid]:first');
            if (contentList.length > 0) {
               var grid = contentList.data('kendoGrid');
               var selectedRows = grid.select();
               if (selectedRows.length > 0) {
                  for (var i = 0; i < selectedRows.length; i++) {
                     var data = grid.dataItem(selectedRows[i]);
                     selection.push(data.id);
                  }
               }
            } else if ($rootScope._route.objectId) {
               selection.push($rootScope._route.objectId);
            }
         }
         return selection;
      }

   }
}());

