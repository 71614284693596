angular.module('com.vmware.platform.ui').directive('vxTabPane', [
   function() {
      return {
         link: function($scope, element){
            var tabId = element.attr('vx-tab-pane');

            $scope.$on('vxTab.showTab', function(event, tabBeingShown){
               if(tabBeingShown === tabId) {
                  $scope.$eval(element.attr('vx-tab-on-show'));
               }
            });
         }
      };
   }
]);