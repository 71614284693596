/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {

   export interface RelationContext {
      relation: string;
      relatedItemUri: string;
   }

   /**
      A ---STATEFUL--- service used to preserve metadata about object navigator relations
      during a navigation procedure.
    */
   export class NavigatorRelationsService {

      private relationContext: RelationContext | undefined;

      setRelationContext(relation: string, relatedItemUri: string): void {
         this.relationContext = {
            relation: relation,
            relatedItemUri: relatedItemUri
         };
      }

      consumeRelationContext(): RelationContext {
         const context: RelationContext = this.relationContext;

         this.relationContext = undefined;

         return context;
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("navigatorRelationsService", NavigatorRelationsService);
}