/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Performance Service.
 *
 * Note: Not making this an angular service to also capture calls made before we load
 * angular, this is applicable only to the first page-load.
 */
//angular.module('com.vmware.platform.ui').factory('performanceService', ['$log',
//function($log) {
var performanceService = (function() {
   var AJAX_REQUEST_TYPE = 'xmlhttprequest';
   var HTML_REQUEST = '.html';

   // Event triggered when a perf measurement cycle is complete, can be used as a trigger
   // to update the UI.
   var PERF_CYCLE_COMPLETED_EVENT = 'perfCycleCompleted';

   // Time window used to detect that UI is in stable state now.
   // Using 600 ms here so that we can detect for example when list items are loaded as with 100 ms this doesn't work
   // Time between interactions should be bigger than this value to avoid accumulating performance data for more than
   // one interaction
   var TIME_OUT = 600;

   // Angular's rootScope.
   var rootScope = null;

   // Performance entries used to compare to determine UI is in stable state now.
   // Not an ideal heuristic, can we do better?
   var oldPerfEntries = -1;
   var pendingHttpCalls = 0;

   // Digest related data.
   var digestFired = false;
   var totalDigestTime = 0;
   var lastDigestEndTimestamp = null;

   // Current performance data, this is internal state, may not be in a stable state
   // until the perf cycle completes.
   var perfData = null;

   // Performance data associated with the last complete performance measurement cycle.
   var lastPerfData = null;

   var noAjaxCalls = true;

   //----------------------------------------------------------------------------------
   // Public API for performanceService.

   var perf = {
      /**
       * Returns the performance data associated with the last click.
       */
      getPerformanceData: function() {
         return lastPerfData;
      },

      /**
       * Returns the historic performance data associated with previous clicks.
       */
      getHistoricPerformanceData: function() {
         // Not implemented.
         return null;
      },

      /**
       * Initializes the performance service.
       * @internal.
       */
      init: function() {
         if (!h5.debug) {
            return;
         }
         // Some browsers like safari does not support the performance api...
         try {
            if (performance.clearResourceTimings()) {
            }
         } catch (e) {
            return;
         }
         // Global click handler to start performance timer.
         $(document).click(function(e) {
            startPerfMeasurement(false);
         });

         // For initial page load.
         startPerfMeasurement(true);
      },

      /**
       * Logs the start of an http request.
       */
      httpStart: function(config) {
         pendingHttpCalls++;
         if (isRequestAjax(config)) {
            noAjaxCalls = false;
         }
      },

      /**
       * Logs the end of an http request.
       */
      httpStop: function() {
         pendingHttpCalls--;
      },

      /**
       * Fired to mark the start of angular's digest cycle.
       */
      digestStart: function(time) {
        digestFired = true;
      },

      /**
       * Fired to mark the end of angular's digest cycle.
       */
      digestStop: function(time) {
        digestFired = true;
        lastDigestEndTimestamp = Date.now();
        totalDigestTime += time;
      },

      /**
       * Passes in any angular service that is required by performanceService.
       * @internal
       */
      onAngularLoad: function(rs) {
        rootScope = rs;
      }
   };
   perf.init();
   return perf;

   //----------------------------------------------------------------------------------
   // Private Methods.

   function startPerfMeasurement(waitForAjax) {
      initState();
      // Record the start time
      perfData.startTimestamp = Date.now();

      // Clear old perf data.
      performance.clearResourceTimings();

      issueSetTimeout(waitForAjax);
   }

   function timeoutHandler(waitForAjax) {
      // For initial page load, we want to wait until at least one ajax is made.
      if (waitForAjax && noAjaxCalls) {
         issueSetTimeout(waitForAjax);
         return;
      }

      // Compare the performance entries, if it hasn't changed, then we are good to go.
      var newPerfEntries = performance.getEntries();
      var length = newPerfEntries.length;
      if (pendingHttpCalls === 0 &&
            length === oldPerfEntries &&
            !digestFired) {
         processPerfData(newPerfEntries);
      } else {
         oldPerfEntries = newPerfEntries.length;
         digestFired = false;
         issueSetTimeout(waitForAjax);
      }
   }

   /**
    * Fired when all perf data related to a single click is available.
    */
   function processPerfData(newPerfEntries) {
      // UI is done loading.
      perfData.endTimestamp = lastDigestEndTimestamp;
      perfData.uiTimeInSeconds =
            Number(totalDigestTime/1000).toFixed(2);
      perfData.timeInSeconds =
            Math.round((perfData.endTimestamp - perfData.startTimestamp) / 10) / 100;

      // Do something with each of the performance entry, for now just log it.
      var length = newPerfEntries.length;
      var timeAjaxCalls = 0;

      var last = null;
      var element = null;
      for (var i=0; i < length; i++) {
         element = newPerfEntries[i];
         if (element.initiatorType === AJAX_REQUEST_TYPE &&
               !endsWith(element.name, HTML_REQUEST)) {
            var perfEntry = createPerfEntry(element);
            perfData.perfEntries.push(perfEntry);

            // Ajax time calculation.
            if (last && element.responseEnd < last.responseEnd)  {
               continue;
            } else if (last && element.startTime < last.responseEnd)  {
               timeAjaxCalls += element.responseEnd - last.responseEnd;
            } else {
               timeAjaxCalls += element.duration;
            }
            last = element;
         }
      }
      perfData.perfEntries.sort(function(entry1, entry2) {
        return entry2.duration - entry1.duration;
      });
      perfData.ajaxCallsCount = perfData.perfEntries.length;
      if (perfData.ajaxCallsCount === 0) {
        // Ignore this cycle;
        return;
      }

      perfData.ajaxTimeInSeconds = Number(timeAjaxCalls/1000).toFixed(2);
      // TODO use a flag to turn on those logs
      //console.log('Time taken to process the last click ' + perfData.timeInSeconds);
      //console.log('Time taken for ajax calls ' + perfData.ajaxTimeInSeconds);
      //console.log('Total ajax calls made ' + perfData.ajaxCallsCount);
      //console.log('Time taken for UI rendering ' + perfData.uiTimeInSeconds);

      var mainAppScope = angular.element("#main-app-div").scope();
      perfData.watchersCount =
            mainAppScope ? mainAppScope.$root.$$watchersCount : 'N/A';

      // This is required for the performance measurements and will be available only in debug
      /*jshint devel:true */
      console.log(perfData);
      lastPerfData = perfData;

      // fire an event
      if (rootScope !== null) {
         rootScope.$broadcast(PERF_CYCLE_COMPLETED_EVENT, perfData);
      }
   }

   function createPerfEntry(element) {
      var newPerfEntry = {};
      var name = element.name;

      if (h5 && h5.contextPath) {
        var ctxt = h5.contextPath;
        newPerfEntry.name = name.substring(name.indexOf(ctxt)+ ctxt.length);
      }

      // convert to seconds;
      newPerfEntry.duration = Number(element.duration/1000).toFixed(2);
      return newPerfEntry;
   }

   /**
    * Helper wrapper for setTimeout.
    */
   function issueSetTimeout(waitForAjax) {
      setTimeout(
            function() {
               timeoutHandler(waitForAjax);
            },
            TIME_OUT);
   }

   /**
    * Re-initializes the data state so that a new performance measurement cycle can start.
    */
   function initState() {
      perfData = new PerformanceData();
      oldPerfEntries = -1;
      lastDigestEndTimestamp = null;
      digestFired = false;
      totalDigestTime = 0;
   }

   function isRequestAjax(config) {
      if (config === null || config === undefined || config.url === null) {
         return false;
      }
      if (!endsWith(config.url, HTML_REQUEST)) {
         return true;
      }
      return false;
   }

   function endsWith(str, suffix) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
   }
}());
//}]);
