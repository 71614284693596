/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * @name com.vmware.platform.ui:datastoreBrowserConstants
 * @module com.vmware.platform.ui
 *
 * @description
 *    The `datastoreBrowserConstants` service holds datastores browser related constants.
 *
 *    List of available constants:
 *
 *    - fileQueryType
 *       - **`VM_DISK`** : flag which represents the query type corresponding to the VM
 *             disk files
 *       - **`FLOPPY_IMAGE`** : flag which represents the query type corresponding to
 *             the floppy image files
 *       - **`ISO_IMAGE`** : flag which represents the query type corresponding to the
 *             ISO image files.
 *       - **`VM_CONFIG_FILES`** : flag which represents the query type corresponding
 *             to the VM configuration (*.vmx, *.vmtx) file.
 *       - **`ALL_FILES`** : flag which represents the query type corresponding to
 *             the all file types.
 *       - **`FOLDERS`** : flag which represents the query type corresponding to folder
 *             types.
 */
angular.module('com.vmware.platform.ui').constant('datastoreBrowserConstants', {
   fileQueryType : {
      VM_DISK: parseInt("0x0001", 16),
      FLOPPY_IMAGE: parseInt("0x0002", 16),
      ISO_IMAGE: parseInt("0x0004", 16),
      VM_CONFIG_FILES: parseInt("0x0008", 16),
      ALL_FILES: parseInt("0x0010", 16),
      FOLDERS: parseInt("0x0020", 16),
      VM_DISK_TYPE_WITH_DETAILS: parseInt("0x0040", 16)
   }
});
