
/**
 * Copyright 2013 VMware, Inc. All rights reserved.
 *
 * The dropdown directives to create a dropdown button
 *
 * - Define button with a label
 * - Define Dropdown menu items
 * - Define separator
 */
angular.module('com.vmware.platform.ui').directive('vxDropdown', function () {
   return {
      restrict: 'E',
      transclude: true,
      scope: {
         label: '@'
      },

     controller:['$scope', function(scope) {
      scope.isOpen = false;
     }],

     template: '<div class="dropdown" ng-class="{open : isOpen}" >' +
            '<a class="btn dropdown-toggle"  ng-click="isOpen=!isOpen">' +
               '{{label}} ' +
               '<span class="caret"></span>' +
            '</a>' +
            '<ul class="dropdown-menu" ng-click="isOpen=!isOpen" ng-transclude></ul>' +
            '</div>',

     replace: true
   };
}).directive('vxDropdownItem', function () {
   return {
      restrict: 'E',
      scope: false,
      transclude: true,
      template: '<li ng-transclude></li>',
      replace: true
   };
}).directive('vxDropdownDivider', function () {
   return {
      restrict: 'E',
      transclude: false,
      template: '<li class="divider"/>',
      replace: true
   };
});
