(function() {
   angular.module('com.vmware.platform.ui').directive(
      'vxSharesControl', vxSharesControl);

   vxSharesControl.$inject = ['i18nService', 'sharesLevelsConstants'];

   function vxSharesControl(i18nService, sharesLevelsConstants) {

      function getSharesOptions() {

         var sharesLevelLabels = {};
         sharesLevelLabels[sharesLevelsConstants.sharesLevel.LOW] =
            i18nService.getString('Common', 'SharesControl.SharesInfoLevel.Low');
         sharesLevelLabels[sharesLevelsConstants.sharesLevel.NORMAL] =
            i18nService.getString('Common', 'SharesControl.SharesInfoLevel.Normal');
         sharesLevelLabels[sharesLevelsConstants.sharesLevel.HIGH] =
            i18nService.getString('Common', 'SharesControl.SharesInfoLevel.High');
         sharesLevelLabels[sharesLevelsConstants.sharesLevel.CUSTOM] =
            i18nService.getString('Common', 'SharesControl.SharesInfoLevel.Custom');

         return _.map(sharesLevelsConstants.sharesLevel, function(level) {
            return {
               id: level,
               label: sharesLevelLabels[level]
            };
         });
      }

      return {
         restrict: 'A',
         templateUrl: 'resources/ui/components/shares/vxSharesControl.html',
         scope: {
            sharesInfo: '=',
            defaultShares: '=',
            multiplier: '=',
            sharesAriaLabel: '<?',
            quantityCaretAriaLabel: "<?",
            quantityAriaLabel: "<?"
         },
         controller: ['$scope', function($scope) {

            var DEFAULT_MIN_CUSTOM_SHARES = 0;
            var DEFAULT_MAX_CUSTOM_SHARES = 1000000;

            $scope.sharesOptions = getSharesOptions();

            $scope.$watch('sharesInfo.level', function() {
               if ($scope.isSharesLevelCustom()) {
                  return;
               }
               $scope.sharesInfo.shares = getNumShares();
            });

            $scope.isSharesLevelCustom = function() {
               return $scope.sharesInfo.level ===
                  sharesLevelsConstants.sharesLevel.CUSTOM;
            };

            $scope.getMinCustomShares = function() {
               return DEFAULT_MIN_CUSTOM_SHARES;
            };

            $scope.getMaxCustomShares = function() {
               return DEFAULT_MAX_CUSTOM_SHARES;
            };

            $scope.sharesRecommendations = [
               {
                  value: $scope.getMinCustomShares(),
                  name: i18nService.getString('Common',
                     'SharesControl.MinCustomShares')
               }, {
                  value: $scope.getMaxCustomShares(),
                  name: i18nService.getString('Common',
                     'SharesControl.MaxCustomShares')
               }
            ];

            var sharesRangeErrorMessage = function() {
               return i18nService.getString('Common', 'SharesControl.RangeError',
                  $scope.getMinCustomShares(), $scope.getMaxCustomShares());
            };
            $scope.shareValueErrorMessages = {
               min: sharesRangeErrorMessage,
               max: sharesRangeErrorMessage
            };

            function getNumShares() {
               var levelMultiplier = 1;
               if ($scope.sharesInfo.level === sharesLevelsConstants.sharesLevel.LOW) {
                  levelMultiplier = 1/2;
               }
               if ($scope.sharesInfo.level === sharesLevelsConstants.sharesLevel.HIGH) {
                  levelMultiplier = 2;
               }
               var result = levelMultiplier * $scope.multiplier * $scope.defaultShares;
               result = Math.min(result, $scope.getMaxCustomShares());
               return Math.floor(result);
            }
         }]
      };
   }
})();
