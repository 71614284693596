/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for retrieving tree nodes from the backend
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('treeNodeService', treeNodeService);

   treeNodeService.$inject = ['$http', 'logService'];

   /**
    * Keeps a mapping between object aliases and primary object Id associated with a specific tree node.
    * Certain objects are not displayed in the tree (e.g. root resource pool of standalone host or cluster etc.), but
    * we still need to be able to map them to a specific tree node, so when we get a children_updated for those objects
    * we update children of the associated tree node.
    */
   var objAliasesToPrimaryObjId = {};

   function treeNodeService($http, logService) {

      var log = logService('treeONodeService');

      //Public API
      var service = {
         getRoot: getRoot,
         getChildren: getChildren,
         getChildrenByObjectIds: getChildrenByObjectIds,
         getPath: getPath,
         getSupportedTypes: getSupportedTypes,
         objAliasesToPrimaryObjId: objAliasesToPrimaryObjId
      };
      return service;

      /**
       * Performs a get request to the backend and retrieves the root node of the tree
       * @param treeId {String} the id of the tree
       * @param rootObjRef {String} /Optional/ String representation of object reference of the root node
       * @returns      {Promise} Promise with a single parameter which is the response data
       */
      function getRoot(treeId, rootObjRef) {
         var httpConfig = {
            method: 'GET',
            url: 'tree/root',
            params: {
               treeId: treeId
            }
         };

         //objRef is added to the params only when provided
         if (rootObjRef) {
            httpConfig.params.objRef = rootObjRef;
         }
         return getData(httpConfig);
      }

      /**
       * Performs a get request to the backend and retrieves the children of a given node
       *
       * For the multi node version of this function see `getChildrenByObjectIds`.
       *
       * @param treeId     {String} the id of the tree
       * @param nodeTypeId {String} the type of the node.
       *                   See the serverside tree definition for more details.
       * @param objRef     {String} String representation of object reference of the parent node
       * @returns {*}      {Promise} Promise with a single parameter which is the response data
       */
      function getChildren(treeId, nodeTypeId, objRef) {
         var httpConfig = {
            method: 'GET',
            url: 'tree/children',
            params: {
               treeId: treeId,
               nodeTypeId: nodeTypeId,
               objRef: objRef
            }
         };
         return getData(httpConfig).then(function(data) {
            // Every time we retrieve a new node, we check if there are alias objects associated with that node and
            // add them to objAliasesToPrimaryObjId map, so that when we get an update for an alias object we know which
            // tree node to update.
            _.each(data, function(object) {
               if (object.nodeTypeId) {
                  object.spriteCssClass = object.spriteCssClass || "";
                  object.spriteCssClass += " " + object.nodeTypeId;
               }
               _.each(object.aliases, function(alias) {
                  objAliasesToPrimaryObjId[alias] = object.objRef;
               });
            });
            return data;
         });
      }

      /**
       * Performs a post request to the backend and retrieves the children of multiple nodes
       *
       * For the single node version of this function see `getChildren`.
       *
       * @param treeId     {String} the id of the tree
       * @param parents    {Object[]} parents who's children are to be fetched
       *    [
       *       {
       *          nodeTypeId: <String>, // the type of the parent node
       *          objRef: <String> // the id of the parent node
       *       },
       *       ...
       *    ]
       * @returns {*}      {Promise} Promise with a single parameter which is the response data
       *    {
       *       objRef: [
       *          <childObject>,
       *          ...
       *       ],
       *       ...
       *    }
       */
      function getChildrenByObjectIds(treeId, parents) {
         var httpConfig = {
            method: 'POST',
            url: 'tree/childrenByObjectIds',
            skipLoadingNotification: true,
            data: {
               treeId: treeId,
               parents: parents
            }
         };
         return getData(httpConfig).then(function(data) {
            // Every time we retrieve a new node, we check if there are alias objects associated with that node and
            // add them to objAliasesToPrimaryObjId map, so that when we get an update for an alias object we know which
            // tree node to update.
            _.each(data, function (children) {
               _.each(children, function(child) {
                  _.each(child.aliases, function(alias) {
                     objAliasesToPrimaryObjId[alias] = child.objRef;
                  });
               });
            });

            return data;
         });
      }

      /**
       * Performs a request to the backend and retrieves the available paths from a root object
       * (FROM_OBJECT) down to another object (TO_OBJECT) for a given tree id
       *
       * @param params the available properties are:
       *        treeId     {String} the id of the tree
       *        rootObjRef {String} Id of the root node. Optional when the root node is a fixed object.
       *                   Required when the root of the tree is not a fixed item (e.g. Datacenter as a root)
       *        objRef     {String} The id of the (TO_OBJECT)
       *        options    additional object for custom usage. Currently used to hold data that
       *                   the caller may relate to. A future possible usage is holding
       *                   an extra config options for the request
       * @returns object:
       *                {
       *                   paths:   [  [path1], [path2], etc.] //array of paths
       *                   options: JSON.toString(options_object_from_the_request)
       *                }
       */
      function getPath(params) {
         var httpConfig = {
            method: 'GET',
            url: 'tree/path',
            skipLoadingNotification: true,
            params: angular.copy(params)
         };
         return getData(httpConfig);
      }

      /**
       * Performs a request to the backend and retrieves a list of types supported by
       * the tree with the given id.
       * @param treeId the id of the tree for which the supported types will be computed.
       * @returns an array of strings representing the supported types.
       */
      function getSupportedTypes(treeId) {
         var httpConfig = {
            method: 'GET',
            url: 'tree/supportedTypes',
            params: {
               treeId: treeId
            }
         };
         return getData(httpConfig);
      }

      function getData(httpConfig) {
         angular.extend(httpConfig, {
               skipErrorInterceptor: true
         });
         return $http(httpConfig).then(function (resp) {
            return resp ? resp.data || {} : {};
         }).catch(function (err) {
            log.error(err);
         });
      }

   }

})();
