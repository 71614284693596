/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * @ngdoc directive
 * @name com.vmware.platform.ui.directive:vxObjectLabel
 *
 * @description
 * Represents and object with an icon and and a label containing the object name<BR><BR>
 *
 * Component reuses the 'object-link' renderer.
 *
 * Sample usage:
 *
 * <vx-object-label
 *    object-id="{{controller.datacenterId}}"
 *    object-icon="{{controller.objectIcon}}"
 *    object-name="{{controller.objectName}}">
 * </vx-object-label>
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxObjectLabel', vxObjectLabel);

   vxObjectLabel.$inject = ['columnRenderersRegistry', '$timeout'];

   function vxObjectLabel(columnRenderersRegistry, $timeout) {
      var propertiesChanged = false;
      return {
         restrict: 'E',
         scope: {
            objectIcon: '@',
            objectName: '@',
            objectId: '@'
         },
         link: function(scope, element, attrs) {
            attrs.$observe('objectId', invalidateProperties);
            attrs.$observe('objectIcon', invalidateProperties);
            attrs.$observe('objectName', invalidateProperties);

            renderElement(scope);

            function invalidateProperties() {
               if (propertiesChanged) {
                  return;
               }
               propertiesChanged = true;
               $timeout(function() {
                  if (propertiesChanged) {
                     renderElement(scope);
                     propertiesChanged = false;
                  }
               }, 0);
            }

            function renderElement(scope) {
               var renderer =
                     columnRenderersRegistry.getColumnRenderer('object-link');
               var data = {
                  id: scope.objectId,
                  label: scope.objectName
               };
               element.html(renderer(['id', 'label', scope.objectIcon], data));
            }
         }
      };
   }
})();
