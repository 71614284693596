/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive for right-click on a node of a kendo-tree view. Given a function callback for the vxTreeRightClick attribute, it calls that function.
 * The function is called passing the (event) parameter. event.data contains the object reference corresponding to the selected node.
 * Usage: Place this on the element which contains a kendo tree
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
         .directive('vxTreeRightClick', vxTreeRightClick);

   vxTreeRightClick.$inject = ['$parse'];

   function vxTreeRightClick($parse) {

      var directive = {
         restrict: 'A',
         link: link
      };
      return directive;


      function link(scope, element, attrs) {
         var fn = null;
         if (attrs.vxTreeRightClick !== '') {
            fn = $parse(attrs.vxTreeRightClick);
         }

         //For contextMenu, kendo uses <filter: ".k-in">
         // http://docs.telerik.com/kendo-ui/web/treeview/how-to/show-context-menu
         element.on('contextmenu', ".k-in", function(event) {
            event.preventDefault();
            if (fn === null) {
               return;
            }

            //prepare the data
            var elClicked = $(event.target);
            var kendoTreeView = element.data('kendoTreeView');
            if (!kendoTreeView) {
               return;
            }
            event.data = kendoTreeView.dataItem(elClicked);

            //envoke the callback
            var callback = function() {
               fn(scope, {$event:event});
            };
            scope.$apply(callback);
         });
      }
   }

})();
