/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {

   'use strict';

   angular.module('com.vmware.platform.ui').factory('dragAndDropManager',
         ['logService', 'defaultUriSchemeUtil', 'dragAndDropRulesService',
            'actionsService','validationExpressionParserService',
            function(logService, defaultUriSchemeUtil, dragAndDropRulesService,
                  actionsService, validationExpressionParserService) {

               function DragAndDropManager(logService, defaultUriSchemeUtil,
                     dragAndDropRulesService, validationExpressionParserService) {
                  var self = this;

                  self._log = logService('dragAndDropManager');
                  self._cachedActionContextsByDropTargetUid = {};
                  self._rulesLookupByDragObjectType = {};

                  dragAndDropRulesService.getRules().then(function(rules) {
                     self.setDragAndDropRules(rules);
                  });

                  self.clearCache = function() {
                     self._cachedActionContextsByDropTargetUid = {};
                  };

                  self.isDropValid = function(dragItems, dropTarget) {
                     dragItems = removeDropTargetFromDragItems(dragItems, dropTarget);

                     if (!dropTarget || !isDragValid(dragItems)) {
                        return false;
                     }

                     var cachedResult =
                           self._cachedActionContextsByDropTargetUid[dropTarget.objRef];

                     if (typeof cachedResult === 'boolean') {
                        return cachedResult;
                     }

                     var validDraggedObjects =
                           getValidDragObjects(dragItems, dropTarget);

                     var isDropTargetValid = validDraggedObjects.length > 0;

                     return setCachedResultAndReturn(dropTarget.objRef, !!isDropTargetValid);
                  };

                  self.invokeAction = function(dragItems, dropTarget) {
                     dragItems = removeDropTargetFromDragItems(dragItems, dropTarget);

                     if (!self.isDropValid(dragItems, dropTarget)) {
                        return;
                     }

                     var validDraggedObjects =
                           getValidDragObjects(dragItems, dropTarget);

                     if(validDraggedObjects.length > 0) {
                        var rulesByDragObjectType =  getRulesByDragObject(dragItems[0]);
                        evaluateActionAndInvokeFunction(
                              rulesByDragObjectType.actionUid, validDraggedObjects, dropTarget.objRef);
                     }

                  };

                  self.setDragAndDropRules = function(rules) {
                     self._rulesLookupByDragObjectType = rules;
                  };

                  function isDragValid (dragItems) {
                     if (_.isEmpty(dragItems)) {
                        return false;
                     }
                     var dragSource = dragItems[0];

                     // if the selection is of a mixed type the drag is not valid
                     var isMixedDrag = isDragMixed(dragItems);
                     if (isMixedDrag) {
                        return false;
                     }

                     var rulesByDragObjectType = getRulesByDragObject(dragSource);
                     if (!rulesByDragObjectType) {
                        return false;
                     }

                     return true;
                  }

                  function setCachedResultAndReturn(targetObjRef, result) {
                     self._cachedActionContextsByDropTargetUid[targetObjRef] = result;
                     return result;
                  }

                  function getRulesByDragObject(dragSource) {
                     if (!dragSource) {
                        return null;
                     }
                     var sourceType = defaultUriSchemeUtil.getEntityType(dragSource.objRef);
                     var rules = self._rulesLookupByDragObjectType[sourceType];
                     return rules;
                  }

                  function evaluateActionAndInvokeFunction(actionId, targets, dropTarget){
                     if (targets.length > 0) {
                        actionsService.getAction(actionId, targets).then(function(actionEval) {
                           if(actionEval && actionEval.available === true) {
                              actionsService.invokeAction(actionEval, {dropTarget: dropTarget});
                           }
                        });
                     }
                  }

                  /**
                   * if the drop target is among the selected drag items,
                   * removes it from the list
                   * @param dragItems
                   * @param dropTarget
                   * @returns {*}
                   */
                  function removeDropTargetFromDragItems(dragItems, dropTarget) {
                     if (_.isEmpty(dragItems) || !dropTarget) {
                        return [];
                     }
                     return _.filter(dragItems, function(dragItem) {
                        return dragItem.objRef !== dropTarget.objRef;
                     });
                  }

                  function isDragMixed(dragItems) {

                     var dragSource = dragItems[0];
                     var sourceType = defaultUriSchemeUtil.getEntityType(dragSource.objRef);
                     // get the item that has a different entity type
                     var isMixedDrag = _.some(dragItems, function(dragItem) {
                        var dragItemType =
                              defaultUriSchemeUtil.getEntityType(dragItem.objRef);
                        return dragItemType !== sourceType;
                     });

                     // if the selection is of a mixed type the drag is not valid
                     return isMixedDrag;
                  }

                  function getValidDragObjects(dragItems, dropTarget) {
                     var dragSource = dragItems[0];
                     var rulesByDragObjectType = getRulesByDragObject(dragSource);

                     // if more than one drag item and the rule's allowMultipleDragObjects
                     // flag is false do not allow drop
                     if(dragItems.length > 1
                           && !rulesByDragObjectType.allowMultipleDragObjects) {
                        return [];
                     }

                     var sourceIds = _.map(dragItems, function(dragItem) {
                        return dragItem.id;
                     });

                     var targetType = defaultUriSchemeUtil.getEntityType(dropTarget.objRef);
                     var isDropTargetValid =
                           (rulesByDragObjectType.dropTargetTypesList.indexOf(targetType) >= 0);

                     var validDraggedObjects =
                           validationExpressionParserService.evaluateValidationExpressions(
                                       rulesByDragObjectType.validationExpressions,
                                       sourceIds, dropTarget.id, dragItems, dropTarget);


                     isDropTargetValid = isDropTargetValid && (validDraggedObjects.length > 0);

                     return isDropTargetValid ? validDraggedObjects : [];
                  }
               }

               return new DragAndDropManager(logService, defaultUriSchemeUtil,
                     dragAndDropRulesService, validationExpressionParserService);
            }]);
})();
