/**
 * Service providing an API for saving a string in a file.
 */
//TODO use ng4 service
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').service('fileSaverService', fileSaverService);
   fileSaverService.$inject = ['logService'];

   function fileSaverService(logService) {
      var log = logService('fileSaverService');
      return {
         save: save
      };

      function save(data, fileName) {
         if (!_.isString(data)) {
            log.error('Data argument should be a string');
            return;
         }
         if (!_.isString(fileName) || _.isEmpty(fileName)) {
            log.error('Filename argument should be a not empty string');
            return;
         }
         var blob = new Blob([data], {type: "text/plain;charset=utf-8"});
         saveBlob(blob, fileName, true);
      }

      function saveBlob(blob, fileName, disableAutoBOM) {
         saveAs(blob, fileName, disableAutoBOM);
      }
   }
})();
