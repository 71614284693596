(function() {
   /**
    * Directive for localizing aria-label attribute of a given html element.
    */
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxLocalizedAriaLabel', ["i18nService",
      function (i18nService) {
         return {
            restrict: 'A',
            scope: false,
            link: function ($scope, $element, $attrs) {
               $scope.$watch($attrs.vxLocalizedAriaLabel, function (newValue) {
                  if (newValue) {
                     const stringProperties = newValue.split(":");
                     const localizedLabel = i18nService.getString.apply(i18nService, stringProperties);
                     if (localizedLabel) {
                        $attrs.$set("aria-label", localizedLabel);
                     }
                  }
               });
            }
         };
      }]);
})();
