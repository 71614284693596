/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Resizes the contained kendo grid to fill it's parent (i.e. have 100% height)
 */
angular.module('com.vmware.platform.ui').directive('vxStretchGrid', [
function() {
   'use strict';
   return {
      restrict: 'A',
      link: function(scope, element) {
         scope.$on('resize', resizeGrid);

         function resizeGrid() {
            kendo.resize(element, true);
         }

         scope.$on('kendoWidgetCreated', function(event, widget) {
            if (!widget || widget.constructor !== kendo.ui.Grid) {
               return;
            }

            widget.resize = _.wrap(widget.resize, function(originalResize) {
               var args = Array.prototype.slice.call(arguments, 1);
               // force resize
               if (args.length === 0) {
                  args.push(true);
               } else {
                  args[0] = true;
               }

               return originalResize.apply(widget, args);
            });

            widget._setContentHeight = function() {
               var that = this,
                  options = that.options,
                  height = that.wrapper.innerHeight(),
                  header = that.wrapper.children(".k-grid-header"),
                  scrollbar = kendo.support.scrollbar();

               if (options.scrollable && that.wrapper.is(":visible")) {

                  height -= header.outerHeight();

                  if (that.pager) {
                     height -= that.pager.element.outerHeight();
                  }

                  if(options.groupable) {
                     height -= that.wrapper.children(".k-grouping-header").outerHeight();
                  }

                  if(options.toolbar) {
                     height -= that.wrapper.children(".k-grid-toolbar").outerHeight();
                  }

                  if (that.footerTemplate) {
                     height -= that.wrapper.children(".k-grid-footer").outerHeight();
                  }

                  var isGridHeightSet = function(el) {
                     var initialHeight, newHeight;
                     if (el[0].style.height) {
                        return true;
                     } else {
                        initialHeight = el.height();
                     }

                     el.height("auto");
                     newHeight = el.height();

                     if (initialHeight !== newHeight) {
                        el.height("");
                        return true;
                     }
                     el.height("");
                     return false;
                  };

                  if (isGridHeightSet(that.wrapper)) { // set content height only if needed
                     if (height > scrollbar * 2) { // do not set height if proper scrollbar cannot be displayed
                        if (that.lockedContent) {
                           scrollbar = that.table[0].offsetWidth > that.table.parent()[0].clientWidth ? scrollbar : 0;
                           that.lockedContent.outerHeight(height - scrollbar);
                        }

                        that.content.outerHeight(height);
                     } else {
                        that.content.outerHeight(scrollbar * 2 + 1);
                     }
                  }
               }
            };

            resizeGrid();
         });
      }
   };
}]);
