/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Service for retrieving and persisting remote data.
 */
angular.module('com.vmware.platform.ui').factory('persistenceService', ['$http',
function($http) {

   function get(url, params) {
      return $http.get(url, {params: params}).then(function(result) {
         if (result) {
            return result.data;
         }
         return [];
      });
   }

   function post(url, data) {
      return $http.post(url, data).then(function(result) {
         return result.data;
      });
   }

   function remove(url, params) {
      return $http.delete(url, {params: params}).then(function(result) {
          return result.data;
      });
   }

   function isNullOrEmptyString(str) {
      if (!str || !str.replace(/^\s+|\s+$/g,'')) {
         return true;
      }
      return false;
   }

   return {

      /**
       * Get the metadata by specifying the search condition and the path.
       * The condition object must look like this:
       * {
       *    "key":"<some key>", // e.g. com.vmware.usersettings.category.uid
       *    "op":"=",
       *    "value":"<some value>" // e.g. com.vmware.usersetting.savedsearches
       * }
       * The path is optional.
       */
      getMetadata: function(conditionObj, path) {
         path = path || "";
         var params = {
            "key" : conditionObj.key,
            "value" : conditionObj.value,
            "op" : conditionObj.op,
            "path" : path
         };
         var url = "persistence/metadata/";
         return get(url, params);
       },

       /**
        * Get the data for the setting by specifying the search condition and the path.
        * The condition object must look like this:
        * {
        *    "key":"<some key>", // e.g. "com.vmware.usersettings.uid""
        *    "op":"=",
        *    "value":"<some value>" // e.g. "80501CE2-A5A7-00EB-FF1A-0146AA9EEEE9"
        * }
        * The path is optional.
        */
      getData : function(conditionObj, path) {
         path = path || "";
         var params = {
            "key" : conditionObj.key,
            "value" : conditionObj.value,
            "op" : conditionObj.op,
            "path" : path
         };
         var url = "persistence/data/";
         return get(url, params);
      },

      /**
       * Set the data for the setting.
       * @param uid The unique id for the setting.
       * @param value The serialized value to be stored.
       * @param addtionalData Object which has additional data to be saved along with the value.
       * @param categoryId The category to which the setting.
       *    belongs to. e.g. com.vmware.usersetting.savedsearches.
       * @param contentType The content type of the data. This field is optional.
       *   Valid values are "CONTENT_TYPE_XML", "CONTENT_TYPE_BINARY", "CONTENT_TYPE_TEXT", "CONTENT_TYPE_JSON".
       */
      setData : function(uid, value, additionalData, categoryId, contentType) {
         if (isNullOrEmptyString(uid) || !value || isNullOrEmptyString(categoryId)) {
            return;
         }

         // Add category info to metadata
         additionalData["com.vmware.usersettings.category.uid"] = categoryId;
         additionalData["uid"] = uid;
         additionalData["com.vmware.usersettings.uid"] = uid;

         var metadata = {
            uid : uid,
            additionalData : additionalData,
         };

         var userData = {
            contentType : contentType,
            metadata : metadata,
            value : value
         };
         var url = "persistence/save/";
         return post(url, userData);
      },

      /**
       * Removes the data based on the setting uid.
       * @param uid The array of uid of the setting to remove.
       */
      removeData: function(uid) {
         var url = "persistence/remove/";
         var params = {
           uid: uid
         };
         return remove(url, params);
      },

      /**
       * Resets the user's persisted data.
       */
      reset: function() {
         var url = "persistence/reset/";
         return remove(url);
      }
   };
}]);
