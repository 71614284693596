/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The Alarms View controller.
 */
(function () {
   'use strict';

   // Component to be used from AngularJS code
   angular
         .module('com.vmware.platform.ui')
         .component('alarmsView', {
            templateUrl: 'resources/ui/views/alarms/AlarmsView.html',
            controller: AlarmsViewController
         });

   /**
    * Wrapper component designed specifically to upgrade alarmsViewContainer and
    * enable its usage in angular.next components.
    * This component is not intended to be used by angular 1.5 components. For this
    * purpose use alarmsView.
    */
   angular
         .module('com.vmware.platform.ui')
         .component('alarmsViewWrapper', {
            template: '<alarms-view></alarms-view>',
            controller: function() {
            }
         });

   AlarmsViewController.$inject = ['$scope',
      '$rootScope',
      'vuiUtils',
      'i18nService',
      'defaultUriSchemeUtil',
      'objectTypesService',
      'alarmService',
      'alarmIssueActionService',
      'websocketMessagingService',
      'vuiConstants',
      'columnRenderersRegistry',
      'timeFormatterService',
      '$q',
      'configurationService',
      'feedbackService'];

   function AlarmsViewController($scope,
                                 $rootScope,
                                 vuiUtils,
                                 i18nService,
                                 defaultUriSchemeUtil,
                                 objectTypesService,
                                 alarmService,
                                 alarmIssueActionService,
                                 websocketMessagingService,
                                 vuiConstants,
                                 columnRenderersRegistry,
                                 timeFormatterService,
                                 $q,
                                 configurationService,
                                 feedbackService) {

      //-----View bindable logic--------//
      var self = this;

      var MAX_VC_COUNT = 25;
      var MAX_VISIBLE_ALARMS = 30;

      var TOP_TRIGGERED_ALARMS_COUNT_IN_VC = 150;
      /**
       * This is the maximum number of alarms that will be kept in the alarms
       * buffer. The number is chosen so that there are enough alarms to cover
       * many deletes and to have fast sort() and concat() operations on the
       * array.
       * Roughly speaking it is 25 VCs, 150 triggered alarm objects per VC = 3750 items;
       */
      var MAX_NUMBER_OF_ALARMS_IN_BUFFER = MAX_VC_COUNT * TOP_TRIGGERED_ALARMS_COUNT_IN_VC;
      var LIVE_ALARM_UPDATES_FLAG = 'live.updates.alarms.enabled';

      self.alarms = [];
      self.alarmTabExtId = 'vsphere.opsmgmt.alarms.triggeredAlarmsView';
      var linkRenderer = columnRenderersRegistry.getColumnRenderer('link');
      self.datagridOptions = {
         columnDefs: [
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.object'),
               field: 'object',
               template: createRendererForAlarmObject,
               sortable: true,
               searchable: true
            },
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.status'),
               field: 'status',
               template: function (dataItem) {
                  var text = dataItem.status;
                  var icon = dataItem.iconClass;
                  return linkRenderer(null,text, icon);
               },
               sortable: true,
               searchable: true
            },
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.name'),
               field: 'name',
               template: createRendererForAlarmName,
               sortable: true,
               searchable: true
            },
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.triggered'),
               field: 'triggeredOnString',
               sortable: true,
               searchable: true
            },
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.acknowledgedTime'),
               field: 'isAcknowledgedIconClass',
               template: function (dataItem) {
                  return linkRenderer(null, timeFormatterService.timestampToText(dataItem.acknowledgedTime), dataItem.isAcknowledgedIconClass);
               },
               sortable: true,
               searchable: true
            },
            {
               displayName: i18nService.getString('AlarmsUi', 'alarmsView.tableHeaderText.acknowledgedByUser'),
               field: 'acknowledgedByUser',
               template: function (dataItem) {
                  return linkRenderer(null, dataItem.acknowledgedByUser, dataItem.acknowledgedByUser);
               },
               sortable: true,
               searchable: true
            }
         ],
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         columnMenu: {
            sortable: false, // hides the sort menu items in the column menu
            messages: {
               columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
               filter: i18nService.getString('CommonUi', 'listview.filterMenu')
            }
         },
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         noInput: true,
         height: '100%',
         resizable: true,
         reorderable: true,
         selectedItems: [],
         sortOrder: [{
            field: 'startTimeString', //Initial sort when data is loaded into the grid,to show the sort direction in ui
            dir: 'desc'
         }]
      };


      //-----Internal Implementation--------//

      var alarmStateManager = new AlarmStateManager();

      this.getAllAlarms = function () {
         return alarmService.getAlarms().then(onAlarmsRetrieved);
      };

      configurationService.getProperty(LIVE_ALARM_UPDATES_FLAG).then(function(isAlarmRefreshEnabled) {
         // Subscribe for alarm updates after initial alarm loading.
         if (isAlarmRefreshEnabled !== 'false') {
            //Re-opened web sockets event listener
            $rootScope.$on('reOpenWebSocketsEvent', initializeWebsocket);
            websocketMessagingService.initializeLiveUpdates().then(function(subscribe) {
               $rootScope.$on('alarms', onAlarmsUpdates);
               subscribe('/topic/alarms');
            });
         } else {
            self.getAllAlarms();
         }
      });

      function initializeWebsocket() {
         websocketMessagingService.initializeLiveUpdates().then(function (subscribe) {
            subscribe('/topic/alarms');
         });
      }

      function refreshData() {
         vuiUtils.safeApply($scope, function () {
            self.datagridOptions.data = populateDatagrid();
         });
      }

      function populateDatagrid() {
         self.alarms = alarmStateManager.sortedAlarms();
         return _.map(self.alarms, function(alarm) {
            return {
               object: alarm.triggeringObjectName,
               status: alarm.severity,
               iconClass: alarm.iconStatusClass,
               name: alarm.title,
               isAcknowledgedIconClass: alarm.iconAcknowledgedClass,
               acknowledgedByUser: alarm.acknowledgedByUser,
               acknowledgedTime: alarm.acknowledgedOn,
               vimAlarmInfo: alarm.vimAlarmInfo,
               vimAlarmState: alarm.vimAlarmState,
               triggeredOn: alarm.triggeredOn,
               triggeredOnString: alarm.triggeredOnString
            };
         });
      }

      /**
       * Handles formatting of time in the alarm.
       * @param alarm
       *    Triggered alarms.
       */

      function formatTriggeredDate(alarm) {
         return timeFormatterService.formatDate(alarm.details.alarmState.time).then(function(formattedDate) {
            alarm.details.alarmState.triggeredOnString = formattedDate;
            return alarm;
         });
      }

      /**
       * Handles formatting of time of updates to the alarm. The alarms are received using websocket.
       * @param partialupdate
       *    partialupdated alarm.
       */
      function formatTriggeredDateForWebSockets(partialupdate) {
         if(partialupdate.data) {
            return timeFormatterService.formatDate(partialupdate.data.time).then(function(formattedDate) {
               partialupdate.data.triggeredOnString = formattedDate;
               return partialupdate;
            });
         }
         return $q.when(partialupdate);

      }
      /**
       * Handles triggered alarms retrieval.
       * @param result
       *    Triggered alarms.
       */
      function onAlarmsRetrieved(result) {
         // Invalidate all alarms before processing absolute values.
         self.alarms = [];
         var promises = [];
         _.each(result, function(alarmData) {
            var promise = formatTriggeredDate(alarmData);
            promise.then(alarmStateManager.processInitialAlarm);
            promises.push(promise);
         });

         $q.all(promises).then(function() {
            refreshData();
         });
      }

      /**
       * When new websocket updates to alarms are received
       *
       * @param event
       *    the triggering event
       * @param partialUpdate
       *    contains updates/deletes
       */
      function onAlarmsUpdates(event, partialUpdate) {
         var promises = [];
         _.each(partialUpdate.updates, function(partialupdate) {
            var promise = formatTriggeredDateForWebSockets(partialupdate);
            promise.then(processUpdates);
            promises.push(promise);
         });

         $q.all(promises).then(function() {
            refreshData();
         });
      }

      this.showActionsMenu = function(event) {
         alarmIssueActionService.showActionsMenu(
            event,
            $scope,
            event.data[0].vimAlarmInfo,
            event.data[0].vimAlarmState,
            event.clientX,
            event.clientY,
            event.currentTarget);
      };

      /**
       * Lets you navigate to alarm detail from alarms list summary
       * @param $event
       * @param alarm
       */
      this.navigateToTriggeredAlarmsPage = function ($event, alarm) {
         $event.stopPropagation();
         var definedInObjectType = defaultUriSchemeUtil.getPartsFromVsphereObjectId(alarm.triggeringObjectId).type;
         objectTypesService.getObjectTypeSpec(definedInObjectType).then(function(data) {
            return $scope._navigateToViewAndObject(
               data.namespace + '.triggeredAlarmsView',
               alarm.triggeringObjectId
            );
         });
      };

      /**
       * Processes the following Alarm stages: Added new, acknowledged and reset-to-green
       *
       * @param partialUpdateItem
       *    contains alarm update data
       */
      function processUpdates(partialUpdateItem) {
         if (!partialUpdateItem.isDelta) {
            return alarmStateManager.processNewAlarmFromWebsocket(partialUpdateItem);
         }
         if (partialUpdateItem.isDelta && partialUpdateItem.data) {
            return alarmStateManager.processUpdatedAlarmFromWebsocket(partialUpdateItem);
         }
         if (partialUpdateItem.isDelta && !partialUpdateItem.data) {
            return alarmStateManager.processRemovedAlarmFromWebsocket(partialUpdateItem);
         }
      }

      function AlarmStateManager () {
         var alarmsByAlarmKey = {};
         var alarmsQueue = [ ];

         this.processInitialAlarm = function (alarmData) {
            var vimAlarmInfo = alarmData.details.alarmInfo;
            var vimAlarmState = alarmData.details.alarmState;

            var alarmInfo = {
               title: vimAlarmInfo.name,
               description: vimAlarmInfo.description,

               iconAcknowledgedClass: alarmService.getAcknowledgedStatusIconClass(vimAlarmState.acknowledged),
               iconStatusClass: alarmService.getStatusIconClass(vimAlarmState.overallStatus),
               severity: alarmService.getSeverity(vimAlarmState.overallStatus),
               status: alarmService.getAcknowledgedStatus(vimAlarmState),

               acknowledgedOn: vimAlarmState.acknowledgedTime,
               acknowledgedByUser: vimAlarmState.acknowledgedByUser,
               triggeredOn: vimAlarmState.time,
               triggeredOnString: vimAlarmState.triggeredOnString,
               triggeringObjectId: defaultUriSchemeUtil.getVsphereObjectId(vimAlarmState.entity),
               triggeringObjectName : alarmData.entityName,

               definedInObjectId: defaultUriSchemeUtil.getVsphereObjectId(vimAlarmInfo.entity),
               definedInObjectName: alarmData.details.definedInEntityName,

               vimAlarmInfo: vimAlarmInfo,
               vimAlarmState: vimAlarmState
            };

            alarmsByAlarmKey[vimAlarmState.key] = alarmInfo;
            alarmsQueue.push(vimAlarmState.key);
         };

         this.processNewAlarmFromWebsocket = function (partialUpdateItem) {
            var vimAlarmState = partialUpdateItem.data;
            var metadata = partialUpdateItem.metadata;
            var alarmKey = vimAlarmState.key;

            var vimAlarmInfo =  partialUpdateItem.metadata[alarmKey];
            if (!vimAlarmInfo) {
               return;
            }

            var alarmInfo = {
               title: vimAlarmInfo.name,
               description: vimAlarmInfo.description,

               iconAcknowledgedClass: alarmService.getAcknowledgedStatusIconClass(vimAlarmState.acknowledged),
               iconStatusClass: alarmService.getStatusIconClass(vimAlarmState.overallStatus),
               severity: alarmService.getSeverity(vimAlarmState.overallStatus),
               status: alarmService.getAcknowledgedStatus(vimAlarmState),

               acknowledgedOn: vimAlarmState.acknowledgedTime,
               acknowledgedByUser: vimAlarmState.acknowledgedByUser,
               triggeredOn: vimAlarmState.time,
               triggeredOnString: vimAlarmState.triggeredOnString,
               triggeringObjectId: defaultUriSchemeUtil.getVsphereObjectId(vimAlarmState.entity),
               triggeringObjectName: metadata.issueInfo.entityName,

               definedInObjectId: defaultUriSchemeUtil.getVsphereObjectId(vimAlarmInfo.entity),
               definedInObjectName: metadata.alarmInfoName,

               vimAlarmInfo: vimAlarmInfo,
               vimAlarmState: vimAlarmState
            };

            alarmsByAlarmKey[alarmKey] = alarmInfo;

            if (alarmsQueue.length === MAX_NUMBER_OF_ALARMS_IN_BUFFER) {
               deleteLastAlarm();
            }

            alarmsQueue.push(alarmKey);
         };

         function deleteLastAlarm() {
            var alarmKey = alarmsQueue.shift();
            delete alarmsByAlarmKey[alarmKey];
         }

         function deleteAlarm(alarmKey) {
            var index = _.findIndex(alarmsQueue, alarmKey);

            if (index !== -1) {
               alarmsQueue.splice(index, 1);
            }
            delete alarmsByAlarmKey[alarmKey];
         }

         function processedStateProperties (vimAlarmState) {
            return {
               iconAcknowledgedClass: alarmService.getAcknowledgedStatusIconClass(vimAlarmState.acknowledged),
               iconStatusClass: alarmService.getStatusIconClass(vimAlarmState.overallStatus),
               severity: alarmService.getSeverity(vimAlarmState.overallStatus),
               triggeredOn: vimAlarmState.time,
               triggeredOnString: vimAlarmState.triggeredOnString,
               acknowledgedOn: vimAlarmState.acknowledgedTime,
               acknowledgedByUser: vimAlarmState.acknowledgedByUser,
            };
         }

         this.processUpdatedAlarmFromWebsocket = function (partialUpdateItem) {
            var vimAlarmState = partialUpdateItem.data;
            var alarmKey = vimAlarmState.key;

            if (alarmKey in alarmsByAlarmKey) {
               angular.extend(alarmsByAlarmKey[alarmKey], processedStateProperties(vimAlarmState), {
                  vimAlarmState: vimAlarmState
               });
            }
         };

         this.processRemovedAlarmFromWebsocket = function (partialUpdateItem) {
            var alarmKey = partialUpdateItem.source;

            if (alarmKey in alarmsByAlarmKey) {
               deleteAlarm(alarmKey);
            }
         };

         this.sortedAlarms = function () {
            return _.sortBy(alarmsByAlarmKey, 'triggeredOn').reverse().slice(0, MAX_VISIBLE_ALARMS);
         };
      }

      function createRendererForAlarmObject(dataItem) {
         var vimAlarmState = dataItem.vimAlarmState;
         var renderLink = columnRenderersRegistry.getColumnRenderer('link');
         var alarmEntity = vimAlarmState.entity;
         var objectUID = defaultUriSchemeUtil.createVmomiUri(alarmEntity.type, alarmEntity.value, alarmEntity.serverGuid);

         return renderLink(objectUID, dataItem.object, null, false);
      }

      function createRendererForAlarmName(dataItem) {
         var vimAlarmInfo = dataItem.vimAlarmInfo;
         var renderLink = columnRenderersRegistry.getColumnRenderer('link');
         var alarmEntity = vimAlarmInfo.entity;
         var objectUID = defaultUriSchemeUtil.createVmomiUri(alarmEntity.type, alarmEntity.value, alarmEntity.serverGuid);

         return renderLink(objectUID, dataItem.name, null, false, { extensionName : "vsphere.core.folder.alarmDefinitionsView"} );
      }
   }
})();
