/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
      .module('com.vmware.platform.ui')
      .factory('periodicRefreshService', periodicRefreshService);

   periodicRefreshService.$inject = ['$rootScope', 'dataService', 'vcService',
      'vcH5ConstantsService', 'configurationService', 'vxZoneService'];

   /**
    * This service provides periodic refresh for all views
    * when some properties of the context object change. This service works only for
    * vCenter 6.0 managed entities.
    * This service should not be injected by anyone. It is initialized on
    * application level.
    *
    * Service polls for changes in generation number of context object. If a change is
    * detected, it will broadcast modelChanged event.
    */
   function periodicRefreshService ($rootScope, dataService, vcService,
                                    vcH5ConstantsService, configurationService, vxZoneService) {

      var periodicRefreshIntervalId = null;

      var lastKnownGenerationNumber;

      var contextObject;

      // Default poll interval is 20 sec. Could be overridden in webclient.properties
      // file see vc60.periodic.refresh.rate property.
      var pollInterval = 20000;

      var PERIODIC_REFRESH_SERVICE = 'periodicRefreshService';

      // --------------- public APIs  ----------------
      var service = {
         init: init
      };

      // --------------- API implementation ----------------

      function init() {
         configurationService.getProperty('vc60.periodic.refresh.rate').then(
            function(rawRefreshRate) {
               var refreshRate = parseInt(rawRefreshRate, 10);
               if (refreshRate === -1) {
                  // Disable periodic refresh
                  return;
               }

               if (refreshRate.toString() === rawRefreshRate && refreshRate > 10) {
                  pollInterval = refreshRate * 1000;
               }
               subscribeForContextObjectChange();
            }, function() {
               // ignore error and proceed with default poll interval
               subscribeForContextObjectChange();
            }
         );
      }

      function subscribeForContextObjectChange() {
         $rootScope.$watch(
            function () {
               return $rootScope._route.objectId;
            },
            function (newValue) {
               startPeriodicRefresh(newValue);
            });
         $rootScope.$on(vcH5ConstantsService.MODEL_CHANGED_EVENT, onModelChanged);
      }

      function clearPeriodicRefreshInterval(){
         vxZoneService.runOutsideAngular(function() {
            if (periodicRefreshIntervalId !== null) {
               clearInterval(periodicRefreshIntervalId);
               periodicRefreshIntervalId = null;
            }
         });
      }

      function startPeriodicRefresh(objectId) {
         clearPeriodicRefreshInterval();
         lastKnownGenerationNumber = 0;
         if(!objectId || !vcService.isValidManagedEntityId(objectId)) {
            contextObject = null;
            return;
         }
         vcService.is65VcOrLater(objectId).then(function(is65VcOrLater) {
            if (!is65VcOrLater) {
               contextObject = objectId;
               monitorForObjectChange(objectId);
               vxZoneService.runOutsideAngular(function() {
                  periodicRefreshIntervalId =
                     setInterval(monitorForObjectChange, pollInterval, objectId);
               });
            } else {
               contextObject = null;
            }
         });
      }

      function monitorForObjectChange(objectId) {
         if (!objectId) {
            return;
         }
         getGeneration(objectId).then(
            function(data) {
               var currentGenerationNumber;
               if (data.hasOwnProperty('@generation') && data['@generation'].length > 0) {
                  if (data['@generation'][0].key === contextObject) {
                     currentGenerationNumber = data['@generation'][0].value;
                  } else {
                     // Context object has changed while retrieving data for previous
                     // context object. Just invalidate this request.
                     return;
                  }
               } else {
                  // Object has been deleted.
                  // No need to continue polling
                  contextObject = null;
                  clearPeriodicRefreshInterval();
                  // No need to notify for changes
                  return;
               }
               if (contextObject && lastKnownGenerationNumber &&
                     currentGenerationNumber !== lastKnownGenerationNumber) {
                  var changeInfo = {
                     operationType: 'CHANGE',
                     objectId: contextObject,
                     source: PERIODIC_REFRESH_SERVICE
                  };
                  vxZoneService.runInsideAngular(function() {
                     $rootScope.$broadcast(vcH5ConstantsService.MODEL_CHANGED_EVENT, changeInfo);
                  });
               }
               lastKnownGenerationNumber = currentGenerationNumber;
            }
         );

      }

      function getGeneration(objectId) {
         return dataService.getProperties(objectId, ['@generation']);
      }

      function onModelChanged (event, objectChangeInfo) {
         if (objectChangeInfo.source === PERIODIC_REFRESH_SERVICE ||
               objectChangeInfo.objectId !== contextObject) {
            // avoid self looping and changes on non context object
            return;
         }
         if (contextObject) {
            // Restart object change monitor for context object
            startPeriodicRefresh(contextObject);
         }
      }

      $rootScope.$on('$destroy', function(){
         clearPeriodicRefreshInterval();
      });

      return service;
   }
})();
