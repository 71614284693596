(function() {

   'use strict';

   angular.module('com.vmware.platform.ui')
         .service('actionConfirmationService', actionConfirmationService);

   actionConfirmationService.$inject = ['$compile', '$rootScope', 'i18nService', 'jsUtils',
         'logService', 'clarityModalService', 'defaultUriSchemeUtil', 'managedByMessageBuilderService'];

   function actionConfirmationService($compile, $rootScope, i18nService, jsUtils,
         logService, clarityModalService, defaultUriSchemeUtil, managedByMessageBuilderService) {

      var log = logService('actionConfirmationService');

      /**
       * Constant used to identify that a default confirmation message shouldn't be
       * displayed by the action framework.
       *
       * In multiple targets scenario if some of the confirmation messages
       * (confirmationTextNone, confirmationTextSome or confirmationTextAll) is not
       * defined we are displaying a default message. If you want to skip that default
       * message just set the corresponding confirmation text in plugin.xml to "CUSTOM".
       */
      var CUSTOM_CONFIRMATION = 'CUSTOM';

      var MANAGEDBY_PROPERTY = 'managedByExtensionKey';

      /**
       * All the actions that are defined by plugins have one of those invokers.
       * In case tha action is defined by a plugin, we do not display a managed by warning message
       */
      var pluginActionsInvokers = ['com.vmware.vsphere.client.htmlbridge.HtmlActionDelegate',
         'com.vmware.vsphere.client.pluginActions', 'com.vmware.vsphere.client.HtmlPluginHeadlessAction',
         'com.vmware.vsphere.client.HtmlPluginModalAction'];

      return {
         confirmIfRequired: confirmIfRequired,
         showConfirmationDialog: showConfirmationDialog,
         showWarningDialog: showWarningDialog
      };

      function confirmIfRequired(actionEval, confirmHandler, skipManagedByCheck) {

         if (jsUtils.isUndefinedOrNull(actionEval)) {
            log.error('ActionEval not found');
            return;
         }

         if (!actionEval.action) {
            log.error('ActionEval\'s action not found');
            return;
         }

         var actionId = actionEval.action.uid;
         if (!actionEval.invoker || actionEval.invoker.length === 0) {
            log.error('No invoker specified for action: ' + actionId);
            return;
         }

         var confirmationText;
         var showConfirmationFunc = showConfirmationDialog;
         var isMultipleTargets = false;

         if (actionEval.evaluationStatuses) {
            var totalTargetsCount = Object.keys(actionEval.evaluationStatuses).length;
            isMultipleTargets = totalTargetsCount > 1;

            if (!isMultipleTargets) {
               if (!actionEval.available) {
                  log.error('Unavailable action ' + actionId + ' for single object');
                  return;
               }

               // in case the text is empty or custom set the confirmationText to an empty string
               // - if the text is CUSTOM the confirmation will be handled by the action
               // - if it is just not set, if needed a warning for managed entity will be displayed
               confirmationText = getConfirmationText(actionEval.action.confirmationText, "");
            } else {
               if (!actionEval.available) {
                  // this is hit when the action is not available for any of the selected
                  // targets
                  confirmationText = getConfirmationText(
                        actionEval.action.confirmationTextNone,
                        i18nService.getString('Common', 'defaultConfirmation.noneOK'));

                  // in this case we need to display a warning dialog that has only OK button
                  // instead of Yes/No
                  showConfirmationFunc = showWarningDialog;
               } else {
                  var availableTargetsCount = 0;

                  _.each(actionEval.evaluationStatuses, function(evalStatus) {
                     if (evalStatus.available) {
                        availableTargetsCount++;
                     }
                  });

                  if (totalTargetsCount === availableTargetsCount) {
                     confirmationText = getConfirmationText(
                           i18nService.interpolate(actionEval.action.confirmationTextAll,
                                 [availableTargetsCount]),
                           i18nService.getString('Common', 'defaultConfirmation.allOK',
                                 availableTargetsCount));
                  } else {
                     confirmationText = getConfirmationText(
                           i18nService.interpolate(actionEval.action.confirmationTextSome,
                                 [availableTargetsCount, totalTargetsCount]),
                           i18nService.getString('Common', 'defaultConfirmation.someOK',
                                 availableTargetsCount, totalTargetsCount));
                  }
               }
            }
         }

         /**
          * Only check if the entity is managed in case skipManagedByCheck flag is false,
          * the action is available, the action is not defined by a plugin and the managed by property is set
          */
         var checkIfManaged = !skipManagedByCheck && actionEval.available &&
               actionEval.additionalProperties &&
               actionEval.additionalProperties[MANAGEDBY_PROPERTY] &&
               !_.contains(pluginActionsInvokers, actionEval.invoker);

         if (!!confirmationText) {
            var properties = getConfirmationDialogProperties(actionEval, confirmationText, confirmHandler);
            /**
             * In case the context object is managed by a solution, retrieve the name of the solution
             * and build the necessary text to be prepended to the confirmation text
             */
            if (checkIfManaged) {
               managedByMessageBuilderService.buildConfirmationText(actionEval.context.targetObjects, confirmationText)
                     .then(function(managedByConfirmationText) {
                        if (managedByConfirmationText) {
                           properties.message = managedByConfirmationText;
                        }
                        showConfirmationFunc(properties);
                     });
               return;
            }
            showConfirmationFunc(properties);
            return;
         }

         // we hit this case ONLY for managed VMs and vApps, for single target
         // that does not have custom handling of the confirmation logic
         // for all other cases of single objects, no matter if the confirmation text
         // is CUSTOM or not set at all confirmHandler will be called and
         // any confirmations defined in there will be shown
         if (!isMultipleTargets && checkIfManaged &&
               actionEval.action.confirmationText !== CUSTOM_CONFIRMATION) {
            managedByMessageBuilderService.buildConfirmationText(actionEval.context.targetObjects, "", true)
                  .then(function(managedByConfirmationText) {
                     if(managedByConfirmationText) {
                        var properties = getConfirmationDialogProperties(actionEval, confirmationText, confirmHandler);
                        properties.message = managedByConfirmationText;
                        showConfirmationFunc(properties);
                     } else {
                        // in case the managed by properties can not be retrieved invoke the action
                        if (confirmHandler && typeof confirmHandler === 'function') {
                           confirmHandler();
                        }
                     }
                  });
            return;
         }


         // we hit this call only for single target when there is no confirmationText
         // specified
         if (confirmHandler && typeof confirmHandler === 'function') {
            confirmHandler();
         }
      }

      /**
       * Displays a confirmation dialog for an action.
       *
       * @static
       * @memberOf actionConfirmationService
       * @param {Object} alertProperties An object with properties describing the dialog:
       *    title {string} - The title of the dialog.
       *    message {string} - The message in the dialog.
       *    yesHandler {Function} - A function called if the 'Yes' button is pressed.
       *    noHandler {Function} - A function called if the 'No' button is pressed.
       *
       *    OPTIONAL:
       *    useClarity {boolean} - Whether to use a clarity dialog or a vx one.
       *       Clarity is recommended, so set this to true.
       *    icon {string} - The icon to use. Its default value is "icon-warning-32".
       *       If null is used, there is no icon.
       *    yesIsDefaultButton {boolean} - The button focused by default is 'Yes' if this
       *       evaluates to true. This property works only if useClarity is set to true!
       * @returns {void}
       * @example
       * actionConfirmationService.showConfirmationDialog({
       *    useClarity: true,
       *    title: 'Log "foo"',
       *    message: 'Press the "Yes" button to log "foo" in the console.',
       *    yesHandler: function() { console.log('foo') }
       * });
       */
      function showConfirmationDialog(alertProperties) {
         _.defaults(alertProperties, {
            icon: 'icon-warning-32',
            yesHandler: function () {},
            noHandler: function () {}
         });
         _.extend(alertProperties, {
            yesLabel: i18nService.getString('Common', 'yes.label'),
            noLabel: i18nService.getString('Common', 'no.label')
         });

         if (alertProperties.useClarity) {
            showClarityConfirmationDialog(alertProperties);
         } else {
            showVxConfirmationDialog(alertProperties);
         }
      }

      //region Helper functions

      function showVxConfirmationDialog(alertProperties) {
         var scope = $rootScope.$new();

         scope.alertOptions = {
            title: alertProperties.title,
            text: alertProperties.message,
            icon: alertProperties.icon,
            visible: true,
            confirmOptions: {
               label: alertProperties.yesLabel,
               visible: true,
               disabled: false,
               onClick: function() {
                  alertProperties.yesHandler();
                  closeConfirmationDialog();
               }
            },
            rejectOptions: {
               label: alertProperties.noLabel,
               visible: true,
               disabled: false,
               focused: true,
               onClick: function() {
                  alertProperties.noHandler();
                  closeConfirmationDialog();
               }
            }
         };
         var alertTemplate = '<div id="action-confirmation" ' +
               'class="action-confirmation" vx-alert="alertOptions"></div>';
         var alertDialog = angular.element(alertTemplate);
         var compiledAlertDialog = $compile(alertDialog)(scope);

         var parentId = alertProperties.parent || "#main-app-div";
         angular.element(parentId).append(compiledAlertDialog);
      }

      function showClarityConfirmationDialog(alertProperties) {
         var modalOptions = {
            title: alertProperties.title,
            subTitle: alertProperties.subTitle,
            message: alertProperties.message,
            icon: alertProperties.icon,
            preserveNewlines: true,
            saveButtonLabel: alertProperties.yesLabel,
            cancelButtonLabel: alertProperties.noLabel,
            showXIcon: alertProperties.showXIcon,
            submit: alertProperties.yesHandler,
            onCancel: alertProperties.noHandler
         };

         if (!_.isEmpty(alertProperties.clarityIcon)) {
            modalOptions.clarityIcon = alertProperties.clarityIcon;
         }

         if(!alertProperties.yesIsDefaultButton) {
            modalOptions.defaultButton = 'close';
         }

         clarityModalService.openConfirmationModal(modalOptions);
      }

      function closeConfirmationDialog() {
         angular.element("#action-confirmation").remove();
      }

      /**
       * Gets confirmation text - if action confirmation text is not specified, we return
       * the default one. If the message is specified and is different than CUSTOM - we
       * return the message, otherwise - return null.
       */
      function getConfirmationText(actionConfirmationText, defaultConfirmationText) {
         if (!actionConfirmationText) {
            return defaultConfirmationText;
         }

         if (actionConfirmationText !== CUSTOM_CONFIRMATION) {
            return actionConfirmationText;
         }

         // if the confirmation is provided by the action definition and it's value is
         // CUSTOM - we don't have to show confirmation from this service
         return null;
      }

      function showWarningDialog(alertProperties) {
         clarityModalService.openConfirmationModal({
            title: alertProperties.title,
            message: alertProperties.message,
            submit: function () {},
            preserveNewlines: true,
            saveButtonLabel: i18nService.getString('Common', 'alert.ok'),
            cancelButtonLabel: null,
            hideCancelButton: true,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false
         });
      }

      function getConfirmationDialogProperties(actionEval, confirmationText, confirmHandler) {
         var confirmationTitle = !!actionEval.action.confirmationTitle ?
               actionEval.action.confirmationTitle :
               actionEval.action.label;
         var properties = {
            useClarity: true,
            title: confirmationTitle,
            message: confirmationText,
            yesHandler: confirmHandler,
            icon: null,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false
         };

         if (actionEval.context && actionEval.context.targetObjects.length === 1) {
            _.extend(properties, {
               subTitle: {
                  objectId: defaultUriSchemeUtil.getVsphereObjectId(actionEval.context.targetObjects[0])
               }
            });
         }

         return properties;
      }
      //endregion
   }
})();
