/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Watches the vx-vui-splitter-resize-notifier attribute and triggers
 * a resize on the contained kendo-splitter, when the value changes.
 * This directive works around a problem with split-views - if the splitter is
 * initialized before its child panes' contents, the view is not rendered
 * properly.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
         .directive('vxVuiSplitterResizeNotifier', vxVuiSplitterResizeNotifier);

   vxVuiSplitterResizeNotifier.$inject = ['$timeout'];

   function vxVuiSplitterResizeNotifier($timeout) {

      return {
         priority: -500,
         link: link
      };

      function link(scope, element, attrs) {
         var triggerResize = function() {
            var splitter = element.find('[kendo-splitter]').data('kendoSplitter');
            if(splitter) {
               splitter.resize(true);
            }
         };
         scope.$watch(attrs.vxVuiSplitterResizeNotifier, function(value) {
            if (typeof attrs.noDelay !== 'undefined') {
               triggerResize();
            } else {
               $timeout(triggerResize, 0);
            }
         });
      }
   }
})();