/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Datastore browser directive that creates a kendo tree view by dynamically loading the
 * tree nodes from the server based on object ref and/or provided datastore uids.
 *
 * Example:
 * 1) datastore browser tree declaration
 * <div
 *    datastore-browser-tree
 *    object-ref="objRef"
 *    datastores="datastoreUids"
 *    file-query-type="fileQueryType"
 *    show-vm-images-folder="showVmImagesFolder"
 *    on-selected-item-changed="selectedTreeItemChanged(item)"
 *    on-selected-item-files-loaded="selectedTreeItemFilesLoaded(contentFiles)"
 *    accessor="dsBrowserTreeAccessor"></div>
 *
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').directive('datastoreBrowserTree', datastoreBrowserTree);

   datastoreBrowserTree.$inject = ['datastoreBrowserTreeService', 'datastoreBrowserConstants', '$q', '$timeout', 'logService'];

   function datastoreBrowserTree(datastoreBrowserTreeService, datastoreBrowserConstants, $q, $timeout, logService) {

      var directive = {
         scope: {
            objectRef: '=', // The object which the datastore browser will be invoked for.
            datastores: '=?',  //Array of ServerObjectRef pointing to datastore to show
                                 // at root level of browsing tree
            fileQueryType: '=?', // The query type of the files that need to be shown.
                                 // It can be a combination of all possible bit flags
                                 // defined in datastoreBrowserConstants.fileQueryType
            selectAndExpandFirstRootItem: '=?', // Optional flag, if set to true, the first
                                                // root item will be automatically selected
                                                // and expanded.
            showVmImagesFolder: '=?',
            initializationCompleted: '&onInitializationCompleted',
            selectedItemChanged: '&onSelectedItemChanged',
            selectedItemFilesLoaded: '&onSelectedItemFilesLoaded',
            accessor: '=' // accessor pattern to make it easy to call the directive
                          // functions from hosting components. Used to call the
                          // directive's 'reloadContentFiles' function when file type
                          // for filtering files is changed
         },
         link: link
      };

      var _logger = logService('datastoreBrowserTree');

      return directive;

      function link(scope, element, attrs) {
         var dsBrowserKendoTree, // kendo tree object
            dataSource,
            dataSourceOptions, // data source and options for the kendo tree
            dataSourceItemIdPropName = 'path';

         var _lastContentFilesPromise;
         var _contentFilesRequestCanceller;
         var _pendingLoadTreeRequestsCancellers = [];

         var bindOnInitializationCompleted = !!attrs.onInitializationCompleted;
         var bindOnSelectedItemChanged = !!attrs.onSelectedItemChanged;
         var bindOnSelectedItemFilesLoaded = !!attrs.onSelectedItemFilesLoaded;
         var selectAndExpandFirstRootItem = attrs.selectAndExpandFirstRootItem !== undefined;
         var initialLoadingCompleted = false;

         scope.$on('$destroy', function() {
            cancelContentFilesRequest();

            _.forEach(_pendingLoadTreeRequestsCancellers, function(canceller) {
               canceller.resolve();
            });
            _pendingLoadTreeRequestsCancellers = [];
         });

         // build kendo tree data source
         dataSource = getKendoDataSource();

         dataSourceOptions = {
            dataSource: dataSource,
            dataTextField: ['name']
         };

         dataSourceOptions.select = function (event) {
            var selectedDataItem = dsBrowserKendoTree.dataItem(event.node);
            notifySelectionChangeListener(selectedDataItem);
            // No need to retrieve the content for the currently selected
            // item in case the onSelectedItemFilesLoaded callback is missing.
            if (bindOnSelectedItemFilesLoaded) {
               reloadContentFilesInternal(selectedDataItem.path);
            }
         };

         // create kendo tree view
         dsBrowserKendoTree = element.kendoTreeView(dataSourceOptions).data('kendoTreeView');
         element.attr('vui-tree-view', '');

         function loadTreeItems(options) {
            var expandingItemId, expandingItemData, loadingRoot, promise;

            expandingItemId = options.data && options.data[dataSourceItemIdPropName];
            loadingRoot = !expandingItemId;
            if (loadingRoot) {
               promise = datastoreBrowserTreeService.getRoot(
                     scope.objectRef,
                     scope.datastores,
                     scope.showVmImagesFolder);
            } else {
               expandingItemData = dsBrowserKendoTree.dataSource.get(expandingItemId);
               var getChildrenCanceller = $q.defer();
               _pendingLoadTreeRequestsCancellers.push(getChildrenCanceller);
               promise = datastoreBrowserTreeService.getChildren(
                     scope.objectRef,
                     expandingItemData.path,
                     datastoreBrowserConstants.fileQueryType.FOLDERS,
                     getChildrenCanceller.promise).finally(function() {
                        var index = _pendingLoadTreeRequestsCancellers.indexOf(getChildrenCanceller);
                        if (index >= 0) {
                           _pendingLoadTreeRequestsCancellers.splice(index, 1);
                        }
                     });
            }

            promise.then(function (data) {
               _.forEach(data, function(item) {
                  if (item.friendlyName) {
                     item.name = item.friendlyName;
                  }
               });
               options.success(data);
               if (loadingRoot) {
                  notifyInitializationCompleteListener(data);

                  // Select and expand root item only during the initial loading.
                  if (!initialLoadingCompleted) {
                     selectExpandFirstRootItem();
                     initialLoadingCompleted = true;
                  }
               }
            });
         }

         function reloadContentFilesInternal(selectedTreeItemFilePath) {

            cancelContentFilesRequest();
            _contentFilesRequestCanceller = $q.defer();

            var contentFilesPromise = datastoreBrowserTreeService.getChildren(
                  scope.objectRef,
                  selectedTreeItemFilePath,
                  scope.fileQueryType,
                  _contentFilesRequestCanceller.promise);

            // Save the current promise
            _lastContentFilesPromise = contentFilesPromise;

            contentFilesPromise.then(function(data) {
               // Ignore all responses which are not related to the
               // last saved promise
               if (contentFilesPromise === _lastContentFilesPromise) {
                  _contentFilesRequestCanceller = null;
                  notifySelectedItemFilesLoadedListener(data);
               }
            });
         }

         function cancelContentFilesRequest() {
            if (_contentFilesRequestCanceller) {
               _contentFilesRequestCanceller.resolve();
            }
         }

         // accessor pattern used to make it easy to call the directive function
         // for reloading the content files when file type is changed easier
         if (scope.accessor) {

            scope.accessor.reloadData = function() {
               initialLoadingCompleted = false;
               dsBrowserKendoTree.setDataSource(getKendoDataSource());
            };

            scope.accessor.reloadContentFiles = function (newFileQueryType) {
               var selectedNode, selectedDataItem;

               if (newFileQueryType) {
                  scope.fileQueryType = newFileQueryType;
               }
               selectedNode = dsBrowserKendoTree.select();
               selectedDataItem = dsBrowserKendoTree.dataItem(selectedNode);
               if (!selectedDataItem) {
                  return;
               }
               reloadContentFilesInternal(selectedDataItem.path);
            };

            scope.accessor.refreshTreeNode = function (folderPath) {
               var dataItem = dsBrowserKendoTree.dataSource.get(folderPath);
               if (dataItem) {
                  try {
                     // mark the node as not loaded
                     dataItem.loaded(false);

                     if (dataItem.expanded) {
                        // Re-load the node only if expanded.
                        // Non-expanded nodes will be loaded when the user
                        // expands them.
                        dataItem.load();
                     }

                     // get the currently selected node
                     var selectedNode = dsBrowserKendoTree.select();
                     var selectedDataItem = dsBrowserKendoTree.dataItem(selectedNode);

                     if (selectedNode && !selectedDataItem) {
                        // currently selected node is no longer available
                        // trigger full refresh
                        scope.accessor.refreshTree();
                        return;
                     }

                     if (selectedDataItem[dataSourceItemIdPropName] === folderPath) {
                        // if we are refreshing the currently selected node we should also
                        // reload its content files.
                        scope.accessor.reloadContentFiles();
                     }

                  } catch (error) {
                     _logger.error("Failed reloading element: " + error.message);
                  }
               }
            };

            // Refreshes the tree, expands and pre-selects the currently selected node
            scope.accessor.refreshTree = function () {
               if (scope.isRefreshing) {
                  // Ignore the refresh action, if we are currently refreshing the tree
                  return;
               }

               // Guard against never ending refresh
               // It might happen that the kendo expansion stuck in the middle and this
               // will result in never ending refresh. That's why we need this guard
               var timeoutPromise = $timeout(function () {
                  scope.isRefreshing = false;
               }, 10000);

               scope.isRefreshing = true;
               var selectedNode = dsBrowserKendoTree.select();
               var selectedDataItem = dsBrowserKendoTree.dataItem(selectedNode);
               if (!selectedDataItem) {
                  // If there is no selection, just refresh the tree
                  refreshTreeInternal().then(function () {
                     scope.isRefreshing = false;
                     $timeout.cancel(timeoutPromise);
                  });
                  return;
               }

               notifySelectionChangeListener();

               var parentNode = dsBrowserKendoTree.parent(selectedNode);
               var parentDataItem = dsBrowserKendoTree.dataItem(parentNode);

               var expandedPath = selectedDataItem.expanded ? [selectedDataItem[dataSourceItemIdPropName]] : [];
               while (parentDataItem) {
                  expandedPath.splice(0, 0, parentDataItem[dataSourceItemIdPropName]);

                  parentNode = dsBrowserKendoTree.parent(parentNode);
                  parentDataItem = dsBrowserKendoTree.dataItem(parentNode);
               }

               var refreshTreePromise = refreshTreeInternal();
               refreshTreePromise.then(function () {
                  expandPath(expandedPath).then(function () {
                     if(!selectItem(selectedDataItem[dataSourceItemIdPropName])) {
                        // If the old selection cannot be restored
                        // Select and expand the root if configured
                        selectExpandFirstRootItem();
                     }
                     scope.isRefreshing = false;
                     $timeout.cancel(timeoutPromise);
                  });
               });
            };

            /**
             * Selects a child item of the currently selected item.
             */
            scope.accessor.selectChildItem = function(childItemId) {
               var selectedNode = dsBrowserKendoTree.select(); // parent of item
               var selectedDataItem = dsBrowserKendoTree.dataItem(selectedNode);
               if (!selectedDataItem) {
                  return;
               }

               var parentNode = dsBrowserKendoTree.parent(selectedNode);
               var parentDataItem = dsBrowserKendoTree.dataItem(parentNode);

               var expandedPath = [selectedDataItem[dataSourceItemIdPropName]];
               while (parentDataItem) {
                  expandedPath.splice(0, 0, parentDataItem[dataSourceItemIdPropName]);

                  parentNode = dsBrowserKendoTree.parent(parentNode);
                  parentDataItem = dsBrowserKendoTree.dataItem(parentNode);
               }

               expandPath(expandedPath).then(function() {
                  selectItem(childItemId);
               });

            };
         }

         function expandPath(expandedPath) {
            var deferred = $q.defer();
            dsBrowserKendoTree.expandPath(expandedPath, function() {
               deferred.resolve();
            });

            return deferred.promise;
         }

         function selectItem(itemId) {
            // Selecting the previously selected node
            var dataItem = dsBrowserKendoTree.dataSource.get(itemId);
            var node;
            if (dataItem) {
               try {
                  node = dsBrowserKendoTree.findByUid(dataItem.uid);
                  dsBrowserKendoTree.select(node);

                  // trigger the select event because programatically selecting node does not trigger it
                  dsBrowserKendoTree.trigger('select', {node: node});
               } catch (error) {
                  _logger.error("Failed selecting element: " + error.message);
               }
            }

            return !!node;
         }

         function selectExpandFirstRootItem() {
            var data = dsBrowserKendoTree.dataSource.data();
            if (selectAndExpandFirstRootItem && data && data.length > 0) {
               var firstRootId = data[0][dataSourceItemIdPropName];
               selectItem(firstRootId);
               expandPath([firstRootId]);
            }
         }

         function notifyInitializationCompleteListener(rootItems) {
            if (bindOnInitializationCompleted) {
               scope.initializationCompleted({rootItems: rootItems});
            }
         }

         function notifySelectionChangeListener(selectedDataItem) {
            if (bindOnSelectedItemChanged) {
               scope.selectedItemChanged({selectedTreeItem: selectedDataItem});
            }
         }

         function notifySelectedItemFilesLoadedListener(data) {
            if (bindOnSelectedItemFilesLoaded) {
               scope.selectedItemFilesLoaded({selectedTreeItemContentFiles: data});
            }
         }

         function refreshTreeInternal() {
            return dsBrowserKendoTree.dataSource.read();
         }

         function getKendoDataSource(){
            return new kendo.data.HierarchicalDataSource({
               schema: {
                  model: {
                     // unique id needed to retrieve the children of a node
                     id: dataSourceItemIdPropName
                  }
               },
               transport: {
                  read: loadTreeItems
               }
            });
         }
      }
   }
})();
