/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {

   export class VxModalKeyService {
      static $inject = ['vxZoneService', 'clarityConstants'];

      constructor(private vxZoneService: any,
            private clarityConstants: any) {

      }

      public onKeydownHandler(event: any, onEsc?: Function, onEnter?: Function) {
         const code = event.which || event.keyCode;
         if (code !== this.clarityConstants.keys.ESC &&
               code !== this.clarityConstants.keys.ENTER) {
            return;
         }
         // Avoid propagation of any keydown event for ESC and ENTER since some other dialog might be
         // listening for it and trigger undesired functionality as closing the other
         // dialog when the current one on is on focus.
         event.stopImmediatePropagation();

         // Run handling inside angular otherwise
         // $digest already in progress error appears
         this.vxZoneService.runInsideAngular(() => {
            switch (code) {
               case this.clarityConstants.keys.ESC:
                  if (!!onEsc) {
                     onEsc();
                  }
                  break;

               case this.clarityConstants.keys.ENTER:
                  if (!!onEnter) {
                     onEnter();
                  }
                  break;
            }
         });
      };
   }

   angular.module("com.vmware.platform.ui")
         .service("vxModalKeyService", VxModalKeyService);
}
