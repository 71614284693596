/* Copyright 2014 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * @ngdoc directive
 * @name com.vmware.platform.ui:vxPasswordMatch
 *
 * @description
 * Directive to tell if Confirm password matches password field.
 *
 * ******
 * Usage:
 * ******
 * <pre><code>
 *
 *    <input type="password" id="password" name="password"
 *          ng-model="passwordModel">
 *    </input>
 *
 *    <input type="password" id="confirmPassword" name="confirmPassword"
 *                ng-model="confirmPassword"
 *                vx-password-match="password">
 *    </input>
 *
 *
 *    <span ng-show="showPasswordMatchError(confirmPassword)">
 *       <p>Password mismatch.</p>
 *     </span>
 *
 *    <script>
 *          $scope.showPasswordMatchError = function(elem) {
 *             return elem.$error.passwordMatch;
 *          };
 *    </script>
 *
 * </code></pre>
 *
 * @element input
 * @scope parent
 * @priority default
 *
 */
angular.module('com.vmware.platform.ui').directive('vxPasswordMatch', function () {
   'use strict';
   return {
      require: 'ngModel',
      link: function (scope, elem, attrs, ctrl) {
         var firstPassword = '#' + attrs.vxPasswordMatch;
         elem.bind('focusout', function () {
            var validity = elem.val() === angular.element(firstPassword).val();
            ctrl.$setValidity('passwordMatch', validity);
         });
         elem.add(firstPassword).on('focusout', function () {
            var validity = elem.val() === angular.element(firstPassword).val();
            ctrl.$setValidity('passwordMatch', validity);
         });
      }
   };
});