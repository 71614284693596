angular.module('com.vmware.platform.ui').directive('allowedCharacters', [function () {
   return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, element, attrs, ctrl) {
         ctrl.$parsers.push(function (value) {
            var newValue = value.toString().replace(
               new RegExp('[^' + attrs.allowedCharacters + ']', 'g'), '');

            ctrl.$setViewValue(newValue);
            ctrl.$render();

            return newValue;
         });
      }
   };
}]);
