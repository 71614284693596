/* Copyright 2017 Vmware, Inc. All rights reserved. -- Vmware Confidential */
namespace common_ui {
   export class StorageSelectorHeaderComponent {
      public templateUrl: string;
      public controller: any;
      public bindings: any;

      constructor() {
         this.templateUrl = "resources/ui/components/storageLocator/storageSelectorHeaderComponent.html";
         this.controller = StorageSelectorHeaderComponentController;
         this.bindings = {
            storageProfiles: "<",
            datastoreItems: "<",
            sourceVmStorageConfig: "<?",
            storageBaselineId: "<?",
            showStorageBaselineSignpostHints: "<?",
            unsupportedStorageBaselineIds: "<?",
            storageSelectorState: "<?",
            showSelectionModeSelector: "<?",
            selectionMode: "<?",
            componentState: "<",
            onStorageBaselineIdChange: "&",
            onSelectionModeChange: "&",
            isPmemOptionDisabled: "<?",
            isDiskGroupsAvailable: "<?"
         };
      }
   } // class StorageLocator

   interface BaselineHint {
      message: string;
      bold: boolean;
   }

   class StorageSelectorHeaderComponentController {
      public static $inject = [
         "i18nService",
         "storageProfileService",
         "storageSelectorConstants",
         "featureFlagsService"
      ];

      // Input parameters
      public storageProfiles: StorageProfile[];
      public datastoreItems: StorageLocatorDatastoreItem[];
      public sourceVmStorageConfig: VmStorageConfig[];
      public storageBaselineId?: string;
      public showStorageBaselineSignpostHints: boolean;
      public unsupportedStorageBaselineIds: string[];
      public storageSelectorState: StorageSelectorState;
      public showSelectionModeSelector: boolean;
      public selectionMode: string;
      public componentState: StorageSelectorHeaderComponentState;
      public isPmemOptionDisabled: boolean;

      // Output
      public onStorageBaselineIdChange: (args: {newStorageBaselineId: string}) => void;
      public onSelectionModeChange: (args: {newSelectionMode: string}) => void;

      // Properties used by the view
      public i18n: (module: string, key: string) => string;
      public availableStorageBaselines?: StorageBaseline[];
      public selectedStorageBaselineId: string;
      public signpostParams: any;

      private baselineHints: {[baselineId: string]: BaselineHint[]|undefined} = {};

      constructor(
         private i18nService: any,
         private storageProfileService: any,
         private storageSelectorConstants: StorageSelectorConstants,
         private featureFlagsService: any
      ) {
         this.i18n = i18nService.getString;
         this.signpostParams = {
            title: i18nService.getString("Common", "storageSelector.pmemStorage"),
            message: i18nService.getString(
                  "Common", "storageSelector.pmemBaseline.signpost")
         };
      }

      public $onInit() {
         this.initSelectionModeSelector();
         this.initStorageBaselineSelector();
      }

      private initSelectionModeSelector(): void {
         this.selectionMode = this.selectionMode ||
               this.storageSelectorConstants.BASIC_MODE;

         this.onSelectionModeChange({newSelectionMode: this.selectionMode});
      }

      public toggleSelectionMode() {
         switch (this.selectionMode) {
            case this.storageSelectorConstants.BASIC_MODE:
               this.selectionMode = this.storageSelectorConstants.ADVANCED_MODE;
               break;
            case this.storageSelectorConstants.ADVANCED_MODE:
               this.selectionMode = this.storageSelectorConstants.BASIC_MODE;
               break;
         }

         this.onSelectionModeChange({newSelectionMode: this.selectionMode});
      }

      private initStorageBaselineSelector(): void {
         // Scan for any Pmem profiles (actually just one) available
         let pmemProfile: boolean = _.any(this.storageProfiles,
            (profile: StorageProfile): boolean => {
               return this.storageProfileService.isPmemStorageProfile(profile);
            }
         );

         if (!pmemProfile) {
            this.availableStorageBaselines = undefined;
            this.setSelectedStorageBaselineId(
                  this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id);
            return;
         }

         // Scan for any PMem datastores available
         let pmemDatastoreAvailable: boolean = _.any(
            this.datastoreItems,
            (item: StorageLocatorDatastoreItem) => {
               return item.type === "PMEM";
            }
         );

         if (!pmemDatastoreAvailable || this.isPmemOptionDisabled) {
            this.availableStorageBaselines = undefined;
            this.setSelectedStorageBaselineId(
                  this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id);
            return;
         }

         // Only VM disks can be placed on PMEM, if no disks then no need for PMEM.
         let hasDiskItems: boolean = _.any(this.sourceVmStorageConfig,
            (vmStorage: VmStorageConfig) => {
               return !_.isEmpty(vmStorage.vmDisks);
         });

         if (!hasDiskItems ) {
            this.availableStorageBaselines = undefined;
            this.setSelectedStorageBaselineId(
                  this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id);
            return;
         }

         // Both PMem profile and PMem datastore are available

         // Should show at least two baselines: Standard and PMem
         this.availableStorageBaselines = [
            this.storageSelectorConstants.STANDARD_STORAGE_BASELINE
         ];

         let pmemBaselineSupported: boolean = _.indexOf(
               this.unsupportedStorageBaselineIds,
               this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id) < 0;
         if (pmemBaselineSupported) {
            this.availableStorageBaselines.push(
                  this.storageSelectorConstants.PMEM_STORAGE_BASELINE);
         }

         let pmemDiskCount: number = 0;
         let totalDiskCount: number = 0;
         _.each(this.sourceVmStorageConfig, (vmStorage: VmStorageConfig) => {
            _.each(vmStorage.vmDisks, (compConfig: VmComponentStorageConfig) => {
               if (this.storageProfileService.isPmemStorageProfile(
                     compConfig.storageProfile)) {
                  pmemDiskCount++;
               };
               totalDiskCount++;
            });
         });

         let suggestedBaseline: string =
               this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id;

         if (pmemDiskCount > 0) {
            if (pmemDiskCount === totalDiskCount) {
               // All PMEM items
               suggestedBaseline = this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id;
            } else {
               // Some PMEM items -> Hybrid config
               this.availableStorageBaselines.push(
                     this.storageSelectorConstants.HYBRID_STORAGE_BASELINE);
               suggestedBaseline = this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id;
            }
         }

         // If we have just one available storage baseline, we nullify the value
         // to trigger proper UI configuration - if one option is available - no need
         // of the storage baseline id selector element.
         if (_.size(this.availableStorageBaselines) <= 1) {
            this.availableStorageBaselines = undefined;
         }

         this.setSelectedStorageBaselineId(
               this.pickInitialStorageBaselineId(suggestedBaseline));

         this.initBaselineHints();
      }

      private pickInitialStorageBaselineId(suggestedBaseline: string) {
         if (!this.availableStorageBaselines) {
            return this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id;
         }

         let baselineToSelect: string = this.storageBaselineId || suggestedBaseline;
         if (baselineToSelect) {
            let storageBaseline = _.findWhere(
                  this.availableStorageBaselines, {id: baselineToSelect});
            if (storageBaseline) {
               return baselineToSelect;
            }

            // The baseline is no longer applicable - fall through to pick some
            // default selection.
         }

         // Prefer hybrid if available
         if (this.availableStorageBaselines.indexOf(
               this.storageSelectorConstants.HYBRID_STORAGE_BASELINE) >= 0) {
            return this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id;
         }

         // Otherwise prefer standard
         return this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id;
      }

      private setSelectedStorageBaselineId(storageBaselineId: string) {
         if (this.selectedStorageBaselineId === storageBaselineId) {
            return;
         }

         this.selectedStorageBaselineId = storageBaselineId;
         this.onSelectedStorageBaselineIdChange();
      }

      private initBaselineHints() {
         if (!this.availableStorageBaselines) {
            return;
         }

         let baseline = this.storageSelectorConstants.PMEM_STORAGE_BASELINE;
         if (!this.componentState.hiddenBaselineHints[baseline.id] &&
               (this.availableStorageBaselines.indexOf(baseline) >= 0)) {
            let hasSingleDisk: boolean = this.sourceVmStorageConfig.length === 1
                  && !this.sourceVmStorageConfig[0].vmHome
                  && this.sourceVmStorageConfig[0].vmDisks
                  && this.sourceVmStorageConfig[0].vmDisks.length === 1;
            if (hasSingleDisk) {
               this.baselineHints[baseline.id] = [{
                  message: this.i18n("Common", "storageSelector.pmemBaseline.singleDisk.hint.line1"),
                  bold: true
               }, {
                  message: this.i18n("Common", "storageSelector.pmemBaseline.singleDisk.hint.line2"),
                  bold: false
               }];
            } else {
               this.baselineHints[baseline.id] = [{
                  message: this.i18n("Common", "storageSelector.pmemBaseline.hint.line1"),
                  bold: true
               }, {
                  message: this.i18n("Common", "storageSelector.pmemBaseline.hint.line2"),
                  bold: false
               }];
            }
         }

         baseline = this.storageSelectorConstants.HYBRID_STORAGE_BASELINE;
         if (!this.componentState.hiddenBaselineHints[baseline.id] &&
               (this.availableStorageBaselines.indexOf(baseline) >= 0)) {
            this.baselineHints[baseline.id] = [{
               message: this.i18n("Common", "storageSelector.hybridBaseline.hint"),
               bold: true
            }];
         }
      }

      public getHintFor(storageBaselineId: string): BaselineHint[]|undefined {
         // None of the alerts is visible in advanced mode.
         if (this.selectionMode === this.storageSelectorConstants.ADVANCED_MODE) {
            return undefined;
         }

         return this.baselineHints[storageBaselineId];
      }

      public onSelectedStorageBaselineIdChange() {
         this.onStorageBaselineIdChange({
               newStorageBaselineId: this.selectedStorageBaselineId});
      }

      public onBaselineHintClose() {
         this.baselineHints[this.selectedStorageBaselineId] = undefined;
         this.componentState.hiddenBaselineHints[
               this.selectedStorageBaselineId] = true;
      }
   } // class StorageBaselineComponentController

   angular.module("com.vmware.platform.ui").component(
         "storageSelectorHeaderComponent", new StorageSelectorHeaderComponent());

} // namespace
