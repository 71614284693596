/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Defines extension framework related constants
 */
angular.module('com.vmware.platform.ui').constant('extensionFrameworkConstants', {
   ViewRetentionPolicy: {
      NONE: "NONE",
      INHERIT: "INHERIT",
      SELF_ONLY: "SELF_ONLY",
      DESCENDANTS_ONLY: "DESCENDANTS_ONLY",
      SELF_AND_DESCENDANTS: "SELF_AND_DESCENDANTS"
   }
});

