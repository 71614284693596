/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Directive to display multiple tabs and their content
 *
 * Usage:
 *
 * <vx-tabbed-view tabs="arrayOfTabs" selectedTab="tabToSelect" params="{myCustomParams}"/>
 *
 * "tabs" - specifies array of tab objects with name and templateUrl i.e. {name: "tabName", templateUrl: "myTab.html"}
 * "selectedTab" (optional) - specifies the preselected tab (the first tab is selected by default)
 * "params"(optional) - specifies any additional parameters that might be needed to render the tab content.
 *
 * the tabbed view caches components once those are rendered.
 * if needed - the cache can be cleared using control.clearCache() {@see vxTabDetailsViewController}
 */
angular.module('com.vmware.platform.ui').directive('vxTabbedView', ['$compile', '$timeout', 'telemetryHelperService',
   function($compile, $timeout, telemetryHelperService) {

      return {
         scope: {
            tabs: '=',
            selectedTab: '=?',
            params: '=?',
            control: '=?'
         },
         templateUrl: 'resources/ui/views/tabs/vxTabbedViewTemplate.html',
         link: function(directiveScope, element) {

            directiveScope.selectedTab = directiveScope.selectedTab || directiveScope.tabs[0];
            var renderedTabCache = {};
            angular.extend(directiveScope, {
                 _generateTid: function (id) {
                     return telemetryHelperService.generateTelemetryIdClass(id + " tabbed-view");
                 }
             });

            //exposing an api function
            if (directiveScope.control) {
               directiveScope.control.clearCache = function(selectedTab) {
                  // clear all rendered tabs besides the current tab
                  _.forEach(_.keys(renderedTabCache), function(name) {
                     if (!selectedTab || selectedTab.name !== name) {
                        destroyTab(name);
                     }
                  });
               };

               directiveScope.control.selectTab = function(tab) {
                  directiveScope.selectedTab = tab;
               };
            }

            directiveScope.$watch(function() {
               return directiveScope.selectedTab;
            }, function(newTab, oldTab) {
               if (oldTab && newTab !== oldTab && isRendered(oldTab)) {
                  // detach the previously rendered tab
                  renderedTabCache[oldTab.name].tabElement.detach();
               }

               if (newTab) {
                  var tabElement;
                  if (isRendered(newTab)) {
                     tabElement = renderedTabCache[newTab.name].tabElement;
                  } else {
                     tabElement = renderTab(newTab);
                  }
                  element.find('#vxTabContent').append(tabElement); // not a wise idea, not reusable at all
               }
            });

            directiveScope.selectTab = function(tab) {
               directiveScope.selectedTab = tab;

               $timeout(function() {
                  directiveScope.$broadcast('resize');
               }, 0, false);
            };

            function isRendered (tab) {
               return renderedTabCache.hasOwnProperty(tab.name);
            }

            function renderTab (tab) {
               var tabElementScope = directiveScope.$new(true);
               if (directiveScope.params) {
                  _.extend(tabElementScope, directiveScope.params);
               }
               if (tab.tabParams) {
                  _.extend(tabElementScope, tab.tabParams);
               }

               var tabElement = $compile(
                  '<div class="fill-parent">' +
                  '<div class="fill-parent" ng-include="\'' + tab.templateUrl + '\'" ></div>' +
                  '</div>')(tabElementScope);

               renderedTabCache[tab.name] = {
                  tabElement: tabElement,
                  tabScope: tabElementScope
               };

               return tabElement;
            }

            function destroyTab (key) {
               var renderedTemplate = renderedTabCache[key];
               if (renderedTemplate) {
                  renderedTemplate.tabScope.$destroy();
                  renderedTemplate.tabElement.remove();
                  delete renderedTabCache[key];
               }
            }

            directiveScope.$on('$destroy', function() {
               _.forEach(_.keys(renderedTabCache), destroyTab);
            });
         }
      };
   }]);
