/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Tabbed view controller for details view which displays multiple tabs.
 *
 * How to use:
 *
 * 1. Defines extension point for tabbed details view
 * <extensionPoint id="vsphere.core.samples.tabbedDetails.tabs">
 *    <objectType class="com.vmware.vise.mvc.model.ViewSpec"/>
 * </extensionPoint>
 *
 * 2. Define extension with hosted point from above (to be used by the tabs)
 *    <extension id="vsphere.core.samples.myTabbedDetailsView">
 *       <extendedPoint>vsphere.core.samples.masterDetails.detailsView</extendedPoint>
 *       <hostedPoint>vsphere.core.samples.tabbedDetails.tabs</hostedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>resources/ui/components/masterdetails/vxTabbedDetailsView.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 *
 * 3. Define extension for each tab you want to plug into the tabbed details view:
 *
 * <extension id="vsphere.core.samples.myTab1">
 *    <extendedPoint>vsphere.core.samples.tabbedDetails.tabs</extendedPoint>
 *    <object>
 *       <name>Tab name</name>
 *       <contentSpec>
 *          <url>path-to-template/MyTab1.html</url>
 *       </contentSpec>
 *    </object>
 * </extension>
 *
 * We support filtering on the tabs with only support boolean flags on the selected items using
 * entry key-value pair in contentSpec metadata.
 * <extension id="vsphere.core.samples.myTab2">
 *    <extendedPoint>vsphere.core.samples.tabbedDetails.tabs</extendedPoint>
 *    <object>
 *       <name>Tab name</name>
 *       <contentSpec>
 *          <url>path-to-template/MyTab2.html</url>
 *          <metadata>
 *             <entry>
 *                <key>relevantFor<key>
 *                <value>isConnected, isPoweredOn</value>
 *             </entry>
 *          </metadata>
 *       </contentSpec>
 *    </object>
 * </extension>
 *
 * Master view is responsible to set the "isConnected" and "isPoweredOn" in its selected items.
 *
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').controller('vxTabbedDetailsViewController', VxTabbedDetailsViewController);

   VxTabbedDetailsViewController.$inject = ['extensionService', '$scope', 'logService', 'localStorageService', '$q', 'navigation'];

   function VxTabbedDetailsViewController (extensionService, $scope, logService, localStorageService, $q, navigation) {
      var log = logService('VxTabbedDetailsViewController');

      var self = this;
      var allTabs = [];

      self.tabViewApi = {};

      $scope.$watch(function() {
         return $scope.masterDetailsViewContext.selectedItem;
      }, function(newValue, oldValue) {
         if (newValue !== oldValue) {
            showRelevantTabsForItem(newValue);
         }
      });

      $scope.$watch(
         function () {
            return self.selectedTab;
         },
         function (newValue, oldValue) {
            if (newValue !== oldValue) {
               persistTabSelection();
            }
         });

      function getTabSelectionUserDataKey() {
         return navigation.getRoute().extensionId + "_selectedTab";
      }

      function persistTabSelection() {
         if (self.selectedTab && self.selectedTab.uid) {
            localStorageService.setUserData(getTabSelectionUserDataKey(), self.selectedTab.uid);
         }
      }

      function retrieveTabSelection() {
         return localStorageService.getUserData(getTabSelectionUserDataKey());
      }

      extractTabs().then(function() {
         if ($scope.masterDetailsViewContext.selectedItem) {
            showRelevantTabsForItem($scope.masterDetailsViewContext.selectedItem);
         }
      });

      function showRelevantTabsForItem(item) {
         var tabs = [];

         _.forEach(allTabs, function(currentTab) {
            if (isTabRelevant(item, currentTab)) {
               tabs.push(currentTab);
            }
         });

         self.tabs = tabs;

         // If the preserved selected tab is no more relevant for the newly selected item,
         // select the first tab. Need to set the selected tab before trying to clear cached tabs.
         if (self.selectedTab && !isTabRelevant(item, self.selectedTab)) {
            self.selectedTab = self.tabs[0];
         }

         if (self.tabViewApi && self.tabViewApi.clearCache) {
            self.tabViewApi.clearCache(self.selectedTab);
         }
      }

      function extractTabs() {
         var tabsPromise = $scope.detailsViewData.tabsPromise;
         if (!tabsPromise) {
            //Extracting tab config from extension framework
            tabsPromise = extensionService.getHostedExtensions($scope.masterDetailsViewContext.detailsView.uid).then(function (extensionResponse) {
               return _.map(extensionResponse, function (tabExtension) {
                  return {
                     name: tabExtension.name,
                     templateUrl: tabExtension.contentSpec.url,
                     metadata: tabExtension.contentSpec.metadata,
                     uid: tabExtension.uid,
                     tabParams: { tabExtension: tabExtension }
                  };
               });
            });
         }
         return $q.all([tabsPromise, retrieveTabSelection()]).then(function (responses) {
            var tabs = responses[0];
            var selectedTabUidResponse = responses[1];
            allTabs = [];
            _.forEach(tabs, function (tab) {
               allTabs.push(tab);
               if (selectedTabUidResponse && tab.uid === selectedTabUidResponse) {
                  self.selectedTab = tab;
               }
            });
         });
      }

      function isTabRelevant(selectedItem, tab) {
         if (!selectedItem) {
            return false;
         }

         if (!tab.metadata || !tab.metadata.relevantFor) {
            return true;
         }

         // The tab is visible only if the selected item defines the boolean
         // flags in the entry-value and they all evaluates to true i.e.
         // support only AND operation.
         var filterFlags = tab.metadata.relevantFor.split(',');

         var matchingFields = true;
         _.forEach(filterFlags, function(flag) {
            var trimmedFlag = flag.trim();
            var expectedFlagValue = true;
            if (trimmedFlag.charAt(0) === "!") {
               // The flag starts with ! and this is not field name. Treat ! as not
               trimmedFlag = trimmedFlag.substring(1);
               expectedFlagValue = false;
            }

            if (!selectedItem.hasOwnProperty(trimmedFlag)) {
               log.debug('Tabbed details view: Missing flags ' + trimmedFlag + ' for the selected item.');
               matchingFields = false;
            }

            if (selectedItem[trimmedFlag] !== expectedFlagValue) {
               matchingFields = false;
            }
         });
         return matchingFields;
      }
   }
})();
