namespace common_ui {
   class ActionBarController {
      public static $inject: string[] = ["vuiConstants", "$log"];

      public options: any;
      public clrTemplate: boolean;

      constructor(private vuiConstants: any, private $log: any) {

      }

      public isSeparator(action: any): boolean {
         return action === this.vuiConstants.actions.SEPARATOR ? true : false;
      }

      public isVisible(action: any): boolean {

         if (!action) {
            return true;
         }

         if (action.visible === undefined) {
            return true;
         }

         return action.visible;

      }

      public hasLabel(action: any): boolean {
         return action.label && action.label.trim().length > 0;
      }

      public isDisabled = function (action) {
         if (!action) {
            return false;
         }

         if (action.enabled === undefined) {
            return false;
         }

         if (!action.enabled) {
            return true;
         }

         return false;
      };

      public clickAction(event: any, action: any): void {
         // handle event propogation when action is disabled
         if (!action.enabled) {
            event.stopPropagation();
            event.preventDefault();
         } else if (angular.isDefined(action.onClick) &&
               angular.isFunction(action.onClick)) {
            action.onClick.call(undefined, event, action);
         } else {
            this.$log.warn("No onClick action defined for action id: " +
                  action.id + " label: " + action.label);
         }
      }

      public hasClarityIcon(action: any): boolean {
         if (!action || !action.iconClass) {
            return false;
         }

         if (action.shape) {
            return true;
         }

         let index: number = action.iconClass.indexOf("clr:");
         if (index === 0) {
            action.shape = action.iconClass.slice(4);
            return true;
         }

         return false;
      }
   }

   export class ActionBarComponent {
      public controller: any;
      public bindings: any;
      public templateUrl =
            "resources/ui/components/action-bar/action-bar.component.html";

      constructor() {
         this.controller = ActionBarController;
         this.bindings = {
            options: "<",
            clrTemplate: "<?"
         };
      }
   }

   angular.module("com.vmware.platform.ui")
         .component("actionBar", new ActionBarComponent());
}
