/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/* TODO mtonev: Remove devel once we have better error reporting */
/*jshint bitwise: false, devel:true */
/*
 * Service that exposes the platform api to plugins using a different h5 technology
 * like extJs etc.  Their views are sandboxed into an iFrame and they can access apis through
 * the WEB_PLATFORM global object.
 *
 * WEB_PLATFORM also allows to support 6.0 plugins built using the SDK's Html Bridge.
 * See doc at http://vmweb.vmware.com/~ldelamar/NGC-SDK-6.0.0/html-bridge/docs/html-bridge.html
 *
 * Plugins are distributed with a web-platform.js which extends the WEB_PLATFORM object created here
 * with more functions: getObjectId, getActionUid, getActionTargets and getVcSelectorInfo.
 * They do not have to be implemented here as they would be overwritten later.
 */

//global vSphereClientSDK exposed in to the browser window
window.vSphereClientSDK = {};

var WEB_PLATFORM = (function() {
   'use strict';
   // Angular's rootScope.
   var rootScope = null;
   var htmlBridgeService = null;
   var platformNgZone = null;

   var refreshMap = {};

   var platformService = {
      /**
       * Passes in any angular service that is required by htmlBridgeService.
       * @internal
       */
      onAngularLoad: function(rs, ds, hbs, $window) {
         rootScope = rs;
         this.dataService = ds;
         htmlBridgeService = hbs;
         //save the current ng zone to be used later
         platformNgZone = window.Zone.current;
         if (platformNgZone.name !== "angular") {
            console.warn("The web platform APIs run outside the angular ng zone.");
         }

         //bootstrap the ability to provide the API to the plugins
         bootstrapWebPlatformApi($window);

         rootScope.$on('dataRefreshInvocationEvent', handlePluginsGlobalRefresh);
         rootScope.domXmlSerializer = new XMLSerializer();
      },

      // -------------------------- Public APIs --------------------------------

      /**
       * DataService instance to fetch data.
       */
      dataService: null,

      /**
       * Get the localized string for the given key.
       */
      getString: function(bundleName, key, params) {
         var args = [bundleName, key];
         if (angular.isDefined(params)) {
            args = args.concat(params);
         }
         return rootScope.i18n.apply(rootScope, args);
      },

      apply: function(fn) {
         rootScope.$apply(fn);
      },

      /**
       * Get the root of the web plugin context path.
       * This is also defined in NGC 6.5.
       * For NGC this is defined directly in web-platform.js in each plugin code
       */
      getRootPath: function() {
         return "/ui";
      },

      /**
       * Get the current client type.
       * This is also defined in NGC 6.5.
       * For NGC 6.0 this is defined directly in web-platform.js in each plugin code.
       */
      getClientType: function() {
         return "html";
      },

      /**
       * Get the current client version.
       * This is also defined in NGC 6.5.
       * For NGC 6.0 this is defined directly in web-platform.js in each plugin code
       */
      getClientVersion: function() {
         // The client version is like 6.5.0-XXXXXXX
         // where XXXXXXX is the gobuild number, or 0 for local dev builds
         var version = this.getString("CommonUi", "client.version");
         // Consider only the official client version without build number
         version = version.split('-')[0];
         return version;
      },

      /**
       * Get the current user session (not a promise!)
       */
      getUserSession: function() {
         return platformNgZone.run(htmlBridgeService.getUserSession, this);
      },

      /**
       * Navigate to the given view
       * @param extensionId
       * @param objectId
       */
      sendNavigationRequest: function(extensionId, objectId) {
         platformNgZone.run(htmlBridgeService.sendNavigationRequest, this, [extensionId, objectId]);
      },

      /**
       * Invoke a headless action within an action dialog.
       * See doc at http://vmweb.vmware.com/~ldelamar/NGC-SDK-6.0.0/html-bridge/docs/html-bridge.html
       *
       * @param url   like /vsphere-client/chassis/rest/actions.html?actionUid=...
       *             Important: target objectId(s) are added automatically to the url.
       * @param json optional data used by the action
       */
      callActionsController: function(url, json) {
         var actionUid = getUrlParameter(url, "actionUid");
         if (!actionUid) {
            throw new Error("missing actionUid parameter in callActionsController url: " + url);
         }
         platformNgZone.run(htmlBridgeService.callActionsController, this, [url, json, actionUid]);
      },

      /**
       * Close the action dialog opened by an HtmlBridge plugin (see htmlBridgeService).
       * This function _must_ be called by the plugin code to dismiss the dialog since the
       * vuiDialogService doesn't show the standard OK/Close buttons in sandbox mode.
       *
       * TODO make this work for other plugins when using the sandbox mode of Action dialogs?
       */
      closeDialog: function() {
         platformNgZone.run(htmlBridgeService.closeDialog, this);
      },

      /**
       * Allow an Html Bridge plugin to modify the dialog title.
       * This API is in the Html Bridge SDK to support use cases where the hard-coded title defined
       * in plugin.xml must be changed at runtime.
       *
       * @param title
       */
      setDialogTitle: function(title) {
         platformNgZone.run(htmlBridgeService.setDialogTitle, this, [title]);
      },

      /**
       * Allow an HtmlBridge plugin to modify the dialog size
       * This API is in the Html Bridge SDK to support use cases where the hard-coded size defined
       * in plugin.xml must be changed at runtime.
       *
       * @param width
       * @param height
       */
      setDialogSize: function(width, height) {
         platformNgZone.run(htmlBridgeService.setDialogSize, this, [width, height]);
      },

      /**
       * This function open modal dialog with width, height and title
       *
       * @param title         Modal dialog title
       * @param url           URL to html page.
       * @param width         Dialog width in pixels, need to be number
       * @param height        Dialog height in pixels, need to be number
       * @param objectId      Id of the target object (optional)
       */
      openModalDialog: function(title, url, width, height, objectId) {
         // showCloseButton and scrollPolicy are not supported in HTML Client SDK
         platformNgZone.run(htmlBridgeService.openModalDialog, this, [title, url, width, height, objectId]);
      },

      /**
       * Triggers an internal modelChange event.  This is mostly useful to refresh custom
       * objects in the RHS navigator (because vSphere objects are refreshed automatically)
       * after a plugin made some changes AND didn't use the standard ActionController which
       * supports such model update (See ActionsController.java in samples)
       *
       * @param objectId   The object affected by the change
       * @param opType     "add", "change", "delete" or "relationship_change"
       */
      // TODO deprecate this API in a future version?
      sendModelChangeEvent: function (objectId, opType) {
         platformNgZone.run(htmlBridgeService.sendModelChangeEvent, this, [objectId, opType]);
      },

      /**
       * Record a refresh handler for the current plugin document.  It will be called each time
       * the user clicks the global refresh button in the toolbar (as long as the view is visible)
       *
       * @param handler          The refresh handler
       * @param viewDocument     The view document (extra parameter used only by HTML SDK)
       */
      setGlobalRefreshHandler: function (handler, viewDocument) {
         if (!viewDocument) {
            console.error("setGlobalRefreshHandler() is missing the view document as 2nd parameter");
            return;
         }
         if (!angular.isFunction(handler)) {
            console.error("setGlobalRefreshHandler() first parameter must be a function");
            return;
         }
         // The refresh handler key must be unique for each plugin iframe document but also not depend on the
         // document body content.  The optimal solution is to compute a hash based on the <head> section, this
         // be unique and won't change over time.
         var docHash = getDocumentHash(viewDocument);
         refreshMap[docHash] = { handler: handler, document: viewDocument};
      }
   };
   return platformService;

   /**
    * Get a Url parameter out of a url
    */
   function getUrlParameter(url, name) {
      return (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')
                  .exec(url) || [undefined, ""])[1].replace(/\+/g, '%20') || null;
   }

   /**
    * This method calls all registered refresh handlers and also removes the ones that are no longer valid
    */
   function handlePluginsGlobalRefresh() {

      _.each(refreshMap, function(value, key) {
         // Once a view is no longer visible there is no reason to keep its refresh handler
         // It will be re-installed when the view becomes visible again.
         if (value.document.visibilityState !== 'visible') {
            delete refreshMap[key];
         } else {
            try {
               value.handler();
            } catch (ex) {
               delete refreshMap[key];
            }
         }

      });
   }

   function getDocumentHash(doc) {
      var headElem = doc.getElementsByTagName("head")[0];
      var headStr = rootScope.domXmlSerializer.serializeToString(headElem);
      return fastHashCode(headStr);
   }

   function fastHashCode(s) {
      return s.split('').reduce(function (a, b) {
         a = ((a << 5) - a) + b.charCodeAt(0);
         return a & a;
      }, 0);
   }


   /**
    * Bootstraps the ability for plugin to get their own copy of the web platform API
    * by means of vSphereClientSDK.getWebPlatformApi() function
    * @param window the H5C main browser window which is extended with the new functionality
    */
   function bootstrapWebPlatformApi(window) {

      //Take a reference to the original functions so that we can safely use them even if
      //some plugin that uses the legacy bootstrap (based on web-platform.js) has overwritten
      //methods of the WEB_PLATFORM object
      var freezedAPI = {
         getClientType: platformService.getClientType,
         getClientVersion: platformService.getClientVersion,
         getRootPath: platformService.getRootPath,

         setGlobalRefreshHandler: platformService.setGlobalRefreshHandler,
         openModalDialog: platformService.openModalDialog,
         closeDialog: platformService.closeDialog,
         setDialogTitle: platformService.setDialogTitle,
         setDialogSize: platformService.setDialogSize,
         getUserSession: platformService.getUserSession,
         getString: platformService.getString,
         sendNavigationRequest: platformService.sendNavigationRequest,
         callActionsController: platformService.callActionsController,
         sendModelChangeEvent: platformService.sendModelChangeEvent
      };


      //create a common object for the vSphere Client SDK if it does not exist
      window.vSphereClientSDK = window.vSphereClientSDK || {};
      window.vSphereClientSDK.getWebPlatformApi = function (pluginWindow) {

         if (!pluginWindow || !pluginWindow.location) {
            console.log('Function called with invalid window parameter: ', pluginWindow);
            return null;
         }

         //preserve the url of the iframe
         var url = pluginWindow.location.href;

         //the actual API, for more info see the official JS API documentation
         var jsSdkAPI = {
            //added in 6.5
            getClientType: getClientType,
            getClientVersion: getClientVersion,
            getRootPath: getRootPath,

            //implemented in web-platform.js
            getLocale: getLocale,
            getObjectId: getObjectId,
            getActionTargets: getActionTargets,
            getActionUid: getActionUid,
            getVcSelectorInfo: getVcSelectorInfo,

            //implemented in the core platform - container app
            setGlobalRefreshHandler: setGlobalRefreshHandler,
            openModalDialog: openModalDialog,
            closeDialog: closeDialog,
            setDialogTitle: setDialogTitle,
            setDialogSize: setDialogSize,
            getUserSession: getUserSession,
            getString: getString,
            sendNavigationRequest: sendNavigationRequest,
            callActionsController: callActionsController,
            sendModelChangeEvent: sendModelChangeEvent
         };
         return jsSdkAPI;


         //added in 6.5
         function getRootPath() {
            return freezedAPI.getRootPath.call(platformService);
         }

         function getClientType() {
            return freezedAPI.getClientType.call(platformService);
         }

         function getClientVersion() {
            return freezedAPI.getClientVersion.call(platformService);
         }


         //web_platform.js
         function getLocale() {
            return getUrlParameter(url, "locale");
         }

         function getObjectId() {
            return getUrlParameter(url, "objectId");
         }

         function getActionTargets() {
            return getUrlParameter(url, "targets");
         }

         function getActionUid() {
            return getUrlParameter(url, "actionUid");
         }


         function getVcSelectorInfo() {
            var info = {
               serviceGuid: getUrlParameter(url, "serviceGuid"),
               sessionId: getUrlParameter(url, "sessionId"),
               serviceUrl: getUrlParameter(url, "serviceUrl")
            };
            return info;
         }

         //platform
         function setGlobalRefreshHandler(handler, document) {
            freezedAPI.setGlobalRefreshHandler.call(platformService, handler, pluginWindow.document);
         }

         function openModalDialog(title, url, width, height, objectId) {
            freezedAPI.openModalDialog.call(platformService, title, url, width, height, objectId);
         }

         function closeDialog() {
            freezedAPI.closeDialog.call(platformService);
         }

         function setDialogTitle(title) {
            freezedAPI.setDialogTitle.call(platformService, title);
         }

         function setDialogSize(width, height) {
            freezedAPI.setDialogSize.call(platformService, width, height);
         }


         function getString(bundleName, key, params) {
            return freezedAPI.getString.call(platformService, bundleName, key, params);
         }


         function getUserSession() {
            return freezedAPI.getUserSession.call(platformService);
         }

         function sendNavigationRequest(extensionId, objectId) {
            freezedAPI.sendNavigationRequest.call(platformService, extensionId, objectId);
         }

         function callActionsController(url, json) {
            freezedAPI.callActionsController.call(platformService, url, json);
         }

         function sendModelChangeEvent(objectId, opType) {
            freezedAPI.sendModelChangeEvent.call(platformService, objectId, opType);
         }
      };

   }

}());
