/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Provides the ability to create visibility-aware subscriptions for data update notifications.
 */
angular.module('com.vmware.platform.ui').factory('visibilityAwareDataUpdateNotificationService', [
   '$rootScope', 'visibilityMonitorService',
   function($rootScope, visibilityMonitorService) {

      var subscribers = {};
      var eventListenersInfo = {};
      var currentSubscriberId = 0;

      function eventHandler(event) {
         var args = arguments;

         var subscribersToNotify = _.values(subscribers);
         subscribersToNotify = _.filter(subscribersToNotify, function(data) {
            // Subscriber is already marked to be refreshed after its element becomes
            // visible - skip it.
            if (data.notifyOnShow) {
               return false;
            }

            var dataUpdateEventConfig = data.dataUpdateEventsConfig[event.name];
            // Subscriber is not subscribed for this event - skip it.
            if (!dataUpdateEventConfig) {
               return false;
            }

            var checker = dataUpdateEventConfig.checker;
            if (_.isFunction(checker)) {
               // Subscriber has a checker function for the specified event -
               // skip the subscriber if the checker function returns false.
               return checker.apply(undefined, args);
            }

            return true;
         });

         notifySubscribers(subscribersToNotify, event.name, args);
      }

      function notifySubscribers(subscribersToNotify, eventName, handlerArgs) {
         var subscribersRawElements = _.map(subscribersToNotify, function(data) {
            return data.element[0];
         });
         var subscribersVisibilityStatuses = visibilityMonitorService.getVisibilityStatus(
            subscribersRawElements
         );

         _.each(subscribersToNotify, function (data, index) {
            var isVisible = subscribersVisibilityStatuses[index];

            if (isVisible) {
               // If the subscriber's element is visible invoke the subscriber's handler
               // for the specified event
               var handler = data.dataUpdateEventsConfig[eventName].handler;
               handler.apply(undefined, handlerArgs);
            } else {
               // If the subscriber's element is invisible mark the subscriber
               // to be refreshed after its element becomes visible
               data.notifyOnShow = true;
            }
         });
      }

      function createSubscriberVisibilityChangeCallback(subscriberData) {
         return function(newValue, oldVlaue) {
            // If the subscriber's element has become visible and the subscriber is
            // marked to be refreshed after its element becomes visible - then
            // invoke the refreshHandler of the subscriber.
            if (newValue !== true || !subscriberData.notifyOnShow) {
               return;
            }

            subscriberData.notifyOnShow = false;
            subscriberData.refreshHandler();
         };
      }

      function updateEventsListenersOnSubscriberAdd(subscriberData) {
         _.each(subscriberData.dataUpdateEventsConfig, function(eventConfig, eventName) {
            // Check if the eventListenersInfo map contains the eventName as key
            // already - if not then we need to subscribe for that event
            // (on the root scope) and store the event removal function in the map
            // so that when there are 0 subscribers for that event we can remove the
            // listener from the root scope
            if (!eventListenersInfo[eventName]) {
               var removeFunction = $rootScope.$on(eventName, eventHandler);
               eventListenersInfo[eventName] = {
                  removeFunction: removeFunction,
                  subscribersCount: 0
               };
            }

            // Increment the subscribers count for the event with 1
            eventListenersInfo[eventName].subscribersCount++;
         });
      }

      function updateEventsListenersOnSubscriberRemove(subscriberData) {
         _.each(subscriberData.dataUpdateEventsConfig, function(eventConfig, eventName) {
            // Decrement the subscribers count for the event with 1
            eventListenersInfo[eventName].subscribersCount--;

            // If there are no more subscribers for that event remove the event listener
            // from the root scope and remove the event from the eventListenersInfo map
            if (eventListenersInfo[eventName].subscribersCount <= 0) {
               eventListenersInfo[eventName].removeFunction();
               delete eventListenersInfo[eventName];
            }
         });
      }

      /**
       * Creates a visibility-aware subscription for data update notifications i.e.
       * notifications to a subscriber that is bound to a invisible element will be
       * delayed until that element becomes visible.
       *
       * @param {Object} element - a DOM element or a jQuery object pointing to a single
       *    DOM element whose visibility determines when the subscriber will be notified
       *    about the data update.
       * @param {function()} refreshHandler - a callback function
       *    that is invoked when the following conditions are satisfied:
       *    1. the element was not visible
       *    2. data update event(s) occurred
       *    3. the element became visible
       * @param {Object} dataUpdateEventsConfig - an object describing which data update
       *    events to subscribe for and for each data update event what
       *    checker function (optional) to invoke and what handler to invoke if
       *    the check succeeds (if there is a checker function).
       *    The arguments passed to the 'checker' and 'handler' functions
       *    are the event and the event arguments (i.e. the arguments received by
       *    the listener for the event on the root scope). If the subscriber's element
       *    is not visible when a relevant data update occurs then respective handler
       *    is not invoked, instead when the element becomes visible the subscriber's
       *    refreshHandler is invoked.
       *
       *    An example dataUpdateEventsConfig object:
       *    {
       *       'update-event-1': {
       *          handler: function(event, arg_1, arg_1, ..., arg_<m>) {
       *          },
       *          checker: function(event, arg_1, arg_2, ..., arg_<m>) {
       *             return <event-is-relevant>;
       *          }
       *       },
       *       ...
       *       'update-event-2': {
       *          handler: function(event, arg_1, arg_2, ..., arg_<k>) {
       *          },
       *          checker: function(event, arg_1, arg_2, ..., arg_<k>) {
       *             return <event-is-relevant>;
       *          }
       *       },
       *    }
       *
       * @returns {Object} subscriber id used to unsubscribe
       */
      var subscribeForDataUpdate = function(element, refreshHandler, dataUpdateEventsConfig) {
         if (!element) {
            throw new Error(
               'element can not be null'
            );
         }

         if (!_.isNumber(element.length)) {
            element = [element];
         }

         if (element.length !== 1 || !_.isElement(element[0])) {
            throw new Error(
               'element must be a DOM element or' +
                  ' a jQuery object pointing to a single DOM element'
            );
         }

         if (!_.isFunction(refreshHandler)) {
            throw new Error('refreshHandler is required and must be a function');
         }

         if (!_.isObject(dataUpdateEventsConfig) || _.isEmpty(dataUpdateEventsConfig)) {
            throw new Error('dataUpdateEventsConfig must be a non-empty object');
         }

         _.each(dataUpdateEventsConfig, function(eventConfig, eventName) {
            if (!_.isObject(eventConfig)) {
               throw new Error('dataUpdateEventConfig for "' +
                  eventName + '" is not an object'
               );
            }

            if (!_.isFunction(eventConfig.handler)) {
               throw new Error('dataUpdateEventConfig.handler for "' +
                  eventName + '" is required and must be an function');
            }

            if (!_.isUndefined(eventConfig.checker) &&
                  !_.isNull(eventConfig.checker) &&
                  !_.isFunction(eventConfig.checker)) {
               throw new Error('dataUpdateEventConfig.checker for "' +
                  eventName + '" must be undefined, null or a function');
            }
         });

         element = $(element);

         currentSubscriberId++;

         var subscriberId = currentSubscriberId;
         var subscriberData = subscribers[subscriberId] = {
            id: subscriberId,
            element: element,
            refreshHandler: refreshHandler,
            dataUpdateEventsConfig: angular.copy(dataUpdateEventsConfig),
            notifyOnShow: false,
            visibilityMonitorServiceSubscriberId: null
         };

         subscriberData.visibilityMonitorServiceSubscriberId =
            visibilityMonitorService.subscribeForVisibilityChange(
               element,
               createSubscriberVisibilityChangeCallback(subscriberData)
            );

         updateEventsListenersOnSubscriberAdd(subscriberData);

         return subscriberId;
      };

      /**
       * Removes a subscription for data update notifications
       *
       * @param {Object} subscriberId - a subscriber id returned as a result
       *    of a call to the 'subscribeForDataUpdate' method
       */
      var unsubscribe = function(subscriberId) {
         if (!_.isNumber(subscriberId)) {
            throw new Error(
               'invalid subscriberId: ' + subscriberId
            );
         }

         if (!(subscriberId in subscribers)) {
            return;
         }

         var subscriberData = subscribers[subscriberId];

         visibilityMonitorService.unsubscribe(
            subscriberData.visibilityMonitorServiceSubscriberId
         );
         subscriberData.visibilityMonitorServiceSubscriberId = null;

         updateEventsListenersOnSubscriberRemove(subscriberData);

         delete subscribers[subscriberId];
      };

      // Cleanup on root scope destroy. Normally we wouldn't concern ourselves with
      // this, but during jasmine tests the service is instantiated multiple times
      // so to prevent memory leaks and memory spikes we need to do a proper clean up
      $rootScope.$on('$destroy', function() {
         _.each(_.keys(subscribers), function(subscriberId) {
            unsubscribe(Number(subscriberId));
         });

         // No need for special cleaning of eventListenersInfo as destroying the
         // root scope will remove the listeners, also we have unsubscribed all
         // subscribers which means this object should be empty.
         eventListenersInfo = {};
         currentSubscriberId = 0;
      });

      return {
         subscribeForDataUpdate: subscribeForDataUpdate,
         unsubscribe: unsubscribe
      };
   }]);