/* Copyright 2013-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Util methods to help managing resource logic.
 */
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').factory('resourceUtil', resourceUtil);

   resourceUtil.$inject = ['i18nService', 'defaultUriSchemeUtil'];

   function resourceUtil(i18nService, defaultUriSchemeUtil) {
      return {
         /**
          * Utility function to process and internationalize the labelIds property of an object.
          * @param labelIdsStr: labelIds property of an object - semicolon separated string
          *
          * @return Empty string if there are no label ids provided, otherwise - comma
          *    separated list of internationalized labelIds surrounded in brackets " ( )"
          *    with a leading whitespace.
          */
         getTaggingLabelString: function (labelIdsStr) {
            if (!angular.isString(labelIdsStr)) {
               return '';
            }
            var labelIds = labelIdsStr.split(';');
            var labels = [];

            labelIds.forEach(function (labelId) {
               labelId = labelId.trim();
               if (!labelId) {
                  return;
               }
               var tokens = labelId.split(':');
               var label = '';
               if (tokens.length === 2) {
                  label = i18nService.getString(tokens[0], tokens[1]);
               } else if (tokens.length === 1) {
                  label = i18nService.getString('Common', tokens[0]);
               }

               if (!label) {
                  label = labelId;
               }

               labels.push(label);
            });
            return labels.length === 0 ? '' : (' (' + labels.join(', ') + ')');
         },

         /**
          * Utility function to figure out if that element needs to be italic
          *
          * @param labelIds: labelIds property of an object - semicolon separated string
          * @param primaryIconId: primaryIconId property of an object - semicolon separated string
          *
          * @return string
          * "italic" - the elements has to be italic
          * "" - empty string when no need to be italic
          */
         getItalicClassName: function (labelIds, primaryIconId) {
            var DISCONNECTED = "disconnected";
            var NOT_RESPONDING = "notResponding";
            var INACCESSIBLE = "inaccessible";

            var isDisconnectedOrNotResponding = labelIds && ( labelIds.indexOf(DISCONNECTED) !== -1 || labelIds.indexOf(NOT_RESPONDING) !== -1 );
            var isInaccessible = primaryIconId && primaryIconId.indexOf(INACCESSIBLE) !== -1;
            return ( isDisconnectedOrNotResponding || isInaccessible ) ? "italic" : "";
         },

         /**
          * Returns the localized type for a given object or empty string in case of unknown type.
          */
         getLocalizedEntityType: function (objectId) {
            if (objectId && typeof objectId === "string") {
               var entityType = defaultUriSchemeUtil.getEntityType(objectId);
               switch (entityType) {
                  case "HostSystem":
                     return i18nService.getString("Common", "typeResource.host");
                  case "Datacenter":
                     return i18nService.getString("Common", "typeResource.datacenter");
                  case "Datastore":
                     return i18nService.getString("Common", "typeResource.datastore");
                  case "Folder":
                     if (defaultUriSchemeUtil.isRootFolder(objectId)) {
                        return i18nService.getString("Common", "typeResource.vCenter");
                     }
                     return i18nService.getString("Common", "typeResource.folder");
                  case "VirtualMachine":
                     return i18nService.getString("Common", "typeResource.vm");
                  case "ClusterComputeResource":
                     return i18nService.getString("Common", "typeResource.cluster");
                  case "VirtualApp":
                     return i18nService.getString("Common", "typeResource.vapp");
                  case "ResourcePool":
                     return i18nService.getString("Common", "typeResource.rp");
                  case "Network":
                     return i18nService.getString("Common", "typeResource.network");
                  case "VmwareDistributedVirtualSwitch":
                     return i18nService.getString("Common", "typeResource.dvs");
                  case "DistributedVirtualPortgroup":
                     return i18nService.getString("Common", "typeResource.dvpg");
                  case "StoragePod":
                     return i18nService.getString("Common", "typeResource.storagePod");
               }
            }
            return "";
         }
      };
   }
})();