(function () {
   'use strict';
   angular
      .module('com.vmware.platform.ui')
      .factory('issueService', issueService);
   issueService.$inject = ['$http'];

   /**
    * @ngdoc service
    * @name issueService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Service to retrieve issues.
    * @param $http
    * @returns {{getIssues: getIssues}}
    */
   function issueService($http) {
      var ISSUES_ENDPOINT = 'issues';
      var ISSUES_ACTIONS_ENDPOINT = 'issues/actions/evaluations';
      var service = {
         getIssues: getIssues,
         evaluateActions: evaluateActions
      };

      return service;

      /**
       * Retrieve issues from the server for a given objectId
       * @param objectId
       *    Identifier of the object whose issues are to be fetched.
       * @param count
       *    Max count of issues to be fetched.
       * @param getActionEvaluations
       *    Boolean flag whether to get actions for each issue.
       */
      function getIssues(objectId, count, getActionEvaluations) {

         var promise = $http({
            method: 'GET',
            url: ISSUES_ENDPOINT,
            params: {
               objectId: objectId,
               pageSize: count,
               getActionEvaluations: getActionEvaluations
            }
         }).then(function (response) {
            return response.data;
         });
         return promise;
      }

      /**
       * Retrieves the action evaluations for an issue and the object to which the issue belongs to
       * @param objectId
       *    Identifier of the object whose actions will be evaluated
       * @param issueId
       *    Identifier of the issue whose actions will be retrieved
       * @param details
       *    Details for the provided issue, used to filter some of the actions
       */
      function evaluateActions(objectId, issueId, details) {
         var promise = $http({
            method: 'POST',
            url: ISSUES_ACTIONS_ENDPOINT,
            params: {
               objectId: objectId,
               issueId: issueId
            },
            data: {
               details: details
            }
         }).then(function (response) {
            return response.data;
         });
         return promise;
      }
   }
}());

