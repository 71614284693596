/* Copyright 2015-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 */
angular.module('com.vmware.platform.ui')
.factory('alertService',['$rootScope', '$compile', 'i18nService',
   function($rootScope, $compile, i18nService) {
      'use strict';

      var compiledAlertDialog;
      var scope;

      /**
       * Alert close reasons.
       *
       * Passed to the `closeHandler` callback in the `alertService.error` function.
       *
       * @property REJECTED
       *    Passed when the alert is closed by pressing escape.
       *
       * @property CONFIRMED
       *    Passed when the alert is closed by clicking the OK button.
       */
      var closeReasons = Object.freeze({
         REJECTED: 0,
         CONFIRMED: 1
      });

      /**
       * Show Alert.
       *
       * @param {string} alertOptions
       *    see vxAlert.
       */
      function showAlert(alertOptions) {
         scope = $rootScope.$new();
         scope.alertOptions = alertOptions;
         var alertDialog = angular.element('<div id="alert-popup" '
            + 'class="action-confirmation" '
            + 'vx-alert="alertOptions"></div>');

         compiledAlertDialog = $compile(alertDialog)(scope);
         angular.element("#main-app-div").append(compiledAlertDialog);
      }

      /**
       * Show Alert with the desired title and text
       *
       * @param {string} title
       *    Alert title.
       *
       * @param {string} text
       *    Alert message.
       *
       * @param {callback} closeHandler (optional)
       *    Invoked when the Alert is closed.
       *
       *    Format:
       *       function (reason) { ... }
       *
       *    Where `reason` is one of the close reasons above.
       */
      function error(title, text, closeHandler) {
         function closeWithReason(reason) {
            closeConfirmationDialog();
            if (_.isFunction(closeHandler)) {
               closeHandler(reason);
            }
         }

         var alertOptions = {
            title: title,
            text: text,
            icon: 'vui-icon32-error',
            visible: true,
            confirmOptions: {
               label: i18nService.getString('Common', 'alert.ok'),
               onClick: function () {
                  closeWithReason(closeReasons.CONFIRMED);
               }
            },
            rejectOptions: {
               visible: false,
               onClick: function() {
                  closeWithReason(closeReasons.REJECTED);
               }
            }
         };
         showAlert(alertOptions);
      }
      /**
       * Show warning Alert with OK and Cancel buttons.
       *
       * @param {string} title
       *    Alert title.
       *
       * @param {string} text
       *    Alert message.
       *
       * @param {callback} okHandler
       *    Invoked when the Alert is closed with OK.
       *
       * @param {callback} cancelHandler (optional)
       *    Invoked when the Alert is closed with Cancel.
       */
      function okCancel(title, text, okHandler, cancelHandler) {
         var alertOptions = {
            title: title,
            text: text,
            icon: 'icon-warning-32',
            visible: true,
            confirmOptions: {
               label: i18nService.getString('Common', 'alert.ok'),
               visible: true,
               disabled: false,
               onClick: function () {
                  closeConfirmationDialog();
                  okHandler();
               }
            },
            rejectOptions: {
               label: i18nService.getString('Common', 'alert.cancel'),
               visible: true,
               disabled: false,
               focused: true,
               onClick: function () {
                  closeConfirmationDialog();
                  cancelHandler();
               }
            }
         };
         showAlert(alertOptions);
      }

      function closeConfirmationDialog() {
         // Use evalAsync because when trying to remove the DOM element
         // `$apply already in progress` error occurs after which the next popup show
         // doesn't interpolate it's angular expressions immediately.
         $rootScope.$evalAsync(function() {
            scope.$destroy();
            compiledAlertDialog.remove();
         });
      }

      return {
         error: error,
         okCancel: okCancel,
         closeReasons: closeReasons
      };
   }]);
