/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Data model class for performance data.
 * TODO: nsheth This should not be in the global namespace.
 */
function PerformanceData() {
   // Total activity start/stop timestamp
   this.startTimestamp = null;
   this.endTimestamp = null;
   this.timeInSeconds = 0;
   this.watchersCount = 0;

   // UI activity timestamp
   this.uiTimeInSeconds = 0;

   // Ajax call timestamp.
   this.ajaxTimeInSeconds = 0;

   // Ajax call counts.
   this.ajaxCallsCount = 0;

   this.perfEntries = [];
 }