/* Copyright 2017 Vmware, Inc. All rights reserved. -- Vmware Confidential */
namespace common_ui {
   export class StorageSelectorConstants {
      public static $inject = [
         "i18nService"
      ];

      public readonly STANDARD_STORAGE_BASELINE: StorageBaseline = {
         label: "na",
         id: "stdBaseline"
      };
      public readonly PMEM_STORAGE_BASELINE: StorageBaseline = {
         label: "na",
         id: "pmemBaseline"
      };
      public readonly HYBRID_STORAGE_BASELINE: StorageBaseline = {
         label: "na",
         id: "hybridBaseline"
      };

      public readonly BASIC_MODE = "basicMode";
      public readonly ADVANCED_MODE = "advancedMode";

      constructor(
         private i18nService: any
      ) {
         this.STANDARD_STORAGE_BASELINE.label =
               i18nService.getString("Common", "storageSelector.stdBaseline.label");

         this.PMEM_STORAGE_BASELINE.label =
               i18nService.getString("Common", "storageSelector.pmemBaseline.label");

         this.HYBRID_STORAGE_BASELINE.label =
               i18nService.getString("Common", "storageSelector.hybridBaseline.label");
      }

   } // class StorageSelectorConstants

   angular.module("com.vmware.platform.ui").service(
         "storageSelectorConstants", StorageSelectorConstants);

} // namespace
