/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter turn format numbers into the closest byte unit Eg: 1024 to 1 kB,
 * 1024*1024 to 1 MB
 */
// originally from https://gist.github.com/thomseddon/3511330
angular.module('com.vmware.platform.ui').filter('bytes', bytesFilter);

bytesFilter.$inject = ['numberFormatterService'];

function bytesFilter (numberFormatterService) {
   'use strict';

   return function (bytes, sourceUnit, targetUnit, fractionSize) {
      return numberFormatterService.formatStorage(bytes, sourceUnit, targetUnit, fractionSize);
   };
}
