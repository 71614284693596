/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').component('settingsViewHeader', {
      templateUrl: 'resources/ui/components/settingsView/settingsViewHeader.html',
      controller: SettingsViewHeader,
      controllerAs: 'ctrl',
      bindings: {
         // Mandatory
         headerTitle: '<',
         // Optional
         actions: '<',
         objectIds: '<',
         accessor: '<' // accessor pattern to make it easy to call the directive
         // functions from hosting components. Used to call the
         // directive's 'evaluateActionAvailability' function when needed
         // (on global refresh for instance
      }
   });

   SettingsViewHeader.$inject = [
      'actionsService'
   ];

   function SettingsViewHeader(actionsService) {
      var ctrl = this;
      ctrl.actionsToRender = [];

      var actionUids = [];
      var customLabelMap = {};

      ctrl.$onInit = function () {
         if (ctrl.actions && ctrl.actions.length) {
            _.each(ctrl.actions, function (action) {
               actionUids.push(action.actionUid);
               customLabelMap[action.actionUid] = action.customLabel;
            });
         }

         if (ctrl.accessor) {
            ctrl.accessor.refresh = function () {
               evaluateActionAvailability();
            };
         }

         evaluateActionAvailability();
      };

      ctrl.invokeAction = function ($event, action) {
         // The settings view header has to provide as context of the action
         // object not array which brakes the contract of invokeAction method.
         // As I manage to verify the objectIds are not used and the change the
         // settings view header to provide object as context probably will
         // not affect other components
         var context = {
           objectIds: ctrl.objectIds,
           focusTarget: $event.target
         };
         actionsService.invokeAction(action.eval, context);
      };

      function evaluateActionAvailability() {
         if (!actionUids || actionUids.length === 0) {
            return;
         }

         // Request action evaluations from the backend
         actionsService.getActions(ctrl.objectIds, actionUids)
            .then(function (actionEvalutaions) {
               var actionToRenderMap = {};

               // Build actions to render items
               _.each(actionEvalutaions, function (actionEvaluation) {

                  var actionUid = actionEvaluation.action.uid;
                  var customLabel = customLabelMap[actionUid];

                  actionToRenderMap[actionUid] = {
                     label: customLabel || actionEvaluation.action.label,
                     actionId: actionEvaluation.action.uid,
                     isEnabled: actionEvaluation.available,
                     eval: actionEvaluation
                  };
               });

               // Add actions in the same order as specified in the
               // directive.actions property.
               var actionsToRender = [];
               _.each(actionUids, function (actionUid) {
                  if (actionToRenderMap[actionUid]) {
                     actionsToRender.push(actionToRenderMap[actionUid]);
                  }
               });
               ctrl.actionsToRender = actionsToRender;
            });
      }

   }
}());
