angular.module('com.vmware.platform.ui').factory('taskFilterService',
   ['persistenceService', 'taskConstants',
   function(persistenceService, taskConstants) {

      var RECENT_TASKS_USER_SETTINGS_UID = 'com.vmware.usersetting.recenttasks.filters';
      var RECENT_TASKS_USER_SETTINGS_CATEGORY = 'com.vmware.usersetting.recenttasks';

      return {

         saveFiltersData: function(selectedTaskStatus) {
            persistenceService.setData(
               RECENT_TASKS_USER_SETTINGS_UID,
               angular.toJson({
                  selectedTaskStatus: selectedTaskStatus
               }),
               {},
               RECENT_TASKS_USER_SETTINGS_CATEGORY);
         },

         loadFiltersData: function() {
            var conditionObj = {
               key: 'uid',
               op: '=',
               value: RECENT_TASKS_USER_SETTINGS_UID
            };

            return persistenceService.getData(conditionObj);
         },

         /**
          * Selects tasks based on the selected status, if other than ALL.
          * @param formattedTasks
          *    the tasks to be filtered.
          *
          * @return
          *    returns the filtered tasks
          */
         filterTasksByStatus: function(formattedTasks, taskStatus) {
            if (taskStatus !== taskConstants.status.ALL) {
               return _.filter(formattedTasks, predicate);
            }
            return formattedTasks;

            function predicate(task) {
               return task.state === taskStatus;
            }
         }
      };
}]);