/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for handling tree updates like item modified, added etc.
 *
 * It has to be initialized by providing: kendoTreeView, treeId, rootObjectId
 * Example:
 *    var handler = treeUpdatesHandler(kendoTreeView, treeId, rootObjectId);
 *    handler.add(object_id)
 *    ...
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('treeUpdatesHandler', treeUpdatesHandler);

   treeUpdatesHandler.$inject = [
      '$q',
      '$timeout',
      'treeNodeService',
      'treeNodesVisibilityStateService',
      'chunkExecutor',
      'defaultUriSchemeUtil',
      'logService',
      'dataService',
      'treeLiveUpdateBufferService',
      'resourceUtil',
      '$log'
   ];

   //the injected services
   var $q, $timeout, treeNodeService, treeNodesVisibilityStateService, chunkExecutor,
      defaultUriSchemeUtil, dataService, treeLiveUpdateBufferService, resourceUtil, $log;

   //the actual logger
   var logLog;

   //A flag for optimizing getChildren call,
   //When true the request to the server for getting a node children is debounced (see _.debounce)
   //for GET_CHILDREN_DELAY period
   //Example: GET_CHILDREN_DELAY = 1s
   // isOptimizedGetChildren = true
   // time | operation
   //  ms
   // 0000 | modified 'a' with parent node 1
   // 0500 | modified 'b' with parent node 1
   // 0500 | modified 'c' with parent node 2
   // 0800 | modified 'd' with parent node 1
   // 1200 | modified 'e' with parent node 1
   // 1500 | request to the server for children of node 2 (will update c)
   // 1700 | modified 'f' with parent node 1
   // 2700 | request to the server for children of node 1 (will update a,b,d,e,f)
   //
   // isOptimizedGetChildren = false
   // time | operation
   //  ms
   // 0000 | modified 'a' with parent node 1
   // 0000 | request to the server for children of node 2 (will update a)
   // 0500 | modified 'b' with parent node 1
   // 0500 | request to the server for children of node 2 (will update b)
   // 0500 | modified 'c' with parent node 2
   // 0500 | request to the server for children of node 2 (will update c)
   // 0800 | modified 'd' with parent node 1
   // 0800 | request to the server for children of node 2 (will update d)
   // 1200 | modified 'e' with parent node 1
   // 1200 | request to the server for children of node 2 (will update e)
   // 1700 | modified 'f' with parent node 1
   // 1700 | request to the server for children of node 1 (will update f)
   var isOptimizedGetChildren = true;

   var supportedTypesByTreeId;

   //A secret way to test the performance improvement
   //Get the treeUpdatesHandler service and call .setOptimizedGetChildren(false/true);
   //In the browser dev console one can turn it off by:
   //angular.element("#main-app-div").injector().get('treeUpdatesHandler').setOptimizedGetChildren(false);
   getHandler.setOptimizedGetChildren = function(flag) {
      isOptimizedGetChildren = !!flag;
   };

   //The debounce wait time when get children is optimized
   //TODO irahov: consider using h5Constants.REFRESH_DELAY
   var GET_CHILDREN_DELAY = 1000;
   var ITEM_MOVE = 0;
   var ITEM_NEW = 1;
   var ITEM_MOVE_BEFORE = 0;
   var ITEM_MOVE_AFTER = 1;

   var GENERIC_FOLDER_TYPE = "Folder";
   var ROOT_FOLDER_VALUE = "group-d1";
   var ROOT_FOLDER_TYPE = "rootFolder";
   var SPECIFIC_FOLDER_TYPES = {
      "group-d": "datacenterFolder",
      "group-h": "hostFolder",
      "group-v": "vmFolder",
      "group-s": "storageFolder",
      "group-n": "networkFolder"
   };
   var CONFIG_TEMPLATE_PROPERTY = "config.template";
   var GENERIC_VIRTUAL_MACHINE_TYPE = "VirtualMachine";
   var NODETYPE_VM_CLASSES = ".StandaloneHostVm, .ClusterVm, .ResPoolVm";

   function treeUpdatesHandler(
      _$q,
      _$timeout,
      _treeNodeService,
      _treeNodesVisibilityStateService,
      _chunkExecutor,
      _defaultUriSchemeUtil,
      logService,
      _dataService,
      _treeLiveUpdateBufferService,
      _resourceUtil,
      _$log) {

      //one time initializers
      var log = logService('treeUpdatesHandler');
      $q = _$q;
      $timeout = _$timeout;
      treeNodeService = _treeNodeService;
      treeNodesVisibilityStateService = _treeNodesVisibilityStateService;
      chunkExecutor = _chunkExecutor;
      defaultUriSchemeUtil = _defaultUriSchemeUtil;
      dataService = _dataService;
      treeLiveUpdateBufferService = _treeLiveUpdateBufferService;
      resourceUtil = _resourceUtil;
      supportedTypesByTreeId = {};

      logLog = log.log.bind(log);
      $log = _$log;

      //the initialization function, which also provides the public api
      return getHandler;
   }

   /**
    * Generates a handler for a specific kendoTreeView.
    *
    * @param kendoTreeView the kendo tree that this handler operates on
    * @param treeId the id of the underlying tree
    * @param rootObjectId {optional} the root id of the tree
    * @param preserveSelection callback in the following format: function(updatedItems)
    * @returns tree updates handler (handling updates like: add, change, etc.)
    */
   function getHandler(kendoTreeView, treeId, rootObjectId, preserveSelection) {

      //This object is used in order to call the server with a delay:
      // 1.To overcome data sync issue: see https://reviewboard.eng.vmware.com/r/922365/
      // 2.To introduce performance improvement - may reduce several requests to 'getChildren' into 1
      var delayedGetChildren = {};

      treeLiveUpdateBufferService.createChangeBuffer(treeId, updateTree);
      treeLiveUpdateBufferService.createChildrenBuffer(treeId, updateParents);
      treeNodesVisibilityStateService.initAsync();

      //Public API
      var handler = {
         add: add,
         change: change,
         handleDelete: handleDelete,
         handleMove: handleMove,
         handleNodesVisibility: handleNodesVisibility,
         childrenUpdate: childrenUpdate,
         liveChildrenUpdate: liveChildrenUpdate,
         liveDelete: liveDelete,
         liveChangeUpdate: liveChangeUpdate,
         destroy: destroy
      };
      return handler;

      /**
       * Destroys the buffers to avoid any update operations after
       * kendoTreeView's element is gone.
       */
      function destroy() {
         treeLiveUpdateBufferService.destroyChangeBuffer(treeId);
         treeLiveUpdateBufferService.destroyChildrenBuffer(treeId);
      }

      /**
       * Handles change events for a given object.
       * @param objectId the id of the modified object.
       */
      function change(objectId) {
         //change events are handled only for currently available items
         if (!getDataItem(kendoTreeView, objectId)) {
            return $q.resolve();
         }

         return updateAround(objectId);
      }

      /**
       * Handles add event for a specific object.
       * @param objectId
       */
      function add(objectId) {
         updateAround(objectId);
      }

      /**
       * Handles delete event for a specific object
       * @param objectId the id of the deleted object
       */
      function handleDelete(objectId) {
         removeItemFromTree(kendoTreeView, objectId);
      }

      /**
       * Handles tree nodes visibility change
       * @param nodeTypes array of node types to be shown/hidden
       */
      function handleNodesVisibility(nodeTypes) {
         return removeItemsOfTypesFromTree(kendoTreeView, nodeTypes);
      }

      function handleMove(objectId) {
         var item = getDataItem(kendoTreeView, objectId);

         if (!item) {
            return updateAround(objectId);
         }

         return getPathsToObject(objectId)
               .then(getParentFromPath)
               .then(function (parent) {
                  if (!parent) {
                     return $q.reject("undefined parent for object id: " + objectId);
                  }
                  return treeNodeService
                     .getChildren(treeId, parent.nodeTypeId, parent.objRef)
                     .then(checkIfViewExists)
                     .then(function(children) {
                        kendoTreeView.remove(kendoTreeView.findByUid(item.uid));
                        return diffUpdateNodeChildren(parent, children);
                     });
               })
               .catch(logLog);
      }

      function childrenUpdate(objectId) {
         var item = getDataItem(kendoTreeView, objectId);

         if (!item) {
            return;
         }

         return treeNodeService.getChildren(treeId, item.nodeTypeId, item.objRef)
               .then(checkIfViewExists)
               .then(function(children) {
                  return diffUpdateNodeChildren(item, children);
               })
               .then(function () {
                  var properties = ['primaryIconId', 'name', 'labelIds'];
                  // get `config.template` property if the node type s VM as we need to distinguish VMs an VM templates
                  if(item.nodeTypeId === GENERIC_VIRTUAL_MACHINE_TYPE){
                     properties.push(CONFIG_TEMPLATE_PROPERTY);
                  }
                  return dataService.getProperties(item.objRef, properties,
                      {queryName:"treeUpdatesHandler." + childrenUpdate.name})
                        .then(function (res) {
                           updateNodeState(item, {
                              icon: res.primaryIconId,
                              name: res.name,
                              labelIds: res.labelIds,
                              isVmTemplate: res[CONFIG_TEMPLATE_PROPERTY]
                           });
                        });
               })
               .catch(logLog);
      }

      function liveChangeUpdate(update) {
         if (isUpdateOfHiddenNode(treeId, update)) {
            return;
         }
         getSupportedTypes(treeId)
            .then(checkIfViewExists)
            .then(function(supportedTypes) {
               if (!isRelevantUpdate(update, supportedTypes)) {
                  return;
               }

               var objId = getObjectIdFromUpdateData(update);
               var item = getDataItem(kendoTreeView, objId);

               if (!item) {
                  return;
               }

               treeLiveUpdateBufferService.addChangeEntry(treeId, objId, update);
            })
            .catch(logLog);
      }

      function liveChildrenUpdate(update) {
         if (isUpdateOfHiddenChildVm(treeId, update)) {
            return;
         }
         getSupportedTypes(treeId)
            .then(checkIfViewExists)
            .then(function(supportedTypes) {
               if (isRelevantUpdate(update, supportedTypes)) {
                  handleLiveChildrenUpdate(getObjectIdFromUpdateData(update));
               }
            })
            .catch(logLog);
      }

      function liveDelete(update) {
         if (isUpdateOfHiddenNode(treeId, update)) {
            return;
         }
         getSupportedTypes(treeId)
            .then(checkIfViewExists)
            .then(function(supportedTypes) {
               if (!isRelevantUpdate(update, supportedTypes)) {
                  return;
               }
               removeItemFromTree(kendoTreeView, getObjectIdFromUpdateData(update));
            })
            .catch(logLog);
      }

      function getObjectIdFromUpdateData(update) {
         var mor = update.source;
         return defaultUriSchemeUtil.createVmomiUri(
             mor.type,
             mor.value,
             mor.serverGuid
         );
      }

      function isRelevantUpdate(update, supportedTypes) {
         if (!update) {
            return false;
         }
         if (!angular.isArray(supportedTypes) || supportedTypes.length === 0) {
            return true;
         }
         var moRef = update.source;
         var type = moRef.type;
         if (type === GENERIC_FOLDER_TYPE) {
            type = getSpecificFolderType(moRef.value) || moRef.type;
         }
         return (supportedTypes.indexOf(type) !== -1);
      }

      function isUpdateOfHiddenChildVm (treeId, update) {
         if (!update) {
            return false;
         }
         var childrenProperties = update.data ? update.data.childrenProperties : [];
         if (childrenProperties.length === 1 && childrenProperties[0] === "vm") {
            var isChildVmVisible = treeNodesVisibilityStateService.isNodeTypeVisibleSync(treeId, "VirtualMachine");
            return !isChildVmVisible;
         }
         return false;
      }

      function isUpdateOfHiddenNode (treeId, update) {
         if (!update) {
            return false;
         }
         var moRef = update.source;
         var type = moRef.type;
         var isVisibleNode = treeNodesVisibilityStateService.isNodeTypeVisibleSync(treeId, type);
         return !isVisibleNode;
      }


      /**
       * Handles children_update coming from the live refresh.
       */
      function handleLiveChildrenUpdate(objectId) {
         if (!angular.isString(objectId)) {
            return;
         }

         var parent = treeNodeService.objAliasesToPrimaryObjId[objectId];
         if (parent) {
            objectId = parent;
         }
         //try to get the item
         var item = getDataItem(kendoTreeView, objectId);

         //item is not currently loaded, ignore the update
         if (!item) {
            return;
         }

         //Final step: the item is loaded, but make sure it is visible
         //If a item is not expanded then mark that item for a reload
         //and stop further processing
         if (!item.expanded) {
            item.loaded(false);
            return;
         }

         treeLiveUpdateBufferService.addChildrenEntry(treeId, objectId, item);
      }

      /**
       * Callback for the children update buffer
       *
       * @param data Contains the children for the parents that should be updated
       * @param parents The parents as kendo items
       */
      function updateParents(data, parents) {
         if (!data || !parents) {
            return;
         }

         var movedChildren = _.reduce(parents, function(acc, parent) {
            return acc.concat(updateChildren(data[parent.objRef], parent));
         }, []);

         preserveSelection(movedChildren);
      }

      function updateChildren(children, parent) {
         if (!children) {
            return [];
         }

         var movedChildren = [];
         var parentNode = kendoTreeView.findByUid(parent.uid);
         if (!kendoTreeView.dataItem(parentNode)) {
            return movedChildren;
         }

         var oldChildren = parent.children && _.isFunction(parent.children.data) ? parent.children.data() : [];
         oldChildren = _.extend([], oldChildren);
         var latestChildrenRef = _.pluck(children, 'objRef');

         _.each(oldChildren, function (x) {
            if (!(_.contains(latestChildrenRef, x.objRef))) {
               movedChildren.push(x.objRef);
               $log.debug("treeUpdatesHandler#updateChildren: removing '" + x.text + "' from '" + parent.text + "'");
               kendoTreeView.remove(kendoTreeView.findByUid(x.uid));
            }
         });

         var isFirstChild = true;
         _.each(children, function (curr, i) {
            var existingItem = getDataItem(kendoTreeView, curr.objRef);
            if (!existingItem || existingItem.parentNode().objRef !== parent.objRef) {
               $log.debug("treeUpdatesHandler#updateChildren: inserting '" + curr.text + "' under '" + parent.text + "'");
               // when the item is existing the opType should be MOVE, otherwise
               // the opType is NEW and the passed item is the JSON object - curr
               const opType = existingItem ? ITEM_MOVE : ITEM_NEW;
               const item = existingItem ? existingItem : curr;
               if (isFirstChild) {
                  insertFront(item, parentNode, opType);
               } else {
                  insertAfter(item, children[i - 1], opType);
               }
               movedChildren.push(curr.objRef);
            }
            if (isFirstChild) {
               isFirstChild = false;
            }
         });
         return movedChildren;
      }

      function getObjectType(objectId) {
         return defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId).type;
      }

      /**
       * Updates the tree elements' icons, names and labels.
       *
       * @param dataObject
       *    Contains objectId to properties mapping.
       * @param bufferMap
       *    `treeLiveUpdateBufferService`'s change update buffer map.
       */
      function updateTree(dataObject, bufferMap) {
         Object.keys(bufferMap).forEach(function(objectId) {
            var currentDataObject = dataObject[objectId];
            if (!dataObject ||
                !Object.keys(dataObject).length ||
                !currentDataObject) {
               return;
            }

            var name = currentDataObject.name;
            var labelIds = currentDataObject.labelIds;
            var icon = currentDataObject.primaryIconId;
            var isVmTemplate = currentDataObject[CONFIG_TEMPLATE_PROPERTY];

            var dataItem = getDataItem(kendoTreeView, objectId);
            if (!dataItem) {
               return;
            }
            var objectType = getObjectType(objectId);

            var parent = dataItem.parentNode();

            if (!parent) {
               return;
            }
            var allChildren = parent.children.data() || [];

            // Get only the children of the same type.
            var children = _.filter(allChildren, function(child) {
               return getObjectType(child.objRef) === objectType;
            });

            var item = kendoTreeView.findByUid(dataItem.uid);

            if (!kendoTreeView.dataItem(item)) {
               return;
            }
            // Move the element if necessary.
            if (bufferMap[objectId].data.isNameUpdate || bufferMap[objectId].data.isTemplateUpdate) {
               var startIndex = 0;
               var endIndex = children.length;

               // If the childrens are VMs and VmTemplates divide them in two groups
               if (isVmTemplate !== null && isVmTemplate !== undefined) {
                  // get number of vms
                  var templateStartingIndex = children.filter(function (child) {
                     return !child.isVmTemplate;
                  }).length;
                  if (isVmTemplate) {
                     startIndex = templateStartingIndex;
                  } else {
                     endIndex = templateStartingIndex;
                  }
               }
               var options = {
                  startIndex: startIndex,
                  endIndex: endIndex,
                  isVmTemplate: isVmTemplate,
                  name: name
               };
               moveElementInTree(dataItem, children, options);
            }

            var kInItem = $($(item[0]).find(".k-in")[0]);
            if (kInItem) {
               kInItem.removeClass('italic');
               var italic = resourceUtil.getItalicClassName(labelIds, icon);
               if (italic) {
                  kInItem.addClass(italic);
               }
            }

            updateNodeState(dataItem, { icon: icon, name: name, labelIds: labelIds, isVmTemplate: isVmTemplate});
         });
      }

      /**
       * Move element in tree
       *
       * @param dataItem - element to move
       * @param children - children element of the parent node
       * @param options - object containing few options needed for the operation:
       *                  - startIndex - start index of the children of the same type
       *                  - endIndex - end index of the children of the same type
       *                  - isVmTemplate - denotes if the element is vmTemplate
       *                  - name - the name of the object
       */
      function moveElementInTree(dataItem, children, options) {
         var item = kendoTreeView.findByUid(dataItem.uid);
         var nextItem = findNextTreeNode(dataItem, children, options);
         if (!nextItem) {
            var lastDataItem = children[options.endIndex - 1];
            moveElementAtPosition(item, lastDataItem, ITEM_MOVE_AFTER);
            return;
         }
         moveElementAtPosition(item, nextItem, ITEM_MOVE_BEFORE);
      }

      /**
       * Find the next element of given node in the tree
       *
       * @param dataItem - element to move
       * @param children - children element of the parent node
       * @param options - object containing few options needed for the operation:
       *                  - startIndex - start index of the children of the same type
       *                  - endIndex - end index of the children of the same type
       *                  - isVmTemplate - denotes if the element is vmTemplate
       *                  - name - the name of the object
       * @returns {*}
       */
      function findNextTreeNode(dataItem, children, options) {
         // if there aren't any VMs in the tree and VmTemplate is being converted to VM,
         // it should become first child
         if (options.startIndex === options.endIndex && options.isVmTemplate === false) {
            return children[0];
         }
         var nextTreeNode;
         for (var i = options.startIndex; i < options.endIndex; i++) {
            if (!(children[i] && children[i].text)) {
               continue;
            }
            if (children[i].text.toLocaleLowerCase().localeCompare(options.name.toLocaleLowerCase()) > 0 &&
                children[i].text.localeCompare(dataItem.text) !== 0) {
               nextTreeNode = children[i];
               break;
            }
         }
         return nextTreeNode;
      }

      /**
       * Moves element in tree before/after another element
       *
       * @param item - element to move
       * @param node - node in the tree which will be before/after the current one
       * @param movePosition - denotes before or after given node the current element will be moved
       */
      function moveElementAtPosition(item, node, movePosition) {
         var nodeElement = kendoTreeView.findByUid(node.uid);
         if (!kendoTreeView.dataItem(nodeElement)) {
            return;
         }
         if (item.text().localeCompare(node.text) === 0) {
            return;
         }
         if (movePosition === ITEM_MOVE_BEFORE) {
            kendoTreeView.insertBefore(item, nodeElement);
            return;
         }
         kendoTreeView.insertAfter(item, nodeElement);
      }

      /**
       * Updates the tree around a given object.
       * Basically the logic here goes like this:
       *   1. Check if the item is in the tree
       *     1.1. If not - then find its parent by requesting it from the server
       *     1.2. If yes - get the parent from the tree
       *   2. The following logic is valid for the parent of the item:
       *     2.1. if it is not loaded or not expanded - do nothing
       *     2.2. if it is loaded and its state is:
       *       2.2.1. collapsed, then just mark it for a reload
       *       2.2.2. expanded, then update the children of this parent
       *
       * @param objectId the id of the item
       */
      function updateAround(objectId) {
         var deferred = $q.defer();

         if (!angular.isString(objectId)) {
            deferred.resolve();
         } else {
            var item, parent;

            //try to get the item
            item = getDataItem(kendoTreeView, objectId);

            //item is not currently loaded, so get the paths to this object from the server
            if (!item) {
               getPathsToObject(objectId)
                  .then(getParentFromPath)
                  .then(getChildrenForDiffUpdate)
                  .then(deferred.resolve)
                  .catch(logLog);
            } else {
               parent = item.parentNode();

               //no parent, it's a root node update
               if (!parent) {
                  //TODO irahov: figure out what should be the exact handling for the root
                  deferred.resolve();
               } else {
                  //Final step: the item is loaded, but make sure it is visible
                  //If a parent is not expanded then mark that parent for a reload
                  //and stop further processing
                  do {
                     if (!parent.expanded) {
                        parent.loaded(false);
                        deferred.resolve();
                        return deferred.promise;
                     }
                  } while ((parent = parent.parentNode()));

                  // Update the children of the parent
                  // This will update the item itself (including its position in case of
                  // a rename)
                  getChildrenForDiffUpdate(item.parentNode()).then(deferred.resolve);
               }
            }
         }

         return deferred.promise;
      }

      /**
       * Retrieves the available paths from the root of the tree to a given object.
       *
       * @param objectId the id of the object to which the available paths will be retrieved
       * @returns a promise which is resolved with an array of available paths
       */
      function getPathsToObject(objectId) {
         //prepare parameters for the getPath request to the server
         var params = {
            treeId: treeId,
            objRef: objectId
         };
         //in case the root of the tree is not a fixed object
         if (rootObjectId) {
            params.rootRef = rootObjectId;
         }

         //call the server to provide available paths
         return treeNodeService.getPath(params).then(checkIfViewExists);
      }

      /**
       * Retrieves the immediate parent data item based on the available paths.
       * If a parent is not expanded it is marked for a reload.
       *
       * An available path is an array of ids starting from the tree root (being the first element)
       * to a given item (being the last item).
       *
       * @param data array with available paths
       * @returns the parent data item or null (if that parent is not loaded or not expanded)
       */
      function getParentFromPath(data) {
         var path, allAvailablePaths,
               objectId, parent, parentId;

         //just return if the server hasn't found valid paths
         if (!data || !angular.isArray(data.paths) || !data.paths.length) {
            return;
         }
         allAvailablePaths = data.paths;

         //make a shallow copy of the paths
         allAvailablePaths = allAvailablePaths.slice();

         while (allAvailablePaths.length) {
            path = allAvailablePaths.shift();

            //make a shallow copy of the path
            path = path.slice();

            //We pop the last item because we are searching for its parent
            objectId = path.pop();

            while (path.length) {
               parentId = path.shift();
               parent = getDataItem(kendoTreeView, parentId);

               //if a parent does not exist this means that this is either a non valid path
               //or the parent is not currently loaded in the tree
               //In both cases break further walk through and let examine another available path
               if (!parent) {
                  break;
               }

               //if a parent is not expanded then mark that parent for a reload
               //and break further walk through and let examine another available path
               if (!parent.expanded) {
                  prepareForLoading(parent);
                  break;
               }

               //there are no other check to be done for the parents
               //but we are interested in the immediate(first level) parent
               //so continue with next in the list
               if (path.length) {
                  continue;
               }

               return parent;
            }
         }

         return null;
      }

      /**
       * Gets the children for a given node from the server and then calls diffUpdateNodeChildren.
       * Getting the children from the server is optimized by a debounce technique (see _ js)
       *
       * @param parent the parent data item
       */
      function getChildrenForDiffUpdate(parent) {
         var deferred = $q.defer();

         function getChildrenForDiffUpdateInternal() {
            treeNodeService
               .getChildren(treeId, parent.nodeTypeId, parent.objRef)
               .then(checkIfViewExists)
               .then(function(children) {
                  diffUpdateNodeChildren(parent, children).then(deferred.resolve);
               })
               .catch(logLog);
         }

         if (!parent) {
            deferred.resolve();
         } else {
            if (!isOptimizedGetChildren) {
               getChildrenForDiffUpdateInternal();
            } else {
               // Debounce getting data for the same parent
               var key = parent.objRef;
               if (delayedGetChildren[key]) {
                  $timeout.cancel(delayedGetChildren[key]);
               }

               delayedGetChildren[key] = $timeout(function() {
                  delayedGetChildren[key] = null;
                  getChildrenForDiffUpdateInternal();
               }, GET_CHILDREN_DELAY);
            }
         }

         return deferred.promise;
      }

      /**
       * Updates the children of a given node.
       * The function takes two parameters a data item (the parent) and its latestChildren.
       * Then a diff is performed between the existing children and the latestChildren.
       * The diff is used to apply the relevant modifications.
       *
       * @param parent the parent data item which children will be updated
       * @param latestChildren a list of the latest children
       */
      function diffUpdateNodeChildren(parent, latestChildren) {
         var deferred = $q.defer();

         if (!parent) {
            logLog('diffUpdateNodeChildren', 'parent is required');
         }
         if (!angular.isArray(latestChildren)) {
            logLog('diffUpdateNodeChildren', 'latestChildren should be array');
         }

         var parentNode = kendoTreeView.findByUid(parent.uid);
         if (!kendoTreeView.dataItem(parentNode)) {
            return deferred.promise;
         }

         var oldChildren = parent.children && _.isFunction(parent.children.data) ?
            parent.children.data() :
            [];
         var latestChildrenByRef = _.reduce(latestChildren, function (acc, x) {
            acc[x.objRef] = x;
            return acc;
         }, {});

         var removedChildren = [];
         var promises = [];
         _.each(oldChildren, function (x) {
            if (!latestChildrenByRef[x.objRef]) {
               removedChildren.push(x);
               // An item has been removed, but this may also mean moved, so lets update
               // around it but do it after the current diff update has finished, i.e.
               // use timeout.
               promises.push($timeout(null, 0).then(function () {
                  return updateAround(x.objRef);
               }));
            }
         });

         _.each(removedChildren, function (x) {
            kendoTreeView.remove(kendoTreeView.findByUid(x.uid));
         });

         var isFirstChild = true;
         _.each(latestChildren, function (curr, i) {
            var existingItem = getDataItem(kendoTreeView, curr.objRef);
            if (existingItem) {
               if (existingItem.spriteCssClass !== curr.spriteCssClass) {
                  existingItem.set('spriteCssClass', curr.spriteCssClass);
               }
               if (isStructuralUpdate(existingItem, curr)) {
                  if (existingItem.text !== curr.text) {
                     existingItem.set('text', curr.text);
                  }
                  if (isFirstChild) {
                     insertFront(existingItem, parentNode, ITEM_MOVE);
                  } else {
                     insertAfter(existingItem, latestChildren[i - 1], ITEM_MOVE);
                  }
               }
            } else {
               if (isFirstChild) {
                  insertFront(curr, parentNode, ITEM_NEW);
               } else {
                  insertAfter(curr, latestChildren[i - 1], ITEM_NEW);
               }
            }

            if (isFirstChild) {
               isFirstChild = false;
            }
         });

         $q.all(promises).then(deferred.resolve);

         return deferred.promise;
      }

      /**
       * Checking whether after the change of the item's state tree elements should be rearranged
       *
       * @param oldItem - current value of the tree item
       * @param newItem - updated value of the tree item
       * @returns {boolean}
       */
      function isStructuralUpdate(oldItem, newItem) {
         return oldItem.text !== newItem.text ||
             oldItem.isVmTemplate !== newItem.isVmTemplate;
      }

      function insertFront(item, parentNode, opType) {
         var firstNode = parentNode.find('.k-item:first');
         if (opType === ITEM_MOVE) {
            var firstDataItem = kendoTreeView.dataItem(firstNode);
            // position did not change, do nothing
            if (firstDataItem && firstDataItem.objRef === item.objRef) {
               return;
            }
            item = kendoTreeView.findByUid(item.uid);
            if (!kendoTreeView.dataItem(item)) {
               return;
            }
         }

         if (firstNode.size()) {
            kendoTreeView.insertBefore(item, firstNode);
         } else {
            kendoTreeView.append(item, parentNode);
         }
      }

      function insertAfter(item, afterItem, opType) {
         if (opType === ITEM_MOVE) {
            item = kendoTreeView.findByUid(item.uid);
            if (!kendoTreeView.dataItem(item)) {
               return;
            }
         }
         var lastItem = kendoTreeView.dataSource.get(afterItem.objRef);
         if(!lastItem){
            return;
         }
         var lastDataItem = kendoTreeView.findByUid(lastItem.uid);
         if (!kendoTreeView.dataItem(lastDataItem)) {
            return;
         }
         kendoTreeView.insertAfter(item, lastDataItem);
      }

      function checkIfViewExists(data) {
         return kendoTreeView.element ?
            $q.resolve(data) :
            $q.reject('KendoTreeView does not exist.');
      }
   }

   /**
    * Removes the relevant node from the kendo tree.
    * If the node is the currently selected one, then its parent is automatically selected.
    *
    * @param item the data item(model) or its id that describes the node
    */
   function removeItemFromTree(kendoTreeView, item) {

      //if the item is string then gets the relevant model
      var model;
      if (angular.isString(item)) {
         model = getDataItem(kendoTreeView, item);
      }

      if (!model) {
         return;
      }

      var node = kendoTreeView.findByUid(model.uid);
      if (!node || !node.length) {
         $log.debug("treeUpdatesHandler#removeItemFromTree: no tree node found for item with uid '" + model.uid + "'");
         return;
      }

      var selectedNode = kendoTreeView.select();
      if (node.get(0) === selectedNode.get(0)) {
         selectedNode = kendoTreeView.parent(node);
         kendoTreeView.select(selectedNode);
      }
      $log.debug("treeUpdatesHandler#removeItemFromTree: removing '"  + node.text() + "'");
      kendoTreeView.remove(node);
   }

   /**
    * Removes all elements of given type from kendo tree.
    * If among the removed nodes is the currently selected one, then its parent is automatically selected.
    *
    * @param kendoTreeView
    * @param nodeTypes types of nodes to be hidden
    */
   function removeItemsOfTypesFromTree (kendoTreeView, nodeTypes) {
      if (!kendoTreeView.element) {
         return Promise.resolve(false);
      }
      var vmLiElems = kendoTreeView.element.find(NODETYPE_VM_CLASSES).closest("li");
      var selectedNode = kendoTreeView.select();
      var selectedElem = selectedNode && selectedNode.get(0);
      if (selectedElem && _.contains(vmLiElems, selectedElem)) {
         kendoTreeView.select(kendoTreeView.parent(selectedNode));
      }
      return chunkExecutor.executeOnChunks(function(node) {
         kendoTreeView.remove(node);
      }, vmLiElems, 250, 1000);

   }

   /**
    * Gets the data item (model) with the specified id from the kendo tree
    *
    * @param kendoTreeView the kendo tree view
    * @param itemId The id of the model to look for.
    * @returns kendo.data.Model the model instance or undefined if a model with the specified id is not found.
    *
    * @see http://docs.telerik.com/kendo-ui/api/javascript/data/datasource#methods-get
    */
   function getDataItem(kendoTreeView, itemId) {
      var dataItem = null;
      //try-catch needed to overcome a kendo bug
      //observed when 'loadingRootNode' and the root node is being expanded
      try {
         dataItem = kendoTreeView.dataSource.get(itemId);
      } catch (ex) {
      }

      return dataItem;
   }

   function getSupportedTypes(treeId) {
      var deferred = $q.defer();
      if (supportedTypesByTreeId.hasOwnProperty(treeId)) {
         deferred.resolve(supportedTypesByTreeId[treeId]);
      } else {
         treeNodeService.getSupportedTypes(treeId).then(function (result) {
            supportedTypesByTreeId[treeId] = result;
            deferred.resolve(result);
         });
      }
      return deferred.promise;
   }

   function getSpecificFolderType(moRefValue) {
      if (moRefValue === ROOT_FOLDER_VALUE) {
         return ROOT_FOLDER_TYPE;
      }
      var key = _.find(Object.keys(SPECIFIC_FOLDER_TYPES), function (key) {
         return (moRefValue.indexOf(key) !== -1);
      });
      // Returns the specific folder type if key is found, otherwise returns null.
      return ((key && SPECIFIC_FOLDER_TYPES[key]) || null);
   }

   function updateNodeState(item, opts) {
      if (!item) {
         return;
      }
      if (opts.icon) {
         item.set('spriteCssClass', opts.icon);
      }
      if (opts.name) {
         var fullName = opts.name;
         if (opts.labelIds) {
            fullName += resourceUtil.getTaggingLabelString(opts.labelIds);
         }
         item.set('text', fullName);
      }
      if (opts.isVmTemplate !== null && opts.isVmTemplate !== undefined) {
         item.set('isVmTemplate', opts.isVmTemplate);
      }
   }

   function prepareForLoading(node) {
      node.hasChildren = true;
      node.loaded(false);
      node.load();
   }
})();
