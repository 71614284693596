/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   /**
    * Service to alter the browser window history title (inherently the document.title) on each router (UR) change.
    */
   angular.module('com.vmware.platform.ui').factory('browserHistoryService',
   ['$document', 'i18nService', function ($document, i18nService) {
      var savedObjectId, savedObjectName, savedViewName, savedAdditionalText;
      var productName =  i18nService.getString('Common', 'productName');
      var productNameWithSucceedingDash = productName + " - " ;

      return {
         setBrowserTitle: setBrowserTitle,
         notifyCurrentObjNameChanged: notifyCurrentObjNameChanged
      };

      /**
       * Sets the browser title to the title like
       * '<objectName> - <viewName>[: "<additionalText>"] - <productName>'
       * based on the current context. The additionalText may or may not be present.
       *
       * @param tree
       *    Navigation Tree
       * @param objectId
       *    Optional objectId
       * @param additionalText
       *    Optional additional text to be displayed after the view name
       */
      function setBrowserTitle(tree, objectId, additionalText) {
         if (!($document) || !($document[0])) {
            return;
         }
         if (objectId) {
            setTitleWithObject(tree, objectId, additionalText);
            return;
         }
         setTitle(null, tree, additionalText);
      }

      /**
       * Gives the name for the object with objectId .
       * @param objectId
       *    Object Identifier whose name is given in second param
       * @param objectName
       *    Name of the object.
       */
      function notifyCurrentObjNameChanged(objectId, objectName) {
         if (savedObjectId === objectId) {
            savedObjectName = objectName;
            setTitle(savedObjectName);
         }
      }

      // Private functions
      /**
       * Sets the title of the browser given a tree and objectId.
       * If the savedObjectId is the same as the objectId given, the savedObjectName is used in the title
       * else the we cache the new objectId and clean up the savedObjectName and show nothing for object name
       * in the title.
       * @param tree - NavigationTree
       * @param objectId - objectId
       * @param additionalText
       */
      function setTitleWithObject(tree, objectId, additionalText) {
         if (objectId) {
            if (savedObjectId !== objectId) {
               savedObjectId = objectId;
               savedObjectName = null;
            }
            setTitle(savedObjectName, tree, additionalText);
         }
      }

      function setTitle(partialTitle, tree, additionalText) {
         // The general title format is:
         // <product name> - <partialTitle> - <view name>[: <additionalText>].
         var title = "";
         if (partialTitle) {
            title = partialTitle;
         }
         if (tree) {
            savedViewName = getSelectedLeafNodeName(tree);
            savedAdditionalText = additionalText;
         }
         if (isValidNodeName(savedViewName)) {
            if (title) {
               title += " - " + savedViewName;
            } else {
               title = savedViewName;
            }
         }
         if (title) {
            title = productNameWithSucceedingDash + title;
         } else {
            title = productName;
         }
         if (additionalText) {
            savedAdditionalText = additionalText;
         }
         if (savedAdditionalText) {
            title += ': "' + savedAdditionalText + '"';
         }
         if ($document && $document[0]) {
            $document[0].title = title;
         }
      }

      /**
       * Gets the name of the lead node of the NavigationTree.
       * In case of relatedObjectViews, label is used.
       *
       * @param tree - NavigationTree
       */
      function getSelectedLeafNodeName(tree)  {
         var leafNode = tree.getSelectedLeafNode();
         if (leafNode) {
            if (leafNode.name) {
               return leafNode.name;
            } else {
               return leafNode.label;
            }
         }
         return "";
      }

      function isValidNodeName(name) {
         // This, alas, is a hack. Blame it on the genius decision to
         // use a random string for the name of a non-existing leaf node.
         // Otherwise the 'missing name' creeps into our browser history.
         // Unlocalized. Because it is hard-coded.
         return name && name !== '(missing name)';
      }
   }]);

})();
