module platform {

   import IPromise = angular.IPromise;
   import IQService = angular.IQService;
   import IHttpService = angular.IHttpService;
   import IDeferred = angular.IDeferred;

   /**
    * Represents possible directions of sorting list's data.
    */
   type  SortDirection = "asc" | "desc";

   /**
    * Represents a sorting by a single column in list.
    */
   export interface ListSorting {
      column: string;
      dir: SortDirection;
   }

   export interface ListOptions {
      columnDataSourceInfos: Array<ColumnDataSourceInfo>;
      sort?: ListSorting[];
      metadata: any;
   }

   /**
    * Data source properties for column.
    */
   export interface ColumnDataSourceInfo {
      uid: string;
      /**
       * Display name for the column.
       */
      headerText: string;
      /**
       * Properties that are requested for column.
       */
      requestedProperties: string[];
      /**
       *  List of resource models.
       *  Need not be set for VMODL1 objects.
       */
      resourceModels: string[];
      /**
       * Width of a column in px.
       */
      width: number;
      /**
       *  If set to false the column will be hidden
       */
      visible?: boolean;
      /**
       * Specifies a renderer for the column.
       */
      columnRenderer: string;
      /**
       * This property contains key/value pairs that will be received by
       * the columnRenderer.
       *
       * To know what values can be present, you should check whether the renderer
       * supports any configuration at all. Check the specific renderer
       * documentation (or source).
       */
      columnRendererConfig?: any;
      /**
       * Property used to sort on column.
       */
      sortProperty?: string;
      /**
       * Property that is used to export column.
       */
      exportProperty?: string;
      /**
       * The default sort column has this
       * property set to true.
       */
      sortedByDefault: string;
      /**
       *  If this is set to true filtering on the column will be enable.
       */
      searchable?: boolean;
   }

   export class ListViewColumnService {

      static $inject = ['$q', 'localStorageService', 'dataService',
         '$timeout', '$http', 'logService', 'defaultUriSchemeUtil', 'vcService',
         'telemetryService'];
      private log: any;

      private constructor(private $q: IQService,
                          private localStorageService: any,
                          private dataService: any,
                          private $timeout: any,
                          private $http: IHttpService,
                          private logService: any,
                          private defaultUriSchemeUtil: any,
                          private vcService: any,
                          private telemetryService: any) {
         this.log = this.logService('listViewColumnService');
      }

      // Custom Attributes are applicable only for the following list view types
      private vmomiListViewTypes: String[] = [
         "VirtualMachine",
         "HostNetwork",
         "AnyNetwork",
         "Network",
         "AnyDistributedVirtualSwitch",
         "DistributedVirtualSwitch",
         "VMwareDistributedVirtualSwitch",
         "DistributedVirtualPortgroup",
         "OpaqueNetwork",
         "Datastore",
         "StoragePod",
         "AnyStorage",
         "Folder",
         "VirtualApp",
         "HostSystem",
         "ClusterComputeResource",
         "ComputeResource",
         "AnyComputeResource",
         "ResourcePool",
         "Datacenter"
      ];


      private customAttributeObjectType: string = 'CustomFieldDef';

      /**
       * Returns persisted column definitions for the specified listView
       * @param listViewId The id of the view, which contains the grid, with
       * these columns
       * @returns a promise, resolved with an array of kendo grid columns
       * objects
       */
      getPersistedColumnsDef(listViewId: string): any {
         return this.getPersistedData(listViewId);
      }

      /**
       * Retrieves columns definitions with applied persisted column states
       * @param columnDefs Array of kendo grid column configuration objects,
       * @param persistedColumnsDefs Array of persisted columns states
       * @param listViewId The id of the view, which contains the grid, with
       * these columns
       * @returns an array, with column definition objects, modified
       * according to the persisted states of the list columns.
       */
      createListColumnsDefs(columnDefs: any, persistedColumnsDefs: any,
                            listViewId: string): any[] {
         this.fallBackUidsToHeaderText(columnDefs);
         const columnsMap = _.indexBy(columnDefs, 'uid');
         return this.applyPersistedColumnDefinitions(
               columnsMap, persistedColumnsDefs, listViewId);
      }

      /**
       * Modifies the array of column def objects passed as input parameter
       * as following: assigns uid property to each column def object which
       * does not already have one. The value set to uid property will be the
       * value of displayName property of the same column def object.
       * This provides a fallback mechanism to legacy mechanism of identifying
       * column defs before introduction of uid property.
       * @param columnDefs Array of kendo grid column configuration objects
       */
      private fallBackUidsToHeaderText(columnDefs: any) {
         for (let i in columnDefs) {
            let columnDef = columnDefs[i];
            if(columnDef.uid === undefined) {
               columnDef.uid = columnDef.displayName;
            }
         }
      }

      /**
       * Determines if passed entity id is custom attribute id.
       * @param id
       * @returns {boolean}
       */
      private isValidCustomAttrId(id: string): boolean {
         if (!id) {
            return false;
         }
         return this.vcService.isValidManagedEntityId(id) &&
               this.defaultUriSchemeUtil.getEntityType(id) === this.customAttributeObjectType;
      }

      /**
       * Saves the current column configuration of a given ListView in
       * the local storage.
       *
       * @param listViewId
       *    The id of the list view.
       * @param columnDefs
       *    Kendo grid column objects.
       */
      saveColumnDefs(listViewId: string, columnDefs: any[], objectId: string): void {
         const listViewData: any = {};
         let serverGuid: string;
         if (objectId && this.vcService.isValidManagedEntityId(objectId)) {
            serverGuid = this.defaultUriSchemeUtil
                  .getPartsFromVsphereObjectId(objectId).serverGuid;
         }

         // Converts kendo column properties to ColumnDataSourceInfo properties
         // title => headerText
         // hidden (true) => visible (false)
         // width ('100px') => width (100)
         listViewData.columns = _.map(columnDefs, (kendoColumn: any) => {
            if (kendoColumn === null) {
               return {};
            }
            const width = parseInt(kendoColumn.width, 10);

            return {
               headerText: kendoColumn.title,
               visible: kendoColumn.hidden !== true,
               width: width,
               uid: kendoColumn.uid
            };
         });

         this.getPersistedColumnsDef(listViewId).then((data: any) => {
            if (!data) {
               // Save current column configuration.
               this.setPersistedData(listViewId, listViewData);
               return;
            }
            // If there is persisted data we should keep currently persisted custom
            // attribute columns for other vCenters.
            const customAttrColumns: any = _.filter(data.columns, (column: any) => {
               if (!serverGuid || !this.isValidCustomAttrId(column.uid)) {
                  return false;
               }
               const customAttributeGuid = this.defaultUriSchemeUtil
                     .getPartsFromVsphereObjectId(column.uid).serverGuid;
               return this.isLinkedVcList(listViewId) ?
                  customAttributeGuid === serverGuid : customAttributeGuid !== serverGuid;
            });

            _.each(customAttrColumns, (customAttibuteColumn: any) => {
               let alreadyExist: boolean = _.some(listViewData.columns, (column: any): boolean => {
                  return column.uid === customAttibuteColumn.uid;
               });
               if (!alreadyExist) {
                  listViewData.columns.push(customAttibuteColumn);
               }
            });

            if (data.sorting) {
               listViewData.sorting = data.sorting;
            }

            // Save current column configuration.
            this.setPersistedData(listViewId, listViewData);
            this.telemetryService.trackEvent("listView", "columns", JSON.stringify(listViewData.columns));
         });
      }

      saveGridState(listViewId: string, columnDefs: any, gridSorting: ListSorting): void {
         let listViewData = <any> {};
         if (gridSorting) {
            listViewData.sorting = gridSorting;
         }

         if (columnDefs && columnDefs.columns) {
            listViewData.columns = columnDefs.columns;
         }
         this.setPersistedData(listViewId, listViewData);
      }

      /**
       * Retrieves persisted column definitions for the specified listView
       * and applies them on the supplied column definitions
       * @param columnDefs Array of kendo grid column configuration objects
       * @param listViewId The id of the view, which contains the grid, with
       * these columns
       * @returns a promise, resolved with an array of kendo grid column
       * definition objects, modified according to the persisted state
       * for the view.
       */
      applyPersistedState(columnDefs: any[], listViewId: string): any[] {
         this.fallBackUidsToHeaderText(columnDefs);
         return this.getPersistedData(listViewId).then((persistedData: any) => {
            if (persistedData && persistedData.columns) {
               const columnDefsByUid = _.indexBy(
                     columnDefs, 'uid');
               return this.applyPersistedColumnDefinitions(
                     columnDefsByUid, persistedData.columns, listViewId);
            }
            return columnDefs;
         });
      }

      /**
       * Creates an event handler that takes a kendo grid event
       * and persists the column definitions in it after a 100ms timeout.
       * @param viewId Extention ID of the view the grid resides in.
       * @param $scope Angular $scope of the grid view. Used to cancel the
       * debouncing timeout if the scope is destroyed intermittently.
       * @returns {Function} kendoGrid column -hide, -reorder, -resize, -show
       * event handler.
       */
      getColumnChangeHandler(viewId: string, $scope: any): Function {
         let timeoutPromise: any = null;
         // Cancel and nullify the promise when the scope is destroyed.
         $scope.$on('destroy', () => {
            if (timeoutPromise) {
               this.$timeout.cancel(timeoutPromise);
               timeoutPromise = null;
            }
         });
         const callback: Function = (event: any) => {
            // Use a timeout in order to allow the grid to apply the column
            // changes and debounce frequent requests
            if (timeoutPromise) {
               this.$timeout.cancel(timeoutPromise);
            }
            timeoutPromise = this.$timeout(
                  () => {
                     // event.sender.columns array will now hold the updated column definitions.
                     this.saveColumnDefs(viewId, event.sender.columns, $scope.objectId);
                  }, 100);
         };

         return callback;
      }

      /**
       * For global lists gets custom attribute fields for all vCenters, for context
       * object lists gets custom attributes only for vCenter where context object belongs
       * @param listViewType
       * @param objectId
       * @param listViewId
       * @returns {*|IPromise<_.Dictionary<_.Dictionary<any>>>}
       */
      getCustomAttributeFields(listViewType: string,
            objectId: string, listViewId: string) {
         const defer = this.$q.defer();
         if (this.vmomiListViewTypes.indexOf(listViewType) === -1) {
            return this.$q.resolve({});
         }
         const criteria = [{
            property: 'applicableType',
            operator: 'EQUAL',
            comparableValue: listViewType
         }];
         const isContextObjectListForVcObject =
               objectId && this.vcService.isValidManagedEntityId(objectId);
         // If objectId is present then this is a context list -> limit custom attr
         // only to those present in the vCenter where context object belongs.
         // The only exception is LinkedVcList, which still requires retrieving
         // custom attributes from all vCenters.
         if (isContextObjectListForVcObject && !this.isLinkedVcList(listViewId)) {
            const serverGuid: string = this.defaultUriSchemeUtil
                  .getPartsFromVsphereObjectId(objectId).serverGuid;
            if (serverGuid) {
               criteria.push({
                  property: '@instanceUuid',
                  operator: 'EQUAL',
                  comparableValue: serverGuid
               });
            }
         }
         const filterSpec = {
            criteria,
            operator: "AND"
         };
         return this.dataService.getPropertiesByFilter(filterSpec,
               ['key', 'name'], ['CustomFieldDef']);
      }

      appendCustomAttributeColumnDefs(columnDefs: any, customAttibuteFields: any): any[] {
         if (customAttibuteFields) {
            _.map(customAttibuteFields, (customAttr: any, iteratee: any) => {
               const customAttrId =
                     this.defaultUriSchemeUtil.getManagedObjectReference(iteratee);
               // append column defintions for each custom attribute
               columnDefs.push({
                  headerText: customAttr.name,
                  requestedProperties: ['customValue', '@instanceUuid'],
                  visible: false,
                  exportProperty: customAttrId.serverGuid + ":" + customAttr.key,
                  columnRenderer: 'custom-attr',
                  width: 200,
                  columnRendererConfig: {
                     key: customAttr.key,
                     serverGuid: customAttrId.serverGuid
                  },
                  uid: iteratee
               });
            });
         }
         return columnDefs;
      }

      getListOptions(listViewId: string, listViewType: string, objectId: string): IPromise<ListOptions> {
         let defer: IDeferred<any> = this.$q.defer();

         let listOptionsDefsPromise: IPromise<ListOptions> =
            this.fetchListOptions(listViewId, listViewType, objectId);
         let persistedColumnsDataPromise: IPromise<any> =
            this.getPersistedColumnsDef(listViewId);

         let requests = [
            listOptionsDefsPromise,
            persistedColumnsDataPromise
         ];

         if (listViewType) {
            let customAttributeColumns = this.getCustomAttributeFields(listViewType, objectId, listViewId);
            requests.push(customAttributeColumns);
         }

         this.$q.all(requests).then(
            (promiseResults) => {
               if (!promiseResults || !promiseResults[0]) {
                  defer.resolve({
                     columnDataSourceInfos: [],
                     metadata: {}
                  });
                  return;
               }
               // holds list options with column definitions coming
               // from the plugin.xml files
               const listOptions: ListOptions = promiseResults[0];
               listOptions.columnDataSourceInfos =
                  this.appendCustomAttributeColumnDefs(
                     listOptions.columnDataSourceInfos, promiseResults[2]);

               if (!promiseResults[1] || (!promiseResults[1].columns && !promiseResults[1].sorting)) {
                  // There is no user-data for this listView,
                  // return list options without any
                  // modifications.
                  defer.resolve(listOptions);
               } else  {
                  if (promiseResults[1].sorting) {
                     const persistedGridSorting: ListSorting = promiseResults[1].sorting;
                     listOptions.sort = this.createListSorting(listOptions.columnDataSourceInfos, persistedGridSorting);
                  }

                  if (promiseResults[1].columns) {
                     // holds all persisted column definition in the
                     // local storage.
                     const persistedColumnsDefs = promiseResults[1].columns;

                     listOptions.columnDataSourceInfos =
                        this.createListColumnsDefs(
                           listOptions.columnDataSourceInfos,
                           persistedColumnsDefs,
                           listViewId);
                  }

                  defer.resolve(listOptions);
               }
            });
         return defer.promise;
      }

      private fetchListOptions(listViewId,
                               listViewType, objectId): IPromise<ListOptions> {
         let params: any = {};
         if (angular.isDefined(listViewType) && listViewType !== null) {
            params.listViewType = listViewType;
         }
         if (angular.isDefined(objectId) && objectId !== null) {
            params.objectId = objectId;
         }
         return this.$http({
            method: 'get',
            url: 'list/listOptions/' + listViewId,
            params: params
         }).then(function (response) {
            return response.data;
         });
      }

      private getListViewColumnDefKey(listViewId: string): string {
         return listViewId + '_ListViewColumnDef';
      }

      private isLinkedVcList(listViewId: string) {
         return listViewId === 'vsphere.core.vc.list';
      }

      /**
       * Applies persisted columns state on column definitions
       * @param columnDefsByHeaderText plugin.xml OR kendo grid formed column
       * definitions, mapped by their header
       * @param persistedColumnDefs columns state, as persisted in the
       * persistence service
       * @returns {Array} column definitions, either ColumnDataSourceInfo
       * objects, or kendo grid column configurations
       */
      private applyPersistedColumnDefinitions(
            columnDefsById: any, persistedColumnDefs: any, listViewId: string): any[]  {
         const columnsMap = {};
         const result: ColumnDataSourceInfo[] = [];

         // We need to update column definitions with the persisted user-data by:
         // - change the order of the columns based on the persisted data
         // - change the width of the columns with the persisted column widths
         // - change the visible field of the columns with the persisted value
         let i;

         // Iterate over the persisted column definitions to get the columns in
         // the right order.
         for (i = 0; i < persistedColumnDefs.length; i++) {
            const persistedColumnDef = persistedColumnDefs[i];
            // get the 'plugin.xml' definition for the current column
            const columnDef = columnDefsById[persistedColumnDef.uid];
            if (!columnDef) {
               if (persistedColumnDef.uid) {
                  this.log.log(
                     "Missing column definition:", " uid: " + persistedColumnDef.uid +
                     " title: " + persistedColumnDef.headerText + ";",
                     "List view id:", listViewId);
               }
               // if it's missing, we skip the column.
               continue;
            }

            // Update the width of the column with the persisted width.
            if (persistedColumnDef.width !== null && !isNaN(persistedColumnDef.width)) {
               columnDef.width = persistedColumnDef.width;
            }

            // Update the visibility of the column based on the persisted data.
            if (persistedColumnDef.visible !== null) {
               columnDef.visible = persistedColumnDef.visible;
            }

            columnsMap[persistedColumnDef.uid] = true;
            result.push(columnDef);
         }

         // Finally, we need to add all columns from the columnDefs for which
         // there is no persisted data.
         _.keys(columnDefsById).forEach((columnId: string) => {
            if (columnsMap[columnId] === true) {
               // the column is already added in the result array.
               return;
            }
            result.push(columnDefsById[columnId]);
         });

         return result;
      }

      /**
       * Retrieves the data from the persistence storage.
       * @returns a promise which when resolved will contain the ListView columns data.
       */
      private getPersistedData(listViewId: string) {
         return this.localStorageService.getUserData(this.getListViewColumnDefKey(listViewId));
      }

      /**
       * Preserve data in the persistence storage.
       * @param listViewId the id of the ListView
       * @param data the data that is going to be persisted.
       */
      private setPersistedData(listViewId: string, data: any) {
         //save into the userdata, using a key based on the listViewId
         this.localStorageService.setUserData(
               this.getListViewColumnDefKey(listViewId), data);
      }

      // Returns the an array of ListSorting object. Currently only a SINGLE columns sorting is supported, so the resulting
      // array's length will be maximum 1 if we have sorting, or 0 in case list is unsorted.
      // In case persisted sorting column is still active method returns it as a single result. Otherwise
      // the default sorting column from column definitions is returned as a single result again.
      // In case no sorting is found method returns empty array.
      private createListSorting(columnDefs: ColumnDataSourceInfo[], persistedListSorting: ListSorting): ListSorting[]  {
         const listSorting: ListSorting[] = [];
         for (let columnDataSourceInfo of columnDefs) {
            if (persistedListSorting.column === columnDataSourceInfo.sortProperty) {
               return [persistedListSorting];
            }
            if (columnDataSourceInfo.sortedByDefault && columnDataSourceInfo.sortedByDefault !== 'false') {
               listSorting.push(<ListSorting> {
                  column: columnDataSourceInfo.sortProperty,
                  dir: "asc"
               });
            }
         }
         return listSorting;
      }

   }

   angular.module('com.vmware.platform.ui').service(
         'listViewColumnService'
         , ListViewColumnService
   );
}
