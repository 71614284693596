/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * @ngdoc service
 * @name com.vmware.platform.ui:vxMultiPageDialogService
 * @module com.vmware.platform.ui
 *
 * @description
 *    Service to create instance of a multi page dialog. The service allows
 *    you to create, show, hide and destroy the dialog.
 *
 * # General usage
 *    The `vxMultiPageDialogService` is a function which takes a single argument - a
 *    configuration object - that is used to create a multi page dialog.
 *
 * @param {Object} config
 *    Object containing the scope and the configObjectName.
 *
 *    - **scope** – `{$scope}` – Angular scope object of the dialog container.
 *       It can be the scope of the controller or rootScope or a custom scope. The scope
 *       of each page in the dialog prototypically inherits from the scope that is
 *       mentioned here.
 *    - **configObjectName** – `{string}` – The name of the scope variable
 *       declared on the above mentioned scope. The variable is of type
 *       {@link com.vmware.platform.ui:MultiPageDialogOptions MultiPageDialogOptions}.
 *
 *    Example:
 *    ```js
 *    var config = {
 *                     scope: $scope,
 *                     configObjectName: 'dialogOptions'
 *                 };
 *    ```
 *
 * @returns {Object}
 *    Returns a response object which has these methods:
 *
 *    - **show()** – `{Function}` To display the dialog.
 *    - **hide()** – `{Function}` To hide the dialog.
 *    - **destroy()** – `{Function}` To destroy the dialog.
 */
angular.module('com.vmware.platform.ui').provider('vxMultiPageDialogService', [
   function() {
      this.$get = function ($rootScope, $document, $q, $timeout, vuiModalService,
            jsUtils, vuiConstants, vuiLocale, vxModalKeyService) {

         function MultiPageDialogFactory(config) {
            var dialogManager;
            var outerScope = config.scope;
            var scope = outerScope.$new();
            scope.multiPageDialog = config.scope[config.configObjectName];
            scope.isInitialized = false;

            var defaultConfirmOptions = {
               label: vuiLocale.ok,
               disabled: false,
               confirmOnEnter: true
            };

            var defaultRejectOptions = {
               label: vuiLocale.cancel,
               visible: true,
               rejectOnEsc: true
            };

            angular.extend(scope.multiPageDialog,
                  angular.extend({
                           maximizable: true,
                           resizable: true,
                           draggable: true,
                           resizeMinWidth: 700,
                           resizeMinHeight: 420
                        },
                        scope.multiPageDialog
                  )
            );

            var temp = {};
            angular.extend(temp, defaultConfirmOptions, scope.multiPageDialog.confirmOptions);
            scope.multiPageDialog.confirmOptions = temp;
            var confirmOptions = scope.multiPageDialog.confirmOptions;
            temp = {};
            angular.extend(temp, defaultRejectOptions, scope.multiPageDialog.rejectOptions);
            scope.multiPageDialog.rejectOptions = temp;
            var rejectOptions = scope.multiPageDialog.rejectOptions;

            var dialogTemplate =
               '<div class="vui-wizard default-text" vui-focus-trap ng-style="multiPageDialog.getStyle()"' +
                     'role="dialog" tabindex="0" vui-focus-on="vuiModalOpened">' +
                  '<div class="wizard-modal-titlebar">' +
                     '<span class="titlebar-left-icons">' +
                        '<span ng-if="multiPageDialog.iconClass" class="vui-icon" ' +
                              'ng-class="multiPageDialog.iconClass">' +
                        '</span>' +
                     '</span>' +
                     '<span class="titlebar-text" title="{{multiPageDialog.title}}">' +
                        '{{multiPageDialog.title}}</span>' +
                 '</div>' +
                  '<div class="wizard-modal-body" ng-if="multiPageDialog.show">' +
                     '<vx-multi-page-dialog-toc></vx-multi-page-dialog-toc>' +
                     '<div class="wizard-content-container" role="main" tabindex="0">' +
                        '<div vui-validation-banner="multiPageDialog.validationBanner"></div>' +
                        '<div class="wizard-content"' +
                           'ng-include="dialogPages[dialogManager.currentIdx].contentUrl"></div>' +
                     '</div>' +
                     '<div class="progress-bordered progress-centered" ng-if="multiPageDialog.loading">' +
                        '<vx-progress message="{{multiPageDialog.loadingMessage}}" ' +
                              'loading-aria-label="{{multiPageDialog.loadingAriaLabel}}">' +
                        '</vx-progress>' +
                     '</div>' +
                  '</div>' +
                  '<div class="wizard-modal-footer">' +
               '<button class="btn" ng-if="multiPageDialog.rejectOptions.visible"' +
                           ' ng-click="multiPageDialog.onCancelClick()">' +
                           '{{multiPageDialog.rejectOptions.label}}</button>' +
                     '<button class="btn btn-primary" ng-click="multiPageDialog.onOkClick()"' +
                           ' ng-disabled="multiPageDialog.confirmOptions.disabled || !isInitialized">' +
                           '{{multiPageDialog.confirmOptions.label}}</button>' +
                     '<span class="wizard-resize-icon-span"></span>' +
                  '</div>' +
               '</div>';

            var setValidationBannerMessages = function (messages) {
               scope.multiPageDialog.validationBanner.messages = messages;
            };

            var clearValidationBanner = function () {
               setValidationBannerMessages([]);
            };

            var isCombobox = function (element) {
               return element && element.getAttribute('role') === 'combobox';
            };

            var isDropdown = function (element) {
               return element && element.getAttribute('role') === 'listbox';
            };

            var escKeyHandler = function ($event) {
               var element = $event.currentTarget.activeElement;
               // Ignore if disabled
               if (rejectOptions.rejectOnEsc !== true) {
                  return;
               }
               // Ignore on vui.angular.combobox and vui.angular.dropdown
               if (isCombobox(element) || isDropdown(element)) {
                  return;
               }

               // Ensure cancel is available
               if (rejectOptions.visible === true) {
                  scope.multiPageDialog.onCancelClick();
               }
            };


            var onKeydownHandler = function ($event) {
               //Run keydown handler in angular zone
               vxModalKeyService.onKeydownHandler($event, escKeyHandler.bind(this, $event));
            };


            // Turn the keypress handler on/off
            var setKeypressHandler = function (state) {
               $document[state]('keydown', onKeydownHandler);
            };

            /**
             * Handles jumping between pages when user clicks on a page in TOC.
             * This function actually validates the page (if page onCommit function is
             * defined) and decides whether to move to the new page that is clicked or
             * to stay on the current page.
             *
             * @param pageIndex The index of the page clicked.
             * @returns Promise Promise with a boolean data indicating whether the move
             *       to the new page is successful or not.
             *
             */
            var tryMoveToPage = function (pageIndex) {
               // Do nothing when user clicks on the same page or a disabled page.
               if (dialogManager.currentIdx === pageIndex ||
                     scope.dialogPages[pageIndex].state === vuiConstants.wizard.pageState.DISABLED) {
                  return $q.resolve(false);
               }

               // Call onCommit of the currentPage when jumping to another page
               return $q.when(onPageCommit()).then(function (result) {
                  var isMoved = false;
                  if (!!result) {
                     updateDialogPageVars(pageIndex);
                     isMoved = true;
                  }
                  return isMoved;
               });
            };

            var updateDialogPageVars = function (pageIndex) {
               dialogManager.currentIdx = pageIndex;
               scope.multiPageDialog.currentPage = scope.dialogPages[pageIndex];
               clearValidationBanner();
            };

            // Holds the onCommit function of the current page
            var onPageCommit = function () {
               var pageCommitFunction = scope.multiPageDialog.currentPage.onCommit;
               if (pageCommitFunction && typeof pageCommitFunction === 'function') {

                  return $q.when(pageCommitFunction()).then(function(result) {
                     if (typeof result === 'boolean') {
                        return result;
                     }

                     if (_.isArray(result) && result.length > 0) {
                        clearValidationBanner();
                        setValidationBannerMessages(result);
                        return false;
                     }

                     // onCommit result is neither boolean nor ValidationBannerMessage[]
                     return true;
                  });

               } else {
                  // onCommit is not set
                  return true;
               }
            };

            var oldFocus;
            /* Attaching to angular event getStyle to put focus on dialog properly,
             * does not work on ngInit or onload because it needs to fire every
             * time the dialog is shown or hidden (i.e. when the style is changed)
             * it is also necessary to wait until the spinnner/progress dialog has
             * fully closed first.
             */
            scope.$watch('multiPageDialog.show', function (val) {
               if (val) {
                  oldFocus = document.activeElement;

                  var waitTillProgressIsGone = function () {
                     $timeout(function () {
                        if (!scope.progress) {
                           scope.progress = $('.modal-spinner');
                        }
                        if (scope.progress.hasClass('ng-hide') || !scope.progress.length) {
                           scope.$broadcast('vuiModalOpened');
                        } else {
                           waitTillProgressIsGone();
                        }
                     }, 100);
                  };
                  waitTillProgressIsGone();
               }
            });

            var restoreFocus = function () {
               if (oldFocus) {
                  oldFocus.focus();
               }
            };

            scope.multiPageDialog.onOkClick = function () {
               $q.when(onPageCommit()).then(function (result) {
                  if (!result) {
                     return;
                  }
                  onOk();
                  restoreFocus();
               });
            };

            /**
             * Calls onClick function of the dialog's confirm options if available.
             */
            var onOk = function () {
               if (confirmOptions && confirmOptions.onClick &&
                     typeof(confirmOptions.onClick) === 'function') {
                  $q.when(confirmOptions.onClick()).then(function (result) {
                     if (!result) {
                        return;
                     }
                     clearValidationBanner();
                     scope.multiPageDialog.show = false;
                  });
               } else {
                  clearValidationBanner();
                  scope.multiPageDialog.show = false;
               }
            };

            scope.multiPageDialog.onCancelClick = function () {
               if (rejectOptions && rejectOptions.onClick &&
                     typeof(rejectOptions.onClick) === 'function') {
                  $q.when(rejectOptions.onClick()).then(function (result) {
                     if (result) {
                        clearValidationBanner();
                        scope.multiPageDialog.show = false;
                     }
                  });
               } else {
                  clearValidationBanner();
                  scope.multiPageDialog.show = false;
               }
               restoreFocus();
            };

            // style for dialog container
            scope.multiPageDialog.getStyle = function () {
               var styles = {};
               if (jsUtils.hasProperty(scope.multiPageDialog, 'width')) {
                  styles.width = scope.multiPageDialog.width;
               }
               if (jsUtils.hasProperty(scope.multiPageDialog, 'height')) {
                  styles.height = scope.multiPageDialog.height;
               }
               return styles;
            };

            // scope.dialogManager contains those properties of the dialog that are
            // required by some of its child components that need to call the dialog
            // functions, such a component is the multi-page-dialog-toc
            scope.dialogManager = {
               currentIdx: 0,
               tryMoveToPage : tryMoveToPage
            };

            var initializeDialog = function() {
               scope.isInitialized = true;

               dialogManager = scope.dialogManager;

               scope.dialogPages = scope.multiPageDialog.pages;
               scope.multiPageDialog.validationBanner = {
                  showAllMessages : false,
                  messages : []
               };
               scope.multiPageDialog.currentPage = {};

               var pageIndex = 0;
               // To handle the case where first page is a skipped page
               while (scope.dialogPages[pageIndex].state === vuiConstants.wizard.pageState.SKIPPED &&
                     pageIndex < scope.dialogPages.length) {
                  pageIndex++;
               }
               updateDialogPageVars(pageIndex);
            };

            config._vuiModalTemplate = dialogTemplate;
            config._outerScopeActual = outerScope;
            config.scope = scope;
            config.showBackdrop = true;
            config.headerElementSelector = '> .wizard-modal-titlebar';
            config.configObjectName = 'multiPageDialog';
            config.zIndex = vuiConstants.internal.zIndex.DEFAULT;

            // set a watch on show to add/remove keystroke listener
            scope.$watch(config.configObjectName + '.show',
               function (newValue, oldValue) {
                  if (newValue ===  false  && oldValue === true) {
                     setKeypressHandler('off');
                  } else if (newValue ===  true) {
                     setKeypressHandler('on');
                  }
               });

            scope.$watch(config.configObjectName + '.loading', function(val) {
               if (!val) {
                  // since the loading might be related to the initial loading of
                  // dialog data, that is why we have to check if the dialog is already
                  // initialized, and if not - initialize it
                  if (scope.isInitialized) {
                     return;
                  }
                  initializeDialog();
               }
            });

            scope.$on('$destroy', function () {
               setKeypressHandler('off');
            });

            return vuiModalService(config);
         }

         return MultiPageDialogFactory;
      };
   }]);
