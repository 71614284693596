/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * A type of container that supports the navigation system.
 *
 * 2 Usages:
 * 1. Navigational view: <vx-view>
 * 2. Non-navigational view: <vx-view="extension">
 *    This form is still preliminary.
 *        Might split into <extension-container="randomExtension"> vs <vx-view="onlyImmidiateChildExtensionAllowed">
 *
 * Originally inspired by ui-view
 */
angular.module('com.vmware.platform.ui').directive(
'vxView',
['navigation', 'extensionFrameworkConstants', '$compile', function(
      navigation, extensionFrameworkConstants, $compile) {
   var SANDBOX_TEMPLATE_URL = 'resources/ui/views/plugins/sandboxTemplate.html';
   var EXTENSION_VIEW_WRAPPER_TEMPLATE =
      '<div class="fill-parent">' +
      '  <div class="fill-parent">' +
      '     <div class="fill-parent" ng-include="_vxViewNodeData.url"' +
      '           vx-extension-view-id="{{_vxViewNodeData.node.$id}}"></div>' +
      '  </div>' +
      '</div>';

   var active = false;
   var directive = {
      restrict : 'ECA',
      scope: true, // ideally I want isolated scope for this template, but not sure if possible to pass the parent scope into vx-container
      link: function($scope, $element, attr) {
         var cache = {};
         // top <vx-view> has a depth of 0, the next one 1, etc
         // this depth is used to get the selected node at the
         // corresponding level in the navigation tree
         var parentVxViewNodeData = $scope.$parent._vxViewNodeData;
         var parentNode = parentVxViewNodeData ?
            parentVxViewNodeData.node : null;
         var parentNodeDepth = parentVxViewNodeData ?
            parentVxViewNodeData.depth : null;
         var info = {
            depth: (typeof parentNodeDepth === 'number') ? parentNodeDepth + 1 : 0,
            parentNode: parentNode || null
         };

         //Remove all cached vxView elements from the cache
         //including the detach one, so that they
         //to be destroyed correctly.
         $scope.$on("$destroy", function () {
            Object.keys(cache).forEach(function (nodeId) {
               var entry = cache[nodeId];
               entry.element.remove();
            });
         });

         $scope.$on('vxRouteChangeSuccess', function(evt, tree, route, previousRoute){
            update();
         });

         $scope.$on("viewDidUnload", function() {
            if (active) {
               active = false;
               info.scope.$broadcast("viewDidUnload", info.node.$id);
            }
         });

         if (!!attr.vxView) {
            $scope.$watch(attr.vxView, function(cur, prev) {
               if (cur === prev || angular.equals(cur, prev)) {
                  return; //init case
               }
               update();
            });
         }

         update();

         function update(){
            // handle <vx-view="node"> case, mainly for non-navigational view such as portlets
            var specificNodeMode = !!attr.vxView;

            var node = null;
            if (specificNodeMode) {
               node = $scope.$eval(attr.vxView);
            } else {
               var tree = navigation.getTree();
               if (!tree) {
                  return;
               }

               var newSelectedNodeParentsIds = [];
               node = tree.getNodeAtLevel(info.depth);
               // Node might be null be it will still have a parent, meaning
               // that we should read the node at the previous level to handle
               // correctly the case where we go from non-null node to null node
               if (info.depth > 0) {
                  var newSelectedNodeParent = tree.getNodeAtLevel(info.depth - 1);
                  while (newSelectedNodeParent) {
                     newSelectedNodeParentsIds.push(newSelectedNodeParent.$id);
                     newSelectedNodeParent = newSelectedNodeParent.$parent;
                  }
               }

               var parentNodesIds = [];
               var parentNode = info.parentNode;
               while (parentNode) {
                  parentNodesIds.push(parentNode.$id);
                  parentNode = parentNode.$parent;
               }

               // We need to compare the parent nodes of our vx-view to
               // the ones of the newly selected node. This is needed so that we don't
               // render unrelated nodes in this vx-view. If we don't do this check then
               // for example if we have 2 vx-views that render nodes on level 3, then
               // when the selected nodes change, both of these vx-views would render
               // the newly selected level 3 node which would cause duplication of the
               // extension views (i.e. we would render the extension view 2 times in
               // 2 different vx-views, only one of the vx-views would be visible though)
               if (JSON.stringify(newSelectedNodeParentsIds) !== JSON.stringify(parentNodesIds)) {
                  return;
               }
            }

            var route = navigation.getRoute();
            var previousRoute = navigation.getPreviousRoute();
            // if the new node is the same as the old node, determine if we should do nothing
            if (node && info.node
                  && (info.node.$id === node.$id)
                  && (specificNodeMode || !navigation.shouldReload(node,
                        info.node, route, previousRoute))) {
               // need to do this as the node could actually be a new js object fetched via ajax
               // (even though by content it is the same)
               // so data-binding will reflect the new object
               if (node !== info.node) {
                  angular.extend(info.node, node);
               }

               if (!active) {
                  active = true;
                  info.scope.$broadcast("viewDidLoad", info.node.$id);
               } else {
                  info.scope.$broadcast("viewDidUpdate", info.node.$id);
               }

               return; // same node, do nothing (probably one of the child changed)
            }

            updateView(node);
         }

         function updateView(node) {
            // deal with the previous DOM, either destroy it or hide it depending on the persist setting
            if (info.node) {
               var retainPrevious = isNodeViewRetained(info.node);
               var previousId = info.node.$id;

               if (!retainPrevious) {
                  removeCacheEntry(previousId);
               } else {
                  addRemoveCacheEntryElementFromDomTree(previousId, false);
               }

               info.node = null;
               info.scope = null;
            }

            if (node) {
               var id = node.$id;

               if (cache[id] && isNodeViewRetained(node)) {
                  angular.extend(cache[id].node, node);
               } else {
                  createCacheEntry(node);
               }

               addRemoveCacheEntryElementFromDomTree(id, true);

               info.node = node;
               info.scope = cache[id].scope;
            }
         }

         function createCacheEntry(node) {
            var extensionViewUrl =  (node.contentSpec && node.contentSpec.sandbox) ?
               SANDBOX_TEMPLATE_URL : node.$templateUrl;

            var extensionViewWrapperScope = $scope.$new(false);
            extensionViewWrapperScope._view = node;
            extensionViewWrapperScope._vxViewNodeData = {
               url: extensionViewUrl,
               depth: info.depth,
               node: node
            };

            var extensionViewWrapper =
                  $compile(EXTENSION_VIEW_WRAPPER_TEMPLATE)(extensionViewWrapperScope);

            cache[node.$id] = {
               node: node,
               scope: extensionViewWrapperScope,
               element: extensionViewWrapper
            };
         }

         function removeCacheEntry(nodeId) {
            var entry = cache[nodeId];

            entry.scope.$destroy();
            entry.element.remove();

            delete cache[nodeId];
         }

         function addRemoveCacheEntryElementFromDomTree(entryId, add) {
            var entry = cache[entryId];
            var element = entry.element;

            if (add) {
               entry.scope.$broadcast('viewDidLoad', entryId);
               element.appendTo($element);
               kendo.resize(element);
            } else {
               entry.scope.$broadcast('viewDidUnload', entryId);
               element.detach();
            }
         }

         function isNodeViewRetained(node) {
            var policy = node.$viewRetentionPolicy ||
                  extensionFrameworkConstants.ViewRetentionPolicy.INHERIT;

            switch (policy) {
               case extensionFrameworkConstants.ViewRetentionPolicy.NONE:
               case extensionFrameworkConstants.ViewRetentionPolicy.DESCENDANTS_ONLY:
                  return false;

               case extensionFrameworkConstants.ViewRetentionPolicy.SELF_ONLY:
               case extensionFrameworkConstants.ViewRetentionPolicy.SELF_AND_DESCENDANTS:
                  return true;

               case extensionFrameworkConstants.ViewRetentionPolicy.INHERIT:
                  while (node.$parent) {
                     var parentPolicy = node.$parent.$viewRetentionPolicy ||
                           extensionFrameworkConstants.ViewRetentionPolicy.INHERIT;

                     switch (parentPolicy) {
                        case extensionFrameworkConstants.ViewRetentionPolicy.NONE:
                        case extensionFrameworkConstants.ViewRetentionPolicy.SELF_ONLY:
                           return false;

                        case extensionFrameworkConstants.ViewRetentionPolicy.DESCENDANTS_ONLY:
                        case extensionFrameworkConstants.ViewRetentionPolicy.SELF_AND_DESCENDANTS:
                           return true;

                        case extensionFrameworkConstants.ViewRetentionPolicy.INHERIT:
                           node = node.$parent;
                           break;

                        default:
                           return false;
                     }
                  }
            }

            return false;
         }
      }
   };
   return directive;
}]);
