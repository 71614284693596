/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * PerformanceView controller.
 */
 angular.module('com.vmware.platform.ui').controller('PerformanceViewController', [
'$scope', '$rootScope',
function($scope, $rootScope) {
   'use strict';
   // If a UI takes longer than this threshold, then the badge will go red.
   var TIME_THRESHOLD = 3; //3s

   // If there are more than this number of watchers on a single screen the
   // badge will go red.
   var WATCHERS_THRESHOLD = 2000;

   // Max number of performance entries that is shown in the UI.
   var MAX_PERF_ENTRIES = 5;

   // Event triggered when a perf measurement cycle is complete, used as a trigger
   // to update the UI.
   var PERF_CYCLE_COMPLETED_EVENT = 'perfCycleCompleted';

   getPerfData();

   /**
    * Hides the perf details view once we click anywhere outside of the perf view.
    * TODO: nsheth Ideally we want to move this code into a directive.
    */
   $(document).click(function() {
      $scope.showList = false;

      if ($rootScope.$$phase) {
         return;
      }

      $scope.$apply();
   });

   $scope.showPerfEntries = function(event) {
      $scope.showList = true;
      event.stopPropagation();
   };

   $scope.$on(PERF_CYCLE_COMPLETED_EVENT, onPerfCycleCompleted);

   function onPerfCycleCompleted(event, perfData) {
      $scope.$apply(function() {
         getPerfData(perfData);
      });
   }

   function getPerfData(perfData) {
      $scope.perfData = perfData;
      $scope.isTimeAboveThreshold = isTimeAboveThreshold;
      $scope.areWatchersAboveThreshold = areWatchersAboveThreshold;

      // Splice perf entries.
      if (_.isObject($scope.perfData)) {
         $scope.perfData.perfEntries.splice(MAX_PERF_ENTRIES);
      }
   }

   function isTimeAboveThreshold() {
      return _.isObject($scope.perfData) &&
            ($scope.perfData.timeInSeconds > TIME_THRESHOLD);
   }

   function areWatchersAboveThreshold() {
      return _.isObject($scope.perfData) &&
            ($scope.perfData.watchersCount > WATCHERS_THRESHOLD);
   }
}]);
