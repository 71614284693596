/* Copyright 2017-2018 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   declare const h5: any;
   declare const _paq: any;

   import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;
   import IHttpService = angular.IHttpService;
   import CssPathOptions = platform.CssPathOptions;
   import TelemetryDomNodeWrapperInterface = platform.TelemetryDomNodeWrapperInterface;
   import TelemetryDomNodeWrapper = platform.TelemetryDomNodeWrapper;

   /**
    * Telemetry service
    */
   export class TelemetryService {

      static $inject = [
         '$rootScope',
         '$http',
         'configurationService',
         'telemetryHelperService',
         'logService',
         'userSessionService',
         'vxZoneService',
         '$location'
      ];

      private readonly PLUGIN_DATA_COLLECTION_TIMEOUT: number = 15000;

      private readonly CONNECTION_STATE_CATEGORY: string = "connectionState";
      private readonly CONNECTION_STATE_ACTION: string = "connectionState";
      private readonly CONNECTION_LOST_EVENT: string = "connectionLost";
      private readonly CONNECTION_RESTORED_EVENT: string = "connectionRestored";

      private readonly MOUSE_EVENT_TYPE_TO_ACTION: { [type: string]: string; } = {
         'click': 'click',
         'contextmenu': 'right-click'
      };

      private log: any;

      constructor(private rootScope: any,
            private http: IHttpService,
            private configurationService: any,
            private telemetryHelperService: any,
            private logService: any,
            private userSessionService: any,
            private vxZoneService: any,
            private $location: any) {
         this.log = logService("telemetryService");
      };

      /**
       * Tracks a telemetry event by sending it to VAC.
       *
       * @param eventCategory
       *    The category of the event, e.g. "login". Populated in the e_c column in VAC DB.
       *
       * @param eventAction
       *    The specific action, e.g. "submit". Populated in the e_a column in VAC DB.
       *
       * @param eventName
       *    Name of the event. E.g. "country". Populated in the a_n column in VAC DB.
       *
       * @param eventValue
       *    The value of the event. E.g. "Australia". Populated in the e_v column in the VAC DB.
       */
      public trackEvent(eventCategory: string,
            eventAction: string,
            eventName?: string,
            eventValue?: string) {
         if (h5.isTelemetryEnabled && (typeof _paq !== "undefined")) {
            _paq.push(['trackEvent', eventCategory, eventAction, eventName, eventValue]);
         }
      }

      private telemetryOptions: CssPathOptions = {
         // Adding the classes that won't be taken into account when generating the css
         // selector.
         cssClassBlacklist: ['ng-', 'fill-parent'],
      };

      /**
       * Track a telemetry related mouse event.
       *
       * @param mouseEventType e.g. 'click', 'contextmenu', etc.
       * Events with unsupported type are not tracked.
       * @param targetNodeWrapper a node wrapper of the node which is the target
       * of the mouse event.
       */
      public trackMouseEvent = (mouseEventType: string,
            targetNodeWrapper: TelemetryDomNodeWrapperInterface): void => {

         let action: string | undefined = this.MOUSE_EVENT_TYPE_TO_ACTION[mouseEventType];
         if (!action) {
            return;
         }

         const cssPath: string = this.telemetryHelperService.getNodeWrapperCssPath(
               targetNodeWrapper, this.telemetryOptions
         );
         if (cssPath.indexOf('tid-global-refresh-button') !== -1) {
            action = "global-refresh";
         }
         const encodedText: string =
               this.telemetryHelperService.getEncodedText(targetNodeWrapper);
         const absUrl: string = this.$location.absUrl();
         if (absUrl.indexOf('debugTelemetry') > -1) {
            // If this parameter is added to the URL the CSS selector will be dumped
            // into the console.
            this.log.debug(cssPath);
            if (encodedText) {
               this.log.debug("Obfuscated label: " + encodedText);
               this.log.debug("Unobfuscated label: " + this.telemetryHelperService.getText(targetNodeWrapper));
            }
         }
         if (h5.isTelemetryEnabled && cssPath !== "") {
            this.trackEvent(action, 'element', cssPath, encodedText);
         }
      };

      private windowMouseEventHandler = (event: MouseEvent): void => {
         let targetNodeWrapper: TelemetryDomNodeWrapperInterface =
               new TelemetryDomNodeWrapper(
                     <Node> event.target,
                     this.telemetryHelperService
               );

         const absUrl: string = this.$location.absUrl();
         if ((event && event.isTrusted !== false) ||
               absUrl.indexOf('allowUntrustedTelemetryEvents') > -1) {
            // Track only trusted events (triggered by a user).
            this.trackMouseEvent(event.type, targetNodeWrapper);
         }
      };

      private startTrackingMouseEvents = (targetWindow: Window): void => {
         for (let mouseEventType in this.MOUSE_EVENT_TYPE_TO_ACTION) {
            targetWindow.addEventListener(mouseEventType, this.windowMouseEventHandler);
         }
      };

      private stopTrackingMouseEvents = (targetWindow: Window): void => {
         for (let mouseEventType in this.MOUSE_EVENT_TYPE_TO_ACTION) {
            targetWindow.removeEventListener(mouseEventType, this.windowMouseEventHandler);
         }
      };

      /**
       * Collects data about installed H5 plug-ins and sends it to VAC.
       * This should be done only once after the service is initialized.
       */
      private collectPluginData = (): void => {
         this.vxZoneService.runOutsideAngular((): void => {
            // Since this is (meant to be) called during the main template controller
            // initialization, use a small timeout so we don't slow it down.
            setTimeout((): void => {
               this.http.get('plugins').then(
                     (response: IHttpPromiseCallbackArg<any[]>): void => {
                        if (response && response.data) {
                           _.each(response.data, (plugin: any): void => {
                              // Pick the keys explicitly; we don't want to send any
                              // information that's not accounted for in case the plugin
                              // request response format is changed.
                              // Also, rename the 'id' property to pluginId to avoid
                              // possible clashes with primary keys in the VAC database.
                              const pluginData: any =
                                    _.extend({ pluginId: plugin.id },
                                          _.pick(plugin,
                                                'name',
                                                'vendor',
                                                'version',
                                                'description',
                                                'state',
                                                'vmwareCertifiedMessage'),
                                    );
                              _paq.push(['trackCustom', 'h5_ui_plugin', pluginData]);
                           });
                        }
                     });
            }, this.PLUGIN_DATA_COLLECTION_TIMEOUT);
         });
      };

      private trackBackendConnectionState = (): void => {
         // We need to guard against sending connection state events more than once.
         ((connectionAlive: boolean): void => {
            this.rootScope.$on("backendConnectionLostEvent", (): void => {
               if (connectionAlive) {
                  connectionAlive = false;
                  this.sendConnectionEvent(this.CONNECTION_LOST_EVENT);
               }
            });
            this.rootScope.$on("backendConnectionRestoredEvent", (): void => {
               if (!connectionAlive) {
                  connectionAlive = true;
                  this.sendConnectionEvent(this.CONNECTION_RESTORED_EVENT);
               }
            });
         })(true);
      };

      private sendConnectionEvent = (state: string): void => {
         this.trackEvent(
               this.CONNECTION_STATE_CATEGORY, this.CONNECTION_STATE_ACTION, state, "");
      };

      private initTelemetry = (): void => {
         // adding explicit cast to `any` in order to avoid typescript errors
         // like: "Property '_paq' does not exist on type 'JQuery'."
         (<any>window)._paq = (<any>window)._paq || [];
         let installationId: string = h5.installationId;
         if (!installationId) {
            installationId = 'internal_installation';
         }
         let version: string = "";
         const commonUiStrings = h5.i18n.languageResource["CommonUi"];
         if (commonUiStrings) {
            version = commonUiStrings["client.version"];
         }
         _paq.push([
            'initProduction',
            true,
            h5.telemetryCollectorId,
            installationId,
            'h5_ui',
            version
         ]);
         _paq.push(['enableLinkTracking']);
         if (h5.telemetryCookieDomain) {
            _paq.push(['setCookieDomain', h5.telemetryCookieDomain]);
         }
         // Sanitize the URL so that the domain name is not collected.
         _paq.push(['setUrlCallback', function (url) {
            let parser = document.createElement('a');
            parser.href = url;
            // Collect pathname (i.e. "/ui/"), search (i.e. extensionId, objectId) and
            // the fragment identifier (including the leading hash mark "#") if any.
            return parser.pathname + parser.search + parser.hash;
         }]);

         this.userSessionService.getUserSession().then((userSession) => {
            _paq.push(['setCustomDimension', 'userSessionLocale', userSession.locale]);
         });
      };

      /**
       * @inheritDoc
       */
      public init(): void {
         this.configurationService.getProperty('ui.telemetry.enabled')
               .then((prop: string) => {
                  if (prop && prop.toUpperCase() !== "TRUE") {
                     h5.isTelemetryEnabled = false;
                  }
                  if (h5.isTelemetryEnabled) {
                     this.initTelemetry();
                     this.collectPluginData();
                     this.trackBackendConnectionState();
                  }
                  this.startTrackingWindowEvents(window);
                  this.rootScope.$on('$destroy', () => {
                     this.stopTrackingWindowEvents(window);
                  });
               });
      }

      /**
       * Starts tracking telemetry related events of a target Window object.
       *
       * @param targetWindow which telemetry related events to start tracking.
       */
      public startTrackingWindowEvents(targetWindow: Window): void {
         this.startTrackingMouseEvents(targetWindow);
      }

      /**
       * Stop tracking telemetry related events of a target Window object.
       *
       * @param targetWindow which telemetry related events to stop tracking.
       */
      public stopTrackingWindowEvents(targetWindow: Window): void {
         this.stopTrackingMouseEvents(targetWindow);
      }
   }

   angular.module('com.vmware.platform.ui')
         .service('telemetryService', TelemetryService);
}
