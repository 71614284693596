namespace common_ui {

   export class StorageSelectorState {
      public mode: string;
      public storageBaselineId?: string;
      public basicModeState: StorageSelectorBasicState;

      public vmStorageConfigInAdvancedMode: VmStorageConfig[];

      public recentlySelectedStorageItemsInAdvancedMode: any[];
   }

   export class StorageSelectorBasicState {
      public drsDisabled: boolean;
      public selectedItem: any;
      public profilesData: IStorageProfileData;
      public diskFormatSettings: any;
      public replicationGroupSettings: ReplicationGroupSettings;
      public headerState: StorageSelectorHeaderComponentState;
   }

   /**
    * NOTE: Do not use the data from this class outside the storageSelector itself.
    */
   export class StorageSelectorHeaderComponentState {
      /**
       * The baseline ids for which the PMem hints are NOT shown.
       *
       * By default all hints are shown - but the user can choose to hide some
       * of them via the "X" button on the informational hints.
       */
      public hiddenBaselineHints: {[storageBaselineId: string]: boolean|undefined} = {};
   }
}