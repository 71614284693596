/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The ObjectListViewController - this controller handles the global object list views (object collections) in the object navigator
 *
 */
(function() {
    'use strict';
    angular.module('com.vmware.platform.ui')
        .controller('ObjectListViewController', ObjectListViewController);

    ObjectListViewController.$inject = [
        '$scope',
        '$http',
        '$location',
        '$element',
        'actionsService',
        'resourceUtil',
        'vuiConstants',
        'dataService',
        'objectNavigatorService'
    ];

    function ObjectListViewController(
        $scope,
        $http,
        $location,
        $element,
        actionsService,
        resourceUtil,
        vuiConstants,
        dataService,
        objectNavigatorService) {

        var PROPERTIES = ['primaryIconId', 'name', 'labelIds'];
        var self = this;
        var showRelationsFor;
        $scope.showActionsMenu = showActionsMenu;
        $scope.getData = getData;
        $scope.openDomainView = openDomainView;

        getData();

        // update view  on explicit manual refresh events
        $scope.$on('dataRefreshInvocationEvent', function() {
            getData();
        });

        // update related items list on modelChanged event
        $scope.$on('modelChanged', function(event, objectChangeInfo) {
            var objectId = objectChangeInfo.objectId;
            var opType = objectChangeInfo.operationType;
            var kendoGrid = getKendoGrid();
            if (!kendoGrid) {
                return;
            }
            if (opType === 'DELETE') {
                var rows = kendoGrid.dataSource.data();
                for (var i = 0; i < rows.length; i++) {
                    if (rows[i].id === objectId) {
                        kendoGrid.dataSource.remove(rows[i]);
                        break;
                    }
                }
                // massive hack below:
                // We want to navigate to the same URL when the deleted object
                // is the same as our selection. That's how we trigger the
                // ObjectNotFound.html page.
                if (self.selectedObject && objectId === self.selectedObject.id) {
                    var route = $location.search();
                    route.forceNavigate = true;
                    $location.search(route);
                    delete route['forceNavigate'];
                }
            } else if (opType === 'CHANGE') {
                dataService.getProperties(objectId, PROPERTIES).then(function(properties) {
                    var label = _.escape(properties.name +
                          resourceUtil.getTaggingLabelString(properties.labelIds));
                    var rows = kendoGrid.dataSource.data();
                    for (var i = 0; i < rows.length; i++) {
                        var item = rows[i];
                        if (item.id === objectId) {
                            if (item.name === label) {
                                if (item.primaryIconId !== properties.primaryIconId) {
                                    item.primaryIconId = properties.primaryIconId;
                                    updateIcon(item);
                                }
                            } else {
                                getData();
                            }
                            break;
                        }
                    }
                });
            } else {
                getData();
            }
        });

        function loadVirtualList(relationId, showRelationsFor) {
            var dataParams = {
                queryFilterId: 'relatedItemsListFilterId',
                filterParams: [relationId, showRelationsFor],
                requestedProperties: PROPERTIES,
                dataModels: [relationId]
            };
            var dataUrl = 'list/ex/';
            $scope.gridOpts = {
                columnDefs: [
                    {
                        displayName: 'Name',
                        field: 'name',
                        template: function (data) {
                            var label = _.escape(data.name + resourceUtil.getTaggingLabelString(data.labelIds));
                            var italic = resourceUtil.getItalicClassName(data.labelIds, data.primaryIconId);

                            return '<div ng-non-bindable>' +
                                     '<span class="object-navigator-icon"><span class="' + data.primaryIconId + '"></span></span>' +
                                     '<span class="object-navigator-label ' + italic + '" title="' + label + '">' + label + '</span>' +
                                     '<span class="vui-icon-object-nav-pan" style="display: none;"></span>' +
                                   '</div>';
                        }
                    }
                ],
                pageable: true,
                pageConfig: {
                    displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING,
                    hidePager: true
                },
                data: {
                    url: dataUrl,
                    params: dataParams,
                    dataType: vuiConstants.grid.dataType.JSON,
                    method: vuiConstants.grid.dataMethod.POST,
                    contentType: "application/json",
                    processData: false,
                    beforeSend: function (xhr, listViewSpec) {
                       listViewSpec.data = JSON.stringify(listViewSpec.data);
                    },
                    data: 'data',
                    total: 'totalResultCount'
                },
                selectedItems: [],
                height: '100%',
                selectionMode: vuiConstants.grid.selectionMode.SINGLE,
                resizable: true,
                sortOrder: [{field: 'name', dir: 'asc'}],
                dataBound: function (data) {
                    $scope.virtualListCount = data.sender.dataSource.total();
                    $element.find('#navigator-virtual-list .k-grid-header').css('display', 'none');

                    // Reselect to prevent kendo grid's selection loss when a new
                    // DataSource is set. Because dataBound is triggered for each page
                    // when scrolling, the selection will be preserved even if it's on a
                    // different page.
                    selectObject();
                },
                change: function () {
                    var selectedRows = this.select();
                    var selectedItems = [];
                    for (var i = 0; i < selectedRows.length; i++) {
                      var dataItem = this.dataItem(selectedRows[i]);
                      selectedItems.push(dataItem);
                    }

                    selectObject(selectedItems[0], selectedItems.length === 0);
                }
            };
        }

        function getData() {
            var context = $scope._view.viewDetails.context;
            showRelationsFor = context.metadata.showRelationsFor;
            getTop().then(function() {
                loadVirtualList($scope.relation.relationId, showRelationsFor);
            });
        }

        // Get the names of the top - relations list - this will contain only one relation always
        function getTop() {
            return $http.get('relateditems/summary?objectType=' + showRelationsFor + '&fetchCount=false').then(function (resp) {
                var topList = resp.data;
                $scope.relation = topList[0];
            });
        }

       /**
        * Select an object or reselect previous selection.
        *
        * @param {Object} [obj]
        *   If object is specified - select it, otherwise reselect the previously
        *   selected object if it exists.
        */
        function selectObject(obj, resetSelectedItem) {

            self.selectedElement = $element.find('#navigator-virtual-list .k-state-selected td');
            var icon = self.selectedElement.find('.vui-icon-object-nav-pan');
            var isIconShown = icon.length > 0 && !icon.is(':hidden');

          /**
           * 1) if there is object and no visible arrow icon then show the arrow icon and navigate the main view to the object
           * 2) if there is an object and visible arrow icon we should navigate the main view and the left pane i.e. call openObject(selectedObject)
           * 3) else if we have selected object but no selected element and no resetSelectedItem - we should preselect the element with the kendo grid API
           */
          if (obj) {
             if (!isIconShown) {
                self.selectedObject = obj;
                $scope._navigate('vsphere.core.inventory.serverObjectViewsExtension', obj.id);
                icon.show();
                $element.find('.vui-icon-object-nav-pan:visible').each(function () {
                   if (!icon.is($(this))) {
                      $(this).hide();
                   }
                });
             } else {
                self.selectedObject = obj;
                openObject(obj);
             }

             return;
          }

          if (!resetSelectedItem) {
             selectSelectedObject();
             return;
          }
          // resetSelectedItem is truthy
          self.selectedObject = null;
       }

        function selectSelectedObject() {
            if (!self.selectedObject) {
              return;
            }

            var kendoGrid = getKendoGrid();
            if (!kendoGrid) {
               return;
            }
            var rows = kendoGrid.dataSource.data();
            for (var i = 0; i < rows.length; i++) {
                if (rows[i].id === self.selectedObject.id) {
                    kendoGrid.select('tr:eq(' + i + ')');
                    if ($scope.gridOpts.selectedItems) {
                       $scope.gridOpts.selectedItems[0] = rows[i];
                    }

                    break;
                }
            }

        }

        function openObject(obj) {
            $scope.objectnavigator.switchView({
                url: "resources/ui/views/objectnavigator/RelatedItemsView.html",
                context: obj,
                historyDescription: {
                    icon: obj.primaryIconId,
                    title: obj.name
                },
                navigate: {
                    extensionId: 'vsphere.core.inventory.serverObjectViewsExtension',
                    objectId: obj.id
                }
            });
        }

        function clearSelection() {
            if (self.selectedElement) {
                self.selectedElement.find('.vui-icon-object-nav-pan').hide();
            }

            var kendoGrid = getKendoGrid();
            if (kendoGrid) {
                kendoGrid.clearSelection();
            }
            self.selectedObject = null;
        }

        function openDomainView() {
            if ($scope.node) {
                switchView();
                return;
            }

            var context = $scope._view.viewDetails.context;
            var nodeId = context ? context.uid : '';

            objectNavigatorService.getView(nodeId).then(function(node) {
                if (!node || !node.navigationTargetUid) {
                    return;
                }

                $scope.node = node;
                switchView();
            });

            function switchView() {
                clearSelection();

                if (!$scope.node.viewOpenedUponFocus) {
                    $scope._navigate($scope.node.navigationTargetUid);
                } else {
                    $scope.objectnavigator.switchView({
                        url: $scope.node.viewOpenedUponFocus.url,
                        context: $scope.node.viewOpenedUponFocus,
                        historyDescription: {
                            icon: $scope.node.icon,
                            title: $scope.node.title
                        },
                        navigate: {
                            extensionId: $scope.node.navigationTargetUid
                        }
                    });
                }
            }
        }

        function showActionsMenu($event) {
            var left =  $event.clientX;
            var top = $event.clientY;
            var selectedObjectIds = $event.data;
            actionsService.showObjectContextMenu($event, $scope, selectedObjectIds, left, top);
        }

        /**
         * Updates the icon for a given kendo DataSource item.
         */
        function updateIcon(item) {
            var kendoGridElement = $element.find('#navigator-virtual-list > div');
            if (kendoGridElement) {
                kendoGridElement
                    .find('tr[data-uid=' + item.uid + '] .object-navigator-icon > span')
                    .prop('class', item.primaryIconId);
            }
        }

        function getKendoGrid() {
            return $element.find('#navigator-virtual-list > div').data('kendoGrid');
        }
    }
})();

