/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * @ngdoc object
 * @name com.vmware.platform.ui:MultiPageDialogPage
 * @module com.vmware.platform.ui
 *
 * @property {string} title
 *    `{string}` Title of the page.
 * @property {string} contentUrl
 *    `{string}` Template URL of the page.
 * @property {string} state
 *    `{string}` State that indicates if page is completed, incomplete or disabled.
 * @property {Function} [onCommit]
 *    `{Function}` A function that is to be called when the Ok button is clicked or
 *    another page is to be opened. This function is where page validation is supposed
 *    to happen.
 *
 *    It is expected to return true/false or an array with
 *    validaiton message objects like:
 *       {
 *          type: vuiConstants.validationBanner.type.ERROR,
 *          text: 'The name is required.'
 *       }
 *    The callback result can be returned via promise as well.
 *
 *    If the result is true or an empty array the dialog is to open the
 *    new page that is clicked or is to be submitted if OK is clicked.
 *
 *    If the result is false or a non-empty array it will stay on the same page.
 *    In case of non-empty array the errors, passed via result, will be shown
 *    in an overlay message.
 */

/**
 * @ngdoc object
 * @name com.vmware.platform.ui:MultiPageDialogOptions
 * @module com.vmware.platform.ui
 *
 * @description
 *    **Extends** {@link vui.angular.modal:ModalOptions ModalOptions}.
 *
 *    Configuration object for
 *    {@link com.vmware.platform.ui:vxMultiPageDialog vxMultiPageDialog} directive.
 *
 *    Example:
 *    ```js
 *    var dialogOptions = {
 *                            title: 'Edit HA settings',
 *                            iconClass: 'vSphere-icon-cluster',
 *                            pages: [{
 *                               title: 'Failures and Responses',
 *                               contentUrl: 'page1.html',
 *                            }, {
 *                               title: 'Heartbeat Datastores',
 *                               contentUrl: 'page2.html',
 *                               onCommit: heartbeatDatastoresOnCommit
 *                            }],
 *                            show: true,
 *                            loading: true,
 *                            loadingMessage: 'Loading...',
 *                            loadingAriaLabel: 'Loading HA data...',
 *                            width : '600px',
 *                            height: '400px',
 *                            confirmOptions: { ... },
 *                            rejectOptions: { ... }
 *                        };
 *    ```
 *
 * @property {string} title
 *    Type: `string`
 *
 *    Specifies the title of the dialog.
 * @property {string} [iconClass]
 *    Type: `string` **|** Optional
 *
 *    The CSS class name representing the icon displayed in the dialog title bar. *
 * @property {Array} pages
 *    Type: `Array`
 *
 *    An array of {@link com.vmware.platform.ui:MultiPageDialogPage MultiPageDialogPage}
 *    that defines pages of the dialog defined by the page title, URL of the content
 *    that will be loaded, and commit function used to validate the page when OK
 *    button is clicked or it is an attempt to move to another page.
 * @property {string} [width]
 *    Type: `string` **|** Optional **|** Default: `'auto'`
 *
 *    The custom width for the dialog. Min-width is 220px and
 *    max-width is 960px.
 * @property {string} [height]
 *    Type: `string` **|** Optional **|** Default: `'auto'`
 *
 *    The custom height for the dialog. Min-height is 200px and
 *    max-height is 80%.
 *
 *    **Note:** In Internet Explorer the default height of dialog is set to
 *    `'400px'`. This is a workaround to handle an existing browser bug where
 *    the flex container does not honor the containers `min-height`.
 * @property {Object} confirmOptions
 *    Type: `Object` **|** Optional
 *
 *    {@link vui.angular.dialog:ConfirmOptions ConfirmOptions} contains
 *    properties to define the confirm (OK) button.
 * @property {Object} rejectOptions
 *    Type: `Object` **|** Optional
 *
 *    {@link vui.angular.dialog:RejectOptions RejectOptions} contains
 *    properties to define the reject (Cancel) button.
 * @deprecated {Object} validationBanner
 *    Type: `Object` **|** Optional
 *
 *    {@link vui.angular.validationBanner:ValidationBannerOptions ValidationBannerOptions}
 *    contains properties to define the validation banner. In the dialog,
 *    validation banner is used to show an error or warning message.
 *
 *    Instead of using vlidationBanner to show overlay messages you can pass them as a
 *    result of each page's onCommit callback.
 *
 * @property {boolean} [loading]
 *
 *    Whether to show the indeterminate loading indicator.
 *
 * @property {String} [loadingMessage]
 *
 *    Property that determines whether a loading message should be shown, and what
 *    the localized content of the loading message is.
 *
 * @property {String} [loadingAriaLabel]
 *    Property that determines the loading message which should be read by the
 *    screen readers when the loading is in progress.
 */

/*
 * @ngdoc directive
 * @name com.vmware.platform.ui:vxMultiPageDialog
 * @module com.vmware.platform.ui
 * @restrict A
 *
 * @description
 *    Directive to create a multi page dialog.
 *
 *    The dialog's content scope prototypically inherits from the scope on
 *    which {@link com.vmware.platform.ui:vxMultiPageDialog vx-multi-page-dialog}
 *    directive is declared.
 *
 * @param {MultiPageDialogOptions} vxMultiPageDialog
 *    Configuration object for the multi page dialog.
 *    It is of type {@link com.vmware.platform.ui:MultiPageDialogOptions MultiPageDialogOptions}.
 */
angular.module('com.vmware.platform.ui')
.directive('vxMultiPageDialog', ['vxMultiPageDialogService',
   function(vxMultiPageDialogService) {
      'use strict';
      return {
         restrict: 'A',
         scope: false,
         link: function (scope, element, attr) {
            // Directive options
            var options = {
               scope: scope,
               configObjectName : attr.vxMultiPageDialog
            };

            // Initialize modal
            var dialog = vxMultiPageDialogService(options);

            // set a watch on show to show and hide
            scope.$watch(attr.vxMultiPageDialog + '.show',
               function (newValue, oldValue) {
                  if (newValue ===  false  && oldValue === true) {
                     dialog.hide();
                  } else if (newValue ===  true  && oldValue === false) {
                     dialog.show();
                  }
               });

            // Garbage collection
            scope.$on('$destroy', function () {
               dialog.destroy();
               options = null;
               dialog = null;
            });
         }
      };
   }]);
