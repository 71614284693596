module common_ui {

    export class StorageProfilesData implements IStorageProfileData {
        private _vmRef: ManagedObjectReference;
        private _storageProfiles: StorageProfile[];
        private _currentVmProfileAssignment: VmStorageProfileAssignment;
        readonly existingVmProfileAssignment: VmStorageProfileAssignment;
        readonly _error: string;

        constructor(
                storageProfiles: StorageProfile[],
                vmRef?: ManagedObjectReference,
                existingVmProfileAssignment?: VmStorageProfileAssignment,
                selectedProfile?: StorageProfile,
                error?: string) {

            this._currentVmProfileAssignment = {
                vmHomeProfile: {
                    id: '',
                    label: ''
                }
            };
            this._storageProfiles = storageProfiles;
            if (vmRef) {
                this._vmRef = vmRef;
            }
            if (existingVmProfileAssignment) {
                this.existingVmProfileAssignment = existingVmProfileAssignment;
            }
            if (this.existingVmProfileAssignment) {
                this.assignExistingAssignments();
            }
            if (selectedProfile) {
                this.selectedProfile = selectedProfile;
            }
            if (storageProfiles && storageProfiles.length) {
                this.selectedProfile = storageProfiles[0];
            }
            if (error) {
                this._error = error;
                this._storageProfiles = [];
                this._currentVmProfileAssignment.vmHomeProfile.id = null;
            }
        }

        set selectedProfile(profile: StorageProfile) {
            if (this.areProfilesEqual(
                    this._currentVmProfileAssignment.vmHomeProfile, profile)) {
                return;
            }
            if (profile && profile.keepExistingProfileAssignments) {
                this._currentVmProfileAssignment.vmHomeProfile = profile;
                if (this.existingVmProfileAssignment
                        && this.existingVmProfileAssignment.diskProfileAssignments) {
                    this._currentVmProfileAssignment.diskProfileAssignments
                        = angular.copy(this.existingVmProfileAssignment.diskProfileAssignments);
                }
                return;
            }
            for (let i=0; i<this._storageProfiles.length; i++) {
                if (this.areProfilesEqual(this._storageProfiles[i], profile)) {
                    this._currentVmProfileAssignment.vmHomeProfile
                        = this._storageProfiles[i];
                    this.assignProfileToDisks(this._storageProfiles[i]);
                    break;
                }
            }
        }

        get selectedProfile(): StorageProfile {
            return this._currentVmProfileAssignment.vmHomeProfile;
        };

        get existingHomeProfile(): StorageProfile {
            return this.existingVmProfileAssignment.vmHomeProfile;
        }

        get storageProfiles(): StorageProfile[] {
            return this._storageProfiles;
        };

        get error(): string {
            return this._error;
        }

        public getDiskProfile(diskKey: string) {
            const diskAssignments = this._currentVmProfileAssignment.diskProfileAssignments;
            return this.getDiskProfileInternal(diskKey, diskAssignments);
        }

        public getExistingDiskProfile(diskKey: string) {
            const diskAssignments = this.existingVmProfileAssignment.diskProfileAssignments;
            return this.getDiskProfileInternal(diskKey, diskAssignments);
        }

        private getDiskProfileInternal(diskKey: string, diskAssignments:any) {
            if (diskAssignments) {
                for (let diskAssignment of diskAssignments) {
                    if (diskAssignment.diskId === diskKey.toString()) {
                        return diskAssignment.profile;
                    }
                }
            }
            return null;
        }

        private assignExistingAssignments() {
            const existingDiskAssignments =
                this.existingVmProfileAssignment.diskProfileAssignments;
            if (existingDiskAssignments) {
                this._currentVmProfileAssignment = angular.copy(this.existingVmProfileAssignment);
            }
        }

        private assignProfileToDisks(profile: StorageProfile) {
            const vmAssignments = this._currentVmProfileAssignment.diskProfileAssignments;
            if (vmAssignments) {
                for (let i = 0; i < vmAssignments.length; i++) {
                    vmAssignments[i].profile = profile;
                }
            }
        }

        // If the StorageProfile interface gets implemented,
        // implement equality interface as well and refactor.
        private areProfilesEqual(p1: StorageProfile, p2: StorageProfile): boolean {
            if (p1 === p2) {
                return true;
            }
            if (p1 && !p2 || p2 && !p1) {
                return false;
            }
            return p1.id === p2.id && p1.label === p2.label;
        }
    }
}
