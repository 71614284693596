/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Provides columns renderers.
 */
angular.module('com.vmware.platform.ui')
.service('columnRenderersRegistry', function() {
   'use strict';
   var columnRenderers = {};
   return {
      /**
       * Registers a column renderer with the provider.
       * @param  {string} id     Id to reference a rendere
       * @param  {function} columnRenderer The column renderer function
       */
      registerColumnRenderers: function(columnRendererConfig) {
         angular.extend(columnRenderers, columnRendererConfig);
      },
      /**
       * Retrieves a column renderer based on its id.
       * @param  {string} id Id of a column renderer
       * @return {function}    A column renderer
       */
      getColumnRenderer: function(id) {
         return columnRenderers[id];
      }
   };
});