namespace platform {
   import ManagedObjectReference = com.vmware.vim.binding.vmodl.ManagedObjectReference;
   export class ManagedByMessageBuilderService {
      public static $inject = [
         "$q",
         "dataService",
         "i18nService",
         "defaultUriSchemeUtil",
         "managedEntityConstants"
      ];
      public readonly MANAGED_BY_INFO_PROPERTY = "managedByInfo";
      public readonly TEMPLATE_PROPERTY = "template";

      constructor(private $q: any,
            private dataService: any,
            private i18nService: any,
            private defaultUriSchemeUtil: any,
            private managedEntityConstants: any) {
      }

      public buildConfirmationText(targetsRefs: ManagedObjectReference[], confirmationText: string = "",
            withProceedMsg: boolean = false): angular.IPromise<string> {
         if (_.isEmpty(targetsRefs)) {
            return this.$q.when();
         }

         const targetIds = this.defaultUriSchemeUtil.getVsphereObjectIds(targetsRefs);

         const managedByPropertiesPromise = this.retrieveManagedByProperties(targetIds);

         return managedByPropertiesPromise.then((managedByProperties: any): string => {
            return this.buildMessage(targetIds, managedByProperties, withProceedMsg, confirmationText);
         }, (): string => {
            // in case the data can not be retrieved return the confirmationText as if the entity is not managed
            return confirmationText;
         });

      }

      public buildWarningMessage(targetIds: string[], withProceedMsg: boolean = false,
            managedByProperties: any = {}): angular.IPromise<string | undefined> {

         if (_.isEmpty(targetIds)) {
            return this.$q.when();
         }

         let managedByPropertiesPromise: angular.IPromise<any>;
         if (_.isEmpty(managedByProperties)) {
            managedByPropertiesPromise = this.retrieveManagedByProperties(targetIds);
         } else {
            managedByPropertiesPromise = this.$q.when(managedByProperties);
         }

         return managedByPropertiesPromise.then((managedByProperties: any): string => {
            return this.buildMessage(targetIds, managedByProperties, withProceedMsg);
         }, (): string => {
            // in case the data can not be retrieved return an empty message as if the entity is not managed
            return;
         });
      }

      private retrieveManagedByProperties(targetIds: string[]): any {
         let properties = [this.MANAGED_BY_INFO_PROPERTY];
         if (this.defaultUriSchemeUtil.getEntityType(targetIds[0]) === this.managedEntityConstants.VIRTUAL_MACHINE) {
            properties.push(this.TEMPLATE_PROPERTY);
         }
         return this.dataService.getPropertiesForObjects(targetIds, properties, {skipErrorInterceptor: true});
      }

      private buildMessage(targetIds: string[], managedByProperties: any,
            withProceedMsg: boolean = false, additionalMessage: string = ""): string {
         let singleEntityManagedByWarning: string;
         let multiEntityManagedByWarning: string;
         switch (this.defaultUriSchemeUtil.getEntityType(targetIds[0])) {
            case this.managedEntityConstants.VIRTUAL_MACHINE:
               singleEntityManagedByWarning = "singleVmManagedByWarning";
               multiEntityManagedByWarning = "multiVmManagedByWarning";
               if (managedByProperties[targetIds[0]][this.TEMPLATE_PROPERTY]) {
                  singleEntityManagedByWarning = "singleTemplateManagedByWarning";
                  multiEntityManagedByWarning = "multiTemplateManagedByWarning";
               }
               break;
            case this.managedEntityConstants.V_APP:
               singleEntityManagedByWarning = "singleVAppManagedByWarning";
               multiEntityManagedByWarning = "multiVAppManagedByWarning";
               break;
            default:
               singleEntityManagedByWarning = "singleEntityManagedByWarning";
               multiEntityManagedByWarning = "multiEntityManagedByWarning";
               break;
         }

         let warning = "";
         let suffix = additionalMessage ? "\n\n" + additionalMessage : "";
         if (withProceedMsg) {
            suffix = this.i18nService.getString("Common", "managedByWarningProceed");
         }
         if (targetIds.length === 1) {
            let managedByInfo: string | null =
                  managedByProperties[targetIds[0]][this.MANAGED_BY_INFO_PROPERTY];
            if (managedByInfo) {
               warning = this.i18nService.getString(
                           "Common", singleEntityManagedByWarning, managedByInfo) + suffix;
               return warning;
            }

            return warning;
         }

         _.each(targetIds, (targetId: string): void => {
            let managedByInfo: string | null =
                  managedByProperties[targetId][this.MANAGED_BY_INFO_PROPERTY];
            if (managedByInfo) {
               warning = this.i18nService.getString("Common", multiEntityManagedByWarning) +
                     suffix;
               return;
            }
         });

         return warning;
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("managedByMessageBuilderService", ManagedByMessageBuilderService);
}
