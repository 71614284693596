/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Persistence of the navigation object extensions.
 */
angular.module('com.vmware.platform.ui').factory(
   'navigationPreferenceService',
   ['localStorageService', 'defaultUriSchemeUtil', '$q',
      function (localStorageService, defaultUriSchemeUtil, $q) {
         var LOCAL_STORAGE_KEY = 'navigation.history.extension';

         var _isPersistenceEnabled = true;

         // public API
         return {
            disablePersistence: disablePersistence,
            enablePersistence: enablePersistence,
            getLastExtension: getLastExtension,
            persistLastExtension: persistLastExtension,
            invalidate: invalidate
         };

         /**
          * Disables the navigation settings persistence.
          */
         function disablePersistence() {
            _isPersistenceEnabled = false;
         }

         /**
          * Enables the persistence of the navigation settings.
          */
         function enablePersistence() {
            _isPersistenceEnabled = true;
         }

         /**
          * Extracts the last extension id which was navigated to in the client.
          * If given an objectId then extracts the last extension id for that particular
          * inventory object
          *
          * @param objectId the server object id
          * @returns {string|*|Object|the} extension id as a string
          */
         function getLastExtension(objectId) {
            if (!_isPersistenceEnabled) {
               return $q.resolve();
            }

            return localStorageService.getUserData(generateStorageKey(objectId));
         }

         /**
          * Invalidates an entry for a particular inventory object
          *
          * @param objectId the server object id
          * @returns {Boolean} false if persistence is not enabled, true otherwise.
          */
         function invalidate(objectId) {
            if (!_isPersistenceEnabled) {
               return false;
            }

            localStorageService.setUserData(generateStorageKey(objectId), {});

            return true;
         }

         /**
          * Persists the extension id in the browsers local storage
          *
          * @param objectId the server object id
          * @param extensionId extension id to persist
          * @param relatedItem related item id to persist
          * @param navigator The extensionId of the view which is used to navigate to
          *    the object and view described by objectId and extensionId params.
          */
         function persistLastExtension(objectId, extensionId, relatedItem, navigator) {
            if (_isPersistenceEnabled) {
               if (navigator && navigator !== 'tree') {
                  localStorageService.setUserData(generateStorageKey(), {
                     extensionId: navigator
                  });
               }
               localStorageService.setUserData(generateStorageKey(objectId), {
                  extensionId: extensionId,
                  relatedItem: relatedItem
               });
            }
         }

         /**
          * Generates the key for local storage per object
          *
          * @param objectId
          * @returns {string}
          */
         function generateStorageKey(objectId) {
            var storageKey = objectId ?
                  LOCAL_STORAGE_KEY + '.' + getEntityTypeKey(objectId) :
                  LOCAL_STORAGE_KEY;

            return storageKey;
         }

         /**
          * Extracts entity type from object id
          *
          * @param objectId
          * @returns {Promise} with the entity
          */
         function getEntityTypeKey(objectId) {
            if (defaultUriSchemeUtil.isRootFolder(objectId)) {
               return "RootFolder";
            }
            return defaultUriSchemeUtil.getEntityType(objectId);
         }
      }]);