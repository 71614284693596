/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Http interceptor to intercept http response errors.
 */
angular.module('com.vmware.platform.ui').factory('errorResponseInterceptor',
   ['$q', '$rootScope', 'vuiConstants', '$log',
function($q, $rootScope, vuiConstants, $log){
   'use strict';
   /**
    * Default notification options
    * @type {{type: string, title: string, content: string}}
    */
   var notificationOptions = {
      'type': 'error',
      'title': '',
      'content': '',
      'unhandledError': true
   };
   return {
      'responseError': function(rejection) {
         if (rejection.config && rejection.config.skipErrorInterceptor) {
            return $q.reject(rejection);
         }
          // Internal server error likely come from the controllers or the controller exception handler.
          if (rejection.status === 500) {
             notificationOptions.title = rejection.statusText;
             notificationOptions.content = rejection.data;
             $rootScope.$broadcast('notificationEvent', notificationOptions);
          } else if (rejection.status === 400 || rejection.status === 404) {
             // 400 = bad request, 404 = not found
             notificationOptions.title = rejection.statusText;
             var url = rejection.config.url;
             if (url && url.length > 1000) {
                url = url.substring(0, 100) + '... (' + (url.length - 100) + ' more chars)';
             }
             notificationOptions.content = url;
             $rootScope.$broadcast('notificationEvent', notificationOptions);
          } else {
             // Don't show other errors in the UI except for local dev and acceptance
             var errMsg = 'ResponseError ' + rejection.statusText +
                ', status: ' + rejection.status +
                ', data: ' + (rejection.data ? rejection.data.toString() : '') +
                ', url: ' + (rejection.config ? rejection.config.url : '');
             var location = window.location.toString();
             if (location.indexOf('localhost:9443') === -1 &&
                 location.indexOf('h5-acceptance-ui.eng.vmware.com') === -1) {
                $log.error(errMsg);
             } else {
                notificationOptions.title = rejection.statusText;
                notificationOptions.content = errMsg;
                $rootScope.$broadcast('notificationEvent', notificationOptions);
             }
          }

          return $q.reject(rejection);
      }
   };
}]);

angular.module('com.vmware.platform.ui').config(['$httpProvider',function($httpProvider) {
   'use strict';
   $httpProvider.interceptors.push('errorResponseInterceptor');
}]);
