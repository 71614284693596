angular.module('com.vmware.platform.ui').directive('vxSummaryItem', [
   'i18nService',
   'defaultUriSchemeUtil',
   'navigation',
   'urlService',
   function (
      i18nService,
      defaultUriSchemeUtil,
      navigation,
      urlService) {
   return {
      templateUrl: 'resources/ui/views/summary/vxSummaryItemTemplate.html',
      scope: {
         item: '=',
         resourceBundle: '=',
         signPostTitle: '='
      },
      link: function (scope) {
         scope.SUMMARY_TOOLS_LABEL = 'summaryView.tools';
         scope.SUMMARY_MANAGED_BY_LABEL = 'summaryView.managedBy';

         scope.i18n = i18nService.getString;
         scope.showingIPs = (scope.item.label === 'summaryView.ipAddressLabel');
         scope.showingTools = scope.SUMMARY_TOOLS_LABEL === scope.item.label;
         scope.showingManagedBy = scope.SUMMARY_MANAGED_BY_LABEL === scope.item.label;
         scope.showingDvsUpgradeVersion = (scope.item.label === 'DvsSummaryView.version');
         scope.showSignPost = scope.showingTools || scope.showingManagedBy ||
               (scope.showingDvsUpgradeVersion && scope.item.value.length > 1) ||
               (scope.showingIPs && scope.item.value.length > 1);
         scope.showingMoRef = (scope.item.value && scope.item.value.second
            && scope.item.value.second.type
            && scope.item.value.second.serverGuid
            && scope.item.value.second.value);

         scope.getSummaryItemValue = function (summaryItem) {
            if(scope.showingTools || scope.showingMoRef) {
               return summaryItem.value.first;
            }
            if(scope.showingDvsUpgradeVersion) {
               return summaryItem.value[0];
            }
            return scope.showingIPs ? _.first(summaryItem.value) : summaryItem.value;
         };
         scope.getSummaryItemLabel = function(summaryItem) {
            return summaryItem.label ? scope.i18n(scope.resourceBundle, summaryItem.label) : '\u00A0';
         };
         scope.onMoRefLinkClicked = function(summaryItem) {
            var objectId = defaultUriSchemeUtil.getVsphereObjectId(summaryItem.value.second);
            navigation.navigateToObject(objectId);
         };
         if(scope.showingManagedBy) {
            defineSignPostForManagedBy(scope);
         }
         if(scope.showingTools) {
            defineSignPostForVmTools(scope);
         }
         if (scope.showingIPs) {
            defineSignPostParams(scope);
         }
         if (scope.showingDvsUpgradeVersion) {
            defineSignPostForDvsUpgradeVersion(scope);
         }
      }
   };


   function defineSignPostParams(scope) {
      scope.signPostLinkText = scope.i18n(scope.resourceBundle, 'summaryView.ipAddressLinkFormat',
      scope.item.value.length);
      scope.signPostParams = {
         message: messageMarkup(scope),
         title: scope.signPostTitle,
         "class": "ip-sign-post",
         iconClass: "vsphere-icon-vm-on"
      };

      var labels = [
         scope.signPostLinkText,
         urlService.stripTags(scope.signPostParams.title),
         urlService.stripTags(scope.signPostParams.message)
      ];
      scope.signPostLinkAriaLabel = labels.join(', ');
   }

   function defineSignPostForVmTools(scope) {

      scope.signPostLinkText = scope.i18n(scope.resourceBundle, 'summaryView.tools.moreInfo');
      scope.signPostParams = {
         message: vmToolsMoreInfoRenderer(scope),
         title: scope.signPostTitle,
         width: "440px",
         "class": "vm-tools-sign-post",
         iconClass: "vsphere-icon-vm-on"
      };
      var labels = [
         scope.i18n(scope.resourceBundle, 'summaryView.tools.moreInfoAriaLabel'),
         urlService.stripTags(scope.signPostParams.title),
         urlService.stripTags(scope.signPostParams.message)
      ];
      scope.signPostLinkAriaLabel = labels.join(', ');
   }

   function defineSignPostForManagedBy(scope) {

      scope.signPostLinkText = scope.i18n(scope.resourceBundle, 'summaryView.managedByLinkLabel');
      scope.signPostParams = {
         message: managedByDetailsRenderer(scope),
         title: scope.signPostTitle,
         width: "440px",
         "class": "vm-tools-sign-post",
         iconClass: "vsphere-icon-vm-on"
      };
   }

   function defineSignPostForDvsUpgradeVersion(scope) {

      scope.signPostLinkText = scope.i18n(
            scope.resourceBundle, 'DvsSummaryView.versionLinkText');
      scope.signPostParams = {
         message: scope.item.value[1],
         title: scope.signPostTitle,
         width: "440px",
         "class": "dvs-upgrade-sign-post",
         iconClass: "vsphere-icon-dv-switch"
      };
   }

   function vmToolsMoreInfoRenderer(scope) {
      var subtitle = '<strong>' + scope.i18n(scope.resourceBundle, 'summaryView.tools') + '</strong>';
      var addresses = _.map(scope.item.value.second, function(item){
         var row = '<span class="vm-tools-info-label">' +
               scope.i18n(scope.resourceBundle, item.label) +
               '</span><span class="vm-tools-info-value">' +
               item.value + '</span>';
         return '<li class="vm-tools-info-row">' + row + '</li>';
      });

      return subtitle + '<ul>' + addresses.join('\n') + '</ul>';
   }

   function messageMarkup(scope) {
      var subtitle = '<strong>' + scope.i18n(scope.resourceBundle, 'summaryView.ipAddressLabel') + '</strong>';
      var addresses = _.map(scope.item.value, function(item){ return '<li>' + item + '</li>'; });

      return subtitle + '<ul>' + addresses.join('\n') + '</ul>';
   }

   function managedByDetailsRenderer(scope) {
      var subtitle = '<strong>' + scope.i18n(scope.resourceBundle, 'summaryView.managedBy') + '</strong>';
      var details = i18nService.getString(
            "Common", "managedBy.summaryView.vm.tooltip", scope.item.value);

      return subtitle + '<ul><li>' + details + '</li></ul>';
   }

}]);

