/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

angular.module('com.vmware.platform.ui')
      .directive('vxPropertyView', ['$rootScope', function ($rootScope) {
         'use strict';

         function getCategoryIndex(categories, id) {
            return _.findIndex(categories, function (category) {
               return category.id === id;
            });
         }

         function categoryExists(categories, id) {
            return getCategoryIndex(categories, id) >= 0;
         }

         return {
            templateUrl: "resources/ui/components/propertyview/propertyView.html",
            restrict: 'E',
            replace: true,
            scope: {
               data: '=',
               defaultCategoryId: '='
            },
            link: function ($scope) {

               $scope.selectedCategoryId = $scope.defaultCategoryId;
               $scope.selectCategory = function (categoryId) {
                  $scope.selectedCategoryId = categoryId;
               };
               $scope.getSelectedCategory = function () {
                  if (!$scope.data || !$scope.data.categories || !($scope.data.categories instanceof Array)) {
                     return null;
                  }

                  var selectedCategoryIndex = getCategoryIndex(
                        $scope.data.categories, $scope.selectedCategoryId
                  );

                  if (selectedCategoryIndex < 0) {
                     return null;
                  }

                  return $scope.data.categories[selectedCategoryIndex];
               };

               $scope.navigate = function(link) {
                  $rootScope._navigate(link.extensionId, link.objectId, link.navigator);
               };

               $scope.$watchCollection('data.categories', function (categories) {
                  if (!categories || !(categories instanceof Array) ||
                        categories.length === 0) {
                     return;
                  }

                  if (categoryExists(categories, $scope.selectedCategoryId)) {
                     return;
                  }

                  if (categoryExists(categories, $scope.defaultCategoryId)) {
                     $scope.selectedCategoryId = $scope.defaultCategoryId;
                     return;
                  }

                  $scope.selectedCategoryId = categories[0].id;
               });
            }
         };
      }]);
