/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui:listUtil
    * @module com.vmware.platform.ui
    *
    * @description
    *    The `listUtil` service holds list related utility functions.
    *
    */
   angular.module('com.vmware.platform.ui').factory('listUtil', [function () {

      return {
         compareNumericValues: compareNumericValues
      };

      function compareNumericValues(item1, item2, field) {
         var firstValue = Number(item1[field]);
         var secondValue = Number(item2[field]);

         if (isNaN(firstValue) && isNaN(secondValue)) {
            return 0;
         }
         if (isNaN(firstValue)) {
            return -1;
         }
         if (isNaN(secondValue)) {
            return 1;
         }

         if (firstValue < secondValue) {
            return -1;
         }
         if (firstValue > secondValue) {
            return 1;
         }
         return 0;
      }
   }]);
})();
