(function() {

   'use strict';

   angular.module('com.vmware.platform.ui')
      .directive('centerPanel', centerPanel);

   // Template containing the following optional elements:
   //  - title which can be enabled by adding <applyDefaultChrome>false</applyDefaultChrome> to extension in the
   // plugin.xml
   //  - vCenter Server dropdown which can be enabled by adding <showVCenterSelector>true</showVCenterSelector> to
   // extension in the plugin.xml
   // Note: ng-if define a new scope so the implementation below uses the parent scope for vcService.
   var CENTER_PANEL_TEMPLATE = '<div id="center-pane" class="vui-panel top-level">' +
      '   <div class="center-pane-container" ng-class="{\'center-pane-with-a-title\':addTitleBar}">' +
      '      <div ng-class="{titlebar: addTitleBar}" ng-if="addTitleBar">' +
      '         <span class="titlebar-left-icons">' +
      '            <i ng-class="icon"></i>' +
      '         </span>' +
      '         <span class="titlebar-text">{{title}}</span>' +
      '      </div>' +
      '      <div ng-if="showVCenterSelector" class="titlebar">' +
      '         <label > {{::i18n("Common", "VcSelector.label")}}</label> ' +
      '         <select' +
      '            ng-model="$parent.vcService"' +
      '            ng-options="vcenter as vcenter.name for vcenter in servicesInfo track by vcenter.serviceGuid"' +
      '         ></select>' +
      '      </div>' +
      '      <div class="panel-inner" vx-view rootview>' +
      '      </div>' +
      '   </div>' +
      '</div>';

   centerPanel.$inject = ['navigation', 'userSessionService'];

   function centerPanel(navigation, userSessionService) {
      return {
         restrict: 'E',
         template: CENTER_PANEL_TEMPLATE,
         link: link
      };

      function link($scope, $element, attr) {
         $scope.$on('vxRouteChangeSuccess', function(evt, tree, route, previousRoute) {
            update();
         });

         function update() {
            var tree = navigation.getTree();
            if (!tree) {
               return;
            }

            var node = tree.getNodeAtLevel(0);
            if (!node) {
               return;
            }

            $scope.addTitleBar = node.extensionObject && node.extensionObject.applyDefaultChrome;
            if ($scope.addTitleBar) {
               $scope.title = node.name;
               $scope.icon = node.icon;
            }

            $scope.showVCenterSelector = node.contentSpec ? node.contentSpec.showVCenterSelector : false;
            if ($scope.showVCenterSelector) {
               userSessionService.getAllServersInfo().then(function(info) {
                  $scope.servicesInfo = info.serversInfo;
                  if ($scope.servicesInfo && $scope.servicesInfo.length > 0) {
                     $scope.vcService = $scope.servicesInfo[0];
                  }
               });
            } else {
               $scope.vcService = null;
            }
         }
      }
   }
})();
