/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Defines datastore type constants
 */
angular.module('com.vmware.platform.ui').constant('datastoreTypeConstants', {
   VMFS: 'VMFS',
   NFS: 'NFS',
   NFS41: 'NFS41',
   VSAN: 'vsan',
   VVOL: 'VVOL'
});
