/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Component, that shows a server error stack
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxErrorStack', vxErrorStack);

   function vxErrorStack() {
      return {
         restrict: 'A',
         scope: {
            vxErrorStack: '=',
            errorReportArgs: '='
         },
         template: [
            '<div class="property-value horizontal-flex-container">',
               '<span class="property">',
                  '{{::ctrl.i18n(\'errorStack.label\')}}',
               '</span>',
               // TODO semerdzhievp copy /errorReport/errorReport.jsp and
               // assets from flex container-app and enable link
               //
               // Submit error report link:
               //'<a style="margin-left:auto;" ',
               //      'ng-click="ctrl.onSubmitErrorReportClicked()" ',
               //      'title="{{::ctrl.i18n(\'SubmitErrorReport.toolTip.text\')}}">',
               //   '{{::ctrl.i18n(\'SubmitErrorReport.link.text\')}}',
               //'</a>',
            '</div>',
            '<span class="horizontal-flex-container property-value"' +
                  'ng-repeat="line in ctrl.stack track by $index"' +
                  'style="white-space: pre;">',
               '<span class="vui-icon-error-stack" ',
                  'style="flex-shrink:0;"></span>',
               '{{line}}',
            '</span>'
         ].join(''),
         controller: ErrorStackViewController,
         controllerAs: 'ctrl'
      };
   }

   var ERROR_REPORT_URL = '/h5ngc/errorReport/';

   ErrorStackViewController.$inject = ['$scope', 'i18nService', '$window'];

   function ErrorStackViewController($scope, i18nService, $window) {
      var self = this;

      self.i18n = function(key) {
         return i18nService.getString('Common', key);
      };

      self.onSubmitErrorReportClicked = function() {
         var url = ERROR_REPORT_URL + '?' +
               angular.element.param($scope.errorReportArgs);
         $window.open(url);
      };

      self.stack = $scope.vxErrorStack;
   }
})();