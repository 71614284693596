/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service that handles log out operations.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('logoutService', logoutService);
   logoutService.$inject = ['$window', 'AngularInjector', 'i18nService', 'alertService'];

   function logoutService($window, AngularInjector, i18nService, alertService) {

      var alertConfig = (function () {
         var getString = _.partial(i18nService.getString, 'Common');
         var productName = getString('client.productName');

         return {
            title: getString('error.connectionError.title'),
            message: getString('error.notAuthenticatedError', productName)
         };
      }());

      // Public API
      var service = {
         logout: logout
      };
      return service;

      /**
       * Log out of the client.
       *
       * @param {Object} options (optional)
       *    Contains optional parameters.
       *
       * @param {boolean} options.showNotification (optional)
       *    If true show an alert and log out when the alert is closed.
       *    Otherwise directly log out of the client. Defaults to false.
       */
      function logout(options) {
         var validatedOptions = _.isObject(options) ? options : {};

         if (validatedOptions.showNotification === true) {
            alertService.error(alertConfig.title, alertConfig.message, internalLogout);
         } else {
            internalLogout();
         }
      }

      function internalLogout() {
         var vApiServiceAngular = AngularInjector.get('vscVapiService');
         vApiServiceAngular.logout();

         // Stores redirect URL. After a successful login the user is redirected to the URL.
         $window.location.href = h5.logoutUrl;
         $window.h5.isLogoutInProgress = true;
      }
   }
}());
