/* Copyright 2014-2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Http interceptor to handle ajax timeout errors.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .factory('responseErrorInterceptor', responseErrorInterceptor);

   responseErrorInterceptor.$inject = ['$q', '$injector', '$window'];

   function responseErrorInterceptor($q, $injector, $window) {
      var isErrorShown = false;

      var interceptor = {
         responseError: function (response) {
            // If the app is navigating away, don't throw errors.
            if (response.status === -1) {
               var neverResolvingPromise = $q.defer().promise;
               return neverResolvingPromise;
            }

            // If the error is due to session timeout, then logout.
            if (response.status === 401 && !isErrorShown) {
               // The application is loading for the first time and we check whether
               // the user is authenticated. If authentication check fails, we redirect
               // to the login page (see redirect.js). The application may try to access
               // secure resources before the authentication check completes and we will
               // receive "401" response. In this case don't show alert and directly
               // navigate to the login page.
               if (!$window.h5.isLoggedIn) {
                  $window.location.href = 'login';
                  return;
               }

               isErrorShown = true;

               // Request the service through the $injector to avoid circular dependency.
               var logoutService = $injector.get('logoutService');

               logoutService.logout({ showNotification: true });
            }

            return $q.reject(response);
         },

         isErrorShown: function() {
            return isErrorShown;
         }
      };

      return interceptor;
   }

   angular.module('com.vmware.platform.ui').config(['$httpProvider',
      function ($httpProvider) {
         $httpProvider.interceptors.push('responseErrorInterceptor');
      }
   ]);
}());
