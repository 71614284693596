/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Service containing helper methods related to the Zones API used by Angular 2
 * Zones library will be furnished by a special Angular Next generation bundle, not from h5's default AngularJS system
 * (https://github.com/angular/zone.js/)
 */
angular.module('com.vmware.platform.ui').factory('vxZoneService', [
   function() {
      var rootZone = null;
      var angularZone = null;
      if (window.Zone && window.Zone.current) {
         rootZone = window.Zone.current.parent || window.Zone.current;
		 angularZone = window.Zone.current.name === "angular" ? window.Zone.current : null;
      }
      return {
         // Runs the method outside of the Angular 2 zone (NG Zone), this
         // allows us to have infinite timeouts and infinite setInterval methods
         // without interfering with the Testability provider
         runOutsideAngular: function(method) {
            if (rootZone) {
               rootZone.run(method);
            } else {
               method();
            }
         },

         runInsideAngular: function(method) {
            if (angularZone) {
               angularZone.run(method);
            } else {
               method();
            }
         }
      };
   }
]);
