/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service that handles client-side session expiration.
 *
 * We're using the 'session.timeout' property from webclient.properties as a session
 * timeout interval.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .factory('sessionTimeoutService', sessionTimeoutService);

   sessionTimeoutService.$inject = [
      '$rootScope',
      '$document',
      'configurationService',
      'vxZoneService',
      'logoutService'
   ];

   function sessionTimeoutService($rootScope, $document, configurationService,
                                  vxZoneService, logoutService) {
      // Throttle interval in seconds
      var THROTTLE_INTERVAL = 30;
      var DOM_EVENTS = [
         'mousedown',
         'keydown'
      ].join(' ');

      var timeoutHandle;
      var isSessionExpired = false;

      // Public API
      var service = {
         init: init,
         resetSessionTimeout: resetSessionTimeout
      };
      return service;

      /**
       * Service initialization. Starts a timer with an interval equal to the value of
       * the 'session.timeout' property from webclient.properties. Also subscribes for
       * DOM events that indicate user activity and reset the session when they are triggered.
       *
       * If the timer expires, a `sessionExpired` event is triggered. If a listener
       * calls the {@code preventDefault} method on the event, the session will be reset.
       * This is suitable for long-running tasks e.g. file uploads, deploy OVF, etc.
       */
      function init() {
         startTimer();

         var throttledReset = _.throttle(function() {
            // test friendly
            service.resetSessionTimeout();
         }, THROTTLE_INTERVAL * 1000);

         $($document[0].body).on(DOM_EVENTS, function () {
            vxZoneService.runOutsideAngular(function () {
               throttledReset();
            });
         });
      }

      /**
       * Resets the timer started in the {@link init} function to its
       * initial value only if the session has not yet expired.
       */
      function resetSessionTimeout() {
         if (!isSessionExpired) {
            startTimer();
         }
      }

      function startTimer() {
         configurationService.getProperty('session.timeout').then(function (value) {
            if (value <= 0) {
               return;
            }
            vxZoneService.runOutsideAngular(function () {
               clearTimeout(timeoutHandle);
               timeoutHandle = setTimeout(function () {
                  var evt = $rootScope.$broadcast('sessionExpired');

                  if (evt.defaultPrevented) {
                     // test friendly
                     service.resetSessionTimeout();
                  } else {
                     isSessionExpired = true;

                     logoutService.logout();
                  }
               }, value * 60 * 1000);
            });
         });
      }
   }
})();
