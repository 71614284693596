/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Angular Controller to manage the vx-tree-view directive.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui')
         .controller('VxTreeViewController', VxTreeViewController);

   VxTreeViewController.$inject = ['$scope', '$rootScope', '$q', '$timeout',
      'treeNodeService', 'treeUpdatesHandler',
      'treeViewLocalStorageService',
      'websocketMessagingService',
      'defaultUriSchemeUtil',
      'treeUpdatesConstants',
      'configurationService',
      'treeViewErrorConstants',
      '$log'];

   function VxTreeViewController($scope, $rootScope, $q, $timeout,
         treeNodeService, treeUpdatesHandler,
         treeViewLocalStorageService,
         websocketMessagingService,
         defaultUriSchemeUtil,
         treeUpdatesConstants,
         configurationService,
         treeViewErrorConstants,
         $log) {
      var DRAGSTART = "dragstart";
      var DRAG = "drag";
      var DRAGEND = "dragend";
      var DROP = "drop";

      /**
       * The following map contains supported properties for children update.
       * @type {{}}
       */
      var SUPPORTED_PROPERTIES_FOR_CHILDREN_UPDATE = {
         "vsphere.core.physicalInventorySpec" : {
            "Folder" : new Set(["childEntity"]),
            "Datacenter" : new Set(["childEntity"]),
            "ComputeResource" : new Set(["childEntity", "vm", "resourcePool", "host"]),
            "ClusterComputeResource" : new Set(["childEntity", "vm", "resourcePool", "host"]),
            "HostSystem" : new Set(["childEntity", "vm", "resourcePool"]),
            "ResourcePool" : new Set(["childEntity", "vm", "resourcePool"]),
            "VirtualApp" : new Set(["childEntity", "vm", "resourcePool"])
         },
         "vsphere.core.virtualInventorySpec" : {
            "Folder" : new Set(["childEntity"]),
            "Datacenter" : new Set(["childEntity"])
         },
         "vsphere.core.storageInventorySpec" : {
            "Folder" : new Set(["childEntity"]),
            "Datacenter" : new Set(["childEntity"]),
            "StoragePod" : new Set(["childEntity"])
         },
         "vsphere.core.networkingInventorySpec" : {
            "Folder" : new Set(["childEntity"]),
            "Datacenter" : new Set(["childEntity"]),
            "VmwareDistributedVirtualSwitch" : new Set(["childEntity", "portgroup"]),
            "DistributedVirtualSwitch" : new Set(["childEntity", "portgroup"])
         }
      };

      //configuration parameter set on the directive, see vx-tree-view for more details
      var _config;

      //the controller itself
      var vxTreeViewController = this;

      //These are internally created and used;
      var _kendoTreeView;
      //tracks the node that has to be selected based on the last 'path' request
      var _pendingNodeSelection;
      //the service that manages the updates to the tree
      var _treeUpdatesHandler;
      //the service that persist data for the tree view
      var _treeViewLocalStorage;
      //the flag indicating that the live updates for the inventory tree are enabled
      var _liveUpdatesEnabled;

      //these are initialized based on the directive, see vxTreeView for more details
      var _rootObjRef = $scope.rootObjRef;
      var _preselectedNode = $scope.selectNode;
      var _treeId = $scope.treeId;
      var _persistSelection = !!($scope.options && $scope.options.indexOf('persistselection') > -1);

      // contains the object ids of all VCs which have an opened inventory changelog listener
      var _openedChangelogs = {};

      /**
       * Call this function and provide the kendo tree view to start rolling.
       * It will bind to the necessary events, create and initialize the needed
       * supporting objects and start loading the tree
       * @param kendoTreeView the kendo tree view component
       */
      vxTreeViewController.setupAndRun = function (kendoTreeView, config) {
         _config = angular.extend({
            preselectRoot: true,
            refreshEnabled: false,
            expandPreselectedNode: false
         }, config);

         //preserve the reference to the kendo tree
         _kendoTreeView = kendoTreeView;
         fixExpandPath(kendoTreeView);

         //setup _treeViewLocalStorage
         _treeViewLocalStorage = treeViewLocalStorageService.getLocalStorageByTreeId(_treeId);
         _treeViewLocalStorage.enablePersistence(_persistSelection);

         //trigger loading the tree by binding to the actual datasource
         _kendoTreeView.setDataSource(getDataSourceDefinition());

         //bind the tree events
         bindTreeEvents();

         //get and setup the treeUpdatesHandler
         _treeUpdatesHandler = treeUpdatesHandler(_kendoTreeView, _treeId, _rootObjRef,
            function preserveSelection(updatedItems) {
               var selectedObject = $scope.selectNode;
               if (_.contains(updatedItems, selectedObject)) {
                  locateAndSelect(selectedObject, true);
               }
            }
         );

         //bind to the scope events after the _treeUpdatesHandler is initialized
         bindScopeEvents();
      };

      /**
       * Get the kendo tree datasource
       * @returns {kendo.data.HierarchicalDataSource}
       */
      function getDataSourceDefinition() {
         return new kendo.data.HierarchicalDataSource({
            //The schema of tha data retrieved from the server
            schema: {
               model: {
                  //objRef is the reference to the real object, so we can use it as an id
                  //which is needed for retrieving the children of a node
                  id: 'objRef'
               }
            },

            //data will be retrieved from the server dynamically, so specify a transport
            transport: {
               read: getDataFromServer
            }
         });
      }

      /**
       * Function to be used in the datasource transport for reading
       * @param options See kendo documentation for more details
       * http://docs.telerik.com/kendo-ui/api/javascript/data/datasource#configuration-transport.read
       */
      function getDataFromServer(options) {
         var item, objRef, nodeTypeId, loadingRootNode, gettingData;

         //when expanding a given node its ID(in our case it is objRef) is being provided
         objRef = options.data && options.data.objRef;

         //the presence of objRef is the way to determine the root element or the children
         loadingRootNode = !objRef;
         if (loadingRootNode) {
            gettingData = treeNodeService.getRoot(_treeId, _rootObjRef);
         } else {
            //Sometimes when deleting a node from the tree by calling kendoTreeView.remove(node)
            //the kendo triggers an avalanche of events which finally land here - in
            //an attempt to load the children of the deleted item !
            //Found by deleting a vApp from another machine and the live updates trigger
            //the deletion on this machine
            item = _kendoTreeView.dataSource.get(objRef);
            if (!item) {
               return;
            }
            nodeTypeId = item.nodeTypeId;
            gettingData = treeNodeService.getChildren(_treeId, nodeTypeId, objRef);
         }

         //on successful retrieval show the fetch data
         gettingData.then(options.success);

         if (loadingRootNode) {
            var navTreeLiveUpdatesFlagPromise = configurationService
                  .getProperty('live.updates.navtree.enabled');
            $q.all([gettingData, navTreeLiveUpdatesFlagPromise]).then(function(results) {
               var data = results[0];
               var flagValue = results[1];
               _liveUpdatesEnabled = flagValue && flagValue.toUpperCase() === "TRUE";
               setSelectionAfterLoadingRoot(data);
            });
         }
      }

      function openChangelog(rootId) {
         if (!defaultUriSchemeUtil.isRootFolder(rootId)) {
            return;
         }
         if (_openedChangelogs[rootId]) {
            return;
         }
         if (!_liveUpdatesEnabled) {
            return;
         }
         _openedChangelogs[rootId] = true;
         sendOpenInventoryMessage(rootId);
      }

      function sendOpenInventoryMessage(rootId) {
         var serverGuid = defaultUriSchemeUtil.getPartsFromVsphereObjectId(rootId).serverGuid;
         websocketMessagingService.openInventory(serverGuid);
      }

      function initializeWebsocket() {
         _.each(Object.keys(_openedChangelogs), function (rootId) {
            sendOpenInventoryMessage(rootId);
         });
      }

      function setSelectionAfterLoadingRoot(data) {
         var deferredSelect = $q.defer();
         var initFirstSelection = (function (node, expand) {
            var rootId = data && data[0] && data[0].objRef;
            //create a fallback handleRoot function that may be reused
            //when locateAndSelect doesn't succeed for any reason
            var handleRoot = angular.noop;
            if (rootId) {
               if  (_config.preselectRoot) {
                  handleRoot = _.partial(selectExisting, rootId, true);
               } else {
                  //at least we expand the root
                  handleRoot = function() {
                     _kendoTreeView.expandPath([rootId]);
                     onError(treeViewErrorConstants.SELECTION);
                  };
               }
            }
            //try to locate and select a the specified node, fallback to handleRoot
            locateAndSelect(node, expand)
                  .then(function (isSuccessful) {
                     //if the operation is not successful select the root
                     if (!isSuccessful) {
                        handleRoot();
                     }
                     deferredSelect.resolve();
                  })
                  //some error has occurred so select the root
                  .catch(handleRoot);
         });

         // We have to expand the tree using the persisted expansion data, which is gathered after the clean
         // We have to clean the data, because the inventory might have changed, so the persisted data could be outdated
         // The persisted data will be used only for restoring the previous selection if possible
         var futureExpand = _treeViewLocalStorage.cleanExpandedNodesData().then(expandChildren);
         if (_preselectedNode) {
            //at this point if _preselectedNode is set, it is set from 'scope.selectNode'
            //which means there is a preselection configuration for the directive
            initFirstSelection(_preselectedNode, !!_config.expandPreselectedNode);

            //Delete the _preselectedNode since it is needed only for the very initial
            //loading of the tree and will lead to a buggy behavior - if the tree is refreshed
            //the last selected node should be selected again, not the initial _preselectedNode
            _preselectedNode = null;
         } else {
            _treeViewLocalStorage.getLastSelectedNodeData().then(function (data) {
               initFirstSelection(data.node, data.expanded);
            });
         }

         // When expansion and selection is completed we scroll to the selected element
         $q.all([deferredSelect.promise, futureExpand]).then(scrollToSelectedItem);
      }

      /**
       * Binds to the necessary tree events
       */
      function bindTreeEvents() {
         _kendoTreeView.bind("change", change);
         _kendoTreeView.bind("navigate", navigate);
         _kendoTreeView.bind("expand", expand);
         _kendoTreeView.bind("collapse", collapse);

         var isDragAndDropEnabled = _config ? _config.dragAndDrop : false;

         if (isDragAndDropEnabled) {
            _kendoTreeView.bind(DRAGSTART, onDragStart);
            _kendoTreeView.bind(DRAG, onDrag);
            _kendoTreeView.bind(DRAGEND, onDragEnd);
            _kendoTreeView.bind(DROP, onDrop);

            _kendoTreeView.element.kendoDropTarget({
               group: "gridGroup",
               drop: onDropFromGrid
            });
         }

         function change() {
            var item = _kendoTreeView.dataItem(_kendoTreeView.select());
            if (!item) {
               return;
            }
            $scope.change({
               objRef: item.objRef,
               text: item.text,
               nodeTypeId: item.nodeTypeId,
               item: item
            });
            //notify the local storage
            _treeViewLocalStorage.selectionChanged(item);
         }

         function navigate(evt) {
            var node = evt.node;
            if (!node) {
               return;
            }
            _kendoTreeView.select(node);
         }

         function expand(evt) {
            var item = _kendoTreeView.dataItem(evt.node);
            _treeViewLocalStorage.nodeExpanded(item);
            openChangelog(item.id);
         }

         function collapse(evt) {
            var item = _kendoTreeView.dataItem(evt.node);
            _treeViewLocalStorage.nodeCollapsed(item);
            //when the current selection is inside the node that is being collapsed
            //then change the selection to the collapsed node.
            //This is also the behavior of the flex client
            if (_kendoTreeView.select().closest(evt.node).length) {
               _kendoTreeView.select(evt.node);
            }
         }

         function onDragStart(evt) {
            invokeHandler(acquireHandler(DRAGSTART), evt, evt.sourceNode);
         }

         function onDrag(evt) {
            invokeHandler(acquireHandler(DRAG), evt, evt.dropTarget);
         }

         function onDragEnd(evt) {
            invokeHandler(acquireHandler(DRAGEND), evt, evt.node);
         }

         function onDrop(evt) {
            invokeHandler(acquireHandler(DROP), evt, evt.destinationNode);
         }

         function onDropFromGrid(evt) {
            var destinationNode = $(evt.target).closest('.k-item');
            invokeHandler(acquireHandler(DROP), evt, destinationNode);
         }

         /**
          * Augment the supplied 'evt' object with additional data and
          * invoke the event handler hooked at the $scope object.
          */
         function invokeHandler(fn, evt, dataNode) {
            if (!fn) {
               return;
            }

            // Prepopulate the event object with some additional data.
            evt.vxKendoTreeView = _kendoTreeView;
            evt.vxDataItem = _kendoTreeView.dataItem(dataNode);
            fn(evt);
         }

         function acquireHandler(name) {
            if (!_config["dragAndDropEventHandler"]) {
               return null;
            }

            return _config.dragAndDropEventHandler[name];
         }
      }

      /**
       * Bind to the scope events
       */
      function bindScopeEvents() {
         //Attach a listener for scope.selectNode changes so that the tree may respond
         //by selecting the corresponding node
         $scope.$watch('selectNode', function (newValue, oldValue) {
            //act only upon real changes
            if (newValue === oldValue) {
               return;
            }
            //if the node is already selected skip further processing
            var currentSelectedObj = _kendoTreeView.dataItem(_kendoTreeView.select());
            if (currentSelectedObj && currentSelectedObj.objRef === newValue) {
               return;
            }
            locateAndSelect(newValue, false);
         });

         $rootScope.$on('treeVisibilityStateChanged', function(event, visibilityStateInfo) {
            if (_treeId === "vsphere.core.physicalInventorySpec") {
               if (visibilityStateInfo.isVisible) {
                  _kendoTreeView.dataSource.read();
               } else {
                  $rootScope.$emit("hidingVms", true);
                  _treeUpdatesHandler.handleNodesVisibility(visibilityStateInfo.nodeTypes).then(
                     function(success) {
                        $rootScope.$emit("hidingVms", false);
                        if (!success) {
                           $log.warn("Hiding VMs has failed. VMs might be partially hidden.");
                        }
                     }, function(error) {
                        error.message = error.message || "";
                        $log.error("An error occurred while hiding VMs: " + error.message);
                        $rootScope.$emit("hidingVms", false);
                     }
                  );
               }
            }
         });

         if (_config.refreshEnabled) {
            //Attach a listener for a global refresh event
            $scope.$on('dataRefreshInvocationEvent', function() {
               _kendoTreeView.dataSource.read();
            });

            //Attach a listener for modelChanged event
            $scope.$on('modelChanged', function(event, objectChangeInfo) {
               var objectId = objectChangeInfo.objectId;
               var op = objectChangeInfo.operationType;
               if (op === 'ADD') {
                  _treeUpdatesHandler.add(objectId);
               } else if (op === 'CHANGE') {
                  _treeUpdatesHandler.change(objectId);
               } else if (op === 'DELETE') {
                  _treeUpdatesHandler.handleDelete(objectId);
               } else if (op === 'RELATIONSHIP_CHANGE') {
                  _treeUpdatesHandler.handleMove(objectId).then(function () {
                     var scope = event.currentScope;
                     var preserveSelection = (scope && scope.selectNode === objectId);
                     if (preserveSelection) {
                        locateAndSelect(objectId, true);
                     }
                  });
               } else if (op === 'CHILDREN_UPDATE') {
                  _treeUpdatesHandler.childrenUpdate(objectId);
               }
            });

            // CDC based refresh - 'live refresh'
            $scope.$on('navTree', function (event, partialUpdate) {
               var updates = partialUpdate.updates;
               if (!updates) {
                  return;
               }

               _.each(removeDuplicatedUpdates(updates), function (update) {
                  var updateAsJsonString = JSON.stringify(update);
                  $log.debug(updateAsJsonString);
                  var updateType = update.data.type;
                  if (updateType === treeUpdatesConstants.CHILDREN_UPDATE) {
                     if (!update.source) {
                        return;
                     }
                     var objectType = update.source.type;
                     if (!SUPPORTED_PROPERTIES_FOR_CHILDREN_UPDATE[_treeId][objectType]) {
                        return;
                     }
                     var childrenProperties = update.data.childrenProperties;

                     var hasSupportedChildrenProperty = true;
                     if (childrenProperties && childrenProperties.length > 0) {
                        hasSupportedChildrenProperty = false;
                        for (var property in childrenProperties) {
                           if (SUPPORTED_PROPERTIES_FOR_CHILDREN_UPDATE[_treeId][objectType]
                                    .has(childrenProperties[property])) {
                              hasSupportedChildrenProperty = true;
                              break;
                           }
                        }
                     }
                     if (hasSupportedChildrenProperty) {
                        _treeUpdatesHandler.liveChildrenUpdate(update);
                     }
                  } else if (updateType === treeUpdatesConstants.PROPERTIES_UPDATE) {
                     _treeUpdatesHandler.liveChangeUpdate(update);
                  } else if (updateType === treeUpdatesConstants.DELETE_OBJECT) {
                     _treeUpdatesHandler.liveDelete(update);
                  }
                  // Do nothing when PARENT_UPDATE or invalid update type arrives
               });
            });
         }

         $scope.$on('vxRouteChangeSuccess', function(evt, tree, route) {
            if (route.extensionId === "OBJECT_NOT_FOUND") {
               // Update the tree if it happens that during navigation we found that the
               // object no longer exists.
               _treeUpdatesHandler.change(route.objectId);
            }
         });

         //Re-opened web sockets event listener
         $scope.$on('reOpenWebSocketsEvent', initializeWebsocket);

         $scope.$on('$destroy', function() {
            _treeUpdatesHandler.destroy();
         });
      }

      /**
       * Filters out duplicated partial updates.
       * @param updates An array of partial updates.
       * @returns {Array}
       */
      function removeDuplicatedUpdates(updates) {
         // Certain objects (we call these aliases) are not visible in the tree,
         // but we nevertheless receive updates for them. Sometimes this can cause
         // problems, e.g. when we migrate a VM we receive children updates on the
         // host and its root resource pool, but we only need to handle one of the
         // updates, otherwise we end up with a duplicated node for the migrated
         // VM.
         var result = [];
         var updatesById = _.groupBy(updates, function (update) {
            return defaultUriSchemeUtil.getVsphereObjectId(update.source);
         });
         _.each(updates, function (update) {
            var object = defaultUriSchemeUtil.getVsphereObjectId(update.source);
            var parent = treeNodeService.objAliasesToPrimaryObjId[object];
            // Updates on an object and its alias are considered duplicates if
            // they have the same payload. In this case the update on the alias
            // is filtered out.
            if (!parent
               || !_.some(_.pluck(updatesById[parent], "data"),
                  _.partial(_.isEqual, update.data))) {
               result.push(update);
            }
         });
         return result;
      }

      function getTreeObjectData(objId) {
         return _kendoTreeView.dataSource.get(objId);
      }

      /**
       * If a node that corresponds to the provided objId is already loaded,
       * then select and optionally expand it.
       * Returns true if the operation was successfully accomplished,
       * i.e the node was found and selected, false otherwise
       *
       * @param objId the id of an object which node is to be located and selected
       * @param expand if true the node is expanded upon selection
       * @returns true if the node was found and selected
       */
      function selectExisting(objId, expand) {
         var dataItem = getTreeObjectData(objId);
         if (dataItem) {
            // extra check for null pointers seen in race conditions
            if (!_kendoTreeView.element) {
               return false;
            }
            _kendoTreeView.expandTo(dataItem);
            var node = _kendoTreeView.findByUid(dataItem.uid);
            _kendoTreeView.select(node);
            if (expand) {
               _kendoTreeView.expand(node);
            }
            scrollToSelectedItem();
         }

         return !!dataItem;
      }

      /**
       * This function tries to find a node corresponding to objId in the currently loaded tree nodes.
       * If such a node exists then it is selected.
       * If the node is not found, then a request to the server is sent to 'locate' the node.
       * The server may provide available paths from the root to that node.
       * If exists those paths are used to load the tree up to that node and select it.
       *
       * @param objId the id of tan object which node is to be located and selected
       * @param expand if true the node is expanded upon selection
       * @returns a promise which is resolved when the process has finished.
       *          Resolved with:
       *             null -  if for some reason the expansion did not succeeded
       *             objId - the id of the selected item after a successful expansion
       */
      function locateAndSelect(objId, expand) {
         if (!objId) {
            return $q.resolve(null);
         }

         //try to find the corresponding node, and if it is already loaded select it and return
         if (selectExisting(objId, expand)) {
            return $q.resolve(objId);
         }

         //prepare parameters for the getPath request to the server
         var params = {
            treeId: _treeId,
            objRef: objId,
            options: {
               //the server will return the options parameter as it is
               //so use this to preserve the objId
               selectNode: objId
            }
         };
         //in case the root of the tree is not a fixed object
         if (_rootObjRef) {
            params.rootRef = _rootObjRef;
         }

         //preserve the value before calling the server
         _pendingNodeSelection = objId;
         //call the server to provide available paths
         return treeNodeService.getPath(params).then(validateAndExpandPath);

         function validateAndExpandPath(data) {
            //return if the server hasn't found valid paths
            if (!data || !angular.isArray(data.paths) || !data.paths.length) {
               return $q.resolve(null);
            }

            //in case of a multiple subsequent requests to the server we'd like to
            //proccess only the last one and skip all the previous ones.
            //_pendingNodeSelection contains the last change
            if (data && angular.fromJson(data.options).selectNode !== _pendingNodeSelection) {
               return $q.resolve(null);
            }
            //_pendingNodeSelection is not needed anymore
            _pendingNodeSelection = null;

            //expand the valid path
            return expandValidPath(data.paths, expand);
         }
      }

      /**
       * Expand the path the node choosing the valid path from a list of available paths.
       * This function is called recursively until the valid path for the tree is
       * found or the path list has been exhausted.
       *
       * @param paths available paths from the root node provided by the server
       * @param expand if true the node is expanded upon selection
       * @param deferred (passed automatically during the recursion) a deferred object
       *                 which will be resolved once the expandPath has finished.
       * @returns a promise which is resolved when the process has finished.
       *          Resolved with:
       *             null -  if for some reason the expansion did not succeeded
       *             objId - the id of the selected item after a successful expansion
       */
      function expandValidPath(paths, expand, deferred) {
         if (!deferred) {
            deferred = $q.defer();
         }

         //get the first path and remove it from the list
         var path = paths.shift();
         if (!path) {
            deferred.resolve(null);
            return deferred.promise;
         }

         // We pop the last item because if it is not an expandable element the kendo framework
         // will not call the provided callback
         var objId = path.pop();

         _kendoTreeView.expandPath(path, function () {
            //try to find the corresponding node, and if it is already loaded select it
            //if it is not found than continue to check the remaining paths
            if (selectExisting(objId, expand)) {
               //operation is successful, so resolve the promise
               deferred.resolve(objId);
            } else {
               // There is one kendo flaw that may interfere here:
               // When expanding/loading a node and the server response is being handled
               // the children of that node are built.
               // Then kendo first fires a change notification for that node and after that
               // it marks the node as loaded. This leads to a case where a node may be fully loaded
               // but still loaded() will return false.
               // Since this callback may be called within such a change notification the next
               // expandPath is called after the current call stack has completed, in order to allow
               // kendo to finish its internal processes.
               $timeout(function(){
                  expandValidPath(paths, expand, deferred);
               }, 0);
            }
         });
         return deferred.promise;
      }

      /**
       * Expand the children of the provided node recursively
       */
      function expandChildren(node) {
         var nodeExpansion = $q.defer();
         if (!node || !node.children || Object.keys(node.children).length === 0) {
            nodeExpansion.resolve();
            return nodeExpansion.promise;
         }

         var childrenExpansion = [];
         _.each(node.children, function (childNode, childObjRef) {
            if (!childNode.expanded || !getTreeObjectData(childObjRef)) {
               // The child is has been collapsed or missing. It might be moved or deleted
               return;
            }

            var childExpansion = $q.defer();
            childrenExpansion.push(childExpansion.promise);
            _kendoTreeView.expandPath([childObjRef], function () {
               // Once the whole tree under that child is expanded we resolve the child as resolved
               expandChildren(childNode).then(childExpansion.resolve);
            });
         });

         // Once all the children are expanded we resolve the expansion of the node
         $q.all(childrenExpansion).then(nodeExpansion.resolve);

         return nodeExpansion.promise;
      }

      /**
       * Scroll to the selected item in the tree
       */
      function scrollToSelectedItem() {
         // PR 1899798
         var kendoTreeElement = _kendoTreeView.element;
         if (!kendoTreeElement) {
            return;
         }

         var treeGlobalOffsetTop = kendoTreeElement.offset().top;
         var item = _kendoTreeView.select();
         if (!item || !item.offset()) {
            return;
         }

         var treeScrollTop = kendoTreeElement.scrollTop();
         var itemGlobalScrollTop = item.offset().top;
         var itemLocalScrollTop = (treeScrollTop + itemGlobalScrollTop) - treeGlobalOffsetTop;
         kendoTreeElement.scrollTop(itemLocalScrollTop);
      }

      function onError(error) {
         if (!$scope.error) {
            return;
         }

         $scope.error({
            error: error
         });
      }
   }

   /**
    * kendoTreeView expandPath has a number of issues.
    *
    * 1. If the last item in the path is a leaf (i.e. not an expandable item)
    *    kendo will not call the callback.
    *
    * 2. If an item is in the process of loading, kendo will invoke the callback as though the
    *    whole expandPath process has finished.
    *    Consider the case when the user expands a node (or another expandPath has been triggered
    *    which results in node expansion). Тhen kendo begins to load the children of that node
    *    by first marking that node as expanded, but not loaded(not loaded is expected).
    *    On the other hand when a new expandPath is triggered, kendo checks
    *     node.expanded || node.loaded()
    *    to determine if the node's children are loaded. This is evaluated to TRUE, and the logic
    *    that follows that condition leads to prematurely calling the callback.
    *
    * 3. Calling expandPath will bind to the underlying dataStore each time. This not only leeks
    *    but also envokes the relevant callbacks although the expandPath-s (they may be numerous)
    *    have already finished.
    *
    * This function overrides the original expandPath and fixes issues: 2 & 3
    */
   function fixExpandPath(kendoTreeView) {
      if (kendo.version !== '2015.3.1214') {
         throw 'Verify expandPath of kendoTreeView with the new kendo version';
      }

      //override the original function
      kendoTreeView.expandPath = function(path, complete) {
         path = path.slice(0);
         var treeview = this;
         var dataSource = this.dataSource;
         var node = dataSource.get(path[0]);
         complete = complete || $.noop;

         function tryExpand(node, complete, context) {
            if (node && !node.loaded()) {
               node.set("expanded", true);
            } else {
               // vmware fixing 3
               dataSource.unbind("change", expandLevel);
               complete.call(context);
            }
         }

         // expand loaded nodes
         // vmware fixing 2
         //while (path.length > 0 && node && (node.expanded || node.loaded())) {
         while (path.length > 0 && node && node.loaded()) {
            node.set("expanded", true);
            path.shift();
            node = dataSource.get(path[0]);
         }

         if (!path.length) {
            return complete.call(treeview);
         }

         var expandLevel = function(e) {
            // listen to the change event to know when the node has been loaded
            var id = e.node && e.node.id;
            var node;

            // proceed if the change is caused by the last fetching
            if (id && id === path[0]) {
               path.shift();

               if (path.length) {
                  node = dataSource.get(path[0]);

                  tryExpand(node, complete, treeview);
               } else {
                  // vmware fixing 3
                  dataSource.unbind("change", expandLevel);
                  complete.call(treeview);
               }
            }
         };
         // expand async nodes
         dataSource.bind("change", expandLevel);

         tryExpand(node, complete, treeview);
      };
   }
})();
