/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service that handles logging to the server's log.
 *
 * Example usage:
 *
 *    var logToServer = logToServerService('myDirective');
 *    logToServer.info('Linking...');
 *
 * This would log something like:
 *
 *    `[INFO ] <some_data> [H5_CLIENT][<client-id>][<username>][myDirective]: Linking...`
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .factory('logToServerService', logToServerService);

   logToServerService.$inject = [
      '$http',
      '$q',
      'logService',
      'userSessionService'
   ];

   function logToServerService($http, $q, logService, userSessionService) {
      var logToClient = logService('logToServerService');
      var deferredMessageTags;

      return init;

      /**
       * Binds the api to a given caller.
       *
       * @param {string} caller
       *    Sent to the server with each request to easily identify the log message's
       *    caller.
       */
      function init(caller) {
         var validatedCaller = _.isString(caller) ? caller : 'UnknownCaller';

         // Public API
         return {
            error : _.partial(log, validatedCaller, 'error'),
            warn  : _.partial(log, validatedCaller, 'warn'),
            info  : _.partial(log, validatedCaller, 'info'),
            debug : _.partial(log, validatedCaller, 'debug'),
            trace : _.partial(log, validatedCaller, 'trace')
         };
      }

      /**
       * Logs to the server.
       *
       * @param {string} caller
       *    See `init`.
       *
       * @param {string} level
       *    The log level. Can be one of the log levels exposed by the public api.
       *
       * @param {string | Object} message
       *    The log message. If type is Object, JSON.stringify is used. Note that Arrays
       *    are Objects.
       */
      function log(caller, level, message) {
         var msg;

         if (_.isString(message)) {
            msg = message;
         } else if (_.isObject(message)) {
            msg = JSON.stringify(message);
         } else {
            logToClient.warn('Log to server message was not a string nor an object.');
            return;
         }

         buildMessageTags(caller).then(function(tags) {
            $http({
               method: 'POST',
               url: 'log/',
               data: {
                  level: level,
                  message: tags + msg
               }
            });
         });
      }

      /**
       * Builds the userName, clientId and caller into tags which will be sent
       * to the server as a part of the message.
       *
       * @param {string} caller
       *    See `init`.
       */
      function buildMessageTags(caller) {
         if (deferredMessageTags) {
            return deferredMessageTags.promise;
         }

         var deferred = $q.defer();

         userSessionService.getUserSession().then(function(session) {
            var validatedSession = session ? session : {};

            var tags =
               '[' + validatedSession.clientId + ']' +
               '[' + validatedSession.userName + ']' +
               '[' + caller + ']: ';

            deferredMessageTags = deferred;
            deferredMessageTags.resolve(tags);
         }, function() {
            logToClient.error('Error getting session from userSessionService');
         });

         return deferred.promise;
      }
   }
}());
