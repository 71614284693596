/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for retrieving and invoking actions and showing menus.
 */
angular.module('com.vmware.platform.ui').factory('actionsService',
      ['$http', '$window', 'logService', 'i18nService', 'vuiActionsMenuService',
      'vuiConstants', '$q', 'actionConfirmationService', 'jsUtils', '$timeout',
      '$analytics', 'dataService','keyboardShortcutMapper',
      function($http, $window, logService, i18nService, vuiActionsMenuService,
            vuiConstants, $q, actionConfirmationService, jsUtils, $timeout, $analytics,
            dataService, keyboardShortcutMapper) {

   'use strict';
   var log = logService('actionsService');
   var menuItemTypes = {
      MENU : 'MENU',
      SEPARATOR: 'SEPARATOR',
      ACTION : 'ACTION'
   };
   var lastMenuItemId = 0;
   var menuContainerId = 'applicationMenuContainer';
   var solutionMenusExtensionPoint = 'vise.menus.context.solutionMenus';
   // combines the 6.0 and 6.5 menu types to support 6.0 plugins
   var objectContextMenuTypes = 'vise.menus.context,vise.menus.context.solutionMenus';

   /**
    * Builds, stores the menu item in MenuStore if it is of type 'ACTION'
    * and returns a menu item.
    *
    * @param item Menu object.
    *
    * @param menuItemsByActionUid Menu object Store.
    *
    * @param callInvoker The menu click handler.
    *
    * @return Object representing a menu Item.
    */
   function buildMenuItem(item, menuItemsByActionUid, targets, callInvoker) {
      var menuItem = {
         id: item.id,
         label: item.label,
         iconClass: item.icon,
         enabled: true
      };
      if (item.type === menuItemTypes.SEPARATOR) {
         return vuiConstants.actions.SEPARATOR;
      } else if (item.type === menuItemTypes.MENU) {
         if (!item.id) {
            item.id = generateMenuItemId();
         }
         menuItem.items = item.children.map(function(item) {
            return buildMenuItem(item, menuItemsByActionUid, targets, callInvoker);
         });
      } else if (item.type === menuItemTypes.ACTION) {
         menuItem.onClick = function(eventInfo) {
            callInvoker(item.id, targets, eventInfo);
         };
         menuItem.keyboardShortcut = keyboardShortcutMapper
               .getFormattedKeyboardShortcut(item.id);
      }
      menuItemsByActionUid[menuItem.id] = menuItem;
      return menuItem;
   }

   /**
    * Extracts and returns the object ids from the given target objects.
    *
    * @param targets {Object[]} The target objects to extract the ids from.
    *
    * @return {string[]} Array of object ids. Null if null is passed in.
    */
   var extractObjectIds = function (targets) {
      if (targets === null) {
         return null;
      }
      var idArray = [];
      $.each(targets, function(index, value) {
         idArray.push(value.id);
      });
      return idArray;
   };

   /**
    * Creates and returns a menu Header object.
    *
    * @param targets Object[] The target objects.
    *
    * @returns Object The generic menu label.
    */
   var buildGenericMenu = function(targets) {
      var labelText, iconClass;
      if (targets && targets.length > 0) {

         var actionObjectsStr = '';
         if (targets.length > 1) {
            actionObjectsStr = i18nService.getString(
                  'Common', 'contextMenu.title.nObjects', targets.length);
         } else if (targets.length === 1) {
            var target = targets[0];
            actionObjectsStr = target.name ?
                  target.name :
                  i18nService.getString('Common', 'contextMenu.title.oneObject');
            if (target.primaryIconId) {
               iconClass = target.primaryIconId;
            }
         }

         labelText =
               i18nService.getString('Common', 'contextMenu.title', actionObjectsStr);
      }
      return buildHeaderItem(labelText, iconClass);
   };

   /**
    * Builds and returns menu header item given
    * label and iconClass.
    *
    * @param label Header item label.
    *
    * @param iconClass Header item iconClass (optional)
    */
   function buildHeaderItem(label, iconClass) {
      var item = {
         id: 'header_id',
         label: label,
         iconClass: iconClass
      };
      return item;
   }

   /**
    * Creates and returns a menu item containing
    * text like "No actions available".
    *
    * @returns Object The new "no actions" menu item.
    */
   function buildEmptyItem() {
      var noActionsStr = i18nService.getString(
            'Common', 'contextMenu.noActionsItem');
      var item = {
         id: generateMenuItemId(),
         label: noActionsStr,
         enabled: false
      };
      return item;
   }

   var generateMenuItemId = function () {
      return (lastMenuItemId++).toString();
   };

   /**
    * Creates and returns a disabled menu item containing
    * text like "Loading..".
    *
    * @returns Object The new "loading" menu item.
    */
   function buildLoadingItem() {
      var noActionsStr = i18nService.getString(
            'Common', 'contextMenu.loadingItem');
      var item = {
         id: generateMenuItemId(),
         label: noActionsStr,
         enabled: false
      };
      return item;
   }

   /**
    * Gets menu of the specified type and for specified target objects.
    *
    * @param type {string} Identifiers menu types eg. 'vise.menus.user',
    *  'vise.menus.help' or 'vise.menus.context,vise.menus.context.solutionMenus'
    *
    * @param targetIds {string[]} Identifiers for server objects to evaluate and
    * return actions menu for.
    *
    * @returns {HttpPromise} An angularjs HttpPromise to return the menu.
    */
   function getMenu(type, targetIds) {
      var url = 'actionsService/menu/';
      var params = {
         menuType: type
      };
      return getData(url, params, {}, targetIds, 'post');
   }

   function getEvaluatedMenus(menuIds, targetIds) {
      var url = 'actionsService/menus/' + menuIds.join(',');
      return getData(url, {}, {}, targetIds, 'post');
   }

   /**
    * Gets global toolbar actions of the specified list view.
    *
    * @param listViewId {string} Extension id of the object list view.
    *
    * @returns {HttpPromise} An angularjs HttpPromise to return the toolbar actions.
    */
   function getToolbarActions(listViewId) {
      var url = 'actionsService/toolbar/';
      var params = {
         listViewId: listViewId
      };
      return getData(url, params);
   }

   //======================= Public Functions ==========================
   /**
    * Gets the specified action for the specified target objects.
    *
    * @param actionId {string} The unique identifier of an action registered
    * with the action service to fetch.
    *
    * @param targetIds {string[]} Identifiers for server objects to evaluate the
    * requested action against. It could be a single value.
    *
    * @param skipErrorInterceptor {boolean} (optional) if true sends the request with
    * skipErrorInterceptor property which determines if a notification message is shown
    * when an error occurs in the actions service backend
    *
    * @returns {HttpPromise} An angularjs HttpPromise to return the
    * evaluated action.
    */
   var getAction = function (actionId, targetIds, skipErrorInterceptor) {
      skipErrorInterceptor = (skipErrorInterceptor ? skipErrorInterceptor : false);
      // TODO mcritch: implement ability to send large array of targets with request.
      var url = 'actionsService/actions/evaluations/' + actionId;
      var params = {};
      if (targetIds && targetIds.length !== 0) {
         params.targetUids = targetIds;
      }
      var additionalParams = {};
      additionalParams.skipErrorInterceptor = skipErrorInterceptor;

      return getData(url, params, additionalParams);
   };

   /**
    * Gets actions with evaluations for the specified target objects.
    *showActionsMenuIds {string[]} Identifiers of actions registered with
    * the action service to fetch.
    *
    * @param targetIds {string[], string} Identifier/Identifiers for server objects to evaluate and
    * return actions for.
    *
    * @returns {HttpPromise} An angularjs HttpPromise to return the
    * evaluated actions.
    */
   var getActions = function (targetIds, actionIds, skipActionFilteringStage, params) {
      params = params === undefined  || params === null ? {} : params;
      if (!targetIds) {
         targetIds = [];
      }
      if (_.isString(targetIds)) {
         targetIds = [targetIds];
      }
      if (actionIds && actionIds.length !== 0) {
         params.actionUids =  actionIds;
      }
      if (skipActionFilteringStage) {
         params.skipActionFilteringStage = true;
      }
      return getData('actionsService/actions/evaluations', params, {}, targetIds, 'post');
   };

   /**
    * Gets data from a REST endpoint.
    *
    * @param url {string} Endpoint URL.
    *
    * @param params {object} Request parameters.
    *
    * @param additionalParams {object} Additional parameters that have to be passed to
    * the request
    *
    * @returns {HttpPromise} An angularjs HttpPromise to return the
    * requested data.
    *
    */
   var getData = function(url, params, additionalParams, data, method) {
      additionalParams = additionalParams ? additionalParams : {};
      method = method || 'get';
      return $http({
         method: method,
         url: url,
         params: params,
         data: data,
         skipLoadingNotification: true,
         skipErrorInterceptor: additionalParams.skipErrorInterceptor ? true : false
      }).then(function(resp) {
         // Extract the data from the $http response before resolving
         return resp.data;
      });
   };

   /**
    * Invokes the action specified in the action evaluation against the
    * available targets in the evaluation, if any.
    *
    * If the given action is not available, it is not invoked.
    *
    * In order to invoke the action, the actionEval should contain an "invoker"
    * property that names a function located in the h5.actions map. That
    * function should accept the actionEval and an array of available targets
    * as parameters.
    *
    * @param actionEval {Object} The evaluated action to invoke.
    * @param context {Object} Additional free-format data, that will be passed
    * to the action invoker.
    */
   var invokeAction = function (actionEval, context) {
      // Could check actionEval.timestamp here to determine whether
      // this eval is too stale.

      // Extract the available targets from the evaluation statuses.
      var targets = extractTargets(actionEval);

      // TODO: smarathe - Check if the analytics is enabled
      // Action, Category, Name/Label, Value
      $analytics.eventTrack(actionEval.action.uid, { category: 'h5.actions.trigger', label: targets });

      var confirmHandler = function() {
         return h5.actions[actionEval.invoker](actionEval, targets, context);
      };
      actionConfirmationService.confirmIfRequired(actionEval, confirmHandler);
   };

   /**
    * Shows a custom menu of the given type that is applicable to the
    * given targetIds.
    *
    * <p>If more than one such custom menu is applicable to the given targets,
    * one is chosen to show arbitrarily.
    * Note that it is an error to introduce non-mutually exclusive menus to
    * the service in this way.</p>
    *
    * <p>If no custom menu exists for the given targets, but applicable actions
    * do exist, a generic menu is constructed from those actions and shown.
    * If no applicable actions exist either, nothing happens.</p>
    *
    * @param $event The click event that was raised to trigger the menu. Used
    * for getting the target element (event.target) on which the click was done
    * for anchoring the menu.
    *
    * @param menuType The type of the menu to show.
    *
    * @param scope The scope from which the function is invoked.
    *
    * @param targets The target objects to show the menu for. (Optional)
    *
    * @param x The left co-ordinate from where the menu should render. (Optional)
    *
    * @param y The top co-ordinate from where the menu should render. (Optional)
    *
    * @param targetElement Specifies the element on which the actions menu should open.
    * (Optional) If this is not specified $event.target is used. If $event is null or undefined,
    * the default element is document body.
    */
   var showActionsMenu = function($event, menuType, scope, targets, x, y, targetElement, focusable) {
      var deferred = $q.defer();
      if (!(targets instanceof Array)) {
         targets = jsUtils.isUndefinedOrNull(targets) ? [] : [targets];
      }

      // New scope object for the vuiActionsMenu two way binding
      var localScope = scope.$new();
      var target = targetElement;
      if (!target && $event) {
         target = $event.target;
      }
      // build Menu Data
      buildMenu(menuType, targets, focusable)
         .then(function(actionsMenu) {
            localScope.actionsMenu = actionsMenu;

            var menuOptions = {
               scope: localScope,
               configObjectName: 'actionsMenu',
               target: target,
               menuContainerId: menuContainerId
            };
            if (!jsUtils.isUndefinedOrNull(x) && !jsUtils.isUndefinedOrNull(y)) {
               menuOptions.coordinates = {
                  x: x,
                  y: y
               };
            }

            vuiActionsMenuService.showMenu(menuOptions);
            setTooltips();
            deferred.resolve(actionsMenu);
         })

         .finally(function() {
            // TODO: smarathe - Check if the analytics is enabled
            // Action, Category, Name/Label, Value
            var targetIds = targets.map(function(currTarget) {
               return currTarget.id;
            });
            $analytics.eventTrack('h5.viewActions', { category: 'h5.actions.view', label: targetIds });
         });

      return deferred.promise;
   };

   var showObjectContextMenu = function($event, scope, targets, x, y, targetElement, focusable) {
      return showActionsMenu($event, objectContextMenuTypes, scope, targets, x, y, targetElement, focusable);
   };


   /**
    * Function to calculate the position of actions menu
    *
    * @param vui_action_menu target to calculate the position
    * @param bodyElement dom element body
    * @param left dom element position
    * @param top dom element position
    */
   var calculateActionsMenuPosition = function (vui_action_menu, bodyElement, left, top) {
      var coordinates_x = Math.min(left, (bodyElement.outerWidth() - vui_action_menu.outerWidth()));

      var applicationMenuContainerElement = getApplicationMenuContainerElement();
      applicationMenuContainerElement.css({left: coordinates_x + "px"});
      applicationMenuContainerElement.css({top: top + "px"});

      configureVirtualMenuScroller(applicationMenuContainerElement, top, vui_action_menu);
   };

   function configureVirtualMenuScroller(menuContainer, menuContainerTop, menuElement) {
      var windowHeight = $(window).height();
      var menuContainerOuterHeight = menuContainer.outerHeight(true);
      if (windowHeight - menuContainerTop < menuContainerOuterHeight) {
         var menuContainerVerticalEdgeThickness =
            menuContainerOuterHeight - menuContainer.height();
         menuContainer.css({
            maxHeight: windowHeight - menuContainerVerticalEdgeThickness - menuContainerTop
         });
         var menuElementVerticalEdgeThickness =
            menuElement.outerHeight(true) - menuElement.height();
         var menuElementMaxHeight = windowHeight - (menuElementVerticalEdgeThickness +
            menuContainerTop + menuContainerVerticalEdgeThickness);
         menuElement.css({
            maxHeight: menuElementMaxHeight
         });
         menuElement.addClass(vuiConstants.actions.VUI_MENU_SCROLLER_CLASS);
      }
   }

   // vuiActionMenu does not support tooltips like the docs say
   function setTooltips() {
      $('.k-menu.k-menu-vertical.k-context-menu span.k-link').each(function (index, menuItem) {
         // Set title attribute only if the contents of the menu item will overflow.
         if (menuItem.offsetWidth < menuItem.scrollWidth) {
            var $menuItem = $(menuItem);
            $menuItem.attr('title', $menuItem.text());
         }
      });
   }

   /**
    * Manager for preparation and invocation of actions.
    *
    * @returns {object} The public functions that can be called on the ActionInvoker.
    */
   function ActionInvoker() {
      var actionEvalsByActionUid = {};
      var pendingActionId = null;

      /**
       * Fetches all specified actions applicable to the specified targets and
       * evaluates their state.
       *
       * @param targets {object} All targets for which the actions are applicable.
       *
       * @param vuiMenuItemsByActionUid {object} All action items mapped to their IDs.
       *
       * @param options {object} Custom options for controlling the action evaluations.
       *
       * @returns {HttpPromise} An angularjs HttpPromise to return the
       * requested data.
       *
       */
      function evaluateActions(targets, vuiMenuItemsByActionUid, skipActionFilteringStage, options) {
         var deferred = $q.defer();
         if (vuiMenuItemsByActionUid === null){
            return deferred.promise;
         }
         var actionIds = Object.keys(vuiMenuItemsByActionUid);
         var targetIds = extractObjectIds(targets);

         if (actionIds.length !== 0) {
            getActions(targetIds, actionIds, skipActionFilteringStage).then(function(actionEvals) {
               if (actionEvals === null || !_.isArray(actionEvals)){
                  return;
               }
               if (targets && targets.length === 1) {
                  var targetName = targets[0]['name'];

                  actionEvals.forEach(function(actionEval) {
                     if (actionEval && actionEval.action &&
                        actionEval.action.confirmationText) {
                        actionEval.action.confirmationText = i18nService.interpolate(
                           actionEval.action.confirmationText, [targetName]);
                     }
                  });
               }
               setActionEvaluations(vuiMenuItemsByActionUid, actionEvals, options);
               $timeout(setTooltips, 0);
               deferred.resolve();
            }, function() {
               log.error('Get Actions call Failed for targets ' + targetIds + ' and actions ' + actionIds);
            });
         }
         return deferred.promise;
      }

      /**
       * Applies the action evaluations to the current list of actions.
       * In the case when a pending action exists (that is, when there are multiple
       * targets) the pending action is invoked.
       *
       * @param vuiMenuItemsByActionUid {object} Menu items mapped by their IDs.
       *
       * @param actionEvals {object} The action evaluations.
       *
       * @param options {object} Custom options for controlling the action evaluations.
       *
       * @returns {HttpPromise} An angularjs HttpPromise to return the
       * requested data.
       *
       */
      function setActionEvaluations(vuiMenuItemsByActionUid, actionEvals, options) {
         // update vuiMenuItemsByActionUid
         actionEvals.forEach(function(actionEval) {
            if (!options || !options.skipSetMenuItemEnabled) {
               vuiMenuItemsByActionUid[actionEval.action.uid].enabled =
                     actionEval.available;
            }
            actionEvalsByActionUid[actionEval.action.uid] = actionEval;
         });
         if (pendingActionId !== null && actionEvalsByActionUid[pendingActionId]) {
            // call action corresponding to the menu item clicked by the user.
            callInvoker(pendingActionId);
            pendingActionId = null;
         }
      }

      /**
       * Invokes the specified action.
       *
       * @param actionId {object} The action to invoke.
       *
       * @param targets {object} All targets for which the action is applicable.
       *
       * @param eventInfo {object} Details about the event.
       *
       */
      function callInvoker(actionId, targets, eventInfo) {
         if (targets && targets.length > 1) {
            // in case of multiple targets action's evaluation is done on action click
            var actionIdItems = {};
            actionIdItems[actionId] = {};
            evaluateActions(targets, actionIdItems, true /*skipActionsFilteringStage*/, { skipSetMenuItemEnabled: true }).then(function(){
               tryInvokeAction(actionId);
            });
         } else {
            // in case of single target - actions' evaluation is already done
            tryInvokeAction(actionId);
         }
      }

      /**
       * Checks if the action evaluations are available and invokes action.
       * @param actionId
       *    Id of the action to be evaluated.
       */
      function tryInvokeAction(actionId) {
         // Check if Action Evals have been fetched
         if (!actionEvalsByActionUid[actionId]) {
            pendingActionId = actionId;
            return;
         }

         var actionEval = actionEvalsByActionUid[actionId];
         invokeAction(actionEval);
      }

      function getActionEvalsByActionUid() {
         return actionEvalsByActionUid;
      }

      return {
         evaluateActions: evaluateActions,
         setActionEvaluations: setActionEvaluations,
         callInvoker: callInvoker,
         getActionEvalsByActionUid: getActionEvalsByActionUid
      };
   }

   function buildMenu(menuType, targets, focusable) {
      var targetIds = extractObjectIds(targets);
      // Map of the items in the menu. Used to enable/disable actions when
      // the evaluations arrive.
      var vuiMenuItemsByActionUid = {};
      // Map of the items in the menu that come from plugins.
      // Used to request plugin actions separately and later append them
      // to their corresponding parent menu item.
      var vuiPluginMenuItemsByActionUid = {};

      var actionInvoker = new ActionInvoker();
      var deferred = $q.defer();
      getMenu(menuType, targetIds).then(function(menuItem) {
         var vuiMenu;

         if (menuItem.label) {
            vuiMenu = buildHeaderItem(menuItem.label, menuItem.icon);
         } else {
            // Build Generic menu label
            vuiMenu = buildGenericMenu(targets);
         }

         if (!jsUtils.isEmpty(menuItem.children)) {
            vuiMenu.items = menuItem.children.map(function(menuItem) {
               if (menuItem.extendedPoint === solutionMenusExtensionPoint) {
                  return buildMenuItem(menuItem, vuiPluginMenuItemsByActionUid,
                        targets, actionInvoker.callInvoker);
               } else {
                  return buildMenuItem(menuItem, vuiMenuItemsByActionUid,
                        targets, actionInvoker.callInvoker);
               }
            });
         } else {
            vuiMenu.items = [buildEmptyItem()];
         }

         vuiMenu.focusable = focusable;

         // Evaluate actions only if there is no target (Help or User menu) or
         // it is a single target. For multiple targets all the relevant actions are
         // enabled by default and they are evaluated on action (menu item) click
         if (!targets || (targets.length <= 1)) {
            actionInvoker.evaluateActions(targets, vuiMenuItemsByActionUid,
                  true /*skipActionsFilteringStage since calling 2nd time*/);
         }

         if (!_.isEmpty(vuiPluginMenuItemsByActionUid)) {
            _.each(vuiPluginMenuItemsByActionUid, function (menuItem) {
               menuItem.items.push(buildLoadingItem());
            });
            getEvaluatedMenus(_.keys(vuiPluginMenuItemsByActionUid), targetIds)
                  .then(function (evaluatedMenuData) {
                     addPluginMenus(vuiPluginMenuItemsByActionUid, actionInvoker,
                           targets, evaluatedMenuData);
                  });
         }

         // Resolve the promise in the next frame to make the browser first to dispatch
         // http request for action evaluations and then render the menu
         $timeout(function() {
            deferred.resolve(vuiMenu);
         }, 0);
      }).catch(function() {
         log.error('Get menu call Failed');
         var vuiMenu = buildGenericMenu(targets);
         vuiMenu.items = [buildEmptyItem()];
         deferred.resolve(vuiMenu);
      });
      return deferred.promise;
   }

   /**
    * Appends plugin menu actions to their corresponding menu item.
    * @param vuiPluginMenuItemsByActionUid {object} Plugin menu items mapped to by IDs.
    * @param actionInvoker The action invoker of the menu instance.
    * @param targets Object[] The target objects.
    * @param evaluatedMenuData An object containing the plugin menus with actions
    *    and the evaluations of those actions.
    */
   function addPluginMenus(vuiPluginMenuItemsByActionUid, actionInvoker, targets, evaluatedMenuData) {
      var actionEvalsByActionUid = {};
      if (!targets || targets.length === 1) {
         _.each(evaluatedMenuData.evaluations, function (actionEval) {
            actionEvalsByActionUid[actionEval.action.uid] = actionEval;
         });
      }

      // Remove "Loading.." placeholder items.
      _.each(vuiPluginMenuItemsByActionUid, function (menuItem) {
         menuItem.items = [];
      });

      _.each(evaluatedMenuData.menus, function (menu) {
         var menuItem = vuiPluginMenuItemsByActionUid[menu.id];
         menuItem.items = menu.children.reduce(function(siblings, item) {
            var evaluation = actionEvalsByActionUid[item.id];
            if (targets) {
               var menuItem = buildMenuItem(item,
                     vuiPluginMenuItemsByActionUid, targets,
                     actionInvoker.callInvoker);
               siblings.push(menuItem);
            }
            return siblings;
         }, []);
      });

      // Insert "No actions available" items into menus that are still empty.
      _.each(vuiPluginMenuItemsByActionUid, function (menuItem) {
         if (menuItem.items && menuItem.items.length === 0) {
            menuItem.items.push(buildEmptyItem());
         }
      });

      if (evaluatedMenuData.evaluations) {
         actionInvoker.setActionEvaluations(vuiPluginMenuItemsByActionUid,
               evaluatedMenuData.evaluations, { skipSetMenuItemEnabled: false });
      }
   }

   /**
    * Prepares all actions for list view toolbar.
    * @param listViewId {String} The extension ID of a list view.
    */
    function buildActionsToolbar(listViewId){
       var deferred = $q.defer();
       var itemsByActionUid = {};
       var actionInvoker = new ActionInvoker();
       getToolbarActions(listViewId).then(function(items) {
          if (items === null){
             $timeout(function() {
                deferred.resolve([]);
             }, 0);
             return;
          }
          $.each(items, function (index, item) {
             itemsByActionUid[item.id] = {
                id: item.id,
                label: item.label,
                iconClass: item.icon,
                enabled: true,
                onClick : function(eventInfo) {
                  actionInvoker.callInvoker(item.id, null, eventInfo);
               }
             };
          });
          actionInvoker.evaluateActions(null, itemsByActionUid);
          $timeout(function() {
             deferred.resolve(itemsByActionUid);
          }, 0);
       }).catch(function() {
          log.error('Build actions toolbar failed for list view: ' + listViewId);
          $timeout(function() {
             deferred.resolve([]);
          }, 0);
       });
       return deferred.promise;
    }

   /**
    * If the confirmationText for single object needs to be formatted,
    * the object name is retrieved and the text is changed.
    * Then invokeAction is invoked
    * @param actionEval {Object} The evaluated action to invoke.
    * @param context {Object} Additional free-format data, that will be passed
    * to the action invoker.
    */
   function invokeActionWithConfirmation(actionEval, context, targets) {
      if (!actionEval) {
         return;
      }
      if (!targets) {
         targets = extractTargets(actionEval);
      }
      // if there is only one target and there is a defined message in
      // plugin xml, we need to take the name of the target and change the
      // confirmationText to contain that name
      if (shouldFormatConfirmationText(actionEval, targets)) {
         dataService.getProperties(targets[0], ['name'])
               .then(function(properties) {
                  actionEval.action.confirmationText = i18nService.interpolate(
                        actionEval.action.confirmationText, [properties.name]);
                  invokeAction(actionEval, context);
               });
      } else {
         if(targets.length > 1
               || (actionEval.available === true && targets.length === 1)) {
            invokeAction(actionEval, context);
         }
      }
   }

   function extractTargets(actionEval) {
      var index = 0;
      var targets = [];
      if(actionEval && actionEval.evaluationStatuses) {
         for (var targetId in actionEval.evaluationStatuses) {
            if (actionEval.evaluationStatuses[targetId].available) {
               targets[index++] = targetId;
            }
         }
      }
      return targets;
   }

   function shouldFormatConfirmationText(actionEval, targets){
      return actionEval.available === true
            && targets.length === 1
            && actionEval.action
            && actionEval.action.confirmationText
            && actionEval.action.confirmationText.includes('{0}');
   }

   function getApplicationMenuContainerElement(){
      return $("#" + menuContainerId);
   }


   return {
      getAction : getAction,
      getActions : getActions,
      getApplicationMenuContainerElement: getApplicationMenuContainerElement,
      getData: getData,
      invokeAction : invokeAction,
      showActionsMenu : showActionsMenu,
      showObjectContextMenu: showObjectContextMenu,
      buildActionsToolbar : buildActionsToolbar,
      invokeActionWithConfirmation : invokeActionWithConfirmation,
      calculateActionsMenuPosition : calculateActionsMenuPosition
   };

}]);
