(function() {
   'use strict';

   /**
    * Provides modal dialogs based on Clarity Design.
    */
   angular
         .module('com.vmware.platform.ui')
         .service('clarityModalServiceHelper', clarityModalServiceHelper);

   clarityModalServiceHelper.inject = [
      '$rootScope',
      'dataService',
      'i18nService'
   ];

   function clarityModalServiceHelper($rootScope, dataService, i18nService) {
      return {
         prepareModalScope: prepareModalScope
      };


      function prepareModalScope(actionEval, availableTargets, dialogData, templateUrl) {
         var scope = $rootScope.$new();

         scope.modalOptions = {
            modalOpen: true,
            iconClass: actionEval && actionEval.action ? actionEval.action.icon : '',
            contentUrl: templateUrl,
            actionEval: actionEval,
            availableTargets: availableTargets,
            dialogData: dialogData,
            defaultButton: dialogData ? dialogData.defaultButton : '',
            title: dialogData ? dialogData.title : '',
            size: dialogData ? dialogData.size : '',
            secondaryTitle: dialogData ? dialogData.secondaryTitle : '',
            focusTarget: dialogData && dialogData.focusTarget ? $(dialogData.focusTarget) : null
         };

         constructAndSetModalOptionsTitle(scope.modalOptions);

         return scope;
      }

      /**
       * Immediately returns some title so that the dialog can be shown as soon as
       * possible.
       * If needed, schedules a request for the target object's name.
       */
      function constructAndSetModalOptionsTitle(modalOptions) {
         var availableTargets = modalOptions.availableTargets;
         if (!availableTargets) {
            modalOptions.title = undefined;
         } else if (_.isEmpty(availableTargets) || availableTargets.length === 1) {
            setTitleForSingleEntity(modalOptions);
         } else {
            setTitleForMultipleEntities(modalOptions);
         }
      }

      function setTitleForSingleEntity(modalOptions) {
         var primaryTitle = modalOptions.title;
         if (primaryTitle && titleContainsPlaceholder(primaryTitle)) {
            setTitleToPlaceholderValue(modalOptions);
         } else if (primaryTitle && !titleContainsPlaceholder(primaryTitle)) {
            constructAndSetSecondaryTitle(modalOptions);
         } else if (!primaryTitle) {
            setPrimaryTitleToActionLabel(modalOptions);
            constructAndSetSecondaryTitle(modalOptions);
         }
      }

      function setTitleForMultipleEntities(modalOptions) {
         modalOptions.title = i18nService.getString('Common', 'dialogTitleFormatMultiple', modalOptions.availableTargets.length, "");
      }

      function titleContainsPlaceholder(title) {
         return title.indexOf('{0}') !== -1;
      }

      function setTitleToPlaceholderValue(modalOptions) {
         var availableTarget = modalOptions.availableTargets[0];
         if (availableTarget) {
            dataService
                  .getProperties(availableTarget, ['name'])
                  .then(function(data) {
                     if (data && data.name) {
                        modalOptions.title = i18nService.interpolate(modalOptions.title, [data.name]);
                        modalOptions.secondaryTitle = null;
                     }
                  });
         }
      }

      function setPrimaryTitleToActionLabel(modalOptions) {
         modalOptions.title = modalOptions.actionEval.action.label.replace(/\.+$/g, "");
      }

      function constructAndSetSecondaryTitle(modalOptions) {
         var secondaryTitle = modalOptions.secondaryTitle;
         if (!secondaryTitle) {
            fetchSecondaryTitleNameFromObjectId(modalOptions);
         }
      }

      function fetchSecondaryTitleNameFromObjectId(modalOptions) {
         var availableTarget = modalOptions.availableTargets[0];
         if (availableTarget) {
            dataService
                  .getProperties(availableTarget, ['name'])
                  .then(function(data) {
                     if (data && data.name) {
                        modalOptions.secondaryTitle = data.name;
                     }
                  });
         }
      }
   }
})();
