/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * GenericPortletController
 */
angular.
module('com.vmware.platform.ui').
controller('GenericPortletController', ['$scope', 'dataService', function($scope, dataService) {

   var liveRefreshProperties =
         $scope.portlet._view.contentSpec.metadata.liveRefreshProperties;
   if (liveRefreshProperties) {
      $scope.liveRefreshProperties = liveRefreshProperties.split(',')
            .map(function(prop) { return prop.trim(); });
   }
   var objectId = $scope._route.objectId;
   $scope.watchForObjects = [objectId];
   $scope.loadingData = false;
   $scope.getPortletData = getPortletData;

   angular.extend($scope, {
      data: {}
   });

   function getPortletData() {
      if ($scope.loadingData) {
         return;
      }

      $scope.loadingData = true;

      return dataService
         .getData(objectId, $scope.portlet._view.contentSpec.metadata.modelClass,
             {queryName: $scope.portlet._view.uid + ':GenericPortletController.' + getPortletData.name})
         .then(function(data) {
            $scope.data = data;
         }).finally(function() {
            $scope.loadingData = false;
         });
   }

   getPortletData();
}]);
