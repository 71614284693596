/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The related items view controller inside object navigator.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
       .controller('RelatedItemsController', RelatedItemsController);

   RelatedItemsController.$inject = ['$scope', '$http', 'dataService', 'actionsService', 'vcH5ConstantsService', 'resourceUtil',
      'defaultUriSchemeUtil', '$timeout', 'vuiConstants', '$element', '$location', 'navigatorRelationsService', 'iconService',
      'objectNavigatorAccessibilityServiceFactory'];

   function RelatedItemsController($scope, $http, dataService, actionsService, vcH5ConstantsService, resourceUtil,
                                   defaultUriSchemeUtil, $timeout, vuiConstants, $element, $location, navigatorRelationsService,
                                   iconService, objectNavigatorAccessibilityServiceFactory) {
      var self = this;
      var context;

      $timeout(function () {
         objectNavigatorAccessibilityServiceFactory.create($($element).get(0), $scope);
      });

      self.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.VERTICAL,
         panes: [{size: '50%', min: '120px'}, {size: '50%', min: '120px'}]
      };

      self.navigateToFocusObject = navigateToFocusObject;
      $scope.showActionsMenu = showActionsMenu;
      self.selectRelation = selectRelation;
      self.selectObject = selectObject;

      $scope.preselectComparator = function (item) {
         return item.id === self.selectedObjectId;
      };

      getData();


      $scope.$on('dataRefreshInvocationEvent', function() {
         fetchItems(false);
      });

      // update related items list on modelChanged event
      $scope.$on('modelChanged', function(event, objectChangeInfo) {
         if (objectChangeInfo.operationType === 'DELETE') {
            // focused object is deleted
            if ($scope._view.viewDetails.context.id === objectChangeInfo.objectId) {
               showErrorPage();
               return;
            }
            if (!self.kendoGrid) {
               fetchItems(false, objectChangeInfo);
               return;
            }
            var rows = self.kendoGrid.dataSource.data();
            for (var i = 0; i < rows.length; i++) {
               if (rows[i].id === objectChangeInfo.objectId) {
                  self.kendoGrid.dataSource.remove(rows[i]);
                  break;
               }
            }
            if (objectChangeInfo.objectId === self.selectedObjectId) {
               showErrorPage();
            }
            fetchCount();
         } else {
            fetchItems(false, objectChangeInfo);
         }
      });

      $scope.$watch('_view.viewDetails.context', function(newValue, oldValue) {
         //act only upon actual changes
         if (newValue === oldValue || !newValue) {
            return;
         }

         getData();
      });


      // Select a relation from relations shown, and fetch details.
      function selectRelation(node) {
         self.isBottomPanelLoading = true;

         // clear the item list as soon as the new node is selected, otherwise if the
         // request takes too long the side effect is you select Clusters, but the list
         // shows the previously select item types, e.g. Hosts
         self.relatedItems = [];

         if (!node) {
            self.isBottomPanelLoading = false;
            return;
         }

         self.selectedRelation = node;

         getBottom(node);
      }


      function selectObject(obj) {
         $scope._navigate('vsphere.core.inventory.serverObjectViewsExtension', obj.id);
         self.selectedObjectId = obj.id;
      }

      function openObject(obj) {
         $scope.gridOpts = null;
         $scope.objectnavigator.switchView({
            url: "resources/ui/views/objectnavigator/RelatedItemsView.html",
            context: obj,
            historyDescription: {
               icon: obj.primaryIconId,
               title: obj.name
            },
            navigate: {
               extensionId: 'vsphere.core.inventory.serverObjectViewsExtension',
               objectId: obj.id
            }
         });
      }

      function getData() {
         self.selectedRelation = null;
         self.selectedObjectId = null;
         context = $scope._view.viewDetails.context;
         // Get the id, name, icon details of context object from description if not available.
         // This happens when the browser view is refreshed for a focused object.
         // The "context" property does not contain id, use _uid instead.
         if (!context.id) {
            context.id = context._uid;
         }
         fetchItems(true);
      }

      function fetchItems(firstTime, objectChangeInfo, partialUpdate) {
         getTop(firstTime, objectChangeInfo, partialUpdate).then(function() {
            //Separate the extraction of count and items from the top list
            fetchCount();

            var relationContext =
                  navigatorRelationsService.consumeRelationContext() || {};

            if (relationContext.relation) {
               self.nodes.forEach(function(node) {
                  if (node.relationId === relationContext.relation) {
                     self.selectedRelation = node;
                  }
               });
            }

            selectRelation(self.selectedRelation);

            if (relationContext.relatedItemUri) {
               selectObject({ id: relationContext.relatedItemUri });
            }
         });
      }

      function getTop(firstTime, objectChangeInfo, partialUpdate) {
         // has a focus object, get the top list
         return $http.get('relateditems/summary/' + context.id + '?fetchCount=false')
             .then(function (resp) {
                getFocusedObjectProperties(firstTime, objectChangeInfo, partialUpdate);
                self.nodes = resp.data;
             });
      }

      function fetchCount() {
         var nodes = self.nodes;
         // for each relation get the aggregate collection count
         nodes.forEach(function (node) {
            $http.get('relateditems/summary/' + context.id + '?relationId=' + node.relationId)
                .then(function (resp) {
                   node.count = resp.data && resp.data[0] ? resp.data[0].count : null;
                });
         });
      }

      function getBottom(node) {
         var context = $scope._view.viewDetails.context;
         loadVirtualList(context.id, node.relationId, node.type);
         self.isBottomPanelLoading = false;
      }
      /*
       * Gets the focused object properties (name, icon and tagging labels) and sets
       * it on the focusedObject. It deals with the conditions for refresh and firstTimeInvocation.
       * On firstTime = true - existing values are used (no ds call is made)
       * On manual refresh and model refresh change events - dataservice call is made.
       */
      function getFocusedObjectProperties(firstTime, objectChangeInfo, partialUpdate) {
         if (firstTime && context.name) {
            self.focusObject = context;
            return;
         }

         // Check if coming from refresh due to model changed event.
         var focusedObjectId = $scope._view.viewDetails.context.id;
         if (partialUpdate) {
            var focusedObjectChanged = partialUpdate.updates
                .some(function(partialUpdateItem) {
                   var mor = partialUpdateItem.data;
                   var objectId = defaultUriSchemeUtil.createVmomiUri(
                       mor.type, mor.value, mor.serverGuid);
                   return focusedObjectId === objectId;
                });
            if (!focusedObjectChanged) {
               // no need to refresh the focused object.
               return;
            }
         }
         if (objectChangeInfo && focusedObjectId !== objectChangeInfo.objectId) {
            // no need to refresh the focused object.
            return;
         }
         // If this reaches here it means that its because of model refreshed event or
         // view manual refresh or browser refresh.
         dataService.getProperties(focusedObjectId, ['name','primaryIconId', 'labelIds'])
             .then(function(resp) {
                context.name = resp.name;
                var iconParts = iconService.parseIcon(resp.primaryIconId);
                $scope.iconNs = iconParts[0];
                context.primaryIconId = iconParts[1];

                context.taggingLabel = resourceUtil.getTaggingLabelString(resp.labelIds);
                self.focusObject = context;
             });
      }

      function navigateToFocusObject() {
         $scope._navigate('vsphere.core.inventory.serverObjectViewsExtension', self.focusObject.id);
         self.selectedObjectId = null;
      }

      function showActionsMenu($event) {
         var left =  $event.clientX;
         var top = $event.clientY;
         var selectedObjectIds = $event.data;
         actionsService.showObjectContextMenu($event, $scope, selectedObjectIds, left, top);
      }


      function loadVirtualList(objectId, relationId, type) {
         var dataParams = {
            constraintObjectId: objectId,
            queryFilterId: 'relatedItemsListFilterId',
            filterParams: [relationId],
            requestedProperties: ['primaryIconId', 'name', 'labelIds'],
            dataModels: [type || vcH5ConstantsService.OBJECT_MODEL]
         };
         var dataUrl = 'list/ex/';
         $scope.gridOpts = {
            columnDefs: [
               {
                  displayName: 'Name',
                  field: 'name',
                  template: function (data) {
                     var label = _.escape(data.name + resourceUtil.getTaggingLabelString(data.labelIds));
                     var italic = resourceUtil.getItalicClassName(data.labelIds, data.primaryIconId);

                     return '<div ng-non-bindable>' +
                               '<span class="object-navigator-icon"><span class="' + data.primaryIconId + '"></span></span>' +
                               '<span class="object-navigator-label ' + italic + '" title="' + label + '">' + label + '</span>' +
                               '<span class="vui-icon-object-nav-pan" style="display: none;"></span>' +
                            '</div>';
                  }
               }
            ],
            pageable: true,
            pageConfig: {
               displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING,
               hidePager: true
            },
            data: {
               url: dataUrl,
               params: dataParams,
               dataType: vuiConstants.grid.dataType.JSON,
               method: vuiConstants.grid.dataMethod.POST,
               contentType: "application/json",
               processData: false,
               beforeSend: function (xhr, listViewSpec) {
                  listViewSpec.data = JSON.stringify(listViewSpec.data);
               },
               data: 'data',
               total: 'totalResultCount'
            },
            selectedItems: [],
            height: '100%',
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            resizable: true,
            sortOrder: [{field: 'name', dir: 'asc'}],
            dataBound: function (data) {
               $scope.virtualListCount = data.sender.dataSource.total();
               if (!self.kendoGrid) {
                  self.kendoGrid = $element.find('#related-items-virtual-list > div')
                      .data('kendoGrid');
               }
               $element.find('#related-items-virtual-list .k-grid-header').css('display', 'none');
               self.kendoGrid.resize();

            }
         };

         $scope.$watch('gridOpts.selectedItems', function(newItems) {
            if (!newItems || newItems.length === 0) {
               return;
            }
            if (self.selectedObject && self.selectedObject.id === newItems[0].id) {
               return;
            }

            self.selectedObject = newItems[0];

            if (self.selectedElement) {
               self.selectedElement.off('click');
               self.selectedElement.find('.vui-icon-object-nav-pan').hide();
            }

            self.selectedElement = $element.find('#related-items-virtual-list .k-state-selected td');
            self.selectedElement.find('.vui-icon-object-nav-pan').show();
            var link = self.selectedElement.find('.vui-icon-object-nav-pan');
            link.on('click', function () {
               openObject(self.selectedObject);
            });

            selectObject(self.selectedObject);
         });
      }

      function showErrorPage() {
         $location.replace(); // do not push a new history entry
         var route = $location.search();
         route.forceNavigate = true;
         $location.search(route);
         delete route['forceNavigate'];
      }
   }

})();
