/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter turn boolean into @trueValue/@falseValue or Yes/No by default
 */
angular.module('com.vmware.platform.ui').filter('dateLocale', ['timeFormatterService',
   function(timeFormatterService) {
      'use strict';
      return function(value) {
         return timeFormatterService.timestampToText(value);
      };
   }]);