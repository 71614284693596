/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Renders a summary view. Usage:
 *  <div vx-summary="com.acme.product.CustomSummaryData"/>
 *
 * Where the "...CustomSummaryData" is the name of a model class in the backend.
 * See the vxSummaryTemplate.html documentation for the expected structure of the
 * returned json data.
 */
angular.module('com.vmware.platform.ui').directive('vxSummary', [
   '$rootScope',
   'dataService',
   'i18nService',
   'actionsService',
   'configurationService',
   function ($rootScope, dataService, i18nService, actionsService, configurationService) {
      return {
         scope: {
            model: '@vxSummary', // Acquire the string value passed in to the vx-summary attribute.
            imageUrl: '@vxSummaryImage', // Acquire the image url if provided.
            resourceBundle: '@vxResourceBundle',  // UI resource bundle for retrieving localized strings
            summaryImageTemplate: '@vxSummaryImageTemplate', // Template to override the object image area
            actionIds: '@vxSummaryActionIds', //(Optional) actions to be populated below metrics section
            liveRefreshProperties: '@'
         },
         templateUrl: 'resources/ui/views/summary/vxSummaryTemplate.html',
         link: function (scope, element, attrs) {
            angular.extend(scope, {i18n: scope.$root.i18n});

            if (scope.liveRefreshProperties) {
               scope.liveRefreshProps = _.map(scope.liveRefreshProperties.split(","), function (prop) {
                  return prop.trim();
               }).filter(function (prop) {
                  return prop.length !== 0;
               });
            } else {
               scope.liveRefreshProps = [];
            }

            scope.entityReference = $rootScope._route.objectId;
            scope.getObjSummaryData = getObjSummaryData;

            scope.getSummaryItemValue = function (summaryItem) {
               return ((summaryItem.value !== null) && (summaryItem.value !== "")) ? summaryItem.value : '\u00A0';
            };
            scope.getSummaryItemLabel = function (summaryItem) {
               return summaryItem.label ? i18nService.getString(scope.resourceBundle, summaryItem.label) : '\u00A0';
            };
            scope.getFreeCapacity = function (resourceMeter) {
               return resourceMeter.progress > 100 ?
                  i18nService.getString('Common', 'ResourceAllocationComponent.overusedCapFormat', resourceMeter.free) :
                  i18nService.getString('Common', 'ResourceAllocationComponent.freeCapFormat', resourceMeter.free);
            };
            scope.getBadgeTooltip = function (badge) {
               return badge.isToolTipLocalized ? badge.toolTip : i18nService.getString(scope.resourceBundle, badge.toolTip);
            };

            scope.summaryData = {}; // Mainly to avoid any "undefined" reference errors from watches that fly all over the place.
            getObjSummaryData();


            function getObjSummaryData() {
               var newSummaryData = [];

               var modelNames = scope.model.split(",");
               _.each(modelNames, function (modelName, idx) {
                  dataService.getData(
                        scope.entityReference, modelName,
                        {queryName: scope.entityReference + ':vxSummary.' + getObjSummaryData.name + "." + idx})
                  .then(function (data) {
                     var items = data && data.summaryItems ? data.summaryItems : [];

                     // Check if any items need to be not-available because the feature is disabled.
                     angular.forEach(items, function(summaryItem) {
                        if (summaryItem.feature) {
                           configurationService.isFeatureEnabled(summaryItem.feature)
                              .then(function (featureEnabled) {
                                 summaryItem.available = featureEnabled;
                              });
                        } else {
                           summaryItem.available = true;
                        }
                     });

                     // Keep the data at the corresponding index, so that subsequent
                     // concatenation of common properties can produce properly
                     // ordered results (i.e values form the first model
                     // come first, etc..)
                     newSummaryData[idx] = data;

                     scope.summaryData = {};

                     concatObjects(scope.summaryData, newSummaryData);
                  });
               });

               requestActionDefs();
            }

            function concatObjects(dest, sources) {
               _.each(sources, function (source) {
                  // Data for this index has not arrived yet.
                  if (!source) {
                     return;
                  }

                  _.each(_.keys(source), function (key) {
                     // We assume the properties for merging are always arrays - and merging
                     // means to concat their elements. That's it.
                     if (dest[key] instanceof Array) {
                        var newValue = dest[key].concat(source[key]);
                        dest[key] = newValue;
                     } else {
                        dest[key] = source[key];
                     }
                  });
               });
            }

            function requestActionDefs() {
               var actions = scope.actionIds && scope.actionIds.split(',');
               if (actions && actions.length) {

                  actionsService.getActions([scope.entityReference], actions)
                     .then(function(actionDefs) {
                        scope.actionItems = _.map(actionDefs, function(actionDef) {
                           return {
                              label: actionDef.action.label,
                              ariaLabel: actionDef.action.description,
                              isEnabled: actionDef.available,
                              onClick: function() {
                                 if (actionDef.available) {
                                    actionsService.invokeAction(actionDef);
                                 }
                              }
                           };
                        });
               });
               }
            }
         }
      };
   }]);
