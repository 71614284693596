/**
 * Clarity Modal decorators
 * - formats the title into two pieces, action and optional ( but recommended ) object title
 */
angular.module('com.vmware.platform.ui').directive('modalTitle', function() {
   return {
      restrict: 'A',
      scope: {
         primaryTitle: "=",
         secondaryTitle: "="
      },
      templateUrl: 'resources/ui/components/modal/modalTitle.html'
   };
});
