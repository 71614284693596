/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   export class PropertyViewService {

      static $inject = ['i18nService'];

      private constructor(private _i18nService:any) {
      }

      createPropertyViewBuilder(): PropertyViewBuilder {
         return new platform.PropertyViewBuilder(this._i18nService);
      }
   }

   angular.module('com.vmware.platform.ui')
            .service('vxPropertyViewService', PropertyViewService);
}
