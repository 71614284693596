/*globals common_ui */ //ignore common_ui TS module import in jslint
angular.module('com.vmware.platform.ui').service('storageProfileService', [
   'defaultUriSchemeUtil',
   'dataService',
   'i18nService',
   '$q',
   '$timeout',
   'authorizationService',
   function (defaultUriSchemeUtil, dataService, i18nService, $q, $timeout, authorizationService) {
      var DEFAULT_ID = 'defaultId';
      var VM_HOME_PROFILE_ID = 'vmHomeProfileId';

      var PBM_PROFILE_RELATION = "pbmProfiles";
      var STORAGE_PBM_PROFILE_TYPE = "PbmRequirementStorageProfile";
      var PROFILE_CONTENT_PROPERTY = "profileContent";
      var IOFILTER_INFO_PROPERTY = "iofilterInfo";

      var COMPATIBLE_STORAGE_PROPERTY = 'pbmCompatibleStorage';

      var VM_PROFILE_ASSIGNMENTS_PROPERTY = 'vmStorageProfileAssignments';

      var VM_RG_ASSIGNMENTS_PROPERTY = "vmReplicationGroupAssignments";

      var STORAGE_PROFILE_VIEW_PRIVILEGE = 'StorageProfile.View';

      return {
         makeProfile: makeProfile,
         makeProfileFromDevice: makeProfileFromDevice,
         fetchProfiles: fetchProfiles,
         getDefaultProfile: getDefaultProfile,
         getProfileIdFromSpec: getProfileIdFromSpec,
         findProfile: findProfile,
         requestVmProfileAndReplicationGroupAssignments: requestVmProfileAndReplicationGroupAssignments,
         requestCompatibleStorage: requestCompatibleStorage,
         requestCompatibilityResultForDatastore: requestCompatibilityResultForDatastore,
         createCompatibleStorageQuerySpecForProfile : createCompatibleStorageQuerySpecForProfile,
         createCompatibleStorageQuerySpecForVms: createCompatibleStorageQuerySpecForVms,
         buildStorageProfile : buildStorageProfile,
         buildStorageProfileInCreateMode : buildStorageProfileInCreateMode,
         fetchStorageProfilesData: fetchStorageProfilesData,
         findPmemStorageProfile: findPmemStorageProfile,
         isPmemStorageProfile: isPmemStorageProfile,
         isDefaultStorageProfile: isDefaultStorageProfile,
         isEncryptionStorageProfile: isEncryptionStorageProfile,
         findDiskAssignment: findDiskAssignment,
         areProfileSpecsEqual: areProfileSpecsEqual,
         buildStorageProfilesData: buildStorageProfilesData,
         buildErrorStorageProfilesData: buildErrorStorageProfilesData,
         DEFAULT_ID: DEFAULT_ID,
         VM_HOME_PROFILE_ID: VM_HOME_PROFILE_ID
      };

      function buildStorageProfile(vmStorageProfileAssignments, device, profiles, isUseVmHomeProfileAsDeafult) {
         var defaultProfileId = isUseVmHomeProfileAsDeafult && vmStorageProfileAssignments.homeStorageProfile
            ? vmStorageProfileAssignments.homeStorageProfile.profileId.uniqueId
            : DEFAULT_ID;
         var defaultSelection = _.find(profiles, function(profile) {
            return profile.id === defaultProfileId;
         });

         if (vmStorageProfileAssignments.diskAssignments && vmStorageProfileAssignments.diskAssignments.length > 0) {
            var storageProfileAssignment = _.find(vmStorageProfileAssignments.diskAssignments, function(diskAssignment) {
               return diskAssignment.diskId.split(':')[1] === device.key.toString();
            });

            if (storageProfileAssignment) {
               // Override the default selection with the assigned to this disk storage profile.
               defaultSelection = {
                  label: storageProfileAssignment.profile.name,
                  id: storageProfileAssignment.profile.profileId.uniqueId
               };
            }
         }

         return {
            options: profiles,
            selection: defaultSelection
         };
      }

      function buildStorageProfileInCreateMode(selectedStorageProfile, storageProfiles) {
         return {
            options: storageProfiles,
            selection: selectedStorageProfile
         };
      }

      function getDefaultProfile() {
         return {
            label: i18nService.getString('Common', "VmStorageProfile.DatastoreDefault"),
            id: DEFAULT_ID
         };
      }

      function fetchProfiles (serverGuid) {
         var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);

         var promise = dataService.getPropertiesByRelation(
            rootFolder,
            PBM_PROFILE_RELATION,
            STORAGE_PBM_PROFILE_TYPE,
            [PROFILE_CONTENT_PROPERTY, IOFILTER_INFO_PROPERTY],
            // Suppress error notification as it is likely to fail if SPS is down
            { skipErrorInterceptor: true });
         return promise.then(
            function (res) {
               return buildStorageProfiles(res);
            });
      }

      /**
       *
       * @param {string} serverGuid
       *
       *
       *
       * @param {string} options.vmId (optional)
       *       id (urn schema) of the vm for which the data is needed;
       *       if assignments are required, then this id must be set
       *
       * @param {boolean} options.includeKeepExisting (optional)
       *       whether or not to include the 'Keep Existing Profile' profile option
       *       to the profile options returned in the method promise;
       */
      function fetchStorageProfilesData(serverGuid, options) {

         var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);
         return $q.all({
               'privilegeData': hasProfilePermissions(rootFolder),
               'profilesData': doFetchStorageProfilesData(serverGuid, options)
         }).then(function (promiseReturnData){
            var privilegeData = promiseReturnData.privilegeData;
            var profilesData = promiseReturnData.profilesData;
            if (privilegeData[STORAGE_PROFILE_VIEW_PRIVILEGE]) {
               return profilesData;
            }
            return new common_ui.StorageProfilesData(
                  [],
                  undefined,
                  undefined,
                  undefined,
                  i18nService.getString('Common', 'storage.policy.dropdown.no.permissions')
            );
         });
      }

      function requestVmProfileAndReplicationGroupAssignments(vmIds) {
         return dataService.getPropertiesForObjects(vmIds, [
               VM_PROFILE_ASSIGNMENTS_PROPERTY, VM_RG_ASSIGNMENTS_PROPERTY]).then(function(response) {

            var result = {
               vmProfileAssignments: {},
               vmReplicationGroupAssignments: {}
            };

            _.forEach(vmIds, function(vmId) {
               if (response && response[vmId]) {
                  result.vmProfileAssignments[vmId] =
                        response[vmId][VM_PROFILE_ASSIGNMENTS_PROPERTY];
                  result.vmReplicationGroupAssignments[vmId] =
                        response[vmId][VM_RG_ASSIGNMENTS_PROPERTY];
               }
            });
            return result;
         }, function() {
            return {
               vmProfileAssignments: {},
               vmReplicationGroupAssignments: {}
            };
         });
      }

      function doFetchStorageProfilesData(serverGuid, options) {
         var opts = options || {};

         var request = fetchProfiles(serverGuid, opts);
         var result = {
            storageProfiles: []
         };
         return request.then(function(response) {
                  var vmRef = opts.vmId
                        ? defaultUriSchemeUtil.getManagedObjectReference(opts.vmId)
                        : undefined;
                  if (response) {
                     result.storageProfiles = response;
                  }

                  if (opts.includeKeepExisting) {
                     // 'Keep existing' option
                     result.storageProfiles.splice(0, 0, makeKeepExistingProfile(DEFAULT_ID));
                  }

                  var storageProfilesData = new common_ui.StorageProfilesData(
                        result.storageProfiles,
                        vmRef,
                        null
                  );
                  return storageProfilesData;
               },
               function(error) {
                  var errorMsg = '';
                  if (error.data && error.data.message) {
                     errorMsg = error.data.message.replace(
                           'com.vmware.vim.binding.vmodl.MethodFault: ', '');
                  }

                  return buildErrorStorageProfilesData(errorMsg);
               });
      }

      function buildStorageProfiles (profilesData) {
         profilesData = _.sortBy(profilesData, function(profileData) {
            return profileData.profileContent.name.toLowerCase();
         });

         var storageProfiles = [];
         storageProfiles.push(getDefaultProfile());

         if (profilesData) {
            storageProfiles = storageProfiles.concat(_.map(profilesData,
                  function(storageProfileData) {
                     var label = "";
                     var id = "";
                     if (storageProfileData.profileContent) {
                        label = storageProfileData.profileContent.name;
                        id = storageProfileData.profileContent.profileId.uniqueId;
                     }
                     var profileObj = storageProfileData.profileContent;
                     profileObj.iofilterInfo = storageProfileData.iofilterInfo;
                     return {
                        label: label,
                        id: id,
                        profileObj: profileObj
                     };
                  }));
         }

         return storageProfiles;
      }

      function buildStorageProfilesData(profilesData, options) {

         var storageProfiles = buildStorageProfiles(profilesData);

         var vmRef = options.vmId
               ? defaultUriSchemeUtil.getManagedObjectReference(options.vmId)
               : undefined;
         var storageProfilesData;

            if (options.includeKeepExisting) {
               // 'Keep existing' option
               storageProfiles.splice(0, 0, makeKeepExistingProfile(DEFAULT_ID));
            }

            storageProfilesData = new common_ui.StorageProfilesData(
                  storageProfiles,
                  vmRef,
                  null
            );
         return storageProfilesData;
      }

      function buildErrorStorageProfilesData(errorMessage) {

         return new common_ui.StorageProfilesData(
               [],
               undefined,
               undefined,
               undefined,
               errorMessage
         );
      }

      function buildVmProfilesData(vmId, allProfiles, profileAssignments, includeKeepExisting, vmDiskKeys) {
         var vmRef = vmId
               ? defaultUriSchemeUtil.getManagedObjectReference(vmId)
               : undefined;
         var profiles = angular.copy(allProfiles);
         var existingVmStorageProfileAssignment;
         if (profileAssignments) {

            // 'Keep existing' option is essentially the same as the
            // default one when there are no existing assignments.
            var keepExistingProfile = makeKeepExistingProfile(DEFAULT_ID);
            if (!includeKeepExisting) {
               keepExistingProfile = getDefaultProfile();
            }
            existingVmStorageProfileAssignment = {vmHomeProfile: keepExistingProfile};

            var data = profileAssignments;
            if (data.homeStorageProfile) {
               keepExistingProfile = makeKeepExistingProfile(data.homeStorageProfile.profileId.uniqueId);
               existingVmStorageProfileAssignment.vmHomeProfile =
                     {
                        label: data.homeStorageProfile.name,
                        id: data.homeStorageProfile.profileId.uniqueId,
                        profileObj: data.homeStorageProfile
                     };
            }
            if (data.diskAssignments) {
               existingVmStorageProfileAssignment.diskProfileAssignments = [];
               _.forEach(vmDiskKeys, function(diskId) {
                  existingVmStorageProfileAssignment.diskProfileAssignments.push({
                     diskId: diskId.toString(),
                     profile: {
                        label: i18nService.getString('Common', 'VmStorageProfile.DatastoreDefault'),
                        id: DEFAULT_ID,
                        profileObj: null
                     }
                  });
               });
               _.forEach(data.diskAssignments, function(diskSpec) {
                  var diskId = _.last(diskSpec.diskId.split(':'));
                  var diskAssignment = _.find(
                        existingVmStorageProfileAssignment.diskProfileAssignments,
                        function(assignment) {
                           return diskId === assignment.diskId;
                        });
                  if (diskAssignment) {
                     diskAssignment.profile = {
                        label: diskSpec.profile.name,
                        id: diskSpec.profile.profileId.uniqueId,
                        profileObj: diskSpec.profile
                     };
                  }
               });
            }

            if (data) {
               // Add the 'keep existing' profile option if required.
               if (includeKeepExisting) {
                  keepExistingProfile.id = existingVmStorageProfileAssignment.vmHomeProfile.id;
                  keepExistingProfile.profileObj = existingVmStorageProfileAssignment.vmHomeProfile.profileObj;
                  profiles.unshift(keepExistingProfile);
               }
            }
         }

         return new common_ui.StorageProfilesData(
               profiles,
               vmRef,
               existingVmStorageProfileAssignment
         );
      }

      function hasProfilePermissions(vcFolderRef) {
         return authorizationService
               .checkGrantedPrivilege(vcFolderRef, STORAGE_PROFILE_VIEW_PRIVILEGE)
               .then(function(permissionsData) {
                  var result = {};
                  result[STORAGE_PROFILE_VIEW_PRIVILEGE] = permissionsData;
                  return result;
               });
      }

      /**
       * Return either undefined or the profile id.
       *
       * @param {com.vmware.vim.binding.vim.vm.ProfileSpec|undefined} profileSpec
       * @returns {string|undefined}
       */
      function getProfileIdFromSpec(
            profileSpec /* com.vmware.vim.binding.vim.vm.ProfileSpec */) {
         if (profileSpec) {
            const clazz = profileSpec._type;
            if (clazz === "com.vmware.vim.binding.vim.vm.DefinedProfileSpec") {
               return profileSpec.profileId;
            } else if (clazz === "com.vmware.vim.binding.vim.vm.DefaultProfileSpec") {
               return DEFAULT_ID;
            }
         }
         return undefined;
      }

      function findProfile(storageProfiles, profile) {
         var id = profile._type === 'com.vmware.vim.binding.vim.vm.DefaultProfileSpec' ?
            DEFAULT_ID : profile.profileId;
         var foundProfile = _.find(storageProfiles, function(profileOption) {
            return id === profileOption.id;
         });

         return foundProfile;
      }

      function makeProfile(profileId, replicationGroupInfo) {
         if (profileId === DEFAULT_ID || !profileId) {
            return {
               _type: 'com.vmware.vim.binding.vim.vm.DefaultProfileSpec'
            };
         }
         var spec = {
            _type: 'com.vmware.vim.binding.vim.vm.DefinedProfileSpec',
            profileId: profileId
         };

         if (replicationGroupInfo && replicationGroupInfo.id) {
            spec.replicationSpec = {
              _type: "com.vmware.vim.binding.vim.vm.replication.ReplicationSpec",
               replicationGroupId: replicationGroupInfo.id
            };
         }

         return spec;
      }

      function makeKeepExistingProfile(profileId) {
         var profile = {};
         if (profileId && profileId !== DEFAULT_ID) {
            profile.id = profileId;
         }
         profile.label = i18nService.getString('Common', 'VmStorageProfile.KeepExisting');
         profile.keepExistingProfileAssignments = true;
         return profile;
      }

      function makeProfileFromDevice(rawDevice, storageProfiles, storageProfileAssignments,
            replicationGroupInfo, isUseVmHomeProfileAsDeafult) {
         var profileOptions = this.buildStorageProfile(storageProfileAssignments, rawDevice,
            storageProfiles, isUseVmHomeProfileAsDeafult);
         return this.makeProfile(profileOptions.selection.id, replicationGroupInfo);
      }

      function requestCompatibilityResultForDatastore(profile, storageRef) {
         if (!profile || !storageRef) {
            return $q.when(null);
         }

         var querySpec = (storageRef.type === "Datastore")
               ? createCompatibleStorageQuerySpecForProfileInternal(profile, [storageRef], [])
               : createCompatibleStorageQuerySpecForProfileInternal(profile, [], [storageRef]);

         return this.requestCompatibleStorage(querySpec).then( function(result) {

            if (!result || !result.compatibilityResults) {
               return {};
            }

            return {
               compatibilityResult:
                  (result.compatibilityResults &&
                     result.compatibilityResults.length) ? result.compatibilityResults[0] : null,
               replicationGroupInfo: result.replicationGroupInfo
            };
         });
      }

      function createCompatibleStorageQuerySpecForProfile(storageProfilesData, dsRefs, storagePodRefs) {
         return createCompatibleStorageQuerySpecForProfileInternal(
               (storageProfilesData && storageProfilesData.selectedProfile)
                  ? storageProfilesData.selectedProfile.profileObj
                  : null,
               dsRefs,
               storagePodRefs);
      }

      function createCompatibleStorageQuerySpecForProfileInternal(profile, dsRefs, storagePodRefs) {
         var spec = {
            _type: 'com.vmware.vsphere.client.common.spbm.spec.CompatibleStorageQuerySpec',
            profile:  profile,
            datastoreRefs: dsRefs,
            storagePodsRefs: storagePodRefs
         };

         if (spec.profile) {
            spec.profile._type = 'com.vmware.vim.binding.pbm.profile.CapabilityBasedProfile';
         }

         return spec;
      }

      function createCompatibleStorageQuerySpecForVms(vmIds, dsRefs, storagePodRefs) {
         var spec = {
            _type: 'com.vmware.vsphere.client.common.spbm.spec.CompatibleStorageQuerySpec',
            profile: null,
            vmRefs: _.map(vmIds, function(vmId) {
               return defaultUriSchemeUtil.getManagedObjectReference(vmId);
            }),
            datastoreRefs: dsRefs,
            storagePodsRefs: storagePodRefs
         };

         return spec;
      }

      function requestCompatibleStorage(compatibleStorageQuerySpec) {

         if (!compatibleStorageQuerySpec ||
            (isNullOrEmpty(compatibleStorageQuerySpec.datastoreRefs) &&
             isNullOrEmpty(compatibleStorageQuerySpec.storagePodsRefs)) ||
            ( (!compatibleStorageQuerySpec.profile ||
            compatibleStorageQuerySpec.profile.id === null ||
            compatibleStorageQuerySpec.profile.id === DEFAULT_ID) && !compatibleStorageQuerySpec.vmRefs)) {
            var deferred = $q.defer();

            // In order to remove the grouping headers, the grid should be removed from the DOM and re-added
            // with the changed grid options. If the grid options are updated in the same digest cycle, the
            // grouping headers will remain. That's why we use timeout to defer the addition of the grid
            // to the next digest cycle.
            $timeout(function () {
               deferred.resolve(null);
            }, 0);

            return deferred.promise;
         }

         var serverGuid;
         if (!isNullOrEmpty(compatibleStorageQuerySpec.datastoreRefs)) {
            serverGuid = compatibleStorageQuerySpec.datastoreRefs[0].serverGuid;
         } else {
            serverGuid = compatibleStorageQuerySpec.storagePodsRefs[0].serverGuid;
         }

         var rootFolder = defaultUriSchemeUtil.createVmomiUri('Folder', 'group-d1', serverGuid);

         return dataService.getProperties(rootFolder, [COMPATIBLE_STORAGE_PROPERTY], {
            propertyParams:[{
               propertyName: COMPATIBLE_STORAGE_PROPERTY,
               parameterType: compatibleStorageQuerySpec._type,
               parameter: compatibleStorageQuerySpec
            }]
         }).then(function (response) {
            return response ? response[COMPATIBLE_STORAGE_PROPERTY] : null;
         });
      }

      function isDefaultStorageProfile(storageProfile) {
         if (!storageProfile) {
            // No profile is equivalent to default profile.
            return true;
         }

         if (storageProfile.id === DEFAULT_ID) {
            // Exact default profile match
            return true;
         }

         // This is definitely not the default profile
         return false;
      }

      function findPmemStorageProfile(storageProfiles) {
         var foundProfile = _.find(storageProfiles, function(storageProfile) {
            return isPmemStorageProfile(storageProfile);
         });

         return foundProfile;
      }

      function isPmemStorageProfile(storageProfile) {
         if (!storageProfile) {
            return false;
         }

         var profileObject = storageProfile.profileObj;
         if (!profileObject) {
            return false;
         }

         var profileConstraints = profileObject.constraints;
         if (_.isEmpty(profileConstraints) || _.isEmpty(profileConstraints.subProfiles)) {
            return false;
         }

         var result = _.any(profileConstraints.subProfiles, function (subProfile) {
            if (!subProfile || _.isEmpty(subProfile.capability)) {
               return false;
            }

            return _.any(subProfile.capability, function (capability) {
               if (!capability || !capability.id || !capability.id.namespace) {
                  return false;
               }

               return capability.id.namespace.toLowerCase() === "pmem";
            });
         });

         return result;
      }

      function isEncryptionStorageProfile(storageProfile) {
         if (!storageProfile) {
            return false;
         }

         var profileObject = storageProfile.profileObj || storageProfile;

         if (!profileObject || !profileObject.iofilterInfo) {
            return false;
         }

         return _.some(profileObject.iofilterInfo, function(filterInfo) {
            return filterInfo.filterType === "ENCRYPTION";
         });
      }

      function findDiskAssignment(vmStorageProfileAssignments, device) {
         if (!vmStorageProfileAssignments) {
            return undefined;
         }

         var deviceKey = device.key.toString();
         var result = _.find(vmStorageProfileAssignments.diskAssignments,
            function(diskAssignment) {
               return diskAssignment.diskId.split(':')[1] === deviceKey;
            });
         return result;
      }

      function isNullOrEmpty(array) {
         return !array || array.length === 0;
      }

      /**
       * Whether or not two profile specs are equal
       *
       * @param {com.vmware.vim.binding.vim.vm.ProfileSpec|undefined} profileSpecA
       * @param {com.vmware.vim.binding.vim.vm.ProfileSpec|undefined} profileSpecB
       * @param {boolean} areReplicationGroupsConsidered
       * @returns {boolean}
       */
      function areProfileSpecsEqual(
            profileSpecA,
            profileSpecB,
            areReplicationGroupsConsidered) {
         // Same references are always equal
         if (profileSpecA === profileSpecB) {
            return true;
         }

         // Both specs are not same references, and one of them is undefined/null
         // Obviously they are NOT equal
         if (!profileSpecA || !profileSpecB) {
            return false;
         }

         // Both references are defined, but of different class/type
         // They are NOT equal
         if (profileSpecA._type !== profileSpecB._type) {
            return false;
         }

         // Both types are equal, so we inspect the actual _type
         if ((profileSpecA._type === "com.vmware.vim.binding.vim.vm.EmptyProfileSpec") ||
             (profileSpecA._type === "com.vmware.vim.binding.vim.vm.DefaultProfileSpec")) {
            return true;
         }

         // Both types are equal and are "com.vmware.vim.binding.vim.vm.DefinedProfileSpec"
         // so we compare the profile ids.
         if (profileSpecA.profileId !== profileSpecB.profileId) {
            return false;
         }

         if (!areReplicationGroupsConsidered) {
            return true;
         }

         if (profileSpecA.replicationSpec === profileSpecB.replicationSpec) {
            return true;
         }

         const deviceGroupIdA = profileSpecA.replicationSpec &&
               profileSpecA.replicationSpec.replicationGroupId &&
               profileSpecA.replicationSpec.replicationGroupId.deviceGroupId &&
               profileSpecA.replicationSpec.replicationGroupId.deviceGroupId.id;

         const deviceGroupIdB = profileSpecB.replicationSpec &&
               profileSpecB.replicationSpec.replicationGroupId &&
               profileSpecB.replicationSpec.replicationGroupId.deviceGroupId &&
               profileSpecB.replicationSpec.replicationGroupId.deviceGroupId.id;

         if (deviceGroupIdA !== deviceGroupIdB) {
            return false;
         }

         const faultDomainIdA = profileSpecA.replicationSpec &&
               profileSpecA.replicationSpec.replicationGroupId &&
               profileSpecA.replicationSpec.replicationGroupId.faultDomainId &&
               profileSpecA.replicationSpec.replicationGroupId.faultDomainId.id;

         const faultDomainIdB = profileSpecB.replicationSpec &&
               profileSpecB.replicationSpec.replicationGroupId &&
               profileSpecB.replicationSpec.replicationGroupId.faultDomainId &&
               profileSpecB.replicationSpec.replicationGroupId.faultDomainId.id;

         if (faultDomainIdA !== faultDomainIdB) {
            return false;
         }

         return true;
      }
   }
]);
