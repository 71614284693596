angular
      .module('com.vmware.platform.ui')
      .service('informationalDialogService', ['$rootScope', 'vuiDialogService',

         function ($rootScope, vuiDialogService) {
            return {
               display: function (params) {
                  var $scope = $rootScope.$new();
                  $scope.informationalDialogOptions = {
                     title: params.title,
                     contentUrl: 'resources/ui/views/dialogs/InformationalDialog.html',
                     show: true,
                     confirmOptions: {
                        onClick: function () {
                           return true;
                        }
                     },
                     rejectOptions: {
                        visible: false
                     },
                     width: params.width || '400px'
                  };

                  var options = {
                     scope: $scope,
                     configObjectName: 'informationalDialogOptions'
                  };

                  $scope.informationalMessage = params.content;

                  $scope.imageUrl = params.imagePath;
                  vuiDialogService(options);
               }
            };
         }
      ]
);
