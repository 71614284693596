/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Logging service wrapper over angular $log
 * Example usage:
 * var log = logService('MyController');
 * var someObject = {key:'value'}
 * log.log('simple log');
 * log.warn('warning');
 * log.debug('debugging',);
 * log.info('some info',someObject);
 * log.error('bad error');
 */

angular.module('com.vmware.platform.ui').config(["$logProvider", function($logProvider){
   $logProvider.debugEnabled(true);  // to enable/disable debugging
}])
.value('logServiceWhiteList', /.*/)  // regex to control logs displayed
.factory('logService', ['$log','logServiceWhiteList','$location',
function($log,logServiceWhiteList,$location) {

   logServiceWhiteList = logServiceWhiteList || /.*/ ;
   var queryParam = $location.search();
   var logLevel = queryParam.logLevel ;
   logServiceWhiteList = logLevel ? new RegExp(logLevel,'i') : logServiceWhiteList;

   return function(caller){

      return{
         log: log('log'),
         debug: log('debug'),
         info: log('info'),
         warn: log('warn'),
         error: log('error'),
         setLogLevel: setWhiteList()
      };

   function setWhiteList(regex){
      return function(regex){
         logServiceWhiteList = new RegExp(regex);
      };

   }

   function log(level){

      var match = logServiceWhiteList ?
            caller.match(logServiceWhiteList) || level.match(logServiceWhiteList) : true;

      // ignore log/info/debug messages if we are not in debug mode
      if (!h5.debug && (
            level === 'log' ||
            level === 'info' ||
            level === 'debug')) {

         return angular.noop;
      }

      if (match){
         return function(){
            if (!$log[level]){
               level = 'log'; // workaround for debug in firefox
            }
            var args = Array.prototype.slice.call(arguments);
            if (caller){
               args.unshift('['+caller+']');
            }
            // NOTE: args.join returns a string containing the printed value.
            // We need this as the Selenium framework that captures the
            // console data in case of error, cannot obtain/get any argument
            // supplied to the $log call, besides the first one. So we join all
            // args into a single string value. As the log statements are most useful
            // for analysis of automated test runs using Selenium, we tailor our logging
            // to best fit the most useful use case.
            // Caveat: This means that if you are used to seeing live JS objects in
            // the browser console log - you will no longer see them as they will
            // be replaced with their string representation after the call to
            // args.join() below
            $log[level].apply($log, [args.join(" ")]);
         };
      }else {
         return angular.noop;
      }
   }

   /*
   * Returns the stack trace
   */
   function getStackTrace(){
      var err = new Error();
      return err.stack;
   }
};

}]);
