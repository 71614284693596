/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Popup directive for quickSearch.
 * The directive accepts a config object which is shared with parent scope.
 * It listens for changes in results on the config object.
 * The config object needs to have an onSelectCallback method which is called when the
 * user selects a result
 * Possible enhancements
 *    Dynamic templateUrl so that other popups could use the same directive
 *    Pass a result formatting function which returns count of results and
 *    an  array of the results so that keyboard navigation works or ability to disable
 *    keyboard navigation if not required
 */

angular.
   module('com.vmware.platform.ui')
   .directive('vxQuicksearchPopup',[

function() {

   // enter(13), esc(27), up(38) and down(40)
   var HOT_KEYS = [13, 27, 38, 40];

   return {
      restrict:'A',
      scope:{
         vxQuicksearchPopup:'='
      },
      // Possibly can be made dynamic to take different templates
      templateUrl:'resources/ui/views/search/QuickSearchPopupTemplate.html',
      replace:true,
      link: function postLink(scope, element, attrs) {
         // get the configuration for the popup which is two way bound with the parent scope
         var popupConf = scope.vxQuicksearchPopup ;

         // we need the count of results for navigation through the results with keyboard
         var resultCount;
         var resultItems;

         angular.extend(scope,{i18n : scope.$root.i18n});

         // watch for change in results set by the typeahead directive
         scope.$watch('vxQuicksearchPopup.results',function(newResults) {
            if(angular.isUndefined(newResults)){
               reset();
               return;
            }
            resultCount = 0 ;
            resultItems = [] ;
            scope.activeIdx =  -1 ;
            scope.noResults = _.isEmpty(newResults);

            // sorting results by label
            newResults = _.sortBy (newResults, function (resultSet) {
               return resultSet.label;
            });

            // iterate over each result and add an index to it and count total
            _.each(newResults,function(resultSet){
             resultItems.push.apply(resultItems, resultSet.results);
               _.each(resultSet.results,function(resultItem){
                  resultItem.index  = resultCount ;
                  resultCount = resultCount + 1 ;
               });
            });
            // set values on scope
            scope.results = newResults;
            scope.resultCount = resultCount ;
            resultCount = resultCount + 1 ;// for AllSearch Results

         });

         // reset the results
         var reset = function(){
            scope.activeIdx = -1 ;
            scope.results = undefined;
         };

         // function called by typeahead directive
         popupConf.onKeyPress = function(evt){
            handleKeyEvent(evt);
         };

         // return true if results are defined or we are searching
         scope.isOpen = function () {
            return angular.isDefined(scope.results) || popupConf.searching;
         };

         // return true if current activeId is same as parameter matchIdx
         scope.isActive = function (matchIdx) {
            return scope.activeIdx === matchIdx;
         };

         // make given Id as the active one
         scope.selectActive = function (matchIdx) {
            scope.activeIdx = matchIdx;
         };

         // run selectCallback method on given Id
         //TODO jaked change to a better name  https://reviewboard.eng.vmware.com/r/544118/
         scope.selectMatch = function (activeIdx) {
            select(activeIdx);
         };

         // true if searching is set
         scope.isSearching = function() {
            return popupConf.searching;
         };

         scope.doSearch = function() {
            if (popupConf.doSearch) {
               popupConf.doSearch();
            }
         };


         // call the onSelectCallBack method on the given object
         var select = function (activeIdx) {
            // if we are on the last item (i.e All search results , we need to navigate to search view)
            if (activeIdx === scope.resultCount){
               var path = "vsphere.core.search.domainView";
               popupConf.onSelectCallback(popupConf.searchTerm,path);
            }
            popupConf.onSelectCallback(resultItems[activeIdx]);
            reset();
         };

         //bind keyboard events: arrows up(38) / down(40), enter(13) and tab(9), esc(27)
         var handleKeyEvent = function(evt){

            // "interesting" key was pressed
            if (HOT_KEYS.indexOf(evt.which) === -1) {
               return;
            }

            // when typeahead is closed we are interested only in the enter(13) key.
            if (!scope.isOpen() && evt.which !== 13) {
               return;
            }

            evt.preventDefault();
            evt.stopPropagation();
            if (evt.which === 40 || evt.which === 9) {
               scope.activeIdx = (scope.activeIdx + 1) % resultCount;
            } else if (evt.which === 38) {
               scope.activeIdx = ( (scope.activeIdx > 0 ) ? scope.activeIdx : resultCount) - 1;
            } else if (evt.which === 13) {
               if (!scope.isOpen()) {
                  // typeahead is closed so we start a new search.
                  scope.doSearch();
               } else {
                  // selects the active item if such is available.
                  if (scope.activeIdx >= 0) {
                     select(scope.activeIdx);
                     reset();
                  }
               }
            } else if (evt.which === 27) {
               reset();
            }
            scope.$apply();
         };
      }
   };
}]);
