/*jshint bitwise: false*/
/* global StackTrace */
(function () {
   'use strict';
   // 2/11/19 - cpham, new feedback tool is in use and old files will be removed later

   angular.module('com.vmware.platform.ui').factory('feedbackService', feedbackService);
   feedbackService.$inject = ['vuiConstants', '$http', '$injector', 'userSessionService', 'i18nService',
      'configurationService', 'logService'];

   function feedbackService(vuiConstants, $http, $injector, userSessionService,
         i18nService, configurationService, logService) {
      var notificationService;
      var installationId;
      var userSession;
      var userName = '';
      var version;
      var versionHtml;
      var _isInternal = false;
      var internalHostName = '?';
      var dataHashCodes = [];
      // Regex to strip out "https://HOSTNAME:PORT/" from any message or stack (global, insensitive, multiple lines)
      var HTTPS_REGEX = /https:\/\/[^\/]*\//gim;
      // Regex to strip out explicit names
      var NAME_REGEX = /\b(name|named|host)[\W]+['"(].+['")]/gim;
      // Regex to strip out IPv4 addresses
      var IPv4_REGEX = /\b(?:[0-9]{1,3}\.){3}[0-9]{1,3}\b/gm;
      // Regex to strip out IPv6 addresses
      var IPv6_REGEX = /\b(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}\b/gm;
      // Regex to strip out file paths
      var FILE_REGEX = /(\bfile\b|\bpath\b).*(\/\S+)/gim;

      // Simple email address validation
      // 1) This is not as strict at the Angular email validation used in vFeedDialog
      // 2) It doesn't matter because this is just doing a sanity check at the service level
      //    independently from the UI.
      // 3) This is not a security fix per se because hackers can always post PhoneHome data directly.
      //    Also, email will be encrypted and not used directly.
      var EMAIL_REGEXP = /^[a-z0-9._%+-]+@[a-z0-9.-]+$/i;

      // Persist user email during the session to avoid re-entering it in feedback dialog and to include it with errors
      var savedEmail = '';

      var log = logService('feedbackService');

      var ERRORS_TO_IGNORE = [
         { message:'Out of memory', count: 0 },
         { message:'Nicht genügend Arbeitsspeicher.', count: 0 }
      ];

      return {
         init: init,
         isInternalUser: isInternalUser,
         sendFeedback: sendFeedback,
         sendDebugMessage: sendDebugMessage,
         getEmail: getEmail
      };

      function init() {
         if (!notificationService) {
            notificationService = $injector.get('notificationService');
         }
         userSessionService.getUserSession().then(function(session) {
            userSession = session;
            userName = userSession.userName.replace('@VSPHERE.LOCAL','');
         });

         // installationId is currently created by install script and persisted in webclient.properties
         configurationService.getProperty('installation.id').then(function(installationVal) {
            installationId = installationVal;
            if (!installationId) {
               installationId = 'internal_installation';
            }
         });

         // version is like 6.5.0-1234 where 1234 is vsphere-h5client gobuild number, or 0 for local dev builds
         version = i18nService.getString('CommonUi', 'client.version');

         // versionHtml is used for emails so that we can simply click on the build number to access that build
         var build = version.substring(version.indexOf('-') + 1);
         if (build === '0') {
            versionHtml = version;
         } else {
            versionHtml = '<a href=\"https://buildweb.eng.vmware.com/ob/' + build + '\">' + version + '</a>';
         }

         // TODO revisit later the concept of internal users. Not point of keeping while when email don't work
         //var host = $location.host();
         //_isInternal = (host === 'localhost' || host === 'h5-acceptance-ui.eng.vmware.com' || host === 'h5-demo-ui.eng.vmware.com');
         //if (_isInternal) {
         //   internalHostName = host;
         //}
      }

      function isInternalUser() {
         return _isInternal;
      }

      function getEmail() {
         return savedEmail;
      }

      /**
       * Send feedback through the PhoneHome platform
       *
       * @param data object used for both error reports and user feedback
       * {
       *    type:    'feedback' (default), 'error' or 'debug'
       *    email:   (optional, feedback only)
       *    cc_emails (optional array of emails to CC to)
       *    rating:  1, 2, 3 (feedback only)
       *    title:   (error only)
       *    message: (required)
       *    stack:   (error or debug)
       *    canvas:  (optional screenshot, feedback only)
       * }
       */
      function sendFeedback(data) {
         var userName = userSession ? userSession.userName.replace('@VSPHERE.LOCAL','') : '';
         // TODO user and message must be encrypted (message can be left in clear for errors)
         // only encrypting user for now
         if (!data.email) {
            data.email = savedEmail;
         } else if (data.email.match(EMAIL_REGEXP)) {
            savedEmail = data.email;
         } else {
            data.email = null;
         }
         var user = data.email ? data.email : (isInternalUser() ? userName : '');
         user = btoa(user);

         var isDebugReport = (data.type === 'debug');
         var isErrorReport = (data.type === 'error' || isDebugReport);
         if (isErrorReport && alreadyRecorded(data)) {
            return;
         }
         if (!data.message) {
            data.message = '';
         }
         var message = data.message;

         if (isErrorReport) {
            message = scrubSensitiveData(message);
         }

         // client_id is useful to track feedback within the same session
         var client_id = userSession ? userSession.hashedClientId : 0;

         var type = isErrorReport ? 'h5_ui_errors' : 'h5_ui_feedback';

         var uaStr = window.navigator.userAgent + ", language=" + window.navigator.language;

         var jsonObject = {
            //"@id": objectId,  not required, must be a string, not a number
            "@type": type,
            product: "vSphere H5 Client",
            version: version,
            user: user,
            client_id: client_id,
            creation_date: Date.now(),
            message: message,
            url: getPageUrl(),
            ua: uaStr
            // rating, screenshot, stack_trace: added below if necessary
         };

         if (data.rating) {
            // data.rating is 0 in case of error report
            jsonObject.rating = data.rating;
         }

         if (data.canvas) {
            // Scale down the image to save space on PhoneHome server
            // For a typical 1200x800 image the size will be 100K instead of 200k
            var smallerCanvas = document.createElement('canvas');
            var newWidth = (data.canvas.width * 3) / 4;
            var newHeight = (data.canvas.height * 3) / 4;
            smallerCanvas.width = newWidth;
            smallerCanvas.height = newHeight;
            var context = smallerCanvas.getContext('2d');
            context.drawImage(data.canvas, 0, 0, data.canvas.width, data.canvas.height, 0, 0, newWidth, newHeight);

            // convert to PNG and remove prefix "data:image/png;base64,"
            var canvasImage = smallerCanvas.toDataURL('image/png');
            canvasImage = canvasImage.substr(22);
            jsonObject.screenshot = canvasImage;

            // Comment this out if you prefer to retain the original image when sending feedback to SocialCast
            data.image = canvasImage;
         }

         // Use StackTrace library to unminify the stack trace
         // Except for debug messages which already come with an unminified stack trace
         if (data.stack && !isDebugReport) {
            var err = new Error(message);
            err.stack = data.stack;
            StackTrace.fromError(err).then(function(stackFrame) {
               var idx = data.stack.indexOf('\n');
               if (idx > 0) {
                  data.stack = data.stack.substring(0, idx);
               }
               for(var i = 0; i < stackFrame.length; i++) {
                  data.stack += '\n  at ' + stackFrame[i].toString();
               }
               // strip out host names
               data.stack = scrubSensitiveData(data.stack);
               if (isErrorReport) {
                  jsonObject.stack_trace = data.stack;
               } else {
                  // Append stack-trace to feedback message because h5_ui_feedback doesn't have a stack_trace field.
                  jsonObject.message += '\nstack-trace: ' + data.stack;
               }
               postToPhoneHome(data, jsonObject);
            }).catch(function(err) {
               log.error('updateStack failed: ' + err);
               jsonObject.stack_trace = scrubSensitiveData(jsonObject.stack_trace);
               postToPhoneHome(data, jsonObject);
            });

         } else {
            if (isDebugReport) {
               jsonObject.stack_trace = data.stack;
            }
            postToPhoneHome(data, jsonObject);
         }
      }

      function postToPhoneHome(data, jsonObject) {
         // Fling and GA collector ids are different
         var collectorId = data.type === 'feedback' && h5.isReleaseBuild ? h5.feedbackCollectorId : h5.telemetryCollectorId;

         // Note: _t param is not used for post requests, see @type in jsonObject
         var phoneHomeUrl = "https://vcsa.vmware.com/ph/api/hyper/send?_v=1.0&_c=" + collectorId +
            "&_i=" + installationId;

         var config = {
            method: 'POST',
            url: phoneHomeUrl,
            withCredentials: false,
            data: jsonObject,
            skipLoadingNotification: true,
            eventHandlers: {
               readystatechange: function(event) {
                  // Use this eventHandler to catch CORS errors (because there won't be any response)
                  // Browsers report a status of 0 in case of XMLHttpRequest errors
                  if (data.type === 'feedback' && event.currentTarget.status === 0) {
                     reportFeedbackError();
                  }
               }
            }
         };

         $http(config).then(function () {
            sayThankYou(data);
         }, function(response) {
            if (data.type === 'feedback') {
               reportFeedbackError();
            }
         });
      }

      /**
       * Use sendDebugMessage to record messages without UI notification (i.e. invisible to users). The typical use case
       * is to record some abnormal condition and provide additional data for offline debugging.
       * Note: this is disabled automatically in Release builds unless the client is in debug mode.
       *
       * @param message    debug message
       * @param debugData  an optional JS object that will be stringified and appended to the error message
       */
      function sendDebugMessage(message, debugData) {
         if (h5.isReleaseBuild && !h5.debug) {
            return;
         }
         var data = {};
         data.type = 'debug';
         data.message = 'DEBUG MSG: ' + message;

         if (debugData) {
            try {
               data.message += '\nDEBUG DATA: ' + JSON.stringify(debugData, null, 2);
            } catch (e) {
               // ignore JSON error
            }
         }

         // Although there is no Error thrown we still generate a stack trace to get more context
         data.stack = '';
         StackTrace.get().then(function(stackFrame) {
               for (var i = 0; i < stackFrame.length; i++) {
                  data.stack += '\n  at ' + stackFrame[i].toString();
               }
               // strip out host names
               data.stack = scrubSensitiveData(data.stack);
               sendFeedback(data);
            }
         ).catch(function() {
            // ignore the StackTrace error
            data.stack = scrubSensitiveData(data.stack);
            sendFeedback(data);
         });
      }

      function scrubSensitiveData(message) {
         // strip out host names
         message = message.replace(HTTPS_REGEX, '<host>');

         // Strip out object names for legal reasons (only in english for now)
         // Note that such messages sent as errors are very rare, see query http://tinyurl.com/hdxzksr
         message = message.replace(NAME_REGEX, '<name>');

         // strip out IP addresses
         message = message.replace(IPv4_REGEX, '<ip-address>');
         message = message.replace(IPv6_REGEX, '<ip-address>');

         // strip out file paths
         message = message.replace(FILE_REGEX, '<filepath>');

         return message;
      }

      // Strip out https://HOST:PORT/ from the current page url
      function getPageUrl() {
         var location = window.location.toString();
         return location.replace(HTTPS_REGEX,'');
      }

      function sayThankYou(data) {
         if (data.type !== 'feedback') {
            return;
         }

         var msg = i18nService.getString('Common', 'feedback.thankYou') + (data.email ? ' ' +
            _.escape(data.email) : '') + '! ' +
            (data.email ? i18nService.getString('Common', 'feedback.willContactYou') : '');
         var options = {
            'type': vuiConstants.notifications.type.INFO,
            'title': i18nService.getString('Common', 'feedback.sent'),
            'content': msg,
            'duration': 10000,
            'linkConfig': {
               'label': '',
               'onClick': angular.noop
            }
         };
         notificationService.notify(options);
      }

      function reportFeedbackError() {
         var msg = i18nService.getString('Common', 'feedback.error');
         var options = {
            'type': vuiConstants.notifications.type.ERROR,
            'title': i18nService.getString('Common', 'feedback.notSent'),
            'content': msg,
            'duration': 10000,
            'no_linkConfig': true
         };
         notificationService.notify(options);
      }

      /**
       * Return true if this data should not be recorded (case of errors happening too many times)
       * @param data
       */
      function alreadyRecorded(data) {
         for (var i = 0; i < ERRORS_TO_IGNORE.length; i++) {
            var error = ERRORS_TO_IGNORE[i];
            if (error.message === data.message) {
               if (error.count++ % 100 === 0) {
                  data.message += ' (count = ' + error.count + ')';
                  return false;
               } else {
                  return true;
               }
            }
         }
         return false;
      }

      function fastHashCode(s) {
         return s.split('').reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a;},0);
      }
   }
}());
