/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Directive to display toc headers for a navigation node
 *
 * Usage:
 * <div vx-navi-toc-headers="_view" categoriesId="vsphere.core.host.settingsCategories"></div>
 *
 * categoriesId specifies the extension point id used to retrieve the category list.
 */
angular.module('com.vmware.platform.ui').directive('vxNaviTocHeaders',
      ['extensionService', 'navigation', 'extensionCategoryUtil', '$q','telemetryHelperService',
      'vxNaviTocHeadersAccessibilityService',
function(extensionService, navigation, extensionCategoryUtil, $q, telemetryHelperService,
      vxNaviTocHeadersAccessibilityService) {

   return {
      scope: {
         _view: '=vxNaviTocHeaders',
         categoriesId: '='
      },
      templateUrl: 'resources/ui/views/tabs/vxNaviTocHeadersTemplate.html',
      link: function($scope, $element) {
         navigation.populateScope($scope);
         angular.extend($scope, {
            _generateTid: function(id) {
               return telemetryHelperService.generateTelemetryIdClass(id + " navi-toc-header");
            }
         });

         $scope.refreshToc = function() {
           var requests = [requestTocExtensions()];
           if ($scope.categoriesId) {
               requests.push(requestCategories());
           }

           $q.all(requests).then(function(responses) {
               var children = responses[0];
               var categories = responses.length > 1 ? responses[1] : null;
               if (categories) {
                   updateCategories(categories, children);
               } else {
                   $scope.categories = [{
                       title: "",
                       exts: children
                   }];
               }
           });
         };

         $scope.toggleCollapseExpand = function(index) {
             $scope.expanded[index] = !$scope.expanded[index];
         };

         var accessibilityService = new vxNaviTocHeadersAccessibilityService($element);
         $scope.handleFocus = accessibilityService.handleFocus.bind(accessibilityService);
         $scope.handleKeydown = accessibilityService.handleKeydown.bind(accessibilityService);
         $scope.handleBlur = accessibilityService.handleBlur.bind(accessibilityService);

         if (!$scope.categoriesId) {
            angular.extend($scope, {
               loaded: true,
               categories: [{
                  title: "",
                  exts: $scope._view.$children
               }],
               expanded: [true]
            });
            $scope.refreshToc();
         } else {
            requestCategories().then(function(data){
               updateCategories(data, $scope._view.$children);
               $scope.refreshToc();
            });
         }

         function requestTocExtensions() {
            return extensionService.getHostedExtensions($scope._view.$id, $scope._route.objectId).then(function(extensions) {
               _.each(extensions, function(ext) {
                  ext.$id = ext.uid;
               });
               return $q.when(extensions);
            });
         }

         function requestCategories() {
            return extensionService.getExtensions($scope.categoriesId, $scope._route.objectId);
         }

         function updateCategories(categories, extensions) {
            // call service to merge the categories data with the extensions in $scope
            $scope.categories = extensionCategoryUtil.mergeCategories(categories, extensions);
            $scope.expanded = $scope.categories.map(function(cat) {return true;});
            $scope.loaded = true;
         }
      }
   };
} ]);
