(function() {
   'use strict';
   
   angular.module("com.vmware.platform.ui")
      .factory("objectNavigatorAccessibilityServiceFactory",
         ["vuiTreeAccessibilityServiceFactory", "vxZoneService",
            function(vuiTreeAccessibilityServiceFactory, vxZoneService) {

            return {
               create: function (container, $scope) {
                  var service = vuiTreeAccessibilityServiceFactory.create(container, $scope);

                  service.expand = function() {
                     // check if we are on a collapsible section title
                     // and collapse / expand it
                     if (!this.currentFocusedElement) {
                        return;
                     }

                     if (this.currentFocusedElement.getAttribute("aria-expanded") === "false") {
                        this.currentFocusedElement.setAttribute("aria-expanded", "true");
                        vxZoneService.runInsideAngular(function () {
                           $("[vx-collapse-icon] i", this.currentFocusedElement).click();
                        });
                     }
                  };

                  service.collapse = function () {
                     // check if we are on a collapsible section title
                     // and collapse / expand it
                     if (!this.currentFocusedElement) {
                        return;
                     }

                     if (this.currentFocusedElement.getAttribute("aria-expanded") === "true") {
                        this.currentFocusedElement.setAttribute("aria-expanded", "false");
                        vxZoneService.runInsideAngular(function () {
                           $("[vx-collapse-icon] i", this.currentFocusedElement).click();
                        });
                     }
                  };

                  service.select = function () {
                     var _this = this;
                     // deselect current element
                     // select the new one and trigger click
                     if ($(this.currentFocusedElement).is('[ng-click]')) {
                        vxZoneService.runInsideAngular(function () {
                           $(_this.currentFocusedElement).click();
                        });
                     } else {
                        // is collapsible element
                        vxZoneService.runInsideAngular(function () {
                           $('[vx-collapse-icon] i', _this.currentFocusedElement).click();
                        });
                     }
                  };

                  return service;
               }
            };

         }]);
})();