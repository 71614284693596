/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for retrieving available storage locator items for VM provisioning.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('storageLocatorService', storageLocatorService);

   storageLocatorService.$inject = ['dataService', 'defaultUriSchemeUtil', 'managedEntityConstants'];

   function storageLocatorService (dataService, defaultUriSchemeUtil, managedEntityConstants) {

      // Public API
      var service = {
         getStorageLocatorData: getStorageLocatorData,
         getProvisioningStorageLocatorData: getProvisioningStorageLocatorData,
         getStorageObjectNameMap: getStorageObjectNameMap
      };
      return service;

      /**
       * For a given objectId, uses its EnvironmentBrowser to request datastores and
       * storagePods available for VM provisioning.
       *
       * @param objectId {String} String representation of the object reference which should be used
       *  to retrieve the available datastores.
       *  Should be of type HostSystem, ClusterComputeResource, ResourcePool or VirtualApp.
       *  For any other type retrieve the root folder's datastore information
       *
       * @param hostContext {optional} {String} String representation of context object which should
       * be passed to the environment browser when querying for the available storage objects.
       *
       * @returns {Promise} Promise with a single parameter which is the response data.
       */
      function getStorageLocatorData (objectId, hostContext) {
         if (isGetStorageLocatorDataForRootFolderToBeUsed(objectId, undefined)) {
            return getStorageLocatorDataForRootFolder(objectId);
         }

         var STORAGE_LOCATOR_ITEMS_DATA = "storageLocator:storageLocatorItemsData";
         if (hostContext) {
            return dataService.getProperties(
                  objectId,
                  [STORAGE_LOCATOR_ITEMS_DATA],
                  {
                     propertyParams: [{
                        propertyName: STORAGE_LOCATOR_ITEMS_DATA,
                        parameterType: 'com.vmware.vim.binding.vmodl.ManagedObjectReference',
                        parameter: defaultUriSchemeUtil.getManagedObjectReference(hostContext)
                     }]
                  }).then(function(response) {
                     return response ? response[STORAGE_LOCATOR_ITEMS_DATA] : null;

                  },
                  function(failureReason) {
                     failureReason = null; // avoid warning 'unused'
                     // If for whatever reason we can't read,
                     // proceed with returning null.
                     // Common reason could be missing System.Read permission
                     return null;
                  }
                  );
         }
         return dataService
               .getProperties(objectId, [STORAGE_LOCATOR_ITEMS_DATA])
               .then(function(response) {
                  return response ? response[STORAGE_LOCATOR_ITEMS_DATA] : null;
               });
      }

      /**
       * This method is used in the provisioning wizard. The original
       * getStorageLocatorData method is left for the other cases.
       *
       * @param {string} objectId
       */
      function getProvisioningStorageLocatorData (objectId) {
         if (isGetStorageLocatorDataForRootFolderToBeUsed(
               objectId, [managedEntityConstants.COMPUTE_RESOURCE])) {
            return getStorageLocatorDataForRootFolder(objectId);
         }

         var PROVISIONING_STORAGE_LOCATOR_ITEMS_DATA =
               "storageLocator:provisioningStorageLocatorItemsData";
         return dataService
               .getProperties(objectId, [PROVISIONING_STORAGE_LOCATOR_ITEMS_DATA])
               .then(function(response) {
                  return response ?
                        response[PROVISIONING_STORAGE_LOCATOR_ITEMS_DATA] : null;
               });
      }

      /**
       * For a given root folder, retrieve all datastore information
       * TODO: Add storage pod support when needed
       *
       * @param {string} objectId String representation of the object reference which
       * should be used to retrieve the available datastores. From it the root folder
       * object will be constructed automatically.
       *
       * @returns {Promise} Promise with a single parameter which is the response data.
       */
      function getStorageLocatorDataForRootFolder (objectId) {
         var folderId = defaultUriSchemeUtil.getRootFolderFromVsphereObjectId(objectId);
         var ALL_DATASTORE_INFO_PROPERTY = "storage:allDatastoresInfo";

         return dataService
            .getProperties(folderId, [ ALL_DATASTORE_INFO_PROPERTY ])
            .then(function(response) {
               var datastoreItems = response ? response[ALL_DATASTORE_INFO_PROPERTY] : null;
               return { "datastoreItems": datastoreItems, "storagePodItems": [] };
            });
      }

      /**
       * Given a StorageLocatorItemsData instance returns a map with datastore/StoragePOD ids as keys and
       * datastore/StoragePOD names as values.
       *
       * @param storageLocatorItemsData {StorageLocatorItemsData}
       *
       * @returns {Map} with all datastore/StoragePOD names.
       */
      function getStorageObjectNameMap (storageLocatorItemsData) {
         var nameMap = {};
         if (storageLocatorItemsData && storageLocatorItemsData.datastoreItems) {
            _.forEach(storageLocatorItemsData.datastoreItems, function(datastoreItem) {
               nameMap[defaultUriSchemeUtil.getVsphereObjectId(datastoreItem.storageRef)] = datastoreItem.name;
            });
         }

         if (storageLocatorItemsData && storageLocatorItemsData.storagePodItems) {
            _.forEach(storageLocatorItemsData.storagePodItems, function(podItem) {
               nameMap[defaultUriSchemeUtil.getVsphereObjectId(podItem.storageRef)] = podItem.name;
            });
         }
         return nameMap;
      }

      /**
       * @param {string} objectId
       *
       * @param {string[]} additionalTypesSupportingStorageLocatorItemsData
       *    A set of additional object types that support retrieval of storage
       *    locator data directly on them as a property.
       *
       *    The core set of objects is already predefined in this method body and is
       *    [HostSystem, ClusterComputeResource, ResourcePool, VirtualApp]
       *
       * @returns {boolean}
       */
      function isGetStorageLocatorDataForRootFolderToBeUsed(
            objectId,
            additionalTypesSupportingStorageLocatorItemsData) {
         var entityType = defaultUriSchemeUtil.getEntityType(objectId);

         const typesSupportingStorageLocatorItemsData = [
               managedEntityConstants.HOST,
               managedEntityConstants.CLUSTER,
               managedEntityConstants.RESOURCE_POOL,
               managedEntityConstants.V_APP
         ];

         if (typesSupportingStorageLocatorItemsData.indexOf(entityType) !== -1) {
            return false;
         }

         if (additionalTypesSupportingStorageLocatorItemsData &&
               (additionalTypesSupportingStorageLocatorItemsData
                     .indexOf(entityType) !== -1)) {
            return false;
         }

         return true;
      }

   }
})();
