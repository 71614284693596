/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for retrieving available disk formats for virtual disks.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('diskFormatService', diskFormatService);

   diskFormatService.$inject = ['datastoreTypeConstants', 'i18nService'];

   function diskFormatService (datastoreTypeConstants, i18nService) {

      var diskFormats = {
         sameAsSource: {
            type: 'sameAsSource',
            name: i18nService.getString('Common', 'VirtualDiskFormat.sameAsSource')
         },
         asDefinedInProfile: {
            type: 'asDefinedInProfile',
            name: i18nService.getString('Common', 'VirtualDiskFormat.asDefinedInProfile')
         },
         flat: { type: 'flat', name: i18nService.getString('Common', 'VirtualDiskFormat.flat') },
         thick: { type: 'thick', name: i18nService.getString('Common', 'VirtualDiskFormat.thick') },
         nativeThick: { type: 'nativeThick', name: i18nService.getString('Common', 'VirtualDiskFormat.nativeThick') },
         thin: { type: 'thin', name: i18nService.getString('Common', 'VirtualDiskFormat.thin') }
      };

      return {
         diskFormats: diskFormats,
         getDrsDiskFormats: getDrsDiskFormats,
         getAvailableDiskFormats: getAvailableDiskFormats
      };
      function getDrsDiskFormats (profile) {
         // formats user to choose for Ovf deploy via DRS placement
         return [diskFormats.flat, diskFormats.thin, diskFormats.thick];
      }

      function getAvailableDiskFormats (
         datastoreType, isVStorageSupported, isSameAsSourceSupported, isThickSupported
      ) {
         if (!datastoreType) {
            return [];
         }

         if (datastoreType === datastoreTypeConstants.VVOL) {
            return [diskFormats.thin, diskFormats.nativeThick];
         }

         if (datastoreType === datastoreTypeConstants.VSAN) {
            return [diskFormats.asDefinedInProfile];
         }

         if ((datastoreType === datastoreTypeConstants.NFS
            || datastoreType === datastoreTypeConstants.NFS41) && isVStorageSupported !== true) {
            return [diskFormats.thin];
         }

         var result = [];
         if (isSameAsSourceSupported === true) {
            result.push(diskFormats.sameAsSource);
         }

         result.push(diskFormats.flat);
         if (isThickSupported === true) {
            result.push(diskFormats.thick);
         }
         result.push(diskFormats.thin);
         return result;
      }
   }

})();
