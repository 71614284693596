/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   import IInjectorService = angular.auto.IInjectorService;
   import IPromise = angular.IPromise;

   /***
    * The class wraps the ng-next-app/platform/services/mutation.service.proxy
    */
   export class MutationServiceWrapper {
      static $inject = ['$injector'];

      private _ng2MutationService: MutationServiceWrapper;

      constructor(private $injector: IInjectorService) {
      }

      get ng2MutationService() {
         // Injecting mutation.proxy.service bridged from ng-next-app.
         // Using regular injection within .service('string',[...]) does not work,
         // due to race condition:
         // Downgraded NG2 modules are registered AFTER .service(..) injections are resolved
         // This way we're postponing injection till first call
         if (!this._ng2MutationService) {
            this._ng2MutationService = this.$injector.get('vcMutationService');
         }
         return this._ng2MutationService;
      }

      public add(propertyObjectType: string, propertySpec: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.add(propertyObjectType, propertySpec, operation);
      }

      public addMulti(propertyObjectType: string, propertySpecs: any[], operation?: string): IPromise<any> {
         return this.ng2MutationService.addMulti(propertyObjectType, propertySpecs, operation);
      }

      public apply(objectId: string, propertyObjectType: string, propertySpec: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.apply(objectId, propertyObjectType, propertySpec, operation);
      }

      public applyByPropName(objectId: string, propertyName: string, data: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.applyByPropName(objectId, propertyName, data, operation);
      }

      public applyOnMultiEntity(objectIds: string[], propertyObjectType: string,
                                propertySpec: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.applyOnMultiEntity(objectIds, propertyObjectType,
               propertySpec, operation);
      }

      public applyMultiSpec(objectId: string, propertyObjectType: string,
                            propertySpecs: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.applyMultiSpec(objectId, propertyObjectType,
               propertySpecs, operation);
      }

      public applyMulti(propertyObjectType: string, entitySpecs: any[], operation?: string): IPromise<any> {
         return this.ng2MutationService.applyMulti(propertyObjectType, entitySpecs, operation);
      }

      public remove(objectId: string, propertyObjectType: string, propertySpec: any,
                    operation?: string): IPromise<any> {
         return this.ng2MutationService.remove(objectId, propertyObjectType, propertySpec,
               operation);
      }

      public removeOnMultiEntity(objectIds: string[], propertyObjectType: string,
                                 propertySpec: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.removeOnMultiEntity(objectIds, propertyObjectType,
               propertySpec, operation);
      }

      public validate(objectId: string, propertyObjectType: string, propertySpec: any): IPromise<any> {
         return this.ng2MutationService.validate(objectId, propertyObjectType, propertySpec);
      }

      public validateSpec(propertyObjectType: string, propertySpec: any, operation?: string): IPromise<any> {
         return this.ng2MutationService.validateSpec(propertyObjectType, propertySpec, operation);
      }

      public validateMultiSpec(propertyObjectType: string, specObjects: any[]): IPromise<any> {
         return this.ng2MutationService.validateMultiSpec(propertyObjectType, specObjects);
      }

      public validateOnMultiEntity(objectIds: string[], propertyObjectType: string,
                                   propertySpec: any): IPromise<any> {
         return this.ng2MutationService.validateOnMultiEntity(objectIds, propertyObjectType,
               propertySpec);
      }

   }

   angular.module('com.vmware.platform.ui')
         .service('mutationService', MutationServiceWrapper);
}
