/* Copyright 2013-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {
   import IPromise = angular.IPromise;

   export class UserSessionService {
      static $inject = ['$injector'];
      private _vcUserSessionService: UserSessionService;
      public readonly WEB_CLIENT_SESSION_ID: string = 'webClientSessionId';
      public readonly VC_NO_ERROR_CODE:string = "noError";

      constructor(private $injector: any) {
      }

      get vcUserSessionService() {
         if (!this._vcUserSessionService) {
            this._vcUserSessionService = this.$injector.get('vcUserSessionService');
         }
         return this._vcUserSessionService;
      }

      get webClientSessionId() {
         return this.vcUserSessionService.webClientSessionId;
      }

      getUserSession(): IPromise<any> {
         return this.vcUserSessionService.getUserSession();
      }

      getServerInfo(serviceGuid: string): IPromise<any> {
         return this.vcUserSessionService.getServerInfo(serviceGuid);
      }

      getAllServersInfo(): IPromise<any> {
         return this.vcUserSessionService.getAllServersInfo();
      }

      ping(): IPromise<number> {
         return this.vcUserSessionService.ping();
      }

      sessionPing(requestTimeout: number = 5000): IPromise<number> {
         return this.vcUserSessionService.sessionPing(requestTimeout);
      }
   }
   angular.module('com.vmware.platform.ui').service('userSessionService',
      UserSessionService);
}
