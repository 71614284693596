(function() {
   angular.module('com.vmware.platform.ui')
      .directive('filterFocusRetainer', ['$document', function($document) {
         return {
            restrict: 'A',
            link: function($element) {

               $document[0].body.addEventListener('focusin', stopFocusinPropagation);

               $element.$on('$destroy', function() {
                  $document[0].body.removeEventListener('focusin', stopFocusinPropagation);
               });

               function stopFocusinPropagation(e) {
                  if (e && e.target) {
                     var targetElementClassAttribute = e.target.getAttribute("class");
                     if (targetElementClassAttribute && targetElementClassAttribute.indexOf('k-textbox') > -1) {
                        e.stopPropagation();
                     }
                  }
               }
            }
         };
      }]);
})();