/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Takes in the resource-info valueTypeName and apply a default formatter to it
 */
angular.module('com.vmware.platform.ui').filter('dataFormat', ['$filter',
function($filter) {
   return function(raw, valueTypeName) {

      // valueTypeName to formatter definition
      var map = {
            'Storage.B' : {
               name: 'bytes'
            }
      };
      var meta = map[valueTypeName];
      if (!meta) {
         return raw;
      }

      return $filter(meta.name)(raw);
   };
}]);