/*
 *  Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential
 */
/**
 * Service which handles the positioning of the vxValueInUnits dropdown content. In cases
 * when the parent container partially covers the content, the dropdown should open
 * upwards.
 *
 * Note: When using the vxValueInUnits directive in tests, make sure to use
 * the renderTemplateAndAppendToDom method instead of the renderTemplate one.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('vxValueInUnitsService',
      vxValueInUnitsService);

   function vxValueInUnitsService() {
      return {
         onRecommendedValuesExpandedChange: onRecommendedValuesExpandedChange
      };
   }

   function onRecommendedValuesExpandedChange(domElement, recommendedValuesExpanded) {
      if (recommendedValuesExpanded) {
         var offsetParentElement = domElement.context.offsetParent;
         var parentRectangle = offsetParentElement.getBoundingClientRect();

         var dropdownContentElement = $(domElement.find('table.recommended-values'));
         var dropdownContentRectangle = dropdownContentElement[0].getBoundingClientRect();

         var shouldOpenUpwards = isContentCoveredByParent(parentRectangle,
            dropdownContentRectangle);

         if (shouldOpenUpwards) {
            dropdownContentElement.addClass('recommended-values-openUpwards');
         }
      }
   }

   function isContentCoveredByParent(parentRectangle, dropdownContentRectangle) {
      var offsetParentBottomPosition = parentRectangle.bottom + parentRectangle.top;
      var dropdownBottomPosition = dropdownContentRectangle.bottom + dropdownContentRectangle.top;
      var shouldOpenMenuUpwards = offsetParentBottomPosition - dropdownBottomPosition < 0;
      return shouldOpenMenuUpwards;
   }
}());
