/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Master-details view controller.
 * Master-details view displays two views: master list view and details view one under another.
 *
 * Two hosted points should be defined, the first hosted point will be the master view,
 * and the second hosted point will be the details view.
 *
 * The following warning messages are displayed in the details view:
 * - "No item selected", when no items are selected in the master view
 * - "Multiple items selected", when multiple items are selected in the master view
 *
 * The controller expects the master view to set its selected items
 * into the scope variable called: "masterDetailsViewContext.selectedItems".
 *
 * Example:
 * 1. Defines Extension point for the master view
 *    <extensionPoint id="vsphere.core.samples.masterView">
 *       <objectType class="com.vmware.vise.mvc.model.ViewSpec"/>
 *    </extensionPoint>
 *
 * 2. Defines Extension point for the details view
 *    <extensionPoint id="vsphere.core.samples.detailsView">
 *       <objectType class="com.vmware.vise.mvc.model.ViewSpec"/>
 *    </extensionPoint>
 *
 * 3. Defines extension for the master-details view
 *    <extension id="vsphere.core.sample.masterDetailsView">
 *       <extendedPoint>vsphere.core.samples.views</extendedPoint>
 *       <hostedPoint>vsphere.core.samples.masterView</hostedPoint>
 *       <hostedPoint>vsphere.core.samples.detailsView</hostedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>resources/ui/components/masterdetails/vxMasterDetailsView.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 *
 * 4. Defines extensions for my custom master views
 *    <extension id="vsphere.core.samples.myMasterView">
 *       <extendedPoint>vsphere.core.samples.masterView</extendedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>path-to-my-master-view-template/MyMasterView.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 *
 * 5. Defines extension for my custom details view
 *    <extension id="vsphere.core.samples.myDetailsView">
 *       <extendedPoint>vsphere.core.samples.detailsView</extendedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>path-to-my-details-view-template/MyDetailsView.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 */
module platform {
   class VxMasterDetailsViewController implements angular.IController {
      static $inject = ['$scope', 'i18nService', 'logService', 'navigation'];
      private log: any;
      masterViewTemplateUrl: string;
      detailsViewTemplateUrl: string;

      constructor(private $scope: any, private i18nService: any, logService: any, navigation: any) {
         this.log = logService('vxMasterDetailsViewController');
         this.$scope.masterDetailsViewContext = $scope.masterDetailsViewContext || {
               selectedItems: <Array<any>> [],
               detailsView: <any>null,
               selectedItem: <any> null,
               contextObjectId: navigation.getRoute().objectId
            };
         this.initMasterDetailsUrls();
      }



      private initMasterDetailsUrls() {
         if (!this.$scope._view.children || this.$scope._view.children.length !== 2) {
            this.log.error('Master-details view: Expected 2 hosted points.');
         } else {
            const masterViewResponse: any = this.$scope._view.children[0];
            const detailsViewResponse: any = this.$scope._view.children[1];

            this.masterViewTemplateUrl = masterViewResponse.contentSpec.url;
            this.detailsViewTemplateUrl = detailsViewResponse.contentSpec.url;

            this.$scope.masterDetailsViewContext.detailsView = detailsViewResponse;
         }
      }
   }
   angular.module('com.vmware.platform.ui').controller('vxMasterDetailsViewController',
      VxMasterDetailsViewController);
}
