/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Provides the ability to create subscriptions for element visibility change notifications.
 */
angular.module('com.vmware.platform.ui').factory('visibilityMonitorService', [
   '$rootScope', '$timeout', 'logService', 'vxZoneService',
   function($rootScope, $timeout, logService, vxZoneService) {
      var log = logService('visibilityMonitorService');

      var CHECK_VISIBILITY_AND_SEND_NOTIFICATIONS_TIMEOUT_NAME =
            "checkVisibilityAndSendNotificationsTimeout";

      var VISIBILITY_CHECK_TTL = 5;
      var VISIBILITY_CHECK_INTERVAL = 250;

      var subscribers = { };
      var currentSubscriberId = 0;
      var timeoutIds = { };

      function checkVisibilityAndSendNotifications() {
         var subscribersArray = _.values(subscribers);
         var subscribersElements = _.map(subscribersArray, function (data) {
            return data.element[0];
         });

         var callbacksToInvoke;
         var ttl = VISIBILITY_CHECK_TTL;

         do {
            callbacksToInvoke = [];

            var subscribersElementsVisibilityStatus =
               getVisibilityStatus(subscribersElements);

            /* Ignore jshint 'Don't make functions within a loop' warning*/
            /*jshint -W083 */
            _.each(subscribersArray, function (data, index) {
               var oldValue = data.oldVisibilityValue;
               var newValue = subscribersElementsVisibilityStatus[index];

               // If the subscriber's element visibility changed add the callback
               // with the correct arguments (new, old values) to the callbacksToInvoke
               // collection
               if (newValue !== oldValue) {
                  data.oldVisibilityValue = newValue;
                  callbacksToInvoke.push(
                     _.partial(data.callback, newValue, oldValue)
                  );
               }
            });

            // If there are subscribers to notify begin an $apply phase and invoke the
            // callbacks
            if (callbacksToInvoke.length > 0) {
               vxZoneService.runInsideAngular(function () {
                  $rootScope.$apply(function() {
                     /* Ignore jshint 'Don't make functions within a loop' warning*/
                     /*jshint -W083 */
                     _.each(callbacksToInvoke, function(callback) {
                        try {
                           callback();
                        } catch (error) {
                           log.error("Visibility change refresh callback failed with error: " + error);
                        }
                     });
                  });
               });
            }

            ttl--;
         } while (callbacksToInvoke.length > 0 && ttl >= 0);

         // Invoking a visibility callback might cause a different element to show
         // so after we invoke all the visibility change callbacks we need to
         // recheck the elements for visibility changes. We want to limit the max number
         // of iterations so for this we use the 'ttl' (time-to-live) var. If after the
         // visibility check cycle the 'ttl' var has value < 0 it means that the ttl
         // has expired i.e. some subscribers' callbacks might not have been invoked.
         // Log a warning to the console in this case.
         if (ttl < 0) {
            log.warn('TTL expired while performing checkVisibilityAndSendNotifications');
         }
      }

      // http://stackoverflow.com/questions/729921/settimeout-or-setinterval
      function createInfiniteTimeoutChain(callback, delay, timeoutName) {
         // Use setTimeout instead of the angular $timeout as create infinity
         // timeout will cause the callback passed to testability.whenStable to never be
         // invoked which causes E2E tests to fail i.e. we always think that there are
         // pending requests
         timeoutIds[timeoutName] = setTimeout(function() {
            try {
               callback();
            } catch (e) {
            }

            createInfiniteTimeoutChain(callback, delay, timeoutName);
         }, delay);
      }

      /**
       * Creates a subscription for element visibility change notifications.
       *
       * Note: The visibility checks happen every 250 ms for performance reasons and
       * also because visibility change of a dom element does not trigger an event.
       * So keep in mind that notifications can be delayed up to ~250ms.
       *
       * @param {Object} element - a DOM element or a jQuery object pointing to a single
       *    DOM element whose visibility is to be observed
       * @param {function(boolean, boolean)} callback - a callback function
       *    that is invoked when the element visibility changes. The first argument
       *    passed to function is the new visibility value and the second argument is
       *    the old visibility value. It's guaranteed that the 2 arguments will have
       *    different values!
       * @returns {Object} subscriber id used to unsubscribe
       */
      var subscribeForVisibilityChange = function (element, callback) {
         if (!element) {
            throw new Error(
               'element can not be null'
            );
         }

         if (!_.isNumber(element.length)) {
            element = [element];
         }

         if (element.length !== 1 || !_.isElement(element[0])) {
            throw new Error(
               'element must be a DOM element or' +
               ' a jQuery object pointing to a single DOM element'
            );
         }

         if (!_.isFunction(callback)) {
            throw new Error('callback is required and must be a function');
         }

         element = $(element);

         currentSubscriberId++;

         var subscriberId = currentSubscriberId;
         subscribers[subscriberId] = {
            id: subscriberId,
            element: element,
            callback: callback,
            oldVisibilityValue: getVisibilityStatus(element)[0]
         };
         return subscriberId;
      };

      /**
       * Removes a subscription for element visibility change notifications
       *
       * @param {Object} subscriberId - a subscriber id returned as a result
       *    of a call to the 'subscribeForVisibilityChange' method
       */
      var unsubscribe = function (subscriberId) {
         if (!_.isNumber(subscriberId)) {
            throw new Error(
               'invalid subscriberId: ' + subscriberId
            );
         }

         if (!(subscriberId in subscribers)) {
            return;
         }

         delete subscribers[subscriberId];
      };

      /**
       * Calculates the visibility of elements. An element is considered visible
       * if $(element).is(':visible') returns true.
       * See https://api.jquery.com/visible-selector/ for more info about visibility
       *
       * @param {Array<Object>} elements - an array of DOM elements or a jQuery object
       * @returns {Array<boolean>} the calculated visibility of each element (in the
       * same order as the input array or jQuery object)
       */
      var getVisibilityStatus = function (elements) {
         if (!elements || !_.isNumber(elements.length)) {
            throw new Error(
               'elements is required and must be an array of DOM elements or a jQuery object'
            );
         }

         var i, rawElements = [];
         for (i = 0; i < elements.length; i++) {
            if (!_.isElement(elements[i])) {
               throw new Error(
                  'elements[' + i + ']' + ' is not a DOM element'
               );
            }

            rawElements.push(elements[i]);
         }

         return _.map(rawElements, function (rawElement) {
            return $.contains(document.documentElement, rawElement);
         });
      };

      vxZoneService.runOutsideAngular(function() {
         createInfiniteTimeoutChain(
               checkVisibilityAndSendNotifications,
               VISIBILITY_CHECK_INTERVAL,
               CHECK_VISIBILITY_AND_SEND_NOTIFICATIONS_TIMEOUT_NAME
         );
      });

      // Cleanup on root scope destroy. Normally we wouldn't concern ourselves with
      // this, but during jasmine tests the service is instantiated multiple times
      // so to prevent memory leaks and memory spikes we need to do a proper clean up
      $rootScope.$on('$destroy', function() {
         _.each(_.keys(subscribers), function(subscriberId) {
            unsubscribe(Number(subscriberId));
         });

         currentSubscriberId = 0;

         vxZoneService.runOutsideAngular(function() {
            clearTimeout(timeoutIds[CHECK_VISIBILITY_AND_SEND_NOTIFICATIONS_TIMEOUT_NAME]);
         });

         timeoutIds = { };
      });

      return {
         subscribeForVisibilityChange: subscribeForVisibilityChange,
         unsubscribe: unsubscribe,
         getVisibilityStatus: getVisibilityStatus,
         // Used for testing purposes
         $$checkVisibilityAndSendNotifications: checkVisibilityAndSendNotifications
      };
   }]);