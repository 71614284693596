/*jshint scripturl:true*/
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').factory('notificationService', notificationService);

   notificationService.$inject = [
      '$timeout',
      '$rootScope',
      '$location',
      'vuiConstants',
      'vuiNotificationService',
      'feedbackService',
      'responseErrorInterceptor',
      'vFeedDialogService',
      'i18nService',
      '$log'
   ];

   function notificationService(
      $timeout,
      $rootScope,
      $location,
      vuiConstants,
      vuiNotificationService,
      feedbackService,
      responseErrorInterceptor,
      vFeedDialogService,
      i18nService,
      $log) {

      var INTERNAL_SERVER_ERROR = 'Internal Server Error';

      var _warningActive = false;
      var _lastErrorOptions;
      const ERROR_DURATION = 60000;  // 60 seconds
      const TASK_ERROR_DURATION_MS = 15000;  // 15 seconds

      $rootScope.showErrorNotification = h5.isErrorPopupEnabled;
      window._rootscope = $rootScope;

      // Public API
      var service = {
         notify: notify,
         notifyError: notifyError,
         notifyTaskError: notifyTaskError,
         isWarningActive: isWarningActive,
         showLastError: showLastError,
         type: {
            INFO: vuiConstants.notifications.type.INFO,
            SUCCESS: vuiConstants.notifications.type.SUCCESS,
            WARNING: vuiConstants.notifications.type.WARNING,
            ERROR: vuiConstants.notifications.type.ERROR
         }
      };
      return service;

      // PRIVATE functions

      /**
       * @return true if an error/warning was notified less than ERROR_DURATION seconds earlier
       */
      function isWarningActive() {
         return _warningActive;
      }

      function notifyError(title, message, stack, unhandledError) {
         if (!title) {
            title = i18nService.getString('Common', 'error.jsException.title');
         }
         notify({title: title, content: message, stack: stack, unhandledError: unhandledError});
      }

      /**
       * Directly call vuiNotificationService.
       * We may want additional logic to limit the number of notifications displayed.
       * @param options
       */
      function notify(options) {
         if (!options.content) {
            options.content = '';
         }
         var content = options.content;
         var message = '';

         if (typeof content === 'string') {
            // Leave only the <%= errorMessage %> part from error.jsp (Java exception)
            var startTag = '<div id="errorMessage">';
            var indexStart = content.indexOf(startTag);
            if (indexStart > 0) {
               content = content.substring(indexStart + startTag.length, content.indexOf('</div>', indexStart));
            }
            else if (content.indexOf('<html') >= 0) {
               // Leave only the <body> content in case of other HTML message
               content = content.replace(/[\s\S]*<body>/, '');
               content = content.replace(/h1>/, 'b>');
               content = content.replace(/<img .*\/>/,'');
               content = content.replace(/<\/body>[\s\S]*/, '');
            }
            message = content;

         } else if (typeof content === 'object') {
            // Case of response error returned as a json object
            if (content.message) {
               message = content.message;
            }
            if (content.cause) {
               message += '\n' + i18nService.getString('Common', 'notification.cause', content.cause);
            }
            if (content.de_properties) {
               message += '\n\n' + i18nService.getString('Common', 'notification.properties', content.de_properties);
            }
            if (content.de_objects) {
               message += '\n\n' + i18nService.getString('Common', 'notification.objects', content.de_objects);
            }

            if (content.stackTrace && !options.stack) {
               options.stack = content.stackTrace;
            }
         } else {
            message = content.toString();
         }

         if (!options.type) {
            options.type =  vuiConstants.notifications.type.ERROR;
         }

         options.content = options.displayAsHtml ? message : _.escape(message).replace(/\n/g, '<br/>');
         if (options.type === vuiConstants.notifications.type.ERROR) {
            if (!responseErrorInterceptor.isErrorShown()) {
               handleError(options, message);
            }
         } else {
            vuiNotificationService.notify(options);
         }
      }

      // Note: message remains unescaped to be able to capture the correct data in PhoneHome
      // It is escaped in options.content which is what is being displayed.
      function handleError(options, message) {
         var localClient = ($location.host === 'localhost');

         if (!localClient && $rootScope.showErrorNotification) {
            // In line script to set the global flag showErrorNotification within the notification popup. It also
            // closes the popup automatically. Sorry, no cleaner way to do this right now...
            var setFlagScript = "javascript:_rootscope.showErrorNotification=false;" +
                  "var _elts = document.getElementsByClassName('vui-icon18-dialog-close');" +
                  "angular.element(_elts[_elts.length-1]).trigger('click');";
            var hideFutureErrors = i18nService.getString('Common', 'notification.hideFutureErrorsInThisSession.label');
            options.content += "<br/><br/><i>" +
                  "<a href=\"" + setFlagScript + "\">" + hideFutureErrors + "</a></i>";
         }

         if (!options.duration) {
            // Leave error message opened for 1 minute (vui default is 10 seconds)
            options.duration = ERROR_DURATION;
         }

         var addDetailsHere = i18nService.getString('Common', 'notification.addDetailsHere.label');
         var addDetailsNote = '\n\n(' + addDetailsHere + ')';
         if (options.title === INTERNAL_SERVER_ERROR) {
            // Less scary title than "Internal Server Error"
            options.title = i18nService.getString('Common', 'error.javaException.title');
         }

         var errorData = {
            type: 'error',
            title: options.title,
            message: message,  // unescaped on purpose
            stack: options.stack
         };

         // Errors are collected to PhoneHome automatically EXCEPT for dev runtime OR on release builds where
         // telemetry (i.e. CEIP) was disabled AND when not in debug mde.
         var collectErrors = !localClient && (h5.isTelemetryEnabled || !h5.isReleaseBuild || h5.debug);
         if (collectErrors) {
            feedbackService.sendFeedback(errorData);
         }
         if (!options.no_linkConfig) {
            var sendDetailsToVMware = i18nService.getString('Common', 'notification.sendDetailsToVMware.label');
            options.linkConfig = {
               'label': sendDetailsToVMware,
               'onClick': function () {
                  if (localClient) {
                     feedbackService.sendFeedback(errorData);
                  }
                  // Open feedback dialog to enter more information
                  $rootScope.errMessage = message + addDetailsNote;
                  $rootScope.stackTrace = errorData.stack;
                  vFeedDialogService.showFeedbackDialog(true);
               }
            };
         }
         _lastErrorOptions = options;

         // Leave a trace in the browser console from the error.
         $log.error("notificationService::handleError",
               "\nmessage = ", errorData.message,
               "\nstack = ", errorData.stack);

         // Error popup shown automatically when in debug mode OR not a release build (i.e. it's a Fling) OR not an
         // unhandled exception (i.e. a valid error to display) AND the user never clicked to hide all errors.

         var displayErrorPopup = (h5.debug || !options.unhandledError)
               && $rootScope.showErrorNotification;

         if (displayErrorPopup) {
            showLastError();
         } else {
            // This will trigger the display of a warning icon in the bottom right corner, that the
            // user can click to get the full error popup
            _warningActive = true;

            $timeout(function() {
               _warningActive = false;
            }, ERROR_DURATION);
         }
      }

      function showLastError() {
         _warningActive = false;
         vuiNotificationService.notify(_lastErrorOptions);
         updateNotificationPopupZIndex();
      }

      function updateNotificationPopupZIndex() {
         // Work-around to make error notifications appear above dialogs and wizards...
         $timeout(function () {
            var elts = document.getElementsByClassName('vui-popup vui-notification bottom-right');
            for (var i = 0; i < elts.length; i++) {
               angular.element(elts[i]).css("z-index", "1051");
            }
         }, 200);
      }

      function notifyTaskError(taskInfo) {
         if (!taskInfo) {
            return;
         }

         var content =
            '<ul class="failed-tasks-notification">' +
               '<li class="item">' +
                  '<span class="name">' + i18nService.getString('Common', 'errorTask.name') + '</span>' +
                  '<div ng-non-bindable><span class="value">' + _.escape(taskInfo.name) + '</span></div>' +
               '</li>' +
               '<li class="item">' +
                  '<span class="name">' + i18nService.getString('Common', 'errorTask.target') + '</span>' +
                  '<div ng-non-bindable><span class="value">' + _.escape(taskInfo.entityName) + '</span></div>' +
               '</li>' +
               '<li class="item">' +
                  '<span class="name">' + i18nService.getString('Common', 'errorTask.status') + '</span>' +
                  '<div ng-non-bindable><span class="value">' + _.escape(taskInfo.errorMessage) + '</span></div>' +
               '</li>' +
            '</ul>';

         var options = {
            title: i18nService.getString('CommonUi', 'notification.operationFailed2'),
            content: content,
            type: vuiConstants.notifications.type.ERROR,
            duration: TASK_ERROR_DURATION_MS,
            linkConfig: {
               label: i18nService.getString('Common', 'allTasks'),
               onClick: function() {
                  $rootScope._navigateToView('vsphere.core.tasks.domainView');
               }
            }
         };

         vuiNotificationService.notify(options);
         updateNotificationPopupZIndex();
      }
   }
}());
