/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   var logger;

   angular.module('com.vmware.platform.ui')
         .controller('InventoryTreeViewController', InventoryTreeViewController);

   InventoryTreeViewController.$inject = ['$rootScope', '$scope','vuiConstants', 'i18nService',
      'logService', 'actionsService', 'inventoryTreeViewDragAndDropHandler',
      '$element', 'vcChangeThemesService'
   ];


   function InventoryTreeViewController($rootScope, $scope, vuiConstants, i18nService, logService,
         actionsService, inventoryTreeViewDragAndDropHandler, $element, vcChangeThemesService) {
      var self = this,

      logger = logService('InventoryTreeViewController'),
      context = $scope._view.viewDetails.context,
      trees = getTrees(i18nService, tabChanged),
      index = getSelectedIndex(context, trees),

      //holds the tab being currently displayed
      currentTab = trees[index],
      //holds the tree ids of trees which are allowed to be loaded (bug #109960982)
      allowTreeLoadingByTreeId = {},

      //holds the last selected node for the different trees
      lastSelectedObjectByTreeId = {};

      // This will reach the vxTreeView via bindings.
      self.treeConfig = {
         dragAndDrop: true,
         refreshEnabled: true,
         dragAndDropEventHandler: inventoryTreeViewDragAndDropHandler
      };

      allowTreeLoadingByTreeId[currentTab.treeId] = true;

      $scope.tabOptions = {
            tabType: vuiConstants.tabs.type.PRIMARY,
            selectedTabIndex: index,
            tabs: trees
      };

      //Preselect the object id passed with viewState.selectedObject
      self.selectedObjectByTreeId = {};

      if (context && context.viewState && context.viewState.selectedObject) {
         self.selectedObjectByTreeId[currentTab.treeId] = context.viewState.selectedObject;
      }

      self.shouldSelectAfterTabChange = false;

      self.hidingVms = false;

      $scope.$watch('_view.viewDetails.context', function(newValue, oldValue) {
         //act only upon actual changes
         if (newValue === oldValue) {
            return;
         }
         var newContext = newValue;

         // Change the tree if required.
         var newTreeIndex = getSelectedIndex(newContext, trees);
         var newTab = trees[newTreeIndex];
         if (currentTab !== newTab) {
            // Programmatically change the tab
            $scope.tabOptions.selectedTabIndex = newTreeIndex;
            currentTab = newTab;
            allowTreeLoadingByTreeId[currentTab.treeId] = true;
            self.shouldSelectAfterTabChange = true;
         }

         if (!newContext) {
            return;
         }

         if (newContext.viewState) {
            // Change the selected Object in the tree if one is provided in the view state
            if (!newContext.viewState.selectedObject) {
               return;
            }

            if (!self.shouldSelectAfterTabChange) {
               selectTreeItem(newContext.viewState.selectedObject, currentTab.treeId);
            }
         } else {
            // Context has changed but no viewstate provided - so that means default - just select the last selected object.
            // Get the last selected object for that tree
            var objRef = lastSelectedObjectByTreeId[currentTab.treeId];
            if (objRef) {
               self.onChange(objRef, currentTab.treeId);
            }
         }
      });

      // Watch tab change. This is necessary since vuiTabs doesn't provide a tab change
      // callback for when programmatically changing the tab.
      $scope.$watch(function() {
         return $element.find('.vui-tab-content.active')[0];
      }, function(activeTab, prevTab) {
         if (activeTab === prevTab || !prevTab) {
            return;
         }

         if (self.shouldSelectAfterTabChange) {
            var context = $scope._view.viewDetails.context;
            if (context && context.viewState) {
               var objToSelect = context.viewState.selectedObject;
               selectTreeItem(objToSelect, currentTab.treeId);
            }
            self.shouldSelectAfterTabChange = false;
         }
      });

      $rootScope.$on("hidingVms", function(event, value) {
         self.hidingVms = value;
      });

      function selectTreeItem(objRef, treeId) {
         self.selectedObjectByTreeId[treeId] = objRef;
      }

      self.onChange = function (objRef, treeId) {
         //preserve the last selected object
         lastSelectedObjectByTreeId[treeId] = objRef;

         //Currently the vui-tab directive renders the content for all the available tabs.
         //This means that all the four trees are being loaded.
         //This leads to the point where all the trees fires onChange event
         //(especially when 'enable-selection-persistence' is set) but we need to load the data
         //only for the object pertaining to the tree corresponding to the current tab
         if (currentTab.treeId === treeId) {
            // If necessary, update the value of the current selected object for this
            // tree.
            if (self.selectedObjectByTreeId[treeId] !== objRef) {
               self.selectedObjectByTreeId[treeId] = objRef;
            }
            navigateToObject(objRef);
         }
      };

      self.$onDestroy = function () {
         vcChangeThemesService.triggerThemeChange("notObjectId");
      };

      self.showActionsMenu = function ($event) {
         var targetObject = {
               id: $event.data.id,
               name: $event.data.text,
               primaryIconId: $event.data.spriteCssClass
            };
         var left =  $event.clientX;
         var top = $event.clientY;
         // Get the tree view to which this right-clicked node belongs to
         // make it the target element (used for anchoring) for the actions menu.
         var eventposTarget = $event.delegateTarget;
         actionsService.showObjectContextMenu(null, $scope, targetObject, left, top, eventposTarget);
      };

      self.isTreeLoadingAllowed = function (treeId) {
         return allowTreeLoadingByTreeId[treeId] === true;
      };

      /**
       * Function called when the current tab is being changed.
       * See the vui-tab documentation for more details
       */
      function tabChanged(event, tab) {
         //preserve the tab
         currentTab = tab;
         allowTreeLoadingByTreeId[currentTab.treeId] = true;

         //get the last selected object for that tree
         var objRef = lastSelectedObjectByTreeId[currentTab.treeId];

         //show information for that object
         if (objRef) {
            navigateToObject(objRef);
         }
      }

      function navigateToObject(objRef) {
         // themifier: react to potential changes
         //Trigger the Banner change by calling the triggerThemeChangeService of the ChangeThemesService
         vcChangeThemesService.triggerThemeChange(objRef);

         // Navigate the application to the selected object in the tree.
         $scope._navigate(
                  'vsphere.core.inventory.serverObjectViewsExtension',
                  objRef,
                  { navigator: currentTab.navigationTargetUid });
      }
   }

   function getSelectedIndex(viewContext, views) {
      if (!viewContext) {
         return 0;
      }
      for (var i = 0; i < views.length; i++) {
         if (viewContext.uid === views[i].id) {
            return i;
         }
      }
      logger.warn('InventoryTreeView did not find the tree tab to navigate to. Defaulting to the first tree tab.');
      return 0;
   }

   // TODO smarathe: Ideally this should go into extensions later.
   function getTrees(i18nService, onClick) {
      var trees = [{
                      id: 'vsphere.core.navigator.hostsClustersTree',
                      iconClass: 'vsphere-icon-cluster-24x',
                      tooltipText: i18nService.getString('inventory','navigator.viHostsAndClusters'),
                      contentUrl: 'hosts_clusters_inv_tree.html',
                      onClick: onClick,
                      treeId: 'vsphere.core.physicalInventorySpec',
                      navigationTargetUid: 'vsphere.core.viTree.hostsAndClustersView'
                   }, {
                      id: 'vsphere.core.navigator.vmTemplatesTree',
                      iconClass: 'vsphere-icon-vm-templates-24x',
                      tooltipText: i18nService.getString('inventory','navigator.viVmsAndTemplates'),
                      contentUrl: 'vms_templates_inv_tree.html',
                      onClick: onClick,
                      treeId: 'vsphere.core.virtualInventorySpec',
                      navigationTargetUid: 'vsphere.core.viTree.vmsAndTemplatesView'
                   }, {
                      id: 'vsphere.core.navigator.storageTree',
                      iconClass: 'vsphere-icon-datastore-24x',
                      tooltipText: i18nService.getString('inventory','navigator.viStorage'),
                      contentUrl: 'inv_data_storage_tree.html',
                      onClick: onClick,
                      treeId: 'vsphere.core.storageInventorySpec',
                      navigationTargetUid: 'vsphere.core.viTree.storageView'
                   }, {
                      id: 'vsphere.core.navigator.networkingTree',
                      iconClass: 'vsphere-icon-network-24x',
                      tooltipText: i18nService.getString('inventory','navigator.viNetworking'),
                      contentUrl: 'inv_networking_tree.html',
                      onClick: onClick,
                      treeId: 'vsphere.core.networkingInventorySpec',
                      navigationTargetUid: 'vsphere.core.viTree.networkingView'
                   }];
      return trees;
   }
})();
