/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.platform.ui')
         .service('wizardValidationService', WizardValidationService);

   WizardValidationService.$inject = [
      'vuiConstants',
      'simpleValidationCommit'];

   /**
    * Provides register for invalidation of pages when some of page validationParams is
    * changed. In case of missing onCommit, adds onCommit callback and applies validation
    * parameters to the validation function for each page having such function.
    */
   function WizardValidationService(vuiConstants, simpleValidationCommit) {

      /**
       * In order to set page for invalidation, 'validationParam' property should be set
       * to the pages that will be monitored.
       */
      this.initWizardValidation = function (wizardScope) {

         postProcessPages(wizardScope);
         registerPagesForInvalidation(wizardScope);
      };

      /**
       * Register all validationParams of pages in the wizardScope to be watched and update
       * page state to INCOMPLETE in case of changes.
       */
      function registerPagesForInvalidation (wizardScope) {
         _.forEach(wizardScope.wizardConfig.pages, function (page) {
            if (page.validationParams && page.validationParams.length > 0) {
               updatePageStatusOnChange(wizardScope, page, page.validationParams);
            }
         });
      }

      function updatePageStatusOnChange(wizardScope, page, validationParams) {
         wizardScope.$watchGroup(validationParams,
               function () {
                  if (page.state === vuiConstants.wizard.pageState.COMPLETED) {
                     page.state = vuiConstants.wizard.pageState.INCOMPLETE;
                  }
               });
      }

      /**
       * Adds onCommit callback to all pages that don't have such
       */
      function postProcessPages (scope) {
         _.each(scope.wizardConfig.pages, function (page) {
            if (!page.onCommit) {
               if (page.validationFunction) {
                  page.onCommit = simpleValidationCommit(
                        validationFunctionWrapper(page.validationFunction, scope),
                        scope.wizardConfig);
               }
            } else if (page.validationFunction) {
               throw new Error("The page has onCommit callback and validationFunction.");
            }
         });
      }

      function validationFunctionWrapper(validationFunction, scope) {
         return function () {
            var args = _.map(scope.wizardConfig.currentPage.validationParams, function (validationParam) {
               return scope.$eval(validationParam);
            });
            return validationFunction.apply(null, args);
         };
      }

   }
})();
