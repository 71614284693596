/* Copyright 2015-2019 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.module('com.vmware.platform.ui')
   .service('timeFormatterService', ['i18nService', 'localStorageService', 'browserLanguage',
      function(i18nService, localStorageService, browserLanguage) {
         'use strict';
         var BROWSER_DEFAULT =  {
            id: 'DateTimeBrowserDefault',
            displayName: i18nService.getString('Common', 'changeDateTimeFormat.BrowserDefault.label')
         };

         var FORMAT_12_HOUR =  {
            id: 'DateTimeFormat12',
            displayName: i18nService.getString('Common', 'changeDateTimeFormat.12Hour.label')
         };

         var FORMAT_24_HOUR = {
            id: 'DateTimeFormat24',
            displayName: i18nService.getString('Common', 'changeDateTimeFormat.24Hour.label')
         };

         var KNOWN_FORMATS = [BROWSER_DEFAULT, FORMAT_12_HOUR, FORMAT_24_HOUR];
         var DEFAULT_FORMAT = BROWSER_DEFAULT;
         var TIMEDATE_LOCALSTORAGE_KEY = 'timeFormat';

         var userTimeFormatPreference = function() {
            return localStorageService.getUserData(TIMEDATE_LOCALSTORAGE_KEY).then(function(savedFormatId) {
               return findDateTimeFormat(savedFormatId);
            });
         };

         function findDateTimeFormat(format) {
            var foundFormat = _.find(KNOWN_FORMATS, function(knownFormat) {
               return knownFormat.id === format;
            });
            return foundFormat || DEFAULT_FORMAT;
         }

         function formatIsKnown(format) {
            return _.find(KNOWN_FORMATS, function(knownFormat) {
               return format && format.id && format.id === knownFormat.id;
            });
         }

         var defaultOptions = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric"
         };

         var language = browserLanguage.get();

         var userDateTimeFormatIdToConfig = {};
         userDateTimeFormatIdToConfig[DEFAULT_FORMAT.id] = {
            formatter: Intl.DateTimeFormat(
               language, Object.assign({}, defaultOptions)),
            hour12: undefined
         };

         userDateTimeFormatIdToConfig[FORMAT_12_HOUR.id] = {
            formatter: Intl.DateTimeFormat(
               language, Object.assign({ hour12: true }, defaultOptions)),
            hour12: true
         };

         userDateTimeFormatIdToConfig[FORMAT_24_HOUR.id] = {
            formatter: Intl.DateTimeFormat(
               language, Object.assign({ hour12: false }, defaultOptions)),
            hour12: false
         };

         function timestamp2txt(timestamp, savedDateTimeFormat, options) {
            var date = new Date(timestamp);
            if (!timestamp || isNaN(date.getTime()) || !isFinite(date.getTime())) {
               return "";
            }

            if (!options) {
               var formatter = userDateTimeFormatIdToConfig[DEFAULT_FORMAT.id].formatter;
               if (savedDateTimeFormat && userDateTimeFormatIdToConfig[savedDateTimeFormat.id]) {
                  formatter = userDateTimeFormatIdToConfig[savedDateTimeFormat.id].formatter;
               }

               return formatter.format(date);
            }

            var hour12 = userDateTimeFormatIdToConfig[DEFAULT_FORMAT.id].hour12;
            if (savedDateTimeFormat && userDateTimeFormatIdToConfig[savedDateTimeFormat.id]) {
               hour12 = userDateTimeFormatIdToConfig[savedDateTimeFormat.id].hour12;
            }

            var finalOptions = Object.assign({hour12: hour12}, options);

            return date.toLocaleString(browserLanguage.get(), finalOptions);
         }

         function formatDate(timestamp, options) {
            return userTimeFormatPreference().then(function(savedDateTimeFormat) {
               return timestamp2txt(timestamp, savedDateTimeFormat, options);
            });
         }

         return {
            formatDate: formatDate,
            timestampToText: timestamp2txt,
            setUserTimeFormatPreference: function(dateTimeFormat) {
               if (formatIsKnown(dateTimeFormat)) {
                  localStorageService.setUserData(TIMEDATE_LOCALSTORAGE_KEY, dateTimeFormat.id);
               }
            },
            getUserTimeFormatPreference: userTimeFormatPreference,
            getKnownFormats: function() {
               return KNOWN_FORMATS;
            }
         };
      }
   ]);
