/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').service('portletActionBarService',
         ['commonActionBarService', function(commonActionBarService) {
            function updateActionBar(portletOptions, targetIds, actionSpecs, portletActionBarServiceCacheObject) {
               return commonActionBarService.updateActionBar(
                     portletOptions,
                     'actions',
                     targetIds,
                     actionSpecs,
                     portletActionBarServiceCacheObject
               );
            }

            return {
               updateActionBar: updateActionBar
            };
         }]);
})();
