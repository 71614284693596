/**
 * Tree list directive that creates a kendo tree list.
 *
 * Example:
 * <div vx-tree-list vx-tree-list-options="treeListOptions"></div>
 *
 * @name treeListOptions
 * @description
 *    Configuration object for tree list view.
 *    Contains properties to specify a TreeList and populate it with data.
 *
 *    Example:
 *    ```js
 *    var treeListOptions =  {
 *                dataSource: {
 *                   data: [
 *                      { id: 1, Name: "Root item 1", Status: "Normal", parentId: null },
 *                      { id: 2, Name: "Root item 2", Status: "Warning", parentId: null },
 *                      { id: 34, Name: "First child of the root item 1", Status: "N/A", parentId: 1 },
 *                      { id: 67, Name: "First child of the root item 2", Status: "Warning", parentId: 2 },
 *                      { id: 72, Name: "Second child of the root item 2", Status: "Error", parentId: 2 },
 *                      ....
 *                   ],
 *                   schema: {
 *                      model: {
 *                         id: "id",
 *                         expanded: false
 *                      }
 *                   }
 *                },
 *                sortable: true,
 *                height : '100%',
 *                width: '100%',
 *                columns: [
 *                   { field: "Name", title: "Name", width: "150px" },
 *                   { field: "Status" }
 *                ],
 *                resizable: true,
 *                selectable: "row"
 *             };
 */
angular.module('com.vmware.platform.ui').directive('vxTreeList', function() {
   'use strict';

   return {
      restrict: 'A',
      scope: {
         'vxTreeListOptions': '='
      },
      link: function(scope, element, attrs) {
         var kendoTreeListWidget = null;

         function bindWidgetInParentScope() {
            if (!attrs['vxTreeList'] || !kendoTreeListWidget) {
               return;
            }

            scope.$parent.$eval(
                  attrs['vxTreeList'] + ' = ' + '$vxTreeListWidget',
                  { '$vxTreeListWidget': kendoTreeListWidget }
            );
         }


         scope.$watch('vxTreeListOptions.dataSource', function(value) {
            if (!kendoTreeListWidget) {
               return;
            }

            kendoTreeListWidget.setDataSource(value);
         });

         if (scope.vxTreeListOptions) {
            var oldDataSource = scope.vxTreeListOptions.dataSource;
            kendoTreeListWidget = element.kendoTreeList(
                  scope.vxTreeListOptions
            ).data('kendoTreeList');
            var newDataSource = scope.vxTreeListOptions.dataSource;

            bindWidgetInParentScope();

            if (!angular.equals(newDataSource, oldDataSource)) {
               kendoTreeListWidget.setDataSource(scope.vxTreeListOptions.dataSource);
            }
         } else {
            var unbindWatcher = scope.$watch('vxTreeListOptions', function() {
               if (!kendoTreeListWidget && scope.vxTreeListOptions) {
                  kendoTreeListWidget = element.kendoTreeList(
                        scope.vxTreeListOptions
                  ).data('kendoTreeList');
                  bindWidgetInParentScope();
                  unbindWatcher();
               }
            });
         }
      }
   };
});
