module platform {
export interface ValidationExpression {
   dropTargetTypes?: string;
   expression: string;
}

export interface ParsedExpression {
   method: string;
   operand1: string;
   operand2?: string;
}

export class ValidationExpressionParserService {

   public static $inject = ["defaultUriSchemeUtil"];

   private readonly DRAG_OBJECTS: string = "DRAG_OBJECTS";
   private readonly DRAG_OBJECTS_DATA: string = "DRAG_OBJECTS_DATA";
   private readonly DROP_TARGET: string = "DROP_TARGET";
   private readonly DROP_TARGET_DATA: string = "DROP_TARGET_DATA";

   public constructor(private defaultUriSchemeUtil: any) {

   }

   /**
    * Evaluates the given validation expressions against the given objects.
    *
    * @param expressions
    *    The validation expressions to evaluate.
    *
    * @param dragObjects
    *    The drag objects to evaluate the expressions against.
    *
    * @param dropTarget
    *    The drop target to evaluate the expressions against.
    *
    * @param dragObjectsData
    *    The drag objects data to evaluate the expression against.
    *
    * @param dropTargetData
    *    The drop target data to evaluate the expression against.
    *
    * @returns {string[]} An Array of the drag objects that passed the evaluation expressions.
    */
   public evaluateValidationExpressions(expressions: ValidationExpression[],
                                        dragObjects: string[], dropTarget: string,
                                        dragObjectsData: any, dropTargetData: any): string[] {

      let dropTargetType: string = this.defaultUriSchemeUtil.getEntityType(dropTarget);
      if (!expressions || expressions.length === 0) {
         return dragObjects;
      }

      let expressionsLength: number = expressions.length;
      for (let i: number = 0; i < expressionsLength; i++) {
         let expression: ValidationExpression = expressions[i];
         if (expression && expression.dropTargetTypes) {
            let targetTypes: string[] = expression.dropTargetTypes.split(",");
            let checkTargetType: string = _.find(targetTypes, function (type: string) {
               return type === dropTargetType;
            });
            if (!checkTargetType) {
               continue;
            }
         }

         dragObjects = this.evaluateValidationExpressionMulti(dragObjects, dropTarget,
               dragObjectsData, dropTargetData, expression.expression);

         if (dragObjects.length === 0) {
            // Everything already filtered out.
            return dragObjects;
         }
      }

      return dragObjects;

   }

   /**
    * Evaluates the given expression in turn for each of the given drag objects
    * or if the drag objects are not evaluated in the expression but only the drop target
    * it evaluates only once the expression for the given drop target
    * and returns an array of those drag objects which passed the evaluation.
    *
    * @param dragObjects
    *    The drag objects to evaluate the expression against.
    *
    * @param dropTarget
    *    The drop target to evaluate the expression against.
    *
    * @param dragObjectsData
    *    The drag objects data to evaluate the expression against.
    *
    * @param dropTargetData
    *    The drop target icon to evaluate the expression against.
    *
    * @param expression
    *    The expression to evaluate.
    *
    * @returns {string[]} An array of the drag objects that passed the evaluation.
    */
   private evaluateValidationExpressionMulti(dragObjects: string[],
         dropTarget: string, dragObjectsData: any, dropTargetData: any,
         expression: string): string[] {

      if (!dragObjects || dragObjects.length === 0) {
         return [];
      }

      let parsedExpression: ParsedExpression|undefined = this.parseExpression(expression);

      if (!parsedExpression) {
         return dragObjects;
      }

      let result: string[] = [];

      let dragObjectsRegExp: RegExp = new RegExp(this.DRAG_OBJECTS);

      if (expression.match(dragObjectsRegExp)) {
         // The expression refers to the drag objects, meaning it needs to be
         // evaluated once for every object in the drag object
         _.each(dragObjects, function (dragObject) {
            if (this.evaluateValidationExpression(dragObject, dropTarget, dragObjectsData,
                        dropTargetData, parsedExpression)) {
               result.push(dragObject);
            }
         }.bind(this));
      } else {
         // Only need to evaluate the expression once since it does not refer to the
         // drag objects.
         let dragObject: string = "";
         if (this.evaluateValidationExpression(dragObject, dropTarget, dragObjectsData,
                     dropTargetData, parsedExpression)) {
            result = dragObjects;
         }
      }

      return result;
   }

   /**
    * Identifies with regular expression the parts of the given expression
    * i.e. the method name and it's operands
    *
    * @param expression
    *    The expression to evaluate.
    *
    * @returns {ParsedExpression|undefined}
    *    Returns an object containing the method and it's operands.
    *    The second operand is not required
    */
   private parseExpression(expression: string): ParsedExpression|undefined {
      const operandRegEx = "(" + this.DRAG_OBJECTS + ")|(" + this.DROP_TARGET + ")|("
            + this.DRAG_OBJECTS_DATA + ")|(" + this.DROP_TARGET_DATA + ")";
      const validationExpressionRegEx: RegExp = new RegExp("(.+)\\((" + operandRegEx + ")+" +
          ",{0,1}(" + operandRegEx + ")*\\)");

      let found: RegExpMatchArray|null = expression.match(validationExpressionRegEx);
      let parsedExpression: ParsedExpression|undefined;
      if (found && found[1] && found[2]) {
         parsedExpression = {
            method: found[1],
            operand1: found[2],
            operand2: found[7]
         };
      }

      return parsedExpression;
   }

   /**
    * Checks if the given method has been registered. If not skips the check.
    * If the method has been registered in the global variable dragAndDropRules
    * it calls the method
    *
    * @param dragObject
    *    The drag object to evaluate the expression against.
    *
    * @param dropTarget
    *    The drop target to evaluate the expression against.
    *
    * @param dragObjectsData
    *    The drag objects data to evaluate the expression against.
    *
    * @param dropTargetData
    *    The drop target data to evaluate the expression against.
    *
    * @param parsedExpression
    *    The parsed expression containing the to be invoked and it's operands
    *
    * @returns {boolean}
    *    Returns true if the expression is correctly evaluated against drag object
    *    or the drop target and false if not
    */
   private evaluateValidationExpression(dragObject: string,
         dropTarget: string, dragObjectsData: any, dropTargetData: any,
         parsedExpression: ParsedExpression): boolean {

      if (!h5.dragAndDropValidationMethods[parsedExpression.method]) {
         return true;
      }
      let operand1: string = this.getOperand(parsedExpression.operand1, dragObject,
            dropTarget, dragObjectsData, dropTargetData);
      let operand2: string|undefined;

      if (parsedExpression.operand2) {
         operand2 = this.getOperand(parsedExpression.operand2, dragObject,
               dropTarget, dragObjectsData, dropTargetData);
      }

      return h5.dragAndDropValidationMethods[parsedExpression.method](operand1, operand2);
   }

   /**
    * Determines the type of the given operand(DROP_TARGET|DRAG_OBJECTS)
    * and returns the corresponding value.
    *
    * @param operand
    *    The type of the operand: (DROP_TARGET|DRAG_OBJECTS)
    *
    * @param dragObject
    *    The drag object to evaluate the expression against.
    *
    * @param dropTarget
    *    The drop target to evaluate the expression against.
    *
    * @param dragObjectsData
    *    The drag objects data to evaluate the expression against.
    *
    * @param dropTargetData
    *    The drop target data to evaluate the expression against.
    *
    * @returns {string}
    *    The actual value of the operand
    */
   private getOperand(operand: string, dragObject: string, dropTarget: string,
                      dragObjectsData: any, dropTargetData: any): string {
      switch (operand) {
         case this.DRAG_OBJECTS:
            return dragObject;
         case this.DROP_TARGET:
            return dropTarget;
         case this.DRAG_OBJECTS_DATA:
            return dragObjectsData;
         case this.DROP_TARGET_DATA:
            return dropTargetData;
         default:
            return "";
      }
   }
}

angular.module("com.vmware.platform.ui")
      .service("validationExpressionParserService", ValidationExpressionParserService);
}
