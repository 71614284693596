/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for ServerObjectView.
 *
 * ServerObjectView exists at the same level as QueryObjectView.
 */
angular.module('com.vmware.platform.ui').controller(
'ServerObjectViewController',
['$scope', 'dataService', 'actionsService',
   'browserHistoryService', 'configurationService', 'defaultUriSchemeUtil',
   'websocketMessagingService',"$window", 'iconService', 'i18nService', 'logService',
function($scope, dataService, actionsService,
         browserHistoryService, configurationService, defaultUriSchemeUtil,
         websocketMessagingService,$window, iconService, i18nService, logService) {
   var objectId = $scope._route.objectId;
   configurationService
         .getProperty('live.updates.objectstate.enabled')
         .then(function(objectDetailsRefreshEnabled) {
      if (objectDetailsRefreshEnabled
            && objectDetailsRefreshEnabled.toUpperCase() === "TRUE"
			&& defaultUriSchemeUtil.isValidManagedObjectId(objectId)) {
         var serverGuid = defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId).serverGuid;
         websocketMessagingService.openInventory(serverGuid);
      }
   });
   $scope.watchForObjects = [objectId];
   $scope.getViewData = getViewData;
   var translatedObjectType = "";
   var objectType = "";
   if (defaultUriSchemeUtil.isValidManagedObjectId(objectId) ) {
      try {
         objectType = defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId).type;
         translatedObjectType = i18nService.getString("Common", "fieldType." + objectType);
      } catch (e) {
         logService.error(e);
      }
   }
   $scope.translatedObjectType = translatedObjectType;

   $scope.isVmObject = defaultUriSchemeUtil.isValidManagedObjectId(objectId)
      && objectType === "VirtualMachine";

   /**
    * Calls the actionservice to show up a context menu that lists actions.
    *
    * @param $event The click event that was raised to trigger the menu.
    */
   $scope.showActionsMenu = function ($event) {
      var bodyElement = angular.element($window.document.body);
      var domElement = $($event.target);
      var domElPosition = domElement.offset();
      var left = domElPosition.left;
      var top = domElPosition.top + domElement.height();

      actionsService.showObjectContextMenu($event, $scope,
         $scope.currentObject, left, top, null, true)
            .then(function (vuiMenuMetadata) {
                  var vui_action_menu = angular.element($window.document.querySelector("#vui-actions-menu"));
                  actionsService.calculateActionsMenuPosition(vui_action_menu, bodyElement, left, top);
            });
   };

   function getViewData() {
      var propertyNames = ['name'];
      if ($scope._view.getSelectedChild()) {
         // Request the icon only if there are views inside this server
         // object view to avoid exceptions from the data service.
         propertyNames.push('primaryIconId');
      }
      dataService
         .getProperties(objectId, propertyNames)
         .then(function(data) {
            if (data.primaryIconId) {
               var parts = iconService.parseIcon(data.primaryIconId);
               data.iconNs = parts[0];
               data.primaryIconId = parts[1];
            }
            $scope.currentObject = data;
            // Notify the browser title service that the object name has changed.
            browserHistoryService.notifyCurrentObjNameChanged(objectId, data.name);
         });
   }

   getViewData();

}]);
