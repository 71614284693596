/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service for retrieving datastore browser tree items from the backend.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('datastoreBrowserTreeService', datastoreBrowserTreeService);

   datastoreBrowserTreeService.$inject = ['$http'];

   function datastoreBrowserTreeService($http) {

      // Public API
      var service = {
         getRoot: getRoot,
         getChildren: getChildren,
         searchResults: searchResults,
         isFileExists: isFileExists
      };
      return service;

      /**
       * Performs a get request to the backend and retrieves the root nodes of the
       * datastore browser tree.
       *
       * @param objectId {String} String representation of the object reference to list
       *    the datastores for. Should be of type HostDatastoreBrowser, HostSystem,
       *    Datastore, VirtualMachine, ComputeResource or ClusterComputeResource.
       * @param datastores {String} /Optional/ An array of datastore uids representing
       *    the datastore objects to be listed in the tree. Should be provided only when
       *    the objectRef is of type HostDatastoreBrowser. In other cases - the
       *    datastores will be gathered by the provided object reference.
       * @param showVmImagesFolder {Boolean} /Optional/ Flag to indicate whether to
       *    return the vm images folder.
       * @returns {Promise} Promise with a single parameter which is the response data.
       */
      function getRoot(objectId, datastores, showVmImagesFolder) {
         var httpConfig = {
            method: 'GET',
            url: 'datastoreBrowserTree/root/' + objectId,
            params: {}
         };

         if (datastores) {
            httpConfig.params.datastores = datastores;
         }
         if (showVmImagesFolder) {
            httpConfig.params.showVmImagesFolder = showVmImagesFolder;
         }

         return getData(httpConfig, []);
      }

      /**
       * Performs a get request to the backend and retrieves the children nodes of the
       * selected datastore browser tree.
       *
       * @param objectId {String} String representation of the object reference to list
       *    the datastores for. Should be of type HostDatastoreBrowser, HostSystem,
       *    Datastore, VirtualMachine, ComputeResource or ClusterComputeResource.
       * @param datastorePath {String} The folder path of the selected datastore browser
       *    item which defined the folder in which to search files.
       * @param fileQueryType {Integer} /Optional/ A bit mask that specifies what is
       *    the file types to include in the search criteria. If not specified - all files
       *    types are included in the result.
       * @cancelPromise {Promise} Optional promise which if resolved cancels the request.
       * @returns {Promise} Promise with a single parameter which is the response data.
       */
      function getChildren(objectId, datastorePath, fileQueryType, cancelPromise) {
         var httpConfig = {
               method: 'GET',
               url: 'datastoreBrowserTree/children/' + objectId,
               params: {
                  datastorePath: datastorePath
               },
               timeout: cancelPromise
         };

         if (fileQueryType) {
            httpConfig.params.fileQueryType = fileQueryType;
         }
         return getData(httpConfig, []);
      }

      /**
       * Performs a get request to the backend to search for datastore files using given pattern.
       *
       * @param datastoreId {String} String representation of the datastore reference
       * @param datastoreRootPath {String} The root folder path e.g. '[Datastore]'
       * @param pattern {String} The search pattern to be used.
       * @cancelPromise {Promise} Optional promise which if resolved cancels the request.
       * @returns {Promise} Promise with a single parameter which is the response data.
       */
      function searchResults(datastoreId, datastoreRootPath, pattern, cancelPromise) {
         var httpConfig = {
            method: 'GET',
            url: 'datastoreBrowserTree/children/' + datastoreId,
            params: {
               datastorePath: datastoreRootPath,
               pattern: '*' + pattern + '*',
               fileQueryType: 16 /*all files*/,
               searchInSubFolders: true
            },
            timeout: cancelPromise
         };

         return getData(httpConfig, []);
      }

      function isFileExists(objectId, filePath, fileType) {
         var httpConfig = {
            method: 'GET',
            url: 'datastoreBrowserTree/isFileExists/' + objectId,
            params: {
               filePath: filePath,
               fileQueryType: fileType
            }
         };

         return getData(httpConfig, false);
      }

      function getData(httpConfig, emptyReturnValue) {
         return $http(httpConfig).then(function (resp) {
            return resp.data;
         }, function (error) {
            return emptyReturnValue;
         });
      }
   }
})();