/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

namespace platform {

   import ListViewExportSpec = com.vmware.vise.mvc.listview.ListViewExportSpec;

   export enum ExportColumnType {
      ALL,
      SELECTED_ONLY
   }

   export interface ExportableColumn {
      title: string;
      selected: boolean;
      propertyName: string;
      type: string;
      uid: string;
   }

   export class ExportColumn {
      _type: string = "com.vmware.vsphere.client.export.ExportColumn";
      title: string;
      propertyName: string;
      type: string;
      uid: string;
   }

   export class ReportDeletionSpec {
      _type: string = "com.vmware.vsphere.client.export.ReportDeletionSpec";
      reportObjectRef: string;
   }

   interface ColumnById {
      [uid: string]: ExportableColumn;
   }

   export class ExportListService {
      public static $inject = [
         "clarityModalService",
         "i18nService",
         "defaultUriSchemeUtil",
         "mutationService",
         "$window",
         "$httpParamSerializer",
         "$q",
         "logService",
         "browserService",
         "$http",
         "listViewColumnService",
         "vxZoneService"
      ];

      private i18n: Function;
      readonly logger: any;
      private downloadWindow: any;
      private readonly FILE_FORMAT = "csv";
      private readonly EXTENSION_COLUMN = "extension";
      private readonly CUSTOM_COLUMN = "custom";

      constructor(private clarityModalService: any,
                  private i18nService: any,
                  private defaultUriSchemeUtil: any,
                  private mutationService: any,
                  private $window: any,
                  private $httpParamSerializer: any,
                  private $q: any,
                  private logService: any,
                  private browserService: any,
                  private $http: any,
                  private listViewColumnService: ListViewColumnService,
                  private vxZoneService: any) {
         this.i18n = this.i18nService.getString;
         this.logger = logService("exportListService");
      }

      open(listViewSpec: any,
           selectedItems: Array<{ id: string }>,
           columnsById: ColumnById): void {

         const selectedItemsCount =
               !_.isEmpty(selectedItems) ? selectedItems.length : 0;

         const dialogData = {
            exportType: NaN,
            selectedItemsCount: selectedItemsCount,
            columnsById: columnsById,
            generatingFile: false
         };

         this.clarityModalService.openOkCancelModal({
            title: this.i18n("Common", "ExportDialog.exportDialogTitle"),
            contentTemplate: "resources/ui/views/listview/export/export-list.view.html",
            onSubmit: () => {
               dialogData.generatingFile = true;
               const spec = this.buildExportSpec(listViewSpec,
                     dialogData.exportType, selectedItems, columnsById);
               return this.generateFile(spec)
                     .then((result: any) => {
                        dialogData.generatingFile = false;
                        return result;
                     })
                     .then((result: any) => this.downloadFile(result),
                           (error: any) => {
                              this.logError("Generate CSV file failed", error);
                              return true;
                           });
            },
            onCancel: () => this.downloadWindow = undefined,
            defaultButton: "submit",
            dialogData: dialogData,
            submitButtonTitle: this.i18n("Common", "ExportDialog.save")
         });
      }

      private showBlockingPopupModal(): void {
         const modalOptions = {
            title: this.i18n("Common", "ExportDialog.blockingPopup.title"),
            message: this.i18n("Common", "ExportDialog.blockingPopup.message"),
            preserveNewlines: true,
            hideCancelButton: true,
            clarityIcon: {
               shape: "warning",
               class: "is-warning",
               size: 48
            },
            showXIcon: false,
            submit: () => {
               return true;
            }
         };

         this.clarityModalService.openConfirmationModal(modalOptions);
      }

      private buildExportSpec(listViewSpec: any,
                              exportType: number,
                              selectedItems: Array<{ id: string }>,
                              columnsById: ColumnById): ListViewExportSpec {

         const exportColumns: Array<ExportColumn> = [];

         const allColumns = _.values(columnsById);
         allColumns.forEach((column: ExportableColumn) => {
            if (!column.selected) {
               return;
            }

            const exportColumn = new ExportColumn();
            exportColumn.title = column.title;
            exportColumn.propertyName = column.propertyName;
            exportColumn.uid = column.uid;
            exportColumn.type = this.listViewColumnService.isValidCustomAttrId(column.uid) ?
               this.CUSTOM_COLUMN.toUpperCase() :
               this.EXTENSION_COLUMN.toUpperCase();
            exportColumns.push(exportColumn);
         });

         let objIds: Array<string> = [];
         if (exportType === ExportColumnType.SELECTED_ONLY) {
            selectedItems.forEach((item: any) => {
               objIds.push(item.id);
            });
         }
         const spec = new ListViewExportSpec();
         // parse the listview spec that is a string
         spec.listViewSpec = JSON.parse(listViewSpec);
         spec.selectedObjectIds = objIds;
         spec.columns = exportColumns;
         spec.format = this.FILE_FORMAT.toUpperCase();

         return spec;
      }

      private generateFile(spec: ListViewExportSpec): angular.IPromise<boolean> {
         const httpConfig = {
            method: "POST",
            url: "list/export",
            contentType: "application/json",
            data: spec
         };

         return this.$http(httpConfig);
      }

      private downloadFile(result: any): angular.IPromise<boolean> {
         if (result.data.error) {
            this.logError("Error generating CSV file", result.data.error);
            throw new Error(this.i18n("Common", "ExportError.exportFailure"));
         }

         const defFileNameKey = "ExportDialog.defaultReportFileName";
         const params = this.$httpParamSerializer({
            filename: this.i18n("Common", defFileNameKey) + this.FILE_FORMAT
         });

         const fileId = result.data.entity;
         const fileUrl = result.data.result;
         const downloadUrl = fileUrl + "?" + params;
         // since the urn:vri:Download object's id contains also the
         // file url, when passing this uri back to the server we have
         // to encode it in order to get rid of the / in the url. The
         // only way worked is this one. I tried different combination
         // like encodeURI(fileId), encodeURIComponent(fileId),
         // encodeURI(encodeURIComponent(fileId)), etc. none of the worked.
         // The only solution worked is this one - first to replace / with
         // %2F, and then encode the string - as a result the / becomes
         // %252F, and he URI on the java side has the expected format
         const encodedFileId = encodeURI(fileId.replace(/\//g, "%2F"));
         const spec = new ReportDeletionSpec();
         spec.reportObjectRef = fileUrl;

         const deferred = this.$q.defer();

         this.browserService.areBlockingPopupsAllowed().then((allowed: boolean) => {
            this.downloadWindow = this.$window.open(downloadUrl, "_blank");
            if (!this.downloadWindow || !allowed) {
               this.showBlockingPopupModal();
               // remove the generated file from server because it will not
               // be downloaded and the after-download removal will not be
               // triggered, so we have to do it explicitly here
               this.removeFile(encodedFileId, spec)
                     .then(() => deferred.resolve(false));
               return;
            }

            const onDownloadComplete: Function = () => {
               // Following operations should be executed inside
               // Angular Zone to avoid digest already in progress
               // angular js error on Firefox browser
               this.vxZoneService.runInsideAngular(() => {
                  this.downloadWindow = undefined;

                  // after the file is downloaded, remove it from server
                  this.removeFile(encodedFileId, spec)
                        .then(() => deferred.resolve(true));
               });
            };

            if (this.browserService.isSafari) {
               this.downloadWindow.onunload = onDownloadComplete;
            } else {
               this.downloadWindow.onbeforeunload = onDownloadComplete;
            }
         });

         return deferred.promise;
      }

      private removeFile(encodedFileId: string, spec: ReportDeletionSpec): angular.IPromise<boolean> {
         return this.mutationService.remove(encodedFileId, spec._type, spec)
               .then(undefined,
                     (error: any) => {
                        this.logError("Remove CSV file failed", error);
                     });
      }

      private logError(msg: string, errorObj: any): void {
         this.logger.error(msg + ": " + JSON.stringify(errorObj));
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("exportListService", ExportListService);
}