/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Controller for the Change Password dialog.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
      .controller('ChangePasswordDialogController', ChangePasswordDialogController);

   ChangePasswordDialogController.$inject = ['$scope',
      'userSessionService',
      'i18nService',
      'logService',
      'mutationService',
      'vxValidatorFactory',
      'defaultUriSchemeUtil'];

   function ChangePasswordDialogController ($scope, userSessionService, i18nService, logService, mutationService,
         vxValidatorFactory, defaultUriSchemeUtil) {
      var self = this;
      var logger = logService('ChangePasswordDialogController');
      self.validator = vxValidatorFactory.create();
      userSessionService.getUserSession().then(function(userSession) {
         if (userSession.userName) {
            $scope.modalOptions.title = i18nService.getString('Common', 'changepassword.primaryTitle');
            $scope.modalOptions.secondaryTitle =  userSession.userName;
         } else {
            logger.error('Error getting username from userSessionService');
         }
      });
      userSessionService.getAllServersInfo().then(function(result) {
         self.vcId = defaultUriSchemeUtil.getRootFolder(
            result.serversInfo[0].serviceGuid);
      });

      self.isValidPassword = function() {
         var errors = [];
         if (self.newPassword !== self.confirmPassword) {
            errors.push(i18nService.getString('CommonUi', 'PasswordValidator.error'));
         }
         return errors;
      };

      self.onCancel = function() {
         $scope.closeModal();
      };

      self.onSave = function() {
         var errors = self.validator.validate();
         if (errors && errors.length) {
            return false;
         }
         var mutationOptions = {
            'oldPassword': self.currentPassword,
            'newPassword': self.newPassword
         };

         // The context object for the mutation call does not matter, but requires
         // ManagedObjectReference as first parameter. We need to provided
         // object id which to match ManagedObjectReference target type, that's why
         // from the session we get serviceGuid and construct VC id which is provided
         // to the mutation call.
         mutationService.apply(self.vcId ,'com.vmware.vsphere.client.sso.admin.model.PasswordSpec', mutationOptions);

         self.onCancel();
      };

      self.okButtonDisabled = function() {
         return !self.currentPassword || !self.newPassword || !self.confirmPassword || !self.vcId;
      };
   }
})();
