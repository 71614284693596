(function () {
   'use strict';
   // 2/11/19 - cpham, new feedback tool is in use and old files will be removed later
   angular.module('com.vmware.platform.ui').factory(
         'vFeedDialogService', vFeedDialogService);
   vFeedDialogService.$inject = ['$rootScope', 'vuiDialogService', 'i18nService'];

   function vFeedDialogService($rootScope, vuiDialogService, i18nService) {
      var dialog = null;

      function showFeedbackDialog(showError) {
         if (dialog) {
            dialog.destroy();
         }

         var scope = $rootScope.$new();
         scope.dialogOptions = {
            show: true,
            confirmOptions: {
               label: i18nService.getString('Common', 'feedback.send')
            },
            rejectOptions: {
               label:i18nService.getString('Common', 'feedback.cancel'),
               onClick: function () {
                  return true;
               }
            },
            data: {
               message: null,
               email: null
            }
         };
         if (showError) {
            scope.dialogOptions.title = i18nService.getString('Common', 'feedback.errorTitle');
            scope.dialogOptions.contentUrl = 'resources/ui/views/vfeed/vFeedError.html';
         } else {
            scope.dialogOptions.title =  i18nService.getString('Common', 'feedback.title');
            scope.dialogOptions.contentUrl = 'resources/ui/views/vfeed/vFeedDialog.html';
         }

         dialog = vuiDialogService({
            scope: scope,
            configObjectName: 'dialogOptions',
            positionClass : 'vfeed-dialog'
         });
      }

      return {
         showFeedbackDialog: showFeedbackDialog
      };
   }
})();
