/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {
   /**
    * Misc URL manipulation utilities.
    */
   export class URLService {
      readonly SCHEME: string = "scheme";
      readonly AUTHORITY: string = "authority";
      readonly PATH:string = "path";
      readonly QUERY: string = "query";
      readonly FRAGMENT: string = "fragment";

      /**
       * Removes HTML tags from provided HTML string
       *
       * @param html
       */
      stripTags(html) {
         return html.replace(/<[^>]+>/ig, '');
      }

      /**
       * Remove the parameter with the given name from the fragment component
       * of the given URL. If the parameter name is not present, return the
       * given URL unmodified.
       */
      removeParameter(url: string, name: string): string {
         let components: any = this.parse(url);
         const fragment: string = components["fragment"];
         if (fragment) {
            const parameterString: string = fragment.startsWith("?")
               ? fragment.substring(1)
               : fragment;
            const parameters: string[] =
               _.reject(parameterString.split("&"), (param: string): boolean => {
                  return param.indexOf(name) >= 0;
               });
            let newFragment: string = parameters.join("&");
            if (newFragment) {
               if (fragment.startsWith("?")) {
                  newFragment = "?" + newFragment;
               }
               components[this.FRAGMENT] = newFragment;
            } else {
               components[this.FRAGMENT] = undefined;
            }
         }
         return this.recompose(components);
      }

      private recompose(components: any): string {
         // See rfc3986, section 5.3.
         let result = "";
         if (components[this.SCHEME]) {
            result += components[this.SCHEME] + ":";
         }
         if (components[this.AUTHORITY]) {
            result += "//" + components[this.AUTHORITY];
         }
         result += components[this.PATH];
         if (components[this.QUERY]) {
            result += "?" + components[this.QUERY];
         }
         if (components[this.FRAGMENT]) {
            result += "#" + components[this.FRAGMENT];
         }
         return result;
      }

      private parse(url: string): any {
         // See rfc3986, appendix B.
         const pattern: RegExp =
            RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?");
         const components: any = url.match(pattern);
         return {
            "scheme":    components[2],
            "authority": components[4],
            "path":      components[5],
            "query":     components[7],
            "fragment":  components[9]
         };
      }
   }
   angular.module('com.vmware.platform.ui').service('urlService', URLService);
}
