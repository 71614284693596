/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * @ngdoc directive
 * @name com.vmware.platform.ui:vxMultiPageDialogToc
 * @module com.vmware.platform.ui
 * @restrict E
 *
 * @description
 *    Directive to create TOC for multi page dialog.
 */
angular.module('com.vmware.platform.ui').directive('vxMultiPageDialogToc',
      ['vuiConstants', 'vuiTreeAccessibilityServiceFactory', function(vuiConstants, accessibilityServiceFactory) {
         'use strict';
         return {
            template:
               '<div class="wizard-panel-TOC" role="tree" tabindex="0">' +
                  '<ul class="wizard-steps-list" role="group">' +
                     '<li ' +
                        'ng-repeat="page in dialogPages" ' +
                        'ng-class="getStepClass(page.state, $index)" ' +
                        'ng-if="criteriaMatch(page.state)" ng-click="moveTo($index)" ' +
                        'role="treeitem" ' +
                        'aria-label="{{page.title}}" ' +
                        'aria-selected="{{getAriaActive($index) ? \'true\' : \'false\'}}"' +
                        'tabindex="-1">' +
                        '<a class="no-content">{{page.title}}</a>' +
                     '</li>' +
                  '</ul>' +
               '</div>',
            scope: false,
            restrict: 'E',
            controller: function($scope, $element, $timeout) {
               var pageStateConst = vuiConstants.wizard.pageState;
               var dialogManager = $scope.dialogManager;

               setTimeout(function () {
                  accessibilityServiceFactory.create($element, $scope);
               }, 0);

               $scope.criteriaMatch = function(pageState) {
                  return pageState !== pageStateConst.SKIPPED;
               };

               $scope.getStepClass = function(pageState, pageIndex) {
                  var stepClass = (pageIndex === dialogManager.currentIdx) ?
                        'wizard-steps-current ' : '';
                  if(pageState === pageStateConst.DISABLED) {
                     stepClass += 'wizard-steps-not-available';
                  } else if(pageState === pageStateConst.COMPLETED && pageIndex !== 0) {
                     stepClass += 'wizard-steps-completed';
                  } else if(pageState === pageStateConst.INCOMPLETE) {
                     stepClass += 'wizard-steps-incomplete';
                  }
                  return stepClass;
               };

               $scope.getAriaActive = function (pageIndex) {
                  return pageIndex === dialogManager.currentIdx;
               };

               $scope.moveTo = function(pageIndex) {
                  dialogManager.tryMoveToPage(pageIndex).then(function(isMoved) {
                     // If because the current page in the dialog is not valid
                     // the navigation to the new page is prevented. But since the new
                     // page's TOC item that is clicked gets a new style applied
                     // (defined by :focus selector) we have to invoke onblur event on
                     // that TOC item in order to make the :focus style removed
                     if (!isMoved) {
                        $element.find('.wizard-steps-list li a:eq(' + pageIndex + ')').blur();
                     }
                  });
               };
            }
         };
      }]
);
