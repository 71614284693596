/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for the list view.
 */

import DataSourceParameterMapDataSort = kendo.data.DataSourceParameterMapDataSort;
import ExportableColumn = platform.ExportableColumn;
import ListSorting = platform.ListSorting;
import ColumnDataSourceInfo = platform.ColumnDataSourceInfo;
import ListOptions = platform.ListOptions;
import DataSourceTransportParameterMapData = kendo.data.DataSourceTransportParameterMapData;
import IHttpPromise = angular.IHttpPromise;
import IHttpPromiseCallbackArg = angular.IHttpPromiseCallbackArg;

interface KendoDataSourceTransportOptions {
   data: DataSourceTransportParameterMapData;
   success: (data: any) => void;
   error: (reason: any) => void;
}

// Based on the SortSpec.java
interface SortSpec {
   // Sort direction
   dir: "asc"|"desc";

   // Field for sorting the dataset
   field: string;
}

// Based on the ListViewSpec.java file
interface ListViewSpec {
   /**
    * Unique identifier of a constraint object.
    */
   constraintObjectId: string;

   /**
    *  Unique identifier of a query filter builder.
    * The constraint object id is passed unchanged
    * as the first argument of the filter builder.
    */
   queryFilterId: string;

   /**
    * An optional list of additional filter parameters.
    * If present, filter parameters are passed positionally
    * and unchanged to the query filter builder, but after
    * the constraint object id.
    */
   filterParams: string[];

   /**
    * An optional list of data models. Data models
    * in DataService 2.0 replace the DataService 1.0
    * object types.
    */
   dataModels: string[];

   /**
    * List of properties to request for the matched objects.
    */
   requestedProperties: string[];

   /**
    * An optional list of property parameters required for
    * retrieving one or more properties specified in
    * <code>requestedProperties</code>.
    */
   propertyParamSpecs?: any[];

   /**
    * Sort specification passed by KendoGrid.
    * KndoGrid calls this property
    */
   sort: SortSpec[];

   /**
    * Search term to filter list data
    */
   searchTerm: string;

   /**
    * List of searchable properties to use when searching for <code>searchTerm</code>
    */
   searchableProperties: string[];

   /**
    * The offset into the result of items. KendoGrid calls
    * this parameter "skip". If the offset is N then items
    * from N to N + maxResultCount - 1 will be returned.
    * If empty, it will default to 0.
    */
   skip?: number;

   /**
    * The number of items to retrieve.
    * Default value is -1 and all results are retrieved.
    * KendoGrid calls this parameter "take".
    */
   take?: number;

   /**
    * Optional unique identifier for a list view.
    * NOTE: this is used only for debugging (as part of an opId). Business logic must
    * not depend on the value of this property.
    */
   listViewId: string;

   /**
    * Indicates that the current request is for a live update.
    * NOTE: this is used only for debugging. Business logic must not depend on the
    * value of this property.
    */
   isLiveRefreshRequest: boolean;
}

(function () {
   'use strict';
   angular
         .module('com.vmware.platform.ui')
         .controller('ListViewController', ListViewController);

   ListViewController.$inject = [
      '$window',
      '$scope',
      '$element',
      '$q',
      '$compile',
      '$http',
      '$timeout',
      '$location',
      'columnRenderersRegistry',
      'vuiConstants',
      'actionsService',
      'dataService',
      'vcH5ConstantsService',
      'i18nService',
      'listViewColumnService',
      'visibilityAwareDataUpdateNotificationService',
      'configurationService',
      'websocketMessagingService',
      'visibilityMonitorService',
      'logService',
      'vcService',
      'defaultUriSchemeUtil',
      'treeUpdatesConstants',
      'browserService',
      'vxZoneService',
      'exportListService',
   ];

   function ListViewController($window,
                               $scope,
                               $element,
                               $q,
                               $compile,
                               $http,
                               $timeout,
                               $location,
                               colRendererReg,
                               vuiConstants,
                               actionsService,
                               dataService,
                               vcH5ConstantsService,
                               i18nService,
                               listViewColumnService,
                               visibilityAwareDataUpdateNotificationService,
                               configurationService,
                               websocketMessagingService,
                               visibilityMonitorService,
                               logService,
                               vcService,
                               defaultUriSchemeUtil,
                               treeUpdatesConstants,
                               browserService,
                               vxZoneService,
                               exportListService) {

      const log = logService('ListViewController');

      const DEFAULT_REFRESH_TIMEOUT: number = 1500;

      const ID_FIELD_NAME: string = "id";

      const DATAGRID_VERTICAL_SCROLLER_WIDTH: number = 20;

      const DATAGRID_DEFAULT_COLUMN_WIDTH: number = 200;

      // Minimum value of the live refresh period.
      const LIVE_REFRESH_PERIOD_MIN: number = 5000;

      // Maximum value of the live refresh period.
      const LIVE_REFRESH_PERIOD_MAX: number = 15000;

      // Step increment of the live refresh period.
      const LIVE_REFRESH_PERIOD_INC: number = 2000;

      // Maximum number of related entities to keep in the cache.
      const RELATED_ENTITIES_CACHE_SIZE_MAX: number = 5000;

      // Number of entries to remove from the related entities cache on eviction.
      const RELATED_ENTITIES_EVICT_SIZE: number = 500;

      // The separator used to delimit specified properties in the
      // ColumnDataSourceInfo.filterProperty
      const FILTER_PROPERTY_SEPARATOR: string = ",";

      const filterPlaceholderText = i18nService.getString('Common', 'filter.label');
      const filterButtonText = i18nService.getString('Common', 'applyFilter.label');
      const FILTER_INPUT_TEMPLATE =
            '<div class="filter-input pull-right">' +
            '<button class="nav-icon fa fa-filter filter-button" ng-click="doSearch()" ' +
                  'aria-label="' + filterButtonText + '" /></button>' +
            '<input type="text" ng-model="searchTerm" ng-trim="true" ng-change="debouncedRefreshCallback()"' +
            ' ng-keypress="keyPress($event)" placeholder="' + filterPlaceholderText + '"/>' +
            '</div>';

      const filterElement = $compile(FILTER_INPUT_TEMPLATE)($scope);

      const NETWORK_TYPES: any = {
         "AnyNetwork": [
            "Network",
            "DistributedVirtualPortgroup",
            "OpaqueNetwork"
         ],
         "HostNetwork": [
            "Network",
            "OpaqueNetwork"
         ],
         "AnyDistributedVirtualSwitch": [
            "VmwareDistributedVirtualSwitch",
            "DistributedVirtualSwitch"
         ]
      };

      let objectId = $scope.objectId;

      const listViewId: string = $scope.listViewId;

      const listViewType = $scope.listViewType;

      const persistColumnsState = listViewColumnService.getColumnChangeHandler(
            listViewId, $scope);

      const dataUrl = 'list/ex/';

      // Indicates that the current refresh request is for a live update.
      let isLiveRefreshRequest: boolean = false;

      // Index of the first clicked item in range selection.
      let selectionStartIndex: number = 0;

      // Index of the second clicked item in range selection.
      let selectionEndIndex: number = 0;

      // keeps reference to all searchable properties that correspond to
      // the list of visible searchable columns
      let searchableProperties: string[] = [];

      let dataParams;

      let lastSearchTerm: string;

      let liveUpdatesQueue: any[] = [];

      // Time of the last live refresh.
      let lastLiveRefreshTimestamp: number = _.now();

      // History of the intervals of time between successive live refreshes.
      let liveRefreshDeltasHistory: number[] = [];

      // Indicates whether the live updates handling is active for this list view.
      let liveUpdatesEnabled: boolean = false;

      let liveUpdatesPeriodicRefreshHandle: any = null;

      let liveRefreshPeriod: number = LIVE_REFRESH_PERIOD_MIN;

      // Map of entityId -> {timestamp, parentIds} for related entities.
      let relatedEntitiesCache: any = Object.create(null);

      // Current number of entries in the related entities cache.
      let relatedEntitiesCacheSize: number = 0;

      // Map of id -> data for all selected items in all visited grid pages.
      let selectedItemDataById: any = Object.create(null);

      // Used to prevent looping when restoring selected items during data operations.
      let isRestoringSelection: boolean = false;

      // Indicates whether a list refresh is currently in progress.
      let isRefreshInProgress: boolean = false;

      // Used to store toolbar actions.
      let toolbarActions: Array<any> = [];

      let lastListViewSpec: string;
      let lastSortSpec: SortSpec[];

      let exportActionDef: any;

      // Set pageSize explicitly - don't rely on default size. This is need to avoid
      // out of sync issues with the call that loads the initial page (where we make
      // part of listViewSpec below by hand and the calls
      // that load the subsequent pages where some of the parameters (like group,
      // page, pageSize, skip, sort, take) come from the Kendo Grid itself based on
      // its internal state.
      const pageSize = 100;

      /**
       * Whether to refresh the view when it transitions from a hidden state (cached)
       * to a visible state.
       */
      let refreshOnShow: boolean = false;

      // Enumerates the possible states of the grid loading mask.
      enum LoadingMaskState {
         Visible,
         Hidden
      }

      $scope.doSearch = function () {
         refreshGrid();
      };

      $scope.keyPress = function (event) {
         if (event.keyCode === 13) {
            // In IE Enter would activate the focused item so we prevent this default
            // behavior.
            event.preventDefault();
            event.stopPropagation();
            refreshGrid();
         }
      };

      $scope.debouncedRefreshCallback = _.debounce(function () {
         if ($scope.searchTerm !== lastSearchTerm) {
            refreshGrid();
         }
      }, 2000);

      $scope.comparatorWrapper = function (data) {
         return $scope.preselectComparator({item: data});
      };

      if ($scope.liveRefreshEnabled
            && $scope.objectId
            && vcService.isValidManagedEntityId($scope.objectId)) {
         const featureEnabled =
               configurationService.getProperty('live.updates.lists.enabled');
         const featureSupportedByVc = vcService.is65VcOrLater($scope.objectId);
         $q.all([featureEnabled, featureSupportedByVc]).then(
               (results: any[]) => {
                  if (!results || results.length === 0) {
                     return;
                  }
                  const enabled: boolean =
                        results[0] && results[0].toUpperCase() === "TRUE";
                  const supported: boolean = results[1];
                  liveUpdatesEnabled = enabled && supported;
                  if (liveUpdatesEnabled) {
                     let serverGuid: string = defaultUriSchemeUtil
                           .getPartsFromVsphereObjectId($scope.objectId).serverGuid;
                     websocketMessagingService.openInventory(serverGuid);
                     startPeriodicLiveUpdatesHandler();
                  }
               });
      }

      if ($scope.filterId) {
         dataParams = {
            constraintObjectId: objectId,
            queryFilterId: $scope.filterId
         };
         if ($scope.filterParams) {
            dataParams.filterParams = $scope.filterParams;
         }
         if ($scope.propertyParams) {
            dataParams.propertyParamSpecs = $scope.propertyParams;
         }

         $scope.$watch(function () {
            return $scope.objectId;
         }, function (newValue, oldValue) {
            if (newValue !== oldValue) {
               objectId = newValue;
               dataParams.constraintObjectId = objectId;
               if ($scope.propertyParams) {
                  dataParams.propertyParamSpecs = $scope.propertyParams;
               }
               refreshGrid();
            }
         });

      } else {
         dataParams = {
            constraintObjectId: objectId,
            queryFilterId: 'relatedItemsListFilterId',
            filterParams: [$scope.relationId, $scope.showRelationsFor].filter(
                  (item?: string) => !!item) // filter out undefined/null/empty items.
         };
      }

      function getSelectionMode(selectionMode) {
         let result = vuiConstants.grid.selectionMode.SINGLE;
         if (selectionMode) {
            switch (selectionMode.toLowerCase()) {
               case vuiConstants.grid.selectionMode.NONE:
                  result = vuiConstants.grid.selectionMode.NONE;
                  break;
               case vuiConstants.grid.selectionMode.SINGLE:
                  result = vuiConstants.grid.selectionMode.SINGLE;
                  break;
               case vuiConstants.grid.selectionMode.MULTI:
                  result = vuiConstants.grid.selectionMode.MULTI;
                  break;
            }
         }
         return result;
      }

      const sortOrderSpec: DataSourceParameterMapDataSort[] = [];

      /**
       * Used to keep track of which properties need to be retrieved to display
       * a certain column.
       */
      const propertiesById = {};

      /**
       * Used to keep track the searchable properties per column.
       */
      const searchPropertiesById: {[columnUid: string]: string[]} = {};

      /**
       * Used to keep track the exportable property per column.
       */
      const exportPropertyById: string[] = [];

      /**
       * Used to keep track of which resource models need to be used to display
       * a certain column.
       */
      const resourceModelsById = {};

      /**
       * Debounces frequent refreshing after a column is shown.
       */
      const refreshOnColumnShow = _.debounce(function (kendoColumns) {
         const visibleColumns: any[] = getVisibleColumns();
         dataParams.requestedProperties = getPropertiesToFetch(visibleColumns);
         dataParams.dataModels = getResourceModels(visibleColumns, $scope.getModels());
         searchableProperties = getSearchProperties(visibleColumns);
         refreshGrid();
      }, 1500);

      let selectionMode = getSelectionMode($scope.selectionMode);

      let showCheckboxesOnMultiSelection = ($scope.showCheckboxesOnMultiSelection() !== undefined)
            ? $scope.showCheckboxesOnMultiSelection()
            : true;

      if (typeof showCheckboxesOnMultiSelection !== 'boolean') {
         throw Error("showCheckboxesOnMultiSelection should evaluate to boolean, but it is a " +
               typeof showCheckboxesOnMultiSelection);
      }

      $scope.showActionsMenu = function (event) {
         if ($scope.rightClickable === "true") {
            const left = event.clientX;
            const top = event.clientY;
            // NOTE: Right-clicking an item in single selection mode does not change
            // the selection (and hence the on-change handler does not get called) so
            // we need to pass the click target as the selected item.
            const selectedObjects =
                  (selectionMode === vuiConstants.grid.selectionMode.MULTI)
                        ? getSelectedObjects()
                        : event.data;
            actionsService.showObjectContextMenu(event, $scope, selectedObjects, left, top);
         }
      };

      $scope.$watch('datagridOptions.selectedItems', function (newItems, oldItems) {
         newItems = newItems || [];
         oldItems = oldItems || [];
         if (_.isEqual(newItems, oldItems)) {
            return;
         }
         saveSelection().then(() => {
            const selectedObjects: any[] = getSelectedObjects();

            if ($scope.hasOwnProperty("readOnlySelectedItems")) {
               $scope.readOnlySelectedItems = selectedObjects;
            }

            onSelectionChanged(selectedObjects, oldItems);
         });
      });

      $scope.resizeGrid = resizeGrid;

      /**
       * @param force
       *  force the layout readjustment without setting a new height
       */
      function resizeGrid(force?: boolean): void {
         const grid = $element.find('.k-grid').data('kendoGrid');
         if (!grid) {
            log.warn('No kendo grid');
            return;
         }
         if (grid.element) {
            grid.resize(force);
         }
      };

      function startPeriodicLiveUpdatesHandler() {
         log.debug('Starting the periodic refresh handler with period',
               liveRefreshPeriod, 'ms.');
         vxZoneService.runOutsideAngular(() => {
            liveUpdatesPeriodicRefreshHandle =
                  setInterval(periodicLiveUpdatesHandler, liveRefreshPeriod);
         });
      }

      function stopPeriodicLiveUpdatesHandler() {
         log.debug('Stopping the periodic live refresh handler.');
         vxZoneService.runOutsideAngular(() => {
            if (liveUpdatesPeriodicRefreshHandle) {
               clearInterval(liveUpdatesPeriodicRefreshHandle);
               liveUpdatesPeriodicRefreshHandle = null;
            }
         });
      }

      function restartPeriodicLiveRefreshHandler(): void {
         stopPeriodicLiveUpdatesHandler();
         startPeriodicLiveUpdatesHandler();
      }

      function periodicLiveUpdatesHandler(): void {
         adjustLiveRefreshPeriod();

         // Skip processing if a list refresh has already been scheduled or the
         // update queue is empty.
         if (isRefreshInProgress
               || !liveUpdatesQueue
               || liveUpdatesQueue.length === 0) {
            return;
         }

         // As a side-effect, this empties the queue.
         const updatesBatch: any[] = liveUpdatesQueue.splice(0, liveUpdatesQueue.length);
         processLiveUpdates(updatesBatch);
      }

      // Apply a simple heuristic to determine how often the list view should handle
      // the queued live updates (the refresh period).
      function adjustLiveRefreshPeriod(): void {
         // Number of required history samples.
         const r: number = (LIVE_REFRESH_PERIOD_MIN +
                            LIVE_REFRESH_PERIOD_MAX -
                            liveRefreshPeriod) / 1000;
         // Number of available history samples.
         const n: number = liveRefreshDeltasHistory.length;
         // Number of refresh periods passed since last refresh.
         const p: number = Math.floor((_.now() - lastLiveRefreshTimestamp) /
                                      liveRefreshPeriod);
         if (n < r) {
            if (p > r) {
               liveRefreshDeltasHistory.splice(0, 1);
               if (liveRefreshPeriod > LIVE_REFRESH_PERIOD_MIN) {
                  liveRefreshPeriod -= LIVE_REFRESH_PERIOD_INC;
                  restartPeriodicLiveRefreshHandler();
               }
            }
         } else { /* n >= r > 0 */
            let increasing: boolean = true;
            let decreasing: boolean = true;
            let sum: number = liveRefreshDeltasHistory[0];
            for (let i: number = 1; i < n; ++i) {
               const curr: number = liveRefreshDeltasHistory[i];
               const prev: number = liveRefreshDeltasHistory[i - 1];
               increasing = increasing && curr > prev;
               decreasing = decreasing && curr < prev;
               sum += curr;
            }
            const avg: number = sum / n;
            let newPeriod: number = liveRefreshPeriod;
            if (increasing || avg > liveRefreshPeriod + LIVE_REFRESH_PERIOD_INC
                  && liveRefreshPeriod > LIVE_REFRESH_PERIOD_MIN) {
               newPeriod -= LIVE_REFRESH_PERIOD_INC;
            } else if (decreasing || avg < liveRefreshPeriod - LIVE_REFRESH_PERIOD_INC
                  && liveRefreshPeriod < LIVE_REFRESH_PERIOD_MAX) {
               newPeriod += LIVE_REFRESH_PERIOD_INC;
            }
            if (liveRefreshPeriod !== newPeriod) {
               liveRefreshPeriod = newPeriod;
               restartPeriodicLiveRefreshHandler();
            }
            liveRefreshDeltasHistory.splice(0, 1);
         }
      }

      function updateLiveRefreshHistory(): void {
         log.debug('Refreshing to reflect a live update.');
         const last: number = lastLiveRefreshTimestamp;
         lastLiveRefreshTimestamp = _.now();
         liveRefreshDeltasHistory.push(lastLiveRefreshTimestamp - last);
      }

      function processLiveUpdates(updates: any[]): void {
         const modelMatch = function (update: any): boolean {
            if (!dataParams.dataModels) {
               return true;
            }
            return _.some(dataParams.dataModels, (model: string): boolean => {
               const type: string = update.source.type;
               return ((model === type)
                       || (NETWORK_TYPES[model]
                           && _.contains(NETWORK_TYPES[model], type)));
            });
         };

         const propertyChangeOrDelete = function (update: any) {
            const updateType = update.data.type;
            return ((updateType === treeUpdatesConstants.PROPERTIES_UPDATE)
                 || (updateType === treeUpdatesConstants.DELETE_OBJECT));
         };

         const dataSource: any = getDataSource();
         if (!dataSource) {
            return;
         }

         // Checks if an item belongs to any of the currently loaded list pages.
         const loadedEntities: string[] = getLoadedEntities(dataSource);
         const isLoaded = function (id: string) {
            return _.indexOf(loadedEntities, id) >= 0;
         };

         // An array of IDs of entities that should be checked for relevance to this
         // list.
         let entityIds: string[] = [];

         // Flag that indicates if the current page should be refreshed immediately.
         // This is used to bypass any unnecessary further checks.
         let refreshPage: boolean = false;

         let updateOnContextObject: boolean = false;

         _.each(updates, function (update: any) {

            const source: any = update.source;
            const id: string = defaultUriSchemeUtil.getVsphereObjectId(source);

            if (id === $scope.objectId) {
               // We have an update on the context object, it can be some destructive
               // operation or parent/children update, so we need to refresh.
               updateOnContextObject = true;
               refreshPage = true;
               return;
            }

            if (!modelMatch(update)) {
               // Skip updates for types not supported by this list.
               return;
            }

            const type: string = update.data.type;
            switch (type) {
               case treeUpdatesConstants.PARENT_UPDATE:
                  deleteRelatedEntityFromCache(id);
                  break;
               case treeUpdatesConstants.DELETE_OBJECT:
                  deleteRelatedEntityFromCache(id);
                  if (isItemSelected(id)) {
                     removeSelectedItem(id);
                  }
                  break;
               default:
                  updateRelatedEntitiesCache(id);
                  break;
            }

            if (isLoaded(id)) {
               // The updated entity is visible so we can refresh immediately without
               // further checks.
               refreshPage = true;
               return;
            }

            if (update.data.isNameUpdate || !propertyChangeOrDelete(update)) {
               // No list-specific update should trigger a refresh, unless it is a name
               // update (which can affect the ordering of items in the list). We don't
               // need to refresh if an invisible item changes state or is deleted.
               entityIds.push(id);
            }
         });

         // Check if any of the entities for which we have received updates is in the
         // related entities cache.
         entityIds = _.unique(entityIds);
         const entitiesToCheck: string[] = [];
         for (let i: number = 0; i < entityIds.length; ++i) {
            const entityId: string = entityIds[i];
            const cacheEntry: any = relatedEntitiesCache[entityId];
            if (cacheEntry) {
               if (_.contains(cacheEntry.parentIds, $scope.objectId)) {
                  log.debug('Cache hit, skipping inverse relation checks.');
                  refreshPage = true;
                  break;
               }
               // Ending here means the entity is cached, but none of its parents
               // is the context object, so we know for a fact that the entity does
               // not belong to the list and there's no need to make an inverse
               // relation check on it.
            } else {
               // Not cached - we need to make an inverse relation check.
               entitiesToCheck.push(entityId);
            }
         }

         if (refreshPage) {
            // In case the update is on the context object, it might have been caused
            // by a non-atomic server-side operation (e.g. creating a virtual machine).
            // In this case we need to delay the refresh for a bit until the change
            // has a chance to actually take place.
            vxZoneService.runOutsideAngular(() => {
               setTimeout(() => {
                     refreshOnLiveUpdates();
                     updateLiveRefreshHistory();
                  }, updateOnContextObject ? DEFAULT_REFRESH_TIMEOUT : 0);
            });
            return;
         }

         if (!$scope.inverseRelation) {
            log.debug('Missing inverse relation for list view with id:', listViewId);
            return;
         }

         if (entitiesToCheck.length === 0) {
            return;
         }

         // Make a relevance check - this is achieved by requesting the inverse relation
         // for the selected entities and checking if any of these matches the current
         // context object.
         vxZoneService.runOutsideAngular(() => {
            setTimeout(() => {
               // This timeout is needed because certain server-side operations are not
               // atomic, e.g. creating a new virtual machine; we need to wait for a
               // while so that these have time to finish.
               requestInverseRelations(entitiesToCheck).then((response: any) => {
                  const result: any[] = processInverseRelationCheckResponse(response);
                  cacheRelatedEntities(...result);
                  const parentIds: string[] =
                        _.chain(result).pluck('parentIds').flatten().unique().value();
                  const refreshRequired: boolean =
                        _.any(parentIds, (id: string) => {
                           return id === $scope.objectId;
                        });
                  if (refreshRequired) {
                     refreshOnLiveUpdates();
                     updateLiveRefreshHistory();
                  }
               });
            }, DEFAULT_REFRESH_TIMEOUT);
         });
      }

      /**
       * Add a variable number of entries to the related entities cache.
       * @param entries An object that has the following properties:
       * - entityId - A string retpreseting a managed entity URI.
       * - parentIds - An array of strings, each representing a managed entity URI.
       */
      function cacheRelatedEntities(...entries: any[]): void {
         evictRelatedEntitiesCache(entries.length);
         _.each(entries, (entry: any) => {
            if (!_.has(relatedEntitiesCache, entry.entityId)) {
               ++relatedEntitiesCacheSize;
            }
            relatedEntitiesCache[entry.entityId] = {
               'timestamp' : _.now(),
               'parentIds' : entry.parentIds
            };
         });
      }

      /**
       * Update the timestamp of a related entities cache entry.
       * @param {string} entityId A string representing a managed entity URI.
       */
      function updateRelatedEntitiesCache(entityId: string) {
         if (_.has(relatedEntitiesCache, entityId)) {
            relatedEntitiesCache[entityId].timestamp = _.now();
         }
      }

      /**
       * Delete an entry from the related entities cache.
       * @param {string} entityId A string representing a managed entity URI.
       */
      function deleteRelatedEntityFromCache(entityId: string): void {
         if (_.has(relatedEntitiesCache, entityId)) {
            delete relatedEntitiesCache[entityId];
            --relatedEntitiesCacheSize;
         }
      }

      /**
       * Remove stale entries from the related entities cache.
       * @param toBeAdded Optional number of entries to be added to the cache later.
       * It must be a non-negative integer. If not specified, or the value of the
       * argument is invalid, it defaults to zero.
       */
      function evictRelatedEntitiesCache(toBeAdded?: number): void {
         toBeAdded = angular.isDefined(toBeAdded) && toBeAdded >= 0 ? toBeAdded : 0;
         const expandedSize: number = relatedEntitiesCacheSize + toBeAdded;
         if (expandedSize >= RELATED_ENTITIES_CACHE_SIZE_MAX) {
            // Transform the cache object into an array of pairs, sorted by timestamp.
            let entries: any = _.sortBy(_.pairs(relatedEntitiesCache),
                  (pair: any[]) => { return pair[1].timestamp; });
            const actualEvictSize: number = RELATED_ENTITIES_EVICT_SIZE
                                          + expandedSize
                                          - RELATED_ENTITIES_CACHE_SIZE_MAX;
            entries.splice(0, actualEvictSize);
            relatedEntitiesCache = _.object(entries);
            relatedEntitiesCacheSize = entries.length;
            log.debug('Related entities cache evicted.');
         }
      }

      // Request the inverse relation property for the given sequence of entities.
      function requestInverseRelations(entities: string[]): angular.IPromise<any> {
         // Entities need to be grouped by type since data service currently does not
         // support heterogeneous requests.
         const entitiesByType = _.groupBy(entities, function (entity) {
            return defaultUriSchemeUtil
                  .getPartsFromVsphereObjectId(entity).type;
         });
         let opId: String = "ListViewController.requestInverseRelations";
         if ($scope.listViewId) {
            opId += "; listViewId=" + $scope.listViewId;
         }
         if ($scope.relationId) {
            opId += "; relationId=" + $scope.relationId;
         }
         const deferred = _.map(_.values(entitiesByType), function (entities) {
            return dataService
                  .getPropertiesForObjects(entities, [$scope.inverseRelation], {
                     queryName: opId,
                     skipLoadingNotification: true
                  });
         });
         return $q.all(deferred);
      }

      function processInverseRelationCheckResponse(response: any[]): any[] {
         const result: any[] = [];
         _.each(response, (item: any) => {
            _.each(_.keys(item), (entityId: string) => {
               const parents: any = item[entityId][$scope.inverseRelation];
               result.push({
                  'entityId' : entityId,
                  'parentIds' : _.map(_.isArray(parents) ? parents : [parents],
                        (parent: any) => defaultUriSchemeUtil.getVsphereObjectId(parent))
               });
            });
         });
         return result;
      }

      function isModelChangeRelevant(event, objectChangeInfo) {
         const operationType = objectChangeInfo.operationType;
         const objectId = objectChangeInfo.objectId;

         if (isChangeOnContextObject(objectId, operationType)) {
            return true;
         }

         switch (operationType) {
            case 'ADD':
               if (typeof objectChangeInfo.object === 'string') {
                  return true;
               }
               return objectChangeInfo.object.type === listViewType;
            case 'CHANGE':
            case 'DELETE':
               const dataSource: any = getDataSource();
               return !!(dataSource && dataSource.get(objectId));
         }

         return false;
      }

      function onSelectionChanged(selectedObjects: any[], oldItems: any[]): void {
         if ($scope.onSelectionChanged) {
            $scope.onSelectionChanged({
               'newItems': selectedObjects,
               'oldItems': oldItems
            });
         }
         addSelectionContextToUrl(selectedObjects);
      }

      function onModelChanged(event, objectChangeInfo): void {
         // If a selected object has been deleted we have update the map of selected
         // items.
         const operationType = objectChangeInfo.operationType;
         const entityId = objectChangeInfo.objectId;
         if (operationType === "DELETE" && selectedItemDataById[entityId]) {
            delete selectedItemDataById[entityId];
         }
         // A small refresh timeout is added to change operations on the context object,
         // because some operations take slightly longer to complete and it is possible
         // to receive an update before they are finished.
         if (entityId === $scope.objectId && operationType === "CHANGE") {
            vxZoneService.runOutsideAngular(() => {
               setTimeout(() => {
                  liveUpdatesQueue = [];
                  refreshGrid(LoadingMaskState.Hidden);
               }, DEFAULT_REFRESH_TIMEOUT);
            });
         } else {
            liveUpdatesQueue = [];
            refreshGrid(LoadingMaskState.Hidden);
         }
      }

      const dataUpdateEventsConfig = {};

      dataUpdateEventsConfig[vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT] = {
         handler: refreshGrid
      };

      dataUpdateEventsConfig[vcH5ConstantsService.MODEL_CHANGED_EVENT] = {
         handler: onModelChanged,
         checker: isModelChangeRelevant
      };

      if ($scope.liveRefreshEnabled) {
         dataUpdateEventsConfig['navTree'] = {
            handler: (event, partialUpdate) => {
               let updates: any[] = partialUpdate.updates || [];
               Array.prototype.push.apply(liveUpdatesQueue, updates);
               refreshOnShow = true;
            },
            checker: (event, partialUpdate) => {
               if (!liveUpdatesEnabled || !objectId
                     || _.isEmpty(partialUpdate.updates)) {
                  return false;
               }

               return true;
            }
         };
      }

      let visibilityStatusSubscriberId = visibilityMonitorService
            .subscribeForVisibilityChange($element, (visible: boolean) => {
               if (visible) {
                  if (!refreshOnShow) {
                     return;
                  }

                  refreshOnShow = false;
                  refreshGrid();
               } else {
                  if (liveUpdatesQueue.length) {
                     refreshOnShow = true;
                     liveUpdatesQueue = [];
                  }
               }
            });

      let dataUpdateNotificationSubscriberId =
            visibilityAwareDataUpdateNotificationService.subscribeForDataUpdate(
                  $element,
                  refreshGrid,
                  dataUpdateEventsConfig
            );

      $scope.$on('$destroy', function () {
         if (visibilityStatusSubscriberId !== null) {
            visibilityMonitorService.unsubscribe(visibilityStatusSubscriberId);
            visibilityStatusSubscriberId = null;
         }
         if (dataUpdateNotificationSubscriberId !== null) {
            visibilityAwareDataUpdateNotificationService.unsubscribe(
                  dataUpdateNotificationSubscriberId
            );
            dataUpdateNotificationSubscriberId = null;
         }
         stopPeriodicLiveUpdatesHandler();
         filterElement.remove();
      });

      fetchListOptions().then((listOptions: ListOptions) => {
         if ($scope.overrideMetadataFromServer === "true" && Object.keys(listOptions.metadata).length) {
            if (listOptions.metadata.selectionMode) {
               selectionMode = getSelectionMode(listOptions.metadata.selectionMode);
            }

            if (listOptions.metadata.isDraggable) {
               $scope.isDraggable = listOptions.metadata.isDraggable;
            }
            showCheckboxesOnMultiSelection = listOptions.metadata.showCheckboxesOnMultiSelection === 'true';
         } else {
            selectionMode = getSelectionMode($scope.selectionMode);
         }
         $scope.datagridOptions =
            createDatagridOptions(listOptions.columnDataSourceInfos, listOptions.sort);
      });


      actionsService.buildActionsToolbar(listViewId).then(
            (actions: any) => {
               toolbarActions = _.values(actions);
               if ($scope.datagridOptions && toolbarActions.length > 0) {
                  $scope.datagridOptions.actionBarOptions.actions = toolbarActions;
               }
            });

      /**
       * Creates datagrid options
       */
      function createDatagridOptions(colDefs: ColumnDataSourceInfo[], sort: ListSorting[] | undefined): any {
         const vuiColumnDefs = createVuiColumnDefinitions(colDefs, sort);
         const visibleColumns: any[] = [];
         vuiColumnDefs.forEach(function (columnDef) {
            if (columnDef.visible) {
               visibleColumns.push(columnDef.uid);
            }
         });
         dataParams.requestedProperties = getPropertiesToFetch(visibleColumns);
         dataParams.dataModels = getResourceModels(visibleColumns, $scope.getModels());
         searchableProperties = getSearchProperties(visibleColumns);

         const footerActions: any = [];
         if ($scope.showExport) {
            exportActionDef = getExportActionDef();
            footerActions.push(exportActionDef);
         }

         // See JIRA VSUN-1667 - we send the initial data request ahead of time since
         // grid options processing from the Kendo takes a lot of time due to
         // the many columns. So we want to gather data in parallel to this  processing
         // time. Otherwise, Kendo itself will request the data at the end
         // of the grid options processing and this will increase total grid
         // initialization time.
         const initialListViewSpec: ListViewSpec = buildListViewSpec(;
         let initialRequest = $http.post(dataUrl, initialListViewSpec);

         const datagridOptions: any = {
            columnDefs: vuiColumnDefs,
            pageConfig: {
               displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING,
               size: pageSize
            },
            data: {
               transport: {
                  read: (options: KendoDataSourceTransportOptions) => {
                     // The options object is passed from Kendo Grid and is mostly based
                     // on its internal state.
                     const listViewSpec: ListViewSpec = buildListViewSpec(options);

                     const request: IHttpPromise<any> = initialRequest ?
                           initialRequest : $http.post(dataUrl, listViewSpec);

                     // Clean this one unconditionally.
                     initialRequest = undefined;

                     return request.then(
                           (successResponse: IHttpPromiseCallbackArg<any>) => {
                              // notify the data source that the request succeeded
                              // NOTE: When Kendo was requesting data on its own - it was using barebone Ajax
                              // and the data was arriving outside the Angular Zone. With the usage of $http.post
                              // the response arrives within a running $digest cycle.
                              // inside the Angular Zone. After passing the data to
                              // Kendo, if there are already selected rows, this
                              // triggers restoreSelection() call. It triggers Kendo
                              // CHANGE event, which triggers
                              // vui-angular.widgetOnChangeHandler, which calls
                              // vuiZoneService.runInsideAngularZone which in a long run
                              // leads to a new $digest() invocation and now we end-up
                              // with "$digest already in progress" error. To avoid
                              // this - we feed Kendo with the data using the setTimeout()
                              // to behave more closely to the case when it requests
                              // data on its own.
                              $timeout(() => { options.success(successResponse.data); }, 0);
                        }, (errorResponse: any) => {
                              $timeout(() => { options.error(errorResponse); }, 0);
                        });
                  }
               },
               data: 'data',
               total: 'totalResultCount'
            },
            actionBarOptions: {
               actions: toolbarActions
            },
            selectedItems: [],
            height: '100%',
            selectionMode: selectionMode,
            showCheckboxesOnMultiSelection: showCheckboxesOnMultiSelection,
            resizable: true,
            reorderable: true,
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: i18nService.getString("CommonUi", "listview.showColumnsMenu"),
                  filter: i18nService.getString("CommonUi", "listview.filterMenu")
               }
            },
            columnHide: onColumnShowHide,
            columnReorder: persistColumnsState,
            columnResize: persistColumnsState,
            columnShow: onColumnShowHide,
            dataBound: onDataBound,
            sortOrder: sortOrderSpec,
            vuiFooter: {
               actions: footerActions
            }
         };

         if (browserService.isIe) {
            // If the browser is IE we will decrease the page size in order to minimize
            // the DOM elements. This is done due to a performance measures
            datagridOptions.pageConfig.size = 50;
         }
         return datagridOptions;
      }

      function buildListViewSpec(
            options?: KendoDataSourceTransportOptions): ListViewSpec {
         const listViewSpec: ListViewSpec = {
            constraintObjectId: dataParams.constraintObjectId as string,
            queryFilterId: dataParams.queryFilterId as string,
            filterParams: dataParams.filterParams as string[],
            propertyParamSpecs: dataParams.propertyParamSpecs as any[],
            requestedProperties: dataParams.requestedProperties as string[],
            dataModels: dataParams.dataModels as string[],
            searchTerm: dataParams.searchTerm as string,
            searchableProperties: dataParams.searchableProperties as string[],

            take: options ? options.data.take : pageSize,
            skip: options ? options.data.skip : 0,
            sort: (options ? options.data.sort : sortOrderSpec) as SortSpec[],

            listViewId: listViewId,
            isLiveRefreshRequest: isLiveRefreshRequest,
         };

         preprocessBeforeSend(listViewSpec);

         return listViewSpec;
      }

      function preprocessBeforeSend(listViewSpec: ListViewSpec) {
         if (listViewSpec.sort) {
            if (!_.isEqual(lastSortSpec, listViewSpec.sort)) {
               lastSortSpec = listViewSpec.sort;
               // When the user sorts the list we have to reset the
               // selection range because the item indexes are shuffled.
               // We can keep the selected items since they are saved using
               // the item ID which is immutable.
               resetSelectionRange();
            }

            // Create a copy of the original sort spec. This is needed as the original
            // one comes from Kendo, and column names have a notation that is about to
            // change with the call to unwrapField. So if we don't clone, we will
            // mess-up with Kendo internal structure - so it will not show correctly
            // the column sorting state.
            listViewSpec.sort = listViewSpec.sort.map((sortSpec: SortSpec) => {
                  const newSortSpec = _.clone(sortSpec);
                  newSortSpec.field = unwrapField(sortSpec.field!);
                  return newSortSpec;
               });
         }

         // Keep a copy for the export functionality
         lastListViewSpec = JSON.stringify(listViewSpec);
      }

      /**
       * Fetch list options.
       * The list options contain column definitions and metadata of the list.
       */
      function fetchListOptions(): angular.IPromise<ListOptions> {
         return listViewColumnService.getListOptions(listViewId, listViewType, objectId);
      }

      /**
       * Returns a rendering function based on the column definition.
       * If no renderer is specified the default 'text' renderer is used.
       */
      function getRenderer(columnDef) {
         const rendererName = columnDef.columnRenderer || 'text';
         const renderer = colRendererReg.getColumnRenderer(rendererName);
         const rendererConfig = columnDef.columnRendererConfig || {};
         if (angular.isDefined($scope.navigatable)) {
            rendererConfig.navigatable = $scope.navigatable === 'true';
         }
         return function (data) {
            // NOTE: renderer config might be skipped for most columns ...
            return renderer(columnDef.requestedProperties, data, rendererConfig);
         };
      }

      // Save the currently selected items in the grid.
      function saveSelection(): angular.IPromise<any> {
         return $q((resolve) => {
            if (isRestoringSelection) {
               isRestoringSelection = false;
            }
            if (!canSelectItems()) {
               resolve();
               return;
            }
            const grid: any = getGrid();
            if (!grid) {
               resolve();
               return;
            }
            const mode: string = getSelectionMode(selectionMode);
            if (mode === vuiConstants.grid.selectionMode.SINGLE) {
               handleSingleSelection(grid);
               resolve();
            } else if (mode === vuiConstants.grid.selectionMode.MULTI) {
               handleMultipleSelection(grid).then(() => {
                  resolve();
               });
            }
         });
      }

      // Handle selection change in single selection mode.
      function handleSingleSelection(grid): void {
         const item: any = grid.select();
         const data: any = grid.dataItem(item);
         if (data) {
            const id: string = data.get(ID_FIELD_NAME);
            setSingleSelection(grid.dataSource, id, data);
         } else {
            clearSelection();
            resetSelectionRange();
         }
      }

      // Handle selection change in multiple selection mode.
      function handleMultipleSelection(grid): angular.IPromise<any> {
         return $q((resolve) => {
            const event: any = $window.event;
            const selected: any[] = grid.select();
            let asyncHandling: boolean = false;
            if (event &&
                  ((event.type === "click" && event.target.type === "checkbox")
                  || event.type === "mouseup"
                  || event.type === "mousedown"
                  || event.type === "contextmenu")) {
               if (event.type === "click" && event.target.type === "checkbox") {
                  const parent: any = angular.element(event.target.parentElement);
                  if (parent.attr('role') === "columnheader") {
                     // The 'select all' checkbox has been clicked.
                     if (event.target.checked === true) {
                        const total: number = grid.dataSource.total();
                        // Make sure the list is not empty so the selection indices are
                        // not invalidated.
                        if (total > 0) {
                           selectionStartIndex = 0;
                           selectionEndIndex = total - 1;
                           asyncHandling = true;
                           handleRangeSelection(grid).then(() => {
                              resolve();
                           });
                        }
                     } else if (event.target.checked === false) {
                        clearSelection();
                        resetSelectionRange();
                     }
                  } else {
                     saveSelectedItemsInView(grid);
                  }
               } else if (event.type === "mousedown" && selected.length === 0) {
                  // Click inside the grid area but not on an item => clear selection.
                  clearSelection();
                  resetSelectionRange();
               } else {
                  if (event.type === "mouseup") {
                     handleItemClick(grid, event);

                     //multi-selection with drag-and-drop
                     if (selected.length > 1 && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
                        selectionStartIndex = Math.min(selected[0].rowIndex, selected[selected.length - 1].rowIndex);
                        selectionEndIndex = Math.max(selected[0].rowIndex, selected[selected.length - 1].rowIndex);
                        asyncHandling = true;
                        handleRangeSelection(grid).then(() => {
                           resolve();
                        });
                     }
                  }
                  if (selected.length === 1) {
                     handleSingleSelectionInView(grid, event, selected[0]);
                  }
                  if (event.shiftKey) {
                     asyncHandling = true;
                     handleRangeSelection(grid).then(() => {
                        resolve();
                     });
                  }
               }
            } else {
               saveSelectedItemsInView(grid);
            }
            if (!asyncHandling) {
               resolve();
            }
         });
      }

      // Handle click events on grid items.
      function handleItemClick(grid: any, event: any): void {
         const data: any = getClickedItemData(grid, event);
         if (!data) {
            return;
         }
         const index: number = getRawDataIndex(grid.dataSource, data);
         if (index === -1) {
            return;
         }
         if (event.shiftKey) {
            // Shift-click always means that a selection range has been set.
            selectionEndIndex = index;
         } else {
            // The start of the selection range has changed.
            selectionStartIndex = index;
            const id: string = data.get(ID_FIELD_NAME);
            if ((event.ctrlKey || event.metaKey) && isItemSelected(id)) {
               // Control-click deselects a previously selected item.
               removeSelectedItem(id);
            } else {
               addSelectedItem(id, data);
            }
         }
      }

      // Find the clicked grid item if a click event occurs inside the grid.
      function getClickedItemData(grid: any, event: any): any {
         const item: any = angular.element(event.target).closest('tr');
         const data: any = grid.dataItem(item);
         if (!data || !data.get) {
            return null;
         }
         const id: string = data.get(ID_FIELD_NAME);
         if (!id) {
            return null;
         }
         return data;
      }

      // Handle item selection when there is a single selected item in the grid's
      // visible area.
      function handleSingleSelectionInView(grid: any, event: any, item: any): void {
         const source: any = grid.dataSource;
         const data: any = grid.dataItem(item);
         const id: string = data.get(ID_FIELD_NAME);
         if (event.type === "mouseup"
               && !(event.ctrlKey || event.metaKey || event.shiftKey)) {
            setSingleSelection(source, id, data);
         } else if (event.type === "contextmenu") {
            const id: string = grid.dataItem(item).get(ID_FIELD_NAME);
            if (!isItemSelected(id)) {
               setSingleSelection(source, id, data);
            }
         }
      }

      // Handle selection of a range of items in the grid.
      function handleRangeSelection(grid): angular.IPromise<any> {
         return $q((resolve) => {
            let asyncHandling: boolean = false;
            clearSelection();
            // Mark the visible items within the selection range.
            saveSelectedItemsInRange(grid);
            const rangeStart: number = Math.min(selectionStartIndex, selectionEndIndex);
            const rangeEnd: number = Math.max(selectionStartIndex, selectionEndIndex);
            // Handle the already rendered items in the grid.
            const items: any[] = grid.items();
            let indexError: boolean = false;
            for (let i: number = 0; i < items.length; ++i) {
               const item: any = items[i];
               const data: any = grid.dataItem(item);
               const index: number = getRawDataIndex(grid.dataSource, data);
               if (index === -1) {
                  indexError = true;
                  break;
               }
               // If an item is inside the selection range, we need to mark it.
               // If an already marked item is outside the selection range, we need to
               // unmark it.
               if (rangeStart <= index && index <= rangeEnd) {
                  if (!isItemMarked(item)) {
                     markItem(item);
                  }
               } else if (isItemMarked(item)) {
                  unmarkItem(item);
               }
            }
            if (indexError) {
               for (let i: nubmer = 0; i < items.length; ++i) {
                  unmarkItem(items[i]);
               }
               resolve();
               return;
            }
            const rangeSize: number = rangeEnd - rangeStart + 1;
            // If the number of saved selected items is less than the length of the
            // selection range, we need to request additional data from the backend.
            if (_.values(selectedItemDataById).length < rangeSize) {
               const visibleColumns: any[] = getVisibleColumns();
               const listViewSpec: any = {
                  constraintObjectId: dataParams.constraintObjectId,
                  queryFilterId: dataParams.queryFilterId,
                  filterParams: dataParams.filterParams,
                  dataModels: dataParams.dataModels,
                  // Currently we only need the item ID, but that may change in the
                  // future in case the contract of the actionService is modified.
                  requestedProperties: getPropertiesToFetch(visibleColumns),
                  sort: getSort(grid),
                  searchTerm: $scope.searchTerm,
                  searchableProperties: getSearchProperties(visibleColumns),
                  skip: rangeStart,
                  take: rangeSize,
                  listViewid: listViewId,
                  isLiveRefreshRequest: false
               };
               asyncHandling = true;
               kendo.ui.progress($element, true);
               $http.post(dataUrl, listViewSpec).then((response: any) => {
                  if (response && response.data) {
                     const result: any = response.data;
                     const items: any[] = angular.isArray(result.data)
                           ? result.data
                           : [];
                     _.each(items, (item: any) => {
                        selectedItemDataById[item.id] = item;
                     });
                  }
                  kendo.ui.progress($element, false);
                  resolve();
               });
            }
            if (!asyncHandling) {
               resolve();
            }
         });
      }

      // Save the loaded items within the selection range.
      function saveSelectedItemsInRange(grid: any): void {
         const rangeStart: number = Math.min(selectionStartIndex, selectionEndIndex);
         const rangeEnd: number = Math.max(selectionStartIndex, selectionEndIndex);
         forEachDataSourceRange(grid.dataSource, (range, data, index) => {
            if (rangeStart <= index && index <= rangeEnd) {
               const id: string = data.get(ID_FIELD_NAME);
               addSelectedItem(id, data);
            }
         });
      }

      // Save the selected ones from the currently rendered grid items.
      function saveSelectedItemsInView(grid: any): void {
         _.each(grid.items(), (item: any) => {
            const data: any = grid.dataItem(item);
            const id: string = data.get(ID_FIELD_NAME);
            if (item.className.indexOf("k-state-selected") >= 0) {
               addSelectedItem(id, data);
            } else if (isItemSelected(id)) {
               removeSelectedItem(id);
            }
         });
      }

      function setSingleSelection(dataSource: any, id: string, data: any): void {
         clearSelection();
         const index: number = getRawDataIndex(dataSource, data);
         if (index === -1) {
            return;
         }
         selectionStartIndex = selectionEndIndex = index;
         addSelectedItem(id, data);
      }

      function addSelectedItem(id: string, data: any): void {
         selectedItemDataById[id] = data;
      }

      function removeSelectedItem(id: string): void {
         delete selectedItemDataById[id];
      }

      function clearSelection(): void {
         selectedItemDataById = Object.create(null);
      }

      function isItemSelected(id: string): boolean {
         return !!selectedItemDataById[id];
      }

      // Mark an item as selected.
      function markItem(item: any): void {
         // Only change the appearance of the item. We want to avoid calling
         // grid.select() since it fires the 'CHANGE' event.
         $(item).addClass('k-state-selected');
      }

      // Mark an item as unselected.
      function unmarkItem(item: any): void {
         // Only change the appearance of the item. We want to avoid calling
         // grid.select() since it fires the 'CHANGE' event.
         $(item).removeClass('k-state-selected');
      }

      // check if an item is marked as selected.
      function isItemMarked(item: any): boolean {
         return item.className.indexOf('k-state-selected') >= 0;
      }

      // Reset the boundaries of the selection range.
      function resetSelectionRange(): void {
         selectionStartIndex = selectionEndIndex = 0;
      }

      /**
      * Clears the selection of items, which are no longer present in the data.
      *
      * If an item is removed from the data, it must not be contained in the selected items map.
      * This can happen, for example, when a VM is migrated.
      */
      function syncSelectedData(): void {
         const dataSource: any = getDataSource();
         if (!dataSource) {
            clearSelection();
            return;
         }
         _.each(_.keys(selectedItemDataById), (itemId: string) => {
            if (!dataSource.get(itemId)) {
               removeSelectedItem(itemId);
            }
         });
      }

      // Restore selected items on data operations.
      function restoreSelection(): void {
         if (!canSelectItems()) {
            return;
         }
         const grid: any = getGrid();
         if (!grid) {
            return;
         }
         const itemsToSelect: any[] = [];
         const itemId: string = getSelectedItemIdFromUrl();
         _.each(grid.items(), (item) => {
            const id: string = grid.dataItem(item).get(ID_FIELD_NAME);
            if (selectedItemDataById[id] || (itemId && itemId === id)) {
               itemsToSelect.push(item);
            }
         });
         isRestoringSelection = itemsToSelect.length > 0;
         grid.select(itemsToSelect);
      }

      function onColumnShowHide(event) {
         // persist column visibility/width/order state
         persistColumnsState.apply(null, arguments);

         if (!$scope.searchTerm && event.column.hidden) {
            // No need to refresh data when hiding column and no search term
            return;
         }

         const grid: any = event.sender;

         // see which properties we need to retrieve and refresh the
         // grid data
         refreshOnColumnShow(grid.columns);
         updateColumnWidths(grid);
      }

      function onDataBound(event: any): void {
         const grid: any = event.sender;
         addToolbarFilter();
         syncSelectedData();
         restoreSelection();
         updateColumnWidths(grid);
         persistSorting(grid);
         if ($scope.showExport && exportActionDef) {
            const itemsCount = grid.items().length;
            const hasItems = itemsCount > 0;
            exportActionDef.tooltip = getExportActionTooltip(hasItems);
            exportActionDef.disabled = !hasItems;
         }

         const currSelectedObjects = getSelectedObjects();
         if (!(_.isEqual(currSelectedObjects, $scope.datagridOptions.selectedItems))) {
            onSelectionChanged(currSelectedObjects, $scope.datagridOptions.selectedItems);
         }
      }

      function addToolbarFilter() {
         if ($scope.showFilter === 'true') {
            const grid: any = getGridElement();
            if (grid) {
               const toolbarElement: any = grid.find('.k-grid-toolbar');
               if (toolbarElement.length > 0) {
                  toolbarElement.append(filterElement);
               }
            }
         }
      }

      function persistSorting(grid: any) {
         const gridCurrentSort: object[] | undefined = grid.dataSource.sort();
         // Ignore operation if grid doesn't support sorting.
         if (!gridCurrentSort) {
            return;
         }

         listViewColumnService.getPersistedColumnsDef(listViewId).then(function (persistedData) {
            let persistedOrDefaultSortCol: string | undefined = sortOrderSpec.length > 0 ? sortOrderSpec[0].field : "";
            let persistedOrDefaultSortDir: string | undefined = sortOrderSpec.length > 0 ? sortOrderSpec[0].dir : "";
            if (persistedData && persistedData.sorting) {
               persistedOrDefaultSortCol = persistedData.sorting.column;
               persistedOrDefaultSortDir = persistedData.sorting.dir;
            }

            // Delete sorting from local storage in case grid is not sorted.
            if (gridCurrentSort.length === 0) {
               listViewColumnService.saveGridState(listViewId, persistedData, null);
               return;
            }

            // Persist in local storage only when there is an actual change to the grid sorting, compared to the
            // already persisted one. This is done because DataBound event is called on all actions, which are causing
            // data loading, not only sorting.
            if (gridCurrentSort[0].field && gridCurrentSort[0].dir &&
                  (persistedOrDefaultSortCol !== gridCurrentSort[0].field ||
                        persistedOrDefaultSortDir !== gridCurrentSort[0].dir)) {
               const sorting = {
                  column: unwrapField(gridCurrentSort[0].field),
                  dir: gridCurrentSort[0].dir
               };
               listViewColumnService.saveGridState(listViewId, persistedData, sorting);

            }
         });
      }

      /**
       * Update the columns' widths to match their actual display width.
       *
       * When the columns' widths don't add up to match the table width, a jump glitch occurs
       * on resizing the columns. This is because the columns get visually stretched to
       * fill the space which doesn't match the actual width.
       *
       * This function prevents the glitch by updating the columns' widths with the
       * stretched ones that kendo gives them.
       *
       * See http://www.telerik.com/forums/grid-column-resizing-issue-b5e8eeb796ed
       */
      function updateColumnWidths(grid: any) {
         listViewColumnService.getPersistedColumnsDef(listViewId).then(function (data) {
            // in case there are already preserved columns' widths we should not do anything
            if (data) {
               return;
            }

            const columns: any[] = grid.columns || [];
            const ths: any = angular.element(grid.element).find('> div > div > table tr:first-child th');
            const headerCols: any = angular.element(grid.element).find('> div.k-grid-header > div > table colgroup col');
            const contentCols: any = angular.element(grid.element).find('> div.k-grid-content > div > table colgroup col');
            const isMultiSelectMode: boolean = getSelectionMode(selectionMode) === vuiConstants.grid.selectionMode.MULTI
                  && showCheckboxesOnMultiSelection;
            let totalWidth: number = 0;
            let visibleColumns: number = 0;
            let visibleColumnsWithZeroWidth: number = 0;

            for (let col of columns) {
               // Ignore hidden columns
               if (col.hidden) {
                  continue;
               }

               ++visibleColumns;

               // Handles the px suffix (parseFloat('12.34px') == 12.34)
               let colWidth: number = parseFloat(col.width);
               if (colWidth === 0) {
                  visibleColumnsWithZeroWidth++;
               }

               totalWidth += colWidth;
            }

            // in case all columns have width set to 0
            if (visibleColumns === visibleColumnsWithZeroWidth) {
               return;
            }

            // Resize the column's internal width with the actual display width
            // that kendo calculated. This should happen only when columns' total width
            // exceeds the grid visible width.
            let lastColumnWidth: number = grid.size().width - totalWidth;
            if (lastColumnWidth > DATAGRID_DEFAULT_COLUMN_WIDTH + DATAGRID_VERTICAL_SCROLLER_WIDTH) {
               const firstColumnIndex = (isMultiSelectMode && visibleColumns >= 2) ? 1 : 0;

               columns.forEach((col: any, idx: number) => {
                  // Ignore hidden columns
                  if (col.hidden) {
                     return;
                  }

                  // we must resize only the first column
                  if (firstColumnIndex === idx && ths.length > 0 && ths[idx] !== undefined) {
                     // Resize only the first column's width
                     col.width = parseFloat(col.width) + lastColumnWidth
                           - DATAGRID_DEFAULT_COLUMN_WIDTH
                           - (DATAGRID_DEFAULT_COLUMN_WIDTH * visibleColumnsWithZeroWidth)
                           - DATAGRID_VERTICAL_SCROLLER_WIDTH;
                     angular.element(headerCols[idx]).width(col.width + 'px');
                     angular.element(contentCols[idx]).width(col.width + 'px');

                     // persist column visibility/width/order state
                     listViewColumnService.saveColumnDefs(listViewId, columns);
                  }
               });
            }
         });
      }

      /**
       * Creates column definitions for the vui-datagrid
       * @param columnDefinitions com.vmware.vise.mvc.lists.ColumnDataSourceInfo
       * List of column definitions, as defined in plugin.xml
       * @returns List of vui-datagrid column specs.
       */
      function createVuiColumnDefinitions(columnDefinitions: ColumnDataSourceInfo[], sort: ListSorting[] | undefined) {
         let colNum = 0;
         if (sort) {
            sort.map( function (listSorting) {
               sortOrderSpec.push({
                  field: wrapField(listSorting.column),
                  dir: listSorting.dir
               });
            });

         }
         return columnDefinitions.map(function (columnDataSourceInfo) {
            if (!sort && columnDataSourceInfo.sortedByDefault &&
                  columnDataSourceInfo.sortedByDefault !== 'false') {
               sortOrderSpec.push({
                  field: wrapField(columnDataSourceInfo.sortProperty),
                  dir: "asc"
               });
            }
            propertiesById[columnDataSourceInfo.uid] =
               columnDataSourceInfo.requestedProperties;
            resourceModelsById[columnDataSourceInfo.uid] =
               columnDataSourceInfo.resourceModels;

            const filterProperty = columnDataSourceInfo.filterProperty
                  || columnDataSourceInfo.sortProperty;
            if (columnDataSourceInfo.searchable && filterProperty) {
               // filterProperty may describe multiple comma-delimited object properties
               searchPropertiesById[columnDataSourceInfo.uid] =
                     filterProperty.indexOf(FILTER_PROPERTY_SEPARATOR) < 0 ?
                           [filterProperty] :
                           filterProperty.split(FILTER_PROPERTY_SEPARATOR).map(v => v.trim());
            }
            if (columnDataSourceInfo.exportProperty) {
               exportPropertyById[columnDataSourceInfo.uid] =
                  columnDataSourceInfo.exportProperty;
            }
            let field;
            if (columnDataSourceInfo.sortProperty) {
               field = columnDataSourceInfo.sortProperty;
            } else if (columnDataSourceInfo.exportProperty) {
               field = columnDataSourceInfo.exportProperty;
            } else {
               // kendo needs unique field names, otherwise column show/hide does not work
               // properly
               field = "vwm_column_" + colNum.toString();
               colNum++;
            }
            return {
               displayName: _.escape(columnDataSourceInfo.headerText),
               field: wrapField(field),
               width: columnDataSourceInfo.width + 'px',
               encoded: false,
               visible: columnDataSourceInfo.visible !== false,
               sortable: !!columnDataSourceInfo.sortProperty,
               searchable: false,
               template: getRenderer(columnDataSourceInfo),
               uid: columnDataSourceInfo.uid
            };
         });
      }

      function wrapField(fieldName) {
         // Workaround for http://www.telerik.com/forums/bug-with-field-name-with-dot-or-space
         // Kendo uses eval to get properties from the data object,
         // so names with a dot are evaluated as
         // data.summary.overallStatus instead of data['summary.overallStatus']

         return '[\'' + fieldName + '\']';
      }

      function unwrapField(fieldName: string): string {
         if (fieldName) {
            fieldName = fieldName.replace(/^\['(.*?)'\]$/, '$1');
         }

         return fieldName;
      }

      /**
       * Returns an array of properties that need to be requested to show the
       * specified columns.
       * @param visibleColumns array of strings, representing the text in the
       * headers of the visible grid columns.
       */
      function getPropertiesToFetch(visibleColumns) {
         const properties: any[] = [];
         visibleColumns.forEach(function (columnId) {
            let requestedProperties = propertiesById[columnId];
            if (requestedProperties) {
               requestedProperties.forEach(function(property) {
                  if (properties.indexOf(property) === -1) {
                     properties.push(property);
                  }
               });
            }
         });
         return properties;
      }

      /**
       * Returns an array of properties that need to be used when a search in the
       * list view is performed. These properties depend on the the list of currently
       * visible columns.
       * @param visibleColumns array of strings, representing the text in the
       * headers of the visible grid columns.
       */
      function getSearchProperties(visibleColumns: string[]): string[] {
         let properties: string[] = [];
         visibleColumns.forEach(function (columnId) {
            const searchProperties: string[] = searchPropertiesById[columnId];
            if (searchProperties && searchProperties.length > 0) {
               // Build a unique set of searchable properties.
               if (searchProperties.length === 1
                     && properties.indexOf(searchProperties[0]) < 0) {
                  properties.push(searchProperties[0]);
               } else {
                  properties = _.union(properties, searchProperties);
               }
            }
         });
         return properties;
      }

      function getVisibleColumns(): any[] {
         const grid: any = getGrid();
         return grid
               ? _.pluck(_.filter(grid.columns, (column: any) => !column.hidden), "uid")
               : [];
      }

      function getSort(grid: any): any[] {
         return _.map(grid.dataSource.sort(), (spec: any) => {
            return {
               dir: spec.dir,
               field: unwrapField(spec.field)
            };
         });
      }

      /**
       * Returns an array of resource models that need to be used to fetch
       * the requested properties
       * @param visibleColumns array of strings, representing the text in the
       * headers of the visible grid columns.
       * @param resourceModelsInScope
       */
      function getResourceModels(visibleColumns: string[],
                                 resourceModelsInScope: string[] | undefined): string[] {
         let allModels: string[] = [];
         let fieldsWithoutModel = false;
         visibleColumns.forEach(function (columnId) {
            let resourceModels = resourceModelsById[columnId];
            if (resourceModels) {
               resourceModels.forEach(function (resourceModel) {
                  if (allModels.indexOf(resourceModel) === -1) {
                     allModels.push(resourceModel);
                  }
               });
            } else {
               fieldsWithoutModel = true;
            }
         });

         // If there is at least one column without a model, assume object type
         if (fieldsWithoutModel && !_.contains(allModels, listViewType)) {
            if (listViewType) {
               allModels.push(listViewType);
            }
         }
         if (allModels.length === 0) {
            // No resource models from column definitions
            // see if they are passed in the scope
            if (resourceModelsInScope) {
               allModels = resourceModelsInScope;
            } else {
               // Cannot determine the model -> fallback to OBJECT_MODEL
               allModels.push(vcH5ConstantsService.OBJECT_MODEL);
            }
         }
         return allModels;
      }

      function refreshOnLiveUpdates(): void {
         isLiveRefreshRequest = true;
         refreshGrid(LoadingMaskState.Hidden);
         isLiveRefreshRequest = false;
      }

      function refreshGrid(loadingMaskState?: LoadingMaskState) {
         if (angular.isUndefined(loadingMaskState)) {
            loadingMaskState = LoadingMaskState.Visible;
         }
         if ($scope.searchTerm) {
            if ($scope.searchTerm !== lastSearchTerm) {
               // The grid is filtered => we need to clear the selection.
               clearSelection();
               resetSelectionRange();
            }

            dataParams.searchTerm = $scope.searchTerm;
            dataParams.searchableProperties = searchableProperties;
         } else {
            dataParams.searchTerm = undefined;
            dataParams.searchableProperties = undefined;
         }

         const dataSource: any = getDataSource();
         if (!dataSource) {
            return;
         }
         let progressHandlers: any = null;
         isRefreshInProgress = true;
         if (loadingMaskState === LoadingMaskState.Hidden
               && angular.isDefined(dataSource._events)) {
            // Watch out - the next piece of code relies on undocumented behavior
            // of the Kendo grid data source! What we are trying to do is
            // temporarily remove the data source's progress handler so the loading
            // overlay doesn't show up when the list is refreshed on a live update.
            try {
               progressHandlers = dataSource._events["progress"];
               delete dataSource._events["progress"];
            } catch (error) {
               // Possibly trying to delete an unmodifiable own
               // property; can't really do anything about that.
               progressHandlers = null;
            }
         }
         dataSource.read().then(() => {
            resizeGrid(true);

            if ($scope.searchTerm !== null && $scope.searchTerm !== undefined) {
               if ($scope.searchTerm !== lastSearchTerm) {
                  const filterInput = getFilterInput();
                  if (filterInput !== null) {
                     filterInput.focus();
                  }
               }
            }
            // When setting back data source's progress handler a new
            // digest cycle is triggered. This should be done inside
            // angular zone in order to avoid error: digest already in
            // progress. This error was thrown consistently on secondary
            // pipeline on Firefox, Windows 10 environment.
            vxZoneService.runInsideAngular(() => {
               isRefreshInProgress = false;
               if (progressHandlers !== null) {
                  dataSource._events["progress"] = progressHandlers;
               }

               lastSearchTerm = $scope.searchTerm;
            });
         });
      }

      function getLoadedEntities(dataSource: any): string[] {
         const pageData = dataSource.data() || [];
         const result = _.pluck(pageData, ID_FIELD_NAME);
         forEachDataSourceRange(dataSource, (range: any, data: any) => {
            const id: string = data.get(ID_FIELD_NAME);
            if (id && _.indexOf(result, id) === -1) {
               result.push(id);
            }
         });
         return result;
      }

      function getRawDataIndex(dataSource: any, data: any): number {
         // This relies on certain implementation details of the Kendo
         // grid (i.e. dataSource._ranges), so always check the return
         // value of this function.
         if (!dataSource) {
            return -1;
         }
         const id: string = data.get(ID_FIELD_NAME);
         const ranges: any[] = flatDataSourceRanges(dataSource);
         for (let i = 0; i < ranges.length; ++i) {
            const element: any = ranges[i];
            const itemId: string = element.data.get(ID_FIELD_NAME);
            if (itemId === id) {
               return element.index;
            }
         }
         return -1;
      }

      function forEachDataSourceRange(dataSource: any, action: Function): void {
         _.each(flatDataSourceRanges(dataSource), (element: any) => {
            action(element.range, element.data, element.index);
         });
      }

      function flatDataSourceRanges(dataSource: any): any[] {
         const result: any = [];
         const ranges: any = dataSource._ranges;
         if (!angular.isArray(ranges)) {
            return result;
         }
         for (let i = 0; i < ranges.length; ++i) {
            const range: any = ranges[i];
            const rangeData: any = range.data;
            if (rangeData && rangeData.length) {
               for (let j = 0; j < rangeData.length; ++j) {
                  const item: any = rangeData[j];
                  result.push({
                     range: range,
                     data: item,
                     index: range.start + j
                  });
               }
            }
         }
         return result;
      }

      function getGridElement(): any {
         const element: any = $element.find('.k-grid-content');
         return element && element.parent();
      }

      function getGrid(): any {
         const gridElement: any = getGridElement();
         return gridElement && gridElement.data('kendoGrid');
      }

      function getDataSource(): any {
         const grid: any = getGrid();
         return grid && grid.dataSource;
      }

      function getSelectedObjects(): any[] {
         return _.values(selectedItemDataById);
      }

      function isChangeOnContextObject(changedObjectId, operationType): boolean {
         return changedObjectId === objectId && operationType === 'CHANGE';
      }

      function getFilterInput(): any {
         return $element.find('.k-header input');
      }

      function canSelectItems(): boolean {
         const mode: string = getSelectionMode(selectionMode);
         return mode === vuiConstants.grid.selectionMode.SINGLE
               || mode === vuiConstants.grid.selectionMode.MULTI;
      }

      function getExportActionDef(): any {
         return {
            label: i18nService.getString('Common', 'exportTitle'),
            tooltip: getExportActionTooltip(true),
            icon: 'vsphere-icon-export-line_16x16',
            action: () => {
               const selectedItems = $scope.datagridOptions.selectedItems;
               const exportColumnsById = getExportableColumns();
               exportListService.open(
                     lastListViewSpec, selectedItems, exportColumnsById);
            },
            disabled: false
         };
      }

      function getExportActionTooltip(hasItems: boolean) {
         return hasItems
               ? i18nService.getString('Common', 'exportToolTip')
               : i18nService.getString('Common', 'exportNaToolTip');
      }

      function getExportableColumns(): { [uid: string]: ExportableColumn } {
         const grid: any = getGrid();

         const exportableColumnsById = {};
         grid.columns.forEach((kendoColumn: any) => {
            if (!exportPropertyById[kendoColumn.uid]) {
               return;
            }

            const exportableColumn: ExportableColumn = {
               title: _.unescape(kendoColumn.title),
               selected: !kendoColumn.hidden,
               propertyName: exportPropertyById[kendoColumn.uid],
               uid: kendoColumn.uid
            };

            exportableColumnsById[kendoColumn.uid] = exportableColumn;
         });

         return exportableColumnsById;
      }

      function addSelectionContextToUrl(selectedObjects: any[]) {
         if ($scope.usePersistentSelectionContext === "true") {
            const search: any = $location.search();
            const prevItemId = search[h5.listViewSelectedItemIdProperty] || null;
            const nextItemId = selectedObjects.length === 1 ? selectedObjects[0].id : null;
            if (nextItemId !== prevItemId) {
               $location.search(h5.listViewSelectedItemIdProperty, nextItemId).replace();
            }
         }
      }

      function getSelectedItemIdFromUrl() {
         return $scope.usePersistentSelectionContext === "true"
            ? $location.search()[h5.listViewSelectedItemIdProperty]
            : null;
      }
   }
})();
