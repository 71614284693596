/**
 *  Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential
 */
module platform {
   /**
    * Interface for telemetryTime buffer item
    */
   interface BufferItem {
      start: number;
      duration: number;
   }
   import IPromise = angular.IPromise;
   import ITimeoutService = angular.ITimeoutService;

   declare var h5: any;

   /**
    * Interface for TimeTracker
    */
   export interface TimeTracker {
      startTimeTracking(): void;
      stopTimeTracking(): void;
   }

   /**
    * Noop class implementing TimeTracker
    */
   export class NoOpTimeTracker implements TimeTracker {
      startTimeTracking(): void {
      };

      stopTimeTracking(): void {
      };
   }
   /**
    * Class handling measuring and buffering time and sending the buffered information after certain time to VAC
    */
   export class TelemetryTimeTracker implements TimeTracker {
      private MIN_NUMBER_OF_TIMES_TO_BUFFER = 3;
      private startTime: number = 0;
      private currentBufferItem: BufferItem;
      private pendingTimeout: boolean = false;
      private buffer: BufferItem[] = [];
      static $inject = [
         '$timeout',
         'telemetryService',
         'configurationService',
         'vxZoneService'
      ];

      constructor(private $timeout: ng.ITimeoutService, private configurationService: any, private telemetryService: any,
                  private telemetryParameterName: string, private bufferTimeProp: string, private vxZoneService: any) {
      };

      /**
       * Start tracking the time
       */
      startTimeTracking(): void {
         this.currentBufferItem = <BufferItem>{};
         this.currentBufferItem.start = Date.now();
      }

      /**
       * Stop time tracking
       */
      stopTimeTracking(): void {
         if (this.currentBufferItem) {
            this.currentBufferItem.duration = Date.now() - this.currentBufferItem.start;
            this.addItem(this.currentBufferItem);
         }
      }

      /**
       * Adds item to the buffer
       *
       * @param item
       */
      private addItem(item: BufferItem): void {
         if (h5.isTelemetryEnabled && this.telemetryParameterName) {
            this.buffer.push(item);
            if (!this.startTime) {
               this.startTime = item.start;
            }
            if (this.buffer.length >= this.MIN_NUMBER_OF_TIMES_TO_BUFFER) {
               this.sendTelemetry();
            }
         }
      }

      /**
       * Buffers the measured time. After the time set
       * elapses, send to the VAC only the median of the buffered time.
       */
      private sendTelemetry() {
         this.configurationService.getProperty(this.bufferTimeProp).then((bufferTime) => {
            if (this.pendingTimeout) {
               return;
            }
            let elapsed = Date.now() - this.startTime;

            this.pendingTimeout = true;
            let wait = Math.max(0, bufferTime - elapsed);
            this.vxZoneService.runOutsideAngular((): void => {
               this.$timeout(() => {
                  let median = this.findMedian();
                  this.telemetryService.trackEvent(this.telemetryParameterName, median);
                  this.buffer = [];
                  this.startTime = 0;
                  this.pendingTimeout = false;
               }, wait);
            });
         });
      }

      /**
       * Finds the median of the buffered time
       *
       * @returns {number} - the median of the buffered times
       */
      private findMedian() {
         let medianIndex: number = Math.floor(this.buffer.length / 2);
         let median: number = this.buffer.sort((item1: BufferItem, item2: BufferItem) => {
            return item1.duration - item2.duration;
         })[medianIndex].duration;
         if (this.buffer.length % 2 !== 0) {
            median = (median + this.buffer[medianIndex - 1].duration) / 2;
         }
         return median;
      }
   }

   /**
    * Service for getting instances of {@link TelemetryTimeTracker}
    */
   export class TelemetryTimeTrackerFactory {
      constructor(private $timeout: ng.ITimeoutService, private telemetryService,
                  private configurationService, private vxZoneService) {
      };

      /**
       * Return new instance of {@link TelemetryTimeTracker}
       * @param telemetryParameterName - the name of the parameter for the tracked time, which will be stored in
       *                                  VAC tables.
       * @param bufferTimeProp - Name of the property which value marks the amount of time that the collected times
       *                      from the tracker will be buffered before sending to VAC
       * @returns {platform.TelemetryTimeTracker}
       */
      createTelemetryTracker(telemetryParameterName: string, bufferTimeProp: string): TimeTracker {
         if (!h5.isTelemetryEnabled) {
            return new NoOpTimeTracker();
         }
         return new TelemetryTimeTracker(this.$timeout, this.configurationService, this.telemetryService,
            telemetryParameterName, bufferTimeProp, this.vxZoneService);
      }
   }

   angular.module('com.vmware.platform.ui')
      .service('telemetryTimeTrackerFactory', TelemetryTimeTrackerFactory);
}