/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for MainTemplate.
 */

(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .controller('MainTemplateController', MainTemplateController);

   MainTemplateController.$inject = [
      '$http',
      '$timeout',
      '$rootScope',
      '$scope',
      '$window',
      'appPingerService',
      'configurationService',
      'dataService',
      'feedbackService',
      'htmlBridgeService',
      'keyboardShortcutService',
      'i18nService',
      'navigation',
      'navigationConstants',
      'notificationService',
      'globalNotificationService',
      'themeService',
      'periodicRefreshService',
      'localStorageService',
      'sessionTimeoutService',
      'vFeedDialogService',
      'vxZoneService',
      'websocketMessagingService',
      'sandboxBridgeService',
      'telemetryService',
      'pluginNotificationService',
      'vcErrorsNotificationService',
      'vcExpirationNotificationService'
   ];

   function MainTemplateController(
      $http,
      $timeout,
      $rootScope,
      $scope,
      $window,
      appPingerService,
      configurationService,
      dataService,
      feedbackService,
      htmlBridgeService,
      keyboardShortcutService,
      i18nService,
      navigation,
      navigationConstants,
      notificationService,
      globalNotificationService,
      themeService,
      periodicRefreshService,
      localStorageService,
      sessionTimeoutService,
      vFeedDialogService,
      vxZoneService,
      websocketMessagingService,
      sandboxBridgeService,
      telemetryService,
      pluginNotificationService,
      vcErrorsNotificationService,
      vcExpirationNotificationService) {

      var spinner = $("#loading-container");
      if (spinner.length) {
         spinner[0].hidden = true;
      }

      var PANEL_STATES = 'panelStates';
      var BOTTOM_PANEL_SIZE_COLLAPSED = 33;
      var BOTTOM_PANEL_SIZE_MIN = 220;
      var BOTTOM_PANEL_SIZE_MAX = 350;
      var LEFT_PANEL_SIZE_MIN = 225;
      var OBJECT_DETAILS_LIVE_UPDATES_FLAG = 'live.updates.objectstate.enabled';
      var NAV_TREE_LIVE_UPDATES_FLAG = 'live.updates.navtree.enabled';
      var LIST_VIEW_LIVE_UPDATES_FLAG = 'live.updates.lists.enabled';
      var TAB_KEY_CODE = 9;
      var HIDE_OBJECT_NAVIGATOR_CLASS_NAME = "hide-object-navigator";
      var LEFT_PANEL_DIRECTIVE_TAG_NAME = "left-panel";

      // Debug function to dump pending http requests
      $window.h5.__getPendingHttpRequests = function getPendingHttpRequests() {
         var pendingRequests = _.clone($http.pendingRequests);
         var jqueryRequests = _.clone($window.pendingJqueryRequests);
         var xhrRequests = _.clone($window.pendingXhrs);
         var i, key, obj;
         for (i = 0; i < jqueryRequests.length; i++) {
            key = "jquery_" + i;
            obj = {};
            obj[key] = jqueryRequests[i];
            pendingRequests.push(obj);
         }

         var xhrKeys = _.keys(xhrRequests);
         for (i = 0; i < xhrKeys.length; i++) {
            key = "xhr:" + xhrKeys[i];
            obj = {};
            obj[key] = xhrRequests[xhrKeys[i]];
            pendingRequests.push(obj);
         }

         return pendingRequests;
      };

      // This HACK is to arrange the event listeners of the keydown event in the right
      // order for modal dialogs - the most recently attached listener will be called
      // first. Since we use vui and clarity at the same time this is a generic fix
      // for both.
      (function($) {
         // Do not override jQuery in tests since it can lead to flakiness.
         if ($window.jasmine) {
            return;
         }

         var originalOn = $.fn.on;

         function getBodyKeyDownListeners() {
            // $._data is a jQuery internal and might not exist in latest jQuery versions
            // so this hack should be removed or revisited when moving to a newer jQuery.
            // Hopefully until then all teams will be using clarity where the order of
            // listeners can be better manipulated through clarityModalService.
            var events = $._data(document, 'events');
            return (events || {}).keydown || [];
         }

         $.fn.on = function() {
            if (this[0] !== document) {
               return originalOn.apply(this, arguments);
            }

            var listenersBeforeLen = getBodyKeyDownListeners().length;
            var ret = originalOn.apply(this, arguments);
            var listenersAfter = getBodyKeyDownListeners();

            // Cannot assume that the newly added listener count is always 1, since
            // jQuery provides event namespaces and you can attach a listener to the same
            // event with multiple namespaces for example:
            //    on('keydown.namespace1 keydown.namespace2', handler);
            // In this example jQuery will attach the listener as 2 new listeners.
            var count = listenersAfter.length - listenersBeforeLen;
            if (count !== 0 && listenersAfter.length > 1) {
               // Remove the newly added listeners, reverse their order and put them in
               // the beginning of the listeners array.
               listenersAfter.unshift.apply(
                  listenersAfter,
                  listenersAfter.splice(-count).reverse());
            }

            return ret;
         };
      }($));

      navigation.init();

      // Adds 'user-tabbing' class to the body of the document when the user
      // press "Tab" key. The 'user-tabbing' class is use in some css selectors
      // which define component focus styles.
      vxZoneService.runOutsideAngular(function() {
         $(document).on('keydown', function(e){
            //If Tab key is pressed add 'user-tabbing'
            if (e.keyCode === TAB_KEY_CODE) {
               $(document.body).addClass('user-tabbing');
            }
         });
      });

      // Removes 'user-tabbing' class when the user press mouse key.
      vxZoneService.runOutsideAngular(function() {
         $(document).on('mousedown', function(e){
            $(document.body).removeClass('user-tabbing');
         });
      });

      // Splitter logic
      $scope.loader = $scope.loader || {};

      $scope.onHeaderLoaded = function () {
         $scope.loader.headerLoaded = true;
      };

	  $scope.skipToMainContent = function () {
         var centralPaneEl = $('#center-pane');
         if (centralPaneEl) {
            centralPaneEl.attr('tabIndex', -1);
            centralPaneEl.focus();
         }

      };
      $scope.$watch('loader',function(newVal, oldVal) {
         var loader = $scope.loader;
         var splitterElement, splitter, bottomPane,
               horizontalSplitterElement, horizontalSplitter;
         var panelStates;

         /* Adding the Kendo splitter to main template panels once all the inner
            views for panels are loaded
         */
         if (loader.naviLoaded && loader.bottomPanelViewLoaded && loader.headerLoaded) {

            navigation.doInitialRouting();

            localStorageService.getUserData(PANEL_STATES).then(function (data) {

               panelStates = data || {
                  isBottomPaneCollapsed: false,
                  bottomPaneHeight: BOTTOM_PANEL_SIZE_MIN,
                  leftPaneWidth: LEFT_PANEL_SIZE_MIN
               };

               vxZoneService.runOutsideAngular(function () {
                  splitterElement = angular.element('#vertical-panel').kendoSplitter({
                     orientation: 'vertical',
                     panes: [
                        {collapsible: false},
                        {
                           collapsed: panelStates.isBottomPaneCollapsed,
                           collapsible: true,
                           size: ( panelStates.bottomPaneHeight || BOTTOM_PANEL_SIZE_MIN ) + 'px',
                           max: BOTTOM_PANEL_SIZE_MAX + 'px',
                           min: BOTTOM_PANEL_SIZE_MIN + 'px',
                           collapsedSize: BOTTOM_PANEL_SIZE_COLLAPSED + 'px'
                        }
                     ]
                  });

                  splitter = splitterElement.data("kendoSplitter");
                  splitter.bind('resize', _.debounce(function (e) {
                     var height = $('#bottom-panel-pane').height();
                     if (height < BOTTOM_PANEL_SIZE_MIN) {
                        return;
                     }

                     panelStates.bottomPaneHeight = height;
                     localStorageService.setUserData(PANEL_STATES, panelStates);
                     return true;
                  }, 5000));
                  bottomPane = $("#bottom-panel-pane");

                  $('clr-tabs ul.nav').click(function (e) {
                     if (e.target.isEqualNode(e.delegateTarget)) {
                        // click on
                        // clr-tabs ul.nav
                        setCollapsedBottomPanel( bottomPane.height() > BOTTOM_PANEL_SIZE_COLLAPSED );
                     } else {
                        // click on
                        // clr-tabs ul.nav clr-tab-link
                        setCollapsedBottomPanel(false);
                     }
                  });

                  $("#toggle-bottom-panel").click(function (e) {
                     setCollapsedBottomPanel( bottomPane.height() > BOTTOM_PANEL_SIZE_COLLAPSED );
                  });

                  $('clr-tab-link .nav-item').on('click',function(){
                     var activeContent =  $('clr-tab-content.active');
                     var currentContent = $('#' + this.parentNode.id.replace('clrTabLink','clrTabContent'));
                     if (activeContent === currentContent) {
                        return;
                     }
                     if (activeContent) {
                        activeContent.removeClass('active');
                     }
                     currentContent.addClass('active');
                  });

               });

               vxZoneService.runOutsideAngular(function () {
                  var horizontalPanel = angular.element('#horizontal-panel');
                  horizontalSplitterElement = horizontalPanel.kendoSplitter({
                     panes: [
                        {
                           collapsible: false,
                           size: (panelStates.leftPaneWidth || LEFT_PANEL_SIZE_MIN) + 'px',
                           max: parseInt(angular.element('#horizontal-panel').width() / 2) + 'px',
                           min: LEFT_PANEL_SIZE_MIN + 'px'
                        },
                        { collapsible: false }
                     ]
                  });
               });

               $rootScope.$on('resizePanel', function () {
                  $timeout(function () {
                     if (angular.element("#horizontal-panel").data("kendoSplitter")) {
                        angular.element("#horizontal-panel").data("kendoSplitter").resize(true);
                     }

                     if (angular.element("#vertical-panel").data("kendoSplitter")) {
                        angular.element("#vertical-panel").data("kendoSplitter").resize(true);
                     }
                  }, 0);
               });

               horizontalSplitter = horizontalSplitterElement.data('kendoSplitter');
               horizontalSplitter.bind('resize', _.debounce(function (e) {
                  var width = angular.element('#horizontal-panel left-panel').width();
                  if (width < LEFT_PANEL_SIZE_MIN) {
                     return;
                  }
                  panelStates.leftPaneWidth = width;
                  localStorageService.setUserData(PANEL_STATES, panelStates);
                  return true;
               }, 5000));

               $scope.splitterReady = true;
            });

            $scope.$on('vxRouteChangeSuccess', function(evt, tree, route, previousRoute) {
               updateObjectNavigatorVisibilityState();
            });
         }

         function updateObjectNavigatorVisibilityState() {
            if (!horizontalSplitter) {
               return;
            }

            var tree = navigation.getTree();
            if (!tree) {
               return;
            }

            var node = tree.getNodeAtLevel(0);
            if (!node) {
               return;
            }

            var navigationVisible = !horizontalSplitter.element
                  .hasClass(HIDE_OBJECT_NAVIGATOR_CLASS_NAME);
            // Object navigator is already in the correct state so return
            // this prevents unnecessary recalculations to the layout
            if (node.navigationVisible === navigationVisible) {
               return;
            }

            if (node.navigationVisible === false) {
               // Hide Object Navigator only when navigationVisible is explicitly set to false.
               horizontalSplitter.options.panes[0].collapsible = true;
               horizontalSplitter.element.toggleClass(HIDE_OBJECT_NAVIGATOR_CLASS_NAME, true);
               horizontalSplitter.collapse(LEFT_PANEL_DIRECTIVE_TAG_NAME);
            } else {
               horizontalSplitter.element.toggleClass(HIDE_OBJECT_NAVIGATOR_CLASS_NAME, false);
               horizontalSplitter.expand(LEFT_PANEL_DIRECTIVE_TAG_NAME);
               horizontalSplitter.options.panes[0].collapsible = false;
            }
         }

         function setCollapsedBottomPanel(shouldCollapseBottomPanel) {
            if (shouldCollapseBottomPanel) {
               // collapse
               splitter.collapse(bottomPane);
               bottomPane.addClass("collapsed");

            } else {
               // expand
               splitter.expand(bottomPane);
               bottomPane.removeClass("collapsed");
            }
            panelStates.isBottomPaneCollapsed = shouldCollapseBottomPanel;
            localStorageService.setUserData(PANEL_STATES, panelStates);
         }
      }, true);

      $scope.vFeedDialogService = vFeedDialogService;

      $rootScope.languageLoaded = true;
      //Re-opened web sockets event listener
      $rootScope.$on('reOpenWebSocketsEvent', reInitializeWebsockets);
      // Notification event listener
      $rootScope.$on('notificationEvent',onNotify);
      function onNotify(event, notificationOptions) {
         notificationService.notify(notificationOptions);
      }

      $scope.showLastError = function () {
         notificationService.showLastError();
      };

      $scope.$watch(
         function () {
            return notificationService.isWarningActive();
         },
         function () {
            $scope.showWarningIcon = notificationService.isWarningActive();
         }
      );

      angular.extend($rootScope, {
         rootExtId: h5.ext
      });

      function reInitializeWebsockets() {
         initializeWebsockets(true);
      }

      function initializeWebsockets(reOpenChangeLogs) {
         //TODO: ajaffer: remove initialize method after all Refresh use-cases are covered by
         //LiveUpdates
         websocketMessagingService.initialize('wss://' + $window.location.host +
            '/ui/messaging', $rootScope);
         configurationService.getConfiguration().then(function (configPayload) {
            var isEnabled = function (prop) {
               return (prop && prop.toUpperCase() === "TRUE");
            };
            var configParams = configPayload.configParams;
            var objRefreshEnabled =
               isEnabled(configParams[OBJECT_DETAILS_LIVE_UPDATES_FLAG]);
            var navTreeRefreshEnabled =
               isEnabled(configParams[NAV_TREE_LIVE_UPDATES_FLAG]);
            var listViewRefreshEnabled =
               isEnabled(configParams[LIST_VIEW_LIVE_UPDATES_FLAG]);
            if (objRefreshEnabled || navTreeRefreshEnabled || listViewRefreshEnabled) {
               websocketMessagingService.initializeLiveUpdates()
                  .then(function (subscribe) {
                     if (objRefreshEnabled) {
                        subscribe('/topic/object-details');
                     }
                     if (navTreeRefreshEnabled || listViewRefreshEnabled) {
                        subscribe('/topic/navTree');
                     }
                     subscribe('/topic/data-notifications');

                     if (reOpenChangeLogs) {
                        // When we reinitialize the websocket connects we have to reopen all
                        // previously opened change logs.
                        websocketMessagingService.reOpenChangeLogs();
                     }
                  });
            }
         });
      }

      function initializeShortcutListener(){
         //Navigation shortcuts
         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+home", navigationConstants.HOME_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+1", navigationConstants.SHORTCUTS_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+2", navigationConstants.HOSTS_AND_CLUSTERS_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+3", navigationConstants.VMS_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+4", navigationConstants.STORAGE_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+5", navigationConstants.NETWORKING_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+6", navigationConstants.DATASTORES_EXTENSION_ID);

         keyboardShortcutService.bindNavigationShortcut("ctrl+alt+7", navigationConstants.GLOBAL_INVENTORY_LISTS_EXTENSION_ID);

         //Quick search shortcut - sets focus on search bar
         keyboardShortcutService.bindFunction("ctrl+alt+s", function () {
            angular.element("#search-term-ref").focus();
         });
         //Help shortcut - opens vSphere docs in new tab
         keyboardShortcutService.bindAction("ctrl+alt+h", "vsphere.core.actions.openHelp");
      }

      //Banner will only show once for the user based on their local storage
      var dark = themeService.getKeys().dark;
      var darkThemePlugin = themeService.getKeys().darkThemePlugin;
      themeService.subscribeToThemeChanged(function(newTheme) {
         if (newTheme === dark && !localStorageService.getData(darkThemePlugin)) {
            globalNotificationService.notify({
               content: i18nService.getString('CommonUi', 'NoticeBannerView.darkTheme'),
               type: "info"
            });
            localStorageService.setData(darkThemePlugin, true);
         }
      });

      performanceService.onAngularLoad($rootScope);

      //Init (subscribe for notifications) before to open web sockets.
      pluginNotificationService.init();
      //Init (subscribe for notifications) before to open web sockets.
      vcExpirationNotificationService.init();

      initializeWebsockets();

      WEB_PLATFORM.onAngularLoad($rootScope, dataService, htmlBridgeService, $window);

      feedbackService.init();

      htmlBridgeService.init();

      sandboxBridgeService.init();

      periodicRefreshService.init();

      sessionTimeoutService.init();

      // Start the application pinger
      appPingerService.start();

      telemetryService.init();

      vcErrorsNotificationService.notify();

      initializeShortcutListener();
   }
}());