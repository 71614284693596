/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * User Session Service Ex.
 */
angular.module('com.vmware.platform.ui').factory('userSessionExService', ['$http', '$q', 'logService',
function($http, $q, logService) {
   'use strict';
   var logger = logService('userSessionExService');

   return {
      /**
       * Makes a call to acquire a session-specific ticket string which can be used to clone the current session.
       *
       * @param {string} serverGuid
       *    Guid of the server for which cloned ticket is being requested
       *
       * @returns {promise}
       *    Promise to return the cloned ticket for the right server
       */
      acquireCloneTicket: function(serverGuid) {
         return $http({method: 'GET', url: 'usersessionex/acquireCloneTicket/' + serverGuid}).then(function(resp){
            if(!resp.data){
               logger.error('Null response for clone ticket');
            }
            return resp.data;
         });
      }
   };
}]);
