/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Maintains a store of all recent tasks and handles live updates.
 *
 * NOTE! If you are going to change the factory name, please make sure that you update the 'recent-tasks-state'
 * renderer (currently in ColumnRenderersConfig.js) or look for .injector().get('recentTasksStoreService').
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
         .factory('recentTasksStoreService', recentTasksStoreService);

   recentTasksStoreService.$inject = ['$rootScope', 'taskFormatter',
         'logService', 'userSessionService', '$q', 'taskConstants',
         'clarityModalService', 'i18nService', 'mutationService',
         'websocketMessagingService', 'timeFormatterService',
         '$timeout', 'notificationService'];

   function recentTasksStoreService($rootScope, taskFormatter,
            logService, userSessionService, $q, taskConstants,
            clarityModalService, i18nService, mutationService,
            websocketMessagingService, timeFormatterService,
            $timeout, notificationService) {

      var log = logService('recentTasksStoreService');
      var onUpdateReceivedList = [];

      /* Bookkeeping for Tasks mapped by their keys and the server guid */
      var allTasksByKey = {};

      /**
       * Flag indicating whether the timeout used to buffer live updates is started.
       *
       * @type {boolean}
       */
      var updatesTimeoutStarted = false;

      /**
       * Flag indicating whether there was a pending update during the
       * timeout to buffer the live updates.
       * @type {boolean}
       */
      var pendingUpdate = false;

      /**
       * The timeout for buffering updates in milliseconds.
       * @type {number}
       */
      const UPDATE_TIMEOUT_MILLIS = 1000;

      var dateTimeFormat;

      /**
       * Metadata key to indicate whether the partial update arrived is from
       * initial load of the tasks or not. Error notification for failed tasks
       * during initial load shouldn't be displayed.
       * @type {string}
       */
      const META_ALL_TASKS = "allTasks";

      timeFormatterService.getUserTimeFormatPreference().then(function (format) {
         dateTimeFormat = format;
         userSessionService.getUserSession().then(function(userSession) {
            if (userSession.userName) {
               websocketMessagingService.initializeLiveUpdates().then(function(subscribe) {
                  // start listening
                  $rootScope.$on(taskConstants.events.RECENT_TASKS, onRecentTasks);
                  subscribe('/topic/recent-tasks');

                  // resubscribe and invalidate current store contents
                  // on manual refresh.
                  $rootScope.$on('dataRefreshInvocationEvent', function() {
                     /**
                      * Empty store contents and resubscribe to the recent-tasks topic.
                      * After the subscription, the server will respond with a partial
                      * update, containing the initial store data (AbsoluteDataValues).
                      */
                     allTasksByKey = {};
                     websocketMessagingService.initializeLiveUpdates().then(function(subscribe) {
                        subscribe('/topic/recent-tasks');
                     });
                  });
               });

               $rootScope.$on(taskConstants.events.FAILED, function(event, clientTaskInfo) {
                  notifyTaskError(clientTaskInfo);
               });
            } else {
               log.error('No userName set in userSession');
            }
         }, function() {
            log.error('Error getting username from userSessionService');
         });

      });

      /**
       * Called when updates/deletes to recent-tasks arrive.
       *
       * @param event the triggering event
       * @param partialUpdate the PartialUpdate object
       */
      function onRecentTasks(event, partialUpdate) {
         var taskUpdatePromises = [];
         var taskAddPromises = [];
         var allPromises = [];
         if (partialUpdate.updates) {
            _.each(partialUpdate.updates, function(partialUpdateItem) {
               if (partialUpdateItem.isDelta) {
                  var promise = updateTask(partialUpdateItem, dateTimeFormat);
                  if (promise) {
                     taskUpdatePromises.push(promise);
                     allPromises.push(promise);
                  }
               } else {
                  var newTask = addNewTask(partialUpdateItem, dateTimeFormat);
                  taskAddPromises.push(newTask);
                  allPromises.push(newTask);
               }
            });
         }
         var deletedTaskKeys = _.map(partialUpdate.deleted, processTaskDelete);

         // Notify observers
         if (taskUpdatePromises.length !== 0) {
            $q.all(taskUpdatePromises).then(function(clientTaskInfoList) {

               $rootScope.$broadcast(taskConstants.events.UPDATED, clientTaskInfoList.map(function(o) {
                  return o.taskRef;
               }));

               // grab only successfully completed tasks
               var successTaskInfoKeysList = clientTaskInfoList
                  .filter(function(o) {
                     return o.state === taskConstants.status.SUCCESS;
                  });

               if (successTaskInfoKeysList.length) {
                  $rootScope.$broadcast(taskConstants.events.COMPLETED, successTaskInfoKeysList);
               }
            });
         }

         if (taskAddPromises.length !== 0) {
            $q.all(taskAddPromises).then(function(clientTaskInfoList) {
               $rootScope.$broadcast(taskConstants.events.ADDED, clientTaskInfoList.map(function(o) {
                  return o.key;
               }));
            });
         }
         $rootScope.$broadcast(taskConstants.events.DELETED, deletedTaskKeys);

         // wait for all promises to resolve, otherwise we could get empty fields,
         // i.e. no icon
         var timeoutFunction = function() {
            updatesTimeoutStarted = false;
            if (pendingUpdate) {
               pendingUpdate = false;
               onUpdateReceivedList.forEach(function (onUpdateReceived) {
                  onUpdateReceived(partialUpdate);
               });
            }
         };
         if (!updatesTimeoutStarted) {
            $q.all(allPromises).then(function () {
               onUpdateReceivedList.forEach(function (onUpdateReceived) {
                  onUpdateReceived(partialUpdate);
               });
            });
            updatesTimeoutStarted = true;
            $timeout(timeoutFunction, UPDATE_TIMEOUT_MILLIS);
         } else {
            $q.all(allPromises).then(function () {
               pendingUpdate = true;
               if (!updatesTimeoutStarted) {
                  $timeout(timeoutFunction, UPDATE_TIMEOUT_MILLIS);
               }
            });
         }
      }

      /**
       * Opens a confirmation dialog asking the user if he really wants to
       * cancel the task. If so, calls mutationService to cancel the task
       * and broadcasts an event for a task update.
       * Consecutive calls to cancel the same task cause an early return since
       * a request to cancel the task has been fired.
       * @param taskKey
       */
      function cancelTask(taskKey, serverGuid) {
         var clientTaskInfo = allTasksByKey[getTaskIdentifier(taskKey, serverGuid)];
         if (!clientTaskInfo) {
            return;
         }
         if (clientTaskInfo.pendingCancel) {
            return;
         }

         var alertProperties = {
            title: i18nService.getString('Common', 'cancelTaskTitle'),
            message: i18nService.getString('Common', 'cancelTaskDescription'),
            submit: function () {
               var objectId = clientTaskInfo.taskUid;

               mutationService.apply(objectId,
                   'com.vmware.vise.core.model.monitor.TaskStateSpec',
                   {cancel: 'true'});

               /*
                We have made a service call to cancel the task.  While the task is being
                canceled we do not want the user to click on the cancel link again.
                Therefore we will disable the cancel call back now.
                */
               clientTaskInfo.pendingCancel = true;
               $rootScope.$broadcast(taskConstants.events.UPDATED,
                  [clientTaskInfo.key]);
               return true;
            }
         };
         clarityModalService.openConfirmationModal(alertProperties);
      }

      /**
       * Adds a new entry in the map of all recent tasks.
       * @param partialUpdateItem
       * @returns {*}
       */
      function addNewTask(partialUpdateItem, dateTimeFormat) {
         var clientTaskInfo = angular.extend({}, partialUpdateItem.data);
         var identifier = getTaskIdentifier(clientTaskInfo.key, clientTaskInfo.serverGuid);
         allTasksByKey[identifier] = clientTaskInfo;

         clientTaskInfo.onCancelTaskClicked = function() {
            cancelTask(this.key, this.serverGuid);
         };
         const result = taskFormatter
               .setDerivedProperties(clientTaskInfo, partialUpdateItem.metadata, dateTimeFormat)
               .then(function() {
                  return clientTaskInfo.key;
               });
         return result;
      }

      /**
       * Given the taskUpdate, updates an existing task
       *
       * PartialUpdateItem {
       *    {object} source;
       *    {ClientTaskInfo} data;
       *    {boolean} isDelta;
       *    {array} deltaProperties;
       *    {object} metadata;
       *    }
       *
       * @param partialUpdateItem the PartialUpdateItem object
       */
      function updateTask(partialUpdateItem, dateTimeFormat) {
         // RecentTasksUpdateProcessor does not set 'key' among the
         // delta properties. Use taskRef._value instead.
         var identifier = getTaskIdentifier(
               partialUpdateItem.data.taskRef.value,
               partialUpdateItem.data.taskRef.serverGuid);
         var currentTask = allTasksByKey[identifier];
         if (!currentTask) {
            log.error('Cannot update task id: ', partialUpdateItem.data.taskRef.value,
                  ' partialUpdateItem:', partialUpdateItem, ' all tasks: ', allTasksByKey);
            return null;
         }

         const result = taskFormatter
            .updateTask(currentTask, partialUpdateItem, dateTimeFormat);
         return result;
      }

      /**
       * Given the partialUpdateItem, deletes the existing task, if already exists,
       * from allTasksByKey map
       *
       *
       * PartialUpdateItem {
       *    {object} source;
       *    {ClientTaskInfo} data;
       *    {boolean} isDelta;
       *    {array} deltaProperties;
       *    {object} metadata;
       *    }
       *
       * @param partialUpdateItem the PartialUpdateItem object
       * @param tasksByKey the object map of tasks by their key
       */
      function processTaskDelete(partialUpdateItem) {
         var taskKey = partialUpdateItem.value;
         delete allTasksByKey[getTaskIdentifier(taskKey, partialUpdateItem.serverGuid)];
         return taskKey;
      }

      function notifyTaskError(failedTaskInfo) {
         var taskError = failedTaskInfo.error;
         if (!taskError || !taskError.message) {
            return;
         }

         var errorDetails = {
            name: failedTaskInfo.description,
            entityName: failedTaskInfo.entityName,
            errorMessage: taskError.message
         };
         notificationService.notifyTaskError(errorDetails);
      }

      function getTaskIdentifier(taskKey, serverGuid) {
         return taskKey + ":" + serverGuid;
      }

      return {
         /**
          * The user can filter tasks based on the initiators defined in
          * taskConstants.initiator.
          * This method returns the tasks map for the currently selected
          * initiator.
          *
          * @returns
          *    List of tasks for the currently selected initiator.
          */
         getTasks: function() {
            return _.values(allTasksByKey);
         },
         /**
          * Searches the store for a specific task by its key
          * @param taskKey the key of the task to search for
          * @param serverGuid the guid of the vCenter which this task belongs to
          * @returns the task or undefined, if it is not in the store
          */
         getTask: function(taskKey, serverGuid) {
            return allTasksByKey[getTaskIdentifier(taskKey, serverGuid)];
         },
         onPartialUpdate: function(fn) {
            onUpdateReceivedList.push(fn);
         },
         cancelTask: cancelTask
      };
   }
})();
