(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .service('summaryService', summaryService);

   summaryService.$inject = ['$sce', 'logService', 'htmlBridgeService', 'pluginUrlService'];

   function summaryService($sce, logService, htmlBridgeService, pluginUrlService) {
      const VM_UI = 'VmUi';
      var log = logService('summaryService');
      return {
         createPortlets:createPortlets,
         updatePortlets: updatePortlets,
         isVmView: isVmView,
      };

      function isVmView(resourceBundle) {
         return resourceBundle === VM_UI;
      }

      function updatePortlets(dstPortlets, srcPortlets) {
         var srcPortletDict = {};
         _.each(srcPortlets, function(portlet) {
            srcPortletDict[portlet._view.contentSpec.url] = portlet;
         });
         var hasChanges = dstPortlets.length !== srcPortlets.length;
         _.each(dstPortlets, function(portlet, index) {
            var srcPortlet = srcPortletDict[portlet._view.contentSpec.url];
            if (srcPortlet) {
               dstPortlets[index] = srcPortlet;
            } else {
               hasChanges = true;
            }
         });

         if (hasChanges) {
            return dstPortlets;
         } else {
            return srcPortlets;
         }
      }

      function createPortlets(portletViewSpecs, objectId) {
         if (!portletViewSpecs || !Array.isArray(portletViewSpecs)) {
            log.error("Portlet view spec is null or not an array: ",
                  portletViewSpecs);
            return [];
         }

         var validHtmlPortlets = portletViewSpecs.filter(function(portletView) {
            if (!portletView.contentSpec || !portletView.contentSpec.url) {
               log.log('Flex portlet ignored: ' + portletView.name);
               return false;
            }
            return true;
         });

         return validHtmlPortlets.map(function(portletView) {
            var url;
            var urlParams = null;
            if (portletView.contentSpec.sandbox) {
               url = portletView.contentSpec.url;
               urlParams = {objectId: objectId};
            } else {
               url = $sce.trustAsResourceUrl(portletView.contentSpec.url);
            }
            return {
               title: portletView.name,
               contentUrl: url,
               contentUrlParams: urlParams,
               sandbox: portletView.contentSpec.sandbox,
               remotePluginExtensionContext: portletView.remotePluginExtensionContext,
               _view: portletView
            };
         });
      }
   }
})();
