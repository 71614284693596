/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * VxSingleExtensionTab view controller.
 *
 * VxSingleExtensionTab should be used to add extensible tabs (tabs that hosts extension point) to the
 * vxMasterDetailsView.
 *
 * Example:
 * 1. Defines Extension point that will be hosted by the tab
 *    <extensionPoint id="vsphere.core.samples.extensibleDetailsTab">
 *       <objectType class="com.vmware.vise.mvc.model.ViewSpec"/>
 *    </extensionPoint>
 *
 *
 * 2. Defines extension for the tab
 *    <extension id="vsphere.core.sample.singleExtensionView">
 *       <extendedPoint>vsphere.core.samples.tabbedDetails.tabs</extendedPoint>
 *       <hostedPoint>vsphere.core.samples.extensibleDetailsTab</hostedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>resources/ui/components/masterdetails/vxSingleExtensionTab.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 *
 * 3. Define extension for the tab content
 *    <extension id="vsphere.core.samples.extensibleDetailsTabContentView">
 *       <extendedPoint>vsphere.core.samples.extensibleDetailsTab</extendedPoint>
 *       <object>
 *          <contentSpec>
 *             <url>path-to-template/MyTab2.html</url>
 *          </contentSpec>
 *       </object>
 *    </extension>
 */
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').controller('vxSingleExtensionTabController', VxSingleExtensionTabController);

   VxSingleExtensionTabController.$inject = ['extensionService', '$scope', 'logService', 'navigation'];

   function VxSingleExtensionTabController (extensionService, $scope, logService, navigation) {
      var log = logService('vxSingleExtensionTabController');
      var self = this;

      var objectId = navigation.getRoute().objectId;
      var extensionId;
      if ($scope.tabExtension) {
         extensionId = $scope.tabExtension.uid || navigation.getRoute().extensionId;
      } else {
         extensionId = navigation.getRoute().extensionId;
      }


      if (!extensionId || !objectId) {
         log.error('VxSingleExtensionTabController: invalid configuration');
         return;
      }

      extensionService.getHostedExtensions(extensionId, objectId).then(function (response) {
         if (!response || response.length !== 1) {
            log.error('VxSingleExtensionTabController: Expected 1 hosted point.');
         } else {
            self.viewTemplateUrl = response[0].contentSpec.url;
         }
      });
   }
})();
