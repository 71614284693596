module platform {
   export class WebSocketPingService {
      static $inject = [
            '$rootScope', 'vxZoneService', 'webSocketService',
            'userSessionService','$log'
      ];
      private socketUrl: string;
      private readonly UNAUTHORIZED_HTTP_STATUS_CODE: number = 401;
      private readonly BAD_REQUEST_HTTP_STATUS_CODE: number = 400;
      private readonly NO_RESPONSE_STATUS_CODE = 0;
      private PING_INTERVAL_SEC: number = 10 * 1000; //ten seconds
      private timerId: number | null = null;
      constructor(private $rootScope: ng.IRootScopeService,
            private vxZoneService: any, private webSocketService: WebSocketService,
                  private userSessionService:UserSessionService, private $log: any) {
         this.$rootScope.$on('$destroy', () => {
            this.stopPing();
         });
      }

      private openWebSocket(): void {
         this.userSessionService.ping().then((statusCode: number) => {
            this.$log.debug('Received status code ' + statusCode + " from url " + this.socketUrl);
            if (statusCode === this.UNAUTHORIZED_HTTP_STATUS_CODE) {
               this.stopPing();
               return;
            }

            if (statusCode >= this.BAD_REQUEST_HTTP_STATUS_CODE) {
               return;
            }

            if (statusCode === this.NO_RESPONSE_STATUS_CODE) {
               this.reload(window);
            }

            this.$log.debug('Recreating web socket on ' + this.socketUrl);
            const webSocket: WebSocket | null =
                  this.webSocketService.createWebSocket(this.socketUrl);
            if (!webSocket) {
               this.stopPing();
               return;
            }

            webSocket.onopen = () => {
               webSocket!.close();
               //If ping has stopped don't broadcast events
               if (this.timerId === null) {
                  return;
               }
               this.stopPing();

               this.vxZoneService.runInsideAngular(() => {
                  this.$log.debug('Broadcasting socket reopen and data refresh invocation events.');
                  // Note - this event can potentially be emitted more than once. Hence we
                  // include the socket url as means of differentiating between events if
                  // needed.
                  this.$rootScope.$broadcast('reOpenWebSocketsEvent');
                  this.$rootScope.$broadcast('dataRefreshInvocationEvent', null);
               });
            };
         });
      }

      private reload(window: any): void {
         window.location.reload();
      }

      /**
       * Starts the websocket ping, which tries to open websocket
       * on provided url.
       * The new call is ignored if the ping has been started
       * for other or the same url.
       */
      startPing(url: string): void {
         this.$log.debug('Start web socket ping on ' + url);
         const timeEventFunction = this.openWebSocket.bind(this);
         this.vxZoneService.runOutsideAngular(() => {
            if (!this.timerId) {
               this.socketUrl = url;
               this.timerId = setInterval(timeEventFunction, this.PING_INTERVAL_SEC);
            }
         });
      }

      /**
       * Stops the websocket ping.
       */
      stopPing(): void {
         this.$log.debug('Stop web socket ping on ' + this.socketUrl);
         if (this.timerId === null) {
            return;
         }
         this.vxZoneService.runOutsideAngular(() => {
            if (this.timerId !== null) {
               clearInterval(this.timerId);
               this.timerId = null;
            }
         });
      }
   }

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.WebSocketPingService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Starts/Stops websocket ping. The websocket ping
    *    tries to open websocket on provided url.
    */
   angular.module('com.vmware.platform.ui').service('webSocketPingService',
      WebSocketPingService);
}
