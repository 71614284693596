/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Display a mhz value as either MHz or GHz depending on the magnitude
 */
angular.module('com.vmware.platform.ui').filter('cpuClock', cpuClockFilter);

cpuClockFilter.$inject = ['i18nService', 'numberFormatterService'];

function cpuClockFilter(i18nService, numberFormatterService) {
   'use strict';
   return function (cpuMhz) {
      var suffixMHz = i18nService.getString('VmUi', "VmCpu.MHz");
      var suffixGHz = i18nService.getString('VmUi', "VmCpu.GHz");

      if (cpuMhz < 0) {
         return i18nService.getString('VmUi', "labelUnlimited");
      }

      var suffix;

      if (((cpuMhz % 1000) === 0) && (cpuMhz > 0)) {
         cpuMhz = cpuMhz / 1000;
         suffix = suffixGHz;
      } else {
         suffix = suffixMHz;
      }

      return numberFormatterService.format(cpuMhz, { suffix: suffix });
   };
}
