namespace common_ui {

   export class VmStorageConfig {

      public vmId: string;

      public vmName: string;

      public vmHome : VmComponentStorageConfig;

      public vmDisks: VmComponentStorageConfig[];

      /**
       * Optional param specifying the destination host
       * where the VM will be created/moved.
       *
       * Used when calculating applicable replication groups
       * of the VM. If not provided all replication groups will
       * be shown.
       */
      public destinationHostId: string;

      public getVmHomeProfile(): StorageProfile|null {
         return this.vmHome && this.vmHome.storageProfile;
      }

      public getVmHomeStorage(): any {
         return this.vmHome && this.vmHome.storageObj;
      }

      public getReplicationGroupAssignments(): any[] {
         let result: any[] = [];
         if (this.vmHome && this.vmHome.replicationGroup) {
            result.push(this.vmHome.replicationGroup);
         }
         _.forEach(this.vmDisks, function(diskConfig: VmComponentStorageConfig) {
            if (diskConfig && diskConfig.replicationGroup) {
               result.push(diskConfig.replicationGroup);
            }
         });
         return result;
      }
   }

   export class VmComponentStorageConfig {
      public name: string;

      public key: any | null;

      public storageObj: any | null;

      public diskFormat: any | null;

      public storageProfile: StorageProfile | null;

      public replicationGroup: {
         id: { // com.vmware.vim.binding.vim.vm.replication.ReplicationGroupId
            deviceGroupId: { // com.vmware.vim.binding.vim.vm.replication.DeviceGroupId
               id: string,
            },
            faultDomainId: { // com.vmware.vim.binding.vim.vm.replication.FaultDomainId
               id: string,
            },
         };
         groupInfo: any; // com.vmware.vim.binding.sms.storage.replication.SourceGroupInfo
      } | null;

      public capacityInBytes: any | null;

      public diskGroupName: any | null;
   }
}
