/* Copyright 2016 Vmware, Inc. All rights reserved. -- VMware Confidential */
module platform {


   export class MasterDetailsComponent implements ng.IComponentController {
      private log: any;

      static $inject = ["$scope", "i18nService", "logService", "navigation", "vuiConstants"];
      public masterViewTemplateUrl: string;
      public detailsViewTemplateUrl: string;
      public masterDetailsViewContext: any;
      public detailsViewData: any;
      public isResizable: boolean = false;
      public splitterOptions: any;
      readonly noItemsSelectedWarning: string;
      readonly multipleItemsSelectedWarning: string;

      constructor(private $scope: any,
                  private i18nService: any,
                  private logService: any,
                  private navigation: any,
                  private vuiConstants: any) {
         this.log = this.logService("vxMasterDetailsViewController");
         this.noItemsSelectedWarning = this.i18nService.getString(
               "Common", "ListPreviewSplitView.noItemsSelected");
         this.multipleItemsSelectedWarning = this.i18nService.getString(
               "Common", "ListPreviewSplitView.multipleItemsSelected");
         this.splitterOptions = {
            orientation: this.vuiConstants.splitter.orientation.VERTICAL,
            panes: [
               { min: "250px", size: "50%" },
               { size: "50%"}
            ]
         };
      }

      $onInit(): void {
         this.$scope.detailsViewData = this.detailsViewData || {};
         this.$scope.masterDetailsViewContext = this.masterDetailsViewContext || {};

         var defaults = {
            selectedItems: <Array<any>> [],
            detailsView: <any>null,
            selectedItem: <any> null,
            contextObjectId: this.navigation.getRoute().objectId
         };
         _.defaults(this.$scope.masterDetailsViewContext, defaults);

         this.initWatch();
      }

      private initWatch() {
         this.$scope.$watch(() => {
            return this.$scope.masterDetailsViewContext.selectedItems;
         }, (newValue: Array<any> | null, oldValue: Array<any> | null) => {

            if (newValue === oldValue || _.isEqual(newValue, oldValue)) {
               return;
            }

            if (!newValue || newValue.length === 0 || newValue.length > 1) {
               this.$scope.masterDetailsViewContext.selectedItem = null;
            } else {
               this.$scope.masterDetailsViewContext.selectedItem = newValue[0];
            }
         });
      }

   }

   angular.module("com.vmware.platform.ui")
         .component("vxMasterDetailsComponent", {
            controller: MasterDetailsComponent,
            templateUrl: "resources/ui/components/masterdetails/vxMasterDetailsComponent.html",
            bindings: {
               masterViewTemplateUrl: "@",
               detailsViewData: "<?",
               masterDetailsViewContext: "<?",
               detailsViewTemplateUrl: "@?",
               isResizable: "@?"
            }
         } as ng.IComponentOptions);
}
