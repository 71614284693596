/* Copyright 2018 Vmware, Inc. All rights reserved. -- VMware Confidential */
namespace common_ui {
   /**
    * Wrapper component designed specifically to upgrade StorageSelector directive and
    * allow storage selector usage in angular.next components.
    * This component is not intended to be used by angular 1.5 components. For this
    * purpose the StorageSelector directive should continue to be used.
    */
   export class UpgradableStorageSelector {
      public template: string;
      public controller: any;
      public bindings: any;

      constructor() {
         this.controller = UpgradableStorageSelectorController;
         this.bindings = {
            modeSettings: "=?",
            onSelectionChanged: "&",
            storageSelectorState: "=",
            storageSelectorData: "=",
            storageSelectorApi: "=",
            diskFormatSettings: "=?",
            disableDefaultPreselectionInBasicMode: "<?",
            ensurePreselectionVisibilityInBasicMode: "<?",
            podDisplayDisabled: "<?",
            showReplicationGroups: "<?",
            showStorageBaselineSignpostHints: "<?",
            unsupportedStorageBaselineIds: "<?",
            isVmtxWorkflow: "<?",
            isDiskGroupsAvailable: "<?",
            showEncryptionOptions: "<?",
            isEncryptionOptionsDisabled: "<?",
            encryptionOptionsDisabledReason: "<?"
         };
         this.template =
               `<storage-selector class="display-flex-col"
                  mode-settings="$ctrl.modeSettings"
                  on-selection-changed="$ctrl.onSelectionChanged()"
                  storage-selector-state="$ctrl.storageSelectorState"
                  storage-selector-data="$ctrl.storageSelectorData"
                  storage-selector-api="$ctrl.storageSelectorApi"
                  disk-format-settings="$ctrl.diskFormatSettings"
                  disable-default-preselection-in-basic-mode="$ctrl.disableDefaultPreselectionInBasicMode"
                  ensure-preselection-visibility-in-basic-mode="$ctrl.ensurePreselectionVisibilityInBasicMode"
                  pod-display-disabled="$ctrl.podDisplayDisabled"
                  show-replication-groups="$ctrl.showReplicationGroups"
                  show-storage-baseline-signpost-hints="$ctrl.showStorageBaselineSignpostHints"
                  unsupported-storage-baseline-ids="$ctrl.unsupportedStorageBaselineIds"
                  is-vmtx-workflow="$ctrl.isVmtxWorkflow"
                  is-disk-groups-available="$ctrl.isDiskGroupsAvailable"
                  show-encryption-options="$ctrl.showEncryptionOptions"
                  is-encryption-options-disabled="$ctrl.isEncryptionOptionsDisabled"
                  encryption-options-disabled-reason="$ctrl.encryptionOptionsDisabledReason"
               ></storage-selector>`;
      }

   } // class

   class UpgradableStorageSelectorController {
   } // class

   angular.module("com.vmware.platform.ui").component(
         "vxUpgradableStorageSelector", new UpgradableStorageSelector());
} // namespace
