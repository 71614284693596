/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive for right-click on a data row of a kendo-grid. Given a function callback for the vxDatagridRowRightClick attribute, it calls that function.
 * The function is called passing the (event) parameter. event.data contains the selected data rows in the grid.
 * Usage: Place this on the same element as the vui-datagrid directive.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxDatagridRowRightClick', vxDatagridRowRightClick);

   vxDatagridRowRightClick.$inject = ['$parse', 'vuiConstants'];

   function vxDatagridRowRightClick($parse, vuiConstants) {
      var directive = {
            link: link
      };
      return directive;

      function link(scope, element, attrs) {
         var fn = null;
         if (attrs.vxDatagridRowRightClick !== '') {
            fn = $parse(attrs.vxDatagridRowRightClick);
         }

         // Find the kendoGrid by using the selector.
         var kendoGrid = element.children('div[kendo-grid]');
         kendoGrid.on('contextmenu', "tr[role='row'][data-uid]", function(event) {

            function rightClickPreselection(currentTarget){
               grid.clearSelection();
               // Select the row that has been right-clicked.
               grid.select(currentTarget); // currentTarget is the row (because of the selector used during event binding).
            }


            if (fn === null) {
               event.preventDefault();
               return;
            }
            event.preventDefault();

            var grid = kendoGrid.data('kendoGrid');

            if (grid.options.selectionMode === vuiConstants.grid.selectionMode.SINGLE) {
               event.data = [grid.dataItem(event.currentTarget)];
               if(attrs.hasOwnProperty('vxDatagridRowRightClickPreselection')) {
                  rightClickPreselection(event.currentTarget);
               }
            } else if (grid.options.selectionMode === vuiConstants.grid.selectionMode.MULTI) {
               var selectedRows = grid.select();
               var dataRows = [];
               for (var i = 0; i < selectedRows.length; i++) {
                  var data = grid.dataItem(selectedRows[i]);
                  dataRows.push(data);
               }
               if (isTargetSelected(dataRows, grid.dataItem(event.currentTarget))) {
                  event.data = dataRows;
               } else {
                  rightClickPreselection(event.currentTarget);
                  event.data = [grid.dataItem(event.currentTarget)];
               }
            }
            var eventCopy = jQuery.extend(true, {}, event);
            var callback = function() {
               fn(scope, {$event:eventCopy});
            };
            scope.$apply(callback);
         });
       }

      /**
       * Is the currently selected target part of the already selected rows?
       * @param dataRows Selected rows in the grid.
       * @param target New row to be selected.
       * @returns {boolean} True if the target is already selected, false otherwise.
       */
      function isTargetSelected(dataRows, target) {
         if (!dataRows || !target) {
            return false;
         }
         for (var i = 0; i < dataRows.length; i++) {
            if (dataRows[i].id === target.id) {
               return true;
            }
         }
         return false;
      }
   }

})();
