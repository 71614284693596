/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * GenericSettingsViewController
 */
(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').controller(
         'GenericSettingsViewController', GenericSettingsViewController);

   GenericSettingsViewController.$inject = ['$scope', 'dataService'];

   function GenericSettingsViewController($scope, dataService) {
      var self = this;
      var objectId = $scope._route.objectId;

      self.refresh = refresh;
      self.title = $scope._view.name;
      self.watchForObjects = [objectId];

      self.settingsViewHeaderAccessor = {};

      self.actions = [];
      // extract action ids and custom labels from the ViewSpec metadata (view plugin.xml)
      var actionIndex = 1;
      while ($scope._view.contentSpec.metadata[getActionIdKey(actionIndex)]) {
         self.actions.push({
            actionUid: $scope._view.contentSpec.metadata[
                  getActionIdKey(actionIndex)],
            customLabel: $scope._view.contentSpec.metadata[
                  getActionCustomLabelKey(actionIndex)]
         });

         actionIndex++;
      }

      self.delayRefreshOnObjectUpdatesBy = 0;
      if ($scope._view.contentSpec && $scope._view.contentSpec.metadata) {
         var metadata = $scope._view.contentSpec.metadata;
         self.cssClass = metadata.cssClass || '';
         if (metadata.liveRefreshProperties) {
            self.liveRefreshProperties = _.map(
               metadata.liveRefreshProperties.split(","),
               function (prop) {
                  return prop.trim();
               });
         }
         if (metadata.delayRefreshOnObjectUpdatesBy &&
               !isNaN(Number(metadata.delayRefreshOnObjectUpdatesBy))) {
            self.delayRefreshOnObjectUpdatesBy = Number(metadata.delayRefreshOnObjectUpdatesBy);
         }
      }

      function getProperties() {
         if ($scope._view && $scope._view.contentSpec &&
               $scope._view.contentSpec.metadata &&
               $scope._view.contentSpec.metadata.propertyToRetrieve) {

            var propertyToRetrieve =
                  $scope._view.contentSpec.metadata.propertyToRetrieve;

            dataService.getProperties(objectId, [propertyToRetrieve]).then(
                  function (response) {
                     if (response) {
                        var newPropertyItems = response[propertyToRetrieve] || [];

                        // Try to apply expanded states to the newPropertyItems.
                        if (self.propertyItems && self.propertyItems.length > 0) {
                           // Map containing all expanded items.
                           var expandedPropertyItems = {};
                           _.forEach(self.propertyItems, function(item) {
                              if (item && item.expanded) {
                                 expandedPropertyItems[item.label] = true;
                              }
                           });

                           _.forEach(newPropertyItems, function(item) {
                              if (expandedPropertyItems[item.label] === true) {
                                 item.expanded = true;
                              }
                           });
                        }

                        self.propertyItems = newPropertyItems;
                     } else {
                        self.propertyItems = [];
                     }
                  });
         }
      }

      function refresh() {
         getProperties();

         if (self.settingsViewHeaderAccessor && self.settingsViewHeaderAccessor.refresh) {
            self.settingsViewHeaderAccessor.refresh();
         }
      }

      getProperties();
   }

   function getActionIdKey(actionIndex) {
      return 'action' + actionIndex + 'Id';
   }

   function getActionCustomLabelKey(actionIndex) {
      return 'action' + actionIndex + 'CustomLabel';
   }

})();
