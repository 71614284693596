/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Provides webclient.properties flags and feature states.
 * Returns true if feature is enabled or false otherwise.
 */
(function() {
   "use strict";
   angular.module("com.vmware.platform.ui")
         .factory("configurationService", configurationService);

   configurationService.$inject = ["$http","$q", "logService"];

   function configurationService($http, $q, logService) {

      var log = logService("configurationService");

      var getConfiguration = function() {
         return $q.when(h5.configuration);
      };

      var getProperty = _.memoize(function (propertyName) {
         return getConfiguration().then(function(configPayload) {
            if (configPayload && configPayload.configParams) {
               return configPayload.configParams[propertyName];
            }
            return undefined;
         });
      });

      var getFeatureState = _.memoize(function (featureName) {
         return getConfiguration().then(function(configPayload) {
            if (configPayload && configPayload.featureStates) {
               return configPayload.featureStates[featureName];
            }
            return undefined;
         });
      });

      var isFeatureEnabled = _.memoize(function (featureName) {
         return getFeatureState(featureName).then(function(featureState) {
            return !!(featureState && featureState === "ENABLED");
         });
      });

      return {
         getConfiguration: getConfiguration,
         getProperty: getProperty,
         isFeatureEnabled: isFeatureEnabled
      };
   }
})();
