module platform {
   export class SpbmReplicationGroupInfoService {
      static $inject = ["$injector"];

      private _vscSpbmReplicationGroupInfoService: SpbmReplicationGroupInfoService;

      constructor(private $injector: any) {
      }

      get vscSpbmReplicationGroupInfoService() {
         if (!this._vscSpbmReplicationGroupInfoService) {
            this._vscSpbmReplicationGroupInfoService =
                  this.$injector.get("vscSpbmReplicationGroupInfoService");
         }
         return this._vscSpbmReplicationGroupInfoService;
      }

      extractReplicationGroupInfos(
            compatibilityResult: any, hostRefs: any[], allRgInfos: any[]): any[] {

         return this.vscSpbmReplicationGroupInfoService.extractReplicationGroupInfos(
               compatibilityResult, hostRefs, allRgInfos);
      }

      getReplicationGroupName(replicationGroupInfo: any): string {
         return this.vscSpbmReplicationGroupInfoService.getReplicationGroupName(
               replicationGroupInfo);
      }

      equalReplicationGroupIds(
            id1: any, id2: any): boolean {
         return this.vscSpbmReplicationGroupInfoService.equalReplicationGroupIds(id1, id2);
      }

      equalReplicationGroupInfos(
            rgInfo1: any, rgInfo2: any): boolean {
         return this.vscSpbmReplicationGroupInfoService
               .equalReplicationGroupInfos(rgInfo1, rgInfo2);
      }

      isAutoReplicationGroup(rgInfo: any): boolean {
         return this.vscSpbmReplicationGroupInfoService.isAutoReplicationGroup(rgInfo);
      }

      validateVmReplicationGroupAssignments(
            replicationGroupInfos: any[]): string|null {
         return this.vscSpbmReplicationGroupInfoService.validateVmReplicationGroupAssignments(
               replicationGroupInfos);
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("spbmReplicationGroupInfoService", SpbmReplicationGroupInfoService);
}