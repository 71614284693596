/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {
   "use strict";

   export interface TreeError {
      name: string;
   }

   export class TreeSelectionError implements TreeError {
      name = "selection";
   }

   export type TreeErrorType = TreeSelectionError;

   export class TreeViewErrorConstants {
      static readonly SELECTION: TreeErrorType = new TreeSelectionError();
   }

   angular.module("com.vmware.platform.ui")
         .constant("treeViewErrorConstants", TreeViewErrorConstants);
}
