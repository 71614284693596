/* Copyright 2013-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for Simple and Advanced search.
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui')
         .controller('SearchViewController', SearchViewController);

   SearchViewController.$inject = [
      '$rootScope',
      '$scope',
      '$http',
      '$httpParamSerializer',
      'vxViewUtil',
      'searchService',
      'i18nService',
      'extensionFrameworkConstants',
      'timeFormatterService',
      'featureFlagsService'
   ];

   function SearchViewController($rootScope, $scope, $http, $httpParamSerializer, vxViewUtil,
                                 searchService, i18nService, extensionFrameworkConstants, timeFormatterService, featureFlagsService) {

      var SEARCH_EXTENSION_POINT = 'vsphere.core.search.domainView';
      var SIMPLE_SEARCH = 'simple';
      var SIMPLE_SEARCH_REST_ENDPOINT = "search/simplesearch/summary/?";

      var i18n = i18nService.getString;

      var searchState = {
         searchInProgress : i18n('Common','searching'),
         noResults : i18n('Common','noResultsFound'),
         searchComplete : i18n('Common','resultsFound')
      };

      //--------------------- scope destroy event -------------------
      $scope.$on('$destroy', function() {
         broadCastSearchInfo(searchService.searchStates.searchDestroyed);
      });

      $rootScope.$on('navSelectionChanged', function (evt, selectedSearchNode) {
         $scope.selectedSearchNode = selectedSearchNode.selection;
      });

      $scope.getSelectedSearchTab = function() {
         return _.find($scope.tabs, function(node) {
            return $scope.selectedSearchNode && $scope.selectedSearchNode.listUid === node.$id;
         });
      };


      $scope.getSearchText = function() {
         return ($scope.currentSearchState === searchState.searchComplete) ?
               i18n('Common','resultsFoundWithData', $scope.searchContext.query):
               '';
      };

      $rootScope.$on('refreshSearchResults', function (evt, payload) {
         prepareSearchViewData(payload.selection);
      });


      //--------------------- scope functions -------------------

      /*
       * Watch on the _route.query param.
       * This changes when the search query changes either from UI or from URL (deep-linking).
       */
      $scope.$watch('_route.query', function (newValue, oldValue) {
         $scope.searchContext.query = $scope._route.query;
         $scope.searchContext.searchType = $scope._route.searchType;
         if (!$scope.searchContext.searchType) {
            // by default its simple search
            $scope.searchContext.searchType = SIMPLE_SEARCH;
         }
         search();
      });

      /*
       * Get the current search state
       */
      $scope.getSearchState = function () {
         return $scope.currentSearchState;
      };

      /*
       * Common entry point for simple and advanced search
       * Function to parse search string, validate it and do search.
       * $scope.doSearch() is bound to the submit button clicked on the UI.
       */
      $scope.doSearch = navigateToSearch;
      $scope.searchContext = new SearchContext("", SIMPLE_SEARCH);
      reset();

      //--------------------- internal functions -------------------

      /**
       * Constructor to create a new SearchContext object
       */
      function SearchContext(searchQuery) {
         // assume simple until advance search is implemented
         this.searchType = SIMPLE_SEARCH;
         this.query = searchQuery;

         this.getContext = function () {
            return {query:this.query , searchType : this.searchType};
         };

         this.isValid = function() {
            return (!!this.query  && !!this.searchType);
         };
      }

      function setSearchState(state){
         $scope.currentSearchState = state;
      }

      /**
       * Uses the navigation service to navigate to search and set the query in the URL.
       */
      function navigateToSearch() {
         if ($scope.searchContext.isValid()) {
            $scope._navigate(SEARCH_EXTENSION_POINT,
                  null, $scope.searchContext.getContext(), { triggerONChange: true });
         }
      }

      /*
       * Reset search tabs and other scope data
       */
      function reset() {
         $scope.tabs = [];
         $scope.searchTimeStamp = undefined;
         $scope.currentSearchState = '';
      }

      /**
       * If query is valid, clears the tabs, does the search and then displays the tabs
       */
      function search() {
         if ($scope.searchContext.isValid()) {
            // Reset tabs
            reset();
            // Begin search
            setSearchState(searchState.searchInProgress);
            broadCastSearchInfo(searchService.searchStates.searchInProgress);
            // Do search and render in tabs
            getSimpleSearchTypeSummary($scope.searchContext.query).then(function(results) {
               //prepareSearchViewData(results);
               prepareSearchViewData(results);
            });
         }
      }

      /**
       * Get the results from backend given the searchTerm
       * @param {string} searchTerm - The searchTerm
       */
      function getSimpleSearchTypeSummary(searchTerm) {
         var validatedSearchTerm = _.isString(searchTerm) ? searchTerm : '';

         var serializedSearchTerm = $httpParamSerializer({
            query: validatedSearchTerm,
            opId: ":" + (Math.floor(Math.random() * 1000000000) + 1000000000)
         });

         // build the RestEndpoint URL could be made into a service
         var url = SIMPLE_SEARCH_REST_ENDPOINT + serializedSearchTerm;

         // get results and return
         return $http.get(url).then(function(resp) {
            return resp.data;
         });
      }

      /*
       * --- Common function to render tabs and init the list views in them. ---
       * --- Iterates over search results and set the tabs on the scope. ---
       * function name changed from displayTabs to prepareSearchViewData, since we
       * no more show tabs in the search results. the left pane navigator directly
       * controls the listview in the search pane.
       */
      function prepareSearchViewData(searchResults) {
         // reset previous search results
         reset();
         broadCastSearchInfo(searchService.searchStates.searchComplete,searchResults);

         if (searchResults.length === 0 ) {
            setSearchState(searchState.noResults);
         } else {
            $scope.searchTimeStamp = timeFormatterService.timestampToText(new Date());
            setSearchState(searchState.searchComplete);
         }

         for (var i = 0; i < searchResults.length ; i++) {
            var searchViewNode = vxViewUtil.getNode({
               $templateUrl: searchResults[i].listUrl,
               $viewRetentionPolicy: extensionFrameworkConstants.ViewRetentionPolicy.NONE,
               title: searchResults[i].labelPlural,
               icon: searchResults[i].icon,
               filterId: 'simpleSearchListFilterId',
               filterParams: searchResults[i].objectUids,
               dataModels: [searchResults[i].type],
               $id: searchResults[i].listUid,
               listSpec:  {
                  contentSpec: {
                     metadata: searchResults[i].listMetadata
                  }
               }
            });
            $scope.tabs.push(searchViewNode);
         }

      }

      /*
       * Send search status and results to searchService which will be picked up by ON
       */
      function broadCastSearchInfo(searchState, searchResults, query, searchType ) {
         query = query || $scope.searchContext.query;
         searchType = searchType || $scope.searchContext.searchType;
         searchService.setSearchData(searchState, searchResults, query, searchType);
      }

   }
}());
