(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').service('featureFlagsService', featureFlagsService);

   featureFlagsService.$inject = ['configurationService'];

   function featureFlagsService(configurationService) {
      var self = {};

      fetchFeatureFlag('h5uiVmLibraryActionAddFlag');
      fetchFeatureFlag('hardwareV14_vbs');
      fetchFeatureFlag('h5uiCLSoverVAPI');
      fetchFeatureFlag('h5uiOvfDeployFromLocal');
      fetchFeatureFlag('h5uiDirectIsoMountFromCL');
      fetchFeatureFlag('h5uiImportItemToCL');
      fetchFeatureFlag('h5uiMoveVmToHostAndCluster');
      fetchFeatureFlag('h5uiAddNetworkingBindToPnic');
      fetchFeatureFlag('h5uiNRP');
      fetchFeatureFlag('HWv14');
      fetchFeatureFlag('VMcrypt2');
      fetchFeatureFlag('DeferHistoryImport');
      fetchFeatureFlag('PMem');
      fetchFeatureFlag('VRDMA_ROCEV2');
      fetchFeatureFlag('HCI');
      fetchFeatureFlag('CL_Native_VMTX_Phase2');
      fetchFeatureFlag('CL_OVA_Support');
      fetchFeatureFlag('CL_VMTX_Sync');
      fetchFeatureFlag('h5uiLicensingOnlineSync');

      function fetchFeatureFlag(flagName) {
         self[flagName + 'Enabled'] = function() { return false; };

         configurationService.isFeatureEnabled(flagName).then(function(featureIsEnabled) {
            self[flagName + 'Enabled'] = function() {
               return featureIsEnabled;
            };
         });
      }

      return self;
   }

   window.featureFlagsService = featureFlagsService;
})();
