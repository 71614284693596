/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive for showing a Clarity spinner
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .directive('vxActivityIndicator', vxActivityIndicator);

   function vxActivityIndicator () {

      return {
         templateUrl: 'resources/ui/components/activityIndicator/vxActivityIndicator.html',
         transclude: true,
         scope: {
            isInteractive: "=?",
            isVisible: "=",
            title: "@",
            size: "@?",
            loadingAriaLabel: "@?"
         },
         controller: function($scope) {
            this.$onInit = function() {
               $scope.isInteractive = angular.isDefined($scope.isInteractive) ? $scope.isInteractive : true;
            };
         },
         restrict: "E",
         priority: -500
      };
   }
})();
