/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   "use strict";

   angular.module("com.vmware.platform.ui").directive("focusPrimaryButton", focusPrimaryButton);

   focusPrimaryButton.$inject = ["$timeout"];

   function focusPrimaryButton($timeout) {

      const directive = {
         scope: {
            primaryTitle: "<?"
         },
         restrict: "A",
         compile: function compile(element, attributes) {
            return {
               pre: function () {},
               post: postLink
            };
         }
      };

      function postLink(scope, element, attributes) {
         $timeout(function() {
            const childElements = element.find("button.btn-primary");
            if (childElements.length > 0) {
               childElements[0].focus();
            }
         }, 0);

      }

      return directive;
   }
})();