/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service to save data in an object. It will give a new object storage for every request
 * This Service is convenient when we want to mock the localStorageService
 */
angular.module('com.vmware.platform.ui').factory('simpleObjectStorageService', ['$q', function ($q) {

   return {
      getObjectStorage: getSimpleObjectStorage
   };

   function getSimpleObjectStorage() {
      var dict = {};

      //Public API
      var service = {
         getData: getData,
         setData: setData,
         getUserData: getUserData,
         setUserData: setUserData
      };
      return service;

      /**
       * Retrieving data from the instance storage.
       * @param key the key to the local storage data
       * @returns the object being saved in the local storage,
       *         Note that the stored data is converted through angular.fromJson()
       */
      function getData(key) {
         return dict[key];
      }

      /**
       * Setting data into the instance storage.
       * The data is stringified through angular.toJson() before being written
       * @param key the key to the local storage data
       * @param data to be stored
       */
      function setData(key, data) {
         dict[key] = data;
      }

      /**
       * Same as getData. For instance storage the user is ignored
       *
       * @param key the key to the local storage data
       * @returns a promise which when resolved will hold the object being saved in the local storage
       */
      function getUserData(key) {
         return $q.when(getData(key));
      }

      /**
       * Same as setData. For instance storage the user is ignored
       */
      function setUserData(key, data) {
         setData(key, data);
      }
   }
}]);
