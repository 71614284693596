/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.module('com.vmware.platform.ui').filter('errorFormat',
   function () {
   'use strict';

   /**
    * @param newLineSymbol
    *    The string to use instead of the default new line symbol. The default value
    *    is <br> as this is going to be used for HTML error display.
    */
   return function (errorObject, newLineSymbol) {
      if (errorObject === null) {
         return "null";
      }

      if (!newLineSymbol) {
         newLineSymbol = "<br>";
      }

      var message, messageData;

      // This is a JavaScript error object.
      if (errorObject instanceof Error) {
         messageData = [];
         if (errorObject.message) {
            messageData.push(errorObject.message);
         }
         if (errorObject.name) {
            messageData.push("name: " + errorObject.name);
         }
         // MS
         if (errorObject.description) {
            messageData.push(errorObject.description);
         }
         if (errorObject.number) {
            messageData.push("number: " + errorObject.number);
         }
         // Mozilla
         if (errorObject.fileName) {
            messageData.push("file: " + errorObject.fileName);
         }
         if (errorObject.lineNumber) {
            messageData.push("line: " + errorObject.lineNumber);
         }
         if (errorObject.columnNumber) {
            messageData.push("col: " + errorObject.columnNumber);
         }
         if (errorObject.stack) {
            messageData.push("stack: " + errorObject.stack);
         }
         message = "UnexpectedError (js): " + messageData.join("\n");
         return escape(message, newLineSymbol);
      }

      // We prefer the object's own error formatting (if available);
      message = errorObject.toString();
      if (message !== "[object Object]") {
         return message;
      }

      // We recognize some Angular supplied pure objects, i.e ones returned by
      // the $httpBackend when remote calls return errors.
      if (errorObject.data) {
         messageData = [];
         var data = errorObject.data;
         if (data.message) {
            messageData.push(data.message);
         }
         if (data.cause) {
            messageData.push("cause: " + data.cause);
         }
         if (data.stackTrace) {
            messageData.push("stack: " + data.stackTrace);
         }

         if (messageData.length > 0) {
            message = "UnexpectedError (object): " + messageData.join("\n");
            return escape(message, newLineSymbol);
         }
      }

      // Stringify the object. That is the default formatting when all other
      // options are exhausted.
      message = JSON.stringify(errorObject);
      return escape("UnexpectedError (json): " + message, newLineSymbol);

      function escape(message, newLineSeparator) {
         var newMessage = _.escape(message);
         if (newLineSeparator !== "\n") {
            newMessage = newMessage.replace(/\n|\\n/g, newLineSeparator);
         }
         return newMessage;
      }
   };
});
