/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Contains common functions for TaskConsole and RecentTasks.
 */
angular.module('com.vmware.platform.ui')
.factory('taskFormatter', ['$filter', 'i18nService', 'taskConstants',
         'objectTypesService', '$q', 'timeFormatterService',
   function($filter, i18nService, taskConstants,
            objectTypesService, $q, timeFormatterService) {
      'use strict';

      var taskIconsByState = {};
      taskIconsByState[taskConstants.status.ERROR] = 'vsphere-icon-status-error';
      taskIconsByState[taskConstants.status.CANCELED] = 'vui-icon-task-canceled';
      taskIconsByState[taskConstants.status.SUCCESS] = 'vsphere-icon-status-ok';
      taskIconsByState[taskConstants.status.RUNNING] = 'vui-icon-task-running';
      taskIconsByState[taskConstants.status.PAUSED] = 'vui-icon-task-paused';
      taskIconsByState[taskConstants.status.QUEUED] = 'vui-icon-task-queued';

      return {
         formatDate: function (timestamp) {
            return timeFormatterService.formatDate(timestamp);
         },
         getProgressString: function(value) {
            return i18nService.getString('Common', 'tasksFormat.progressPercent',
                  value);
         },
         /**
          * Returns localized status label for a given task. If the task has an error
          * then returns the error message. If the task is in progress, returns the
          * string representation of the numeric progress percentage (used for
          * sorting).
          */
         getClientStatus: function(info) {
            if (info.state === taskConstants.status.RUNNING) {
               if(info.progress < 0) {
                  info.progress = 0;
               }
               return this.getProgressString(info.progress);
            }
            return this.getClientErrorOrSimpleStatus(info);
         },
         /**
          * Returns true if a task has failed and its error field is not empty.
          */
         hasClientError: function(info) {
            return info.state === taskConstants.status.ERROR && info.error !== null;
         },
         /**
          * Returns localized status label for a given task. If the task has an error
          * then returns the error message.
          */
         getClientErrorOrSimpleStatus: function(info) {
            if (info.state === taskConstants.status.ERROR &&
                  info.error !== null) {
               if(info.error.message){
                   return info.error.message;
               }
            }
            return this.getSimpleStatus(info.state);
         },
         /**
          * Returns localized status label for a given TaskInfo_State.
          */
         getSimpleStatus: function(state) {
            return i18nService.getString('Common', state.toLowerCase());
         },
         getTaskIconByState: function(state) {
            if (state in taskIconsByState) {
               return taskIconsByState[state];
            }
            return 'vsphere-icon-status-unknown';
         },
         /**
          * Sets client-side properties that are derived from the clientTaskInfo.
          * The result is basically the equivalent of flex's TaskListItemData.as
          * Returns a promise, because the retrieval of entity icons from the
          * objectTypesService is asynchronous.
          * @param clientTaskInfo the object returned by the dataProvider
          * @param metadata (Optional) the task from a partialUpdateItem,
          * this is its metadata.
          * @returns a promise, resolved when property formatting is completed.
          */
         setDerivedProperties: function(clientTaskInfo, metadata, dateTimeFormat) {
            var deferred = $q.defer();
            clientTaskInfo.statusSummary = this.getClientStatus(clientTaskInfo);
            clientTaskInfo.initiatorName =
                  this.getInitiatorName(clientTaskInfo, metadata);
            // We check the MoRef type here and not display the hyperlink for
            // CLS objects (Content Library and Content Library Items).
            // For more information and future maintenance see bug 1351765.
            clientTaskInfo.targetExists =
                  clientTaskInfo.entity !== null &&
                  clientTaskInfo.entityType !== 'ContentLibrary' &&
                  clientTaskInfo.entityType !== 'ContentLibraryItem';

            if (clientTaskInfo.startTime) {
               clientTaskInfo.queuedForMilliseconds = clientTaskInfo.startTime - clientTaskInfo.queueTime;
               clientTaskInfo.timeInQueueString = i18nService
                     .getString('Common', 'msStringFormat', clientTaskInfo.queuedForMilliseconds);
            }

            if (dateTimeFormat) {
               clientTaskInfo.startTimeString = timeFormatterService.timestampToText(clientTaskInfo.startTime,
                     dateTimeFormat);
               clientTaskInfo.completionTimeString = timeFormatterService.timestampToText(clientTaskInfo.completionTime,
                  dateTimeFormat);
            } else {
               this.formatDate(clientTaskInfo.startTime).then(function(formattedTime) {
                  clientTaskInfo.startTimeString = formattedTime;
               });
               this.formatDate(clientTaskInfo.completionTime).then(function(formattedTime) {
                  clientTaskInfo.completionTimeString = formattedTime;
               });
            }

            if (clientTaskInfo.entityType === 'rootFolder') {
               clientTaskInfo.entityIcon = 'vsphere-icon-vcenter';
               deferred.resolve(clientTaskInfo);
            } else {
               // retrieve icon to show in the 'Target' column
               objectTypesService.getObjectTypeSpec(clientTaskInfo.entityType)
                     .then(function(objectTypeSpec) {
                        clientTaskInfo.entityIcon = objectTypeSpec.icon;
                     }).finally(function() {
                        deferred.resolve(clientTaskInfo);
                     });
            }
            return deferred.promise;
         },
         /**
          * Updates a task with the given partialUpdateItem
          *
          * @param clientTaskInfo the task to be updated
          * @param partialUpdateItem the partial update task item
          */
         updateTask: function(clientTaskInfo, partialUpdateItem, dateTimeFormat) {
            partialUpdateItem.deltaProperties.forEach(function(property) {
               clientTaskInfo[property] = partialUpdateItem.data[property];
            });
            return this.setDerivedProperties(clientTaskInfo,
                  partialUpdateItem.metadata, dateTimeFormat);
         },
         /**
          * Converts the initiator's name into a username format, see how these two
          * formats differ below:
          *
          * initiator name: DOMAIN_NAME\USER_NAME
          * username: USER_NAME@DOMAIN_NAME
          *
          * @param taskInitiator
          *    The given initiator's name that needs to be converted into a username
          *    format
          * @returns
          *    the initiator's name in the username format, if it is a user,
          *    unmodified taskInitiator otherwise.
          */
         convertInitiatorToUsername: function(taskInitiator) {
            var domainname, username, separator = '\\', arrayOfStrings;
            if (taskInitiator.indexOf(separator) > -1) {
               arrayOfStrings = taskInitiator.split(separator);
               domainname = arrayOfStrings[0];
               username = arrayOfStrings[1];
               return (username + '@' + domainname).toLowerCase();
            } else {
               return taskInitiator;
            }
         },
         /**
          * Gets the name of the task initiator
          *
          * @param data
          *    contains data for clientTaskInfo
          * @param metadata
          *    may contain the initiator's name
          * @returns
          *    the username that initiated this task or the name of the service
          */
         getInitiatorName: function(data, metadata) {
            var initiatorName;
            if (metadata && metadata.reason && metadata.reason.userName) {
               initiatorName = this.convertInitiatorToUsername(metadata.reason.userName);
            } else if (data.initiator) {
               initiatorName = this.convertInitiatorToUsername(data.initiator);
            }

            return initiatorName;
         }
      };
   }]);
