/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Enhanced version of ng-transclude
 *
 * Supports a onTransclude function which will be called with the transcludedScope supplied.
 * <div vx-transclude="onTransclude($transcludedScope)"></div>
 *
 * TODO: contribute back to ng-transclude?
 */
angular.module('com.vmware.platform.ui').directive('vxTransclude', function() {
          return {
             controller: ['$transclude', '$element', '$attrs', '$parse', '$scope',
                function($transclude, $element, $attrs, $parse, $scope) {
                   $transclude(function(clone, transcludedScope) {
                     $element.append(clone);

                     // invokes the onTransclude function
                     var getter = $parse($attrs.vxTransclude);
                     getter($scope, {
                        $transcludedScope: transcludedScope
                     });

                });
              }]
          };
        });