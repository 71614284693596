/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular
         .module('com.vmware.platform.ui')
         .service('pageStateManagementService', pageStateManagementService);

   pageStateManagementService.$inject = ['vuiConstants'];

   /**
    * Provides a strategy for keeping in the wizard ToC in consistent state.
    *
    * To achieve this it obeys the following rules:
    * 1. The page after the current is always accessible i.e. incomplete or completed.
    * 2. The page after a completed page should be accessible.
    * 3. A page after a incomplete or disabled page should be always disabled expect in the case 1.
    *
    * The only responsibility of the developer is to maintain the state of the current page.
    * For example if the user input page is no longer valid, the developer just has to assign
    * INCOMPLETE to currentPage.state, and this strategy will take care of everything else.
    */
   function pageStateManagementService(vuiConstants) {
      var pageState = vuiConstants.wizard.pageState;
      return {
         registerWizardForDefaultStateManagementStrategy: registerWizardForDefaultStateManagementStrategy,
         applyDefaultStateManagementStrategy: applyDefaultStateManagementStrategy,
         togglePageVisibility: togglePageVisibility
      };


      /**
       * Watches for changes in all page states and the current page and triggers
       * applyDefaultStateManagementStrategy in case of a change.
       */
      function registerWizardForDefaultStateManagementStrategy(wizardScope) {
         //by default all pages have to be DISABLED
         _.each(wizardScope.wizardConfig.pages, function (page) {
            page.state = pageState.DISABLED;
         });
         //watches for all page states
         var watchExpressions = _.map(wizardScope.wizardConfig.pages, function (page) {
            return function () {
               return page.state;
            };
         });
         //watch for changes of the current page.
         watchExpressions.push('wizardConfig.currentPage');

         wizardScope.$watchGroup(watchExpressions,
               function () {
                  applyDefaultStateManagementStrategy(wizardScope.wizardConfig);
               });
      }

      /**
       * Updates page states starting from first to last visible page taking into account
       * the guidelines for navigation behavior.
       *
       * The algorithm iterates through the pages starting from the first one and sets the
       * state of each subsequent page based on its precursor and what the which is the
       * current page.
       */
      function applyDefaultStateManagementStrategy(wizardConfig) {

         var visiblePages = _.filter(wizardConfig.pages, function (page) {
            return page.state !== pageState.SKIPPED;
         });

         //assign previousPage to a fake completed page because the first page should behave
         //like the previous page is completed
         var previousPage = {};
         previousPage.state = pageState.COMPLETED;

         _.each(visiblePages, function (page) {
            if (previousPage.state === pageState.COMPLETED) {
               // If a completed page is followed by a disabled one we need to
               // enable the later. Such sequence can occur if a another page between
               // these particular pages got skipped.
               page.state =
                     (page.state === pageState.DISABLED)
                           ? pageState.INCOMPLETE
                           : page.state /* Preserve current state */;
            } else if (previousPage.state === pageState.INCOMPLETE) {
               // if the previous page was the "current" one then this page should be
               // incomplete. Otherwise it should be disabled...
               page.state =
                     (previousPage === wizardConfig.currentPage)
                           ? pageState.INCOMPLETE
                           : pageState.DISABLED;
            } else if (previousPage.state === pageState.DISABLED) {
               // all subsequent pages should be disabled...
               page.state = pageState.DISABLED;
            } else {
               throw Error("Unsupported page state: " + previousPage.state);
            }
            previousPage = page;
         });
      }

      function togglePageVisibility(page, isVisible) {
         if (isVisible) {
            // change the state to DISABLED only if the page is currently invisible.
            if (page.state === pageState.SKIPPED) {
               page.state = pageState.DISABLED;
            }
         } else {
            page.state = pageState.SKIPPED;
         }
      }
   }
})();
