/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   /**
    * Class representing the options that will configure additionally the CSS path creation:
    *       - cssClassBlacklist - list of element classes that should not be included in the CSS path
    */
   export class CssPathOptions {
      constructor(public cssClassBlacklist: string[]) {
      }
   }

   /**
    * Describes information about a node CSS path
    */
   export interface ClassSelectorData {
      /**
       * A unique CSS selector for the node.
       */
      classSelector: string;

      /**
       * Determines whether the classSelector contains telemetry id.
       */
      containsTid: boolean;
   }

   /**
    * A telemetry specific wrapper for DOM nodes;
    */
   export interface TelemetryDomNodeWrapperInterface {
      /**
       * The id of the wrapped element.
       * Valid only if the wrapped node is an element node!
       */
      id: string | null;

      /**
       *  A list of the CSS classes of the wrapped element.
       *  Valid only if the wrapped node is an element node!
       */
      classList: string[] | null;

      /**
       * The index of the wrapped element relative to its sibling elements
       * inside its parent.
       * Valid only if the wrapped node is an element node!
       */
      index: number | null;

      /**
       * The node type of the wrapped node.
       */
      nodeType: number;

      /**
       * The node name of the wrapped node.
       */
      nodeName: string;

      /**
       * The direct text contents of the wrapped node excluding the text
       * contents of its descendants.
       * Valid only if the wrapped node is an element node!
       */
      textContents: string[] | null;

      /**
       * Determines whether the wrapped node is embedded in a child document
       */
      isEmbeddedNodeWrapper: boolean;

      /**
       * A wrapper of the parent node of this wrapped node.
       */
      parentNodeWrapper: TelemetryDomNodeWrapperInterface | null;
   }

   export class TelemetryDomNodeWrapper implements TelemetryDomNodeWrapperInterface {

      /**
       * Constructs a TelemetryDomNodeWrapper for a DOM Node.
       *
       * @param node to wrap
       * @param telemetryHelperService to use when calculating certain
       * properties.
       */
      constructor(private node: Node,
            private telemetryHelperService: TelemetryHelperService) {
      }

      /**
       * @inheritDoc
       */
      public get id(): string | null {
         if (!this.telemetryHelperService.isElementNode(this.node)) {
            return null;
         }

         return (<Element> this.node).id;
      }

      /**
       * @inheritDoc
       */
      public get classList(): string[] | null {
         if (!this.telemetryHelperService.isElementNode(this.node)) {
            return null;
         }

         return this.telemetryHelperService
               .getElementClassList(<Element> this.node);
      }

      /**
       * @inheritDoc
       */
      public get index(): number | null {
         if (!this.telemetryHelperService.isElementNode(this.node)) {
            return null;
         }

         return this.telemetryHelperService
               .getElementIndex(<Element> this.node);
      }

      /**
       * @inheritDoc
       */
      public get nodeType(): number {
         return this.node.nodeType;
      }

      /**
       * @inheritDoc
       */
      public get nodeName(): string {
         return this.node.nodeName;
      }

      /**
       * @inheritDoc
       */
      public get textContents(): string[] | null {
         if (!this.telemetryHelperService.isElementNode(this.node)) {
            return null;
         }

         return this.telemetryHelperService
               .getElementDirectTextContents(<Element> this.node);
      }

      /**
       * @inheritDoc
       */
      public get isEmbeddedNodeWrapper(): boolean {
         return this.telemetryHelperService.isEmbeddedNode(this.node);
      }

      /**
       * @inheritDoc
       */
      public get parentNodeWrapper(): TelemetryDomNodeWrapper | null {
         let parentNode: Node | null | undefined =
               this.telemetryHelperService.getParentNode(this.node);

         if (!parentNode) {
            return null;
         }

         return new TelemetryDomNodeWrapper(
               parentNode,
               this.telemetryHelperService
         );
      }
   }
}