(function () {
   'use strict';
   angular
         .module('com.vmware.platform.ui')
         .factory('keyboardShortcutMapper', keyboardShortcutMapper);
   keyboardShortcutMapper.$inject = [
   ];

   /**
    * This service has one purpose and one purpose only: keeping the mapping
    * between a keyboard shortcut and action invoked as result of the shortcut.
    */
   function keyboardShortcutMapper() {
      var shortcutsByActionId = {};

      var service = {
        getKeyboardShortcut: getKeyboardShortcut,
        getFormattedKeyboardShortcut: getFormattedKeyboardShortcut,
        putKeyboardShortcut: putKeyboardShortcut
      };
      return service;


      function getKeyboardShortcut(actionId) {
         var info = shortcutsByActionId[actionId];
         if (!info) {
            return undefined;
         }

         return info.raw;
      }

      function getFormattedKeyboardShortcut(actionId) {
         var info = shortcutsByActionId[actionId];
         if (!info) {
            return undefined;
         }

         return info.formatted;
      }

      function putKeyboardShortcut(actionId, shortcut) {
         shortcutsByActionId[actionId] = {
            raw: shortcut,
            formatted: formatKeyboardShortcut(shortcut)
         };
      }

      function formatKeyboardShortcut(shortcut) {
         if (shortcut === undefined) {
            return undefined;
         }

         var keys = shortcut.split("+");

         for (var i = 0; i < keys.length - 1; i++) {
            keys[i] = keys[i].trim().toLowerCase();
         }
         keys[keys.length - 1] = keys[keys.length - 1].trim().toUpperCase();

         return keys.join(" + ");
      }

   }
}());

