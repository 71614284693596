/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   import IInjectorService = angular.auto.IInjectorService;
   import IPromise = angular.IPromise;

   /***
    * The class wraps the ng-next-app/platform/services/vc-proxy.service
    */
   export class VcServiceWrapper {
      static $inject = ["$injector"];
      private _ng2VcService: VcServiceWrapper;

      constructor(private $injector: IInjectorService) {}

      get ng2VcService() {
         if (!this._ng2VcService) {
            this._ng2VcService = this.$injector.get("vcVcService");
         }
         return this._ng2VcService;
      }

      public isValidManagedEntityId(objectId: string): boolean {
         return this.ng2VcService.isValidManagedEntityId(objectId);
      }

      public is51VcOrLater(objectId: string): boolean {
         return this.ng2VcService.is51VcOrLater(objectId);
      }

      public is65VcOrLater(objectId: string): boolean {
         return this.ng2VcService.is65VcOrLater(objectId);
      }

      public is66VcOrLater(objectId: string): boolean {
         return this.ng2VcService.is66VcOrLater(objectId);
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("vcService", VcServiceWrapper);
}
