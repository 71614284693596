/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   import IInjectorService = angular.auto.IInjectorService;

   export class BrowserLanguageServiceWrapper {
      static $inject = ['$injector'];
      private _vcBrowserLanguageService: BrowserLanguageServiceWrapper;

      constructor(private $injector: IInjectorService) {
      }

      get vcBrowserLanguageService(): BrowserLanguageServiceWrapper {
         if (!this._vcBrowserLanguageService) {
            this._vcBrowserLanguageService = this.$injector.get('vcBrowserLanguageService');
         }
         return this._vcBrowserLanguageService;
      }

      get() {
         return this.vcBrowserLanguageService.get();
      }
   }

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.browserLanguage
    * @module com.vmware.platform.ui
    *
    * @description
    *    Browser language Service.
    */
   angular.module('com.vmware.platform.ui').service('browserLanguage', BrowserLanguageServiceWrapper);
}
