/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   /**
    * Service to retrieve triggered alarms.
    */
   angular.module('com.vmware.platform.ui').factory('alarmService', ['$http', '$q', 'i18nService', 'dataService', 'defaultUriSchemeUtil', 'logService',
   function ($http, $q, i18nService, dataService, defaultUriSchemeUtil, logService) {
      // Endpoint to get all alarms from
      var ALARMS_ENDPOINT = 'alarms';
      // Endpoint to get alarms per selected object from
      var ENTITY_ALARMS_ENDPOINT = 'entityAlarms';

      var log = logService('alarmService');

      return {
         getAlarms: getAlarms,
         getStatusIconClass: getStatusIconClass,
         getAcknowledgedStatusIconClass: getAcknowledgedStatusIconClass,
         getSeverity: getSeverity,
         getAcknowledgedStatus: getAcknowledgedStatus
      };

      /**
       * Retrieve triggered alarms.
       *
       * @param count
       *    Number of alarms to return.
       */
      function getAlarms(objectId, count) {
         var alarmsEndpoint = (!!objectId) ? ENTITY_ALARMS_ENDPOINT : ALARMS_ENDPOINT;

         var promise = $http({
            method: 'GET',
            url: alarmsEndpoint,
            params: {
               objectId: objectId,
               count: count
            }
         }).then(function (response) {
            if (!response) {
               var reason = 'undefined response to ' + alarmsEndpoint;
               log.error(reason);
               return $q.reject(reason);
            }
            return response.data;
         });
         return promise;
      }

      /**
       * Given the status, returns the name of iconClass to use
       *
       * @param status
       *    the given status
       * @returns {string}
       *    the name of iconClass to use
       */
      function getStatusIconClass(status) {
         var iconClass;
         switch(status) {
            case 'green':
               iconClass = 'vsphere-icon-status-ok';
               break;
            case 'yellow':
               iconClass = 'vsphere-icon-status-warning';
               break;
            case 'red':
               iconClass = 'vsphere-icon-status-error';
               break;
            default :
               iconClass = 'vui-icon-datagrid-status-unknown';
         }

         return iconClass;
      }

      /**
       * Given the alarm acknowledged status, returns the name of iconClass to use
       *
       * @param isAcknowledged
       *    the given status
       * @returns {string}
       *    the name of iconClass to use
       */
      function getAcknowledgedStatusIconClass(isAcknowledged) {
         return isAcknowledged ? 'vx-icon-acknowledgedAlarm' : '';
      }

      /**
       * Given the status, returns a severity level
       *
       * @param status
       *    the given status
       * @returns {string}
       *    the name severity level for the given status
       */
      function getSeverity(status) {
         var severity;
         switch (status) {
            case 'red':
               severity = i18nService.getString('Common',
                     'alarmsView.tableContentText.severity.critical');
               break;
            case 'yellow':
               severity = i18nService.getString('Common',
                     'alarmsView.tableContentText.severity.warning');
               break;
            default:
            case 'green':
               severity = i18nService.getString('Common',
                     'alarmsView.tableContentText.severity.green');
               break;
         }

         return severity;
      }

      /**
       * Parse the "acknowledged" and "acknowledgedByUser" fields to determine the acknowledged Status of an alarm
       *
       * @param alarmState
       *    the alarmState object describing an alarm
       * @returns {string}
       *    the acknowledged status of an alarm
       */
      function getAcknowledgedStatus(alarmState) {
         var acknowledgedStatus = alarmState.acknowledged ?
            i18nService.getString('AlarmsUi', 'AlarmDetailsView.acknowledged', alarmState.acknowledgedByUser) :
            i18nService.getString('AlarmsUi', 'AlarmDetailsView.new');

         return acknowledgedStatus;
      }
   }]);

})();
