namespace platform {

   import IInjectorService = angular.auto.IInjectorService;

   /**
    * Wrapper over downgraded service \vim-clients\applications\h5\ng-next-app\src\app\platform\services\format\number-formatter.service.ts
    */
   export class NumberFormatterServiceWrapper {

      static $inject = ['$injector'];

      constructor(private $injector: IInjectorService) {
      }

      private _downgradedNumberFormatterService: NumberFormatterServiceWrapper;

      get downgradedNumberFormatterService(): NumberFormatterServiceWrapper {
         if (!this._downgradedNumberFormatterService) {
            this._downgradedNumberFormatterService = this.$injector.get('vcNumberFormatterService');
         }
         return this._downgradedNumberFormatterService;
      }

      public format(num: number, options?: any /*NumberOptions*/): string {
         return this.downgradedNumberFormatterService.format(num, options);
      }

      public formatStorage(num: number,
                    sourceUnit: any,
                    targetUnit: any,
                    fractionSize: number): string {
         return this.downgradedNumberFormatterService.formatStorage(num, sourceUnit, targetUnit, fractionSize);
      }

      public formatBandwidth(num: number,
            sourceUnit: any,
            targetUnit: any,
            precision: number,
            trimTrailingZeroes: boolean): string {
         return this.downgradedNumberFormatterService.formatBandwidth(num, sourceUnit, targetUnit, precision, trimTrailingZeroes);
      }
   }

   angular.module('com.vmware.platform.ui')
         .service('numberFormatterService', NumberFormatterServiceWrapper);
}