/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').component('recentTasksView', {
      templateUrl: 'resources/ui/views/tasks/RecentTasksView.html',
      controller: RecentTasksViewController
   });

   angular
         .module('com.vmware.platform.ui')
         .component('recentTasksViewWrapper', {
            template: '<recent-tasks-view></recent-tasks-view>',
            controller: function() {
            }
         });

   RecentTasksViewController.$inject = [
      '$rootScope',
      'i18nService',
      'taskConstants',
      'recentTasksStoreService',
      'taskFilterService',
      '$element',
      '$scope',
      'recentTasksColumnsService',
      'telemetryHelperService'
   ];

   function RecentTasksViewController($rootScope,
         i18nService,
         taskConstants,
         recentTasksStoreService,
         taskFilterService,
         $element,
         $scope,
         recentTasksColumnsService,
         telemetryHelperService) {

      var self = this;

      activate();

      function activate() {
         self.i18n = i18nService.getString;
         self.taskConstants = taskConstants;
         self.taskStatuses = [
            {
               id: taskConstants.status.ALL,
               title: i18nService.getString('Common', 'allTasksLabel')
            },
            {
               id: taskConstants.status.RUNNING,
               title: i18nService.getString('Common', 'runningTasksLabel')
            },
            {
               id: taskConstants.status.ERROR,
               title: i18nService.getString('Common', 'failedTasksLabel')
            }
         ];
         self.moreTasksLabel = i18nService.getString('Common', 'allTasks');

         var persistCallback = recentTasksColumnsService.persistColumnsState($scope);
         recentTasksColumnsService.fetchColumnsData().then(function(columnsData) {
            const columnDefs = columnsData.columnDefs;

            self.datagridOptions = {
               columns: columnDefs,
               dataSource: {
                  pageSize: 50
               },
               dataBound: function(event) {
                  var currentSort = self.getGridSort();
                  if (currentSort && !_.isEqual(currentSort, self.lastSort)) {
                     self.lastSort = currentSort;
                     persistCallback(event);
                  }
               },
               scrollable: {
                  virtual: true
               },
               pageable: {
                  numeric: false,
                  previousNext: false
               },
               sortable: {
                  mode: 'single',
                  allowUnsort: true
               },
               columnMenu: {
                    sortable: false, // hides the sort menu items in the column menu
                    messages: {
                        columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                        filter: i18nService.getString('CommonUi', 'listview.filterMenu')
                    }
               },
               filterable: true,
               resizable: true,
               reorderable: true,
               columnShow: persistCallback,
               columnHide: persistCallback,
               columnReorder: persistCallback,
               columnResize: persistCallback,
               navigatable: true
            };

            self.grid = $element.find('.vui-datagrid').kendoGrid(self.datagridOptions);
            $element.find('.k-pager-wrap.k-grid-pager')
               .append('<vui-datagrid-footer-bar></vui-datagrid-footer-bar>');

            $(self.grid)
               .find("th.k-header.k-with-icon[role='columnheader']")
               .addClass(function () {
                  // Here 'this' is a reference to the DOM element of invocation.
                  var element = $(this);
                  var identifier = "recent-tasks-column-" + element.attr('data-field');
                  return telemetryHelperService.generateTelemetryIdClass(identifier);
               });

            self.grid.data('kendoGrid').bind('columnMenuInit', self.onColumnMenuInit);

            self.dataSource = self.grid.data('kendoGrid').dataSource;

            self.lastSort = columnsData.sort;
            self.setGridSort(self.lastSort);

            recentTasksStoreService.onPartialUpdate(self.onPartialUpdate);

            // Filters refers to the dropdown filter for recent tasks grid,
            // not the filters applied through the kendo grid
            loadFiltersAndData();
         });

         self.onPartialUpdate = function() {
            populateDatagrid();
         };

         self.refreshTaskGrid = function() {
            populateDatagrid();
            saveFiltersData();
         };

         self.navigateToTasks = function() {
            $rootScope._navigateToView('vsphere.core.tasks.domainView');
         };

         self.getGridFilters = function() {
            var datasource = getGridDatasource();
            return (datasource) ? datasource.filter() : null;
         };

         self.setGridFilters = function(filters) {
            var datasource = getGridDatasource();
            if (datasource) {
               datasource.filter(filters);
            }
         };

         self.getGridSort = function() {
            var datasource = getGridDatasource();
            return (datasource) ? datasource.sort() : null;
         };

         self.setGridSort = function(sortValues) {
            var datasource = getGridDatasource();
            if (datasource) {
               datasource.sort(sortValues);
            }
         };

         self.onColumnMenuInit = function (event) {
            var columnId = 'column-' + event.field;
            // Add telemetry IDs to the sort menu items.
            $(event.container)
               .find('li.k-item.k-sort-asc')
               .addClass(telemetryHelperService.generateTelemetryIdClass(
                  'recent-tasks-' + columnId + '-sort-asc'));
            $(event.container)
               .find('li.k-item.k-sort-desc')
               .addClass(telemetryHelperService.generateTelemetryIdClass(
                  'recent-tasks-' + columnId + '-sort-desc'));
            // Add telemetry IDs to the column checkboxes.
            $(event.container)
               .find('span.k-link > input[type="checkbox"]')
               .each(function (i, element) {
                  var dataField = $(element).attr('data-field');
                  var checkboxId = 'recent-tasks-' + dataField + '-checkbox';
                  $(element).closest('li.k-item').addClass(
                     telemetryHelperService.generateTelemetryIdClass(checkboxId));
               });
            // Add telementry IDs to the filter buttons.
            $(event.container)
               .find('form.k-filter-menu > div > div > button')
               .each(function (i, element) {
                  var type = element.type;
                  if (type === 'submit' || type === 'reset') {
                     var buttonId = 'recent-tasks-' + columnId + '-filter-' + type;
                     $(element).addClass(
                        telemetryHelperService.generateTelemetryIdClass(buttonId));
                  }
               });
         };
      }

      function populateDatagrid() {
         var currentFilters = self.getGridFilters();
         var currentSort = self.getGridSort();
         getGridDatasource().data(getTasksSortedByTimeDesc());

         self.setGridFilters(currentFilters);
         self.setGridSort(currentSort);
      }

      function getTasksSortedByTimeDesc() {
         if (!self.selectedTaskStatus) {
            return [];
         }

         var tasks = recentTasksStoreService.getTasks();
         tasks = taskFilterService.filterTasksByStatus(tasks, self.selectedTaskStatus);
         return _.sortBy(tasks, 'startTime').reverse();
      }

      function loadFiltersAndData() {
         taskFilterService.loadFiltersData()
               .then(function(userData) {
                  self.selectedTaskStatus = taskConstants.status.ALL;
                  if (userData && userData.length === 1 && userData[0].value) {
                     var persistedFilters = angular.fromJson(userData[0].value);
                     if (persistedFilters.selectedTaskStatus) {
                        self.selectedTaskStatus = persistedFilters.selectedTaskStatus;
                     }
                  }

                  refresh(populateDatagrid);
               });
      }

      $scope.$on('dataRefreshInvocationEvent', function() {
         refresh(populateDatagrid);
      });

      $scope.$on('$destroy', function () {
         if (self.grid) {
            var kendoGrid = self.grid.data('kendoGrid');
            if (kendoGrid) {
               kendoGrid.unbind('columnMenuInit');
               kendoGrid.destroy(); // destroy the grid
            }
         }
      });

      function refresh(callback) {
         return recentTasksColumnsService.renewUserTimeFormat().then(function() {
            if (!callback) {
               return;
            }

            callback();
         });
      }

      function saveFiltersData() {
         taskFilterService.saveFiltersData(self.selectedTaskStatus);
      }

      function getGridDatasource() {
         return self.dataSource;
      }
   }
})();
