/* Copyright 2017 Vmware, Inc. All rights reserved. -- Vmware Confidential */
namespace common_ui {

   import IStorageProfileData = common_ui.IStorageProfileData;
   import StorageSelectorDialogData = common_ui.StorageSelectorDialogData;
   import SpbmReplicationGroupInfoService = platform.SpbmReplicationGroupInfoService;

   export class StorageLocatorAdvancedModeApi {
      validateSelection:()=>string|null;
   }

   export class StorageLocatorAdvancedMode {
      public template: string;
      public controller: any;
      public bindings: any;

      constructor() {
         this.template = `<div vui-datagrid="$ctrl.datagridOptions" class="flex-grid" ng-if="$ctrl.datagridOptions"></div>`;
         this.controller = StorageLocatorAdvancedModeController;
         this.bindings = {
            onSelectionChanged: "&",
            vmStorageConfig: "<",
            storageProfilesData: "<?",
            diskFormatSettings: "<?",
            storageLocatorItemsData: "<",
            recentlySelectedStorageItems: "<",
            controlApi: "<",
            podDisplayDisabled: "<?",
            disableDefaultPreselection: "<",
            showReplicationGroups: "<?",
            isEncryptionOptionsDisabled: "<?",
            isVmtxWorkflow: "<?",
            isDiskGroupsAvailable: "<?"
         };
      }
   } // class

   class StorageLocatorAdvancedModeController {

      public static readonly VVOL_DS_TYPE = "VVOL";
      public static readonly SYSTEM_DISK_GROUP_KEY = "ngcArtificialSystemDiskGroup";

      public onSelectionChanged: () => void;
      public storageProfilesData: IStorageProfileData;
      public diskFormatSettings: StorageSelectorDiskFormatSettings;
      public vmStorageConfig: VmStorageConfig[];
      public storageLocatorItemsData: any;
      public controlApi: StorageLocatorAdvancedModeApi;
      public isEncryptionOptionsDisabled: boolean;
      private podDisplayDisabled: boolean;
      private disableDefaultPreselection: boolean;
      private showReplicationGroups: boolean;
      private isVmtxWorkflow: boolean;
      private isDiskGroupsAvailable: boolean;

      public recentlySelectedStorageItems: any[];

      private originalStorageItems: { [key: string]: any} = {};

      public datagridOptions: any;

      public static $inject = [
            "i18nService",
            "diskFormatService",
            "defaultUriSchemeUtil",
            "$q",
            "storageSelectorService",
            "spbmReplicationGroupInfoService",
            "$element",
            "$timeout",
            "storageProfileService"];

      constructor(
            private i18nService: any,
            private diskFormatService: any,
            private defaultUriSchemeUtil: any,
            private $q: any,
            private storageSelectorService: StorageSelectorService,
            private spbmReplicationGroupInfoService: SpbmReplicationGroupInfoService,
            private $element: any,
            private $timeout: any,
            private storageProfileService: any) {
      }

      public $onInit() {

         this.recentlySelectedStorageItems = this.recentlySelectedStorageItems || [];

         if (this.controlApi) {
            this.controlApi.validateSelection = this.validateSelection.bind(this);
         }

         this.buildDatagridOptions();
      }

      public validateSelection():string|null {

         let hasMissingStorage = !!_.find(this.vmStorageConfig, (vmConfig: VmStorageConfig) => {
            if (!vmConfig.vmHome.storageObj) {
               return true;
            }

            return !!_.find(vmConfig.vmDisks, (vmDisk:VmComponentStorageConfig) => !vmDisk.storageObj);
         });

         if (hasMissingStorage) {
            return this.i18nService.getString("Common", "storageSelector.incompleteSelection");
         }
         return null;
      }

      private getAvailableDiskFormatsForStorageObj(storageObj:any):any[] {
         let datastoreType;
         let vStorageSupported;

         if (storageObj.storageRef.type !== "Datastore") {
            let storagePodId = this.defaultUriSchemeUtil.getVsphereObjectId(storageObj.storageRef);
            let datastoreObj:any = _.find(this.storageLocatorItemsData.datastoreItems, (dsItem:any) => {
               return dsItem.parentStoragePod &&
                           this.defaultUriSchemeUtil.getVsphereObjectId(dsItem.parentStoragePod) === storagePodId;
            });
            if (!datastoreObj) {
               return [];
            }

            datastoreType = datastoreObj.type;
            vStorageSupported = datastoreObj.vStorageSupported;
         } else {
            datastoreType = storageObj.type;
            vStorageSupported = storageObj.vStorageSupported;
         }

         return this.diskFormatService.getAvailableDiskFormats(
               datastoreType,
               vStorageSupported,
               this.diskFormatSettings.sameAsSourceSupported,
               this.diskFormatSettings.thickDiskFormatSupported);
      }

      /**
       * Suggests a storage profile for a vm vmx/disk. Typically, there is no need to
       * change the storage profile when new storage is selected. However, sometimes
       * the current profile is not applicable for the selected storage and a new one
       * needs to be selected. Such is the case with PMEM profiles which are not
       * applicable to any storage other than the PMEM storage. In this case we suggest
       * that the default storage profile is to be used.
       *
       * @param vmComponentConfig - The storage component fow which to suggest
       *    a new profile
       * @returns {any} - Storage profile to be used with the storage.
       */
      private suggestProfileForVmComponent(
            vmComponentConfig: VmComponentStorageConfig): any {
         if (this.storageProfileService
               .isPmemStorageProfile(vmComponentConfig.storageProfile)) {
            return this.storageProfileService.getDefaultProfile();
         }
         return vmComponentConfig.storageProfile;
      }

      private triggerOnSelectionChange() {
         if (this.onSelectionChanged) {
            this.onSelectionChanged();
         }
      }

      private saveOriginalStorageItems() {
         this.originalStorageItems = {};
         _.forEach(this.vmStorageConfig, (vmConfig: VmStorageConfig) => {
            this.originalStorageItems[ vmConfig.vmId ] = vmConfig.vmHome.storageObj;
            _.forEach(vmConfig.vmDisks, (vmDisk:VmComponentStorageConfig) => {
               this.originalStorageItems[ vmConfig.vmId + ":" + vmDisk.key] = vmDisk.storageObj;
            });
         });
      }

      private buildDatagridOptions() {
         this.saveOriginalStorageItems();
         this.datagridOptions = {
            height: "100%",
            resizable: true,
            reorderable: true,
            data: this.buildStorageLocatorItems(),
            columnDefs: this.getColumnDefs(),
            searchable: false,
            selectedItems: []
         };
      }

      private buildStorageLocatorItems() {
         let items:any[] = [];
         _.forEach(this.vmStorageConfig, (vmConfig: any) => {
            if (vmConfig.vmHome && !this.isDiskGroupsAvailable) {
               items.push(this.buildStorageLocatorItem(vmConfig, true, vmConfig.vmHome));
            }
            _.forEach(vmConfig.vmDisks, (diskComponent: VmComponentStorageConfig) => {
               items.push(this.buildStorageLocatorItem(vmConfig, false, diskComponent));
            });
         });

         return items;
      }

      private buildStorageLocatorItem(vmConfig: VmStorageConfig, isVmHome: boolean,
            vmComponent:VmComponentStorageConfig):any {

         let result = new StorageLocatorItem();
         result.uniqueId = (isVmHome ? vmConfig.vmId : vmConfig.vmId + vmComponent.key)
               .replace(/\:/g, "_");
         result._isVmHome = isVmHome;
         result.vmName = vmConfig.vmName;
         result._vmComponent = vmComponent;
         result._destinationHost = vmConfig.destinationHostId;
         result.getAvailableDiskFormatsForStorageObj = this.getAvailableDiskFormatsForStorageObj.bind(this);
         result.suggestProfileForVmComponent = this.suggestProfileForVmComponent.bind(this);

         result.onSelectionChange = this.triggerOnSelectionChange.bind(this);
         result.i18nService = this.i18nService;
         result.spbmReplicationGroupInfoService = this.spbmReplicationGroupInfoService;
         result.browseForStorageObj = this.browseForStorageObj.bind(this, isVmHome);
         result.onRecentItemsChange = this.onRecentItemsChange.bind(this);
         result.storageLocatorItemsData = this.storageLocatorItemsData;
         result._recentlySelectedStorageItems = this.recentlySelectedStorageItems;

         result.originalStorageItem = isVmHome
               ? this.originalStorageItems[vmConfig.vmId]
               : this.originalStorageItems[vmConfig.vmId + ":" + vmComponent.key];
         result.init();
         result.diskGroupName = vmComponent.diskGroupName;

         return result;
      }

      private onRecentItemsChange(itemIdToSelect: string): void {
         _.forEach(this.datagridOptions.data, (item: StorageLocatorItem) => item.updateStorageItems());

         // This seems to be the only way to refresh the grid.
         this.datagridOptions.data = this.buildStorageLocatorItems();

         if (itemIdToSelect) {
            this.$timeout(() => {
               let matchingItems = this.$element.find("#" + itemIdToSelect);
               if (matchingItems && matchingItems[0]) {
                  matchingItems[0].focus();
               }
            }, 1);
         }
      }

      private browseForStorageObj(
            isVmHome: boolean, vmComponent: VmComponentStorageConfig, destinationHost: string): any {

         let initialSelection = new StorageSelectorDialogData();
         if (vmComponent.storageObj && !vmComponent.storageObj.parentStoragePod) {
            initialSelection.storageObj = vmComponent.storageObj;
         }
         initialSelection.storageProfile = vmComponent.storageProfile;
         initialSelection.replicationGroup = vmComponent.replicationGroup;

         const isPmemOptionDisabled: boolean = this.isVmComponentASystemDiskGroup(vmComponent);

         return this.storageSelectorService.browseForStorage(
               this.storageLocatorItemsData,
               this.storageProfilesData,
               initialSelection,
               this.podDisplayDisabled,
               this.disableDefaultPreselection,
               destinationHost,
               this.showReplicationGroups,
               this.isEncryptionOptionsDisabled,
               isVmHome,
               isPmemOptionDisabled);
      }

      private getColumnDefs() {

         let browseOptionLabel = this.i18nService.getString("Common", "DiskLocatorControl.Browse");

         const showColumnsForDiskGroups: boolean = this.isDiskGroupsAvailable ? this.isDiskGroupsAvailable : false;
         const showColumnsForVmtx: boolean = this.isVmtxWorkflow ? this.isVmtxWorkflow : false;

         let columnDefs = [
            {
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.Vm"),
               width: 180,
               field: "vmName",
               type: "string",
               sortable: true,
               visible: !showColumnsForDiskGroups,
            },
            {
               displayName: this.i18nService.getString("ProvisioningUiLib", "DiskGroupLocator.diskGroup"),
               width: 180,
               field: "diskGroupName",
               type: "string",
               sortable: true,
               visible: showColumnsForDiskGroups,
            },
            {
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.File"),
               width: 180,
               field: "fileName",
               type: "string",
               sortable: true,
               visible: !showColumnsForDiskGroups,
            },
            {
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.Storage"),
               width: 180,
               template: function(dataItem: StorageLocatorItem) {
                  return `
                        <div class="select">
                          <select id="{{dataItem.uniqueId}}-storage-selector"
                                  aria-label="{{dataItem.i18nService.getString('Common', 'DiskLocatorControl.Storage')}}"
                                  ng-model="dataItem.storageItemId"
                                  ng-change="dataItem.setStorageItemId(dataItem.storageItemId)"
                                  ng-click="dataItem.onDatastoreComboClick($event)"
                                  ng-options="option.id as option.label for option in dataItem.storageItems">
                               <option value="" disabled style="display: none; visibility:hidden">${browseOptionLabel}</option>
                           </select>
                        </div>`;
               }
            },
            {
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.DiskFormat"),
               width: 200,
               visible: !showColumnsForVmtx,
               template: function(dataItem: StorageLocatorItem) {
                  return `
                        <div class="select" >
                          <select ng-model="dataItem.diskFormat"
                                  aria-label="{{dataItem.i18nService.getString('Common', 'DiskLocatorControl.DiskFormat')}}"
                                  ng-change="dataItem.updateDiskFormatVmComponent(dataItem.diskFormat)"
                                  ng-options="diskFormat as diskFormat.name for diskFormat in dataItem.availableDiskFormats track by diskFormat.type"
                                  ng-disabled="!dataItem.isDiskFormatEnabled">
                        </div>`;
               }
            },
            {
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.VmStorageProfile"),
               width: 200,
               field: "storageProfileLabel",
               type: "string",
               visible: !showColumnsForVmtx,
               template: function(dataItem: StorageLocatorItem) {
                  return '<div>{{dataItem.storageProfileLabel}}</div>';
               },
               sortable: true
            }
         ];

         if (this.showReplicationGroups && !showColumnsForDiskGroups && !showColumnsForVmtx) {
            columnDefs.push({
               displayName: this.i18nService.getString("Common", "DiskLocatorControl.ReplicationGroup"),
               width: 200,
               field: "replicationGroupLabel",
               type: "string",
               template: function(dataItem: StorageLocatorItem) {
                  return "<span>{{dataItem.replicationGroupLabel}}</span>";
               },
               sortable: true
            });
         }

         return columnDefs;
      }

      private isVmComponentASystemDiskGroup (vmComponent: VmComponentStorageConfig): boolean {
         return vmComponent &&
               (vmComponent.key === StorageLocatorAdvancedModeController.SYSTEM_DISK_GROUP_KEY);
      }
   }

   class StorageLocatorItem {

      private readonly MAX_STORAGE_ITEMS_OPTIONS = 10;

      private readonly BROWSER_OPTION_ID = "browse";

      private readonly NO_SELECTION_OPTION_ID = null;

      public vmName: string;

      public diskGroupName: string;

      public _isVmHome: boolean;

      public uniqueId: string;

      /**
       * Contains the raw data model.
       */
      public _vmComponent: VmComponentStorageConfig;

      public _destinationHost: string;

      /**
       * Callback which retrieves available disk formats supported from a given datastore.
       */
      public getAvailableDiskFormatsForStorageObj: (storageObj:any) => any[];

      /**
       * Callback which suggests profile given the storage and the currently selected
       * profile
       */
      public suggestProfileForVmComponent:
            (vmComponentConfig: VmComponentStorageConfig) => any;

      /**
       * Callback which will be triggered when the selected storage, profile or disk format is changed.
       */
      public onSelectionChange: () => void;

      /**
       * Callback which will be triggered when a new items is added to the recent items collection.
       */
      public onRecentItemsChange: (itemIdToSelect: string) => void;

      /**
       * Callback which opens the browse for storage dialog. Should return a promise with StorageSelectorDialogData
       */
      public browseForStorageObj: (vmComponent: VmComponentStorageConfig, destinationHost: string) => any;

      /**
       * Reference to the i18nService
       */
      public i18nService: any;

      /**
       * Reference to the SpbmReplicationGroupInfoService
       */
      public spbmReplicationGroupInfoService: SpbmReplicationGroupInfoService;

      /**
       * Holds all available datastore and StoragePOD items.
       */
      public storageLocatorItemsData: any;

      /**
       * Array holding all recently browsed storage items.
       * It is shared between all items.
       */
      public _recentlySelectedStorageItems: any[];

      /**
       * Holds original storage item.
       */
      public originalStorageItem: any;

      /**
       * The id of the currently selected storage item option. Can be either the BROWSER_OPTION_ID or
       * a valid datastore/storagePOD object id.
       */
      public storageItemId: string;

      /**
       * The name
       * @returns {string}
       */
      public get fileName(): string {
         return this._vmComponent.name;
      }

      public init() {
         this.storageItemId = this._vmComponent.storageObj
               ? this._vmComponent.storageObj.storageRef.value
               : this.NO_SELECTION_OPTION_ID;
         this.updateStorageItems();
         this.updateAvailableDiskFormats();
         this.updateStorageProfileLabel();
         this.updateReplicationGroupLabel();
      }

      public storageProfileLabel: string;

      private updateStorageProfileLabel() {
         if (this._vmComponent.storageProfile) {
            this.storageProfileLabel = this._vmComponent.storageProfile.label;
         } else {
            this.storageProfileLabel = "";
         }
      }

      public replicationGroupLabel: string;

      private updateReplicationGroupLabel(): void {
         if (this._vmComponent.replicationGroup) {
            this.replicationGroupLabel =
                  this.spbmReplicationGroupInfoService.getReplicationGroupName(
                        this._vmComponent.replicationGroup);
         } else {
            this.replicationGroupLabel = "";
         }
      }

      /**
       * Currently selected item in disk format combo.
       */
      public diskFormat: any;

      /**
       * Update the disk format in the data model.
       */
      public updateDiskFormatVmComponent(diskFormat: any) {
         // Check if this is the N/A item, if it's
         // set the null as current diskFormat.
         if (diskFormat && !diskFormat.type) {
            diskFormat = null;
         }

         if (diskFormat !== this._vmComponent.diskFormat) {
            this._vmComponent.diskFormat = diskFormat
                  ? {
                     name: diskFormat.name,
                     type: diskFormat.type
                  }
                  : null;

            this.onSelectionChange();
         }
      }

      /**
       * Contains all disk format combo items.
       */
      public availableDiskFormats: any[];

      /**
       * Flag indicating whether the disk format combo should be enabled.
       */
      public isDiskFormatEnabled: boolean;

      private updateAvailableDiskFormats() {
         if (this._isVmHome || !this._vmComponent.storageObj) {
            this.availableDiskFormats = [];
         } else {
            this.availableDiskFormats = this.getAvailableDiskFormatsForStorageObj(this._vmComponent.storageObj);
         }

         if (!this.availableDiskFormats || this.availableDiskFormats.length === 0) {
            this.availableDiskFormats = [{
               type: null,
               name: this.i18nService.getString("Common", "DiskLocatorControl.na")
            }];
         }

         if (!this._vmComponent.diskFormat) {
            this.diskFormat = this.availableDiskFormats[0];
         } else {
            if (_.find(this.availableDiskFormats, (diskFormat: any) => diskFormat.type === this._vmComponent.diskFormat.type)) {
               this.diskFormat = this._vmComponent.diskFormat;
            } else {
               this.diskFormat = this.availableDiskFormats[0];
            }
         }

         this.isDiskFormatEnabled = !this._isVmHome && this.availableDiskFormats.length > 1;
         this.updateDiskFormatVmComponent(this.diskFormat);
      }

      private changeStorageSelection(storageObj) {
         this._vmComponent.storageObj = storageObj;
         if (storageObj) {
            this.storageItemId = storageObj.storageRef.value;
            this.updateAvailableDiskFormats();
         }

         this.onSelectionChange();
      }

      /**
       * Reset the last selected item in the storage combo.
       */
      private restoreLastStorageSelection() {
         if (this._vmComponent.storageObj) {
            this.storageItemId = this._vmComponent.storageObj.storageRef.value;
         } else {
            this.storageItemId = this.NO_SELECTION_OPTION_ID;
         }
      }

      /**
       * Checks if the only item in the storage combo is the "browse" option.
       * If this is the case opens the browse storage dialog.
       */
      public onDatastoreComboClick(event: any) {
         if (this.storageItems && this.storageItems.length === 1) {
            if (event && event.target && event.target.blur) {
               // hide the dropdown's pop-up
               event.target.blur();
            }
            this.setStorageItemId(this.storageItemId);
         }
      }

      private addNewRecentItem(newItem: any): boolean {
         if (newItem && newItem.type === StorageLocatorAdvancedModeController.VVOL_DS_TYPE) {
            // The only way to select a VVOL datastore should be via the "Browse..." option.
            return false;
         }
         if (newItem && !_.find(this._recentlySelectedStorageItems, (item: any) => {
                  return item.storageRef.value === newItem.storageRef.value;
               })) {
            this._recentlySelectedStorageItems.unshift(newItem);
            if (this._recentlySelectedStorageItems.length > this.MAX_STORAGE_ITEMS_OPTIONS) {
               this._recentlySelectedStorageItems.pop();
            }

            return true;
         }
         return false;
      }

      /**
       * Handles selection changed events in the storage items dropdown.
       * @param selectedItemId
       */
      public setStorageItemId(selectedItemId: any) {
         if (selectedItemId === this.BROWSER_OPTION_ID || selectedItemId === this.NO_SELECTION_OPTION_ID) {
            this.browseForStorageObj(this._vmComponent, this._destinationHost).then( (storageSelectorDialogData:StorageSelectorDialogData) => {
                     if (!storageSelectorDialogData) {
                        // when there is no selection revert to previous selection (if any)
                        this.restoreLastStorageSelection();
                     } else {
                        this._vmComponent.storageProfile = storageSelectorDialogData.storageProfile;
                        this.updateStorageProfileLabel();

                        this._vmComponent.replicationGroup = storageSelectorDialogData.replicationGroup;
                        this.updateReplicationGroupLabel();

                        this.changeStorageSelection(storageSelectorDialogData.storageObj);
                        this.updateStorageItems();

                        if (this.addNewRecentItem(storageSelectorDialogData.storageObj)) {
                           this.onRecentItemsChange(this.uniqueId + "-storage-selector");
                        }
                     }
                  },
                  () => {
                     // in case of error replace browse with the last selection without
                     // triggering selection change events.
                     this.restoreLastStorageSelection();
                  });
         } else {
            // Find the actual storage object by its id
            let storageObj = _.find(this.storageLocatorItemsData.datastoreItems, function(dsItem: any) {
               return dsItem.storageRef.value === selectedItemId;
            });

            if (!storageObj) {
               storageObj = _.find(this.storageLocatorItemsData.storagePodItems, function(podItem: any) {
                  return podItem.storageRef.value === selectedItemId;
               });
            }

            if (this._vmComponent.replicationGroup) {
               // reset replication group as it's no longer available for the
               // newly selected datastore
               this._vmComponent.replicationGroup = null;
               this.updateReplicationGroupLabel();
            }

            this._vmComponent.storageProfile =
                  this.suggestProfileForVmComponent(this._vmComponent);
            this.updateStorageProfileLabel();

            this.changeStorageSelection(storageObj);
            this.updateStorageItems();
         }
      }

      public storageItems: any;

      private buildStorageItemOption(storageObj: any) {
         return {
            id: storageObj.storageRef.value,
            label: storageObj.name
         };
      }

      private addStorageItemOptionToList(items: any[], itemToAdd: any) {
         if (items.length < this.MAX_STORAGE_ITEMS_OPTIONS &&
               !_.find(items, (item:any) => item.id === itemToAdd.id)) {
            items.push(itemToAdd);
         }
      }

      public updateStorageItems() {
         let items: any[] = [];

         if (this._vmComponent.storageObj) {
            this.addStorageItemOptionToList(items, this.buildStorageItemOption(this._vmComponent.storageObj));
         }

         // The only way to select a VVOL datastore should be via the "Browse..." option.
         if (this.originalStorageItem && this.originalStorageItem.type !==
               StorageLocatorAdvancedModeController.VVOL_DS_TYPE) {
            this.addStorageItemOptionToList(items, this.buildStorageItemOption(this.originalStorageItem));
         }

         _.forEach(this._recentlySelectedStorageItems,
               (recentItem: any) => this.addStorageItemOptionToList(items, this.buildStorageItemOption(recentItem)));

         items = _.sortBy(items, "label");
         items.push({
            id: this.BROWSER_OPTION_ID,
            label: this.i18nService.getString("Common", "DiskLocatorControl.Browse")
         });

         this.storageItems = items;
      }
   }

   angular.module("com.vmware.platform.ui").component(
         "storageLocatorAdvancedMode", new StorageLocatorAdvancedMode());
}
