namespace common_ui {

   export class StorageSelectorDiskFormatSettings {
      diskFormatSupported: boolean;

      sameAsSourceSupported: boolean;

      thickDiskFormatSupported: boolean;
      drsPlacement: boolean;
   }
}
