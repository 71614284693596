/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * LiveRefresh update types for the inventory tree (destination "/topic/navTree"), same as NavTreeUpdate.java
 */
angular.module('com.vmware.platform.ui').constant('treeUpdatesConstants', {
        PROPERTIES_UPDATE: 0,
        CHILDREN_UPDATE: 1,
        PARENT_UPDATE: 2,
        DELETE_OBJECT: 3
});