angular.module('com.vmware.platform.ui').directive('vxResourceMeter', function () {
   return {
      restrict: 'E',
      replace: true,
      scope: {
         percentage: '=',
         orientation: '@'
      },
      templateUrl: 'resources/ui/components/meter/vxResourceMeter.html'
   };
});