/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * This directive compiles a template string and inserts it in the DOM.
 * It helps reuse column renderers.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxTemplate', vxTemplate);

   vxTemplate.$inject = ['$compile'];

   function vxTemplate($compile) {
      return {
         restrict: 'A',
         link: function(scope, element, attrs) {
            attrs.$observe('vxTemplate', function(template) {
               if (template) {
                  // compile the provided template against the current scope
                  var contentElement = $compile(template)(scope);

                  element.empty();
                  // add the template content
                  element.append(contentElement);
               }
            });
         }
      };
   }
})();