/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Expands all panes in a splitter if a watched value is
 * set from false to true. Unregisters the watcher
 * after that.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
         .directive('vxVuiSplitterExpandIf', vxVuiSplitterExpandIf);

   function vxVuiSplitterExpandIf() {

      return {
         link: link
      };

      function link(scope, element, attrs) {
         var removeWatch = scope.$watch(attrs.vxVuiSplitterExpandIf, function (newValue, oldValue) {
            if (!oldValue && newValue) {
               var splitter = element.find('[kendo-splitter]').data('kendoSplitter');
               var panes = splitter.element.find(".k-pane");

               if (panes) {
                  panes.each(function (index, pane) {
                     splitter.expand(pane);
                  });
               }
               removeWatch();
            }
         });
      }
   }
})();