/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Intercepts every http request/response and registers it with the performanceService.
 */
angular.module('com.vmware.platform.ui').config(["$httpProvider", function($httpProvider) {
   $httpProvider.interceptors.push(["$q", function($q) {
      return {
         'request': function(config) {
            performanceService.httpStart(config);
            return config || $q.when(config);
         },

         'requestError': function(rejection) {
            performanceService.httpStop(rejection.config);
            return $q.reject(rejection);
         },

         'response': function(response) {
            performanceService.httpStop(response.config);
            return response || $q.when(response);
         },

         'responseError': function(rejection) {
            performanceService.httpStop(rejection.config);
            return $q.reject(rejection);
         }
      };
   }]);
}]);