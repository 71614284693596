/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive to create a data-driven settings view with Grid view inside it.
 * It renders a grid showing key/values for the propertyName passed in the scope for an object with identifier passed in objectId.
 * It also does refresh of the values - manual refresh and auto-refresh.
 * This directive takes a isolated scope which should contain something like:
 *    {
 *       objectId : ObjectId of object whose settings is being rendered.
 *       propertyName: Name of the property to be queried for the object.
 *       delayRefreshTimeInMsecsOnObjectUpdate: Optional Time in MSecs to refresh data after a object change is detected. Use this only when you find inconsistencies
 *       where backend VC server does not change the object properties before returning.
 *    }
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxGridviewSettings', gridviewSettings);

   function gridviewSettings() {
      return {
         restrict: 'EA',
         replace: true,
         templateUrl: 'resources/ui/views/settings/vxGridviewSettings.html',
         scope: {
            vxGridviewSettings: '=' // two-way scope for objectId , propertyName, delayRefreshTimeInMsecsOnObjectUpdate
         },
         controller: GridviewSettingsController,
         controllerAs: 'gridviewSettings',
         bindToController: true
      };
   }

   GridviewSettingsController.$inject = ['$scope', '$timeout', 'resourceService', 'vuiConstants',
      'i18nService', 'vxGridSettingsService'];
   function GridviewSettingsController($scope, $timeout, resourceService, vuiConstants,
      i18nService, vxGridSettingsService) {

      //-----View bindable logic--------//
      var self = this;

      self.$onInit = function () {
         self.objectId = this.vxGridviewSettings.objectId;
         self.propertyName = this.vxGridviewSettings.propertyName;
         var i18n = i18nService.getString;
         self.liveRefreshProperties = this.vxGridviewSettings.liveRefreshProperties;
         self.delayRefreshTimeInMsecs = this.vxGridviewSettings.delayRefreshTimeInMsecsOnObjectUpdate;
         self.watchForObjects = [self.objectId];
         self.getData = getData;

         self.riDatagridOptions = {
            columnDefs: [{
               displayName: i18n('ResourceFw', 'settingsGrid.header.key'),
               field: 'key',
               template: '#:key#',
               width: '240px'
            }, {
               displayName: i18n('ResourceFw', 'settingsGrid.header.value'),
               field: 'value',
               template: '#:value#',
               width: '240px'
            }, {
               displayName: i18n('ResourceFw', 'settingsGrid.header.summary'),
               field: 'description',
               template: '#:description#',
               width: '160px'
            }],
            resizable: true,
            data: [],
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            actionBarOptions: {
               actions: []
            },
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            height: '100%'
         };

         // get data on view load
         getData();

         if (this.vxGridviewSettings.setSelectedAdvancedOption) {
            $scope.$watch(function () {
                     return self.riDatagridOptions.selectedItems;
                  },
                  function (selectedItems) {
                     vxGridSettingsService.setSelectedSettings(selectedItems);
                  });
         }
      };
      //----- Service calls --------//

      /**
       *
       * @param propertyName
       */
      function getData() {
         return resourceService
               .getResourceInfo(self.objectId, self.propertyName)
               .then(function(data) {
                  // Convert to grid rows
                  self.riDatagridOptions.data = extractGridData(data);
                  return self.riDatagridOptions.data;
               });
      }

      //----- Private functions --------//

      /**
       *
       * @param data Data object retrieved from dataservice
       */
      function extractGridData(data) {
         var NO_CONTENT = '--';

         var returnValue = [];
         angular.forEach(
               data.properties,
               function(property) {
                  returnValue.push({
                     key: property.key,
                     value: property.value + "" || NO_CONTENT,
                     description: property.description || NO_CONTENT
                  });
               },
               returnValue
         );
         return returnValue;
      }
   }

})();
