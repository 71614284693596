/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Util service for high-level operations on scope
 */
angular.module('com.vmware.platform.ui').factory('scopeUtil', [
   function() {
      return {
         watchUntilTrue: function (scope, property, f) {
            var watcher = scope.$watch(property, function (value) {
               if (!value) {
                  return;
               }

               f(value);
               watcher();
            });
         }
      };
   }]);
