angular.module('com.vmware.platform.ui').directive('vxDatagridPreselectItem', [
   '$timeout', 'vuiConstants', '$log',
   function ($timeout, vuiConstants, $log) {
      return {
         link: function ($scope, element, attributes) {
            $scope.$on('kendoWidgetCreated', function (e, widget) {
               var isDestroyed = false;
               if (attributes.vxDatagridPreselectItem === 'false') {
                  return;
               }

               var grid = element.find('[kendo-grid]').data("kendoGrid");

               if(widget !== grid) {
                  return;
               }

               var ensurePreselectionVisibility = attributes.ensurePreselectionVisibility
                     && attributes.ensurePreselectionVisibility !== "false";

               if(ensurePreselectionVisibility) {
                  var animateToPreselected = true;
                  var currentPage = grid.dataSource.page();
               }

               function onGridPageChange () {
                  var newPage = grid.dataSource.page();
                  animateToPreselected = newPage === currentPage;
                  currentPage = newPage;
               }

               function selectRow() {
                  if(attributes.vxDatagridPreselectItem) {
                     selectSpecificRow();
                  } else {
                     selectFirstRow();
                  }
               }

               function selectFirstRow() {
                  grid.select(element.find(".k-selectable tr:first"));
               }

               function selectSpecificRow() {
                  if (attributes.preselectComparator) {
                     var cmp = null;
                     if (attributes.preselectComparator
                        && attributes.preselectComparator.indexOf('.') !== -1) {
                        //for nested scope values ($ctrl.anotherLevel.myPreSelectComparator)
                        // we need to perform lookups one after another
                        var nestedKeys = attributes.preselectComparator.split('.');
                        var current = $scope;
                        while (nestedKeys.length) {
                           var key = nestedKeys.shift();
                           current = current[key];
                        }
                        cmp = current;
                     } else {
                        cmp = $scope[attributes.preselectComparator];
                     }
                     if (!cmp) {
                        $log.warn('Pre-select comparator "' + attributes.preselectComparator + '" is not undefined!');
                        return;
                     }
                     grid.items().each(function() {
                        var data = grid.dataItem(this);
                        if (cmp(data)) {
                           if (grid.options.selectionMode === vuiConstants.grid.selectionMode.MULTI) {
                              var row = this;
                              // When grid is in multiple selection mode,
                              // trigger selection inside a timeout to avoid
                              // a digest loop.
                              $timeout(function() {
                                 grid.select(row);
                              }, 0);
                           } else {
                              grid.select(this);
                           }
                           var gridContent = $(this).closest('.k-grid-content');
                           gridContent[0].scrollTop = gridContent[0].scrollTop + $(this).position().top;
                        }
                     });
                  } else {
                     var selector = ".k-selectable tr:contains('" + attributes.vxDatagridPreselectItem + "')";
                     grid.select(element.find(selector));
                  }

                  if(ensurePreselectionVisibility && animateToPreselected) {
                     scrollToPreselection();
                  }
               }

               function scrollToPreselection() {
                  $timeout(function() {
                     if (!element) {
                        return;
                     }
                     var grid = element.find('div[kendo-grid]').data("kendoGrid");

                     if (!grid || !grid.select().length) {
                        return;
                     }

                     var vs = grid.wrapper.find('.k-grid-content').data('kendoVirtualScrollable');
                     if (!vs) {
                        return;
                     }

                     // The height from the beginig of the list to the begining of the selected item
                     var selectedItemRelativePosition = grid.select().position().top;
                     // The height from the begionign to the first visible item
                     var vsScrollTop = vs.verticalScrollbar.scrollTop();
                     // The height of the visible part of the grid
                     var gridInnerHeight = vs.verticalScrollbar.innerHeight();

                     var topVisiblePosition = vsScrollTop;
                     var bottomVisiblePosition = vsScrollTop + gridInnerHeight;
                     var positionToScrollTo = 0;

                     // The following logic should apply:
                     // If the current position of the selected element is withing the
                     // visible area of the grid - no need to scroll.
                     // If the element is not in visible position
                     // scroll it on the top of the list.
                     if(selectedItemRelativePosition <= topVisiblePosition) {
                        positionToScrollTo = selectedItemRelativePosition;
                     }
                     if(selectedItemRelativePosition > topVisiblePosition
                           && selectedItemRelativePosition < bottomVisiblePosition) {
                        positionToScrollTo = vsScrollTop;
                     }
                     if(bottomVisiblePosition <= selectedItemRelativePosition) {
                        positionToScrollTo = selectedItemRelativePosition;
                     }

                     vs.verticalScrollbar.animate({ scrollTop:  positionToScrollTo}, 400);
                  }, 0);
               }

               if (attributes.hasOwnProperty('reselectOnChange')) {
                  attributes.$observe('vxDatagridPreselectItem', function () {
                     selectSpecificRow();
                  });

                  $scope.$watch(attributes.vuiDatagrid + '.data', function (newData) {
                     if (newData) {
                        $timeout(function () {
                           if(!isDestroyed) {
                              selectSpecificRow();
                           }
                        }, 0);
                     }
                  });
                  $scope.$on('$destroy', function() {
                     isDestroyed = true;
                  });
               }

               if(grid.items().length > 0){
                  // we have the data, select it now
                  selectRow();
               } else {
                  // wait for data, then select the first row
                  grid.bind("dataBound", function() {
                     if(ensurePreselectionVisibility) {
                        onGridPageChange();
                     }
                     selectRow();
                  });
               }
            });
         }
      };
   }
]);
