namespace common_ui {

   export class StorageSelectorData {

      /**
       * Holds current user selection for datastores, profiles and disk format.
       */
      public vmStorageConfig: VmStorageConfig[];

      /**
       * Initial (source) VM storage configuration.
       */
      public sourceVmStorageConfig: VmStorageConfig[];

      /**
       * List of all available storage profiles.
       */
      public storageProfilesData: IStorageProfileData;

      /**
       * List of all available storage items.
       */
      public storageLocatorItemsData:any;

      /**
       * Initial configuration of the control. Will be used only the first time
       * the control is initialized. After that the control will be initialized
       * from the storageSelectorState.
       */
      public initialConfig:StorageSelectorInitialConfig;
   }

   export class StorageSelectorInitialConfig {

      /**
       * Initially selected item in basic mode. Will be used
       * only when the control is initialized for the first time.
       */
      public selectedItemInBasicMode:any;

      /**
       * Initially selected storage profile in basic mode. Will be used
       * only when the control is initialized for the first time.
       */
      public selectedStorageProfileInBasicMode:any;

      /**
       * Initially selected disk format in basic mode. Will be used
       * only when the control is initialized for the first time.
       */
      public selectedDiskFormatInBasicMode:any;

      /**
       * Initially selected replication group in basic mode. Will be used
       * only when the control is initialized for the first time.
       */
      public selectedReplicationGroupInBasicMode:any;
   }
}