/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {

    export class ScheduledTaskTimeService {

        static $inject = [
            "i18nService",
            "timeFormatterService"
        ];
        public i18n: any;
        constructor(private i18nService: any,
                    private timeFormatterService: any) {

            this.i18n = this.i18nService.getString;
        }


        private currentDate() {
            return new Date();
        }

        private getTime(recurrence: any) {
            let d = this.currentDate();
            return this.timeFormatterService.timestampToText(d.setUTCHours(recurrence.hour, recurrence.minute), null, {hour: "numeric",
                minute: "numeric"});
        }


        private getDay(recurrence: any) {
            let dayShift: any = this.computeLocalDayShift(recurrence.hour, recurrence.minute);
            let day: any = this.getAdjustedMonthDay(recurrence.day, dayShift);
            return day;
        }

        private getAdjustedMonthDay(day: any, dayShift: any) {
            // we compare the local day with the UTC day to see if we need to move
            // the day ahead or behind.
            var result: any = day + dayShift;

            // If the day becomes 31 we set it to the first day of the month
            if (result > 31) {
                result = 1;
            }

            // If the day becomes 0 we set it to the last day of the month
            // NOTE speev: The server scheduled task API treats 31 as the last day of the
            // month so the wrap-around below is a valid (and the only possible one).
            if (result < 1) {
                result = 31;
            }

            return result;
        }

        private getMinutes(recurrence: any) {
            let d = this.currentDate();
            return this.timeFormatterService.timestampToText(d.setUTCMinutes(recurrence.minute), null, {minute: "numeric"});
        }

        private computeLocalDayShift(hour: any, minutes: any) {
            let d = this.currentDate();
            d.setUTCHours(hour, minutes);

            if (d.getDate() === d.getUTCDate()) {
                return 0;
            }

            // if the utc time is lagging after the local time, you need to add to
            // it to get the local day.
            if (d.getTimezoneOffset() < 0 ) {
                return 1;
            }

            // If UTC is ahead of local time, then you need to substract from UTC to get
            // the local day.
            return -1;
        }

        private getAdjustedWeeklyScheduler(recurrence: any, offset: any) {
            let weekDays = {
                monday: undefined,
                tuesday: undefined,
                wednesday: undefined,
                thursday: undefined,
                friday: undefined,
                saturday: undefined,
                sunday: undefined
            };
            switch (offset) {
                case 0:
                    weekDays.monday = recurrence.monday;
                    weekDays.tuesday = recurrence.tuesday;
                    weekDays.wednesday = recurrence.wednesday;
                    weekDays.thursday = recurrence.thursday;
                    weekDays.friday = recurrence.friday;
                    weekDays.saturday = recurrence.saturday;
                    weekDays.sunday = recurrence.sunday;
                    break;
                case -1:
                    weekDays.monday = recurrence.tuesday;
                    weekDays.tuesday = recurrence.wednesday;
                    weekDays.wednesday = recurrence.thursday;
                    weekDays.thursday = recurrence.friday;
                    weekDays.friday = recurrence.saturday;
                    weekDays.saturday = recurrence.sunday;
                    weekDays.sunday = recurrence.monday;
                    break;
                case 1:
                    weekDays.monday = recurrence.sunday;
                    weekDays.tuesday = recurrence.monday;
                    weekDays.wednesday = recurrence.tuesday;
                    weekDays.thursday = recurrence.wednesday;
                    weekDays.friday = recurrence.thursday;
                    weekDays.saturday = recurrence.friday;
                    weekDays.sunday = recurrence.saturday;
                    break;

            }
            return weekDays;
        }

        private getDaysOfWeek(recurrence: any) {
            let offset :any = this.computeLocalDayShift(recurrence.hour, recurrence.minute);
            let weekDays:any = this.getAdjustedWeeklyScheduler(recurrence, offset);
            let result: string = '';
            if (weekDays.monday) {
                result += this.i18n('SchedulingUi', 'dayShort.1') + ",";
            }
            if (weekDays.tuesday) {
                result += this.i18n('SchedulingUi', "dayShort.2") + ",";
            }
            if (weekDays.wednesday) {
                result += this.i18n('SchedulingUi', "dayShort.3") + ",";
            }
            if (weekDays.thursday) {
                result += this.i18n('SchedulingUi', "dayShort.4") + ",";
            }
            if (weekDays.friday) {
                result += this.i18n('SchedulingUi', "dayShort.5") + ",";
            }
            if (weekDays.saturday) {
                result += this.i18n('SchedulingUi', "dayShort.6") + ",";
            }
            if (weekDays.sunday) {
                result += this.i18n('SchedulingUi', "dayShort.0") + ",";
            }
            return result;
        }


        private getAdjustedDayOfWeek(day: any, dayShift: any) {
            if (dayShift === 0) {
                return day;
            }

            if (day === 'monday') {
                return (dayShift < 0) ? 'sunday' : 'tuesday';
            } else if (day === 'tuesday') {
                return (dayShift < 0) ? 'monday' : 'wednesday';
            } else if (day === 'wednesday') {
                return (dayShift < 0) ? 'tuesday' : 'thursday';
            } else if (day === 'thursday') {
                return (dayShift < 0) ? 'wednesday' : 'friday';
            } else if (day === 'friday') {
                return (dayShift < 0) ? 'thursday' : 'saturday';
            } else if (day === 'saturday') {
                return (dayShift < 0) ? 'friday' : 'sunday';
            } else if (day === 'sunday') {
                return (dayShift < 0) ? 'saturday' : 'monday';
            }

            return day;
        }


        private getWeekOffset(recurrence: any) {
            let offset: any = recurrence.offset;

            if (offset === 'first') {
                return this.i18n('SchedulingUi', "week.0");
            }
            if (offset === 'second') {
                return this.i18n('SchedulingUi', "week.1");
            }
            if (offset === 'third') {
                return this.i18n('SchedulingUi', "week.2");
            }
            if (offset === 'fourth') {
                return this.i18n('SchedulingUi', "week.3");
            }
            if (offset === 'last') {
                return this.i18n('SchedulingUi', "week.4");
            }

            return "";
        }

        private getWeekday(recurrence: any) {
            var utcDay: any = recurrence.weekday;

            var dayShift :Number = this.computeLocalDayShift(recurrence.hour, recurrence.minute);

            var day: any = this.getAdjustedDayOfWeek(utcDay, dayShift);

            if (day === 'monday') {
                return this.i18n('SchedulingUi', "day.1");
            }
            if (day === 'tuesday') {
                return this.i18n('SchedulingUi', "day.2");
            }
            if (day === 'wednesday') {
                return this.i18n('SchedulingUi', "day.3");
            }
            if (day === 'thursday') {
                return this.i18n('SchedulingUi', "day.4");
            }
            if (day === 'friday') {
                return this.i18n('SchedulingUi', "day.5");
            }
            if (day === 'saturday') {
                return this.i18n('SchedulingUi', "day.6");
            }
            if (day === 'sunday') {
                return this.i18n('SchedulingUi', "day.0");
            }
            if (day === 'weekday') {
                return this.i18n('SchedulingUi', "day.7");
            }
            if(day === 'weekendDay') {
                return this.i18n('SchedulingUi', "day.8");
            }

            return "";
        }

    }

    angular.module("com.vmware.platform.ui")
        .service("scheduledTaskTimeService", ScheduledTaskTimeService);
}
