/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Used to provide aria attributes and appropriate roles to the confirmation modals
 * in H5 client to make it readable by the screen readers
 */
(function () {
   "use strict";

   angular.module("com.vmware.platform.ui").directive("alertDialogRole", alertDialogRole);

   alertDialogRole.$inject = ["$timeout"];

   function alertDialogRole($timeout) {

      const directive = {
         scope: {
            primaryTitle: "<?"
         },
         restrict: "A",
         compile: () => {
            return {
               pre: () => {},
               post: (scope, element) => {
                  $timeout(() => {
                     const $dialog = element.find("div[role=dialog]");
                     if (!$dialog.length) {
                        return;
                     }

                     const dialogTitleId = "dialog-title-" + Date.now();
                     const dialogDescriptionId = "dialog-description-" + Date.now();
                     $dialog.find("h3.modal-title").attr("id", dialogTitleId);
                     $dialog.find(".modal-body").attr("id", dialogDescriptionId);

                     $dialog.attr({
                        "role": "alertdialog",
                        "aria-labelledby": dialogTitleId,
                        "aria-modal": "true",
                        "aria-describedby": dialogDescriptionId,
                        "tabindex": 0
                     });
                  }, 0);
               }

            };
         }
      };

      return directive;
   }
})();