/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace common_ui {
   /**
    * Wrapper component designed specifically to upgrade vxObjectSelector directive and
    * allow object selector usage in angular.next components.
    * This component is not intended to be used by angular 1.5 components. For this
    * purpose the vxObjectSelector directive should continue to be used.
    */
   export class UpgradableObjectSelector {
      public bindings: any;
      public controller: any;
      public template: string;

      constructor() {
         this.bindings = {
            config: "<"
         };

         this.controller = UpgradableObjectSelectorController;
         this.template =
               `<div vx-object-selector
                     config="$ctrl.config"
               </div>`;
      }
   }

   class UpgradableObjectSelectorController {
   }

   angular.module("com.vmware.platform.ui")
         .component("vxUpgradableObjectSelector", new UpgradableObjectSelector());
}
