/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Document click directive. Triggers when the user clicks on the document excluding
 * this directive's dom element and it's children.
 *
 * Most useful for implementing popups/menus.
 *
 * however it is dangerous to continuously be observing
 */
angular.module('com.vmware.platform.ui').directive('vxDocumentClick', ['$timeout', '$parse',
function($timeout, $parse) {
   return {
      restrict: 'A',
      link: function($scope, iElement, iAttrs, controller){

         function call(locals){
            // execute the expression
            $scope.$apply($parse(iAttrs.vxDocumentClick)($scope, locals));
         }

         function onClick(evt){
            if (!$(evt.target).closest(iElement).length){
               call({$event:evt});
            }
         }

         $(document).on("mousedown", onClick);

         $scope.$on("$destroy", function(){
            $(document).off("mousedown", onClick);
         });
      }
   };
}]);
