/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
      .module('com.vmware.platform.ui')
      .constant('clarityConstants', {
         notifications: {
            type: {
               INFO: 'info',
               SUCCESS: 'success',
               WARNING: 'warning',
               ERROR: 'error'
            }
         },
         keys: {
            ENTER: 13,
            ESC: 27,
            TAB: 9
         }
      });
})();
