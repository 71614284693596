angular.module('com.vmware.platform.ui').directive('vxMultiDatagridContainer', [
   function () {
      return {
         link: function ($scope, element) {
            $scope.$on('vx-multi-datagrid.selection-changed', function (event, triggeringEl) {
               clearOtherDatagrids(element, triggeringEl);
            });
         }
      };

      function clearOtherDatagrids(element, triggeringEl) {
         var datagrids = element.find('[vx-multi-datagrid-member]');
         datagrids.each(function (i, childDatagrid) {
            if (childDatagrid !== triggeringEl[0]) {
               $(childDatagrid).find('[kendo-grid]').data('kendoGrid').clearSelection();
            }
         });
      }
   }]);
