/* Copyright 2016-2017 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {
   export class ListViewDragAndDropHandler {

      // NOTE: We need to keep k-denied as its presence is
      // internally inspected by Kendo UI code.
      private DENIED_CLASS = "k-denied";

      private DENIED_ICON_CLASS = "vsphere-icon-drag-and-drop-reject";
      private ALLOWED_ICON_CLASS = "vsphere-icon-drag-and-drop-accept";

      private _sourceItems: any;
      private _dragAndDropManager: any;

      public constructor(dragAndDropManager: any) {
         this._dragAndDropManager = dragAndDropManager;
      }

      /**
       * Saves the source item data to the currentTarget of the event
       * so that when the drop is on a different element (Ex. a tree)
       * the data will still be available.
       * Checks if the drag is valid
       * @param $event
       * @param itemsData
       */
      public dragstart($event: any, itemsData: any[]): void {
         this._sourceItems = itemsData;
         $event.currentTarget.itemsData = itemsData;
         this._dragAndDropManager.clearCache();
      }

      /**
       * Builds a hint that is displayed next to the mouse during drag of an element
       * of the grid.
       *
       * @param $event
       * @param itemsData
       * @returns {JQuery} the element to be attached to the dom
       */
      public hint($event: any, itemsData: any): JQuery {
         let name: string = "";
         if (itemsData && itemsData.length === 1 && itemsData[0].name) {
            name = itemsData[0].name;
         }
         return $("<div class='k-header k-drag-clue' />")
               .html(_.escape(name))
               .prepend(`<span class='k-icon k-drag-status ${this.DENIED_CLASS} ${this.DENIED_ICON_CLASS}'/>`);
      }

      /**
       * Checks the validity of the drop target at any moment during the drag event
       * and dynamically changes the hint to show whether or not a drop at this moment
       * is allowed.
       *
       * @param $event
       */
      public drag($event: any): void {
         // retrieves the drop item (if any)
         let currentItem: any|undefined = this.getDropDataItem($event);

         // if no such item is found show the hint for drop as denied
         if (!currentItem) {
            this.markDropAsDisallowed();
            return;
         }

         if (currentItem.id) {
            // needed because the dragAndDropManager requires property with that name
            currentItem.objRef = currentItem.id;
         }

         if (this._sourceItems
               && this._sourceItems.length === 1
               && this._sourceItems[0].objRef === currentItem.objRef) {
            // We don't allow source and target of drop operation to be the same.
            // Since drag is called almost on every pixel - this is a 'must have' check
            // in order to avoid pointless further processing.
            this.markDropAsDisallowed();
            return;
         }

         if (this._dragAndDropManager.isDropValid(this._sourceItems, currentItem)) {
            this.markDropAsAllowed();
            return;
         }

         this.markDropAsDisallowed();
      };

      /**
       * Called when drag is ended.
       * Checks if the drop is allowed and if so
       * removes the hint from the html.
       * If the drop is not allowed the hint will not be removed and will
       * fly back to the dragged object
       * @param $event
       */
      public dragend($event: any): void {
         let dragClueSpan: JQuery = $(".k-drag-clue span");
         if (dragClueSpan.hasClass(this.ALLOWED_ICON_CLASS)) {
            $(".k-drag-clue").remove();
         }
      }

      public drop($event: any): void {
      }

      /**
       * Change the class of the hint if it is needed
       * to show that the drop at this moment is allowed
       */
      private markDropAsAllowed(): void {
         let dragClue: JQuery = $(".k-drag-clue span");

         dragClue.removeClass(this.DENIED_CLASS);
         dragClue.removeClass(this.DENIED_ICON_CLASS);

         dragClue.addClass(this.ALLOWED_ICON_CLASS);
      }

      /**
       * Change the class of the hint if it is needed
       * to show that the drop at this moment is not allowed
       */
      private markDropAsDisallowed(): void {
         let dragClue: JQuery = $(".k-drag-clue span");

         dragClue.removeClass(this.ALLOWED_ICON_CLASS);

         dragClue.addClass(this.DENIED_CLASS);
         dragClue.addClass(this.DENIED_ICON_CLASS);
      }

      /**
       * Gets the 'data' object that corresponds to the drop target HTML element.
       */
      private getDropDataItem($event: any): any|undefined {
         // $event.target - the target over which the mouse is currently placed
         if (!$event.target) {
            return undefined;
         }

         // $event.dropTarget - the 'control' over which the mouse is currently placed.
         // In Kendo UI, usually the control.kendoDropTarget(...) method is invoked to
         // configure it as a drop target.
         if (!$event.dropTarget) {
            return undefined;
         }

         let maybeKendoTree: any =
               $($event.dropTarget.targetElement).data("kendoTreeView");
         if (maybeKendoTree) {
            return this.getTreeDataItem($event, maybeKendoTree);
         }

         let maybeKendoGrid: any = $($event.dropTarget.targetElement).data("kendoGrid");
         if (maybeKendoGrid) {
            return this.getGridDataItem($event, maybeKendoGrid);
         }

         return undefined;
      }

      private getTreeDataItem($event: Event, kendoTree: any): any|undefined {
         // if in a tree that will return the closes tree item to the target element
         let selectedElement: JQuery = $($event.target).closest(".k-item");

         if (!selectedElement) {
            return undefined;
         }
         // retrieve the data
         return kendoTree.dataItem(selectedElement);
      }

      private getGridDataItem($event: Event, kendoGrid: any): any|undefined {
         // if in a kendo grid -  that will return the closes row to the target element
         let selectedElement: JQuery = $($event.target).closest("tr");

         if (!selectedElement) {
            return undefined;
         }
         // retrieve the data
         return kendoGrid.dataItem(selectedElement);
      }

   }

   angular.module("com.vmware.platform.ui")
         .factory("listViewDragAndDropHandler", ["dragAndDropManager",
            function (dragAndDropManager) {
               return new ListViewDragAndDropHandler(dragAndDropManager);
            }]);
}
