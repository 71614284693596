/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Template utility service
 */
module platform {

   export class TemplateHelperService {

      static $inject = ['$http', '$sce', '$interpolate', '$templateCache'];

      constructor(private $http: angular.IHttpService,
            private $sce: angular.ISCEService,
            private $interpolate: angular.IInterpolateService,
            private $templateCache: angular.ITemplateCacheService) {
      }

      renderTemplateWithScope(templateUrl: string,
            scope: angular.IScope): angular.IPromise<string> {
         return this.$http.get(
               this.$sce.getTrustedResourceUrl(templateUrl),
               {
                  cache: this.$templateCache
               }
         ).then(function (response: any) {
            return this.$interpolate(response.data)(scope);
         }.bind(this));
      }

      /**
       * Returns a function that substitutes parameters in the provided HTML
       * template string safely i.e. it escapes the HTML tags in the
       * parameters. The parameters in the template are specified in the same
       * way as the ones in the Angular
       * $interpolate service i.e. {{parameterName}}.
       *
       * @param templateString - a template HTML String
       * @returns a function (params: Object) -> string
       */
      getHtmlEscapingTemplateRenderFunction(templateString: string): (params: Object) => string {

         const wrappedTemplate = '<div ng-non-bindable>' + templateString + '</div>';

         return _.template(wrappedTemplate, {
            escape: /\{\{(.+?)\}\}/,
            interpolate: /(.)^/, // Do not match anything
            evaluate: /(.)^/, // Do not match anything
         });
      }
   }

   angular.module('com.vmware.platform.ui')
         .service('templateHelperService', TemplateHelperService);
}
