namespace common_ui {

   export class StorageSelectorDialogData {

      public storageObj: any;

      public storageProfile: StorageProfile | null;

      public replicationGroup: any | null;
   }
}