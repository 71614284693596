/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {
   'use strict';

   import IHttpService = angular.IHttpService;
   import IRequestConfig = angular.IRequestConfig;
   import ITimeoutService = angular.ITimeoutService;
   import IPromise = angular.IPromise;

   declare var _: any;

   interface TreeLiveUpdateBufferEntry {}

   export interface ChangeUpdate extends TreeLiveUpdateBufferEntry {
      data: {
         isNameUpdate: boolean
      };
   }

   export interface Parent extends TreeLiveUpdateBufferEntry {
      nodeTypeId: string;
      objRef: string;
   }

   enum CollectorKind {
      Change,
      Children
   }

   type onHttpDoneCallback = (serverData: { [objectId: string]: any },
                              bufferMap: { [objectId: string]: TreeLiveUpdateBufferEntry }) => void;

   /**
    * Service that holds different buffers for tree live updates and performs server
    * data fetch operations on a given time interval.
    */
   export class TreeLiveUpdateBufferService {
      static $inject = [
         "$rootScope",
         "$timeout",
         "$http",
         "treeNodeService",
         "logService",
         "configurationService",
         "$log"
      ];

      private collectors: {
         [treeId: string]: {
            [collectorKind: number]: LiveUpdateCollector<TreeLiveUpdateBufferEntry,
                                                         LiveUpdateBuffer<TreeLiveUpdateBufferEntry>>
         }
      };

      constructor(private $rootScope:any,
                  private $timeout: ITimeoutService,
                  private $http: IHttpService,
                  private treeNodeService: any,
                  private logService: any,
                  private configurationService: any,
                  private $log: any) {
         this.collectors = {};

         $rootScope.$on("$destroy", () => {
            this.collectors = {};
         });
      }

      /**
       * Create a new change update buffer for the given treeId.
       *
       * Overrides previous buffer if any.
       *
       * @param treeId              The tree id.
       * @param httpDoneCallback    Called on each successful http request.
       *    Callback in the following format:
       *
       *       function(serverData, bufferMap);
       *
       *    `serverData`   is the server response.
       *    `bufferMap`    contains the buffered entries by objectIds.
       */
      createChangeBuffer(treeId: string,
                         httpDoneCallback: onHttpDoneCallback): void {
         if (!this.collectors[treeId]) {
            this.collectors[treeId] = {};
         }

         if (this.collectors[treeId][CollectorKind.Change]) {
            this.collectors[treeId][CollectorKind.Change].destroy();
         }

         this.collectors[treeId][CollectorKind.Change] = new ChangeUpdateCollector(
            new ChangeUpdateBuffer(),
            this.$timeout,
            this.logService('treeLiveChangeUpdateBuffer'),
            this.configurationService,
            httpDoneCallback,
            (bufferMap: {}): IPromise<{}> => {
               return this.$http(<IRequestConfig>{
                  method: 'POST',
                  url: 'tree/propertiesByObjectIds',
                  skipLoadingNotification: true,
                  data: {
                     objectIds: Object.keys(bufferMap)
                  }
               }).then((resp: {data: {}}) => {
                  // { objectId => properties } mapping
                  return resp ? resp.data || {} : {};
               });
            });
      }

      /**
       * Create a new children update buffer for the given treeId.
       *
       * Overrides previous buffer if any.
       *
       * @param treeId              The tree id.
       * @param httpDoneCallback    Called on each successful http request.
       *    Callback in the following format:
       *
       *       function(serverData, bufferMap);
       *
       *    `serverData`   is the server response.
       *    `bufferMap`    contains the buffered entries by objectIds.
       */
      createChildrenBuffer(treeId: string,
                           httpDoneCallback: onHttpDoneCallback): void {
         if (!this.collectors[treeId]) {
            this.collectors[treeId] = {};
         }

         if (this.collectors[treeId][CollectorKind.Children]) {
            this.collectors[treeId][CollectorKind.Children].destroy();
         }

         this.collectors[treeId][CollectorKind.Children] = new ChildrenUpdateCollector(
             new ChildrenUpdateBuffer(),
             this.$timeout,
             this.logService('treeLiveChildrenUpdateBuffer'),
             this.configurationService,
             httpDoneCallback,
             (bufferMap: {}): IPromise<{}> => {
                const parents = _.values(bufferMap);
                const parentsAsJson: string = JSON.stringify(parents);
                this.$log.debug(
                      "TreeLiveUpdateBufferService: calling treeNodeService#getChildrenByObjectIds for '"
                      + parentsAsJson + "'");
                // { objectId => children } mapping
                return this.treeNodeService.getChildrenByObjectIds(treeId, parents);
             });
      }

      /**
       * Destroy existing change update buffer for the given treeId.
       *
       * @param treeId              The tree id.
       * @returns {boolean}         False if the buffer does not exist.
       */
      destroyChangeBuffer(treeId: string): boolean {
         if (!this.collectors[treeId] || !this.collectors[treeId][CollectorKind.Change]) {
            return false;
         }

         this.collectors[treeId][CollectorKind.Change].destroy();
         delete this.collectors[treeId][CollectorKind.Change];

         return true;
      }

      /**
       * Destroy existing children update buffer for the given treeId.
       *
       * @param treeId              The tree id.
       * @returns {boolean}         False if the buffer does not exist.
       */
      destroyChildrenBuffer(treeId: string): boolean {
         if (!this.collectors[treeId] || !this.collectors[treeId][CollectorKind.Children]) {
            return false;
         }

         this.collectors[treeId][CollectorKind.Children].destroy();
         delete this.collectors[treeId][CollectorKind.Children];

         return true;
      }


      /**
       * Add a new change update to the given treeId's change update buffer.
       *
       * @param treeId              The tree id.
       * @param objectId            The object id. Will be used as a key for the given entry.
       * @param entry               The data that will be buffered.
       * @returns {boolean}         False if the buffer does not exist.
       */
      addChangeEntry(treeId: string,
                     objectId: string,
                     entry: ChangeUpdate): boolean {
         if (!this.collectors[treeId] || !this.collectors[treeId][CollectorKind.Change]) {
            return false;
         }

         this.collectors[treeId][CollectorKind.Change].addEntry(objectId, entry);

         return true;
      }

      /**
       * Add a new children update to the given treeId's children update buffer.
       *
       * @param treeId              The tree id.
       * @param objectId            The object id. Will be used as a key for the given entry.
       * @param entry               The data that will be buffered.
       * @returns {boolean}         False if the buffer does not exist.
       */
      addChildrenEntry(treeId: string,
                       objectId: string,
                       entry: Parent): boolean {
         if (!this.collectors[treeId] || !this.collectors[treeId][CollectorKind.Children]) {
            return false;
         }

         this.collectors[treeId][CollectorKind.Children].addEntry(objectId, entry);

         return true;
      }
   }

   /**
    * The common base class for live update collectors.
    *
    * Collectors handle the throttling and the server data retrieval.
    * For internal data buffering they use a variant of `LiveUpdateBuffer`.
    *
    * The throttling algorithm for a given throttle interval is as follows:
    *    1. A server call is made immediately when the first entry is added and
    *       a throttle timer is started.
    *
    *    2. All new entries are buffered until both the http call and
    *       the throttle timer are due.
    *
    *    3. When the http call is done the given callback is executed.
    *
    *    4. When both the http call and the throttle timer are due a check is made whether
    *       new entries have been added to the buffer in that period.
    *
    *    5. If so an http call is made and a new throttle timer starts. Otherwise the
    *       collector is in it's initial state and the process repeats.
    *
    * The throttle interval is configurable from `webclient.properties` with the key
    * `live.updates.navtree.throttle.interval`:
    *
    *    negative number stands for using the default value,
    *    0 disables the throttling,
    *    positive number overrides the default value.
    *
    * If live.updates.navtree.throttle.interval is missing in the
    * webclient.properties file, the interval will be set to its default value
    * `LIVE_UPDATES_THROTTLE_INTERVAL_MS`
    */
   class LiveUpdateCollector<Entry extends TreeLiveUpdateBufferEntry,
                             Buffer extends LiveUpdateBuffer<Entry>> {
      static readonly LIVE_UPDATES_THROTTLE_INTERVAL_MS = 1 * 1000;

      private timer: Timer;
      private isHttpAndThrottleDone: boolean;

      constructor(private buffer: Buffer,
                  private $timeout: ITimeoutService,
                  private logger: any,
                  private configurationService: any,
                  private callback: onHttpDoneCallback,
                  private getData: (bufferMap: {}) => IPromise<{}>) {
         this.timer = new Timer();
         this.isHttpAndThrottleDone = true;
      }

      destroy() {
         // Prevent calling of consumer callback after destruction
         this.callback = angular.noop;
      }

      addEntry(objectId: string, entry: Entry): void {
         this.buffer.addEntry(objectId, entry);

         if (this.isHttpAndThrottleDone) {
            this.flushBuffer();
         }
      }

      private flushBuffer(): void {
         const bufferMap: {} = this.buffer.getMapAndReset();

         this.isHttpAndThrottleDone = false;
         this.timer.start();

         this.getData(bufferMap)
            .then((data: {}) => {
               this.callback(data, bufferMap);
               this.throttle();
            }, (err: any) => {
               this.logger.warn(err);
               this.throttle();
            });
      }

      private throttle(): void {
         this.configurationService.getProperty('live.updates.navtree.throttle.interval')
            .then((prop: string) => {
               let throttleInterval = parseInt(prop);

               if (isNaN(throttleInterval) || throttleInterval < 0) {
                  throttleInterval = LiveUpdateCollector.LIVE_UPDATES_THROTTLE_INTERVAL_MS;
               }

               let wait = throttleInterval - this.timer.elapsed();
               wait = wait < 0 ? 0 : wait;

               this.$timeout(() => {
                  this.isHttpAndThrottleDone = true;
                  if (!this.buffer.isEmpty()) {
                     // If the buffer is not empty we need initiate another http call
                     // for the values in the buffer.
                     this.flushBuffer();
                  }
               }, wait);
            });
      }
   }

   class ChangeUpdateCollector extends LiveUpdateCollector<ChangeUpdate, ChangeUpdateBuffer> {}
   class ChildrenUpdateCollector extends LiveUpdateCollector<Parent, ChildrenUpdateBuffer> {}

   /**
    * The common base class for live update buffers.
    */
   class LiveUpdateBuffer<Entry extends TreeLiveUpdateBufferEntry> {
      protected map: { [objectId: string]: Entry };

      constructor() {
         this.map = {};
      }

      getMapAndReset(): { [objectId: string]: Entry } {
         const oldMap = this.map;
         this.map = {};
         return oldMap;
      }

      addEntry(objectId: string, entry: Entry): void {
         this.map[objectId] = entry;
      }

      isEmpty(): boolean {
         return !Object.keys(this.map).length;
      }
   }

   /**
    * Mapping objectId to the last update for that object.
    *
    * NOTE: Non-rename updates don't override name updates.
    */
   class ChangeUpdateBuffer extends LiveUpdateBuffer<ChangeUpdate> {
      addEntry(objectId: string, update: ChangeUpdate): void {
         if (!this.map[objectId] || update.data.isNameUpdate) {
            this.map[objectId] = update;
         }
      }
   }

   /**
    * Mapping objectId to parent info.
    */
   class ChildrenUpdateBuffer extends LiveUpdateBuffer<Parent> {}

   /**
    * Simple timer
    */
   class Timer {
      private startTime: number;

      constructor() {
         this.startTime = 0;
      }

      start(): void {
         this.startTime = _.now();
      }

      elapsed(): number {
         return _.now() - this.startTime;
      }
   }

   angular.module('com.vmware.platform.ui')
       .service('treeLiveUpdateBufferService', TreeLiveUpdateBufferService);
}
