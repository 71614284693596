/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter turn boolean into @trueValue/@falseValue or Yes/No by default
 */
angular.module('com.vmware.platform.ui').filter('boolean', ['$filter', 'i18nService',
   function($filter, i18nService) {
      'use strict';
      return function(value, trueValue, falseValue) {
         trueValue = trueValue || i18nService.getString("Common", "yes.label");
         falseValue = falseValue || i18nService.getString("Common", "no.label");
         return value ? trueValue : falseValue;
      };
   }]);
