/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * This file must come first.
 */
window.h5 = window.h5 || {};
window.h5.uiLib = window.h5.uiLib || {};
window.h5.actions = window.h5.actions || {};
window.h5.dragAndDropValidationMethods = window.h5.dragAndDropValidationMethods || {};
window.h5.listViewSelectedItemIdProperty = "lvSelectedItemId";

/**
 * @ngdoc overview
 * @name com.vmware.platform.ui
 * @module com.vmware.platform.ui
 *
 * @description
 *    # com.vmware.platform.ui
 *    This module contains various utility services for fetching and mutating objects
 *    and data, navigating, localization and other common tasks.
 *
 *    <mark style="background: #FA8072">Many of the APIs in this module are still
 *    candidate only and subject to change. Send feedback to h5-reviews@vmware.com.</mark>
 */
window.h5.uiLib.angularModule = angular
      .module('com.vmware.platform.ui', [
            'ngAria',
            'ngCookies',
            'vui.angular',
            'kendo.directives',
            'angulartics',
            'angulartics.piwik',
            'clarity-bridge', // Angular2 based Clarity
            'ui.sortable'
         ]
      )
      .config([
         'vuiModalServiceProvider', 'vuiDialogServiceProvider', 'vuiWizardServiceProvider',
         function(vuiModalServiceProvider, vuiDialogServiceProvider, vuiWizardServiceProvider) {
            vuiModalServiceProvider.setDefaultConfig({
               destroyOnHide: true,
               destroyOuterScope: true
            });

            vuiDialogServiceProvider.setDefaultConfig({
               destroyOnHide: true,
               destroyOuterScope: true
            });
            vuiDialogServiceProvider.setDefaultDialogOptions({
               maximizable: false,
               resizable: false,
               draggable: true,
               resizeMinWidth: 320,
               resizeMinHeight: 320
            });

            vuiWizardServiceProvider.setDefaultConfig({
               destroyOnHide: true,
               destroyOuterScope: true
            });
            vuiWizardServiceProvider.setDefaultWizardOptions({
               maximizable: true,
               resizable: true,
               draggable: true,
               resizeMinWidth: 700,
               resizeMinHeight: 420,
               closeOnEsc: true
            });
         }])
      .config([
         '$compileProvider', '$httpProvider', function ($compileProvider, $httpProvider) {
            $compileProvider.aHrefSanitizationWhitelist(
                  /^\s*((https?|ftp|mailto|tel|file|vmrc):|(javascript:((void\(0\))?;?|(\/{2})))$)/);
            $compileProvider.debugInfoEnabled(h5.debug);

            $httpProvider.defaults.xsrfCookieName = h5.xsrfCookieName;
            $httpProvider.defaults.xsrfHeaderName = h5.xsrfHeaderName;

            // Disables the compilation of directives on comments.
            // Results in a compilation performance gain.
            $compileProvider.commentDirectivesEnabled(false);
            // Disables the compilation of directives on element classes.
            // Results in a compilation performance gain.
            $compileProvider.cssClassDirectivesEnabled(false);

         }])
      .config(['$qProvider', function($qProvider) {
         // Possibly unhandled rejected promises will be logged to the
         // $exceptionHandler. Normally, that means that an error will be
         // logged to the console, but in tests $exceptionHandler will
         // (by default) re-throw any exceptions.
         // Tests that are affected by this change.
         // Adding this config option to keep 1.5 - 1.6 compatibility.
         // https://docs.angularjs.org/guide/migration#migrate1.5to1.6-ng-services-$q
         $qProvider.errorOnUnhandledRejections(false);
      }])
      .config(['$locationProvider', function ($locationProvider) {
         // Angular 1.6 introduces '!' as default hash prefix.
         // Adding this config option to keep 1.5 - 1.6 compatibility.
         // https://docs.angularjs.org/guide/migration#commit-aa077e8
         $locationProvider.hashPrefix('');
      }])
      .directive('input', function() {
         // Added for backward compatibility between Angular 1.5 & 1.6
         // https://docs.angularjs.org/guide/migration#migrate1.5to1.6-ng-directives-input%5Bnumber%5D
         return {
            restrict: 'E',
            require: '?ngModel',
            link: function (scope, elem, attrs, ngModelCtrl) {
               // ...that are of type "number" and have `ngModel`...
               if ((attrs.type === 'number') && ngModelCtrl) {
                  // ...remove the `step` validator.
                  delete ngModelCtrl.$validators.step;
               }
            }
         };
      })
      .run(['$rootScope', 'i18nService', '$cookies', 'h5SdkActionService',
         function ($rootScope, i18nService, $cookies, h5SdkActionService) {
         // Adding Language Resources to h5 global variable and $rootScope.
         h5.i18n.getString = i18nService.getString;
         angular.extend($rootScope, {
            i18n: i18nService.getString
         });

         jQuery.ajaxPrefilter(function (options, originalOptions, jqXHR) {
            var xsrfCookie = $cookies.get(h5.xsrfCookieName);
            if (xsrfCookie) {
               jqXHR.setRequestHeader(h5.xsrfHeaderName, xsrfCookie);
            }
         });

         h5SdkActionService.bindActionHandlers();
         }]);

window.h5.h5main = window.h5.h5main || {};
window.h5.h5main.angularModule = angular.module('h5main',
      ['ngAnimate', 'com.vmware.platform.ui', 'angulartics', 'angulartics.piwik']);

var pendingJqueryRequests = [];
var pendingXhrs = {};

// context / right click disabled
if (!h5.debug) {
   if (h5.configuration && h5.configuration.configParams
         && h5.configuration.configParams["browser.context.menu"] === "false") {
      $(window).on('contextmenu', function() {
         return false;
      });
   } else {
      $(document).ready(function() {
         $('#applicationMenuContainer').on('contextmenu', function() {
            return false;
         });
      });
   }

   // Instrumentation to capture pending jQuery requests in addition to
   // pending Angular requests. h5.__getPendingHTTPRequests() is invoked by
   // the SystemTest automation.
   // See https://reviewboard.eng.vmware.com/r/1243537
   (function () {
      $(document).ready(function () {
         (function (open) {
            XMLHttpRequest.prototype.open = function (method, url, async, user, pass) {
               this.addEventListener("loadstart", function () {
                  pendingXhrs[url] = {'method': method};
               }, false);
               this.addEventListener("loadend", function () {
                  delete pendingXhrs[url];
               }, false);
               open.apply(this, arguments);
            };
         })(XMLHttpRequest.prototype.open);

         $(document).ajaxSend(function (evt, xhr, opts) {
            xhr.vmwopts = opts.url + " - " + opts.data;
            pendingJqueryRequests.push(xhr.vmwopts);
            xhr.always(function () {
               var idx = pendingJqueryRequests.indexOf(xhr.vmwopts);
               if (idx === -1) {
                  pendingJqueryRequests = [];
               }
               pendingJqueryRequests.splice(idx, 1);
            });
         });
      });
   })();
}
