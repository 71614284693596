(function () {
   'use strict';
   angular.module('com.vmware.platform.ui').factory('$exceptionHandler',
      ['$log', '$injector', function ($log, $injector) {
      var notificationService;

         // Overrides Angular's $exceptionHandler to show error in notification popup and collect it in PhoneHome.
         // For release builds the error popup won't show up.  Error collection depends on CEIP approval
      return function (exception, cause) {
         if (window.jasmine) {
            throw exception;
         }

         // If the app is in debug mode, keep track of all of the exceptions that
         // occurred in the jsErrors array.
         if (window.h5.debug) {
            window.jsErrors = window.jsErrors || {};
            window.jsErrors[exception.message] = exception.stack;
         }

         if (!notificationService) {
            notificationService = $injector.get('notificationService');
         }
         $log.error.apply($log, arguments);
         notificationService.notifyError(null, exception.message, exception.stack, true);
      };
   }]);
}());