/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').directive('vxFocusAndSelect', vxFocusAndSelect);

   function vxFocusAndSelect() {
      return {
         require: 'ngModel',
         link: function(scope, element, attrs, ngModel) {
            ngModel.$render = function() {
               element.val(ngModel.$viewValue);
               element[0].select();
            };
         }
      };
   }
})();