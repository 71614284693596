/*
 *  Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential
 */
module platform {
   import IHttpService = angular.IHttpService;
   import IPromise = angular.IPromise;

   /**
    * VcErrorsNotificationService service for notifying when there are errors from the VC
    */
   export class VcErrorsNotificationService {
      static $inject = ['$http', 'globalNotificationService'];


      constructor(private $http: IHttpService, private _globalNotificationService: any) {
      };

      /**
       * Notify when there are errors from the VCs login
       */
      notify(): void {
         this.getUserSessionServerErrors().then((errors) => {
            if(errors && !_.isEmpty(errors.data)){
               this._globalNotificationService.notify({
                  content: errors.data.join("\n"),
                  type: "info"
               });
            }
         });
      }

      private getUserSessionServerErrors(): IPromise{
         return this.$http({
            method: 'GET',
            url: 'usersession/userSessionServerErrors',
         });
      }
   }

   angular.module('com.vmware.platform.ui')
      .service('vcErrorsNotificationService', VcErrorsNotificationService);
}
