angular.module('com.vmware.platform.ui')
.run(['columnRenderersRegistry',
      'resourceUtil',
      'taskConstants',
      'i18nService',
      'taskFormatter',
      'entityStatusService',
      '$interpolate',
      'vimEntityEscapingService',
      'iconService',
      function(columnRenderersRegistry, resourceUtil, taskConstants, i18nService,
               taskFormatter, entityStatusService, $interpolate, vimEntityEscapingService, iconService) {
         'use strict';

   columnRenderersRegistry.registerColumnRenderers({
      /**
       * Renderer to render icon and label which is link-enabled in a cell. [e.g. <icon> name(label1)(label2)]
       * @param  {array} props Array of property names, where 1st property is an object reference,
       *  2nd icon property,
       *  3nd is a label property,
       *  4th is tagging labels
       *  5th is tooltip title
       * @param  {object} data  Map of values for icon and label properties.
       * @param {object} config A dictionary (key/value) to control the renderer behavior.
       *  'navigatable' - controls whether a link navigating to the object should be rendered.
       */
      'object-name': function(props, data, config) {
         var objectId = data[props[0]];
         var iconClass = data[props[1]] || '';
         var label = data[props[2]];
         if (!label) {
            return '';
         }
         var labelIds = data[props[3]];
         var title = data[props[4]];
         var taggingLabel = resourceUtil.getTaggingLabelString(labelIds);

         var navigatable = config ? config.navigatable: undefined;

         var options = null;
         if (config && config.extensionName) {
            options = {
               extensionName: config.extensionName
            };
         }
         // TODO - vaivanov - This needs to be reworked to use options object
         return linkRenderer(
               objectId, label + taggingLabel, iconClass, false, options, title, navigatable);
      },

      /**
       * Renderer to render status icon and status label in a cell.
       * @param  {array} props Array of property names, where there is only 1 "status" property
       * @param  {object} data  Map of values for a "status" property.
       */
      'status': function(props, data) {
         var stat = data[props[0]];

         var properties = entityStatusService.statusProperties(stat);

         var label = _.escape(properties.label);

         return '<div ng-non-bindable class="object"><span title="' + label +
               '"><i class="' +  properties.iconClass +'"></i>' + label + '</span></div>';
      },

      'task-status': taskStatus,

      'task-status-first-line': taskStatusFirstLine,

      /**
       * Renderer to render object icon and name as navigable link in a cell if
       * object id is provided, otherwise the object name is displayed as a plain text.
       * @param  {array} props Array of property names, where
       *    1st property is the object reference
       *    2nd property is the object name
       *    3rd property is the object icon
       *    4th property is a custom label to be displayed if the object name is not
       *       available, e.g. if datastore does not belong to a datastore cluster,
       *       the parentPod property of the datastore will be null, so for those
       *       datastores we could want to display a custom label, e.g. --, na, etc.
       *    5th property is a boolean defining if the object exists. If set to
       *    false, the link will be non-navigatable.
       *    6th property is tooltip title
       * @param  {object} data  Map of values for icon and label properties.
       * @param {object} config A dictionary (key/value) to control the renderer behavior.
       *  'navigatable' - controls whether a link navigating to the object should be rendered.
       */
      'object-link': function(props, data, config) {
         var objectId = data[props[0]],
               objectName = data[props[1]],
               objectIcon = props[2],
               customLabel = data[props[3]],
               targetExists = props[4],
               title = data[props[5]],
               displayText; // objectName or customLabel (if objectName not provided)

         displayText = !objectName ? customLabel : objectName;

         var isDisabled = targetExists === false;

         var navigatable = config ? config.navigatable: undefined;
         var focusable = config && config.focusable === true;

         return linkRenderer(objectId, displayText, objectIcon, isDisabled, config, title, navigatable, focusable);
      },

      /**
       * Renderer to render a custom attribute value in a cell.
       * @param {array} props Array of property names, where there is "customValue" and
       *    "@instanceUuid" properties
       * @param {object} data  Map of values for a "customValue" and "@instanceUuid"
       *    properties.
       * @param {object} config Object with custom attribute's 'key' and 'serverGuid' to
       *       identify the correct custom attribute value that matches current cell.
       *       @link config value
       */
      'custom-attr': function(props, data, config) {
         var customAttr = _.find(data.customValue, function(attr) {
            return config.key === attr.key && config.serverGuid === data['@instanceUuid'];
         });
         var value = customAttr ? customAttr.value : "";
         return textWithTooltipRenderer(value, value);
      },

      /**
       * Plain-text renderer. Used when no renderer is specified.
       * @param  {array} props Array of property names.
       * 1st property is displayed both as value and a tooltip.
       * Other properties are ignored.
       *
       * @param  {object} data  Map of values.
       *
       * @param  {boolean} unescapeCharacters (Optional) specifies whether the renderer
       * should unescape special characters such as % / and \.
       */
      'text': function(props, data, unescapeCharacters) {
         return textWithTooltipRenderer(data[props[0]], undefined, unescapeCharacters);
      },

      /**
       * Plain-text renderer. Used when no renderer is specified.
       * @param  {array} props Array of property names.
       * 1st property is displayed.
       * 2nd property is the tootlip of the displayed property.
       * Other properties are ignored.
       *
       * @param  {object} data  Map of values.
       *
       * @param  {boolean} unescapeCharacters (Optional) specifies whether the renderer
       * should unescape special characters such as % / and \.
       */
      'text-with-custom-tooltip': function(props, data, unescapeCharacters) {
         return textWithTooltipRenderer(data[props[0]], data[props[1]], unescapeCharacters);
      },

      /**
       * Multi-line text renderer.
       * @param  {array} props Array of property names. 1st property is displayed.
       * Other properties are ignored
       * @param  {object} data  Map of values.
       */
      'wrapped-text': function(props, data) {
         var value = data[props[0]] !== undefined ? data[props[0]] : '';
         return $('<div/>')
               .attr('ng-non-bindable', '')
               .addClass('line-wrap')
               .text(value)[0].outerHTML;
      },

      /**
       * Renders disabled (grayed out and in italics) text.
       * @param props Array of property names, where:
       *    1st property is the property to display,
       *    2nd property controls whether the text should be rendered as disabled.
       *        The first property will be rendered disabled only when
       *        data[ <2nd property> ] equals 'true'.
       *
       * Other properties are ignored
       * @param  {object} data  Map of values.
       */
      'disabled-text': function(props, data) {
         var textValue = data[props[0]] !== undefined ? data[props[0]] : '';
         var className = 'vertical-aligned-text';
         if (data[props[1]] !== undefined && data[props[1]] === true) {
            className += ' disabled-text';
         }
         return $('<span/>')
               .attr('ng-non-bindable', '')
               .addClass(className)
               .text(textValue)[0].outerHTML;
      },

      /**
       * Renders disabled (grayed out and in italics) text with an icon and a tooltip.
       * @param props Array of property names, where:
       *    1st property is the icon to display,
       *    2st property is the text to display,
       *    3nd property controls whether the text should be rendered as disabled.
       *        The first property will be rendered disabled only when
       *        data[ <2nd property> ] equals 'true'.
       *    4nd property is the tooltip of the displayed property.
       *
       * Other properties are ignored
       * @param  {object} data  Map of values.
       */
      'disabled-text-icon-with-tooltip': function(props, data) {
         var icon = data[props[0]];
         var text = data[props[1]];

         if (typeof text === 'undefined' || text === null) {
            text = '';
         }

         var className = 'vertical-aligned-text';
         if (data[props[2]] !== undefined && data[props[2]] === true) {
            className += ' disabled-text';
         }

         var title = data[props[3]];
         if (typeof title === 'undefined' || title === null) {
            title = text;
         }

         return "<span ng-non-bindable class='" + className + "'>" +
            "<span title='" + _.escape(title) + "' class='object'>" +
                  "<i class='" + _.escape(icon) + "'></i>" + _.escape(text) +
            "</span></span>";
      },

      /**
       * Renders number values (right-aligned).
       * @param props Array of property names, where:
       *    1st property is the property to display
       *
       * Other properties are ignored
       * @param  {object} data  Map of values.
       */
      'number': function(props, data) {
         var textValue = data[props[0]] !== undefined ? data[props[0]] : '';
         return $('<span/>')
               .attr('ng-non-bindable', '')
               .addClass('numeric-text')
               .text(textValue)[0].outerHTML;
      },

      /**
       * Renders an array as a comma-separated list.
       * @param  {array} props Array of property names, where 1st property is
       * the name of the array property. Other properties are ignored
       * @param  {object} data  Map of values, contains the array.
       */
      'array': function(props, data) {
         var items = data[props[0]];
         var displayText = items ? items.join(', ') : '';
         return '<div ng-non-bindable class="line-wrap">' + displayText + '</div>';
      },

      /**
       * Renders a percentage meter
       * @param  {array} props Array of property names. 1st property is displayed.
       * Other properties are ignored
       * @param  {object} data  Map of values, contains the percentage to render.
       */
      'meter': function(props, data) {
         var value = data[props[0]];
         if (!value) {
            value = 0;
         }
         var labelElement = '<span ng-non-bindable>' + value + '%</span>';
         var meterElement = '<vx-resource-meter percentage="' + value + '" orientation="horizontal"/>';
         return '<div class="column-meter">' + labelElement + meterElement + '</div>';
      },

      'progress-bar-cancel-link': progressBar,

      /**
       * Rendering function for Status column, which shows the progress bar
       * while the task is running, otherwise shows the status.
       */
      'task-state': function(clientTaskInfo) {
         var pluginTaskState = getPluginTaskState(clientTaskInfo);
         if (pluginTaskState) {
            return pluginTaskState;
         }
         if (clientTaskInfo.state === taskConstants.status.RUNNING) {
            return progressBar(clientTaskInfo.progress, clientTaskInfo.cancelable,
               clientTaskInfo.pendingCancel,
               'dataItem.onCancelTaskClicked()');
         } else {
            return taskStatus(clientTaskInfo);
         }
      },

      'recent-task-state': function(clientTaskInfo) {
         var pluginTaskState = getPluginTaskState(clientTaskInfo);
         if (pluginTaskState) {
            return pluginTaskState;
         }
         if (clientTaskInfo.state === taskConstants.status.RUNNING) {
            var cancelClass =  clientTaskInfo.cancelable ? 'vui-icon-datagrid-cancel' : 'vui-icon-datagrid-cancel-disabled';
            var onclick = "angular.element('recent-tasks-view').injector().get('recentTasksStoreService').cancelTask('" +
                  clientTaskInfo.key + "', '" + clientTaskInfo.serverGuid + "')";
            return '<div class="horizontal-flex-container">' +
                  '<div class="progress-static flex-column flex-grow-auto">' +
                  '<div class="progress-meter" data-value="' + clientTaskInfo.progress + '"></div>' +
                  '</div>' +
                  '<div role="percentage">' + clientTaskInfo.progress + '%</div>' +
                  '<div role="cancelButton" onclick="' + onclick + '" class="' + cancelClass + '" title="'
                        + i18nService.getString('Common', 'cancel') + '"></div>' +
                  '</div>';
         } else {
            return taskStatus(clientTaskInfo);
         }
      },


      /**
       * Renders an icon and text
       * @param  {array} props Array of property names. 1st property is icon,
       * second is text
       * @param  {object} data  Map of values
       * @param  {boolean} unescapeCharacters (Optional) specifies whether the renderer
       * should unescape special characters such as % / and \.
       */
      'icon-text': function(props, data, unescapeCharacters) {
			return iconTextExRenderer(props, data, null, unescapeCharacters);
      },


      /**
       *
       *
       * @param  {array} props Array of property names. If no renderConfig is
       * specified it is expected the 1st property is the icon, second is the text.
       *
       * @param  {object} data Map of the property values.
       *
       * @param {object} rendererConfig A dictionary (key/value) to contrl the behavior.
       *    'icon' - if the key is specified it should contain the icon class that
       *       provides the icon.
       *    'iconProperty' - usually you won't specify this if you specify an 'icon'
       *       value. Use this key to specify the name of the property in the
       *       'data' map that holds the icon class.
       *    'dataProperty' - use this key to specify the name of the property in the
       *       'data' map that hold the text to show next to the icon.
       *
       *     e.g {
       *       icon: "vx-icon-vcenter"
       *     }
       *     or {
       *       icon: ["vx-warning", "vx-icon-vcenter"]
       *     }
       *
       * @param  {boolean} unescapeCharacters (Optional) specifies whether the renderer
       * should unescape special characters such as % / and \.
       */
      'icon-text-ex': function(props, data, rendererConfig, unescapeCharacters) {
         return iconTextExRenderer(props, data, rendererConfig, unescapeCharacters);
      },

      /**
       * @see linkRenderer
       */
      'link': linkRenderer,

      /**
       * @see linkAndTextRenderer
       */
      'link-and-text': linkAndTextRenderer,

      /**
       * @see issueStatusRenderer
       */
      'issue-status': issueStatusRenderer
   });

   /**
    * Progress bar renderer, which shows a clickable link in order to request
    * cancellation of the current process.
    *
    * @param progress the current progress value
    * @param cancelable boolean if the process is cancelable
    * @param isCancelPending indicates if a request to cancel the task has been
    * dispatched. If so, show the cancelIcon disabled.
    * @param cancelCallback the callback to be called on click of the cancel link
    */
   function progressBar(progress, cancelable, isCancelPending, cancelCallback) {
      return $interpolate(
         '<vx-static-progress-indicator ' +
         'progress="{{ progress }}" ' +
         'enable-cancel-button="{{ cancelable }}" ' +
         'is-cancel-pending="{{ isCancelPending }}" ' +
         'cancel-callback="{{ cancelCallback }}"/>'
      )({
         progress: progress,
         cancelable: cancelable,
         isCancelPending: isCancelPending,
         cancelCallback: cancelCallback
      });
   }

   /**
    * Renderer to render recent tasks status icon and status label.
    * @param clientTaskInfo the task we are rendering
    */
   function taskStatus(clientTaskInfo) {
      var label = _.escape(taskFormatter.getClientErrorOrSimpleStatus(clientTaskInfo));
      if (label && isPluginTask(clientTaskInfo)) {
         label = label.substr(label.indexOf('\n') + 1);
      }
      var iconClass = taskFormatter.getTaskIconByState(clientTaskInfo.state);
      var iconClassAriaLabel = taskFormatter.hasClientError(clientTaskInfo)
            ? _.escape(i18nService.getString("Common", "error")) : "";
      return '<div class="object"><span ng-non-bindable><span ng-non-bindable title="'
            + _.escape(clientTaskInfo.statusSummary) + '"><span class="vui-icon ' + iconClass + '" '
            + (iconClassAriaLabel && 'aria-label="' + iconClassAriaLabel+ '" ')
            + '></span>' + label + '</span></span></div>';
   }

   /**
    * This renderer does what {@link taskStatus} renderer does with the difference that it only shows
    * the first line of a multi-line status string.
    * @param clientTaskInfo the task we are rendering
    * @returns {string}
    */
   function taskStatusFirstLine(clientTaskInfo) {
      var label = _.escape(taskFormatter.getClientErrorOrSimpleStatus(clientTaskInfo));
      if (label) {
         var newLines = label.split(/[\r\n]/g);
         if (newLines.length && newLines.length > 1) {
            label = newLines[0];
            if (isPluginTask(clientTaskInfo)) {
               label = newLines[1];
            }
         }
      }
      var iconClass = taskFormatter.getTaskIconByState(clientTaskInfo.state);
      var iconClassAriaLabel = taskFormatter.hasClientError(clientTaskInfo)
            ? _.escape(i18nService.getString("Common", "error")) : "";
      return '<div class="object"><span ng-non-bindable><span title="'
            + _.escape(clientTaskInfo.statusSummary) + '"><span class="vui-icon ' + iconClass + '" '
            + (iconClassAriaLabel && 'aria-label="' + iconClassAriaLabel + '" ')
            + '></span>' + label + '</span></span></div>';
   }

   /**
    * Null-checks a string and if present, wraps it in a span with a title
    * attribute(to show a tooltip).
    * @param text the text to show
    * @param title the tooltip title to show, if not set text param will be used
    * @param unescapeCharacters (Optional) specifies whether the renderer
    * should unescape special characters such as % / and \.
    */
   function textWithTooltipRenderer(text, title, unescapeCharacters) {
      // just the falsy check incorrectly disregards 0
      if (!text && text !== 0) {
         return '';
      }

      if (typeof title === 'undefined') {
         title = text;
      }

      if (unescapeCharacters) {
         title = vimEntityEscapingService.unescapeSpecialCharacters(title);
         text = vimEntityEscapingService.unescapeSpecialCharacters(text);
      }

      return ['<span ng-non-bindable class="vertical-aligned-text"><span title="',
         _.escape(title), '">', _.escape(text), '</span></span>'].join('');
   }

   /**
    * Renders text/link with an icon.
    * @param objectId the object reference, if not set, will render plain text
    * @param text the text to show
    * @param icon the object icon
    * @param disabled if set to true, the link will be non-navigatable
    * @param options hash of additional options including "extensionName" to specify a custom extension
    * @param title the tooltip title to show, if not set text param will be used
    * @param navigatable indicates whether the link should navigate to the object or not.
    * @param focusable - if set to true the link receives a href attribute
    */
   function linkRenderer(objectId, text, icon, disabled, options, title, navigatable, focusable) {
      var anchorCssClass = '';
      var iconSpan = '';

      if (typeof title === 'undefined') {
         title = text;
      }

      var textElement;
      if (!text) {
         return '';
      }
      if (!!icon) {
         var iconAriaLabel = resourceUtil.getLocalizedEntityType(objectId);
         var iconAriaLabelAttr = "";
         if (iconAriaLabel) {
            iconAriaLabelAttr = ' aria-label="' + _.escape(iconAriaLabel) + '"';
         }
         var iconParts = iconService.parseIcon(icon);
         if (!iconParts[0]) {
            iconSpan = '<span class="' + icon + '"' + iconAriaLabelAttr + ' ></span>';
         } else if (iconParts[0] === 'clr') {
            iconSpan = '<span><clr-icon shape="' + iconParts[1] + '"' + iconAriaLabelAttr+ '></clr-icon></span>';
         }
      }
      if (disabled) {
         anchorCssClass = 'disabled-link';
      }
      // Disable navigation only when `navigatable` is explicitly specified to `false`
      if (navigatable === false) {
         anchorCssClass += ' non-navigatable-link';
      }
      if (!objectId) {
         textElement = textWithTooltipRenderer(text, title);
      } else {
         var extensionName = (options && options.extensionName) || 'vsphere.core.inventory.serverObjectViewsExtension';
         var navParams = [
            '"' + extensionName + '"',
            '"' + objectId + '"'
         ];

         if (options && options.isKendoGrid) {
            var $jqElement = $('<a/>')
               .addClass(anchorCssClass)
               .attr('onClick', "angular.element('recent-tasks-view').injector().get('navigation').navigateToViewAndObject(" + navParams.join(', ') + ")")
               .html([
                  '<span ng-non-bindable>',
                  $('<span/>').attr('title', title).text(text)[0].outerHTML,
                  '</span>'
               ].join(''));
            if (focusable === true && !$jqElement.attr('href')) {
               // just putting a href attribute to the link to make it focusable
               // in order to enable interaction when Enter or Space is pressed on it
               $jqElement.attr('href', '');
            }
            textElement = $jqElement[0].outerHTML;
         } else {
            text = _.escape(text);

            var onclick = navigatable === false ? '' : "ng-click='$root._navigateToViewAndObject(" + navParams.join(', ').replace(/"/g, '&quot;') + ")'";
            var classStr = anchorCssClass ? 'class="' + anchorCssClass + '"' : '';
            // just putting a href attribute to the link to make it focusable
            // in order to enable interaction when Enter or Space is pressed on it
            var href = focusable === true ? ' href ' : '';
            textElement = [
               '<a ' + classStr + ' ' + onclick + href + '>',
               '<span ng-non-bindable="">',
               '<span title="' + _.escape(title) + '">' + text + '</span>',
               '</span>',
               '</a>'].join('');
         }
      }
      return [
         '<div class="object object-link">',
         iconSpan,
         textElement,
         '</div>'].join('');
   }

   /**
    * Renders text with an optional link to an element in front of it.
    * I.e. link: text.
    * @param objectId the object reference, if not set, will not render link
    * @param linkText the text to show on the link, if not set, will not render link
    * @param labelIds the labelIds associated with the given objectId
    * @param plainText the text to show after the link
    * @param textFirst if true renders the text before the link
    * @param styleClass css class to be applied to the link
    */
   function linkAndTextRenderer(objectId, linkText, labelIds, plainText, textFirst, styleClass) {
      var objectLink = '';
      if (objectId && linkText) {
         var labelIdsString = resourceUtil.getTaggingLabelString(labelIds);

         var objectLinkText = textFirst
               ? linkText + labelIdsString
               : linkText + labelIdsString + ':';

         objectLink = $('<a/>')
            .attr('ng-click', '$root._navigateToObject("' + objectId + '")')
            .html([
               '<span ng-non-bindable>',
               $('<span/>')
                  .attr('title', objectLinkText)
                  .text(objectLinkText)[0].outerHTML,
               '</span>'].join(''))[0].outerHTML;
      }

      var wrappedPlainText = $('<span/>')
          .attr('title', plainText)
          .text(plainText)
          [0].outerHTML;

      var elementsArray = textFirst
            ? ['<div class ="' + styleClass + '">', wrappedPlainText, ' ', objectLink, '</div>']
            : ['<div class ="' + styleClass + '">', objectLink, ' ', wrappedPlainText, '</div>'];

      return elementsArray.join('');
   }

   /**
    * Renders an icon with status text for an issue, based on a provided status string.
    * @param status the issue status string as returned by the backend
    */
   function issueStatusRenderer(status) {

      var issueStatusToIconAndLabelMap = {
         'WARNING': {'iconClass': 'vsphere-icon-status-warning',
            'label': i18nService.getString('Common', 'issueStatus.warning')},
         'ERROR': {'iconClass': 'vsphere-icon-status-error',
            'label': i18nService.getString('Common', 'issueStatus.error')},
         'INFO': {'iconClass': 'vui-icon-info',
            'label': i18nService.getString('Common', 'issueStatus.info')},
         'QUESTION': {'iconClass': 'vsphere-icon-status-unkown',
            'label': i18nService.getString('Common', 'issueStatus.questions')}
      };

      if (!status || !issueStatusToIconAndLabelMap[status]) {
         return '';
      }

      return '<span class="' + issueStatusToIconAndLabelMap[status].iconClass + '"></span>' +
           issueStatusToIconAndLabelMap[status].label;
   }


	function iconTextExRenderer(props, data, rendererConfig, unescapeCharacters) {
		var iconClass = "undefined";
		var text = "";

		if (!rendererConfig) {
			iconClass = data[props[0]];
			text = data[props[1]];
			if (unescapeCharacters) {
				text = vimEntityEscapingService
						.unescapeSpecialCharacters(text);
			}
		} else {
			if (rendererConfig.icon) {
				iconClass = rendererConfig.icon;
			} else if (rendererConfig.iconProperty) {
				iconClass = data[rendererConfig.iconProperty];
			} else {
				iconClass = "unspecified";
			}

			if (rendererConfig.dataProperty) {
				text = data[rendererConfig.dataProperty];
				if (unescapeCharacters) {
					text = vimEntityEscapingService
							.unescapeSpecialCharacters(text);
				}
			} else {
				text = data[props[0]];
				if (unescapeCharacters) {
					text = vimEntityEscapingService
							.unescapeSpecialCharacters(text);
				}
			}
		}

		var title = _.escape(text);

		var iconHtml = '';
		if (Array.isArray(iconClass)) {
			_.each(iconClass, function(item) {
				iconHtml += '<i class="' + _.escape(item) + '"></i>';
			});
		} else {
			iconHtml = '<i class="' + _.escape(iconClass) + '"></i>';
		}

		return '<span ng-non-bindable class="object" title="' + title + '">' + iconHtml + _.escape(text) + '</span>';
	}

    function getPluginTaskState(clientTaskInfo) {
        if (isPluginTask(clientTaskInfo) && clientTaskInfo.state === taskConstants.status.RUNNING) {
            return pluginTaskLabelAndSpinner(clientTaskInfo);
        }
        return null;
    }

   function isPluginTask(clientTaskInfo) {
      return clientTaskInfo.taskTypeId === "com.vmware.vsphere.client.PluginDeploymentTask" ||
            clientTaskInfo.taskTypeId === "com.vmware.vsphere.client.PluginDownloadTask" ||
            clientTaskInfo.taskTypeId === "com.vmware.vsphere.client.PluginUndeploymentTask";
   }

   function pluginTaskLabelAndSpinner(clientTaskInfo) {
      var label = _.escape(taskFormatter.getClientErrorOrSimpleStatus(clientTaskInfo));
      return '<span class="spinner spinner-inline"></span><span>&ensp;' + label + '</span>';
   }
}]);
