/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Util methods to help implement directives.
 *
 * Some(most?) should be integrated into AngularJS itself.
 */
angular.module('com.vmware.platform.ui').factory('directiveUtilService', [
function() {
   'use strict';
   return {
      /*
       * Find a parent controller by walking up the dom tree.
       *
       * Should be in angular itself: https://github.com/angular/angular.js/issues/2057
       */
      findParentController: function(elm, controllerName){

         // mostly copy/pasted from angular.js
         var name = '$' + controllerName + 'Controller';
         var element = elm.parent();
         var value;

         while (element.length) {
            value = element.data(name);
            if (value) {
               return value;
            }
            element = element.parent();
          }
      }
   };
}]);