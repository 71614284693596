namespace platform {
   import IInjectorService = angular.auto.IInjectorService;
   import IPromise = angular.IPromise;

   /***
    * The class wraps the ng-next-app/platform/services/authorization/authorization.service.proxy
    */
   export class AuthorizationServiceWrapper {
      static $inject = ["$injector"];

      private _ng2AuthorizationService: AuthorizationServiceWrapper;

      constructor(private $injector: IInjectorService) {
      }

      get ng2AuthorizationService() {
         // Injecting authorization.proxy.service bridged from ng-next-app.
         // Using regular injection within .service('string',[...]) does not work,
         // due to race condition:
         // Downgraded NG2 modules are registered AFTER .service(..) injections are resolved
         // This way we're postponing injection till first call
         if (!this._ng2AuthorizationService) {
            this._ng2AuthorizationService = this.$injector.get("vcAuthorizationService");
         }
         return this._ng2AuthorizationService;
      }

      public checkPrivilege(objectId: string, privilege: string): IPromise<boolean> {
         return this.ng2AuthorizationService.checkPrivilege(objectId, privilege);
      }

      public checkPrivileges(objectId: string, privileges: string[]): IPromise<Array<boolean>> {
         return this.ng2AuthorizationService.checkPrivileges(objectId, privileges);
      }

      public checkPrivilegesForMultipleObjects(objectIds: string[], privileges: string[])
            : IPromise<{ [objectId: string]: { [privilege: string]: boolean } }> {
         return this.ng2AuthorizationService.checkPrivilegesForMultipleObjects(objectIds, privileges);
      }

      public checkGrantedPrivilege(objectId: string, privilege: string): IPromise<boolean> {
         return this.ng2AuthorizationService.checkGrantedPrivilege(objectId, privilege);
      }

      public checkGrantedPrivileges(objectId: string, privileges: string[]): IPromise<Array<boolean>> {
         return this.ng2AuthorizationService.checkGrantedPrivileges(objectId, privileges);

      }

      public checkGrantedPrivilegesForMultipleObjects(objectIds: string[], privileges: string[])
               : IPromise<{ [objectId: string]: { [privilege: string]: boolean } }> {
         return this.ng2AuthorizationService.checkGrantedPrivilegesForMultipleObjects(objectIds, privileges);
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("authorizationService", AuthorizationServiceWrapper);
}
