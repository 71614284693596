/* Copyright 2013-2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
* Controller for QuickSearchView.
*/

angular.module('com.vmware.platform.ui').controller('QuickSearchViewController',
['$scope', '$http', '$httpParamSerializer',
function($scope, $http, $httpParamSerializer) {
   // function to navigate to a given object eg VM, DataCenter, Host
   function navigate(object,path){
      if (angular.isDefined(path) && angular.isDefined(object)){
         // build search context object for simple search
         var searchContext = {
            query : object,
            searchType:'simple'
         };

         $scope._navigate(path, undefined, searchContext, {triggerONChange: true, replaceSameHistory : true});
         return ;
      } else if (angular.isDefined(object)){
         $scope._navigateToObject(object.id);
      }
   }


   /**
   * Get the results from backend given the searchTerm
   * @param {string} searchTerm - The searchTerm
   */
   function getResults(searchTerm){
      var validatedSearchTerm = _.isString(searchTerm) ? searchTerm : '';

      var serializedSearchTerm = $httpParamSerializer({
         query: validatedSearchTerm,
         opId: ":" + (Math.floor(Math.random() * 1000000000) + 1000000000)
      });

      // build the RestEndpoint URL could be made into a service
      var url = 'search/quicksearch/?' + serializedSearchTerm;

      // get results and return
      return $http.get(url).then(function(resp){
         return resp.data;
      });
   }

   // config object for typeahead and popupResults
   $scope.typeAheadConfig = {
         isInputRefocused : true,
         dataSource : getResults,
         onSelectCallback : navigate
   };

}]);
