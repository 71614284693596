/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Component used to display "No item selected" message in master-details views.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxNoItemsSelected', ['i18nService', vxNoItemsSelected]);

   function vxNoItemsSelected(i18nService) {
      return {
         restrict: 'E',
         replace: true,
         scope: {
            message: '=?'
         },
         template: [
            '<div class="no-items-selected-container">',
               '<span class="no-items-selected-text" ng-bind="message || defaultMessage" />',
            '</div>'
         ].join(''),
         link: function($scope) {
            if (!$scope.defaultMessage) {
               $scope.defaultMessage = i18nService.getString(
                     'Common', 'ListPreviewSplitView.noItemsSelected');
            }
         }
      };
   }
})();