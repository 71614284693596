/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Wraps $http to provide request cancellation logic.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .factory('httpCancellationService', httpCancellationService);

   httpCancellationService.$inject = ['$http', '$q'];

   function httpCancellationService($http, $q) {
      return function() {
         var deferred;

         function fetch(httpConfig) {
            cancel();

            deferred = $q.defer();

            httpConfig = httpConfig || {};
            httpConfig.timeout = deferred.promise;

            return $http(httpConfig);
         }

         function cancel() {
            if (deferred) {
               // $http timeout allows only promises created by $timeout to be
               // used as parameter. However we want to cancel the request not
               // based on elapsed time but rather based on the fact that a new
               // navigation request has been dispatched so we ser $$timeoutId to
               // imitate a promise created by $timeout.
               // https://github.com/angular/angular.js/blob/v1.7.x/src/ng/httpBackend.js#L173
               deferred.promise.$$timeoutId = -1;
               // Do not report error on rejected promise.
               // https://github.com/angular/angular.js/blob/v1.7.x/src/ng/q.js#L682
               deferred.promise.$$state.pur = true;
               // $timeout.cancel() internally calls promise.reject() so we do the same
               // https://github.com/angular/angular.js/blob/v1.7.x/src/ng/timeout.js#L102
               deferred.reject('canceled');
            }
         }

         // Public API
         return {
            /**
             * Wraps $http.
             *
             * Always cancels previous fetch.
             *
             * @param {Object} httpConfig Passed to $http.
             * @returns {Promise}
             */
            fetch: fetch,

            /**
             * Cancels the previously initiated fetch request.
             *
             * NOTES:
             * This does not resolve neither reject the $http promise.
             * Subsequent calls to cancel do nothing unless a fetch is in progress.
             */
            cancel: cancel
         };
      };
   }
}());

