angular.module('com.vmware.platform.ui').directive('vxMultiDatagridMember', [
   function () {
      return {
         link: function ($scope, element, attributes) {
            onSelection($scope, attributes, function(){
               $scope.$emit('vx-multi-datagrid.selection-changed', element);
            });
         }
      };

      function onSelection($scope, attributes, fn) {
         $scope.$watch(attributes.vuiDatagrid + '.selectedItems', function(selectedItems){
            if(selectedItems.length > 0) {
               fn();
            }
         });
      }
   }]);