/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   /**
    * Service to retrieve privileges.
    */
   angular.module('com.vmware.platform.ui').factory('userPrivilegeService', ['dataService', 'defaultUriSchemeUtil',
      function (dataService, defaultUriSchemeUtil) {
         return {
            checkUserPrivilege: checkUserPrivilege,
            getUserPrivileges: getUserPrivileges
         };

         function checkUserPrivilege(objectId, privilege) {
            return this.getUserPrivileges(objectId).then(function(data) {
               return data.grantedPrivileges && data.grantedPrivileges.indexOf(privilege) >= 0;
            });
         }

         function getUserPrivileges(object) {
            var objectId;
            if (typeof object === 'object') {
               objectId = defaultUriSchemeUtil.getVsphereObjectId(object);
            } else {
               objectId = object;
            }
            return dataService.getProperties(objectId, ['grantedPrivileges']);
         }
      }
   ]);
})();
