/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Data structure class to help make it easier to interact with the navigation tree...
 */
interface TreeNode {
   $id: string;
   $selectedChildIndex: number;
   $children: TreeNode[];
   $templateUrl: string;
   $viewRetentionPolicy: string;
   getSelectedChild(): TreeNode | null;
   getNonEmptyChildren(): TreeNode[];
}

class NavigationTree {
   /**
    * root is of the form:
    * {
    *    $id: string,
    *    $selectedChildIndex: int,
    *    $children: [],
    *    other fields...
    * }
    */
   constructor(private root: TreeNode) {
      // this adds the helper getSelectedChild() method to every node
      // we might refactor to use proper prototypes in the future
      this.everyNode((node: TreeNode) => {
         node.getSelectedChild = (): TreeNode | null => {
            return this.getSelectedChild(node);
         };
         node.getNonEmptyChildren = (): TreeNode[] => {
            const result: TreeNode[] = [];
            for (let i = 0; i < node.$children.length; i++) {
               const child: TreeNode = node.$children[i];
               // Skip extensions created by vsphere.core.inventory.objectViewTemplate
               // for which there is no content yet, to avoid empty tabs
               if (child.$templateUrl !== "resources/ui/views/TocView.html" ||
                   (angular.isArray(child.$children) && (child.$children.length > 0))) {
                  result.push(child);
               }
            }
            return result;
         };
      });
   }

   static everyNode(node: TreeNode, visitor: (node: TreeNode, parent?: TreeNode) => void,
                    childFieldName: string, parent?): void {
      visitor(node, parent);
      node[childFieldName].forEach(child => {
         NavigationTree.everyNode(child, visitor, childFieldName, node);
      });
   }

   // visitor(node,parent) function will be called on every node of this tree
   everyNode(visitor: (node: TreeNode, parent?: TreeNode) => void): void {
      return NavigationTree.everyNode(this.root, visitor, '$children');
   };

   // returns the leaf node of the navigation tree
   getSelectedLeafNode(): TreeNode {
      let n = this.root;

      let child;
      while (n.getSelectedChild && (child = n.getSelectedChild())) {
         n = child;
      }

      return n;
   };

   // searches for a node by id, then return {node, parent}
   getById(id: string): {node: TreeNode, parent: TreeNode} {
      const result = <any>{};

      // not the most optimized way...
      this.everyNode((node: TreeNode, parent: TreeNode) => {
         if (node.$id === id) {
            result.node = node;
            result.parent = parent;
         }
      });

      return result;
   };

   // returns the node at a certain depth, 0 being the root node
   getNodeAtLevel(idx: number): TreeNode | null {
      let n: TreeNode | null = this.root;
      for (let i = 0; i < idx; i++) {
         n = this.getSelectedChild(n);

         if (!n) {
            return null;
         }
      }
      return n;
   };

   // return the selected node at the next level, or null
   getSelectedChild(node: TreeNode): TreeNode | null {
      if (node && node.$selectedChildIndex !== -1) {
         return node.$children[node.$selectedChildIndex] || null;
      }
      return null;
   }
}
