/* Copyright 2014 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * @ngdoc directive
 * @name com.vmware.platform.ui:vxValid
 *
 * @description
 ** Directive to validate an input field for combination ONE or MORE of the following:<BR>
 * 1. IPv4, IPv6, fully qualified domain name(FQDN). <BR>
 * 2. RegExp expression. <BR>
 * 3. Validation Function.<BR><BR>
 *
 ** Most important part of this directive is that all the validations will be performed
 * and "LOGICAL AND" will be performed on all the results. This means that vxValid element
 * will be marked 'invalid' even if one of the validationRules turns out 'invalid'.<BR>
 *
 ** validationRules: Can have SINGLE or MULTIPLE options.These options can be a combination
 * of contants, regex expressions, and custom validation functions.<BR><BR>
 *
 ** Each validation entry is an javascript object containing a single validation rule,
 * which is in the form of {key: validationOption}. Key is the key which can be used to
 * get the validation result of the validationOption. validationOption could be constant,
 * a regular expression, or a custom validation function. Note that the custom validation
 * function should return a boolean value to indicate if the input passes the validation
 * or not. Any non-boolean value as returned will be ignored and the validity will be false.
 *
 * The validation result (validity) of a rule can be obtained by:
 * someForm.someControl.$error.myErrorKey. myErrorKey is the key of the validation entry.
 *
 ** In case, if input value is an empty string then this directive will not validate the field.
 * To handle validation for non-empty inputs, please use "required" directive like this:
 * <input required/>.
 *
 * In below are examples 'vx-valid' takes a 'vxValidConfig' object.<BR><BR>
 *
 * ******
 * Usage:
 * ******
 * <pre><code>
 *    <input vx-valid ="vxValidConfig" />
 *
 *    <script>
 *      $scope.vxValidConfig = {
 *         validationRules: [
 *            { ipv4: 'ipv4' },
 *            { fqdn: 'fqdn' },
 *            { key1: new RegExp("/^[a-zA-Z ]*$/",'i') },
 *            { key2: function a(input) {return true;} },
 *            { key3: /^[a-zA-Z ]*$/i }
 *         ]
 *      };
 *    </script>
 * </code></pre>
 *<BR>
 *
 * PS: In case you need to add more RegExp constants, you can add them to 'regexConstants' object.<BR>
 *
 * @element input
 * @scope parent
 * @priority default
 *
 */
angular.module('com.vmware.platform.ui')

/**
 * Service which provides regular expressions based on constant strings.
 * In case you need to add more RegExp constants, you can add them to 'regexConstants' object.
 ** Examples:
 * ipv4 and ipv6 - matches with standard IP address formats.
 * fqdn - matches with fully qualified domain name similar to 'promc12-49.vmware.com'
 * port - integer number ranging in between 1 and 49151.
 * url - urls matching with strings beginning with http/https/ftp. For example: https://vapp-upds.vmware.com/catalog/alm/8e7-69/5.5.0.10.latest
 * or it could match with simple urls like http://acme.com/
 **/
.constant(
   'regexConstants', {
      'ipv4': /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
      'ipv6': /^((?=.*::)(?!.*::.+::)(::)?([\dA-F]{1,4}:(:|\b)|){5}|([\dA-F]{1,4}:){6})((([\dA-F]{1,4}((?!\3)::|:\b|$))|(?!\2\3)){2}|(((2[0-4]|1\d|[1-9])?\d|25[0-5])\.?\b){4})$/i,
      'fqdn': /^(?=.{1,254}$)((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$/i,
      'port': /^(4915[0-1]|491[0-4]\d|490\d\d|4[0-8]\d{3}|[1-3]\d{4}|[1-9]\d{0,3}|0)$/,
      'url' : /\(?(?:(http|https|ftp):\/\/)?(?:((?:[^\W\s]|\.|-|[:]{1})+)@{1})?((?:www.)?(?:[^\W\s]|\.|-)+[\.][^\W\s]{2,4}|localhost(?=\/)|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})(?::(\d*))?([\/]?[^\s\?]*[\/]{1})*(?:\/?([^\s\n\?\[\]\{\}\#]*(?:(?=\.)){1}|[^\s\n\?\[\]\{\}\.\#]*)?([\.]{1}[^\s\?\#]*)?)?(?:\?{1}([^\s\n\#\[\]]*))?([\#][^\s\n]*)?\)?/
   }
)

.directive('vxValid', ['regexConstants',

   function(regexConstants) {
      'use strict';

      return {
         restrict: 'A',
         require: 'ngModel',
         link: function(scope, element, attrs, ctrl) {

            /**
             * TODO - Below is a work around for retrieving the validation options from
             * the scope of <input/>. This work around needs to be modified once angularjs
             * is updgraded to 1.2.0 RC3 or above. Scope of this directive must be changed
             * to isolated. This is happening because ngModel doesnt work in isolated scope.
             **/
            var validationRules = scope[attrs.vxValid].validationRules;

            function getRegExp(key) {
               return regexConstants[key];
            }

            /**
             * Add a parser to the <input/> element.
             * This function is invoked everytime the input is edited.
             **/
            ctrl.$parsers.unshift(function(input) {
               var isValid = false;
               var isValidationPerformed = false;

               if (typeof(validationRules) !== 'undefined' && input !== '') {
                  if(!Array.isArray(validationRules)){
                     validationRules = [validationRules];
                  }

                  // Validation is performed for every option in validationRules.
                  validationRules.forEach(function(option) {
                     // get the key and the validation rule
                     var key = Object.keys(option)[0];
                     var validationRule = option[key];
                     var valid = false;

                     /**
                      * 1. Input validated against constant RegExp.
                      * 2. Else, Input validated against custom RegExp.
                      * 3. Else, Input validated against executable Function.
                      **/
                     if (typeof(validationRule) === 'string') {
                        valid = getRegExp(validationRule).test(input);
                     } else if (validationRule instanceof RegExp) {
                        valid = validationRule.test(input);
                     } else if (typeof(validationRule) === 'function') {
                        var funcResult = validationRule(input);
                        if(typeof(funcResult) === 'boolean'){
                           valid = funcResult;
                        }
                     }

                     isValid = isValid || valid;

                     ctrl.$setValidity(key, valid);
                  });
                  isValidationPerformed = true;
               }

               // If none of the above validations are peformed then return true.
               isValid = isValid || !isValidationPerformed;

               // Set the vailidity of the input element with the result of validation.
               ctrl.$setValidity('vxValid', isValid);

               // If input is valid then return the input else return undefined.
               if (isValid) {
                  return input;
               } else {
                  return undefined;
               }
            });
         }
      };
   }
]);