/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Service for opening dialogs and wizards (all popups) based on actions framework actions.
 * Can be extended to work for other non-action integrated actions later on. Also will be integrated with TIWO
 */
angular.module('com.vmware.platform.ui')
.factory('actionDialogService',['$q', '$rootScope', 'vuiDialogService', 'dataService', 'i18nService', function($q, $rootScope, vuiDialogService, dataService, i18nService) {

   //======================= Public Functions ==========================
   /**
    * Opens an OK/Cancel modal dialog in the application.
    *
    * @param actionEval {Object} ActionEval associated with the action that is being invoked.
    *
    * @param availableTargets {string[]} Identifiers for server objects on which the action will be performed.
    *
    * @param dialogData {Object} Additional data passed to the dialog/wizard from the source.
    *
    * @param contentUrl {string} Url of the html template of the dialog content.
    *
    * @param extraDialogOptions {Object} Additional data for vuiDialogService to use (height, width...)
    */
   var openActionDialog = function(actionEval, availableTargets, dialogData, contentUrl, extraDialogOptions, modalOptions) {
      var scope = $rootScope.$new();
      var dialogOptions = angular.extend({
         show: true,
         iconClass: actionEval.action.icon,
         contentUrl: contentUrl,
         actionDetails: {
            actionEval: actionEval,
            availableTargets: availableTargets,
            dialogData: dialogData
         },
         width: '400px',
         height: '200px'
      }, extraDialogOptions || {});

      if (extraDialogOptions) {
         // amarinov: Merge dialogOptions back to extraDialogOptions so that the caller would be given the ability to
         // modify the dialog options after the dialog is shown through the extraDialogOptions
         dialogOptions = angular.extend(extraDialogOptions, dialogOptions);
      }

      scope.dialogOptions = dialogOptions;

      scope.dialogOptions.title = getDialogTitle(actionEval, availableTargets, scope.dialogOptions);

      var options = angular.merge({}, modalOptions);
      options = angular.extend(options, {
         scope: scope,
         configObjectName: 'dialogOptions'
      });

      return vuiDialogService(options);
   };

   //======================= Private Functions ==========================
   /**
    * Immediately returns some title so that the dialog can be shown as soon as
    * possible.
    * If needed, schedules a request for the target object's name.
    */
   function getDialogTitle(actionEval, availableTargets, dialogOptions) {
      // we have a localized title without placeholders
      if (dialogOptions.title && dialogOptions.title.indexOf('{0}') === -1) {
         return dialogOptions.title;
      }
      if (!availableTargets) {
         return actionEval.action.label;
      }
      if (availableTargets.length === 1) {
         // Get the name of the object and make the new title.
         setTitleForSingleObject(availableTargets[0], dialogOptions, actionEval,
               dialogOptions.title);
         return actionEval.action.label;
      } else {
         return i18nService.getString('Common', 'dialogTitleFormatMultiple',
               availableTargets.length, actionEval.action.label);
      }
   }

   function setTitleForSingleObject(objectId, dialogOptions, actionEval, placeholderTitle) {
      return dataService.getProperties(objectId, ['name']).then(function(data) {
         if (!data.name) {
            return;
         }
         if (placeholderTitle) {
            // Remove the placeholder from the localized title if it exists
            dialogOptions.title = i18nService.interpolate(placeholderTitle,
                  [data.name]);
         } else {
            dialogOptions.title = i18nService.getString('Common',
                  'dialogTitleFormat', data.name, actionEval.action.label);
         }
      });
   }

   // ======================= Public API ==========================
   return {
      openActionDialog : openActionDialog
   };
}]);
