/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
angular.module('com.vmware.platform.ui').factory('entityStatusService', ['i18nService', function (i18nService) {
   return {
      statusProperties: function (status) {
         var iconClass = 'vui-icon-datagrid-status-unknown';
         var label = i18nService.getString('Common', 'entityStatus.gray');
         if (status === 'green') {
            iconClass = 'vsphere-icon-status-ok';
            label = i18nService.getString('Common', 'entityStatus.green');
         } else if (status === 'yellow') {
            iconClass = 'vsphere-icon-status-warning';
            label = i18nService.getString('Common', 'entityStatus.yellow');
         } else if (status === 'red') {
            iconClass = 'vsphere-icon-status-error';
            label = i18nService.getString('Common', 'entityStatus.red');
         }
         return {
            iconClass: iconClass,
            label: label
         };
      }
   };
}]);