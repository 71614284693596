/**
 * Controller for the directive that shows Monitor > Issues > All Issues list.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui')
         .controller('CommonIssuesViewController', CommonIssuesViewController);

   CommonIssuesViewController.$inject = [
      '$scope',
      'i18nService',
      'columnRenderersRegistry',
      'timeFormatterService',
      'alarmIssueActionService',
      'vuiConstants',
      '$element',
      'datagridActionBarService',
      'issueService'
   ];

   function CommonIssuesViewController($scope,
         i18nService,
         columnRenderersRegistry,
         timeFormatterService,
         alarmIssueActionService,
         vuiConstants,
         $element,
         datagridActionBarService,
         issueService) {

      var linkAndTextRenderer = columnRenderersRegistry.getColumnRenderer('link-and-text');
      var issueStatusRenderer = columnRenderersRegistry.getColumnRenderer('issue-status');

      var self = this;
      self.objectId = $scope._route.objectId;

      var LONG_DATE_OPTIONS = {
         year: "numeric",
         month: "2-digit",
         day: "2-digit",
         hour: "2-digit",
         minute: "2-digit"
      };

      // Refresh the list if the manual refresh button is clicked.
      $scope.$on('dataRefreshInvocationEvent', function() {
         refreshGrid();
      });
      // Refresh the list if the "modelChanged" event comes - this is for auto-refresh only in the current session
      // no live updates
      $scope.$on('modelChanged', function() {
         refreshGrid();
      });

      // flag indicating if a request for the issues is currently in progress
      self.pendingRequest = false;

      self.datagridOptions = {
         actionBarOptions: {},
         pageable: true,
         pageConfig: {
            displayMode: vuiConstants.grid.displayMode.VIRTUAL_SCROLLING,
            size: 50
         },
         data: {
            url: 'issues',
            params: {objectId: self.objectId},
            dataType: vuiConstants.grid.dataType.JSON,
            method: vuiConstants.grid.dataMethod.GET,
            data: 'summaryItemSpecs',
            total: 'totalMatchedObjectCount',
            change: function(data) {
               if(!data.items) {
                  return;
               }
               timeFormatterService.getUserTimeFormatPreference()
                  .then(function(timeFormat) {
                     data.items.forEach(function(item, dataIndex) {
                        if (item.timestamp) {
                           var date = timeFormatterService.timestampToText(item.timestamp, timeFormat, LONG_DATE_OPTIONS);
                           $('.dateText').html(function(index, currentContent) {
                              if (index === dataIndex) {
                                 return date;
                              } else {
                                 return currentContent;
                              }
                           });
                        }
                     });
                  });

            }
         },
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         height: '99%',
         resizable: true,
         reorderable: true,
         pageSize: 50,
         columnDefs: [
            {
               displayName: i18nService.getString('Common', 'issueList.descriptionColumnLabel'),
               field: 'description',
               template: function(dataItem) {
                  var details = dataItem.details;
                  return linkAndTextRenderer(
                        dataItem.entityId,
                        dataItem.entityName,
                        details ? details.entityLablesIds : null,
                        dataItem.description
                  );
               }
            },
            {
               displayName: i18nService.getString('Common', 'issueList.typeColumnLabel'),
               field: 'issueType'
            },
            {
               displayName: i18nService.getString('Common', 'issueList.timestampColumnLabel'),
               field: 'dateText',
               template: '<span class="dateText">#:dateText#</span>'
            },
            {
               displayName: i18nService.getString('Common', 'issueList.statusColumnLabel'),
               field: 'status',
               template: function(dataItem) {
                  return issueStatusRenderer(dataItem.status);
               }
            }
         ]
      };

      $scope.$watch("ctrl.datagridOptions.selectedItems", function() {
         var selectedItems = self.datagridOptions.selectedItems;
         updateActionBar(selectedItems);
      });

      this.showActionsMenu = function(event) {
         if (event.data[0].issueType === 'Triggered Alarm') {
            alarmIssueActionService.showActionsMenu(
                  event,
                  $scope,
                  event.data[0].details.alarmInfo,
                  event.data[0].details.alarmState,
                  event.clientX,
                  event.clientY,
                  event.currentTarget);
         }
      };

      function refreshGrid() {
         getDataSource().read();
         updateActionBar();
      }

      function getGrid() {
         return $element.find('.k-grid-content').parent();
      }

      function getDataSource() {
         var grid = getGrid();
         return grid.data('kendoGrid').dataSource;
      }

      $scope.$on('IssueChangeEvent', refreshGrid);

      function updateActionBar(selectedItems) {
         // the action evaluations for each action available for the selected issue
         var commonActionBarServiceCacheObject = {};
         self.datagridOptions.actionsOptions = {};
         var actionSpecs = [];
         // clear the actions
         datagridActionBarService.updateActionBar(self.datagridOptions, [self.objectId],
               actionSpecs, commonActionBarServiceCacheObject);

         _.each(selectedItems, function(selectedItem) {
            issueService.evaluateActions(self.objectId, selectedItem.issueUid, selectedItem.details)
                  .then(function(actionEvaluations) {
                     _.each(actionEvaluations, function(actionEval) {
                        // add the already retrieved action evaluation to the cache object
                        // used in the action bar service
                        commonActionBarServiceCacheObject[actionEval.invoker] = actionEval;
                        actionSpecs.push({
                           actionId: actionEval.invoker,
                           getActionInvocationContext: function() {
                              // return the alarm as a context object
                              // this is needed for reset and acknowledge actions
                              return selectedItem;
                           }
                        });
                     });

                     //initialize the new actions in the action bar
                     datagridActionBarService.updateActionBar(self.datagridOptions, [self.objectId],
                           actionSpecs, commonActionBarServiceCacheObject);
                  });
         });


      }
   }
})();
