/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for ControlCenterView (shortcuts on home screen).
 */

(function () {
    'use strict';
    angular
        .module('com.vmware.platform.ui')
        .controller('ControlCenterViewController', ControlCenterViewController);

    ControlCenterViewController.$inject = ['$scope', 'extensionService', 'extensionCategoryUtil'];

    //TODO jaked $scope can be removed once we have a NavigationService.navigate
    function ControlCenterViewController($scope, extensionService, extensionCategoryUtil) {
        var categorySpec, shortcutSpec;
        //-----View bindable logic--------//
        var self = this;
        self.categories = [];
        self.hasClass = hasClass;

        /*
         * Navigate to the shortcut target view uid when the shortcut is clicked
         */
        self.onSelect = onSelect;

        //----- Service calls --------//
        // Get all shortcuts
        extensionService.getExtensions('vise.home.shortcuts', null).then(function (data) {
            for (var i = 0; i < data.length; i++) {
                // if the icon is empty or not present add a default icon
                if (!data[i].icon || /^\s*$/.test(data[i].icon)) {
                    data[i].icon = 'vx-icon-defaultShortcut';
                } else if (data[i].isPluginShortcut) {
                    data[i].icon = "controlcenter-shortcut-plugin-icon " + data[i].icon;
                }
            }
            shortcutSpec = data;
            updateCategoryData(categorySpec, shortcutSpec);
        });

        // Get all categories
        extensionService.getExtensions('vsphere.core.controlcenter.shortcutCategories', null)
            .then(function (data) {
                categorySpec = data;
                updateCategoryData(categorySpec, shortcutSpec);
            });

        function hasClass(shortcut) {
           // Eventually will be removed when all icon attributes refer to class names instead of image file names
           return shortcut.icon.indexOf('.png') === -1;
        }

        //----- Private functions --------//
        function updateCategoryData(categorySpec, shortcutSpec) {
            // if both the data are defined, call service to combine them based
            // on categoryUid
            if (categorySpec && shortcutSpec) {
                self.categories = extensionCategoryUtil.mergeCategories(categorySpec, shortcutSpec);
            }
        }

        function onSelect(shortcut) {
            if (angular.isDefined(shortcut.targetViewUid)) {
                $scope._navigateToView(shortcut.targetViewUid);
            }
        }
    }
})();
