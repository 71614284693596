module platform {
   import IPromise = angular.IPromise;

   //region interfaces

   export interface ResourceOptions extends DataOptions {
      supportedKey?: string;
   }

   export interface ResourceEntry {
      key: string;
      label: string;
      value: any;
      type?: string;
      description?: string;
      staticValueSpace?: any[];
   }

   export interface ResourceResult {
      datasetSupported: boolean;
      properties: ResourceEntry[];
   }

   interface DynamicObject {
      properties: NameValuePair[];
   }

   interface NameValuePair {
      name: string;
      value: any;
   }

   interface TypeNodeDescriptor {
      properties: DataElementDescriptor[];
   }

   interface DataElementDescriptor {
      name: string;
      title: string;
      valueTypeName: string;
      description: string;
      staticValueSpace: any[];
   }

   //endregion

   export class ResourceService {
      public static $inject = ['dataService'];

      public constructor(private dataService: DataService) {
      }

      //region public functions

      /**
       * @ngdoc method
       * @name com.vmware.platform.ui.resourceService#getResourceInfo
       * @methodOf com.vmware.platform.ui.resourceService
       *
       * @description
       *    Get the RI data, normalized to the same structure as getData()
       *
       * @param {string} objectId
       *    ObjectId of the entity/object whose data is to be fetched.
       *
       * @param {string} propertyName
       *    Name of a property to fetch.
       *
       * @param {object} options (optional)
       *    This is an object containing optional arguments passed to the function.
       *    All valid options' properties are mention below as follows:
       *
       * @param options.supportedKey (optional)
       *
       * @param {boolean} options.skipErrorInterceptor (optional)
       *    Passed to $http requests to skip the `errorResponseInterceptor`.
       *    Use this option only once your calling code is handling DS errors properly.
       *    In all other cases the default error popup will be displayed in the
       *    lower right corner and errors will be recorded in PhoneHome.
       *
       * @returns {object}
       *    Data
       */
      public getResourceInfo(objectId: string, propertyName: string,
            options: ResourceOptions = {}): IPromise<ResourceResult> {
         var infoPropertyName = `${propertyName}.@info`;

         return this.dataService.getProperties(
               objectId, [propertyName, infoPropertyName], options)
               .then(function (results) {
                  var data: DynamicObject = results[propertyName];
                  var metaData: TypeNodeDescriptor = results[infoPropertyName];
                  var returnValue: ResourceResult = {
                     datasetSupported: true,
                     properties: []
                  };

                  if (data && options.supportedKey) {
                     var datasetSupportedEntry = _.find(data.properties, function (entry) {
                        return entry.name === options.supportedKey;
                     });
                     returnValue.datasetSupported = !!(datasetSupportedEntry &&
                     datasetSupportedEntry.value);
                  }

                  if (data && returnValue.datasetSupported) {
                     _.reduce<NameValuePair, ResourceEntry[]>(
                           data.properties,
                           (memo, obj) => {
                              if (obj.name === options.supportedKey) {
                                 return memo;
                              }
                              var entry: ResourceEntry = {
                                 key: obj.name,
                                 label: obj.name,
                                 value: obj.value
                              };
                              if (metaData) {
                                 var matchedMetaData: DataElementDescriptor | undefined =
                                       _.find<DataElementDescriptor>(metaData.properties,
                                             (metaEntry) => {
                                                return metaEntry.name === obj.name;
                                             });
                                 if (matchedMetaData) {
                                    entry.label = matchedMetaData.title;
                                    entry.type = matchedMetaData.valueTypeName;
                                    entry.description = matchedMetaData.description;
                                    entry.staticValueSpace = matchedMetaData.staticValueSpace;
                                 }
                              }

                              memo.push(entry);
                              return memo;
                           },
                           returnValue.properties
                     );
                  }
                  return returnValue;
               });
      }
   }

   //endregion

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.resourceService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Utility methods for fetching resource framework data and metadata.
    */
   angular.module('com.vmware.platform.ui').service('resourceService', ResourceService);
}