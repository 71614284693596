/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive for right-click. Given a function callback for the vxRightClick attribute, it calls that function.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxRightClick', vxRightClick);

   vxRightClick.$inject = ['$parse'];

   function vxRightClick($parse) {
      var directive = {
            link: link
      };
      return directive;

      function link(scope, element, attrs) {
         var fn = null;
         if (attrs.vxRightClick !== '') {
            fn = $parse(attrs.vxRightClick);
         }
         element.bind('contextmenu', function(event) {
            if (fn === null) {
               event.preventDefault();
               return;
            }

            scope.$apply(function() {
               event.preventDefault();
               fn(scope, {$event:event});
            });
         });
       }
   }

})();