namespace common_ui {

   import StorageSelectorState = common_ui.StorageSelectorState;
   import StorageSelectorData = common_ui.StorageSelectorData;
   import VmStorageConfig = common_ui.VmStorageConfig;
   import VmComponentStorageConfig = common_ui.VmComponentStorageConfig;
   import StorageSelectorModeSettings = common_ui.StorageSelectorModeSettings;
   import StorageSelectorDiskFormatSettings = common_ui.StorageSelectorDiskFormatSettings;
   import StorageSelectorApi = common_ui.StorageSelectorApi;
   import StorageSelectorInitialConfig = common_ui.StorageSelectorInitialConfig;
   import StorageSelectorDialogData = common_ui.StorageSelectorDialogData;

   export interface StorageSelectorVmData {
      id: string;
      name: string;
      homeDatastore: string;
      destinationHost?: string;
      disksData?: any;
   }

   export class StorageSelectorService {

      public static $inject = ["$q", "i18nService", "storageProfileService",
            "defaultUriSchemeUtil", "bytesFilter",
            "clarityModalService", "clarityConstants"];

      constructor(private $q: any,
                  private i18nService: any,
                  private storageProfileService: any,
                  private defaultUriSchemeUtil: any,
                  private bytesFilter: any,
                  private clarityModalService: any,
                  private clarityConstants: any) {
      }

      public getStorageSelectorApiObj(): StorageSelectorApi {
         return new StorageSelectorApi();
      }

      public getStorageSelectorState(): StorageSelectorState {
         let state = new StorageSelectorState();
         state.mode = "basicMode";
         return state;
      }

      public getStorageSelectorInitialConfig(
            selectedItemInBasicMode: any,
            storageProfile: StorageProfile| null,
            replicationGroup?: any|null): StorageSelectorInitialConfig {

         let initialConfig = new StorageSelectorInitialConfig();
         initialConfig.selectedItemInBasicMode = selectedItemInBasicMode;
         initialConfig.selectedStorageProfileInBasicMode = storageProfile;
         initialConfig.selectedReplicationGroupInBasicMode = replicationGroup;
         return initialConfig;
      }

      public getStorageSelectorModeSettings(
            showAdvancedMode: boolean): StorageSelectorModeSettings {
         let settings = new StorageSelectorModeSettings();
         settings.showAdvancedMode = showAdvancedMode;
         return settings;
      }

      public getStorageSelectorData(
            objectId: string,
            storageLocatorItemsData: any,
            includeKeepExistingPolicies: boolean,
            vmData: StorageSelectorVmData[],
            storageProfilesData: any) {

         vmData = vmData || [];

         let vcGuid = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId).serverGuid;

         let profileRequest = storageProfilesData
               ? this.$q.when(storageProfilesData)
               : this.storageProfileService.fetchStorageProfilesData(vcGuid, {
                  includeKeepExisting: includeKeepExistingPolicies
               });

         let requests = {
            storageLocatorItemsDataRequest: this.$q.when(storageLocatorItemsData),
            storageProfilesDataRequest: profileRequest
         };

         return this.$q.all(requests).then( (response) => {
            let selectorData = new StorageSelectorData();
            if (!response) {
               return selectorData;
            }

            selectorData.storageLocatorItemsData = response.storageLocatorItemsDataRequest;
            selectorData.storageProfilesData = response.storageProfilesDataRequest;

            // Set the group labels for the storage profiles
            _.each(selectorData.storageProfilesData.storageProfiles, (profile) => {
               // "Encryption" group label
               let isEncryptionPolicy: boolean = this.storageProfileService
                     .isEncryptionStorageProfile(profile);

               if (isEncryptionPolicy) {
                  profile.groupLabel = this.i18nService
                        .getString("Common", "storage.policy.dropdown.group.encryption");
               } else {
                  profile.groupLabel = "";
               }

               // Add logic for other group labels below..
            });

            let vmIds: string[] = [];
            _.forEach(vmData,  (vm: StorageSelectorVmData) => {
               if (vm && vm.id) {
                  vmIds.push(vm.id);
               }
            });

            if (!vmIds.length) {

               let firstVmData:StorageSelectorVmData|null =
                     vmData && vmData.length ? vmData[0] : null;

               // If there are no source VMs, create a dummy VmStorageConfig so that
               // storageSelector have a way to report the selected storage, policy &
               // disk format. This case should handle selecting storage for a
               // new VM or Deploy from OVF/CL.
               let storageConfig = this.buildDummyVmConfig(
                     selectorData.storageLocatorItemsData,
                     selectorData.storageProfilesData,
                     firstVmData);
               selectorData.sourceVmStorageConfig = [storageConfig];
               return selectorData;
            }

            // Request vm profile assignments
            let vmAssignmentsPromise;
            let isSpbmEnabled = selectorData.storageProfilesData && !selectorData.storageProfilesData.error;
            if (isSpbmEnabled) {
               vmAssignmentsPromise = storageProfilesData && storageProfilesData.vmProfileAssignments
                  && storageProfilesData.vmReplicationGroupAssignments
                     ?this.$q.when({
                        vmProfileAssignments: storageProfilesData.vmProfileAssignments,
                        vmReplicationGroupAssignments: storageProfilesData.vmReplicationGroupAssignments
                     })
                     :this.storageProfileService.requestVmProfileAndReplicationGroupAssignments(vmIds);
            } else {
               // Spbm service is down or the user doesn't have privileges.
               vmAssignmentsPromise = this.$q.when({
                  vmProfileAssignments: {},
                  vmReplicationGroupAssignments: {}
               });
            }
            return vmAssignmentsPromise.then( (vmAssignmentsResponse) => {
               selectorData.sourceVmStorageConfig = _.map(vmData, (vm:StorageSelectorVmData) => {
                  return this.buildVmConfig(
                        vm,
                        vmAssignmentsResponse.vmProfileAssignments[vm.id],
                        isSpbmEnabled,
                        vmAssignmentsResponse.vmReplicationGroupAssignments[vm.id],
                        selectorData.storageLocatorItemsData,
                        selectorData.storageProfilesData.storageProfiles);
               });

               return selectorData;
            });
         });
      }

      private buildDummyVmConfig(
            storageLocatorItemsData: any,
            storageProfilesData: any,
            dummyVmData:StorageSelectorVmData|null): VmStorageConfig {

         if (!dummyVmData ||  _.isEmpty(dummyVmData.disksData)) {
            // We provide a default storage config with VM HOME and one disk.
            let storageConfig = new VmStorageConfig();
            storageConfig.vmHome = new VmComponentStorageConfig();
            storageConfig.vmDisks = [this.buildDummyDiskVmConfig()];
            return storageConfig;
         }

         // We don't have VM id, but we have some disk data (supposedly fake/dummy).
         let dummyVmProfileAssignments = this.buildDummyVmProfileAssignments(
               storageProfilesData, dummyVmData.disksData);

         dummyVmData.id = "undefined";

         return this.buildVmConfig(
               dummyVmData,
               dummyVmProfileAssignments,
               true /*spbmEnabled*/,
               [] /*replication group assignments*/,
               storageLocatorItemsData,
               storageProfilesData.storageProfiles);
      }

      private buildDummyDiskVmConfig(): VmComponentStorageConfig {
         let dummyDisk = new VmComponentStorageConfig();
         dummyDisk.name = "__placeholder_disk__";
         dummyDisk.key = -1;
         return dummyDisk;
      }

      private buildDummyVmProfileAssignments(
            storageProfilesData: any, dummyVmDisksData: any): any {
         let result: any = {
            vm: {
               value: undefined
            },
            homeStorageProfile: undefined,
            diskAssignments: []
         };

         _.each(dummyVmDisksData, (virtualDisk: any) => {
            if (!virtualDisk.$profileId) {
               return;
            }

            let foundProfile: StorageProfile = _.find(
                  storageProfilesData.storageProfiles, (profile: StorageProfile) => {
               return (profile.id === virtualDisk.$profileId);
            });

            if (!foundProfile) {
               return;
            }

            result.diskAssignments.push({
               diskId: result.vm.value + ":" + virtualDisk.key,
               profile: foundProfile.profileObj
            });
         });

         return result;
      }

      /**
       * Opens a dialog with storageSelector in basic mode.
       *
       * Returns a promise with StorageSelectorDialogData
       *
       * @param profilesData
       *    IStorageProfileData instance, will filter out keep existing policies option if present.
       *
       * @param storageLocatorItemsData
       *    Contains all available datastore and storagePOD items.
       *
       * @param initialData
       *    Optional param, if specified will be used to preselect the selected storage item and storage profile.
       *
       * @param podDisplayDisabled
       *    Don't show storage pods.
       *
       * @param disableDefaultPreselection
       *    Controls default preselection.
       *
       * @param destinationHostId
       *    Optional parameter, can specify compute resource which will be used to access the datastore.
       *    If provided will be used to determine the applicable replication groups for the
       *    selected storage policy and datastore. Used only when showReplicationGroups is TRUE.
       *
       * @param showReplicationGroups
       *    Optional parameter, if set to TRUE the storage selector dialog will show
       *    replication group selector in case there are available replication groups for
       *    the selected datastore and storage policy.
       * @param isVmHome
       *    Optional parameter indicating whether storage will be browsed for vm home
       *    location (vm config files) or for a vm disk.
       *    Default is true (browse for vm home location)
       */
      public browseForStorage(
            storageLocatorItemsData: any[],
            profilesData: IStorageProfileData,
            initialData: StorageSelectorDialogData|null,
            podDisplayDisabled: boolean,
            disableDefaultPreselection: boolean,
            destinationHostId?: string|null,
            showReplicationGroups?: boolean,
            isEncryptionOptionsDisabled?: boolean,
            isVmHome: boolean = true,
            isPmemOptionDisabled: boolean = false): any {

         showReplicationGroups = showReplicationGroups || false; // default value false

         // show only basic mode
         let selectorModeSettings = this.getStorageSelectorModeSettings(false);

         // hide disk format options
         let diskFormatSettings = new StorageSelectorDiskFormatSettings();
         diskFormatSettings.diskFormatSupported = false;

         // initialize storage selector data with single VmStorageConfig containing
         // only one VM home component.
         let storageSelectorData = new StorageSelectorData();
         let storageConfig = new VmStorageConfig();
         storageConfig.destinationHostId = destinationHostId;

         let componentConfig: VmComponentStorageConfig = new VmComponentStorageConfig();
         if (isVmHome) {
            storageConfig.vmHome = componentConfig;
         } else {
            storageConfig.vmDisks = [componentConfig];
         }
         storageSelectorData.sourceVmStorageConfig = [storageConfig];

         // Set the initial configuration in case initialData param is provided or valid.
         if (initialData) {
            // Validate the initial data property.
            componentConfig.storageProfile = initialData.storageProfile;
            componentConfig.storageObj = initialData.storageObj;

            let profileToSelect: StorageProfile|null = null;
            if (initialData.storageProfile
                  && !this.storageProfileService.isPmemStorageProfile(initialData.storageProfile)) {
               profileToSelect = _.find(profilesData.storageProfiles,
                     (profile: StorageProfile) => {
                  return (initialData.storageProfile &&
                        profile.id === initialData.storageProfile.id &&
                        profile.label === initialData.storageProfile.label);
                  }) || null;
            }

            storageSelectorData.initialConfig = this.getStorageSelectorInitialConfig(
                  initialData.storageObj,
                  profileToSelect,
                  initialData.replicationGroup);
         }

         // create new IStorageProfileData instance by filtering out the keep existing profiles option.
         storageSelectorData.storageProfilesData = {
            storageProfiles: _.filter(profilesData.storageProfiles, (profile: StorageProfile) => {
               return !profile.keepExistingProfileAssignments;
            }),
            selectedProfile: null,
            error: profilesData.error
         };

         storageSelectorData.storageLocatorItemsData = storageLocatorItemsData;

         let storageSelectorApi = this.getStorageSelectorApiObj();

         let deferredResult = this.$q.defer();

         let modalOptions: any = {
            contentTemplate: "resources/ui/components/storageLocator/storageSelectorDialog.html",
            title: this.i18nService.getString("Common", "StorageLocatorDialog.Title"),
            defaultButton: "submit",
            size: "lg",
            dialogData: {
               modeSettings: selectorModeSettings,
               storageSelectorState: this.getStorageSelectorState(),
               storageSelectorData: storageSelectorData,
               storageSelectorApi: storageSelectorApi,
               diskFormatSettings: diskFormatSettings,
               podDisplayDisabled: podDisplayDisabled,
               disableDefaultPreselection: disableDefaultPreselection,
               showReplicationGroups: showReplicationGroups,
               isEncryptionOptionsDisabled: isEncryptionOptionsDisabled,
               isPmemOptionDisabled: isPmemOptionDisabled
            },
            alerts: [],
            onSubmit: () => {
               let validationError = storageSelectorApi.validateSelection() ||
                     storageSelectorApi.validateReplicationGroupSelection();
               if (validationError) {
                  modalOptions.alerts = [{
                     text: validationError,
                     type: this.clarityConstants.notifications.type.ERROR
                  }];
                  return false;
               }

               let result = new StorageSelectorDialogData();
               if (isVmHome) {
                  result.storageObj = storageSelectorData.vmStorageConfig[0].getVmHomeStorage();
                  result.storageProfile = storageSelectorData.vmStorageConfig[0].getVmHomeProfile();
                  result.replicationGroup = storageSelectorData.vmStorageConfig[0].vmHome
                        ? storageSelectorData.vmStorageConfig[0].vmHome.replicationGroup
                        : null;
               } else {
                  result.storageObj = storageSelectorData.vmStorageConfig[0].vmDisks[0].storageObj;
                  result.storageProfile = storageSelectorData.vmStorageConfig[0].vmDisks[0].storageProfile;
                  result.replicationGroup = storageSelectorData.vmStorageConfig[0].vmDisks[0].replicationGroup;
               }

               deferredResult.resolve(result);

               return true;
            },
            onCancel: () => {
               deferredResult.resolve(null);
               return true;
            }
         };

         this.clarityModalService.openOkCancelModal(modalOptions);

         return deferredResult.promise;
      }

      private getRgAssignment(rgAssignments: any[], id: string): any|null {
         let result: any|null = null;
         if (rgAssignments) {
            rgAssignments.forEach((assignment:any) => {
               if (assignment && assignment.vmObjectId === id) {
                  result = assignment.replicationGroup;
               }
            });
         }

         return result;
      }

      private buildVmConfig(vmData: StorageSelectorVmData,
            vmProfileAssignments: any,
            isSpbmEnabled: boolean,
            vmReplicationGroupAssignments: any,
            storageLocatorItemsData: any,
            profiles: StorageProfile[]) {

         let vmConfig = new VmStorageConfig();
         vmConfig.vmId = vmData.id;
         vmConfig.vmName = vmData.name;
         vmConfig.vmHome = new VmComponentStorageConfig();
         vmConfig.vmHome.name = this.i18nService.getString(
               "Common", "DiskLocatorControl.fileType.configuration");
         vmConfig.vmHome.storageObj = this.getStorageObjectFromId(
               vmData.homeDatastore, storageLocatorItemsData);

         vmConfig.destinationHostId = vmData.destinationHost;

         let vmMorValue: string = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(vmData.id).value;
         vmConfig.vmHome.replicationGroup =
               this.getRgAssignment(vmReplicationGroupAssignments, vmMorValue);

         if (vmProfileAssignments && vmProfileAssignments.homeStorageProfile) {
            vmConfig.vmHome.storageProfile = this.buildProfileItem(
                  vmProfileAssignments.homeStorageProfile, profiles);
         } else if (isSpbmEnabled) {
            vmConfig.vmHome.storageProfile = this.storageProfileService.getDefaultProfile();
         }

         vmConfig.vmDisks = _.map(vmData.disksData, (virtualDisk:any) => {
            let vmDiskConfig = new VmComponentStorageConfig();
            vmDiskConfig.key = virtualDisk.key;
            vmDiskConfig.name = this.getVirtualDiskLabel(virtualDisk);
            vmDiskConfig.storageObj = this.getStorageObjectFromId(
                  this.defaultUriSchemeUtil.getVsphereObjectId(virtualDisk.backing.datastore), storageLocatorItemsData);
            vmDiskConfig.capacityInBytes = virtualDisk.capacityInBytes;

            vmDiskConfig.replicationGroup = this.getRgAssignment(
                  vmReplicationGroupAssignments, vmMorValue + ":" + virtualDisk.key);

            if (vmProfileAssignments) {
               // get disk profile
               let diskId = vmProfileAssignments.vm.value + ":" + virtualDisk.key;
               let diskProfileAssignment: any = _.find(vmProfileAssignments.diskAssignments,
                     (diskData: any) => diskId === diskData.diskId);
               if (diskProfileAssignment && diskProfileAssignment.profile) {
                  vmDiskConfig.storageProfile = this.buildProfileItem(
                        diskProfileAssignment.profile, profiles);
               }
            }

            // If disk is using PMEM unset storage object.
            // PMEM datastore should not be visible in UI.
            if (this.storageProfileService.isPmemStorageProfile(vmDiskConfig.storageProfile)) {
               vmDiskConfig.storageObj = undefined;
            }

            if (!vmDiskConfig.storageProfile && isSpbmEnabled) {
               vmDiskConfig.storageProfile = this.storageProfileService.getDefaultProfile();
            }

             //VM ID won't be available during deploy template workflows.
            if (vmConfig.vmId === "undefined") {
               vmDiskConfig.diskGroupName = virtualDisk.deviceInfo.label;
            }

            return vmDiskConfig;
         });

         return vmConfig;
      }

      private getVirtualDiskLabel(virtualDisk: any) {
         let capacity = this.bytesFilter(virtualDisk.capacityInKB, "KB", "Auto", 2);
         return this.i18nService.interpolate("{0} ({1})", [virtualDisk.deviceInfo.label, capacity]);
      }

      private buildProfileItem(profile: any, profiles: StorageProfile[]): StorageProfile|null {
         if (!profile) {
            return null;
         }
         return _.find(profiles, function(storageProfile: StorageProfile) {
            return storageProfile.id ===  profile.profileId.uniqueId;
         }) || null;
      }

      public getStorageObjectFromId(storageId:string, storageLocatorItemsData:any): any | null {
         if (!storageId || !storageLocatorItemsData) {
            return null;
         }

         let datastoreObj = _.find(storageLocatorItemsData.datastoreItems, (dastoreItem:any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(dastoreItem.storageRef) === storageId;
         });

         if (datastoreObj) {
            return datastoreObj;
         }

         let storagePodObj = _.find(storageLocatorItemsData.storagePodItems, (storagePodItem:any) => {
            return this.defaultUriSchemeUtil.getVsphereObjectId(storagePodItem.storageRef) === storageId;
         });

         return storagePodObj || null;
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("storageSelectorService", StorageSelectorService);
}
