/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxTimePicker', [vxTimePicker]);

   function vxTimePicker() {
      var VALUE_MOMENT_FORMATS = ["HH:mm:ss", "HH:mm"];
      var DISPLAY_VALUE_MOMENT_FORMATS = ['HH:mm:ss', 'HH:mm'];

      return {
         restrict: "A",
         require: "ngModel",
         link: function(scope, element, attrs, ngModelCtrl) {

            function formatTime(value) {
               if (typeof value !== 'string') {
                  return '';
               }

               var valueMoment =
                     moment(value, VALUE_MOMENT_FORMATS, true);
               if (!valueMoment.isValid()) {
                  return '';
               }

               return valueMoment.format(DISPLAY_VALUE_MOMENT_FORMATS[0]);
            }

            function parseTime(displayValue) {
               if (typeof displayValue !== 'string') {
                  return '';
               }

               var displayValueMoment =
                     moment(displayValue, DISPLAY_VALUE_MOMENT_FORMATS, true);
               if (!displayValueMoment.isValid()) {
                  return '';
               }

               return displayValueMoment.format(VALUE_MOMENT_FORMATS[0]);
            }

            ngModelCtrl.$formatters.push(formatTime);
            ngModelCtrl.$parsers.push(parseTime);
         }
      };
   }
})();
