module platform {
   enum WebSocketStateEnum {
      CONNECTING,
      OPEN,
      CLOSING,
      CLOSED,
   }

   export class WebsocketMessagingService {
      static $inject = ['logService',
         '$rootScope', '$q', 'webSocketPingService',
         'vxZoneService', 'webSocketService', '$log'];

      private log: any;
      private initializeLiveUpdatesFunction: any;
      private liveUpdatesSocket: WebSocket | null;
      private readonly WEB_SOCKET_CLOSE_NORMAL_STATE: number = 1000;
      private readonly OPEN_INVENTORY: string = 'OPEN_INVENTORY';
      // contains the object ids of all VCs which have an opened inventory
      // changelog listener
      private openedChangelogs: Object = {};

      constructor(
            private logService: any, private $rootScope: ng.IRootScopeService,
            private $q: ng.IQService, private webSocketPingService: WebSocketPingService,
            private vxZoneService: any, private webSocketService: WebSocketService,
            private $log: any) {
         this.log = logService('websocketMessagingService');
      }

      initialize(url: string): WebSocket | null {
         const socket: WebSocket | null = this.webSocketService.createWebSocket(url);
         if (!socket) {
            return null;
         }
         let sockedOpened: boolean = false;
         socket.onmessage = (message: any) => {
            const msgObj = angular.fromJson(message.data);
            this.handleReceivedMessage(msgObj, msgObj.type);
         };
         socket.onopen = () => {
            sockedOpened = true;
         };
         socket.onclose = (event: CloseEvent) => {
            this.$log.debug('Web socket on ' + url + ' was closed');
            if (sockedOpened) {
               this.handelCloseEvent(event, url);
            }
         };
         return socket;
      }

      /**
       * Creates a live-updates websocket and returns a promise, resoved with a
       * function that sends a SUBSCRIBE message for a specified topic.
       */
      initializeLiveUpdates(): angular.IPromise<(destination: string) => void> {
         if (!this.initializeLiveUpdatesFunction || !this.liveUpdatesSocket
             || this.liveUpdatesSocket.readyState === WebSocketStateEnum.CLOSED
             || this.liveUpdatesSocket.readyState === WebSocketStateEnum.CLOSING) {
            this.initializeLiveUpdatesFunction = _.memoize(this.initializeLiveUpdatesSocket);
         }
         return this.initializeLiveUpdatesFunction();
      }

      send(msg: string): void {
         if (!this.liveUpdatesSocket) {
            this.log.error('Trying to send on non-initialized websocket');
            return;
         }
         if (this.liveUpdatesSocket.readyState !== WebSocketStateEnum.OPEN) {
            this.log.error('Trying to send on non-ready websocket, state: ',
               this.liveUpdatesSocket.readyState);
            return;
         }
         this.liveUpdatesSocket.send(msg);
      }

      /**
       * Reopen change logs
       */
      reOpenChangeLogs(): void {
         let serverGuids: string[] = _.keys(this.openedChangelogs);
         for(const serverGuid of serverGuids) {
            if (this.openedChangelogs[serverGuid]) {
               this.openedChangelogs[serverGuid] = false;
               this.openInventory(serverGuid);
            }
         }
      }

      openInventory(serverGuid: string): void {
         if (this.openedChangelogs[serverGuid]) {
            return;
         }
         this.openedChangelogs[serverGuid] = true;
         this.initializeLiveUpdates().then(() => {
            this.$log.debug('Sending ' + this.OPEN_INVENTORY);
            this.send(this.OPEN_INVENTORY + ':' + serverGuid);
         });
      }

      private initializeLiveUpdatesSocket(): angular.IPromise<(destination: string) => void> {
         const deferred: angular.IDeferred<(destination: string) => void> =
            this.$q.defer();
         const liveUpdatesUrl: string = 'wss://' + window.location.host +
            '/ui/live-updates';
         let sockedOpened: boolean = false;
         this.$log.debug('Initializing live updates socket on ' + liveUpdatesUrl);
         this.liveUpdatesSocket = this.webSocketService.createWebSocket(
            liveUpdatesUrl);
         if (!this.liveUpdatesSocket) {
            deferred.reject();
            return deferred.promise;
         }

         this.liveUpdatesSocket.onmessage = (message: any) => {
            // Broadcasting the message on the scope here.
            const msgObj = angular.fromJson(message.data);
            if (!msgObj.payload || !msgObj.payload.destination) {
               return;
            }
            //LiveRefresh messages' payload will contain destination, e.g. '/topic/alarms'
            const type: string = msgObj.payload.destination.substring("/topic/".length);
            this.handleReceivedMessage(msgObj, type);
         };
         this.liveUpdatesSocket.onopen = () => {
            this.$log.debug('Opened live updates socket.');
            sockedOpened = true;
            deferred.resolve((destination: string) => {
               this.liveUpdatesSocket!.send('SUBSCRIBE\n destination:' + destination);
            });
         };
         this.liveUpdatesSocket.onerror = () => {
            this.$log.debug('Error detected for live update socket ' + liveUpdatesUrl);
            deferred.reject();
         };
         this.liveUpdatesSocket.onclose = (event: CloseEvent) => {
            this.$log.debug('Live updates socket ' + liveUpdatesUrl + ' was closed.');
            if (sockedOpened) {
               this.handelCloseEvent(event, liveUpdatesUrl);
            }
         };
         return deferred.promise;
      }

      private handleReceivedMessage(msgObj: any, type: string): void {
         // Broadcasting the message on the scope here.
         this.$rootScope.$broadcast(type, msgObj.payload);
      }

      private handelCloseEvent(event: CloseEvent, url: string): void {
         if (event && event.code && event.code !== this.WEB_SOCKET_CLOSE_NORMAL_STATE) {
            this.$log.debug('Web socket was closed with status code ' + event.code);
            this.webSocketPingService.startPing(url);
         }
      }
   }

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.websocketMessagingService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Utility methods for fetching resource framework data and metadata.
    */
   angular.module('com.vmware.platform.ui').service('websocketMessagingService',
         WebsocketMessagingService);
}
