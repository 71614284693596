/**
 * Copyright 2015 VMware, Inc. All rights reserved.
 *
 * The progress directive shows an indeterminate progress bar
 */
angular.module('com.vmware.platform.ui').directive('vxProgress', function () {
   return {
      restrict: 'E',
      templateUrl: 'resources/ui/components/progress/vxProgress.html',
      scope: {
         message: "@",
         loadingAriaLabel: "@?"
      },
      link: function (scope, element, attributes) {
         scope.message = attributes.message;
      }
   };
});