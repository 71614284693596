/**
 * vx-tabs
 *
 * A directive that supports switching between active tabs.
 * Currently, it only adds/removes the `active` class from
 * the tab and content for the selected/unselected element.
 *
 * If the `vx-tab-pane` has a `vx-tab-on-show`, the callback
 * will be called when the tab is shown.
 *
 * If you need to size tabs with flexbox, you'll need to add
 * `vx-tabs-flexy` to the `vx-tabs` element. This will replace
 * `display: block` of the active `vx-tab-pane` with `display: flex`
 *
 * Markup:
 * <div vx-tabs class="styleguide-tabs">
 * 	<div vx-tab-headers>
 * 	   <div class="btn-group nav-secondary">
 * 		   <div vx-tab-header="myFirstTab" class="btn btn-default active">Look at this tab</div>
 * 		   <div vx-tab-header="mySecondTab" class="btn btn-default">And this other one</div>
 * 		</div>
 * 	</div>
 * 	<div vx-tab-content>
 * 		<div vx-tab-pane="myFirstTab" class="active">
 * 			Isn't this content great
 * 		</div>
 * 		<div vx-tab-pane="mySecondTab" vx-tab-on-show="myAwesomeCallback()">
 * 			So much tab
 * 		</div>
 * 	</div>
 * </div>
 *
 * Style guide: vx.tabs
 */

angular.module('com.vmware.platform.ui').directive('vxTabs', [ '$timeout',
   function($timeout) {
      return {
         link: function($scope, element, attributes){
            var initialTabId = attributes.vxTabsInitialActiveTab;
            if(initialTabId) {
               activateTab(element, initialTabId, $scope);
            }

            element.on('click', '[vx-tab-header]', function(event) {
               var clickedHeader = $(event.currentTarget);
               if(clickedHeader.hasClass('active')) {
                  return;
               }
               var tabId = clickedHeader.attr('vx-tab-header');
               activateTab(element, tabId, $scope);
            });

            // TODO remove temp patch
            // update it to get called when the content element size (tabContentSection)
            //  var clickedHeader = $(event.currentTarget);
            //  var contentId = clickedHeader.attr("id").replace("Link","Content");
            //  var tabContentSection = element.find("#"+contentId).find("section");
            // is available
            element.on('click', 'clr-tab-link', function(event) {
                $timeout(function () {
                    $scope.$broadcast('resize');
                }, 0);
            });
         }
      };
      function activateTab(element, tabId, $scope) {
         element.find('[vx-tab-header].active').removeClass('active');
         element.find('[vx-tab-pane].active').removeClass('active');
         element.find('[vx-tab-header="' + tabId + '"]').addClass('active');
         element.find('[vx-tab-pane="' + tabId + '"]').addClass('active');
         $scope.$broadcast('vxTab.showTab', tabId);
         $scope.$broadcast('resize');
      }
   }
]);
