/* Copyright 2017-2018 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   declare const CryptoJS: any;
   const TID: string = 'tid-';

   /**
    * Service that provides helper methods for telemetry
    */
   export class TelemetryHelperService {
      /**
       * Returns a unique CSS path of a node wrapper in the application.
       *
       * @param nodeWrapper whose path will be generated
       * @param options for configuring the CSS path generation
       * @returns {string} a unique CSS path of the node wrapper
       */
      public getNodeWrapperCssPath(nodeWrapper: TelemetryDomNodeWrapperInterface,
            options: CssPathOptions = { cssClassBlacklist: [] }): string {
         let cssSelectorParts: string[] = [];
         let currentNodeWrapper: TelemetryDomNodeWrapperInterface | null = nodeWrapper;
         while (currentNodeWrapper) {
            const normalizedNodeName: string = currentNodeWrapper.nodeName.toLowerCase();
            /*
             The root of the current DOM sub-tree is reached and it's not
             a Document node. This means that the sub-tree is detached or
             something is very wrong, so skip this sub-tree
             */
            if (!currentNodeWrapper.parentNodeWrapper &&
                  !this.isDocumentNode(currentNodeWrapper)) {
               return "";
            }

            /*
             Skip 'body', 'html' and non-element nodes when traversing the path
             */
            if (currentNodeWrapper.isEmbeddedNodeWrapper) {
               if (!this.isElementNode(currentNodeWrapper)) {
                  currentNodeWrapper = currentNodeWrapper.parentNodeWrapper;
                  continue;
               }
            } else {
               if (normalizedNodeName === 'body' ||
                     normalizedNodeName === 'html' ||
                     !this.isElementNode(currentNodeWrapper)) {
                  currentNodeWrapper = currentNodeWrapper.parentNodeWrapper;
                  continue;
               }
            }

            let elementSelector: string = "";
            const cssSelectorData: ClassSelectorData =
                  this.getClassesSelector(currentNodeWrapper, options);
            // if element has an id, add them to selector
            if (currentNodeWrapper.id) {
               elementSelector += "#" + this.escapeCssIdentifier(currentNodeWrapper.id);
               elementSelector += cssSelectorData.classSelector;
            } else {
               elementSelector += this.escapeCssIdentifier(normalizedNodeName);
               elementSelector += cssSelectorData.classSelector;
               if (!cssSelectorData.containsTid) {
                  elementSelector += this.getIndexSelector(currentNodeWrapper);
               }
            }
            // add individual selector to paths array
            cssSelectorParts.push(elementSelector);
            currentNodeWrapper = currentNodeWrapper.parentNodeWrapper;
            if (cssSelectorData.containsTid) {
               break;
            }
         }

         return cssSelectorParts.reverse().join(' > ');
      }

      /**
       * Method used for getting the trimmed text of a given node wrapper. An empty
       * string is returned if the node wrapper does not have text or its text
       * is empty (after trimming). The returned text is trimmed.
       *
       * @param nodeWrapper whose text will be returned
       * @returns {string} the text if such is present, or an empty string otherwise
       */
      public getText(nodeWrapper: TelemetryDomNodeWrapperInterface): string {
         if (!nodeWrapper.textContents || nodeWrapper.textContents.length === 0) {
            return "";
         }

         return nodeWrapper.textContents[0].trim();
      }

      /**
       * Method used for getting a SHA-256 encoded version of the trimmed text
       * of a given node wrapper. An empty string is returned if the node
       * wrapper does not have text or its text is empty (after trimming).
       *
       * @param nodeWrapper whose encoded text will be returned
       * @returns {string} the encoded text if such is present, or an empty string otherwise
       */
      public getEncodedText(nodeWrapper: TelemetryDomNodeWrapperInterface): string {

         let text: string = this.getText(nodeWrapper);
         if (!text) {
            return "";
         }

         let obfuscatedText;

         if (typeof CryptoJS !== typeof undefined) {
            obfuscatedText = CryptoJS.SHA256(text).toString();
         } else {
            obfuscatedText = "[Error: CryptoJS has not loaded properly]";
         }

         return obfuscatedText;
      }

      /**
       * Method used for generating telemetry id in the format of css class
       * e.g. tid-hosts-datastores.
       *
       * @param identifier to use as a base
       * @returns {string} a telemetry id generated based on the identifier
       */
      public generateTelemetryIdClass(identifier: string): string {
         if (identifier) {
            return TID + identifier.replace(/\s+|\./g, '-').toLowerCase();
         }
         return "";
      }

      /**
       * Returns the CSS class list of a DOM Element.
       *
       * @param element whose class list will be returned
       * @returns {string[]} the class list of the element as an array of
       * strings
       */
      public getElementClassList(element: Element): string[] {

         let result: string[] = [];

         if (element && element.classList) {
            result = Array.prototype.slice.apply(element.classList);
         }

         return result;
      }

      /**
       * Returns the child index of a DOM Element within its parent Node.
       *
       * @param element whose index will be returned
       * @returns {number} the index of the element
       */
      public getElementIndex(element: Element): number {
         let index: number = 0;
         let sibling: Element = element.previousElementSibling;
         while (sibling) {
            index++;
            sibling = sibling.previousElementSibling;
         }
         return index;
      }

      /**
       * Returns the text contents of the direct child nodes of
       * type Node.TEXT_NODE of a DOM Element.
       *
       * @param element whose direct text contents to return
       * @returns {string[]} an array of strings containing the direct text
       * contents of the element
       */
      public getElementDirectTextContents(element: Element): string[] {
         let textContents: string[] = [];
         for (let i = 0; i < element.childNodes.length; i++) {
            let currentNode = element.childNodes[i];
            if (this.isTextNode(currentNode) &&
                  typeof currentNode.textContent === "string") {
               textContents.push(currentNode.textContent);
            }
         }
         return textContents;
      }

      /**
       * Returns the parent DOM Node of a DOM Node
       *
       * @param node whose parent DOM Node to return
       * @returns {Node | null} the node's parent DOM Node if such exists,
       * or null otherwise
       */
      public getParentNode(node: Node): Node | null {
         let parentNode: Node | null = node.parentNode;

         /*
          If the node is an embedded Document node then its parent node is
          the frame element that contains the node's iframe window
          */
         if (this.isEmbeddedNode(node) && this.isDocumentNode(node)) {
            let documentNode: Document = <Document> node;
            if (documentNode.defaultView && documentNode.defaultView.frameElement) {
               parentNode = documentNode.defaultView.frameElement;
            }
         }

         /*
          If the node is a ShadowRoot Document fragment then its parent node
          is the ShadowRoot host DOM Node.
          */
         if (typeof window['ShadowRoot'] === 'function' &&
               node instanceof window['ShadowRoot']) {
            parentNode = (<ShadowRoot> node).host;
         }

         if (!parentNode) {
            return null;
         }

         return parentNode;
      }

      /**
       * Returns whether a Node is embedded in another Document.
       *
       * @param node whose state to determine
       * @returns {boolean} whether the node is embedded or not
       */
      public isEmbeddedNode(node: Node): boolean {
         let nodeDocument: Document | null = node.ownerDocument;
         if (this.isDocumentNode(node)) {
            nodeDocument = <Document> node;
         }

         return nodeDocument !== document;
      }

      /**
       * Returns whether a Node is an Element node.
       *
       * @param node to check
       * @returns {boolean} whether the node is an Element Node or not
       */
      public isElementNode(node: Node | TelemetryDomNodeWrapperInterface) {
         return node.nodeType === Node.ELEMENT_NODE;
      }

      /**
       * Returns whether a Node is a Text node.
       *
       * @param node to check
       * @returns {boolean} whether the node is a Text node or not
       */
      public isTextNode(node: Node | TelemetryDomNodeWrapperInterface) {
         return node.nodeType === Node.TEXT_NODE;
      }

      /**
       * Returns whether a Node is a Document node.
       *
       * @param node to check
       * @returns {boolean} whether the node is a Document node or not
       */
      public isDocumentNode(node: Node | TelemetryDomNodeWrapperInterface) {
         return node.nodeType === Node.DOCUMENT_NODE;
      }

      private getClassesSelector(nodeWrapper: TelemetryDomNodeWrapperInterface,
            options: CssPathOptions): ClassSelectorData {
         let classList: string[] = [];
         let containsTid: boolean = false;
         let filteredClassList: string[] = [];
         if (nodeWrapper.classList) {
            classList = nodeWrapper.classList;
            if (nodeWrapper.id) {
               filteredClassList = classList.filter((className: string) => className.indexOf(TID) === 0);
               containsTid = filteredClassList.length > 0;
            } else {
               for (let index: number = 0; index < classList.length && !containsTid; index++) {
                  const currentClass: string = classList[index];
                  if (currentClass.indexOf(TID) === 0) {
                     filteredClassList = [currentClass];
                     containsTid = true;
                  } else {
                     let blacklistMatch = _.find(options.cssClassBlacklist, (blacklistClass: string) => {
                              return currentClass.indexOf(blacklistClass) !== -1;
                           }
                     );
                     if (!blacklistMatch) {
                        filteredClassList.push(currentClass);
                     }
                  }
               }
            }
         }
         filteredClassList = _.map(filteredClassList,
               (cssClass: string) => this.escapeCssIdentifier(cssClass));
         const classSelector: string = filteredClassList.reduce((result, el) => result + `.${el}`, "");
         return {
            classSelector: classSelector,
            containsTid: containsTid
         };
      }

      /**
       * Escapes the provided string so that it can be used as part of a
       * CSS selector.
       *
       * @param cssIdentifier
       *    The identifier to escape.
       */
      private escapeCssIdentifier(cssIdentifier: string): string {
         /* a css.escape polyfill has been used to ensure support on IE & Edge */
         return CSS.escape(cssIdentifier);
      }

      private getIndexSelector(nodeWrapper: TelemetryDomNodeWrapperInterface): string {
         let index: number | null = nodeWrapper.index;
         if (index === null || index <= 0) {
            return "";
         }

         return `:nth-child(${index + 1})`;
      }
   }

   angular.module('com.vmware.platform.ui')
         .service('telemetryHelperService', TelemetryHelperService);
}