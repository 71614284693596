/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   export enum PropertyViewModelItemType {
      Message = <any> 'Message',
      PropertyValue = <any> 'PropertyValue',
      PropertyKey = <any> 'PropertyKey',
      Property = <any> 'Property',
      Section = <any> 'Section',
      Category = <any> 'Category'
   }

   export abstract class PropertyViewBaseBuilder {

      protected static cloneBuilders<T extends PropertyViewBaseBuilder>(
            builders: T[], parentBuilder?: PropertyViewBaseBuilder): T[] {

         return _.map(builders, function (builder: T) {
            let result: T = <T> builder.clone();

            if (parentBuilder) {
               parentBuilder.setAsParentTo(result);
            }

            return result;
         });
      }

      protected static getBuildersResults(builders: PropertyViewBaseBuilder[]): Object[] {
         return _.map(builders, function (builder: PropertyViewBaseBuilder) {
            return builder.build();
         });
      }

      protected parent: PropertyViewBaseBuilder|null;

      constructor() {
         this.parent = null;
      }

      abstract build(): any;

      abstract clone(): PropertyViewBaseBuilder;

      abstract exit(): PropertyViewBaseBuilder|null;

      protected setAsParentTo(child: PropertyViewBaseBuilder): PropertyViewBaseBuilder {
         child.parent = this;
         return this;
      }
   }

   export class PropertyViewMessageBuilder extends PropertyViewBaseBuilder {

      protected _text: string|null;
      protected _icon: string|null;
      protected _renderAsHtml: boolean;

      constructor() {
         super();

         this._text = null;
         this._icon = null;
         this._renderAsHtml = false;
      }

      text(value: string|null): PropertyViewMessageBuilder {
         this._text = value;
         return this;
      }

      icon(value: string|null): PropertyViewMessageBuilder {
         this._icon = value;
         return this;
      }

      renderAsHtml(value: boolean): PropertyViewMessageBuilder {
         this._renderAsHtml = value;
         return this;
      };

      build(): any {
         return {
            type: PropertyViewModelItemType.Message,
            text: this._text,
            icon: this._icon,
            renderAsHtml: this._renderAsHtml
         };
      }

      clone(): PropertyViewMessageBuilder {
         let result: PropertyViewMessageBuilder = new PropertyViewMessageBuilder();
         result._text = this._text;
         result._icon = this._icon;
         result._renderAsHtml = this._renderAsHtml;

         return result;
      }

      exit(): PropertyViewSectionBuilder|null {
         return <PropertyViewSectionBuilder|null> this.parent;
      }
   }

   export class PropertyViewPropertyKeyBuilder extends PropertyViewBaseBuilder {

      protected _text: string|null;
      protected _icon: string|null;

      constructor() {
         super();

         this._text = null;
         this._icon = null;
      }

      text(value: string|null): PropertyViewPropertyKeyBuilder {
         this._text = value;
         return this;
      }

      icon(value: string|null): PropertyViewPropertyKeyBuilder {
         this._icon = value;
         return this;
      }

      build(): any {
         return {
            type: PropertyViewModelItemType.PropertyKey,
            text: this._text,
            icon: this._icon
         };
      }

      clone(): PropertyViewPropertyKeyBuilder {
         let result: PropertyViewPropertyKeyBuilder =
               new PropertyViewPropertyKeyBuilder();
         result._text = this._text;
         result._icon = this._icon;

         return result;
      }

      exit(): PropertyViewPropertyBuilder|null {
         return <PropertyViewPropertyBuilder|null> this.parent;
      }
   }

   export class PropertyViewPropertyValueBuilder extends PropertyViewBaseBuilder {

      protected _text: string|null;
      protected _icon: string|null;
      protected _link: string|null;

      constructor() {
         super();

         this._text = null;
         this._icon = null;
         this._link = null;
      }

      text(value: string|boolean|number|null): PropertyViewPropertyValueBuilder {
         this._text = <string|null> value;
         return this;
      }

      icon(value: string|null): PropertyViewPropertyValueBuilder {
         this._icon = value;
         return this;
      }

      link(value: string|null): PropertyViewPropertyValueBuilder {
         this._link = value;
         return this;
      }

      build(): any {
         return {
            type: PropertyViewModelItemType.PropertyValue,
            text: this._text,
            icon: this._icon,
            link: this._link
         };
      }

      clone(): PropertyViewPropertyValueBuilder {
         let result: PropertyViewPropertyValueBuilder =
               new PropertyViewPropertyValueBuilder();
         result._text = this._text;
         result._icon = this._icon;
         result._link = angular.copy(this._link);

         return result;
      }

      exit(): PropertyViewPropertyBuilder|null {
         return <PropertyViewPropertyBuilder|null> this.parent;
      }
   }

   export class PropertyViewPropertyBuilder extends PropertyViewBaseBuilder {

      protected _keyBuilder: PropertyViewPropertyKeyBuilder;
      protected _valueBuilders: PropertyViewPropertyValueBuilder[];

      constructor() {
         super();

         this._keyBuilder = new PropertyViewPropertyKeyBuilder();
         this.setAsParentTo(this._keyBuilder);
         this._valueBuilders = [];
      }

      keyBuilder(): PropertyViewPropertyKeyBuilder {
         return this._keyBuilder;
      }

      valueBuilder(): PropertyViewPropertyValueBuilder {
         let result: PropertyViewPropertyValueBuilder =
               new PropertyViewPropertyValueBuilder();
         this.setAsParentTo(result);
         this._valueBuilders.push(result);

         return result;
      }

      build(): any {
         return {
            type: PropertyViewModelItemType.Property,
            key: this.keyBuilder().build(),
            content: PropertyViewBaseBuilder.getBuildersResults(this._valueBuilders)
         };
      }

      clone(): PropertyViewPropertyBuilder {
         let result: PropertyViewPropertyBuilder = new PropertyViewPropertyBuilder();
         result._keyBuilder = this._keyBuilder.clone();
         result.setAsParentTo(result._keyBuilder);
         result._valueBuilders =
               PropertyViewBaseBuilder.cloneBuilders(this._valueBuilders, result);

         return result;
      }

      exit(): PropertyViewSectionBuilder|null {
         return <PropertyViewSectionBuilder|null> this.parent;
      }
   }

   export class PropertyViewSectionBuilder extends PropertyViewBaseBuilder {

      private static readonly INFO_ICON_CLASS = 'vx-icon-info_normal';
      private static readonly WARNING_ICON_CLASS = 'vx-icon-warn';
      private static readonly ERROR_ICON_CLASS = 'vx-icon-error';

      protected _id: string;
      protected _renderAsHtml: boolean;
      protected _compileAsHtml: boolean;
      protected _title: string|null;
      protected _titleIcon: string|null;
      protected _contentBuilders: Array<PropertyViewPropertyBuilder|PropertyViewMessageBuilder>;
      protected _actions: Array<Object>;

      constructor(id: string) {
         if (typeof id !== 'string') {
            throw new Error('id must be a string');
         }

         super();

         this._id = id;
         this._title = null;
         this._titleIcon = null;
         this._contentBuilders = [];
         this._actions = [];
         this._renderAsHtml = false;
         this._compileAsHtml = false;
      }

      getId(): string {
         return this._id;
      }

      renderAsHtml(value: boolean): PropertyViewSectionBuilder {
         this._renderAsHtml = value;
         return this;
      };

      compileAsHtml(value: boolean): PropertyViewSectionBuilder {
         this._compileAsHtml = value;
         return this;
      };

      title(value: string|null): PropertyViewSectionBuilder {
         this._title = value;
         return this;
      }

      titleIcon(value: string|null): PropertyViewSectionBuilder {
         this._titleIcon = value;
         return this;
      }

      action(value?: Object|null): PropertyViewSectionBuilder {
         if (value) {
            this._actions.push(value);
         }

         return this;
      };

      property(key: string, values?: Array<string|number|boolean>|string|boolean|number|null): PropertyViewSectionBuilder {
         let valuesAsArray: Array<string|number|boolean>;

         if (values instanceof Array) {
            valuesAsArray = values;
         } else if (values === null || values === undefined) {
            valuesAsArray = [];
         } else {
            valuesAsArray = [<string|boolean|number> values];
         }

         let propertyBuilder = this.propertyBuilder();
         propertyBuilder.keyBuilder().text(key);
         _.each(valuesAsArray, function (value: string|boolean|number) {
            propertyBuilder.valueBuilder().text(value);
         });

         return this;
      }

      message(text: string, icon?: string|null): PropertyViewSectionBuilder {
         let messageBuilder: PropertyViewMessageBuilder = this.messageBuilder();
         messageBuilder.text(text);
         if (typeof icon === 'string') {
            messageBuilder.icon(icon);
         }

         return this;
      }

      info(text: string): PropertyViewSectionBuilder {
         return this.message(text, PropertyViewSectionBuilder.INFO_ICON_CLASS);
      }

      warning(text: string): PropertyViewSectionBuilder {
         return this.message(text, PropertyViewSectionBuilder.WARNING_ICON_CLASS);
      }

      error(text: string): PropertyViewSectionBuilder {
         return this.message(text, PropertyViewSectionBuilder.ERROR_ICON_CLASS);
      }

      propertyBuilder(): PropertyViewPropertyBuilder {
         let result: PropertyViewPropertyBuilder = new PropertyViewPropertyBuilder();
         this.setAsParentTo(result);
         this._contentBuilders.push(result);

         return result;
      }

      messageBuilder(): PropertyViewMessageBuilder {
         let result: PropertyViewMessageBuilder = new PropertyViewMessageBuilder();
         this.setAsParentTo(result);
         this._contentBuilders.push(result);

         return result;
      }

      build(): any {
         return {
            type: PropertyViewModelItemType.Section,
            id: this._id,
            renderAsHtml: this._renderAsHtml,
            compileAsHtml: this._compileAsHtml,
            title: this._title,
            titleIcon: this._titleIcon,
            actions: this._actions,
            content: PropertyViewBaseBuilder.getBuildersResults(this._contentBuilders)
         };
      }

      clone(): PropertyViewSectionBuilder {
         let result: PropertyViewSectionBuilder =
               new PropertyViewSectionBuilder(this._id);
         result._renderAsHtml = this._renderAsHtml;
         result._compileAsHtml = this._compileAsHtml;
         result._title = this._title;
         result._titleIcon = this._titleIcon;
         result._actions = this._actions.concat();
         result._contentBuilders =
               PropertyViewBaseBuilder.cloneBuilders(this._contentBuilders, result);

         return result;
      };

      exit(): PropertyViewCategoryBuilder|null {
         return <PropertyViewCategoryBuilder|null> this.parent;
      }
   }

   export class PropertyViewCategoryBuilder extends PropertyViewBaseBuilder {

      protected _id: string;
      protected _title: string|null;
      protected _contentBuilders: Array<PropertyViewSectionBuilder>;

      constructor(id: string) {
         if (typeof id !== 'string') {
            throw new Error('id must be a string');
         }

         super();

         this._id = id;
         this._title = null;
         this._contentBuilders = [];
      }

      getId(): string {
         return this._id;
      }

      title(value: string|null): PropertyViewCategoryBuilder {
         this._title = value;
         return this;
      }

      section(id: string): PropertyViewSectionBuilder {
         let sectionBuilder = this.getSection(id);
         if (sectionBuilder === null) {
            sectionBuilder = new PropertyViewSectionBuilder(id);
            this.setAsParentTo(sectionBuilder);
            this._contentBuilders.push(sectionBuilder);
         }

         return sectionBuilder;
      };

      getSection(id: string): PropertyViewSectionBuilder|null {
         let matchingBuilders: PropertyViewSectionBuilder[] = _.filter(
               this.getAllSections(),
               (s: PropertyViewSectionBuilder) => s.getId() === id
         );

         if (matchingBuilders.length === 1) {
            return matchingBuilders[0];
         }

         return null;
      };

      getAllSections(): Array<PropertyViewSectionBuilder> {
         return _.filter(
               this._contentBuilders,
               (b) => b instanceof PropertyViewSectionBuilder
         );
      }

      cloneAndAddBuilder(
            builder: PropertyViewSectionBuilder|null): PropertyViewCategoryBuilder {
         if (!builder) {
            return this;
         }

         if (!(builder instanceof PropertyViewSectionBuilder)) {
            throw new Error('expected a PropertyViewSectionBuilder instance');
         }

         if (this.getSection(builder.getId()) !== null) {
            throw new Error('a PropertyViewSectionBuilder with the same id already exists');
         }

         builder = builder.clone();
         this.setAsParentTo(builder);
         this._contentBuilders.push(builder);

         return this;
      };

      cloneAndAddBuilders(
            builders: Array<PropertyViewSectionBuilder|null>): PropertyViewCategoryBuilder {
         _.each(builders, b => this.cloneAndAddBuilder(b));
         return this;
      }

      build(): any {
         return {
            type: PropertyViewModelItemType.Category,
            id: this._id,
            title: this._title,
            content: PropertyViewBaseBuilder.getBuildersResults(this._contentBuilders)
         };
      };

      clone(): PropertyViewCategoryBuilder {
         let result = new PropertyViewCategoryBuilder(this._id);
         result._title = this._title;
         result._contentBuilders =
               PropertyViewBaseBuilder.cloneBuilders(this._contentBuilders, result);

         return result;
      };

      exit(): PropertyViewBuilder|null {
         return <PropertyViewBuilder|null> this.parent;
      }
   }

   export class PropertyViewBuilder extends PropertyViewBaseBuilder {

      protected _categoryBuilders: Array<PropertyViewCategoryBuilder>;

      constructor(protected _i18nService: any) {
         super();

         if (typeof _i18nService !== 'object' ||
               typeof _i18nService.getString !== 'function') {
            throw new Error(
                  'expecting i18nService to be an object with a getString() method'
            );
         }

         this._categoryBuilders = [];
      }

      category(id: string, insertionIndex?: number): PropertyViewCategoryBuilder {
         let categoryBuilder = this.getCategory(id);
         if (categoryBuilder === null) {
            categoryBuilder = new PropertyViewCategoryBuilder(id);
            this.setAsParentTo(categoryBuilder);
            this._categoryBuilders.push(categoryBuilder);
         }

         let removalIndex = _.findIndex(this._categoryBuilders, (b) => b.getId() === id);
         this._categoryBuilders.splice(removalIndex, 1);

         if (insertionIndex === undefined) {
            insertionIndex = removalIndex;
         } else if (typeof insertionIndex !== 'number' ||
               isNaN(insertionIndex) || !isFinite(insertionIndex) ||
               insertionIndex !== parseInt(insertionIndex.toString(), 10) ||
               insertionIndex < 0 ||
               this._categoryBuilders.length < insertionIndex) {
            throw new Error('invalid insertionIndex');
         }

         this._categoryBuilders.splice(insertionIndex, 0, categoryBuilder);

         return categoryBuilder;
      }

      getCategory(id: string): PropertyViewCategoryBuilder|null {
         let matchingBuilder = _.filter(
               this.getAllCategories(),
               (s) => s.getId() === id
         );

         if (matchingBuilder.length === 1) {
            return matchingBuilder[0];
         }

         return null;
      }

      getAllCategories(): Array<PropertyViewCategoryBuilder> {
         return _.filter(
               this._categoryBuilders,
               (b) => b instanceof PropertyViewCategoryBuilder
         );
      }

      getSection(categoryId: string, sectionId: string): PropertyViewSectionBuilder|null {
         let category = this.getCategory(categoryId);
         if (category === null) {
            return null;
         }

         return category.getSection(sectionId);
      }

      generateAllCategory(categoryTitle?: string): PropertyViewCategoryBuilder {
         if (this.getCategory('all')) {
            throw new Error('"All" category already added');
         }

         categoryTitle = categoryTitle ||
               this._i18nService.getString('Common', 'vxPropertyView.allCategory');

         return this.category('all', 0)
               .title(<string> categoryTitle)
               .cloneAndAddBuilders(
                     _.flatten(_.map(this.getAllCategories(), c => c.getAllSections()))
               );
      };

      build(): any {
         return {
            categories: PropertyViewBaseBuilder.getBuildersResults(this._categoryBuilders)
         };
      };

      clone(): PropertyViewBuilder {
         let result = new PropertyViewBuilder(this._i18nService);
         result._categoryBuilders =
               PropertyViewBaseBuilder.cloneBuilders(this._categoryBuilders, result);

         return result;
      };

      exit(): null {
         return null;
      }
   }

   angular.module('com.vmware.platform.ui')
         .constant('vxPropertyViewConstants', {
            PropertyViewModelItemType: PropertyViewModelItemType
         });

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewBaseBuilder', () => PropertyViewBaseBuilder);

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewMessageBuilder', () => PropertyViewMessageBuilder);

   angular.module('com.vmware.platform.ui')
         .factory(
               'vxPropertyViewPropertyKeyBuilder',
               () => PropertyViewPropertyKeyBuilder
         );

   angular.module('com.vmware.platform.ui')
         .factory(
               'vxPropertyViewPropertyValueBuilder',
               () => PropertyViewPropertyValueBuilder
         );

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewPropertyBuilder', () => PropertyViewPropertyBuilder);

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewSectionBuilder', () => PropertyViewSectionBuilder);

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewCategoryBuilder', () => PropertyViewCategoryBuilder);

   angular.module('com.vmware.platform.ui')
         .factory('vxPropertyViewBuilder', () => PropertyViewBuilder);
}
