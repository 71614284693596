/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive to create a settings block header.
 * It contains a title and the action buttons integrated with Action Framework actions which means that
 * action buttons are enabled/disabled and action invoker is invoked on click.
 * This directive takes a isolated scope which should contain something like:
 *    {
 *       objectId : ObjectId of object whose settings header is being rendered.
 *       title: Title of block
 *       actions: Optional Actions array which contains action objects of the type {actionId: '', label: 'some custom label'}.
 *                'label' property is optional. If not present, the default Edit label will be picked up.
 *    }
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxSettingsBlockHeader', settingsBlockHeader);

   function settingsBlockHeader() {
      var directive = {
            restrict: 'EA',
            replace: true,
            template:
               '<div class="settingsBlockHeader">' +
                  '<h2 class="settingsBlockTitle">{{settingsBlockHeader.vxSettingsBlockHeader.title}}</h2>' +
                  '<div vx-refreshable ' +
                     'watch-objects="{{settingsBlockHeader.objectsToWatch}}" ' +
                     'refresh="settingsBlockHeader.calculateActionAvailability(settingsBlockHeader.actions)"></div>' +
                  '<button ng-repeat="action in settingsBlockHeader.actions"' +
                        ' ng-class="action.enabled ? settingsBlockHeader.enabledBtnCssClasses : settingsBlockHeader.disabledBtnCssClasses"' +
                        ' ng-disabled=!action.enabled' +
                        ' ng-click="settingsBlockHeader.invokeAction(action)">{{action.label}}</button>' +
                '</div>',
             scope: {
                vxSettingsBlockHeader: '=' // two-way scope for objectId , title, actions array
             },
             controller: SettingsBlockHeaderController,
             controllerAs: 'settingsBlockHeader',
             bindToController: true
      };
      return directive;
   }

   SettingsBlockHeaderController.$inject = ['i18nService', 'actionsService', 'logService'];
   function SettingsBlockHeaderController(i18nService, actionsService, logService) {
      var self = this;
      var log = logService('SettingsBlockHeaderController');

      this.$onInit = function() {
         self.enabledBtnCssClasses = ['btn', 'btn-default', 'settingsBlockButton'];
         self.disabledBtnCssClasses = ['btn', 'btn-default', 'settingsBlockButton', 'disabled'];

         self.objectId = this.vxSettingsBlockHeader.objectId;
         var actionsOnSettingsBlockArr = getActions(this.vxSettingsBlockHeader.actions);
         self.actions = actionsOnSettingsBlockArr;
         self.invokeAction = invokeAction;
         self.calculateActionAvailability = calculateActionAvailability;
         self.objectsToWatch = [self.objectId];

         // Get actions evaluations and then set availability of each action.
         calculateActionAvailability(actionsOnSettingsBlockArr);
      };

      // Implementation
      /**
       * This function gets the array containing objects representating actions in the following form
       * [ {actionId: 'blah', label: 'customlabel'}, {actionId: 'blah2', label: 'customlabel2'} ]. Label is optional.
       * It parses it and returns an array of action framework like action objects. (Action object has properties - label, actionId, enabled).
       * If custom 'label' is not present it uses the default Edit action label.
       */
      function getActions(actionsArr) {
         var retActionsArr = [];
         if (actionsArr === null || actionsArr === undefined || actionsArr.length === 0) {
            return retActionsArr;
         }
         var settingsEditDefaultLabel = i18nService.getString('Common', 'settingsView.defaultEditActionLabel');
         for (var i = 0; i < actionsArr.length; i++ ) {
            var currAction = actionsArr[i];
            var actionLabel = currAction.label ? currAction.label : settingsEditDefaultLabel;
            var action = { actionId:currAction.actionId, label:actionLabel, enabled: false };
            retActionsArr.push(action);
         }
         // Reverse the array for the view since the actions are laid out from right to left.
         return retActionsArr.reverse();
      }

      /**
       * Action invoker.
       */
      function invokeAction(action) {
         if (!angular.isDefined(action.actionEvaluation)) {
            log.log("Action Evaluation for the action is null - check if you have defined the action in the action framework properly");
            return;
         }
         actionsService.invokeAction(action.actionEvaluation, {objectId: self.objectId });
      }

      /**
       * Get actionIds array given an array of action objects.
       */
      function getActionIds(actionsArray) {
         var actionIdsArray = actionsArray.map(function (currAction) {
            return currAction.actionId;
         });
         return actionIdsArray;
      }

      /**
       * Set the action enabled property on 'actionsArr' by looking into
       * 'actionsEvaluationsArr'. Also attaches the 'evaluationStatus' for each action.
       */
      function configureActionAvailability(actionEvaluationsArr, actionsArr) {
         // O(n (square)) is fine here since the number of actions will be less - mostly 1 and max 3
         // very rarely.
         for (var i = 0, totalCount = actionEvaluationsArr.length; i < totalCount; i++) {
            var evaluatedActionId = actionEvaluationsArr[i].action.uid;
            var available = actionEvaluationsArr[i].available; // Since it is one target object only, you can take the overall availability.
            for (var j = 0, total = actionsArr.length; j < total; j++) {
               var currActionId = actionsArr[j].actionId;
               if (currActionId === evaluatedActionId) {
                  actionsArr[j].enabled = available;
                  actionsArr[j].actionEvaluation = actionEvaluationsArr[i];
                  break;
               }
            }
         }
      }
      function calculateActionAvailability(actionsOnSettingsBlockArr) {
         // Get actions evaluations and then set availability of each action.
         var actionIds = getActionIds(actionsOnSettingsBlockArr);
         if (actionIds && actionIds.length > 0) {
            actionsService.getActions([self.objectId], actionIds).then (function(actions) {
               configureActionAvailability(actions, self.actions);
            });
         }
      }
}
})();
