/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */

module platform {

   import IInjectorService = angular.auto.IInjectorService;

   /**
    * Wrapper over downgraded service \vim-clients\applications\h5\ng-next-app\src\app\platform\services\translation\i18n.service.ts
    */
   export class I18nServiceWrapper {
      static $inject = ['$injector'];
      private _vcI18nService: I18nServiceWrapper;

      constructor(private $injector: IInjectorService) {
      }

      get vcI18nService(): I18nServiceWrapper {
         if (!this._vcI18nService) {
            this._vcI18nService = this.$injector.get('vcI18nService');
         }
         return this._vcI18nService;
      }

      // Note: Using arrow functions instead of a regular class method because many users of this service
      // like to grab a handle to getString method from the service but doing so
      // losing the class context with regular class methods. Arrow functions maintains this functionality
      getString = (bundle: string, key: string, ...interpolations: string[]): string => {
         return this.vcI18nService.getString(bundle, key, ...interpolations);
      };

      getEscapedString = (bundle: string, key: string, ...interpolations: string[]): string => {
         return this.vcI18nService.getEscapedString(bundle, key, ...interpolations);
      };

      interpolate = (localizedString: string, interpolations: Array<string>): string => {
         return this.vcI18nService.interpolate(localizedString, interpolations);
      };

      interpolateAndEscape = (localizedString: string, interpolations: Array<string>): string => {
         return this.vcI18nService.interpolateAndEscape(localizedString, interpolations);
      };

      getLocale = (): string => {
         return this.vcI18nService.getLocale();
      };
   }

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.i18nService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Internationalization Service.
    */
   angular.module('com.vmware.platform.ui').service('i18nService', I18nServiceWrapper);
}
