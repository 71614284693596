/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Directive to display tab headers for a navigation node
 *
 * Usage for "2nd level" component:
 * <div vx-navi-tab-headers="_view" level="second"></div>
 *
 * Type specifies formatting of the tab header.
 */
angular.module('com.vmware.platform.ui').directive('vxNaviTabHeaders', ['navigation', 'telemetryHelperService',
function(navigation, telemetryHelperService) {

   var urlMap = {
      first: 'resources/ui/views/tabs/vxNaviTabHeadersTemplate.html',
      second: 'resources/ui/views/tabs/vxNaviSubTabHeadersTemplate.html',
      details: 'resources/ui/views/tabs/vxNaviDetailsTabHeadersTemplate.html'
   };

   return {
      scope: {
         _view: '=vxNaviTabHeaders',
         nameField: '=?'
      },
      templateUrl : function(element, attr) {
         return urlMap[attr.level || 'first'];
      },
      link: function($scope, iElement, iAttrs, controller){
         $scope.nameField = $scope.nameField || 'name';
         navigation.populateScope($scope);
         angular.extend($scope, {
             _generateTid: function (id) {
                 return telemetryHelperService.generateTelemetryIdClass(id + " navi-tab-header");
             }
         });
         $scope.relatedItemParentId = function(parent) {
               // special case for related item encoding into the url
               var parentId;
               if (parent && parent.contentSpec && parent.contentSpec.metadata &&
                  (parent.contentSpec.metadata.fetchRelatedAsChildren ||
                  parent.contentSpec.metadata.showRelationsFor)) {
                  parentId = parent.$id;
               }
               return parentId;
         };
      }
   };
}]);
