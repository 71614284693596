namespace common_ui {

   import SpbmReplicationGroupInfoService = platform.SpbmReplicationGroupInfoService;
   import DataService = platform.DataService;

   export class StorageLocator {
      public templateUrl: string;
      public controller: any;
      public bindings: any;

      constructor() {
         this.templateUrl = "resources/ui/components/storageLocator/storageLocator.html";
         this.controller = StorageLocatorController;
         this.bindings = {
            storageLocatorItemsData: "=",
            podDisplayDisabled: "=?",
            drsDisabled: "=?",
            selectedItem: "=?",
            onSelectionChanged: "&",
            storageProfilesData: "=?",
            diskFormatSettings: "=?",
            datagridPreselect: "=?",
            disableDefaultPreselection: "<?",
            ensurePreselectionVisibility: "<?",
            vmIds: "<?",
            vmDestinationHosts: "<?",
            vmStorageConfigs: "<?",
            replicationGroupSettings: "<?",
            showEncryptionOptions: "<?",
            isEncryptionOptionsDisabled: "<?",
            encryptionOptionsDisabledReason: "<?"
         };
      }
   } // class StorageLocator

   interface ReplicationGroupItem {
      label: string;
      rgInfo: any;
   }

   export class ReplicationGroupSettings {
      showReplicationGroups: boolean;
      selectedReplicationGroup: any|null;
      replicationGroupValidationError: string|null;
   }

   class StorageLocatorController {
      public static $inject = [
         "$scope",
         "$element",
         "vuiConstants",
         "i18nService",
         "dataService",
         "featureFlagsService",
         "columnRenderersRegistry",
         "defaultUriSchemeUtil",
         "bytesFilter",
         "storageProfileService",
         "listUtil",
         "diskFormatService",
         "$timeout",
         "spbmReplicationGroupInfoService"
      ];

      // Input parameters
      public storageLocatorItemsData: StorageLocatorItemsData;
      public podDisplayDisabled: boolean;
      public selectedItem: any;
      public onSelectionChanged: any;
      public storageProfilesData: IStorageProfileData;
      public diskFormatSettings: any;
      public datagridPreselect: any;
      public disableDefaultPreselection: boolean;
      public ensurePreselectionVisibility: boolean;
      public vmIds: any;
      public vmDestinationHosts: string[];
      public vmStorageConfigs: VmStorageConfig[];
      public replicationGroupSettings: ReplicationGroupSettings;
      public showEncryptionOptions: boolean;
      public isEncryptionOptionsDisabled: boolean;
      public encryptionOptionsDisabledReason: string;

      // Accessed from the view/HTML
      public settings: any;
      public i18n: () => string;
      public datagridOptions: any;
      public diskFormats: any;
      public drsCheckboxVisible: boolean;
      public isEncryptVmEnabled: boolean;
      public isProfileSetFromDropDown: boolean;
      public preselectComparator: (gridItem) => boolean;
      public rgSignPostConfig: any;
      public filteredStorageProfiles: StorageProfile[];
      public hasEncryptionPolicies: boolean;
      public encryptionOptionsLabel: string;

      private rawItemDataMap: any;
      private formattedItemToSelect: any;
      private compatibleStorageData: any;
      private _drsDisabled: boolean;
      private _isInitalized: boolean = false;
      private _preserveSelectedItem: boolean = false;
      private lastEncryptionProfile: StorageProfile;
      private lastNonEncryptionProfile: StorageProfile;

      public get drsDisabled(): boolean {
         return this._drsDisabled;
      }

      public set drsDisabled(value: boolean) {
         let oldValue = this._drsDisabled;
         this._drsDisabled = value;
         if (oldValue !== this._drsDisabled && this._isInitalized) {
            this.populateStorageListItems();
         }
      }

      public replicationGroupItems: ReplicationGroupItem[];

      private _selectedRgItem: ReplicationGroupItem|null;

      public set selectedReplicationGroupItem(item: ReplicationGroupItem) {
         this._selectedRgItem = item;
         if (item) {
            this.replicationGroupSettings.selectedReplicationGroup = item.rgInfo;
         } else {
            this.replicationGroupSettings.selectedReplicationGroup = null;
         }

         this.updateReplicationGroupValidationError();
      }

      public get selectedReplicationGroupItem(): ReplicationGroupItem|null {
         return this._selectedRgItem;
      }

      public get showReplicationGroupSelector(): boolean {
         return this.replicationGroupSettings.showReplicationGroups &&
               this.replicationGroupItems &&
               this.replicationGroupItems.length > 0;
      }

      constructor(
         private $scope: any,
         private $element: any,
         private vuiConstants: any,
         private i18nService: any,
         private dataService: DataService,
         private featureFlagsService: any,
         private columnRenderersRegistry: any,
         private defaultUriSchemeUtil: any,
         private bytesFilter: any,
         private storageProfileService: any,
         private listUtil: any,
         private diskFormatService: any,
         private $timeout: any,
         private spbmReplicationGroupInfoService: SpbmReplicationGroupInfoService
      ) {

         this.rgSignPostConfig = {
            title: this.i18nService.getString("Common", "help"),
            message: this.i18nService.getString("Common", "StorageLocatorControl.protectionGroup.info")
         };
      }

      public $onInit() {
         this.compatibleStorageData = null;
         this._isInitalized = true;

         this.diskFormatSettings = this.diskFormatSettings || {};

         this.replicationGroupSettings = this.replicationGroupSettings ||
               new ReplicationGroupSettings();

         _.defaults(this.diskFormatSettings, {
            diskFormatSupported: false,
            sameAsSourceSupported: true,
            thickDiskFormatSupported: true,
            selectedDiskFormat: null
         });

         if (_.isUndefined(this.datagridPreselect)) {
            this.datagridPreselect = true;
         }

         if (!_.isUndefined(this.selectedItem)) {
            this._preserveSelectedItem = true;
         }

         this.encryptionOptionsLabel = this.encryptionOptionsDisabledReason
               ? this.i18nService.getString("Common", "storageSelector.encryptVmWithReason",
                  this.encryptionOptionsDisabledReason)
               :this.i18nService.getString("Common", "storageSelector.encryptVm");

         if (!!this.storageProfilesData) {
            if (this.storageProfilesData.storageProfiles) {
               this.lastNonEncryptionProfile = this.storageProfilesData.storageProfiles[0];
            }
            this.onStorageProfileChanged(this.storageProfilesData.selectedProfile);
         }
         this.refreshStorageProfiles();

         this.i18n = this.i18nService.getString;
         this.rawItemDataMap = {};

         this.updateDatagridOptions([], null);

         // selection changes listener, updates the scope.selectedItem
         this.$scope.$watch(() => {
            if (this.datagridOptions) {
               return this.datagridOptions.selectedItems;
            }
         }, (newValue, oldValue) => {

            if (!this.datagridOptions) {
               return;
            }

            if (this.isEmptyArrayOrNull(newValue) && this.isEmptyArrayOrNull(oldValue)) {
               return;
            }

            if (newValue !== oldValue) {
               let oldSelection = this.selectedItem;

               if (!this.disableDefaultPreselection) {
                  this._preserveSelectedItem = false;
               }

               if (newValue && newValue.length > 0) {
                  let selectedListItem = newValue[0];

                  // if the item has been selected, reset it.
                  this.formattedItemToSelect = null;

                  this.selectedItem = selectedListItem ? this.rawItemDataMap[selectedListItem.id] : null;
               } else {
                  this.selectedItem = null;
               }

               let selectionChanged = !this.areStorageLocatorDataItemsEqual(oldSelection, this.selectedItem);
               this.onStorageSelected(selectionChanged);
            }
         });

         this.$onInitContinued();
      }

      private toggleEncryptVm() {
         if (this.isEncryptVmEnabled && this.lastEncryptionProfile) {
            this.storageProfilesData.selectedProfile = this.lastEncryptionProfile;
         } else if (!this.isEncryptVmEnabled && this.lastNonEncryptionProfile) {
            this.storageProfilesData.selectedProfile = this.lastNonEncryptionProfile;
         }
         this.isProfileSetFromDropDown = false;

         this.refreshStorageProfiles();
      }

      private refreshStorageProfiles() {
         if (!this.storageProfilesData || _.isEmpty(this.storageProfilesData.storageProfiles)) {
            return;
         }

         this.filteredStorageProfiles = this.filterStorageProfiles(
               this.storageProfilesData.storageProfiles);

         // Set the selection
         if (!_.isEmpty(this.filteredStorageProfiles) &&
               (!this.storageProfilesData.selectedProfile ||
               !_.some(this.filteredStorageProfiles,
                     (profile) => profile.id === this.storageProfilesData.selectedProfile.id))) {
            this.storageProfilesData.selectedProfile = this.filteredStorageProfiles[0];
         }
      }

      private isEmptyArrayOrNull(value: any): boolean {
         return !value || (value && !value.length);
      }

      private onStorageProfileChanged(storageProfile: StorageProfile) {
         this.storageProfilesData.selectedProfile = storageProfile;

         // Enable VM encryption if encryption storage profile is selected
         if (!this.isEncryptionOptionsDisabled) {
            this.isEncryptVmEnabled = this.storageProfileService
                  .isEncryptionStorageProfile(storageProfile);
         }

         this.isProfileSetFromDropDown = true;
         if (this.isEncryptVmEnabled) {
            this.lastEncryptionProfile = storageProfile;
         } else {
            this.lastNonEncryptionProfile = storageProfile;
         }
         this.afterProfileOrStorageChanged();
      }

      private notifySelectionChanged(selectedItem: any): void {
         if (this.onSelectionChanged) {
            this.onSelectionChanged({selectedItem: selectedItem});
         }
      }

      private onStorageSelected(selectionChanged) {
         let selectedListItem =
            this.datagridOptions.selectedItems && this.datagridOptions.selectedItems.length > 0
               ? this.datagridOptions.selectedItems[0]
               : null;

         this.notifySelectionChanged(selectedListItem);
         if (selectedListItem) {
            let datastoreType;
            let vStorageSupported;

            if (selectedListItem.isDatastore) {
               datastoreType = this.rawItemDataMap[selectedListItem.id].type;
               vStorageSupported = this.rawItemDataMap[selectedListItem.id].vStorageSupported;
            } else {
               // Storage POD item
               if (selectedListItem && selectedListItem.childDatastoreDataItems &&
                  selectedListItem.childDatastoreDataItems.length > 0) {

                  datastoreType = selectedListItem.childDatastoreDataItems[0].type;
                  vStorageSupported = selectedListItem.childDatastoreDataItems[0].vStorageSupported;
               }
            }

            // If this is newly selected datastore, we need to
            // reset the selectedDiskFormat
            if (selectionChanged) {
               this.diskFormatSettings.selectedDiskFormat = null;
            }

            this.diskFormats = this.diskFormatService.getAvailableDiskFormats(
               datastoreType,
               vStorageSupported,
               this.diskFormatSettings.sameAsSourceSupported,
               this.diskFormatSettings.thickDiskFormatSupported);
         } else {
            this.diskFormats = [];
         }

         this.updateReplicationGroupItems();
         this.afterProfileOrStorageChanged();
      }

      private afterProfileOrStorageChanged(): void {
         this.overrideDiskFormatsIfNeeded();
      }
      private isStorageSelected(): boolean {
         if (!this.datagridOptions) {
            return false;
         }

         const items: Array<any> = this.datagridOptions.selectedItems;
         return items && items.length > 0;
      }

      private overrideDiskFormatsIfNeeded(): void {
         if (this.isStorageSelected()) {
            return;
         }
         // Only apply extra logic if no datastore selected
         // and deployment will go via compute-DRS placement
         // which is currently only Ovf template
         if (this.diskFormatSettings.drsPlacement) {
            const profile = this.storageProfilesData.selectedProfile;
            // Show thick, thin, ezt instead of empty list.
            // Profile is reserved for future use.
            this.diskFormats = this.diskFormatService.getDrsDiskFormats(profile);
         }
      }

      private getSelectItemCompatibilityResult(): any|null {
         let selectedListItem =
               this.datagridOptions.selectedItems && this.datagridOptions.selectedItems.length > 0
                     ? this.datagridOptions.selectedItems[0]
                     : null;
         if (selectedListItem  && this.compatibleStorageData &&
               this.rawItemDataMap[selectedListItem.id] &&
               this.rawItemDataMap[selectedListItem.id].storageRef) {

            let storageRefValue = this.rawItemDataMap[selectedListItem.id].storageRef.value;

            return _.find(this.compatibleStorageData.compatibilityResults, (cr: any) =>
                  cr.hub && cr.hub.hubId === storageRefValue) || null;
         }

         return null;
      }

      private getAvailableReplicationGroups(): any[] {
         let compatibilityResult: any = this.getSelectItemCompatibilityResult();

         if (!compatibilityResult || !this.compatibleStorageData) {
            return [];
         }

         let hostRefs: any[] = [];
         _.forEach(this.vmDestinationHosts, (hostId: string) => {
            let hostRef = this.defaultUriSchemeUtil.getManagedObjectReference(hostId);
            if (hostRef && hostRef.type === "HostSystem") {
               hostRefs.push(hostRef);
            }
         });

         return this.spbmReplicationGroupInfoService.extractReplicationGroupInfos(
               compatibilityResult, hostRefs, this.compatibleStorageData.replicationGroupInfo);
      }

      private updateReplicationGroupItems(): void {
         let replicationGroups: any[] = this.getAvailableReplicationGroups();
         if (replicationGroups) {
            this.replicationGroupItems = replicationGroups.map((rgInfo: any): ReplicationGroupItem => {
               return {
                  label: this.spbmReplicationGroupInfoService.getReplicationGroupName(rgInfo),
                  rgInfo: rgInfo
               };
            });

            let selectedRgInfo: any = this.replicationGroupSettings &&
                  this.replicationGroupSettings.selectedReplicationGroup;
            if (selectedRgInfo) {
               this.selectedReplicationGroupItem = _.find(this.replicationGroupItems,
                     (rgItem: ReplicationGroupItem): boolean =>
                           this.spbmReplicationGroupInfoService.equalReplicationGroupInfos(
                                 rgItem.rgInfo, selectedRgInfo)
               ) || null;
            } else {
               // If Automatic option is available select it as default option
               this.selectedReplicationGroupItem = _.find(this.replicationGroupItems,
                     (rgItem: ReplicationGroupItem): boolean =>
                        this.spbmReplicationGroupInfoService.isAutoReplicationGroup(rgItem.rgInfo)
               ) || null;
            }

         } else {
            this.replicationGroupItems = [];
            this.selectedReplicationGroupItem = null;
         }
      }

      private updateDatagridOptions(items: any[], groupOrder: any, selectedFormattedItem?: any) {

         // preselectComparator will select the formattedItemToSelect
         this.formattedItemToSelect = selectedFormattedItem;

         this.datagridOptions = {
            columnDefs: this.getColumnDefs(),
            data: items,
            height: "100%",
            selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
            searchable: false,
            resizable: true,
            selectedItems: [],
            groupOrder: groupOrder,
            sortOrder: [{dir: "asc", field: "initialIndex"}], // sortOrder prevents the order of the items from being changed when grouping
            pageConfig: {
               hidePager: true
            }
         };
      }

      private $onInitContinued() {
         this.requestCompatibleStorage().finally(() => {
            this.populateStorageListItems();
         });

         // re-create all storage items when storageLocatorItemsData directive attribute is changed.
         // from outside.
         this.$scope.$watch(() => {
            return this.storageLocatorItemsData;
         }, (newValue, oldValue) => {
            if (newValue !== oldValue) {
               this.requestCompatibleStorage().finally(() => {
                  this.populateStorageListItems();
               });
            }
         });

         if (this.storageProfilesData) {
            // re-create all storage items when storage profile selection changes.
            this.$scope.$watch(() => {
               return this.storageProfilesData.selectedProfile;
            }, (newValue, oldValue) => {
               if (newValue !== oldValue) {
                  this.requestCompatibleStorage().finally(() => {
                     this.populateStorageListItems();
                  });
               }
            });
         }

         this.preselectComparator = (gridItem) => {
            let itemIdToSelect;
            if (this.formattedItemToSelect) {
               itemIdToSelect = this.formattedItemToSelect.id;
            } else if (this.selectedItem) {
               itemIdToSelect = this.defaultUriSchemeUtil.getVsphereObjectId(this.selectedItem.storageRef);
            }

            return itemIdToSelect && gridItem && itemIdToSelect === gridItem.id;
         };
      }

      private populateStorageListItems() {
         let formattedStorageItems: any[] = [];
         this.drsCheckboxVisible = false;

         let storagePodListItems = {};

         if (this.storageLocatorItemsData && this.storageLocatorItemsData.storagePodItems) {
            _.forEach(this.storageLocatorItemsData.storagePodItems, (podDataItem: any) => {
               let podId = this.defaultUriSchemeUtil.getVsphereObjectId(podDataItem.storageRef);
               this.rawItemDataMap[podId] = podDataItem;
               podDataItem.storageRef.isSdrsEnabled = podDataItem.drsEnabled;
               if (podDataItem.drsEnabled === true) {
                  this.drsCheckboxVisible = true;
                  if (!this.drsDisabled && !this.podDisplayDisabled) {
                     let storagePodListItem = this.buildStoragePodListItem(podDataItem);
                     formattedStorageItems.push(storagePodListItem);
                     storagePodListItems[podId] = storagePodListItem;
                  }
               }
            });
         }

         if (this.storageLocatorItemsData.datastoreItems) {
            _.forEach(this.storageLocatorItemsData.datastoreItems,
                  (dsDataItem: StorageLocatorDatastoreItem) => {
               // Pmem datastores are not shown in the datastore list.
               if (this.isPmemDatastoreItem(dsDataItem)) {
                  return;
               }

               let dsId = this.defaultUriSchemeUtil.getVsphereObjectId(dsDataItem.storageRef);
               this.rawItemDataMap[dsId] = dsDataItem;

               let parentPodDataItem;
               if (dsDataItem.parentStoragePod) {
                  let parentPodId = this.defaultUriSchemeUtil.getVsphereObjectId(dsDataItem.parentStoragePod);
                  parentPodDataItem = this.rawItemDataMap[parentPodId];
                  dsDataItem.parentStoragePod.isSdrsEnabled = parentPodDataItem.drsEnabled;
                  if (storagePodListItems[parentPodId]) {
                     storagePodListItems[parentPodId].childDatastoreDataItems.push(dsDataItem);
                  }
               }

               if (this.isDatastoreItemVisible(parentPodDataItem)) {
                  formattedStorageItems.push(this.buildDatastoreListItem(dsDataItem, parentPodDataItem));
               }
            });
         }

         _.each(formattedStorageItems,
            (item, index) => {
               item.initialIndex = index;
            });

         let groupOrder: any = null;
         // Holds the item which will be selected.
         // Will be initialized with one of the following values:
         // 1. [highest priority] the formatted item for the scope.selectedItem (if such is available)
         // 2. the first compatible storage pod or the first compatible datastore with Datastore.AllocateSpace privilege if disableDefaultPreselection flag is false
         // 3. the first compatible item if disableDefaultPreselection flag is false and there is no compatible item with Datastore.AllocateSpace privilege
         // 4. the first storage pod or the first datastore with Datastore.AllocateSpace privilege if disableDefaultPreselection flag is false
         // 5. [lowest priority] the first item in the list if disableDefaultPreselection flag is false
         let formattedItemToBeSelected = null;
         if (this.compatibleStorageData && this.compatibleStorageData.compatibleStorage) {

            let compatibleStr = this.i18nService.getString("Common", "StorageLocatorControl.Compatible");
            let incompatibleStr = this.i18nService.getString("Common", "StorageLocatorControl.Incompatible");

            _.forEach(formattedStorageItems, (item) => {
               item.compatibility = this.compatibleStorageData.compatibleStorage[item.id] === true
                 ? compatibleStr
                 : incompatibleStr;
               // Have to select the first storage pod or the first compatible datastore with allocateSpace privilege
               if (!this.disableDefaultPreselection && !formattedItemToBeSelected &&
                     item.compatibility === compatibleStr && (!item.isDatastore || item.hasAllocateSpacePrivilege === true)) {
                  formattedItemToBeSelected = item;
               }
            });

            if (!this.disableDefaultPreselection && !formattedItemToBeSelected) {
               // There are no compatible datastores with allocateSpace privilege. Select the first compatible item.
               formattedItemToBeSelected = _.find(formattedStorageItems, function (item: any) {
                        return item.compatibility === compatibleStr;
                     }) || formattedItemToBeSelected;
            }

            let dir = "asc";
            if (compatibleStr > incompatibleStr) {
               dir = "desc";
            }

            groupOrder = [{
               field: "compatibility",
               dir: dir
            }];
         }

         // In case there are no compatible items:
         // 1. select the first storage pod in the list or the first datastore with AllocateSpace privilege in the list
         // 2. select first item in the list
         if (!this.disableDefaultPreselection && !formattedItemToBeSelected &&
            formattedStorageItems && formattedStorageItems.length) {
            let storageItemWithPrivilege = _.find(formattedStorageItems, function (item: any) {
               return !item.isDatastore || item.hasAllocateSpacePrivilege;
            });
            formattedItemToBeSelected = storageItemWithPrivilege || formattedStorageItems[0];
         }

         if (this._preserveSelectedItem && this.selectedItem) {
            let selectedItemAsFormattedItem = _.find(formattedStorageItems, (item) => {
               return this.defaultUriSchemeUtil.getVsphereObjectId(this.selectedItem.storageRef) === item.id;
            });

            if (!selectedItemAsFormattedItem) {
               this.selectedItem = null;
            } else {
               // if there is a valid formatted item assign it to the formattedItemToBeSelected
               formattedItemToBeSelected = selectedItemAsFormattedItem;
            }
         }

         this.updateDatagridOptions(formattedStorageItems, groupOrder, formattedItemToBeSelected);

         if (this.ensurePreselectionVisibility) {
            this.scrollToPreselection();
         }
      }

      private requestCompatibleStorage() {

         // This will remove the grid from the DOM
         this.datagridOptions = null;
         // Clear the selection while loading
         let previouslySelectedItem = this.selectedItem;
         this.selectedItem = null;
         this.compatibleStorageData = null;

         let spec;
         if (this.storageProfilesData && this.storageProfilesData.selectedProfile &&
            this.storageProfilesData.selectedProfile.keepExistingProfileAssignments &&
            this.vmIds && this.vmStorageConfigs &&
            this.vmStorageConfigs.some(this.isVmStorageAssignedToNonDefaultStorageProfile)) {

            spec = this.storageProfileService.createCompatibleStorageQuerySpecForVms(
               this.vmIds,
               this.getDatastoreRefs(),
               this.getDrsEnabledStoragePodRefs());
         } else {

            // No request to the backend should be made when the selected storage profile is:
            // 1. Default
            // 2. Keep existing... when all vm's storage components are assigned to the default storage profile.
            // In case (2) the profile's info will be null which will be handled as like default storage profile is chosen (1).
            spec = this.storageProfileService.createCompatibleStorageQuerySpecForProfile(
               this.storageProfilesData,
               this.getDatastoreRefs(),
               this.getDrsEnabledStoragePodRefs());
         }

         return this.storageProfileService.requestCompatibleStorage(spec).then((result) => {
            this.selectedItem = previouslySelectedItem;
            this.compatibleStorageData = result;

            if (this.compatibleStorageData) {
               this.compatibleStorageData.compatibleStorage = {};
               _.forEach(this.compatibleStorageData.datastoreRefs, (moRef) => {
                  let storageObjId = this.defaultUriSchemeUtil.getVsphereObjectId(moRef);
                  this.compatibleStorageData.compatibleStorage[storageObjId] = true;
               });
               _.forEach(this.compatibleStorageData.storagePodsRefs, (moRef) => {
                  let storageObjId = this.defaultUriSchemeUtil.getVsphereObjectId(moRef);
                  this.compatibleStorageData.compatibleStorage[storageObjId] = true;
               });
            }

            return result;
         });
      }

      private updateReplicationGroupValidationError(): void {
         if (this.isStorageSelected()
               && this.shouldShowAdvancedModeOrChangePolicyWarning()
               && this.hasCurrentDatastoreMatchingReplicationGroups()) {
            const configurePerDiskLabel = this.i18nService.getString("Common", "storageSelector.configurePerDisk");
            this.replicationGroupSettings.replicationGroupValidationError =
                  this.i18nService.getString("Common",
                        "DiskLocatorControl.ReplicationGroup.advancedModeOrAnotherPolicyNeededError",
                        configurePerDiskLabel);
         } else if (this.showReplicationGroupSelector &&
               !this.replicationGroupSettings.selectedReplicationGroup) {
            this.replicationGroupSettings.replicationGroupValidationError =
                  this.i18nService.getString("Common", "DiskLocatorControl.ReplicationGroup.noSelectionError");
         } else {
            this.replicationGroupSettings.replicationGroupValidationError = null;
         }
      }

      /**
       * This warning should be shown when all are true:
       *    1. Selected policy is "Keep existing"
       *    2. One of the existing policies is replication and RG could not be configured from the dropdown
       */
      private shouldShowAdvancedModeOrChangePolicyWarning() {
         return this.storageProfilesData
               && this.storageProfilesData.selectedProfile
               && this.storageProfilesData.selectedProfile.keepExistingProfileAssignments
               && this.replicationGroupSettings.showReplicationGroups
               && (!this.replicationGroupItems || this.replicationGroupItems.length === 0);
      }

      private hasCurrentDatastoreMatchingReplicationGroups() {
         const dsRefs = (this.compatibleStorageData || {}).datastoresWithReplicationGroupsRefs;
         return dsRefs && dsRefs.length > 0 && dsRefs.some(datastoreRef => datastoreRef.value === this.selectedItem.storageRef.value);
      }

      private getDatastoreRefs() {
         return _.pluck(this.storageLocatorItemsData.datastoreItems, "storageRef");
      }

      private getDrsEnabledStoragePodRefs() {
         let result: any[] = [];
         if (this.storageLocatorItemsData && this.storageLocatorItemsData.storagePodItems) {
            _.forEach(this.storageLocatorItemsData.storagePodItems, (podDataItem: any) => {
               if (podDataItem.drsEnabled === true) {
                  result.push(podDataItem.storageRef);
               }
            });
         }
         return result;
      }

      private isDatastoreItemVisible(parentPodDataItem) {
         return this.drsDisabled || !parentPodDataItem || parentPodDataItem.drsEnabled === false
            || this.podDisplayDisabled;
      }

      private isVmStorageAssignedToNonDefaultStorageProfile(vmStorageConfig) {
         return [vmStorageConfig.vmHome, ...vmStorageConfig.vmDisks]
            .filter(vmComponent => vmComponent.storageProfile)
            .some(vmComponent => vmComponent.storageProfile.id !== "defaultId");
      }

      private scrollToPreselection() {
         this.$timeout(() => {
            if (!this.$element) {
               return;
            }
            let grid = this.$element.find("#storageLocatorGrid div[kendo-grid]").data("kendoGrid");
            if (!grid) {
               return;
            }

            let vs = grid.wrapper.find(".k-grid-content").data("kendoVirtualScrollable");
            if (!vs) {
               return;
            }

            if (!grid.select().length) {
               return;
            }
            let offset = grid.select().position().top;

            vs.verticalScrollbar.animate({scrollTop: offset}, 400);
         }, 0);
      }

      private buildStoragePodListItem(podDataItem) {
         return {
            id: this.defaultUriSchemeUtil.getVsphereObjectId(podDataItem.storageRef),
            isDatastore: false,
            name: podDataItem.name,
            childDatastoreDataItems: [],
            icon: "vsphere-icon-datastore-cluster",
            capacity: podDataItem.capacity,
            formattedCapacity: this.bytesFilter(podDataItem.capacity),
            provisionedSpace: podDataItem.provisionedSpace,
            formattedProvisionedSpace: this.bytesFilter(podDataItem.provisionedSpace),
            freeSpace: podDataItem.freeSpace,
            formattedFreeSpace: this.bytesFilter(podDataItem.freeSpace),
            formattedType: "",
            storageDRS: podDataItem.drsEnabled ?
               this.i18nService.getString("Common", "StorageListItem.sdrsEnabled") :
               this.i18nService.getString("Common", "StorageListItem.sdrsDisabled"),
            thinProvisioning: this.formatThinProvisioningSupported(podDataItem),
            access: this.formatMultipleHostAccess(podDataItem),
            hardwareAcceleration: podDataItem.hardwareAcceleration,
            storagePodName: ""
         };
      }

      private buildDatastoreListItem(dsDataItem, parentPodDataItem) {
         return {
            id: this.defaultUriSchemeUtil.getVsphereObjectId(dsDataItem.storageRef),
            isDatastore: true,
            name: dsDataItem.name,
            icon: "vsphere-icon-datastore",
            capacity: dsDataItem.capacity,
            formattedCapacity: this.bytesFilter(dsDataItem.capacity),
            provisionedSpace: dsDataItem.provisionedSpace,
            formattedProvisionedSpace: this.bytesFilter(dsDataItem.provisionedSpace),
            freeSpace: dsDataItem.freeSpace,
            formattedFreeSpace: this.bytesFilter(dsDataItem.freeSpace),
            formattedType: dsDataItem.formattedType,
            storageDRS: "",
            thinProvisioning: this.formatThinProvisioningSupported(dsDataItem),
            access: this.formatMultipleHostAccess(dsDataItem),
            hardwareAcceleration: dsDataItem.hardwareAcceleration,
            parentPodName: parentPodDataItem ? parentPodDataItem.name : "",
            parentPodIcon: parentPodDataItem ? "vsphere-icon-datastore-cluster" : null,
            hasAllocateSpacePrivilege: dsDataItem.hasAllocateSpacePrivilege
         };
      }

      private formatMultipleHostAccess(item) {
         return item.multipleHostAccess
            ? this.i18nService.getString("Common", "StorageListItem.MultipleHostAccess")
            : this.i18nService.getString("Common", "StorageListItem.SingleHostAccess");
      }

      private formatThinProvisioningSupported(item) {
         return item.thinProvisioningSupported
            ? this.i18nService.getString("Common", "StorageListItem.ThinProvisioningSupported")
            : this.i18nService.getString("Common", "StorageListItem.ThinProvisioningNotSupported");
      }

      private getColumnDefs() {
         let columns = [
            {
               field: "name",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.NameColumnHeader"),
               width: 200,
               template: this.iconNameRendererFactory("name", "icon")
            },
            {
               field: "compatibility",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.CompatibilityColumnHeader"),
               width: 110,
               visible: false,
               template: (record) => {
                  return record.compatibility;
               }
            },
            {
               field: "formattedCapacity",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.CapacityColumnHeader"),
               width: 110,
               sortable: (item1, item2) => {
                  return this.listUtil.compareNumericValues(item1, item2, "capacity");
               },
               template: (record) => {
                  return record.formattedCapacity;
               }
            },
            {
               field: "formattedProvisionedSpace",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.ProvisionedSpaceColumnHeader"),
               width: 110,
               sortable: (item1, item2) => {
                  return this.listUtil.compareNumericValues(item1, item2, "provisionedSpace");
               },
               template: (record) => {
                  return record.formattedProvisionedSpace;
               }
            },
            {
               field: "formattedFreeSpace",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.FreeSpaceColumnHeader"),
               width: 110,
               sortable: (item1, item2) => {
                  return this.listUtil.compareNumericValues(item1, item2, "freeSpace");
               },
               template: (record) => {
                  return record.formattedFreeSpace;
               }
            },
            {
               field: "formattedType",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.TypeColumnHeader"),
               width: 110,
               template: (record) => {
                  return record.formattedType;
               }
            },
            {
               field: "storagePodName",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.StoragePodColumnHeader"),
               width: 200,
               template: this.iconNameRendererFactory("parentPodName", "parentPodIcon")
            },
            {
               field: "storageDRS",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.StorageDrsColumnHeader"),
               width: 110,
               template: (record) => {
                  return record.storageDRS;
               }
            },
            {
               field: "thinProvisioning",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.ThinProvisioningColumnHeader"),
               width: 110,
               template: (record) => {
                  return record.thinProvisioning;
               }
            },
            {
               field: "access",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.AccessColumnHeader"),
               width: 110,
               template: (record) => {
                  return record.access;
               }
            },
            {
               field: "hardwareAcceleration",
               displayName: this.i18nService.getString("Common", "StorageLocatorControl.HardwareAccelerationColumnHeader"),
               width: 110,
               template: (record) => {
                  return record.hardwareAcceleration;
               }
            }
         ];

         return columns;
      }

      private iconNameRendererFactory(nameField, iconField) {
         return ((data) => {
            let iconName = data[iconField];
            let objectLinkRenderer = this.columnRenderersRegistry.getColumnRenderer("object-link");
            return objectLinkRenderer([undefined, nameField, iconName], data);
         });
      }

      private areStorageLocatorDataItemsEqual(itemA, itemB) {
         if (!itemA && !itemB) {
            return true;
         }
         if (itemA && itemB) {
            return this.defaultUriSchemeUtil.getVsphereObjectId(itemA.storageRef) ===
               this.defaultUriSchemeUtil.getVsphereObjectId(itemB.storageRef);
         }

         return false;
      }

      private isPmemDatastoreItem(dsDataItem: StorageLocatorDatastoreItem): boolean {
         return dsDataItem.type === "PMEM";
      }

      private filterStorageProfiles(storageProfiles: StorageProfile[]): StorageProfile[] {
         let hasEncryptionPolicies: boolean = false;
         let filteredProfiles: StorageProfile[] =
               _.reduce(storageProfiles,
                     (memo: StorageProfile[], profile: StorageProfile) => {
                        let isEncryptionPolicy: boolean = this.storageProfileService
                              .isEncryptionStorageProfile(profile);
                        hasEncryptionPolicies = hasEncryptionPolicies || isEncryptionPolicy;
                        if (this.isEncryptVmEnabled) {
                           if (!isEncryptionPolicy) {
                              return memo;
                           }
                        }

                        // Skip Pmem profiles in the result
                        if (this.storageProfileService.isPmemStorageProfile(profile)) {
                           return memo;
                        }

                        memo.push(profile);
                        return memo;
                     }, []);

         this.hasEncryptionPolicies = hasEncryptionPolicies;

         return filteredProfiles;
      }
   } // class StorageLocatorController

   angular.module("com.vmware.platform.ui").component(
      "storageLocator", new StorageLocator());

} // namespace
