function VxValueInUnitsController($scope, $element, $attrs, $document, i18nService, vxValueInUnitsService) {
   var UNLIMITED_VALUE = i18nService.getString('Common', 'unlimited');
   var self = this;

   var modelValueChanged = function (convertedValue) {
      if (convertedValue === undefined) {
         convertedValue = self.ngModelController.$viewValue;
      }
      if (self.ngModelController.$viewValue === -1) {
         self.selection.quantity = UNLIMITED_VALUE;
      } else {
         self.selection.quantity = convertedValue;
      }
   };

   function setErrorMessage(errors) {
      var firstErrorForWhichWeHaveAMessage = _.find(_.keys(self.errorMessages), function (errorMessageKey) {
         return errors.hasOwnProperty(errorMessageKey);
      });

      if (!firstErrorForWhichWeHaveAMessage) {
         self.vxErrorConfig.message = i18nService.getString('Common',
               'resourcePool.create.form.invalid.chars');
      } else {
         var message = self.errorMessages[firstErrorForWhichWeHaveAMessage];
         if (angular.isFunction(message)) {
            message = message();
         }
         self.vxErrorConfig.message = message;
      }
   }

   function setDefaultUnit(unit) {
      var actualUnit = unit ? unit : $attrs.defaultUnit;

      if (!self.showUnits) {
         return null;
      } else if (actualUnit) {
         return _.find(self.unitOptions, function(option) {
            return option.label === actualUnit;
         });
      }

      return self.unitOptions[0];
   }

   function highlightValue() {
      var itemHighlighted = false;
      _.each(self.recommendedValues, function(recommendation) {
         if(recommendation.value === self.ngModelController.$viewValue && !itemHighlighted) {
            recommendation.isHighlighted = true;
            itemHighlighted = true;
         } else {
            recommendation.isHighlighted = false;
         }
      });
   }

   function setCustomValidators(ngModelController) {
      if (!self.customValidators) {
         return;
      }
      _.each(self.customValidators, function(validator) {
         ngModelController.$validators[validator.name] = function(modelValue, viewValue) {
            var valid = validator.validate(viewValue);
            self.valueInUnitsForm.quantity.$setValidity(validator.name, valid);
            return valid;
         };
         self.errorMessages[validator.name] = validator.message;
      });
   }

   function installDocumentClickEvent($document, $scope, $element) {
      var dismissDropdown = function (e) {
         // close recommended values dropdown when clicking outside the current element
         // or on the units dropdown
         if (($element !== e.target && !$element[0].contains(e.target)) ||
               (e.target && e.target.type === 'select-one')) {
            $scope.$apply(function () {
               self.properties.recommendedValuesExpanded = false;
            });
         }
      };
      $document.on('click', dismissDropdown);
      $scope.$on('$destroy', function () {
         $document.off('click', dismissDropdown);
      });
   }

   function AttributeValidator(inputForError, ngModelController, attributeName, $attrs, validationFn) {
      $attrs.$observe(attributeName, function () {
         ngModelController.$validate();
      });

      return function (computedValue) {
         var attributeValue = $attrs[attributeName];

         if (ngModelController.$isEmpty(attributeValue)) {
            inputForError.$setValidity(attributeName, true);
            return true;
         }

         var valid = validationFn(computedValue, Number(attributeValue));
         inputForError.$setValidity(attributeName, valid);
         return valid;
      };
   }

   // Lifecycle hooks
   self.$onInit = function() {
      self.properties = {};
      self.showUnits = angular.isUndefined(self.showUnits) ? true : self.showUnits;

      $scope.$watch('ctrl.recommendedValues', function() {
         highlightValue();
      });

      $scope.$watch('ctrl.errorMessages', function() {
         var errors = self.valueInUnitsForm.$error;
         if(!errors || _.isEmpty(errors)) {
            return;
         }

         setErrorMessage(errors);
      });

      $scope.$watchCollection("ctrl.valueInUnitsForm.$error", function (errors) {
         self.vxErrorConfig.isVisible = false;
         self.vxErrorConfig.message = "";

         if (!errors || _.isEmpty(errors)) {
            return;
         }

         setErrorMessage(errors);

         self.vxErrorConfig.isVisible = true;
      });

      self.ngModelController = self.ngModel;

      setCustomValidators(self.ngModelController);
      self.ngModelController.$render = modelValueChanged;

      if ($attrs.defaultUnit) {
         var setDefaultQuantity = $scope.$watch('ctrl.selection.quantity', function() {
            if (self.selection.quantity !== UNLIMITED_VALUE) {
               if (!self.selection.unit) {
                  throw new Error("No unit for '" + self.name + "'.");
               }
               self.selection.quantity = self.ngModelController.$viewValue / self.selection.unit.multiplier;
            }
            setDefaultQuantity(); // to stop watching
         });
      }
   };

   self.$postLink = function() {
      self.name = $attrs.name;
      self.type = $attrs.type || 'text';
      self.selection = {
         quantity: 1,
         unit: setDefaultUnit()
      };
      self.allowedCharacters = angular.isDefined(self.allowedCharacters) ?
         self.allowedCharacters : '0-9.';
      self.vxErrorConfig = {
         message: "",
         isVisible: false
      };

      installDocumentClickEvent($document, $scope, $element);

      self.ngModelController.$validators.max = AttributeValidator(self.valueInUnitsForm.quantity, self.ngModelController, 'max', $attrs, function (computedValue, attributeValue) {
         return computedValue <= attributeValue;
      });


      self.ngModelController.$validators.min = AttributeValidator(self.valueInUnitsForm.quantity, self.ngModelController, 'min', $attrs, function (computedValue, attributeValue) {
         return computedValue >= attributeValue;
      });

      self.ngModelController.$validators.mod = AttributeValidator(self.valueInUnitsForm.quantity, self.ngModelController, 'mod', $attrs, function (computedValue, attributeValue) {
         if(!$attrs.min) {
            $attrs.min = 0;
         }

         var valid;
         if($attrs.hotPlugActive) {
            var hotPlugValid = (computedValue - $attrs.min) >= attributeValue;
            var modValid = (computedValue % attributeValue === 0);

            valid = (modValid && hotPlugValid) || (Number($attrs.min) === computedValue);
         } else {
            valid = computedValue % attributeValue === 0 ;
         }
         return valid;
      });

      self.updateModelFromFields = function(quantity, unit) {
         var multiplier = unit ? unit.multiplier : 1;
         var value = 0;
         self.currentUnit = unit;

         if (quantity === UNLIMITED_VALUE) {
            value = -1;
         }
         else {
            if(quantity){
                value = quantity * multiplier;
            }
         }

         self.ngModelController.$setViewValue(value);

         if (self.unitChangedCallback && (typeof self.unitChangedCallback === 'function')) {
            self.unitChangedCallback(unit ? unit : null);
         }

         if (self.valueChangedCallback && (typeof self.valueChangedCallback === 'function')) {
            self.valueChangedCallback({value: value});
         }
      };

      self.clickDropdownButton = function() {
         self.properties.recommendedValuesExpanded = !self.properties.recommendedValuesExpanded;
         vxValueInUnitsService.onRecommendedValuesExpandedChange($element);
      };

      self.setModelValue = function (recommendation) {
         if (recommendation.unit) {
            self.selection = {
               quantity: 1,
               unit: setDefaultUnit(recommendation.unit)
            };
         }
         self.ngModelController.$setViewValue(recommendation.value);
         modelValueChanged(recommendation.value /
               (self.selection.unit ? self.selection.unit.multiplier : 1));
         highlightValue();
      };
   };
}

angular.module('com.vmware.platform.ui')
   .component('vxValueInUnits', {
      templateUrl: 'resources/ui/components/forms/vxValueInUnits.html',
      bindings: {
         unitOptions: "<",
         unitChangedCallback: "=",
         valueChangedCallback: "&",
         errorMessages: "<",
         isDisabled: "<",
         recommendedValues: "<",
         showUnits: "<?",
         customValidators: "<?",
         allowedCharacters: "@",
         currentUnit: "=?",
         unitAriaLabel: "<?",
         quantityCaretAriaLabel: "<?",
         quantityAriaLabel: "<?"

      },
      require: {
         ngModel: 'ngModel'
      },
      controller: VxValueInUnitsController,
      controllerAs: 'ctrl'
   });

VxValueInUnitsController.$inject = [
   '$scope',
   '$element',
   '$attrs',
   '$document',
   'i18nService',
   'vxValueInUnitsService'
];
