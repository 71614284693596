/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Util service to create the nodes needed for use of vx-view
 */
angular.module('com.vmware.platform.ui').factory('vxViewUtil', [
function() {

   var id = 0;

   return {
     /**
       * Get navi data
       */
      getNode: function(src) {
         id++;

         var rawNode = angular.extend({
            $id: id,
            $selectedChildIndex: -1,
            $children: []
         }, src);

         return new NavigationTree(rawNode).getNodeAtLevel(0);
      },
   };
}]);
