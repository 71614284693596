/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Messaging service to communicate between ObjectNavigator and Search
 */
angular.module('com.vmware.platform.ui').factory('searchService',
['$rootScope',
function($rootScope) {

   var api = {};

   var searchResultData = {
      query : undefined,
      searchState : undefined,
      searchType : undefined,
      results : {}
   };

   function broadcastMessage (resultData) {
      $rootScope.$broadcast('searchEvent');
   }

   api.searchStates = {
      searchInProgress : 0,
      searchComplete : 1,
      searchDestroyed : 2
   };

   api.searchTypes = {
      simple : 0,
      advanced : 1
   };


   api.getSearchData = function () {
      return searchResultData;
   };

   api.setSearchData = function (searchState, searchResults, query, searchType) {
      searchResultData = {
         query : query,
         searchState : searchState,
         searchType : searchType,
         results : searchResults
      };
      broadcastMessage();
   };



   return api;

}]);
