/**
 * Accepts text of the form  "something {vm.name} with {host.otherName} and {someUnknownEntity.name}"
 * As well as an object specifying the values to replace the {variable} expressions
 * and renders HTML ready text that has navigation links inside.
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('linkedText', linkedText);

   linkedText.$inject = ['defaultUriSchemeUtil', 'columnRenderersRegistry', '$compile'];

   function linkedText(defaultUriSchemeUtil, columnRenderersRegistry, $compile) {
      var getLinkHTMl = columnRenderersRegistry.getColumnRenderer('link');

      return {
         scope: {
            text: '=',
            targets: '=',
            fallbackText: '='
         },
         template: "",
         link: link
      };

      function link(scope, element) {
         scope.$watch('text', function() {
            var text = _.escape(scope.text);
            if (!scope.targets) {
               scope.targets = {};
            }

            _.every(parseEntities(text), function(entity) {
               if (getTargetEntity(entity)) {
                  var linkHTML = getLinkHTMl(getLinkTarget(entity), getLinkText(entity));
                  text = text.replace(entity, linkHTML);
                  return true;
               } else {
                  text = '<span ng-non-bindable>' + scope.fallbackText + '</span>';
                  return false;
               }
            });
            element.html(text);
            $compile(element.contents())(scope);
         }, true);


         function getTargetEntity(entity) {
            var key = entity.match(/{([^{}]+)}/)[1];
            return (scope.targets[key] || {}). entity;
         }

         function parseEntities(text) {
            return text.match(/{([^{}]+)}/g) || [];
         }

         function getLinkTarget(entity) {
            return defaultUriSchemeUtil.getVsphereObjectId(getTargetEntity(entity));
         }

         function getLinkText(entity) {
            var key = entity.match(/{([^{}]+)}/)[1];
            return (scope.targets[key] || {}).name;
         }
      }
   }
})();
