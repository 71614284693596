/* Copyright 2014 VMware, Inc. All rights reserved. -- VMware Confidential */

angular.module('com.vmware.platform.ui').factory('refreshHttpInterceptor', ['$q', '$rootScope',
function($q, $rootScope){
      var requestCount = 0;

      return {
         'request': function(config) {
            if (!config || config.skipLoadingNotification !== true) {
               requestCount++;
               $rootScope.$broadcast('showRefreshSpinner');
            }
            return config || $q.when(config);
         },
         'requestError': function(rejection) {
            return $q.reject(rejection);
         },
         'response': function(response) {
            if (!response.config || response.config.skipLoadingNotification !== true) {
               requestCount--;
            }
            // broadcast when no requests are pending
            if (requestCount === 0) {
               $rootScope.$broadcast('hideRefreshSpinner');
            }
            return response || $q.reject(response);
         },
         'responseError': function(rejection) {
            if (!rejection.config || rejection.config.skipLoadingNotification !== true) {
               requestCount--;
            }
             if (requestCount === 0) {
               $rootScope.$broadcast('hideRefreshSpinner');
            }
            return $q.reject(rejection);
         }
      };
   }]);

angular.module('com.vmware.platform.ui').config(['$httpProvider',
   function($httpProvider) {
   $httpProvider.interceptors.push('refreshHttpInterceptor');

   var spinnerFunction = function spinnerFunction(data, headersGetter) {
      return data;
   };
   $httpProvider.defaults.transformRequest.push(spinnerFunction);
}]);
