(function() {

   'use strict';

   angular.module('com.vmware.platform.ui')
         .factory('dragAndDropRulesService', dragAndDropRulesService);

   dragAndDropRulesService.$inject = [
      '$http'
   ];

   function dragAndDropRulesService($http) {

      var getRules = _.memoize(function() {
         return $http({
            method: 'get',
            url: 'dragAndDrop/rules'
            }).then(function(resp) {
               return resp.data;
            });
      });

      return {
         getRules: getRules
      };
   }
})();
