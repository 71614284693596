/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Click directive, supports different types of click. Eg: long click
 */
angular.module('com.vmware.platform.ui').directive('vxCollapseIcon', ['$timeout', '$parse',
function($timeout, $parse) {
   return {
      restrict: 'A',
      scope: {
         vxCollapseIcon: '=?'
      },
      template: '<i ng-class="getIcon()" style="margin: 0px;"></i>',
      link: function($scope, iElement, iAttrs, controller){

      },
      controller:['$scope', '$element', '$attrs', function($scope, $element, $attrs) {


         $scope.getIcon = function() {
            return $scope.vxCollapseIcon ? 'vui-icon-caret-gray-right' : 'vui-icon-caret-gray-down';
         };
         $element.on('click', function () {
            $scope.vxCollapseIcon = !$scope.vxCollapseIcon;
         });
      }]
   };
}]);