/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Decorator for $rootScope, overrides the $digest method to add performance
 * tracking data.
 */
angular.module('com.vmware.platform.ui').config(["$provide", function ($provide) {
   $provide.decorator('$rootScope', ['$delegate', function ($delegate) {
      var originalDigest = $delegate.$digest;
      $delegate.$digest = function (args) {
         var startTime = Date.now();
         performanceService.digestStart();

         // Call the original digest.
         originalDigest.apply(this, args);

         // Calculate the time for the digest cycle.
         var digestCycleTime = Date.now() - startTime;
         performanceService.digestStop(digestCycleTime);
      };

      return $delegate;
   }]);
}]);