/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Controller for the tasks view.
 */

(function() {
   'use strict';
   angular
         .module('com.vmware.platform.ui')
         .controller('TaskConsoleViewController', TaskConsoleViewController);

   TaskConsoleViewController.$inject = ['$scope', '$element', 'i18nService',
         'vuiConstants', '$http', 'columnRenderersRegistry', 'taskFormatter', '$q',
         'objectTypesService', 'taskConstants', 'recentTasksStoreService',
         'listViewColumnService', 'vcH5ConstantsService',
         'visibilityAwareDataUpdateNotificationService', 'timeFormatterService'];

   function TaskConsoleViewController($scope, $element, i18nService,
         vuiConstants, $http, columnRenderersRegistry, taskFormatter, $q,
         objectTypesService, taskConstants, recentTasksStoreService,
         listViewColumnService, vcH5ConstantsService,
         visibilityAwareDataUpdateNotificationService, timeFormatterService) {

      var self = this;

      // The Id of the currently selected object in the inventory
      var objectId = $scope._route.objectId;
      var viewId = $scope._view.$id;
      var columnsChangeHandler = listViewColumnService
            .getColumnChangeHandler(viewId, $scope);

      var requestedPage = 0;
      var pageSize = 100;
      var hasNextPage = false;
      var skipSelectedItemRendering = false;

      var dataUpdateNotificationSubscriberId = null;

      // flag indicating whether or not there is a pending refresh of task info
      self.pendingRequest = false;

      /**
       * We keep a map of non-finished tasks we can easily update
       * when live-updates arrive.
       */
      var pendingTasksByKey = {};

      /**
       * Flag indicating if we are migrating forward or backward. This information needs
       * to be passed to the server side if there is migration history in play so that
       * it knows whether to advance to the next or previous migrated identity when it
       * reaches the end of the current set of events
       */
      var navigatingForward = true;
      var i18n = i18nService.getString.bind(i18nService, 'Common');
      var linkRenderer = columnRenderersRegistry.getColumnRenderer('object-link');
      var textRenderer = columnRenderersRegistry.getColumnRenderer('text');
      var taskStatusRenderer = columnRenderersRegistry
            .getColumnRenderer('task-status-first-line');
      var taskTargetRenderer = function(clientTaskInfo, focusable) {
         return linkRenderer(
            ['entityUid', 'entityName', clientTaskInfo.entityIcon, '',
               clientTaskInfo.targetExists],
            clientTaskInfo, {
               focusable: focusable ? true : false
            });
      };

      var previousPageLabel = i18n('taskPrevPage');
      var previousPageButton = {
         id: 1,
         tooltipText: previousPageLabel,
         label: previousPageLabel,
         enabled: false,
         iconClass: 'vui-icon-object-nav-history-left-hover',
         onClick: function() {
            navigatingForward = false;
            requestedPage--;
            fetchTasks();
         }
      };

      var nextPageLabel = i18n('taskNextPage');
      var nextPageButton = {
         id: 2,
         tooltipText: nextPageLabel,
         label: nextPageLabel,
         enabled: false,
         iconClass: 'vui-icon-object-nav-history-right-hover',
         onClick: function() {
            navigatingForward = true;
            requestedPage++;
            fetchTasks();
         }
      };

      var columnDefinitions = [{
         displayName: i18n('taskNameHeader'),
         field: 'description',
         template: function(clientTaskInfo) {
            return textRenderer(['description'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskTargetHeader'),
         field: 'entityName',
         template: taskTargetRenderer
      }, {
         // TODO semerdzhievp Monitor and update status of running tasks.
         displayName: i18n('taskStatusHeader'),
         field: 'state',
         sortable: function(cti1, cti2) {
            var status1 = cti1.statusSummary;
            var status2 = cti2.statusSummary;
            if (status1 < status2) {
               return -1;
            }
            if (status1 > status2) {
               return 1;
            }
            return 0;
         },
         template: columnRenderersRegistry.getColumnRenderer('task-state')
      }, {
         displayName: i18n('taskDetailsHeader'),
         field: 'details',
         template: function(clientTaskInfo) {
            return textRenderer(['details'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskInitiatorHeader'),
         field: 'initiator',
         template: function(clientTaskInfo) {
            return textRenderer(['initiator'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskQueueTimeHeader'),
         field: 'queuedForMilliseconds',
         sortable: function(cti1, cti2) {
            return compareNumericValues(cti1, cti2, 'queuedForMilliseconds');
         },
         template: function(clientTaskInfo) {
            return textRenderer(['timeInQueueString'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskStartTimeHeader'),
         field: 'startTime',
         template: function(clientTaskInfo) {
            return textRenderer(['startTimeString'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskCompleteTimeHeader'),
         field: 'completionTime',
         template: function(clientTaskInfo) {
            return textRenderer(['completionTimeString'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskTotalTimeHeader'),
         field: 'timeInExecution',
         sortable: function(cti1, cti2) {
            return compareNumericValues(cti1, cti2, 'timeInExecution');
         },
         template: function(clientTaskInfo) {
            return textRenderer(['timeInExecutionString'], clientTaskInfo);
         }
      }, {
         displayName: i18n('taskvServerHeader'),
         field: 'service',
         template: function(clientTaskInfo) {
            return linkRenderer(['rootFolderUid', 'service'],
                  clientTaskInfo);
         }
      }, {
         visible: false,
         template: function(clientTaskInfo) {
            return clientTaskInfo.key;
         }
      }];

      listViewColumnService.applyPersistedState(columnDefinitions, viewId)
            .then(function(columnDefs) {
         self.tasksListOptions = {
            columnDefs: columnDefs,
            data: [],
            sortOrder: [{
               field: 'startTime',
               dir: 'desc'
            }],
            actionBarOptions: {
               actions: [previousPageButton, nextPageButton]
            },
            sortMode: vuiConstants.grid.sortMode.SINGLE,
            selectionMode: vuiConstants.grid.selectionMode.SINGLE,
            selectedItems: [],
            height: '100%',
            resizable: true,
            reorderable: true,
            columnMenu: {
               sortable: false, // this will hide sort menu items
               messages: {
                  columns: i18nService.getString('CommonUi', 'listview.showColumnsMenu'),
                  filter: i18nService.getString('CommonUi', 'listview.filterMenu')
               }
            },
            columnHide: columnsChangeHandler,
            columnReorder: columnsChangeHandler,
            columnResize: columnsChangeHandler,
            columnShow: columnsChangeHandler
         };

         // send initial data request
         fetchTasks(false);

         // Listen for changes in the selection
         $scope.$watch(function() {
            return self.tasksListOptions.selectedItems;
         }, function(selectedItems, previouslySelectedItems) {
            if (selectedItems.length === 1) {

               // If previous task is set to Select task return to avoid flickering
               if (skipSelectedItemRendering === true) {
                  skipSelectedItemRendering = false;
                  return;
               }
               var selectedTask = selectedItems[0];
               // Early return to avoid flickering if the newly selected
               // task is the same as the old one
               if (previouslySelectedItems.length === 1 &&
                     previouslySelectedItems[0].key === selectedTask.key) {
                  return;
               }
               self.selectedTask = selectedTask;
               self.selectedTask.errorStack = false;
               selectedTask.statusTemplate = taskStatusRenderer(selectedTask);
               selectedTask.targetTemplate = taskTargetRenderer(selectedTask, true);
               selectedTask.errorReportArgs = {
                  taskId: selectedTask.key,
                  parentTaskId: selectedTask.parentTaskId,
                  eventId: selectedTask.eventChainId,
                  serverGuid: selectedTask.serverGuid
               };
               getErrorStack(selectedTask.errorReportArgs)
                     .then(function(errorStack) {
                        // skip first line in the stack - the error message
                        if (errorStack.length > 1 ) {
                           errorStack.shift();
                           // Some time there is a line in stack trace with no content
                           if (!!errorStack[0]) {
                              selectedTask.errorStack = errorStack;
                           }
                        }
                     });
               if (selectedTask.result) {
                  selectedTask.resultTemplate = textRenderer(
                        ['result'], selectedTask);
               } else if (!selectedTask.resultType) {
                  selectedTask.resultTemplate = linkRenderer(
                        ['resultEntityUid', 'resultName'], selectedTask);
               } else {
                  objectTypesService.getObjectTypeSpec(selectedTask.resultType)
                        .then(function(objectTypeSpec) {
                           selectedTask.resultEntityIcon = objectTypeSpec.icon;
                           selectedTask.resultTemplate = linkRenderer(
                                 ['resultEntityUid', 'resultName',
                                    selectedTask.resultEntityIcon], selectedTask);
                        });
               }
               self.hasRelatedEvents = false;
               fetchRelatedEvents(selectedTask);
            } else {
               if (previouslySelectedItems.length === 1) {
                  self.selectedTask = previouslySelectedItems[0];
                  skipSelectedItemRendering = true;
                  var grid  = $element.find('[kendo-grid]').data("kendoGrid");
                  var row = grid.tbody.find("tr:contains('" + previouslySelectedItems[0].key+ "')");
                  if(row.length) {
                     grid.select(row);
                  }
               } else {
                  self.selectedTask = null;
               }
            }
         });

         var dataUpdateEventsConfig = {};
         dataUpdateEventsConfig[vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT] = {
            handler: function() {
               fetchTasks();
            }
         };
         dataUpdateEventsConfig[taskConstants.events.ADDED] = {
            handler: function() {
               fetchTasks();
            }
         };
         dataUpdateEventsConfig[taskConstants.events.UPDATED] = {
            handler: onTasksUpdated,
            checker: areTaskUpdatesRelevant
         };

         dataUpdateNotificationSubscriberId =
            visibilityAwareDataUpdateNotificationService.subscribeForDataUpdate(
               $element,
               fetchTasks,
               dataUpdateEventsConfig
            );
      });

      // TODO semerdzhievp extract the related events grid as a separate
      // component to avoid duplicating code in events- and tasks- consoles
      self.relatedEventsListOptions = {
         columnDefs: [{
            displayName: i18nService.getString('EventUi',
                  'eventConsoleView.eventTriggeredOnColumn.headerText'),
            width: '20%',
            field: 'createdTime',
            searchable: false,
            template: function(dataItem) {
               return ['<div>',
                        '<span class="icon-vSphere-events"></span>',
                        '<span>',
                  timeFormatterService.timestampToText(parseInt(dataItem.createdTime)),
                        '</span>',
                     '</div>'].join('');
            }
         }, {
            displayName: i18nService.getString('EventUi',
                  'eventConsoleView.eventDescriptionColumn.headerText'),
            field: 'contextSensitiveMessage',
            width: '80%',
            template: function(dataItem) {
               return ['<div>',
                  '<linked-text text="dataItem.linkableFormattedMessage" targets="dataItem.linkableEntities" fallback-text="dataItem.fullFormattedMessage" title="{{dataItem.fullFormattedMessage}}">',
                  '</linked-text>',
                  '</div>'].join('');
            }
         }],
         data: [],
         sortMode: vuiConstants.grid.sortMode.SINGLE,
         selectionMode: vuiConstants.grid.selectionMode.SINGLE,
         selectedItems: [],
         height: '100%',
         resizable: true
      };

      self.splitterOptions = {
         orientation: vuiConstants.splitter.orientation.VERTICAL,
         panes: [{
            min: '140px',
            size: '75%'
         }, {
            min: '100px'
         }]
      };

      /**
       * List data is not being deeply watched. Therefore, a digest will not
       * notice if, for example, the progress of some task changes.
       * This function maps the identity function over the data source to
       * create a new array with the same items.
       * TODO semerdzhievp Figure out a better solution.
       */
      function triggerDataSourceUpdate() {
         self.tasksListOptions.data =
               self.tasksListOptions.data.map(function(x) {return x;});
      }

      // Enables/Disables the paging buttons
      function setButtonsAvailability(enabled) {
         previousPageButton.enabled = enabled && requestedPage !== 0;
         nextPageButton.enabled = enabled && hasNextPage;
      }

      function setViewData(tasks) {
         self.tasksListOptions.data = tasks;
         hasNextPage = tasks && tasks.length === pageSize;
      }

      function fetchTasks() {
         if (self.pendingRequest === true) {
            return;
         }
         self.pendingRequest = true;
         setButtonsAvailability(false);
         $http.get('tasks/', {
            params: {
               objectId: objectId,
               requestedPage: requestedPage,
               pageSize: pageSize,
               requestingPreviousPage: navigatingForward
            }
         }).then(function(resp) {
            setViewData(resp.data);
            pendingTasksByKey = {};
            return $q.all(resp.data.map(function(clientTaskInfo) {
               if (clientTaskInfo.state === taskConstants.status.RUNNING ||
                     clientTaskInfo.state === taskConstants.status.PAUSED) {
                  pendingTasksByKey[clientTaskInfo.key] = clientTaskInfo;
               }
               var recentTask = recentTasksStoreService
                     .getTask(clientTaskInfo.key, clientTaskInfo.serverGuid);
               if (recentTask) {
                  angular.extend(clientTaskInfo, recentTask);
               }
               return taskFormatter.setDerivedProperties(clientTaskInfo);
            }));
         }).then(setViewData)
         .finally(function() {
            self.pendingRequest = false;
            setButtonsAvailability(true);
         });
      }

      function fetchRelatedEvents(selectedTask) {
         return $http.get('events/', {
            params: {
               serverGuid: selectedTask.serverGuid,
               chainId: selectedTask.eventChainId
            }
         }).then(function(response) {
            if (self.selectedTask &&
                  self.selectedTask.key === selectedTask.key) {
               self.relatedEventsListOptions.data = response.data;
               self.hasRelatedEvents =
                     self.relatedEventsListOptions.data.length !== 0;
            }
         });
      }

      // fetches the error stack to show from the server
      function getErrorStack(errorReportArgs) {
         return $http.get('tasks/errorStack', {
            params: errorReportArgs
         }).then(function(response) {
            return response.data;
         });
      }

      function compareNumericValues(item1, item2, field) {
         var firstValue = Number(item1[field]);
         var secondValue = Number(item2[field]);
         if (firstValue < secondValue) {
            return -1;
         }
         if (firstValue > secondValue) {
            return 1;
         }
         return 0;
      }

      function areTaskUpdatesRelevant(event, updatedTasksRefs) {
         return _.some(updatedTasksRefs, function(taskRef) {
            return (taskRef.value in pendingTasksByKey);
         });
      }

      function onTasksUpdated(event, updatedTasksRefs) {
         _.each(updatedTasksRefs, function(taskRef) {
            var taskKey = taskRef.value;
            if (taskKey in pendingTasksByKey) {
               var currentlyDiplayedTask = pendingTasksByKey[taskKey];
               var updatedTask = recentTasksStoreService.getTask(
                     taskKey, taskRef.serverGuid);
               angular.extend(currentlyDiplayedTask, updatedTask);
            }
         });

         triggerDataSourceUpdate();
      }

      $scope.$on('$destroy', function() {
         if (dataUpdateNotificationSubscriberId !== null) {
            visibilityAwareDataUpdateNotificationService.unsubscribe(
               dataUpdateNotificationSubscriberId
            );
            dataUpdateNotificationSubscriberId = null;
         }
      });
   }
})();
