/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {
   'use strict';

   angular
         .module('com.vmware.platform.ui')
         .factory('objectTypesService', objectTypesService);

   objectTypesService.$inject = ['$http'];

   /*
    * Service for retrieving ObjectTypeSpec registered for the given object type.
    */
   function objectTypesService ($http) {

      // --------------- public APIs  ----------------
      var service = {
         getObjectTypeSpec: _.memoize(getObjectTypeSpec),
         getAllObjectTypeSpecs: _.memoize(getAllObjectTypeSpecs)
      };

      var URLS = {
         fetchObjectTypeSpec: function(objectType) {
            return 'objectTypes/' + objectType + '/';
         },
         fetchAllObjectTypeSpecs: function () {
            return 'objectTypes/';
         }
      };

      return service;

      // --------------- API implementation ----------------

      function get(url, params) {
         return $http.get(url, {params: params}).then(function(result) {
            return result.data;
         });
      }

      function getObjectTypeSpec(objectType) {
         var url = URLS.fetchObjectTypeSpec(objectType);
         var params = [];
         return get(url, params);
      }

      function getAllObjectTypeSpecs() {
         var url = URLS.fetchAllObjectTypeSpecs();
         return get(url);
      }
   }
})();