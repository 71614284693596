/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive to create a data-driven settings view with stackview inside it.
 * It renders a stackview showing key/values for the propertyName passed in the scope for an object with identifier passed in objectId.
 * It also does refresh of the values - manual refresh and auto-refresh.
 * This directive takes an isolated scope which should contain something like:
 *    {
 *       objectId : ObjectId of object whose settings is being rendered.
 *       propertyName: Name of the property to be queried for the object.
 *       formatValueCallback: Property value formatting callback function, accepts a String and Object, and returns a String.
 *       delayRefreshTimeInMsecsOnObjectUpdate: Optional Time in MSecs to refresh data after a object change is detected. Use this only when you find inconsistencies
 *       where backend VC server does not change the object properties before returning.
 *    }
 */
(function() {
  'use strict';
   angular.module('com.vmware.platform.ui').directive('vxStackviewSettings', stackviewSettings);

   function stackviewSettings() {
      var directive = {
         templateUrl: 'resources/ui/views/settings/vxStackviewSettings.html',
         scope: {
            vxStackviewSettings: '='
         },
         controller: StackviewSettingsController,
         controllerAs: 'stackviewSettings',
         bindToController: true
      };
      return directive;
   }

   StackviewSettingsController.$inject = [
      '$filter',
      'resourceService',
      '$q'
   ];

   function StackviewSettingsController(
      $filter,
      resourceService,
      $q
   ) {
      var self = this;

      self.$onInit = function () {
         self.objectId = this.vxStackviewSettings.objectId;
         self.propertyName = this.vxStackviewSettings.propertyName;
         self.formatValueCallback = this.vxStackviewSettings.formatValueCallback;
         self.delayRefreshTimeInMsecs = this.vxStackviewSettings.delayRefreshTimeInMsecsOnObjectUpdate;
         self.supportedKey = this.vxStackviewSettings.supportedKey;
         self.liveRefreshProperties = this.vxStackviewSettings.liveRefreshProperties;
         self.liveRefreshEnabled = this.vxStackviewSettings.liveRefreshEnabled;
         self.datasetSupported = true;
         self.unsupportedMessage = this.vxStackviewSettings.unsupportedMessage;
         self.watchForObjects = [self.objectId];
         self.getData = getData;
         getData();
      };

      function getData() {
         resourceService.getResourceInfo(self.objectId, self.propertyName, {
            supportedKey: self.supportedKey
         }).then(function(data) {
            self.datasetSupported = data.datasetSupported;
            self.stackViewData = {blocks: []};
            var contentPromises = [];
            angular.forEach(
               data.properties,
               function(property) {
                  if (!angular.isDefined(property.value) || property.type === "password") {
                     return;
                  }
                  var pushing = $filter('dataFormat')(property.value, property.type);

                  if (!pushing) {
                     pushing = '--';
                  }

                  if (self.formatValueCallback) {
                     contentPromises.push(self.formatValueCallback(property.key, pushing));
                  }

                  this.push({label: property.label, content: pushing});
               },
               self.stackViewData.blocks
            );
            //TODO refactor this to old behavior once timeFormatterService become synchronous
            if (contentPromises.length > 0) {
               $q.all(contentPromises).then(function(data) {
                  _.each(data, function(item, index) {
                     self.stackViewData.blocks[index].content = item;
                  });
               });
            }
         });
      }
   }
})();
