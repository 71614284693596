/// <reference path="../../../types/clarity/index.d.ts" />

module platform {
// missing clarity pieces regenerated
   export class DropdownToggleDirective implements angular.IDirective {

      restrict = "A";
      require: any;

      constructor(private clarityExtender: ClarityExtenderService) {
         // shared state only
         this.require = clarityExtender.augmentRequire({}, true);

      }

      link: angular.IDirectiveLinkFn = (scope: angular.IScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, requiredCtrlMap: any) => {
         let clarityDropdown;

         element.addClass("dropdown-toggle"); // styled

         this.clarityExtender.fetchingHostComponent(requiredCtrlMap).then((cmp) => clarityDropdown = cmp);

         // behavioral
         element.on("click", (evt: Event) => {
            clarityDropdown.toggleDropdown();
         });

      };

      static Metastructure: AngularMetastructure<angular.IDirectiveFactory> = {
         name: "clrDropdownToggle", // referenced name
         factory: ["ClarityExtenderService", (ClarityExtenderService: ClarityExtenderService) => new DropdownToggleDirective(ClarityExtenderService)]
      };

   }

   export class DropdownItemDirective implements angular.IDirective {

      restrict = "A";

      bindToController = {
         "(click)": "&" // item action per click
      };

      require: any;

      controller = class {
         clarityDropdown: any;

         constructor(private $element: angular.IAugmentedJQuery, private clarityExtender: ClarityExtenderService) {
            $element.addClass("dropdown-item"); // styled
         }

         $onInit() {
            this.clarityExtender.fetchingHostComponent(this).then((cmp) => this.clarityDropdown = cmp);

            // behavioral : menu item mouse click
            this.$element.on("click", (evt: Event) => {
               let outputEvent = this["(click)"];
               if (outputEvent) {
                  outputEvent({"$evt": evt}); // decorate with MouseEvent
                  this.onDropdownItemClick();
               }
            });
         }


         // behavior via clarity
         onDropdownItemClick(): void {
            if (this.clarityDropdown.isMenuClosable && !this.$element.hasClass("disabled")) {
               this.clarityDropdown.toggleDropdown();
            }
         }

         static $inject: Array<string> = ["$element", ClarityExtenderService.Metastructure.name];
      };


      constructor(private clarityExtender: ClarityExtenderService) {
         // shared state only
         this.require = clarityExtender.augmentRequire({}, true);
      }

      // link: angular.IDirectiveLinkFn = (scope: DropdownItemDirectiveScope, element: angular.IAugmentedJQuery, attrs: angular.IAttributes, requiredCtrlMap: any) => { };

      static Metastructure: AngularMetastructure<angular.IDirectiveFactory> = {
         name: "clrDropdownItem", // referenced name
         factory: ["ClarityExtenderService", (ClarityExtenderService: ClarityExtenderService) => new DropdownItemDirective(ClarityExtenderService)]
      };

   }

   // module definition0
   angular.module("clarity-bridge").directive(DropdownToggleDirective.Metastructure.name,
      DropdownToggleDirective.Metastructure.factory);
   angular.module("clarity-bridge").directive(DropdownItemDirective.Metastructure.name,
      DropdownItemDirective.Metastructure.factory);
}
