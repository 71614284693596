/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Service to save data to local storage.
 * There are two flavors for getting/setting data:
 * getData/setData & getUserData/setUserData
 *
 * The first one gets/sats the dada into the local storage directly,
 * while the second one modifies the key to include the username
 * (thus its usage is best for data manipulation specific to the currently logged user)
 * and that's why it uses promises, i.e. the result of getUserData is a primise
 * which when resolved will contain the actual data
 */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui').factory('localStorageService', localStorageService);

   localStorageService.$inject = ['$window', '$q', 'userSessionService', 'logService'];

   function localStorageService($window, $q, userSessionService, logService) {
      //deferred object used to optimise the process of retrieving the username,
      //i.e. when the username is retrieved this deferred object is being reused henceforth
      var _deferredUsername;

      //define and initialize the log
      var log = logService('localStorageService');

      //the real local storage object
      var localStorage = getLocalStorage();

      //Public API
      var service = {
         getData: getData,
         setData: setData,
         getUserData: getUserData,
         setUserData: setUserData,
         removeUserData: removeUserData
      };
      return service;

      /**
       * Retrieving data from the local storage.
       * @param key the key to the local storage data
       * @returns the object being saved in the local storage,
       *         Note that the stored data is converted through angular.fromJson()
       */
      function getData(key) {
         var data = (localStorage ? localStorage.getItem(key) : null);
         data = angular.fromJson(data);
         return data;
      }

      /**
       * Setting data into the local storage.
       * The data is stringified through angular.toJson() before being written
       * @param key the key to the local storage data
       * @param data to be stored
       */
      function setData(key, data) {
         data = angular.toJson(data);
         if (localStorage) {
            try {
               localStorage.setItem(key, data);
            } catch(e) {
               // ignore case of mobile browser or safari incognito mode where localStorage is not fully supported
               // see "QuotaExceededError: DOM Exception 22" in http://h5-feedbackportal.eng.vmware.com:3000/feedback?errlist=1
            }
         }
      }

      /**
       * Removing data from local storage
       * @param key the key to the local storage data
       */
      function removeData(key) {
         if (localStorage) {
            try {
               localStorage.removeItem(key);
            } catch(e) {
               // ignore case of mobile browser or safari incognito mode
               // where localStorage is not fully supported
               // see "QuotaExceededError: DOM Exception 22"
               // in http://h5-feedbackportal.eng.vmware.com:3000/feedback?errlist=1
            }
         }
      }

      /**
       * Retrieving user specific data from the local storage.
       * This method is similar to getData() and it uses it internally.
       * The key is concatenated with the username to build the final key.
       *
       * @param key the key to the local storage data
       * @returns a promise which when resolved will hold the object being saved in the local storage
       */
      function getUserData(key) {
         var deferred = $q.defer();
         getUsername().then(function (username) {
            var data = getData(key + username);
            deferred.resolve(data);
         });
         return deferred.promise;
      }

      /**
       * Setting user specific data into the local storage.
       * This method is similar to setData() and it uses it internally.
       * The key is concatenated with the username to build the final key.
       */
      function setUserData(key, data) {
         getUsername().then(function (username) {
            setData(key + username, data);
         });
      }

      /**
       * Removing user specific data from the local storage
       * This method is similar to removeData() and it uses it internally.
       * The key is concatenated with the username to build the final key.
       */
      function removeUserData(key) {
         getUsername().then(function (username) {
            removeData(key + username);
         });
      }

      /**
       * Retrieves the username from the userSessionService
       * @returns a promise which when resolved will hold the username converted to lowercase
       */
      function getUsername() {
         if (_deferredUsername) {
            return _deferredUsername.promise;
         }

         //this deferred object is used until the username is successfully retrieved
         var deferred = $q.defer();
         userSessionService.getUserSession().then(function (userSession) {
            if (userSession.userName) {
               //set the _deferredUsername so that it can be reused
               _deferredUsername = deferred;
               _deferredUsername.resolve(userSession.userName.toLowerCase());
            } else {
               log.error('Error getting username from userSessionService');
            }
         });
         return deferred.promise;
      }

      /**
       * Gets the local storage that this service is build upon
       * @returns the browser local storage if such exists, or a fake one
       */
      function getLocalStorage() {
         // Need a try/catch because IE may throw "access denied"
         try {
            if ($window.localStorage) {
               return $window.localStorage;
            }
         } catch(e) {
            log.error(e);
         }

         // fake storage returning always null data
         var dict = {};
         return {
            getItem: function (key) {
               return dict[key];
            },
            setItem: function (key, value) {
               dict[key] = value;
            }
         };
      }
   }
})();
