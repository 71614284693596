
(function() {
   angular.module('com.vmware.platform.ui')

      .directive('autoscrollintoview', ['$timeout', function($timeout) {
         return {
            restrict: 'A',
            link : function($scope, $element, attributes) {
               $timeout(function() {
                  var preferredElement = attributes['autoscrollintoview']
                        ? $element.find(attributes['autoscrollintoview'])[0]
                        : undefined;
                  var elementToScrollTo = preferredElement || $element[0];

                  if (elementToScrollTo) {
                     if (elementToScrollTo.scrollIntoViewIfNeeded) {
                        elementToScrollTo.scrollIntoViewIfNeeded();
                     } else {
                        elementToScrollTo.scrollIntoView(false);
                     }
                  }
               }, 1);
            }
         };
      }]);
})();
