/* Copyright 2016 Vmware, Inc. All rights reserved. -- VMware Confidential */

/**
 * The static, non-transitioning progress directive shows a percentage-labeled progress bar
 * Shows an optional cancel button
 */
angular.module('com.vmware.platform.ui').directive('vxStaticProgressIndicator', function() {

   vxStaticProgressIndicatorController.$inject = ['i18nService'];

   function vxStaticProgressIndicatorController (i18nService) {
      var self = this;

      self.$onInit = function() {
         self.resources = {
            cancelTooltip: i18nService.getString('Common', 'cancel'),
            cancelEnableClass: "vui-icon-datagrid-cancel",
            cancelDisabledClass: "vui-icon-datagrid-cancel-disabled"
         };

         // derived CSS states
         self.resources.resolvedCancelClass = self.isCancelPending
            ? self.resources.cancelDisabledClass
            : self.resources.cancelEnableClass;

         if (!self.enableCancelButton) {
            self.resources.resolvedCancelClass = self.resources.cancelDisabledClass;
         }

         self.events = {
            onCancelClickEvent: function(event) {
               if (self.enableCancelButton) {
                  self.cancelCallback(event);
               }
            }
         };
      };
   }
   return {
      restrict: 'E',
      bindToController: true,
      controllerAs: "vx_spi_ctrl",
      templateUrl: 'resources/ui/components/progress/vxStaticProgressIndicator.html',
      scope: {
         progress: "=",
         enableCancelButton: "=",
         isCancelPending: "=",
         cancelCallback: "&"
      },
      controller: vxStaticProgressIndicatorController
   };

});

