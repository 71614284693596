module platform {
   import IPromise = angular.IPromise;

   export class DataService {
      static $inject = ["$injector"];
      private _vcDataService:DataService;

      constructor(private $injector:any) {
      }

      get vcDataService() {
         if (!this._vcDataService) {
            this._vcDataService = this.$injector.get('vcDataService');
         }
         return this._vcDataService;
      }

      getData(objectId:string, modelClassName:string,
              options:DataOptions = {}):IPromise<any> {
         return this.vcDataService.getData(objectId, modelClassName, options);
      }

      getProperties(objectId:string, properties:string[],
                    options:PropertiesOptions = {}):IPromise<any> {
         return this.vcDataService.getProperties(objectId, properties, options);
      }

      getPropertiesForObjects(objectIds:string[], properties:string[],
                              options:PropertiesOptions = {}):IPromise<any> {

         return this.vcDataService.getPropertiesForObjects(objectIds, properties, options);
      }

      getPropertiesByRelation(objectId:string, relation:string,
                              targetType:string, properties:string[],
                              options:DataOptions = {}):IPromise<any> {
         return this.vcDataService.getPropertiesByRelation(objectId, relation, targetType, properties, options);
      }

      getPropertiesByFilter(filterSpec: FilterSpec,
                            properties: string[],
                            resourceModels: string[],
                            options: DataOptions = {}): Promise<any> {

         return this.vcDataService.getPropertiesByFilter(filterSpec, properties, resourceModels, options);
      }
   }

   /**
    * @ngdoc service
    * @name com.vmware.platform.ui.dataService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Utility methods for fetching data from the /data endpoint.
    */
   angular.module("com.vmware.platform.ui").service("dataService", DataService);
}
