namespace h5_client {
   import IScope = angular.IScope;
   import IRootScopeService = angular.IRootScopeService;

   export module platform {
      class ScheduledTasksViewController {
         public static $inject = ['$scope', '$rootScope', 'i18nService', 'dataService', 'vuiConstants', 'timeFormatterService',
            'defaultUriSchemeUtil', '$q', 'scheduledTaskTimeService'];
         public i18n: any;
         private objectId: string;
         private viewId: string;
         private dataGridOptions: any;
         private lastRunDatagridOptions: any;
         private scheduledTaskInfo: any;
         private selectedScheduledTask: any;
         private splitterOptions: any;

         constructor(private $scope: IScope, private $rootScope: IRootScopeService, private i18nService: any, private dataService: any,
               private vuiConstants: any, private timeFormatterService: any,  private defaultUriSchemeUtil:any, private $q: any,
                     private scheduledTaskTimeService: any) {
            let self: any = this;
            this.objectId = self.$scope._route.objectId;
            this.viewId = self.$scope._view.$id;

            self.i18n = self.i18nService.getString;
            self.setupGridOptions();
            self.getScheduledTasksData();
         }

         private getScheduledTasksData(): angular.IPromise<any> {
            return this.dataService.getProperties(this.objectId, 'allScheduledTasks').then((tasks: any) => {
               this.dataGridOptions.data = this.formatScheduledTaskData(tasks);
            });
         }

         private formatScheduledTaskData(tasks: any): any {
            let formattedData = tasks.allScheduledTasks.items.map((data: any) => {
               if (data.properties[0].value.lastRunInfo) {
                  data.properties[0].value.lastRunTime = data.properties[0].value.lastRunInfo.startTime || '';
                  data.properties[0].value.lastRunResult = data.properties[0].value.lastRunInfo.state || '';
               } else {
                  data.properties[0].value.lastRunTime = '';
                  data.properties[0].value.lastRunResult = '';
               }
               data.properties[0].value.schedule = this.getSchedule(data.properties[0].value.recurrence);
               return data.properties[0].value;
            });
            return formattedData;
         }

         private setupGridOptions() {
            let columnDefinitions: any = [{
               displayName: this.i18n('ScheduledOpsUi', 'columnHeader.task'),
               field: 'name',
               width: '30%'
            }, {
               displayName: this.i18n('ScheduledOpsUi', 'columnHeader.schedule'),
               field: 'schedule'
            }, {
               displayName: this.i18n('ScheduledOpsUi', 'columnHeader.lastRun'),
               field: 'lastRunTime',
               template: (dataItem) => {
                  return this.timeFormatterService.timestampToText(Number(dataItem.lastRunTime));
               }
            }, {
               displayName: this.i18n('ScheduledOpsUi', 'columnHeader.lastRunResult'),
               field: 'lastRunResult'
            }, {
               displayName: this.i18n('ScheduledOpsUi', 'columnHeader.nextRun'),
               field: 'nextRunTime',
               template: (dataItem) => {
                  return this.timeFormatterService.timestampToText(Number(dataItem.nextRunTime));
               }
            }];

            this.dataGridOptions = {

               onChange: (data) => {
                  this.showSelectedItemData(data);
               },
               columnDefs: columnDefinitions,
               data: [],
               sortOrder: [{
                  field: 'lastRunTime',
                  dir: 'desc'
               }],
               sortMode: this.vuiConstants.grid.sortMode.SINGLE,
               selectionMode: this.vuiConstants.grid.selectionMode.SINGLE,
               selectedItems: [],
               height: '100%',
               resizable: true,
               reorderable: true,
               columnMenu: {
                  sortable: false, // this will hide sort menu items
                  messages: {
                     columns: this.i18n('CommonUi', 'listview.showColumnsMenu'),
                     filter: this.i18n('CommonUi', 'listview.filterMenu')
                  }
               }
            };

            this.lastRunDatagridOptions = {
               columnDefs: [
                  {
                     displayName: this.i18n('ScheduledOpsUi', 'columnHeader.launchedOn'),
                     field: 'startTime',
                     template: (dataItem) => {
                        return this.timeFormatterService.timestampToText(Number(dataItem.startTime));
                     }
                  },
                  {
                     displayName: this.i18n('ScheduledOpsUi', 'columnHeader.duration'),
                     field: 'timeInExecutionString'
                  },
                  {
                     displayName: this.i18n('ScheduledOpsUi', 'columnHeader.runResult'),
                     field: 'state'
                  }
               ],
               data: [],
            };

            this.splitterOptions = {
               orientation: this.vuiConstants.splitter.orientation.VERTICAL,
               panes: [{
                  min: '140px',
                  size: '50%'
               }, {
                  min: '100px'
               }]
            };
         }


         private getSelectedScheduledExecutionTasksData(scheduledTaskId: any) {
            return this.dataService.getProperties(scheduledTaskId, 'scheduledTaskExecutionInfo').then((response :any) => {
               return _.map(response.scheduledTaskExecutionInfo.items[0].properties[0].value, (data :any) => {
                        return data.result[0].result;
               });

            });
         }

         private getSelectedScheduledTasksData(scheduledTaskId: any) {
            return this.dataService.getProperties(scheduledTaskId, 'scheduledTaskInfo').then((response) => {
               response.scheduledTaskInfo.items[0].properties[0].value.lastModifiedTime = this.timeFormatterService.timestampToText(Number(response.scheduledTaskInfo.items[0].properties[0].value.lastModifiedTime));
               response.scheduledTaskInfo.items[0].properties[0].value.nextRunTime = this.timeFormatterService.timestampToText(Number(response.scheduledTaskInfo.items[0].properties[0].value.nextRunTime));
               return response.scheduledTaskInfo.items[0].properties[0].value;
            });
         }

         private showSelectedItemData (selectedItems: any) {
            if(selectedItems.length > 0) {
               this.selectedScheduledTask = selectedItems[0];
               let scheduledTaskId = this.defaultUriSchemeUtil.getVsphereObjectId(this.selectedScheduledTask.scheduledTask);

               return this.$q.all([this.getSelectedScheduledExecutionTasksData(scheduledTaskId), this.getSelectedScheduledTasksData(scheduledTaskId)]).then((response: any) => {
                  this.scheduledTaskInfo = response[1];
                  this.lastRunDatagridOptions.data = response[0];
               });
            }
         }

         private getSchedule(recurrence: any): string {
            let schedule: string = this.i18n('ScheduledOpsUi', 'recurrence.unknown');
            if (recurrence.interval) {
               if (recurrence.weekday || recurrence.day) {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.monthly');
               } else if (typeof recurrence.sunday !== 'undefined') {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.weekly');
               } else if (recurrence.hour) {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.daily');
               } else if (recurrence.minute) {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.hourly');
               }
            } else {
               if (recurrence.minute) {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.afterStartup');
               } else if (recurrence.runAt) {
                  schedule = this.i18n('ScheduledOpsUi', 'recurrence.once');
               }
            }
            return schedule;
         }

         private getScheduleForDisplay(recurrence: any): string {
            let schedule: string = this.i18n('SchedulingUi', 'recurrence.unknown');
            if(recurrence) {
               if (recurrence.interval) {
                  if (recurrence.weekday) {
                     schedule = this.i18n('SchedulingUi', 'recurrence.monthlyByWeekLong', recurrence.interval, this.scheduledTaskTimeService.getWeekOffset(recurrence), this.scheduledTaskTimeService.getWeekday(recurrence), this.scheduledTaskTimeService.getTime(recurrence));
                  } else if (typeof recurrence.day !== 'undefined') {
                     schedule = this.i18n('SchedulingUi', 'recurrence.monthlyLong', recurrence.interval, this.scheduledTaskTimeService.getDay(recurrence), this.scheduledTaskTimeService.getTime(recurrence));
                  } else if (typeof recurrence.sunday !== 'undefined') {
                     schedule = this.i18n('SchedulingUi', 'recurrence.weeklyLong', recurrence.interval, this.scheduledTaskTimeService.getDaysOfWeek(recurrence), this.scheduledTaskTimeService.getTime(recurrence));
                  } else if (recurrence.hour) {
                     schedule = this.i18n('SchedulingUi', 'recurrence.dailyLong', recurrence.interval, this.scheduledTaskTimeService.getTime(recurrence));
                  } else if (recurrence.minute) {
                     schedule = this.i18n('SchedulingUi', 'recurrence.hourlyLong', recurrence.interval, recurrence.minute);
                  } else {
                     schedule = this.i18n('SchedulingUi', 'recurrence.minutelyLong', recurrence.interval);
                  }
               } else {
                  if(typeof recurrence.day !== 'undefined' && recurrence.runAt === null) {
                     schedule = this.i18n('SchedulingUi', 'recurrence.nowLong');
                  }  else if (recurrence.minute) {
                     schedule = this.i18n('SchedulingUi', 'recurrence.afterStartupLong', recurrence.minute);
                  } else {
                     schedule = this.i18n('SchedulingUi', 'recurrence.onceLong', this.timeFormatterService.timestampToText(recurrence.runAt));
                  }
               }
            }
            return schedule;
         }

      }

      angular.module('com.vmware.platform.ui')
         .controller('scheduledTasksViewController', ScheduledTasksViewController);
   }
}
