(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').directive('diskFormatSelector', ['diskFormatService',
      function(diskFormatService) {
         return {
            scope: {
               diskFormats: '=',
               selectedFormat: '=?',
               readOnly: '=?',
               ctrlAriaLabeledBy: '=?'
            },
            templateUrl: 'resources/ui/components/diskFormatSelector/diskFormatSelector.html',
            link: function(scope) {

               // If no selection is provided, select the first item by default.
               if (!scope.selectedFormat) {
                  selectFirstItem();
               }

               scope.$watch(function () {
                  return scope.diskFormats;
               }, function(newValue, oldValue) {
                  if (newValue !== oldValue) {
                     if (!scope.selectedFormat) {
                        selectFirstItem();
                     }
                     updateIsDisabled();
                  }
               });

               updateIsDisabled();

               function updateIsDisabled() {
                  scope.isDisabled = !scope.diskFormats || !scope.diskFormats.length ||
                     (scope.diskFormats.length === 1 &&
                        scope.diskFormats[0].type === diskFormatService.diskFormats.asDefinedInProfile.type);
               }

               function selectFirstItem() {
                  if (scope.diskFormats && scope.diskFormats.length > 0) {
                     scope.selectedFormat = scope.diskFormats[0];
                  }
               }
            }
         };
      }]);
})();
