/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * The object navigator controller.
 */
angular.module('com.vmware.platform.ui').controller('ObjectNavigatorController',
['$scope', 'objectNavigatorService', 'vxViewUtil',  '$q', 'dataService', 'dataServiceUtil',
      'defaultUriSchemeUtil', '$timeout', 'extensionFrameworkConstants', 'feedbackService',
      '$log', "treeNodesVisibilityStateService", "notificationService", "i18nService",
function($scope, objectNavigatorService, vxViewUtil, $q, dataService, dataServiceUtil,
         defaultUriSchemeUtil, $timeout, extensionFrameworkConstants, feedbackService, $log,
         treeNodesVisibilityStateService, notificationService, i18nService) {

   $scope.history = [];
   $scope.history.currentIndex = -1;

   // This is done to fix the race condition. ON should be initialized for the first time
   // and not rely on the url change event - as registration may happen later.
   function init (route, previousRoute, forceReinit) {
      if (!forceReinit) {
         // If extension and object did not change, ON view does not need to be updated. Unless replaceSameHistory param is passed
         if (route.extensionId === previousRoute.extensionId &&
             route.objectId === previousRoute.objectId &&
             route.navigator === previousRoute.navigator &&
             (!route._navigationParam || !route._navigationParam.replaceSameHistory)) {
            return;
         }
      }

      // only respond if the url change is from outside the app or search
      if (!route._navigationParam || route._navigationParam.triggerONChange) {
         switchObjectNavigatorView(route, previousRoute);
      }
   }

   // we may need a before route change event instead
   $scope.$on('vxRouteChangeSuccess', function(evt, tree, route,
         previousRoute) {
      if (route.forceNavigate) {
         init(route, previousRoute, true);
         return;
      }
      // if previous route is empty object then return, this is to avoid dupe handling at first time
      if (angular.equals(previousRoute, {})) {
         return;
      }
      init(route, previousRoute);
   });

   // update the history list on modelChanged event
   dataServiceUtil.onModelChanged($scope, ['name','primaryIconId'], function(objectChangeInfo){
      // We are assuming that if historyEntry.naviView.viewDetails.context.id === objectChangeInfo.objectId,
      // then the historyEntry refers to the changed resource object
      return _($scope.history).filter(function(historyEntry){
         return historyEntry &&
             historyEntry.naviView &&
             historyEntry.naviView.viewDetails &&
             historyEntry.naviView.viewDetails.context &&
             historyEntry.naviView.viewDetails.context.id === objectChangeInfo.objectId;
      });
   },function(entry, resp){
      entry.historyDescription.title = resp.name;
      entry.historyDescription.icon = resp.primaryIconId;
   });

   function switchObjectNavigatorView(route, previousRoute) {
      var currentRoute = route;

      if (!route.forceNavigate
            && route.extensionId === previousRoute.extensionId
            && route.objectId === previousRoute.objectId
            && route.navigator === previousRoute.navigator) {
         return;
      }

      return objectNavigatorService.getNaviData(route.extensionId, route.objectId, route.navigator, previousRoute.navigator).then(
         function (data) {
            getNaviDataCallback(data, currentRoute);
         }, function (err) {
            $log.error("Failed to retrieve navigation data." + err);
         }
      );
   }

   function getNaviDataCallback(data, currentRoute) {
      var context = data.viewSpec;
      if (!context) {
         // case of plugins which didn't define a viewSpec
         return;
      }
      context.viewState = data.viewState;
      context._uid = data.uid;

      var namePresentOrObjectNotFound = data.title ||
         (currentRoute.objectId === null || currentRoute.objectId === undefined) ||
         context.url === 'resources/ui/views/objectnavigator/error/ObjectNotFound.html';
      //For resource object, get the name and icon from data service
      var promise = (namePresentOrObjectNotFound) ? $q.when(data) :
         dataService.getProperties(data.uid, ['name','primaryIconId'])
            .then(function(resp){
               angular.extend(data, {
                  id: resp.id,
                  title: resp.name,
                  icon: resp.primaryIconId
               });
               return data;
            });

      promise.then(function(data) {
         var navigate = angular.extend({
               extensionId: currentRoute.extensionId,
               objectId: currentRoute.objectId,
               _navigationParam: currentRoute._navigationParam
            },
            currentRoute.getAdditionalParams()
         );
         // Now that we are going to switch views, we do not need anymore object navigator to change.
         // if (navigate._navigationParam && navigate._navigationParam.triggerONChange) {
         //     navigate._navigationParam.triggerONChange = false;
         // }
         api.switchView({
            url:context.url,
            context: context,
            historyDescription:{
               title: data.title || '(not found)',
               icon: data.icon
            },
            navigate: navigate
         });
      }, function (error) {
         $log.error("Get properties for navigation data has failed." + error);
      });
   }

   var api = $scope.objectnavigator = {
      getState: function(){
         return $scope.history[$scope.history.currentIndex].state;
      },
      getCurrentViewNavigationReqDetails: function(){
         return $scope.history[$scope.history.currentIndex].navigate;
      },
      switchView : function(arg) {

         var url = arg.url;
         var context = arg.context;
         var historyDescription = arg.historyDescription;

         url = url || $scope.history[$scope.history.currentIndex].naviView.$templateUrl;

         context._url = RegExp(".*/(.*)\.html").exec(url)[1]; // return the template name without path or .html portion

         // Check if the current view inside the object navigator is the same as what we want to switch to - if so just
         // put the viewDetails in there and create the history and return.
         var historyEntry = getHistoryEntry(url, context, historyDescription, arg.navigate);

         $scope.history.currentIndex++;
         $scope.history.length = $scope.history.currentIndex;
         $scope.history.push(historyEntry);

         if (context.viewState && context.metadata
               && context.metadata["treeVisibilityStateChanged"] === "vmsAutoShown") {
            treeNodesVisibilityStateService.notifyForChange('vsphere.core.physicalInventorySpec', "VirtualMachine", true);
            notificationService.notify({
               title: i18nService.getString('Common', 'notification.inventoryTee.nodesVisibilityChange.title'),
               content: i18nService.getString('Common', 'notification.inventoryTee.nodesVisibilityChange.message'),
               type: "info"
            });
            angular.extend(arg.navigate, {forceNavigate: true});
         }

         if (arg.navigate) {
            $scope._navigate(arg.navigate.extensionId, arg.navigate.objectId,
               $scope._route.getAdditionalParams(arg.navigate), arg.navigate._navigationParam);
         }
      }

   };

   function getHistoryEntry(url, context, historyDescription,navigateFlags) {
      var historyEntry = null;
      var currentViewUrl = null;
      // Get the current template view.
      if ($scope.history[$scope.history.currentIndex] && $scope.history[$scope.history.currentIndex].naviView) {
         currentViewUrl = $scope.history[$scope.history.currentIndex].naviView.$templateUrl;
      }

      if (historyEntry === null) {
         historyEntry = {
            state: {},
            historyDescription: historyDescription,
            navigate: navigateFlags,
            viewDetails: {
               context: context,
               description: historyDescription
            }
         };
      }
      if (currentViewUrl === url &&
            $scope.history[$scope.history.currentIndex].naviView.viewDetails.context.uid === context.uid) {
         $scope.history[$scope.history.currentIndex].naviView.viewDetails.context = context;
         $scope.history[$scope.history.currentIndex].naviView.viewDetails.description = historyDescription;
         historyEntry.naviView = $scope.history[$scope.history.currentIndex].naviView;
      } else {
         historyEntry.naviView = vxViewUtil.getNode(angular.extend({
            $templateUrl: url,
            $viewRetentionPolicy: extensionFrameworkConstants.ViewRetentionPolicy.NONE,
            viewDetails: {
               context: context,
               description: historyDescription
            }
         }));
      }

      return historyEntry;
   }

   $scope.switchTo = function(idx) {
      $scope.history.currentIndex = idx;
      // Make sure that the right context is set since views are getting reused and it may not trigger a change in the view
      // if context is not changed. State is getting changed since the currentIndex is changing.
      $scope.history[$scope.history.currentIndex].naviView.viewDetails.context = $scope.history[idx].viewDetails.context;
      $scope.history[$scope.history.currentIndex].naviView.viewDetails.description = $scope.history[idx].viewDetails.description;
      // TODO - set the state too.
      var navi = $scope.history[idx].navigate;
      if (navi) {
         $scope._navigate(navi.extensionId, navi.objectId, $scope._route.getAdditionalParams(navi));
      }
      $scope.history.showMenu = false;
   };

   $scope.getPrevious = function() {
      return $scope.history[$scope.history.currentIndex - 1];
   };

   $scope.hasPrevious = function() {
      var preIdx = $scope.history.currentIndex - 1 ;
      return preIdx >= 0;
   };

   $scope.hasNext = function() {
      var nextIdx = $scope.history.currentIndex + 1 ;
      return nextIdx < $scope.history.length;
   };

   $scope.onPrevClick = goBackInHistory;
   $scope.onNextClick = goNextInHistory;

   $scope.showHistoryMenu = showHistoryPullDownMenu;
   $scope.switchToHistoryView = function (index) {
      $scope.switchTo($scope.history.length - 1 - index);
      $scope.history.showMenu = false;
   };

   $scope.hideHistoryMenu = function () {
      $scope.history.showMenu = false;
   };
   function goBackInHistory() {
      $scope.history.showMenu = false;
      $scope.switchTo($scope.history.currentIndex - 1);
   }

   function goNextInHistory() {
      $scope.history.showMenu = false;
      $scope.switchTo($scope.history.currentIndex + 1);
   }

   function showHistoryPullDownMenu($event) {
      $scope.history.showMenu = true;
   }


   $scope.loader.naviLoaded = true;

}]);
