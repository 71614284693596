/* Copyright 2017 Vmware, Inc. All rights reserved. -- Vmware Confidential */
namespace common_ui {
   import IStorageProfileData = common_ui.IStorageProfileData;
   import StorageLocatorAdvancedModeApi = common_ui.StorageLocatorAdvancedModeApi;
   import StorageSelectorBasicState = common_ui.StorageSelectorBasicState;
   import ReplicationGroupSettings = common_ui.ReplicationGroupSettings;

   export class StorageSelectorApi {
      validateSelection: () => string|null;
      validateReplicationGroupSelection: () => string|null;
   }

   export class StorageSelector {
      public templateUrl: string;
      public controller: any;
      public bindings: any;

      constructor() {
         this.templateUrl = "resources/ui/components/storageLocator/storageSelector.html";
         this.controller = StorageSelectorController;
         this.bindings = {
            modeSettings: "=?",
            onSelectionChanged: "&",
            storageSelectorState: "=",
            storageSelectorData: "=",
            storageSelectorApi: "=",
            diskFormatSettings: "=?",
            disableDefaultPreselectionInBasicMode: "<?",
            ensurePreselectionVisibilityInBasicMode: "<?",
            podDisplayDisabled: "<?",
            showReplicationGroups: "<?",
            showStorageBaselineSignpostHints: "<?",
            unsupportedStorageBaselineIds: "<?",
            isVmtxWorkflow: "<?",
            isDiskGroupsAvailable: "<?",
            isPmemOptionDisabled: "<?",
            showEncryptionOptions: "<?",
            isEncryptionOptionsDisabled: "<?",
            encryptionOptionsDisabledReason: "<?"
         };
      }
   } // class

   class StorageSelectorController {
      //
      // Component bindings related properties
      //
      public modeSettings: StorageSelectorModeSettings;
      public onSelectionChanged: () => void;
      public storageSelectorState: StorageSelectorState;
      public storageSelectorData: StorageSelectorData;
      public storageSelectorApi: StorageSelectorApi;
      public diskFormatSettings: StorageSelectorDiskFormatSettings;
      public disableDefaultPreselectionInBasicMode: boolean;
      public podDisplayDisabled: boolean;
      public ensurePreselectionVisibilityInBasicMode: any;
      public showReplicationGroups: boolean;
      public showStorageBaselineSignpostHints: boolean;
      public unsupportedStorageBaselineIds?: string[];
      public showEncryptionOptions: boolean;
      public isEncryptionOptionsDisabled: boolean;
      public encryptionOptionsDisabledReason: string;

      public selectionModeButtonLabel: string;

      public vmIds: string[];
      public vmDestinationHosts: string[];

      public advancedModeApi: StorageLocatorAdvancedModeApi;

      private _onSelectionChangedDebounced: () => void;

      public static $inject = [
         "$scope",
         "$element",
         "$timeout",
         "i18nService",
         "defaultUriSchemeUtil",
         "storageSelectorService",
         "storageSelectorConstants",
         "storageProfileService"
      ];

      constructor(
            private $scope: any,
            private $element: any,
            private $timeout: any,
            public i18nService: any,
            private defaultUriSchemeUtil: any,
            private storageSelectorService: StorageSelectorService,
            private  storageSelectorConstants: StorageSelectorConstants,
            private storageProfileService: any
      ) {
      }

      public $onInit() {
         if (this.storageSelectorApi) {
            this.storageSelectorApi.validateSelection = this.validateSelection.bind(this);
            this.storageSelectorApi.validateReplicationGroupSelection =
                  this.validateReplicationGroupSelection.bind(this);
         }

         if (this.onSelectionChanged) {
            this._onSelectionChangedDebounced = _.debounce(this.onSelectionChanged, 20);
         }

         this.advancedModeApi = new StorageLocatorAdvancedModeApi();

         this.vmIds = [];
         this.vmDestinationHosts = [];
         _.forEach(this.storageSelectorData.sourceVmStorageConfig, (vmConfig: VmStorageConfig) => {
            this.vmIds.push(vmConfig.vmId);
            if (vmConfig.destinationHostId) {
               this.vmDestinationHosts.push(vmConfig.destinationHostId);
            }
         });

         this.initBasicModeData();
         this.initAdvancedModeData();
      }

      public onSelectionModeChange(newSelectionMode: string): void {
         this.storageSelectorState.mode = newSelectionMode;
         switch (newSelectionMode) {
            case this.storageSelectorConstants.BASIC_MODE:
               this.selectionModeButtonLabel = this.i18nService.getString(
                     "Common", "DatastoreSelectControl.Advanced");
               this.updateSelectionInBasicMode();
               break;
            case this.storageSelectorConstants.ADVANCED_MODE:
               this.selectionModeButtonLabel = this.i18nService.getString(
                     "Common", "DatastoreSelectControl.Basic");
               this.onSelectionChangedInAdvancedMode();
               // This is needed because the grid size is not calculated properly by the
               // kendo when the user goes directly in the advanced mode of the wizard
               // because while rendering new vui wizard page the new one starts
               // rendering before the previous is gone.
               this.resizeAdvancedGrid();
               break;
         }
      }

      private initAdvancedModeData() {
         if (!this.storageSelectorState.vmStorageConfigInAdvancedMode) {
            this.storageSelectorState.vmStorageConfigInAdvancedMode =
                  angular.copy(this.storageSelectorData.sourceVmStorageConfig);
         }

         if (!this.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode) {
            this.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode = [];
         } else {
            this.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode = _.filter(
                  this.storageSelectorState.recentlySelectedStorageItemsInAdvancedMode,
                  (item: any) => this.isStorageObjAvailable(item));
         }

         // Remove selected storage objects that are no longer available
         _.forEach(this.storageSelectorState.vmStorageConfigInAdvancedMode, (vmConfig: VmStorageConfig) => {
            if (vmConfig.vmHome) {
               this.removeUnavailableStorage(vmConfig.vmHome);
            }
            _.forEach(vmConfig.vmDisks, (vmDisk: VmComponentStorageConfig) =>
                  this.removeUnavailableStorage(vmDisk));
         });
      }

      private isStorageObjAvailable(storageItem: any): boolean {
         if (storageItem && storageItem.storageRef) {
            let storageId = this.defaultUriSchemeUtil.getVsphereObjectId(storageItem.storageRef);

            return !!this.storageSelectorService.getStorageObjectFromId(
                  storageId, this.storageSelectorData.storageLocatorItemsData);
         }
         return false;
      }

      private removeUnavailableStorage(component: VmComponentStorageConfig) {
         if (component.storageObj) {
            let storageId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  component.storageObj.storageRef);
            let isSdrsEnabled = component.storageObj.storageRef.isSdrsEnabled;
            component.storageObj = this.storageSelectorService.getStorageObjectFromId(
                   storageId, this.storageSelectorData.storageLocatorItemsData);
            if (component.storageObj && component.storageObj.storageRef) {
               component.storageObj.storageRef.isSdrsEnabled = isSdrsEnabled;
            }
            if (component.storageObj && component.storageObj.parentStoragePod) {
               let parentId: string = this.defaultUriSchemeUtil.getVsphereObjectId(component.storageObj.parentStoragePod);
               component.storageObj.parentStoragePod.isSdrsEnabled =
                  this.storageSelectorService.getStorageObjectFromId(parentId, this.storageSelectorData.storageLocatorItemsData).drsEnabled;
            }
         }
      }

      private initBasicModeData() {
         if (!this.storageSelectorState.basicModeState) {
            this.initBasicModeState();
         }

         if (this.storageSelectorState.basicModeState.profilesData) {
            this.$scope.$watch( () => {
               return this.storageSelectorState.basicModeState.profilesData.selectedProfile;
            }, (newValue, oldValue) => {
              if (newValue !== oldValue) {
                 this.updateSelectionInBasicMode();
              }
            });
         }

         this.$scope.$watch(() => {
            return this.storageSelectorState.basicModeState.selectedItem;
         }, (newValue, oldValue) => {
            if (newValue !== oldValue) {
               this.updateSelectionInBasicMode();
            }
         });

         this.$scope.$watch(() => {
            return this.storageSelectorState.basicModeState.replicationGroupSettings.selectedReplicationGroup;
         }, (newValue, oldValue) => {
            if (newValue !== oldValue) {
               this.updateSelectionInBasicMode();
            }
         });

         this.$scope.$watch(() => {
            return this.storageSelectorState.basicModeState.diskFormatSettings.
               selectedDiskFormat;
         }, (newValue, oldValue) => {
            if (newValue !== oldValue) {
               this.updateSelectionInBasicMode();
            }
         });
      }

      private initBasicModeState() {
         this.storageSelectorState.basicModeState = new StorageSelectorBasicState();

         let rgSettings: ReplicationGroupSettings = new ReplicationGroupSettings();
         this.storageSelectorState.basicModeState.replicationGroupSettings = rgSettings;
         rgSettings.replicationGroupValidationError = null;
         rgSettings.showReplicationGroups = this.showReplicationGroups;
         rgSettings.selectedReplicationGroup = this.storageSelectorData.initialConfig
               ? this.storageSelectorData.initialConfig.selectedReplicationGroupInBasicMode
               : null;

         this.storageSelectorState.basicModeState.diskFormatSettings = {
            drsPlacement: this.diskFormatSettings.drsPlacement,
            diskFormatSupported: this.diskFormatSettings.diskFormatSupported,
            sameAsSourceSupported: this.diskFormatSettings.sameAsSourceSupported,
            thickDiskFormatSupported: this.diskFormatSettings.thickDiskFormatSupported,
            selectedDiskFormat: this.storageSelectorData.initialConfig
                  ? this.storageSelectorData.initialConfig.selectedDiskFormatInBasicMode
                  : null
         };

         this.storageSelectorState.basicModeState.selectedItem = null;
         if (this.storageSelectorData.initialConfig) {
            this.storageSelectorState.basicModeState.selectedItem =
                  this.storageSelectorData.initialConfig.selectedItemInBasicMode;
         }

         if (this.storageSelectorData.storageProfilesData) {
            this.storageSelectorState.basicModeState.profilesData = {
               error: this.storageSelectorData.storageProfilesData.error,
               storageProfiles: this.storageSelectorData.storageProfilesData.storageProfiles,
               selectedProfile: this.storageSelectorData.initialConfig
                     ? this.storageSelectorData.initialConfig.selectedStorageProfileInBasicMode
                     : null
            };
         }

         // Determine the drsDisabled value
         this.storageSelectorState.basicModeState.drsDisabled = false; /* default value */
         // If the selectedItemInBasicMode in initialConfig is datastore part of drs-enabled cluster
         // we should disable the drs checkbox
         if (this.storageSelectorData.initialConfig
               && this.storageSelectorData.initialConfig.selectedItemInBasicMode
               && this.storageSelectorData.initialConfig.selectedItemInBasicMode.parentStoragePod) {
            let parentPodId = this.defaultUriSchemeUtil.getVsphereObjectId(
                  this.storageSelectorData.initialConfig.selectedItemInBasicMode.parentStoragePod);
            let parentPodItem =
                  _.find(this.storageSelectorData.storageLocatorItemsData.storagePodItems, (podItem: any) => {
                     return parentPodId === this.defaultUriSchemeUtil.getVsphereObjectId(podItem.storageRef);
                  });
            if (parentPodItem && parentPodItem.drsEnabled) {
               this.storageSelectorState.basicModeState.drsDisabled = true;
            }
         }

         this.storageSelectorState.basicModeState.headerState =
               new common_ui.StorageSelectorHeaderComponentState();
      }

      public onSelectionChangedInAdvancedMode() {
         this.storageSelectorData.vmStorageConfig = this.storageSelectorState.
               vmStorageConfigInAdvancedMode;
         this.triggerOnSelectionChange();
      }

      private updateVmComponentInBasicMode(
            component: VmComponentStorageConfig, isVmHome: boolean) {
         if (!component) {
            return;
         }

         component.storageObj = this.storageSelectorState.basicModeState.selectedItem;
         component.replicationGroup = this.storageSelectorState.basicModeState
               .replicationGroupSettings.selectedReplicationGroup;

         if (isVmHome) {
            component.diskFormat = null;
            this.updateVmComponentProfileInBasicMode(component, true);
         } else {
            // VM Disk
            component.diskFormat = this.storageSelectorState.basicModeState.
                  diskFormatSettings.selectedDiskFormat;
            this.updateVmComponentProfileInBasicMode(component, false);
         }
      }

      private updateVmComponentProfileInBasicMode(
            component: VmComponentStorageConfig, isVmHome: boolean): void {
         if (!this.storageSelectorState.basicModeState.profilesData) {
            return;
         }
         // VM Disks
         if (!isVmHome) {
            if (this.storageSelectorState.storageBaselineId ===
                  this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id) {
               // All Disks go to PMem storage - no discussion.
               component.storageProfile = this.storageProfileService.findPmemStorageProfile(
                     this.storageSelectorData.storageProfilesData.storageProfiles);
               component.storageObj = null;
               return;
            }

            if (this.storageSelectorState.storageBaselineId ===
                  this.storageSelectorConstants.HYBRID_STORAGE_BASELINE.id) {
               if (component.storageProfile && this.storageProfileService.
                        isPmemStorageProfile(component.storageProfile)) {
                  // In hybrid mode we keel the same storage profile - if this is a
                  // Pmem storage profile, just nullify the storageObj
                  component.storageObj = null;
                  return;
               }

               // NOTE speev: If the above check does not work out, we may
               // want to do additional check if the component backing is of
               // type com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo
               // Still, the backing is skipped when building VmComponentStorageConfig
               // in storageSelectorService.ts, so we need to start from there first.
               // something like:
               // if (component.backing._type ===
               //       "com.vmware.vim.binding.vim.vm.device.VirtualDisk$LocalPMemBackingInfo") {
               //    component.storageProfile = this.storageProfileService.findPmemStorageProfile(
               //       this.storageSelectorData.storageProfilesData.storageProfiles);
               //    component.storageObj = undefined;
               // }

               // Fall through - this will assign the selected profile
            }
         }

         // VM Home or fall through
         let isKeepExisingSelected: boolean|undefined =
               !!this.storageSelectorState.basicModeState.profilesData.selectedProfile
               && this.storageSelectorState.basicModeState.profilesData.selectedProfile.
                     keepExistingProfileAssignments;
         if (!isKeepExisingSelected) {
            // If the selected option is different from `Keep existing` then update.
            component.storageProfile =  this.storageSelectorState.basicModeState.
                  profilesData.selectedProfile;
            return;
         }

         // If selected option is `Keep existing` and we are using STANDARD baseline
         // and the component is using PMEM policy we cannot keep that PMEM policy
         // and need to default to something else.
         let isUsingPmemPolicy: boolean = component.storageProfile
               && this.storageProfileService.isPmemStorageProfile(component.storageProfile);
         let isStandardBaseline: boolean = this.storageSelectorState.storageBaselineId ===
               this.storageSelectorConstants.STANDARD_STORAGE_BASELINE.id;
         if (isStandardBaseline && isUsingPmemPolicy) {
            component.storageProfile = this.storageProfileService.getDefaultProfile();
         }
      }

      private updateSelectionInBasicMode() {
         this.storageSelectorData.vmStorageConfig = angular.copy(
               this.storageSelectorData.sourceVmStorageConfig);

         _.forEach(this.storageSelectorData.vmStorageConfig, (vmConfig: VmStorageConfig) => {
            // update vm home
            this.updateVmComponentInBasicMode(vmConfig.vmHome, true);
            // Update disks
            _.forEach(vmConfig.vmDisks, (disk: VmComponentStorageConfig) =>
                  this.updateVmComponentInBasicMode(disk, false));
         });

         this.triggerOnSelectionChange();
      };

      private triggerOnSelectionChange() {
         if (this._onSelectionChangedDebounced) {
            this._onSelectionChangedDebounced();
         }
      }

      private validateSelection(): string|null {
         if (this.storageSelectorState.mode ===
               this.storageSelectorConstants.ADVANCED_MODE) {
            return this.advancedModeApi.validateSelection();
         } else {
            if (this.storageSelectorState.basicModeState
                  .replicationGroupSettings.replicationGroupValidationError) {
               return this.storageSelectorState.basicModeState
                     .replicationGroupSettings.replicationGroupValidationError;
            }

            let hasMissingStorage = _.any(this.storageSelectorData.vmStorageConfig,
                  (vmConfig: VmStorageConfig) => {
               if (vmConfig.vmHome && !vmConfig.vmHome.storageObj) {
                  return true;
               }

               return _.any(vmConfig.vmDisks, (vmDisk: VmComponentStorageConfig) => {
                     return !this.storageProfileService.isPmemStorageProfile(vmDisk.storageProfile)
                           && !vmDisk.storageObj;
               });
            });

            if (hasMissingStorage) {
               return this.i18nService.getString("Common", "storageSelector.incompleteSelection");
            }
         }

         // no errors
         return null;
      }

      private validateReplicationGroupSelection(): string|null {

         if (this.showReplicationGroups &&
               this.storageSelectorState.mode === this.storageSelectorConstants.BASIC_MODE) {
            return this.storageSelectorState.basicModeState
                  .replicationGroupSettings.replicationGroupValidationError;
         }

         // no errors
         return null;
      }

      private resizeAdvancedGrid(): void {
         this.$timeout( () => {
            let grid = this.$element.find(".storage-selector-advanced-mode [kendo-grid]");
            if (!grid) {
               return;
            }
            let kendoGrid = grid.data("kendoGrid");
            if (!kendoGrid) {
               return;
            }
            kendoGrid.resize(true);
         }, 0);
      }

      public onStorageBaselineSelectionChange(newStorageBaselineId?: string) {
         this.storageSelectorState.storageBaselineId = newStorageBaselineId;
         // This is needed because the storage baseline Id is evaluated each time
         // when the user goes directly in the advanced mode of the wizard and then the
         // selections should not be updated according to the basic view selections.
         if (this.storageSelectorState.mode === this.storageSelectorConstants.BASIC_MODE) {
            this.updateSelectionInBasicMode();
         }
      }

      public isLocatorInBasicMode() {
         let vmHomeConfig = _.find(this.storageSelectorData.vmStorageConfig,
               (vmConfig: VmStorageConfig) => !!vmConfig.vmHome);

         return this.storageSelectorState.mode === this.storageSelectorConstants.BASIC_MODE
               && (this.storageSelectorConstants.PMEM_STORAGE_BASELINE.id
                     !== this.storageSelectorState.storageBaselineId
                  || !!vmHomeConfig);
      }

   } // class

   angular.module("com.vmware.platform.ui").component(
         "storageSelector", new StorageSelector());
} // namespace
