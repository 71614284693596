module platform {

   import ISCEService = angular.ISCEService;
   import IPromise = angular.IPromise;
   import IQService = angular.IQService;

   /**
    * Represents the additional parameters appended to the plugin url in the iframe.
    */
   export interface PluginUrlParams {
      // Used by htmlBridgeService. New JS APIs should use vSphereHtmlSdk.getClientLocale.
      locale: string;
      objectId?: string;
      objectType?: string;
      actionUid?: string;  // Used by htmlBridgeService.openActionDialog method.
      serviceGuid?: string; // Used by VC Selector (deprecated in 6.5 U1)
      sessionId?: string;   // Used by VC Selector (deprecated in 6.5 U1)
      serviceUrl?: string;  // Used by VC Selector (deprecated in 6.5 U1)
   }

   /**
    * Help service used for manipulating the plugin url.
    */
   export class PluginUrlService {

      private static readonly FORWARD_SLASH_ENCODED2 = "%252F";

      public static $inject = ["defaultUriSchemeUtil", "$sce", "$http", "$q"];

      constructor(private defaultUriSchemeUtil: any, private $sce: ISCEService,
                  private $http: ng.IHttpService, private $q: IQService) {
      }

      /**
       * Associates the plugin session with the client session by means of requestInterceptor
       * that adds the 'webClientSessionId' header and the SessionManagementFilter
       * that does the association itself.
       * @param url - plugin url used for associating the plugin with the client session.
       */
      associatePluginSessionWithClientId(url: string): IPromise<any> {
         // Don't send such a request, since no session association is needed for full https URLs
         // which are either local plugins going straight to their back-end, or remote plugins
         const isHttpsRegex = /^https?:\/\//i;
         if (!isHttpsRegex.test(url)) {
            return this.$http.get(url, {
               headers: {
                  // https://jira.eng.vmware.com/browse/VUSP-2706
                  // Prevent caching of the request/response.
                  'Cache-Control': 'no-cache, no-store, max-age=0'
               }
            }).then(() => true, () => true);
         } else {
            return this.$q.when(true);
         }
      }

      /**
       * Builds a plugin url which means to associate the plugin session with the client session,
       * append any additional params to the plugin url and trust the plugin url.
       * @param url - plugin url.
       * @param urlParams - additional params to be appended to the plugin url.
       * @returns {any} - return a trusted plugin url.
       */
      buildUrl(url: string, urlParams?: any): any {
         let params: PluginUrlParams = this.buildAndValidateParams(urlParams);
         url = this.appendParams(url, params);

         return this.trustPluginUrl(url);
      }

      /**
       * Appends params to the given url.
       * @param url - the url which will be extended with additional params.
       * @param params - url params to be added to the specified url.
       * @returns {string} - returns the URL as a string.
       */
      appendParams(url: string, params: PluginUrlParams) {
         // quick last-minute dirty fix
         // url may be an $sce-returned object coming from
         // buildUrl() so convert it to normal string
         //TODO fix it with Pivotal Story #142274059
         url = url.toString();
         url += (url.indexOf("?") > 0) ? "&" : "?";
         url += PluginUrlService.convertParamsToString(params);
         return url;
      }

      /**
       * Converts the object which holds the url params to a string.
       */
      private static convertParamsToString(params: PluginUrlParams) {
         return _.map(<any>params, (value, key) => {
            return key + "=" + value;
         }).join("&");
      }

      /**
       * Creates an object which holds the url params.
       * It does validation on the given params and adds additional params
       * like objectType and vc selector related data.
       * @param urlParams - an object which represents the url params.
       * @returns {PluginUrlParams}
       */
      private buildAndValidateParams(urlParams: any): PluginUrlParams {
         let params: PluginUrlParams = {
            locale: urlParams.locale
         };

         let objectId: string = urlParams.objectId;

         let isValidManagedObjectId = objectId &&
               this.defaultUriSchemeUtil.isValidManagedObjectId(objectId);
         if (objectId) {
            params.objectId = objectId.replace("/",
                  PluginUrlService.FORWARD_SLASH_ENCODED2);
         }

         // Add objectType for vSphere ManagedObjects only because we can't get extract the type of other objects
         // out of their objectId without making a server call and that would be overkill.
         let objectType: string | undefined;
         if (isValidManagedObjectId) {
            let parts = this.defaultUriSchemeUtil.getPartsFromVsphereObjectId(objectId);
            objectType = parts.type;
            params.objectType = objectType;
         }

         // VC Selector related url params.
         let vcSelectorSelection = urlParams.vcSelectorSelection;
         if (vcSelectorSelection) {
            params.serviceGuid = vcSelectorSelection.serviceGuid;
            params.sessionId = vcSelectorSelection.sessionKey;
            params.serviceUrl = vcSelectorSelection.serviceUrl;
         }

         return params;
      }

      /**
       * Marks plugin url as trusted before binding the plugin url
       * to ng-src in the iframe tag. That will skip the automatic
       * security checks that Angular runs.
       */
      trustPluginUrl(url: string): any {
         return this.$sce.trustAsResourceUrl(url);
      }

      /**
       * Returns the parameter value or
       * null if the parameter is missing in the url.
       * @param url
       * @param name - parameter name to be searched for in the specified url.
       * @returns {string|null}
       */
      getUrlParameterValue(url: string, name: string): string | null {
         let paramValue: string | null = null;

         let resultArray: RegExpExecArray | null =
               (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)')).exec(url);

         if (resultArray && resultArray.length > 1) {
            paramValue = resultArray[1].replace(/\+/g, '%20');
         }
         return paramValue;
      }
   }

   angular.module("com.vmware.platform.ui").service("pluginUrlService", PluginUrlService);
}
