/* Copyright 2016 Vmware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';

   angular.module('com.vmware.platform.ui')
      .component('macAddressControl', {
         templateUrl: 'resources/ui/components/macAddressControl/macAddressControl.html',
         controller: MacAddressController,
         controllerAs: 'ctrl',
         bindings: {
            addressType: '=?',
            assignedMacAddress: '=?',
            disabled: '=ngDisabled'
         }
      });

   MacAddressController.$inject = ['macAddressValidatorService', 'i18nService'];

   function MacAddressController(macAddressValidatorService, i18nService) {
      var self = this;

      function resetErrorConfig() {
         return {
            message: '',
            isVisible: false
         };
      }

      function isAutomaticAddressType(type) {
         return type === 'assigned' || type === 'generated';
      }

      self.$onInit = function () {
         self.MAC_ADDRESS_PREFIX = "00:50:56:";
         self.originalMacAddress = self.assignedMacAddress;
         self.originalAddressType = self.addressType;

         self.ARIA_LBL_MAC = i18nService.getString("Common", "macAddress");
         self.ARIA_LBL_MAC_TYPE = i18nService.getString("Common", "macAddressType");


         var automaticSelectionId = isAutomaticAddressType(self.addressType) ? self.addressType : 'generated';

         self.dropDownOptions = [
            { id: 'manual', label: i18nService.getString('Common', 'macAddressType.manual') },
            { id: automaticSelectionId, label: i18nService.getString('Common', 'macAddressType.automatic') }
         ];
         self.macAddressValidateErrorConfig = resetErrorConfig();

         if (self.addressType) {
            self.selectedOption =
                  (self.addressType === 'manual') ? self.dropDownOptions[0] : self.dropDownOptions[1];
         } else {
            self.selectedOption = self.dropDownOptions[1];
         }
      };

      // Lifecycle hooks
      self.$postLink = function () {

         // Todo: Change this to use angular $validators and $parsers so we do not need to do this manual logic
         //       of render, setting view value etc.
         self.validateMacAddress = function () {
            if (self.selectedOption.id === 'manual') {
               var validationResult = macAddressValidatorService.validate(self.assignedMacAddress);
               self.macAddressForm.macAddressInput.$setValidity("required", validationResult.result);
               self.macAddressValidateErrorConfig.isVisible = !validationResult.result;
               self.macAddressValidateErrorConfig.message = validationResult.error;
            } else {
               self.macAddressForm.macAddressInput.$setValidity("required", true);
               self.macAddressValidateErrorConfig = resetErrorConfig();
            }
         };

         self.onSelectionChange = function () {
            self.addressType = self.selectedOption.id;

            if (self.selectedOption.id === self.originalAddressType) {
               var defaultMacAddress = isAutomaticAddressType(self.addressType) ? '' : self.MAC_ADDRESS_PREFIX;
               self.assignedMacAddress = !_.isEmpty(self.originalMacAddress) ?
                     self.originalMacAddress : defaultMacAddress;
            } else {
               if (self.originalAddressType === 'manual') {
                  if (self.addressType === 'manual') {
                     self.assignedMacAddress = self.originalMacAddress ? self.originalMacAddress : self.MAC_ADDRESS_PREFIX;
                  } else {
                     self.assignedMacAddress = self.originalMacAddress ? self.originalMacAddress : '';
                  }
               } else {
                  if (self.addressType === 'manual') {
                     self.assignedMacAddress = self.MAC_ADDRESS_PREFIX;
                  } else {
                     self.assignedMacAddress = self.originalMacAddress ? self.originalMacAddress : '';
                  }
               }
            }

            self.isAutomatic = isAutomaticAddressType(self.addressType);
            self.validateMacAddress();
         };

         self.onSelectionChange();
      };
   }
})();
