/**
 * Created by vmware on 12/2/16.
 */
/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter turn boolean into @trueValue/@falseValue or Enabled/Disabled by default
 */
angular.module('com.vmware.platform.ui').filter('enabled', ['$filter', 'i18nService',
   function($filter, i18nService) {
      'use strict';
      return function(value, trueValue, falseValue) {
         trueValue = trueValue || i18nService.getString("Common", "enabled");
         falseValue = falseValue || i18nService.getString("Common", "disabled");

         value = !!value;
         return value ? trueValue : falseValue;
      };
   }]);
