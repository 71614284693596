/* Copyright 2018 VMware, Inc. All rights reserved. -- VMware Confidential */
namespace platform {

   class VxNaviTocHeadersAccessibilityServiceFactory {
      public static $inject = [
         "$timeout"
      ];

      constructor($timeout: any) {
         return (tocViewContainer: any) => {
            return new VxNaviTocHeadersAccessibilityService(
                  $timeout, tocViewContainer);
         };
      }
   }

   enum VxNaviTocHeadersPseudoClass {
      First = ":first",
      Last = ":last"
   }

   import PseudoClass = VxNaviTocHeadersPseudoClass;
   export class VxNaviTocHeadersAccessibilityService {
      public static $inject = [
         "$timeout"
      ];

      private currentFocus: any;
      private ariaId: string = "vx-navi-toc-headers-view-active";
      private tocView: any;

      private readonly EXTENSION_ITEM = "li";
      private readonly CATEGORY_TITLE = "div.toc-tabs-title";
      private readonly EXPANDED = "[aria-expanded='true']";
      private readonly COLLAPSED = "[aria-expanded='false']";
      private readonly SELECTED = "[aria-selected='true']";
      private readonly TOC_VIEW = "div.toc-headers-view";
      private readonly CATEGORY_SECTION = "div.toc-headers-category";
      private readonly SELECTED_EXTENSION_ITEM =
            this.CATEGORY_SECTION + " " + this.EXTENSION_ITEM + this.SELECTED;
      private readonly STATE_FOCUSED = "state-focused";
      private readonly ATTR_ID = "id";
      // The aria-activedescendant attribute contains the ID of the currently
      // active child object that is part of a composite widget within the DOM.
      // It makes do with the overhead of having all or more than one child
      // focusable.
      private readonly ARIA_ACTIVEDESCENDANT = "aria-activedescendant";



      constructor(private $timeout: any,
                  tocViewContainer: any) {
         this.tocView = tocViewContainer.find(this.TOC_VIEW);
         if (this.tocView.attr(this.ATTR_ID)) {
            this.ariaId = this.ariaId + "-" + this.tocView.attr(this.ATTR_ID);
         }
      }

      handleFocus($event: any): void {
         // if selected item's category is collapsed, focus the category title
         const selectedExtension = this.tocView.find(this.SELECTED_EXTENSION_ITEM);
         const categoryTitle = this.getCategoryTitle(selectedExtension);
         const newFocus = categoryTitle && categoryTitle.is(this.COLLAPSED)
               ? categoryTitle
               : selectedExtension;
         this.focusItem(newFocus);
      }

      handleKeydown($event: any): void {
         let newFocus;

         if (!this.currentFocus) {
            // all other methods depend on this.currentFocus being set
            return;
         }

         switch ($event.keyCode) {
            case $.ui.keyCode.DOWN:
               newFocus = this.currentFocus.is(this.EXTENSION_ITEM)
                     ? this.findNextForTocExtension()
                     : this.findNextForCategoryTitle();
               this.focusItem(newFocus);
               break;
            case $.ui.keyCode.UP:
               newFocus =
                     this.currentFocus.is(this.EXTENSION_ITEM)
                           ? this.findPrevForTocExtension()
                           : this.findPrevForCategoryTitle();
               this.focusItem(newFocus);
               break;
            case $.ui.keyCode.ENTER:
            case $.ui.keyCode.SPACE:
               this.clickCurrent();
               break;
            case $.ui.keyCode.RIGHT:
               if (!this.currentFocus.is(this.CATEGORY_TITLE) ||
                     this.currentFocus.is(this.EXPANDED)) {
                  return;
               }
               this.clickCurrent();
               break;
            case $.ui.keyCode.LEFT:
               if (this.currentFocus.is(this.CATEGORY_TITLE)) {
                  if (this.currentFocus.is(this.EXPANDED)) {
                     this.clickCurrent();
                  }
                  return;
               }

               newFocus = this.getCategoryTitle(this.currentFocus);
               if (!newFocus) {
                  return;
               }
               this.focusItem(newFocus);
               break;
            case $.ui.keyCode.HOME:
               newFocus = this.findCategoryTitle(PseudoClass.First);
               if (!newFocus) {
                  newFocus = this.findExtension(PseudoClass.First);
               }
               if (newFocus) {
                  this.focusItem(newFocus);
               }
               break;
            case $.ui.keyCode.END:
               newFocus = this.findExtension(PseudoClass.Last);
               if (!newFocus) {
                  newFocus = this.findCategoryTitle(PseudoClass.Last);
               }
               if (newFocus) {
                  this.focusItem(newFocus);
               }
               break;
            default:
               break;
         }
      }

      handleBlur($event: any): void {
         this.unfocusCurrent();
      }

      /**
       * Next for extension item is:
       * 1) Another extension item from the same category
       * 2) The next category title or its first extension (if the category
       * does not have a title)
       */
      private findNextForTocExtension(): void {
         const nextTocExtension = this.currentFocus.next();
         if (nextTocExtension.length) {
            return nextTocExtension;
         }

         const thisCategory =
               this.currentFocus.closest(this.CATEGORY_SECTION);
         const nextElement = this.findNextForCategory(thisCategory);
         return nextElement;
      }

      /**
       *  Next for category is:
       *  1) Title of the next category
       *  2) First item of the next category if it does not have a title
       *  3) Title of the first category
       *  4) First item of the first category if there is no title.
       */
      private findNextForCategory(currentCategory: any): any {
         let nextElement;
         const nextCategory = currentCategory.next();
         if (nextCategory.length) {
            nextElement = this.findFirstForCategory(nextCategory);
         } else {
            const firstCategory =
                  currentCategory.parent()
                        .find(this.CATEGORY_SECTION + PseudoClass.First);
            nextElement = this.findFirstForCategory(firstCategory);

         }
         return nextElement;
      }

      /**
       * Finds the first focusable element in category - it is either the
       * category's title, or its first child if the category doesn't have a
       * title.
       */
      private findFirstForCategory(category: any): void {
         const categoryTitle = category.find(this.CATEGORY_TITLE);
         const nextElement = categoryTitle.length
               ? categoryTitle
               : category.find(this.EXTENSION_ITEM + PseudoClass.First);
         return nextElement;
      }

      /**
       * Next for category title is:
       * 1) First item if the category is expanded and has items.
       * 2) If the category is collapsed OR does not have items - look for a
       * next element in the next category.
       */
      private findNextForCategoryTitle(): void {
         const thisCategory =
               this.currentFocus.closest(this.CATEGORY_SECTION);

         const isExpanded = this.currentFocus.is(this.EXPANDED);
         if (isExpanded) {
            const firstItem = thisCategory.find(this.EXTENSION_ITEM + PseudoClass.First);
            if (firstItem) {
               return firstItem;
            }
         }

         // when the current category is collapsed OR does not have items
         // find the next inside the next category
         const nextElement = this.findNextForCategory(thisCategory);
         return nextElement;
      }

      /**
       * Prev for extension item is:
       * 1) Previous extension item in the same category
       * 2) The title of the current category
       * 2) The prev category title or its last extension (if the category
       * does not have a title)
       */
      private findPrevForTocExtension(): void {
         const prevTocExtension = this.currentFocus.prev();
         if (prevTocExtension.length) {
            return prevTocExtension;
         }

         const thisCategory =
               this.currentFocus.closest(this.CATEGORY_SECTION);
         const categoryTitle = thisCategory.find(this.CATEGORY_TITLE);
         if (categoryTitle.length) {
            return categoryTitle;
         }

         const prevElement = this.findPrevForCategory(thisCategory);
         return prevElement;
      }

      /**
       *  Prev for category is:
       *  1) Last item of the previous category.
       *  2) Title of the previous category if it does not have items.
       *  4) Last item of the last category.
       *  3) Title of the last category if it does not have items.
       */
      private findPrevForCategory(currentCategory: any): void {
         let prevElement;
         const prevCategory = currentCategory.prev();
         if (prevCategory.length) {
            prevElement = this.findLastInsideCategory(prevCategory);
         } else {
            const lastCategory =
                  currentCategory.parent().find(this.CATEGORY_SECTION + PseudoClass.Last);
            prevElement = this.findLastInsideCategory(lastCategory);

         }
         return prevElement;
      }

      /**
       * Finds the last focusable element in category - it is either the
       * category's last child if category is expanded or its title if the
       * category is collapsed or doesn't have items.
       */
      private findLastInsideCategory(category: any): void {
         let prevElement;
         const categoryTitle = category.find(this.CATEGORY_TITLE);
         if (categoryTitle.length) {
            const isExpanded = categoryTitle.is(this.EXPANDED);
            if (isExpanded) {
               prevElement = category.find(this.EXTENSION_ITEM + PseudoClass.Last);
            } else {
               prevElement = categoryTitle;
            }
         } else {
            prevElement = category.find(this.EXTENSION_ITEM + PseudoClass.Last);
         }

         return prevElement;
      }

      /**
       * Previous for category title is:
       * 1) Last item of the previous category if it is expanded and has items.
       * 2) Category title of the previous category.
       */
      private findPrevForCategoryTitle(): void {
         const thisCategory =
               this.currentFocus.closest(this.CATEGORY_SECTION);
         const prevElement = this.findPrevForCategory(thisCategory);
         return prevElement;
      }

      private getCategoryTitle(currentTocExtension: any): any | null {
         const categoryTitle = currentTocExtension
               .closest(this.CATEGORY_SECTION)
               .find(this.CATEGORY_TITLE);
         return categoryTitle.length ? categoryTitle : null;
      }

      private focusItem(newItem: any): void{
         this.unfocusCurrent();

         this.currentFocus = newItem;
         // add highlight style for the selected item
         this.currentFocus.addClass(this.STATE_FOCUSED);

         // set aria-activedescendant attribute for the screen readers
         const focusedItemId = this.currentFocus.attr(this.ATTR_ID) || this.ariaId;
         this.currentFocus.attr("id", focusedItemId);
         this.tocView.attr(this.ARIA_ACTIVEDESCENDANT, focusedItemId);

         // convert jquery element to html element
         if (this.currentFocus.get(0).scrollIntoViewIfNeeded) {
            this.currentFocus.get(0).scrollIntoViewIfNeeded();
         } else if (this.currentFocus.get(0).scrollIntoView) {
            this.currentFocus.get(0).scrollIntoView();
         }
      }

      private unfocusCurrent(): void {
         if (!this.currentFocus) {
            return;
         }

         this.currentFocus.removeClass(this.STATE_FOCUSED);
         // if the element didn't have an explicit id set, it gets the custom
         // this.ariaId value set for an id, so when un-focusing the element we
         // should remove that id
         if (this.ariaId === this.currentFocus.attr(this.ATTR_ID)) {
            this.currentFocus.removeAttr(this.ATTR_ID);
         }

         this.tocView.removeAttr(this.ARIA_ACTIVEDESCENDANT);
         this.currentFocus = null;
      }

      private clickCurrent(): void {
         if (!this.currentFocus) {
            return;
         }

         // if not with timeout => digest already in progress error occurs
         this.$timeout(() => {
            this.currentFocus.click();
         }, 10);
      }

      private findCategoryTitle(nth: PseudoClass) {
         const categoryTitle = this.tocView
               .find(this.CATEGORY_TITLE + nth);
         if (!categoryTitle.length) {
            return null;
         }
         return categoryTitle;
      }

      private findExtension(nth: PseudoClass) {
         const extension = this.tocView
               .find(this.EXTENSION_ITEM + nth);
         if (!extension.length) {
            return null;
         }
         return extension;
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("vxNaviTocHeadersAccessibilityService",
               VxNaviTocHeadersAccessibilityServiceFactory);
}