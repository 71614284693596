/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

(function () {
   'use strict';

   angular
      .module('com.vmware.platform.ui')
      .controller('IssuesListController', IssuesListController);

   IssuesListController.$inject = ['$scope', 'i18nService', 'issueService',
      'actionsService', '$rootScope', 'configurationService', 'defaultUriSchemeUtil'];

   function IssuesListController($scope, i18nService, issueService, actionsService,
                                 $rootScope, configurationService, defaultUriSchemeUtil) {
      var self = this;

      // flag indicating if a request for the issues is currently in progress
      self.pendingRequest = false;

      self.i18n = i18nService.getString;
      self.VMWARE_KB_SITE = "https://kb.vmware.com/s/article/";

      //-----View bindable logic--------//
      /**
       * The default extension id for all objects to navigate to
       * @type {string}
       */

      /**
       * Max issue counts to show
       * @type {number}
       */
      self.ISSUE_COUNT_TO_SHOW = 4;

      self.summaryIssuesGroups = [];

      /**
       * Total count of issues retrieved from the server
       * @type {number}
       */
      self.totalCount = 0;

      self.navigateToServerObjectViewsExtension = navigateToServerObjectViewsExtension;

      self.navigate = navigate;

      self.invokeAction = invokeAction;

      self.getIssues = getIssues;

      self.$onInit = function() {

         // Get all issues from the server on load of the view
         getIssues();
         subscribeToLiveRefresh();

         $scope.$on('IssueChangeEvent', getIssues);
         $scope.$on('$destroy', function () {
            if (self.cancelLiveRefresh) {
               self.cancelLiveRefresh();
            }
         });
      };

      //----- Private functions --------//

      /**
       * Get the list of issues from the server
       */
      function getIssues() {
         if (self.pendingRequest === true) {
            return;
         }
         self.pendingRequest = true;
         var promise = issueService.getIssues(self.objectId, self.ISSUE_COUNT_TO_SHOW, true);

         promise.then(function (issues) {
            self.totalCount = issues.totalMatchedObjectCount;

            self.summaryIssuesGroups = [
               makeIssueGroupForStatus('ERROR', issues.summaryItemSpecs),
               makeIssueGroupForStatus('WARNING', issues.summaryItemSpecs),
               makeIssueGroupForStatus('INFO', issues.summaryItemSpecs),
               makeIssueGroupForStatus('QUESTION', issues.summaryItemSpecs),
               makeIssueGroupForStatus(null, issues.summaryItemSpecs)
            ];

            self.summaryIssuesGroups = _.filter(self.summaryIssuesGroups);
         }).finally(function() {
            self.pendingRequest = false;
         });
      }


      var statusIconMap = {
         'WARNING': { shape: 'exclamation-triangle', className : 'alert-warning', ariaLabel: 'Common:issueStatus.warning'},
         'ERROR': { shape: 'exclamation-circle', className: 'alert-danger', ariaLabel: 'Common:issueStatus.error'},
         'INFO': { shape: 'info-circle', className: 'alert-info', ariaLabel: 'Common:issueStatus.info'},
         'QUESTION': { shape: 'info-circle', className: 'alert-info', ariaLabel: 'Common:issueStatus.questions'},
         null: { shape: 'info-circle', className: 'alert-info', ariaLabel: 'Common:issueStatus.info'}
      };

      function makeIssueGroupForStatus(status, allIssues) {
         var issues = _.filter(allIssues, function(issue) {
            return issue.status === status;
         });

         if (issues.length) {
            return {
               status: status,
               ariaLabel: getIssueGroupAriaLabel(status),
               icon: statusIconMap[status],
               issues: issues.map(function(issue) {
                  return new SummaryIssue(issue);
               })
            };
         }

         return null;
      }

      function getIssueGroupAriaLabel(status) {
         switch (status) {
            case 'WARNING':
               return i18nService.getString("CommonModuleUi", "issueType.warningsAriaLabel");
            case 'ERROR':
               return i18nService.getString("CommonModuleUi", "issueType.errorsAriaLabel");
            case 'QUESTION':
               return i18nService.getString("CommonModuleUi", "issueType.questionsAriaLabel");
            case 'INFO':
               return i18nService.getString("CommonModuleUi", "issueType.infoAriaLabel");
            default:
               return i18nService.getString("CommonModuleUi", "issueType.infoAriaLabel");
         }
      }

      /**
       * Invokes issue action.
        * @param actionEvaluation
        * @param issue
        *   The action evaluation to be used when invoking the action.
        */
      function invokeAction(actionEvaluation, issue) {
         if (actionEvaluation && actionEvaluation.available) {
            actionsService.invokeAction(actionEvaluation, issue);
         }
      }

      /**
       * Navigate to a given extenion point
       * @param objectId
       */
      function navigateToServerObjectViewsExtension(objectId) {
         $rootScope._navigateToViewAndObject('vsphere.core.inventory.serverObjectViewsExtension', objectId);
      }

      /**
       * Navigate to a passed extenion point
       * @param objectId
       */
      function navigate(extensionId, objectId) {
         $rootScope._navigateToViewAndObject(extensionId, objectId);
      }
      /**
       * Summary issue model object representing each issue in the view
       * @param issue
       * @constructor
       */
      function SummaryIssue(issue) {
         this.issueUid = issue.issueUid;
         // If a specific localisation is used in the H5 client we have passed both
         // localisation key and description and in this constructor we change the
         // descriptionthat is passed from the backend with the new that is H5 specific.
         if (issue.descriptionKey) {
            this.description = " " + self.i18n('Common', issue.descriptionKey);
         } else {
            this.description = " " + issue.description;
         }
         // Add link to KB article if URL is included in the description
         const kbIndex = this.description.indexOf(self.VMWARE_KB_SITE);
         if (kbIndex >= 0) {
            const substr = this.description.substring(kbIndex + self.VMWARE_KB_SITE.length);
            const result = /[\d]+/.exec(substr);
            if (result) {
               this.kbNumber = result[0];
            }
         }
         this.type = issue.issueType;
         // the vui-icon- class gives the effect of an empty icon to keep issues without icons aligned
         // this.icon = alarmIconMap[issue.status] || 'vui-icon-';
         this.entityName = issue.entityName ? issue.entityName + ':' : '';
         this.entityId = issue.entityId;
         this.details = issue.details;
         this.actionEvaluations = issue.actionEvaluations;

         var issueText = issue.entityName
               ? issue.entityName + " " + this.description : this.description;

         _.forEach(this.actionEvaluations, function(actionEvaluation) {
            if (actionEvaluation.additionalData &&
                  actionEvaluation.additionalData["issueAction.ariaLabelLocalizationKey"]) {
               var localizationKeyParts = actionEvaluation.additionalData["issueAction.ariaLabelLocalizationKey"].split(":");
               actionEvaluation.ariaLabel =
                     i18nService.getString(localizationKeyParts[0], localizationKeyParts[1], issueText);
            }
         });
      }

      function onAlarmChange(evt, partialUpdate) {
         if (self.pendingRequest) {
            return;
         }
         var newAlarms = _.filter(partialUpdate.updates, function (update) {
            return isNewAlarm(update) &&
                  defaultUriSchemeUtil.getVsphereObjectId(update.data.entity) === self.objectId;
         });

         if (newAlarms.length > 0) {
            getIssues();
            return;
         }
         _.each(partialUpdate.updates, function (update) {
            if (!isNewAlarm(update)) {
               var comparator = isAcknowledged(update) ? ackComparator : resetComparator;
               eraseIssue(update.source, comparator);
            }
         });
      }

      function isAcknowledged(update) {
         return update.isDelta && update.data;
      }

      function isNewAlarm(update) {
         return !update.isDelta && update.data;
      }

      function ackComparator(issue, key) {
         if (!issue.details || !issue.details.alarmState || !issue.details.alarmState.alarm) {
            return false;
         }
         return defaultUriSchemeUtil.getVsphereObjectId(issue.details.alarmState.alarm) ===
                     defaultUriSchemeUtil.getVsphereObjectId(key);
      }

      function resetComparator(issue, key) {
         if (!issue.details || !issue.details.alarmState) {
            return false;
         }
         return issue.details.alarmState.key === key;
      }

      function eraseIssue(key, comparator) {
         _.each(self.summaryIssuesGroups, function (group) {
            if (group) {
               var idx = _.findIndex(group.issues, function(issue) {
                  return comparator(issue, key);
               });
               if (idx !== -1) {
                  group.issues.splice(idx, 1);
                  self.totalCount--;
                  if (group.issues.length === 0) {
                     eraseGroup(group);
                  }
               }
            }
         });
      }

      function eraseGroup(group) {
         var idx = _.findIndex(self.summaryIssuesGroups, function (summaryGroup) {
            return summaryGroup.status === group.status;
         });
         if (idx !== -1) {
            self.summaryIssuesGroups.splice(idx, 1);
         }
      }

      function subscribeToLiveRefresh() {
         configurationService.getProperty('live.updates.alarms.enabled')
               .then(function (refreshEnabled) {
                  if (refreshEnabled === 'true') {
                     self.cancelLiveRefresh = $rootScope.$on('alarms', onAlarmChange);
                  }
               });
      }
   }

}());
