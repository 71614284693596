/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */
/**
 * Directive that adds refresh functionality (both manual and auto-refresh) to the view.
 * This directive takes a isolated scope which should contain something like:
 *    {  Array of objectIds (watchObjects): ObjectIds of the objects whose properties change trigger the refresh of the view.
 *       refresh() - callback to get refresh the view.
 *       delayRefreshOnObjectUpdatesBy: Optional Time in MSecs to refresh data after a object change is detected. Use this only when you find inconsistencies
 *       where backend VC server does not change the object properties before returning.
 *    }
 */
(function() {
   'use strict';
   angular.module('com.vmware.platform.ui').directive('vxRefreshable', refreshableCtrl);

   function refreshableCtrl() {
      var directive = {
            restrict: 'A',
             scope: {
                objectIds: '@watchObjects', // Array of object Ids to watch for changes.
                refreshCallback: '&refresh',
                refreshEventTypes: '@refreshEventTypes',
                delayRefreshOnObjectUpdatesBy: '@delayRefreshOnObjectUpdatesBy',
                liveRefreshEnabled: '@',
                liveRefreshProperties: '=?',
                hasObjectStateProperty: '@'
             },
             controller: RefreshController
      };
      return directive;
   }

   RefreshController.$inject = ['$scope', '$element', '$timeout',
      'visibilityAwareDataUpdateNotificationService', 'defaultUriSchemeUtil', 'vcH5ConstantsService'];
   function RefreshController($scope, $element, $timeout,
         visibilityAwareDataUpdateNotificationService, defaultUriSchemeUtil, vcH5ConstantsService) {
      var OBJECT_DETAILS_CHANGED_EVENT = 'object-details';
      var liveRefreshEnabled = $scope.liveRefreshEnabled ?
            $scope.liveRefreshEnabled === 'true' : true;
      var relevantProperties = {};
      if (liveRefreshEnabled) {
         _.each($scope.liveRefreshProperties, function (prop) {
            relevantProperties[prop] = true;
         });
      }
      var debouncedRefreshCallback = _.debounce(function() {
         $scope.refreshCallback();
      }, vcH5ConstantsService.CHANGELOG_REFRESH_DELAY);
      var timeoutPromise = null;

      var dataUpdateEventsConfig = {};

      var refreshInvocationEventEnabled = !$scope.refreshEventTypes ||
            ($scope.refreshEventTypes.indexOf(vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT) !== -1);
      var modelChangedEventEnabled = !$scope.refreshEventTypes ||
            ($scope.refreshEventTypes.indexOf(vcH5ConstantsService.MODEL_CHANGED_EVENT) !== -1);
      var objectDetailsChangedEventEnabled = !$scope.refreshEventTypes ||
            ($scope.refreshEventTypes.indexOf(OBJECT_DETAILS_CHANGED_EVENT) !== -1);

      if (refreshInvocationEventEnabled) {
         dataUpdateEventsConfig[vcH5ConstantsService.DATA_REFRESH_INVOCATION_EVENT] = {
            handler: $scope.refreshCallback
         };
      }

      if (modelChangedEventEnabled) {
         dataUpdateEventsConfig[vcH5ConstantsService.MODEL_CHANGED_EVENT] = {
            handler: onModelChanged,
            checker: isModelChangeRelevant
         };
      }

      if (objectDetailsChangedEventEnabled) {
         dataUpdateEventsConfig[OBJECT_DETAILS_CHANGED_EVENT] = {
            handler: onObjectDetailsChanged,
            checker: isObjectDetailsChangeRelevant
         };
      }

      var dataUpdateNotificationSubscriberId =
         visibilityAwareDataUpdateNotificationService.subscribeForDataUpdate(
            $element,
            $scope.refreshCallback,
            dataUpdateEventsConfig
         );

      function isObjectDetailsChangeRelevant(event, partialUpdate) {
         if (!$scope.objectIds || !liveRefreshEnabled) {
            return false;
         }

         if (!$scope.hasObjectStateProperty && _.isEmpty(relevantProperties)) {
            return false;
         }

         return partialUpdate.updates.some(isUpdateRelevant);
      }

      function isUpdateRelevant(update) {
         var mor = update.data;
         var objectId = defaultUriSchemeUtil.createVmomiUri(
               mor.type, mor.value, mor.serverGuid
         );

         if ($scope.hasObjectStateProperty && $scope.hasObjectStateProperty === 'true') {
            return $scope.objectIds.indexOf(objectId) >= 0
                  && update.metadata
                  && update.metadata['hasObjectStateProperty'];
         }

         return $scope.objectIds.indexOf(objectId) >= 0 &&
               update.deltaProperties.some(function (prop) {
                  return _.has(relevantProperties, prop);
               });
      }

      function onObjectDetailsChanged(event, partialUpdate) {
         debouncedRefreshCallback();
      }

      function isModelChangeRelevant(event, objectChangeInfo) {
         var objectIdsToWatchFor = $scope.objectIds;

         var changedId = null;
         if (objectChangeInfo.operationType === 'CHANGE') {
            changedId = objectChangeInfo.objectId;
         }

         return objectIdsToWatchFor &&
               objectIdsToWatchFor.indexOf(changedId) >= 0;
      }

      function onModelChanged(event, objectChangeInfo) {
         // Use debounced refresh if model change and live refresh updates are
         // both enabled to avoid multiple view refreshes. A debounce will not
         // ensure that in general, but should improve the chances and is easy
         // to implement.
         var refreshCallback = objectDetailsChangedEventEnabled
               ? debouncedRefreshCallback
               : $scope.refreshCallback;
         // TODO smarathe: WORKAROUND for VC bug - for cases like
         // https://bugzilla.eng.vmware.com/show_bug.cgi?id=1508760
         // In some cases, the edit action takes place on the server and
         // returns without a task (VC server bug). In this case, if there
         // is no timeout set, the view refreshes before VC server has actually
         // completed the task. For those rare mutations, let the view know
         // that it has to refresh after a timeout.
         if ($scope.delayRefreshOnObjectUpdatesBy > 0) {
            timeoutPromise = $timeout(refreshCallback, $scope.delayRefreshOnObjectUpdatesBy);
         } else {
            refreshCallback();
         }
      }

      // Cancel and nullify the promise when the scope is destroyed (meaning if the view is destroyed).
      $scope.$on('$destroy', function() {
         if (timeoutPromise) {
            $timeout.cancel(timeoutPromise);
            timeoutPromise = null;
         }

         if (dataUpdateNotificationSubscriberId !== null) {
            visibilityAwareDataUpdateNotificationService.unsubscribe(
               dataUpdateNotificationSubscriberId
            );
            dataUpdateNotificationSubscriberId = null;
         }
      });
}
})();
