/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */
/*
 * Directive to display tabs.
 *
 * Usage:
 * <div vx-view-tabs="tabs"></div>
 * Where 'tabs' is an array of vx-view nodes to render
 */
angular.module('com.vmware.platform.ui').directive('vxViewTabs', ['navigation',
   function(navigation) {
      return {
         scope: {
            tabs: '=vxViewTabs',
         },
         template: "<div class='fill-parent'> " +
         "<ul class='nav nav-tabs'> " +
            "<li ng-class='{active: isSelected($index)}' ng-repeat='tab in tabs'>" +
               "<a ng-click='switchTab($index)'>" +
               "<i class='{{tab.icon}}'></i>{{tab.title}}</a>" +
            "</li>" +
         "</ul>" +
         "<div class = 'tab-content fill-parent' vx-view = 'getSelectedTab()'> </div>" +
         "</div>",
         link: function($scope) {
            navigation.populateScope($scope);
            init();
            // set first tab as selected
            function init() {
               $scope.selectedTabId = 0;
            }

            //--------------------- scope functions -------------------

            /*
             * Set given tabId as the selectedTabId
             * @param tabId
             */
            $scope.switchTab = function(tabId) {
               $scope.selectedTabId = tabId;
            };

            /*
             * Returns true if the selectedTabId is equal to the given tabId
             * @param tabId
             */
            $scope.isSelected = function(tabId) {
               return $scope.selectedTabId === tabId;
            };

            /*
             * Get the selectedTab.
             */
            $scope.getSelectedTab = function() {
               if (angular.isDefined($scope.tabs) && angular.isArray($scope.tabs)) {
                  return $scope.tabs[$scope.selectedTabId];
               }
            };
         }
      };
   }]);