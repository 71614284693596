(function() {
   'use strict';

   /**
    * @name IssuesListConstants
    *
    * @description
    *    Represents Issue constants
    */
   angular
         .module('com.vmware.platform.ui')
         .constant('issuesListConstants', {
            issueType: {
               VM_QUESTION_ISSUE: 'VmQuestionIssue',
               ALARM_ISSUE: 'AlarmIssue',
               CONFIG_ISSUE: 'ConfigIssue'
            }
         });
})();
