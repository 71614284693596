/* Copyright 2017 VMware, Inc. All rights reserved. -- VMware Confidential */
module platform {

   export interface NavigationData {
      viewId: string;
      customData: any;
   }

   /**
    This cache is used to store and retrieve custom data that is passed to
    navigateTo() API.
    */
   export class IframeNavigationDataCache {

      // this field is used to store information about the navigation data
      // which is passed to navigatoTo() API
      private navigationData: NavigationData;

      // this value is used by getNavigationData() API
      private returnedNavigationData: any;


      setNavigationData(newNavigationData: NavigationData): void {
         this.navigationData = newNavigationData;
      }

      getReturnedNavigationData(): any {
         return this.returnedNavigationData;
      }

      updateNavigationData(extensionId: string): void {
         let currentNavData = this.navigationData;
         this.navigationData = undefined;

         if (currentNavData && currentNavData.viewId !== extensionId) {
            currentNavData = undefined;
         }
         this.returnedNavigationData = currentNavData ? currentNavData.customData : undefined;
      }
   }

   angular.module("com.vmware.platform.ui")
      .service("iframeNavigationDataCache", IframeNavigationDataCache);
}
