/* Copyright 2013 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Filter turns floating point numbers into integers always rounding to the higher integer using Math.ceil
 */

angular.module('com.vmware.platform.ui').filter('ceil', function() {
   return function(input) {
      return Math.ceil(input);
   };
});