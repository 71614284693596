/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function() {

   'use strict';

   angular.module('com.vmware.platform.ui').
   factory('inventoryTreeViewDragAndDropHandler', ['dragAndDropManager',
      function (dragAndDropManager) {

         function InventoryTreeViewDragAndDropHandler(dragAndDropManager) {
            var FORBIDDEN_CLASSES = {
               'insert-middle' : true,
               'insert-top': true,
               'insert-bottom': true
            };

            var self = this;

            self.sourceItem = null;
            self.targetItem = null;
            self.lastKnownStatusClass = undefined;

            self.dragstart = function ($event) {
               self.lastKnownStatusClass = undefined;
               self.sourceItem = $event.vxDataItem;
               dragAndDropManager.clearCache();
            };

            self.drag = function ($event) {
               self.lastKnownStatusClass = $event.statusClass;

               if (FORBIDDEN_CLASSES[$event.statusClass]){
                  // Disallow dropping the dragged node before or after an allowed node.
                  markDropAsDisallowed($event);
                  return;
               }

               var currentItem = $event.vxDataItem;

               if (!currentItem) {
                  // If the dragged item goes out of the tree the vxDataItem comes as
                  // undefined.
                  markDropAsDisallowed($event);
                  return;
               }

               if (self.sourceItem === currentItem) {
                  // We don't allow source and target of drop operation to be the same.
                  // Since drag is called almost on every pixel - this is a 'must have'
                  // check in order to avoid pointless further processing.
                  markDropAsDisallowed($event);
                  return;
               }

               if (!dragAndDropManager.isDropValid([self.sourceItem], currentItem)) {
                  markDropAsDisallowed($event);
                  return;
               }

               markDropAsAllowed($event);
            };

            self.dragend = function ($event) {
            };

            self.drop = function ($event) {
               // Keep the lastStatusClass for one final check.
               var lastKnownStatusClassValue = self.lastKnownStatusClass;

               // Wipe off any leftovers. The drop handle is called for both: when
               // initiated DnD from the tree as well as DnD from the grid. We need to
               // clear it right here - not to mix states from the two DnD initiators.
               self.lastKnownStatusClass = undefined;

               if (FORBIDDEN_CLASSES[lastKnownStatusClassValue]) {
                  cancelDrop($event);
                  return;
               }

               var sourceItems = [self.sourceItem];
               if($event.draggable && $event.draggable.currentTarget) {
                  sourceItems = $event.draggable.currentTarget.itemsData;
               }

               //The event object holds
               self.targetItem = $event.vxDataItem;

               if (!dragAndDropManager.isDropValid(sourceItems, self.targetItem)) {
                  cancelDrop($event);
                  return;
               }

               dragAndDropManager.invokeAction(sourceItems, self.targetItem);
               $event.preventDefault();
            };

            function markDropAsAllowed(evt) {
               evt.setStatusClass('vsphere-icon-drag-and-drop-accept');
            }

            function markDropAsDisallowed(evt) {
               evt.preventDefault();
               evt.setStatusClass('vsphere-icon-drag-and-drop-reject');
            }

            function cancelDrop($event) {
               // Events due to drops from the grid don't have setValid method,
               // weird huh...
               if ($event.setValid) {
                  $event.setValid(false);
               }
               $event.preventDefault();
            }
         }

         return new InventoryTreeViewDragAndDropHandler(dragAndDropManager);
      }]);
})();

