/* Copyright 2014-2016 VMware, Inc. All rights reserved. -- VMware Confidential */

/*
 * Registers common action invokers in the h5.actions cache.
 *
 * We do this in the run block of the module so that invokers can take
 * advantage of angular services injected into a closure.
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui').run(registerCommonActionInvokers);

   registerCommonActionInvokers.$inject = [
      '$window',
      'configurationService',
      'clarityModalService',
      'helpService',
      'i18nService',
      'logoutService',
      'themeService',
      '$rootScope',
      '$q'
   ];

   function registerCommonActionInvokers(
      $window,
      configurationService,
      clarityModalService,
      helpService,
      i18nService,
      logoutService,
      themeService,
      $rootScope,
      $q) {

      _.extend($window.h5.actions, {
         'vsphere.core.actions.logout': logoutAction,
         'vsphere.core.actions.openHelp': openHelpAction,
         'vsphere.core.actions.changePassword': changePasswordAction,
         'vsphere.core.actions.switchTheme': switchThemeAction,
         'vsphere.core.actions.openVmcOnAws': openVmcOnAwsAction
      });

      /**
       * Action for logging out of the web app.
       */
      function logoutAction() {
         var options = {
            preventLogout: false
         };
         var evt = $rootScope.$broadcast('onUserLogout', options);
         if (evt.defaultPrevented) {
            $q.when(options.preventLogout).then(function(preventLogout) {
               if (!preventLogout) {
                  logoutService.logout();
               }
            });
            return;
         }

         logoutService.logout();
      }

      /**
       * Action for opening a help page.
       */
      function openHelpAction() {
         helpService.showHelpPage();
      }

      /**
       * Action for opening a VMware Cloud on AWS page.
       */
      function openVmcOnAwsAction(){
         helpService.showVmcOnAwsPage();
      }

      /**
       * Action for changing the password of the currently logged user.
       */
      function changePasswordAction(actionEval, availableTargets, context) {
         clarityModalService.openModal(
            actionEval,
            availableTargets,
            context,
            'resources/ui/views/mainlayout/ChangePasswordDialog.html'
         );
      }

      /**
       * Action for changing the theme between light and dark.
       */
      function switchThemeAction() {
         themeService.changeTheme();
      }
   }
}());

