"use strict";
angular
   .module("com.vmware.platform.ui")
   .constant("navigationConstants", {
      DEFAULT_CLIENT_LOCATION: "vsphere.core.viTree.hostsAndClustersView",
      SERVER_OBJECT_VIEW_EXTENSION_ID: "vsphere.core.inventory.serverObjectViewsExtension",
      SEARCH_VIEW_EXTENSION_ID: "vsphere.core.search.domainView",
      NAVIGATOR_IDS: [
         "vsphere.core.viTree.hostsAndClustersView",
         "vsphere.core.viTree.vmsAndTemplatesView",
         "vsphere.core.viTree.storageView",
         "vsphere.core.viTree.networkingView",
      ],
      HOME_EXTENSION_ID: "com.vmware.samples.dashboard.mainView",
      SHORTCUTS_EXTENSION_ID: "vsphere.core.controlcenter.domainView",
      HOSTS_AND_CLUSTERS_EXTENSION_ID: "vsphere.core.viTree.hostsAndClustersView",
      VMS_EXTENSION_ID: "vsphere.core.viTree.vmsAndTemplatesView",
      STORAGE_EXTENSION_ID: "vsphere.core.viTree.storageView",
      NETWORKING_EXTENSION_ID: "vsphere.core.viTree.networkingView",
      DATASTORES_EXTENSION_ID: "vsphere.core.viLibraries.domainView",
      GLOBAL_INVENTORY_LISTS_EXTENSION_ID: "vsphere.core.viHome.domainView",
      // retry 3 times at most, when navigating to an invalid extension
      RETRIES_THRESHOLD: 3,
      NAVIGATION_REQUEST_DURATION_MEDIAN: "navReqDurationMed",
      NAVIGATION_REQUEST_BUFFER_TIME_PROP: "telemetry.navigation.bufferTime"
   });