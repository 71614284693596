/* Copyright 2016 VMware, Inc. All rights reserved. -- VMware Confidential */
(function () {
   'use strict';
   angular
      .module('com.vmware.platform.ui')
      .factory('keyboardShortcutService', keyboardShortcutService);
   keyboardShortcutService.$inject = [
      'actionsService',
      'contextObjectService',
      'keyboardShortcutMapper',
      'notificationService',
      'navigation',
      'vxZoneService',
      'telemetryService'
   ];

   var NO_ACTION_WITH_ID = "No Action registered with ID";

   /**
    * @ngdoc service
    * @name keyboardShortcutService
    * @module com.vmware.platform.ui
    *
    * @description
    *    Service to bind shortcuts to a specific action or function.
    * @param actionsService
    * @param contextObjectService
    * @returns {{
         bindFunction: bindFunction,
         bindAction: bindAction
      }}
    */
   function keyboardShortcutService(actionsService, contextObjectService, keyboardShortcutMapper, notificationService, navigation, vxZoneService, telemetryService) {
      var service = {
         bindFunction: bindFunction,
         bindAction: bindAction,
         bindNavigationShortcut: bindNavigationShortcut,
         invokeNavigation: invokeNavigation
      };
      var blockedShortcuts = {};

      return service;

      /**
       * Binds a function to a specific shortcut
       * Sample:
       *    keyboardShortcutService.bindFunction('ctrl+f', function(event) {...});
       *    event parameter is of KeyboardEvent type
       * @param shortcut - a sequence of keyboard keys
       * @param functionToBind - function to be invoked when the shortcut is pressed
       */
      function bindFunction(shortcut, functionToBind) {

         Mousetrap.bind(shortcut, function (event, shortcut) {
            if (blockedShortcuts[shortcut]) {
               return false;
            }
            // we simulate a mouse click so that if there are any opened pop-up
            // (such as actions-menu, context menu or other) will be closed
            simulateClick();
            blockedShortcuts[shortcut] = true;
            vxZoneService.runInsideAngular(function () {
               trackTelemetryKeyboardEvent(shortcut);
               functionToBind(event);
            });
            //this is used to prevent the default browser behaviour of some shortcuts
            return false;
         }, 'keydown');

         Mousetrap.bind(shortcut, function (event, shortcut) {
            if (blockedShortcuts[shortcut]) {
               delete blockedShortcuts[shortcut];
            }
            return false;
         }, 'keyup');
      }

      /**
       * Invokes a given action when the specified keyboard shortcut is triggered. Action
       * is invoked on the currently selected object in the H5 app.
       * Sample:
       *    keyboardShortcutService.bindAction('ctrl+alt+b', 'vsphere.core.vm.powerOnAction');
       * @param shortcut - a sequence of keyboard keys
       * @param actionId - an action id from plugin.xml that will be evaluated and invoked
       * when the shortcut is pressed
       */
      function bindAction(shortcut, actionId) {
         keyboardShortcutMapper.putKeyboardShortcut(actionId, shortcut);
         bindFunction(shortcut, evaluateActionAndInvokeFunction.bind({}, actionId));
      }

      /**
       * Invokes navigation to a specific view when the corresponding keyboard shortcut
       * is triggered.
       * Sample:
       *    keyboardShortcutService.bindNavigationShortcut('ctrl+alt+1',
       *    'vsphere.core.controlcenter.domainView');
       * @param shortcut - a sequence of keyboard keys
       * @param extensionId - an extension id from plugin.xml that will be evaluated and invoked
       * when the shortcut is pressed
       */
      function bindNavigationShortcut(shortcut, extensionId) {
         keyboardShortcutMapper.putKeyboardShortcut(extensionId, shortcut);
         bindFunction(shortcut, invokeNavigation.bind({}, extensionId));
      }

      function trackTelemetryKeyboardEvent(shortcut, extensionId) {
         telemetryService.trackEvent("keyboard", "shortcut", shortcut, extensionId);
      }

      function invokeNavigation(extensionId, event) {
         navigation.navigateToView(extensionId);
      }

      function evaluateActionAndInvokeFunction(actionId, event) {
         var targets = contextObjectService.getSelection();
         if (targets.length > 0) {
            actionsService.getAction(actionId, targets, true)
               .then(function (actionEval) {
                  actionsService.invokeActionWithConfirmation(actionEval, {}, targets);
               })
               .catch(function (error) {
                  if (error.status && error.status === 500) {
                     var message = error.data.message;
                     if (message && message.lastIndexOf(NO_ACTION_WITH_ID, 0) < 0) {
                        var notificationParam = {};
                        notificationParam.content = message;
                        notificationParam.type = notificationService.type.ERROR;
                        notificationService.notify(notificationParam);
                     }
                  }
               });
         }
      }

      function simulateClick() {
         $(document.body).mousedown();
      }
   }
}());