/* Copyright 2015 VMware, Inc. All rights reserved. -- VMware Confidential */

/**
 * Tree view directive that creates a kendo tree view by dynamically loading the tree nodes
 * from the server based on a tree id
 *
 * Example:
 * 1) bare minimum (even without a controller)
 * <div vx-tree-view  tree-id="vsphere.core.physicalInventorySpec"></div>
 *
 * 2) Controller with onChange callback
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       on-change="ctrl.onChange(objRef)"
 *    ></div>
 * </div>
 *
 * 3) Controller with onError callback
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       on-error="ctrl.onError(error)"
 *    ></div>
 * </div>
 *
 * 4) Providing a reference to the root node (rootObjRef)
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       on-change="ctrl.onChange(objRef)"
 *       root-obj-ref="ctrl.treeRootObjRef"   //or if the scope is used: root-obj-ref="treeRootObjRef"
 *    ></div>
 * </div>
 *
 * 5) Providing a preselected node or means to change the current selection programmatically.
 *   If the corresponding node is not currently loaded a request to the server
 *   will be send to get the available paths to from the root down to that node.
 *   Note:
 *   selectNode is being watched for changes, so that changing it will result in a tree selection change
 *
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       select-node="ctrl.id_of_object_to_select"
 *    ></div>
 * </div>
 *
 * 6) Providing 'persistselection' in the 'options' attribute will allow the directive to persist
 *    the last selected node so that the node be selected automatically the next time this tree is
 *    being rendered
 *
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       options="persistselection"
 *    ></div>
 * </div>*
 *
 * 7) Providing 'config' may fine tune the directive behavior
 *
 * ctrl.treeConfig = {
 *    preselectRoot : false/true   //defaults to true if missing
 * }
 * preselectRoot - if explicitly set to false the root node will not be selected
 *                otherwise  the root node will be preselected.
 *                This config is applied only if there is no other item to be preselected,
 *                i.e. there is no 'select-node' and 'persistselection' options.
 *
 *
 * <div ng-controller="InventoryTreeViewController as ctrl">\
 *    <div
 *       vx-tree-view
 *       tree-id="vsphere.core.physicalInventorySpec"
 *       config="ctrl.treeConfig"
 *    ></div>
 * </div>*
 */
(function() {
   'use strict';

   angular.module('com.vmware.platform.ui')
         .directive('vxTreeView', vxTreeView);

   function vxTreeView() {

      return {
         link: link,
         scope: {
            treeId: '@treeId',
            options: '@options',
            change: '&onChange',
            error: '&onError',
            rootObjRef: '=',
            selectNode: '=',
            config: '='
         },
         controller: 'VxTreeViewController'
      };

      function link(scope, element, attrs, vxTreeViewController) {

         //this attribute is needed for the CSS rules, but do not set it into the html
         //because it is also an angular directive, so it will start a whole bunch of processing
         element.attr('vui-tree-view', '');

         // note mibryamov: in order the keyboard navigation of kendo tree view
         // to work correctly and read by screen readers, there should be two
         // attributes on the wrapping html element provided - role and id.
         // Since all the instances of the vx-tree-view directive provides
         // a tree-id attribute (that should be unique), we will use it to
         // auto-generate the id attribute (if not provided).
         var idAttr = element.attr('id');
         if (!idAttr) {
            var treeIdAttr = element.attr('tree-id');
            if (treeIdAttr) {
               element.attr('id', treeIdAttr);
            }
         }
         element.attr('role', 'tree');

         //create the kendo tree view
         var kendoTreeView = element.kendoTreeView({
            dragAndDrop: scope.config ? scope.config.dragAndDrop : false,
            // resolves https://jira.eng.vmware.com/browse/VSUIP-2503
            // Pre-selecting the root object in a vui tree breaks JAWS reader
            dataBound: function () {
              element.find('ul[role=tree]').attr('role', null);
            }
         }).data('kendoTreeView');

         vxTreeViewController.setupAndRun(kendoTreeView, scope.config);
      }
   }
})();
