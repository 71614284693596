module platform {

   export class VimEntityEscapingService {
      /**
       * @param value {string} containing special characters (%, \ or /)
       * retrieved from user input
       * @returns {string} value with converted/escaped special characters
       * or return the original value if it is not a string
       */
      public escapeSpecialCharacters(value: any): string {
         if (!value || (typeof value !== "string")) {
            return value;
         }

         return value.replace(/%/g, "%25").replace(/\//g, "%2f").replace(/\\/g, "%5c");
      }

      /**
       * @param value {string} containing escaped special characters (%25, %2f or %5c)
       * retrieved from the backend
       * @returns {string} value with converted/unescaped special characters
       * or return the original value if it is not a string
       */
      public unescapeSpecialCharacters(value: any): string {
         if (!value || (typeof value !== "string")) {
            return value;
         }

         return value.replace(/%25/g, "%")
               .replace(/%2f/g, "\/").replace(/%5c/g, "\\");
      }
   }

   angular.module("com.vmware.platform.ui")
         .service("vimEntityEscapingService", VimEntityEscapingService);
}
